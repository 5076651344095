import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllCategories, getAllJobTitles } from '../../app/config/functions';
const initialState = {
  jobTitleData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  initjobTitleData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch jobtitle data
 */
export const fetchJobTitle = createAsyncThunk(
  'jobTitle/list',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllJobTitles(organisationId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial jobtitle data
 */
export const fetchJobTitleInitData = createAsyncThunk(
  'jobTitle/initData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllJobTitles(organisationId, '', 1, 10);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all jobtitle data
 */
export const fetchJobTitleWithFilter = createAsyncThunk(
  'jobTitle/filter',
  async (
    { organisationId, searchName, page, pageSize, sort },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllJobTitles(
        organisationId,
        searchName,
        page,
        pageSize,
        sort
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter jobtitle data
 */
export const fetchAllJobTitleData = createAsyncThunk(
  'jobtitle/allData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllJobTitles(organisationId, '', 1, 99999);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const jobTitleSlice = createSlice({
  name: 'jobTitle',
  initialState,
  reducers: {
    setjobData: (state, action) => {
      state.jobTitleData = action.payload;
    },
    setjobInitData: (state, action) => {
      state.initjobTitleData = action.payload;
    },
    setjobLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setjobError: (state, action) => {
      state.error = action.payload;
    },
    setjobPage: (state, action) => {
      state.page = action.payload;
    },
    setjobPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobTitle.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobTitle.fulfilled, (state, action) => {
        state.isLoading = false;
        state.initjobTitleData = action.payload.response;
        state.jobTitleData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchJobTitle.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchJobTitleInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchJobTitleInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.initjobTitleData = action.payload.response;

        state.error = null;
      })
      .addCase(fetchJobTitleInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchJobTitleWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchJobTitleWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.jobTitleData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
        if (state.initjobTitleData && state.initjobTitleData.length <= 0) {
          state.initjobTitleData = action.payload.response;
        }
      })
      .addCase(fetchJobTitleWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllJobTitleData.pending, (state) => {})
      .addCase(fetchAllJobTitleData.fulfilled, (state, action) => {})
      .addCase(fetchAllJobTitleData.rejected, (state, action) => {});
  },
});

export const {
  setjobData,
  setLoading,
  setError,
  setjobInitData,
} = jobTitleSlice.actions;

export default jobTitleSlice.reducer;
