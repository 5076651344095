import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Input, Icon } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import { createBulkCategory } from '../../../config/functions';
import { toast } from 'react-toastify';
import useGetJsonDataFromExcelFile from '../../../../hooks/useGetJsonDataFromExcelFile';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { CloseIcon } from '../../../../Assets/Icons/svg';
function CategoryBulkUploadModal({
  isOpen = false,
  setModalOpenState = () => {},
  handleSuccess = () => {},
}) {
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const { getJsonDataFromExcelFile } = useGetJsonDataFromExcelFile();
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleBrowseButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  useEffect(() => {
    return () => {
      setSelectedFile(null);
    };
  }, [isOpen]);
  const handleCategoryCreate = async () => {
    if (
      selectedFile &&
      (selectedFile.type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        selectedFile.type === 'application/vnd.ms-excel')
    ) {
      let jsonData = await getJsonDataFromExcelFile(
        selectedFile,
        [t.categories.table.name],
        'category'
      );
      if (jsonData && jsonData.length) {
        let res = await createBulkCategory(jsonData, organisationId);
        if (res && res.status == 200) {
          // toast.success(t.categories.category_create_success);
          handleSuccess(res);
        } else {
          toast.error(t.common.something_went_wrong);
        }
      }
    } else {
      toast.error(t.categories.upload_excel_category_file);
    }
  };

  const handleDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = toAbsoluteUrl(selectedLanguage == "en" ? '/media/sample-xls/category-sample.xlsx' : "/media/sample-xls/فئة.xlsx"); // Set the path to your static file
    downloadLink.download =selectedLanguage == "en" ?  'category-sample.xlsx' : "فئة.xlsx"; // Set the desired file name
  

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Modal
      className="assets-modal"
      size="tiny"
      open={isOpen}
      onClose={() => setModalOpenState(false)}
    >
      <div className="header-container">
        <span className="assets-header">{t.categories.upload_categories}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => setModalOpenState(false)}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content>
        <span className="assets-label">{t.categories.import_categories}</span>
        {/* <label>{t.categories.import_categories}</label> */}

        <div className="modal-input">
          <div
            class={`small-box ${
              selectedLanguage == 'en' ? 'text-left' : 'text-right'
            }`}
          >
            <label
              className={`text-label ${
                selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
              }`}
            >
              {selectedFile?.name}
            </label>
          </div>
          <input
            id="barcodePopup"
            type="file"
            hidden
            className="file-input"
            ref={fileInputRef}
            fluid
            onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                setSelectedFile(e.target.files[0]);
              }
            }}
          />
          <button onClick={handleBrowseButtonClick} className="browse-btn">
            {t.assets.browse_btn}
          </button>
        </div>

        <div
          className="buttun-type-link mt-5"
          style={{ color: '#0C66E4' }}
          onClick={handleDownload}
        >
          {t.assets.category_temp_xls}
        </div>
        {/* <Input
          id="barcodePopup"
          type="file"
          fluid
          //   value={selectedFile}
          onChange={(e) => {
            if (e.target.files && e.target.files.length) {
              setSelectedFile(e.target.files[0]);
            }
          }}
        />
        <div className="buttun-type-link mt-4" basic onClick={handleDownload}>
          {t.categories.download_sample}
        </div> */}
      </Modal.Content>

      <div
        className={`modal-button-container ${
          selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
        }`}
      >
        <button
          onClick={() => setModalOpenState(false)}
          className="modal-form-action-button black"
        >
          {t.assets.form.cancel}
        </button>

        <button
          onClick={() => handleCategoryCreate()}
          className="modal-form-action-button light-blue"
        >
          {t.categories.import}
        </button>
      </div>
      {/* <Modal.Actions>
        <Button color="black" onClick={() => handleCategoryCreate()}>
          {t.categories.import}
        </Button>
        <Button negative onClick={() => setModalOpenState(false)}>
          {t.common.cancel}
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
}

export default CategoryBulkUploadModal;
