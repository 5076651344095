import aside_en from './aside/en';
import aside_ar from './aside/ar';

import auth_en from './pages/auth/en';
import auth_ar from './pages/auth/ar';

import sets_en from './pages/sets/en';
import sets_ar from './pages/sets/ar';

import work_orders_en from './pages/work_orders/en';
import work_orders_ar from './pages/work_orders/ar';

import procedures_en from './pages/procedures/en';
import procedures_ar from './pages/procedures/ar';

import assets_en from './pages/assets/en';
import assets_ar from './pages/assets/ar';

import parts_en from './pages/parts/en';
import parts_ar from './pages/parts/ar';

import time_en from './pages/time_tracking/en';
import time_ar from './pages/time_tracking/ar';

import location_en from './pages/locations/en';
import location_ar from './pages/locations/ar';

import categories_en from './pages/categories/en';
import categories_ar from './pages/categories/ar';

import job_title_en from './pages/job_title/en';
import job_title_ar from './pages/job_title/ar';

import common_en from './common/en';
import common_ar from './common/ar';

import customers_en from './pages/customers/en';
import customers_ar from './pages/customers/ar';

import vendors_en from './pages/vendors/en';
import vendors_ar from './pages/vendors/ar';

import people_en from './pages/people/en';
import people_ar from './pages/people/ar';

import teams_en from './pages/teams/en';
import teams_ar from './pages/teams/ar';

import timesheet_en from './pages/timesheet/en';
import timesheet_ar from './pages/timesheet/ar';

import dashboard_en from './pages/dashboard/en';
import dashboard_ar from './pages/dashboard/ar';


import work_request_en from './pages/work_requests/en';
import work_request_ar from './pages/work_requests/ar';

import po_en from './pages/purchase_order/en';
import po_ar from './pages/purchase_order/ar';

export default {
  en: {
    aside: aside_en,
    workOrders: work_orders_en,
    workRequests: work_request_en,
    jobTitle:job_title_en,
    procedures: procedures_en,
    assets: assets_en,
    parts: parts_en,
    time: time_en,
    locations: location_en,
    auth: auth_en,
    categories: categories_en,
    common: common_en,
    customers: customers_en,
    vendors: vendors_en,
    people: people_en,
    teams: teams_en,
    timesheet:timesheet_en,
    dashboard:dashboard_en,
    sets:sets_en,
    po:po_en,
  },
  ar: {
    aside: aside_ar,
    workRequests: work_request_ar,
    workOrders: work_orders_ar,
    procedures: procedures_ar,
    assets: assets_ar,
    parts: parts_ar,
    time: time_ar,
    locations: location_ar,
    auth: auth_ar,
    categories: categories_ar,
    common: common_ar,
    customers: customers_ar,
    vendors: vendors_ar,
    people: people_ar,
    teams:teams_ar,
    jobTitle:job_title_ar,
    timesheet:timesheet_ar,
    dashboard:dashboard_ar,
    sets:sets_ar,
    po:po_ar,
  },
};
