import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllParts, getAllPurchaseOrder, getAllWorkOrders } from '../../app/config/functions';
const initialState = {
  POData: [],
  allPOData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  POInitData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch parts initial data
 */
export const fetchPOInitData = createAsyncThunk(
  'po/initData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllPurchaseOrder(organisationId, '', 1, 25,[],[],{},[],[],[],role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all Parts data
 */
export const fetchAllPOData = createAsyncThunk(
  'po/allData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllPurchaseOrder(organisationId, '', 1, 99999,[],[],{},[],[],[],role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter parts data
 */
export const fetchPOWithFilter = createAsyncThunk(
  'po/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      vendors = [],
      status = [],
      totalCost ={},
      submissionDate=[],
      dueDate=[],
      sort = [],
      role='',
      allIds = [],
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllPurchaseOrder(
        organisationId,
      searchName,
      page,
      pageSize,
      vendors,
      status,
      totalCost,
      submissionDate,
      dueDate,
      sort,
      role,
      allIds,
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const poSlice = createSlice({
  name: 'po',
  initialState,

  extraReducers: (builder) => {
    builder

      .addCase(fetchPOInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchPOInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.POInitData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchPOInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchPOWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPOWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.POData = action.payload.response;
        state.allPOData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchPOWithFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllPOData.pending, (state) => {})
      .addCase(fetchAllPOData.fulfilled, (state, action) => {
        state.allPOData = action.payload.response;
      })
      .addCase(fetchAllPOData.rejected, (state, action) => {});
  },
});

export default poSlice.reducer;
