import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import * as auth from '../app/modules/Auth/_redux/authRedux';
import i18nReducer from '../app/config/i18n/slice';
import categoryReducer from '../redux/reducers/categoryReducer';
import jobTitleReducer from '../redux/reducers/jobTitleReducer';
import assetReducer from '../redux/reducers/assetReducer';
import procedureReducer from '../redux/reducers/procedureReducer';
import locationReducer from '../redux/reducers/locationReducer';
import teamsReducer from '../redux/reducers/teamsReducer';
import peopleReducer from '../redux/reducers/peopleReducer';
import vendorReducer from '../redux/reducers/vendorReducer';
import customerReducer from '../redux/reducers/customerReducer';
import WOReducer from '../redux/reducers/workOrderReducer';
import WRReducer from '../redux/reducers/workRequestReducer';
import partsReducer from '../redux/reducers/partReducer';
import setsReducer from './reducers/setsReducer.js';
import poReducer from './reducers/purchaseOrderReducer.js';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: i18nReducer,
  category: categoryReducer,
  jobTitle: jobTitleReducer,
  asset: assetReducer,
  procedure: procedureReducer,
  location: locationReducer,
  teams: teamsReducer,
  people: peopleReducer,
  vendor: vendorReducer,
  customer: customerReducer,
  workOrder: WOReducer,
  purchaseOrder: poReducer,
  workRequest: WRReducer,
  parts: partsReducer,
  sets: setsReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
