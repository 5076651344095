import React, { useEffect, useState } from 'react';
import { Formik, Form as FormikForm, ErrorMessage } from 'formik';
import { Input, Form, Button, Grid, Icon, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { changeLoggedInUserPassword } from '../../config/functions';
import { selectTranslations } from '../../config/i18n/slice';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const ChangePassword = () => {
  const t = useSelector(selectTranslations);
  const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, t.common.min_6_symbol)
      .max(50, t.common.max_50_symbol)
      .required(t.common.required_field),
    newPassword: Yup.string()
      .min(6, t.common.min_6_symbol)
      .max(50, t.common.max_50_symbol)
      .matches(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#!*&]).{6,50}$/,
        t.common.password_validation
      )
      .required(t.common.required_field),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], t.common.password_should_match) // Ensure it matches the "password" field
      .required(t.common.required_field),
  });

  const history = useHistory();
  const [buttonStatus, setButtonStatus] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const handleSubmit = async (values) => {
    setButtonStatus(true);
    let res = await changeLoggedInUserPassword(
      values.oldPassword,
      values.newPassword,
      values.confirmPassword
    );
    if (res && res.status === 200) {
      toast.success('Password changed successfully.');
      history.push('/profile');
    } else {
      if (res.error && res.error.message) {
        toast.error(res.error.message);
      } else {
        toast.error('Password not changed.');
      }
    }
    setButtonStatus(false);
  };
  return (
    <div className='ml-6 mr-6 mt-7'>
      <Formik
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
        }}
        enableReinitialize
        validationSchema={changePasswordSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          setFieldTouched,
          handleBlur,
          submitForm,
        }) => (
          <FormikForm>
            <Grid columns="3">
              <Grid.Column className="">
                <Form.Field autoComplete="off">
                  <label className="label-item">
                    {t.common.old_password}
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t.common.old_password}
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 email-password-ltr`}
                      name="oldPassword"
                      onChange={(e) =>
                        setFieldValue('oldPassword', e.target.value)
                      }
                      error={errors.oldPassword && touched.oldPassword}
                      onBlur={(e) => {
                        setFieldTouched('oldPassword', true);
                      }}
                    />
                    {errors.oldPassword && touched.oldPassword ? (
                      <div className="label-error">{errors.oldPassword}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <Input
                    fluid
                    placeholder="Old Password"
                    value={values.oldPassword}
                    onChange={(e) =>
                      setFieldValue('oldPassword', e.target.value)
                    }
                    error={errors.oldPassword && touched.oldPassword}
                    onBlur={(e) => {
                      setFieldTouched('oldPassword', true);
                    }}
                  />
                  {errors.oldPassword && touched.oldPassword ? (
                    <div className="label-error">{errors.oldPassword}</div>
                  ) : (
                    ''
                  )} */}
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid columns="3">
              <Grid.Column className="">
                <Form.Field autoComplete="off">
                  <label className="label-item">
                    {t.common.new_password}
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t.common.new_password}
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 email-password-ltr`}
                      name="newPassword"
                      onChange={(e) =>
                        setFieldValue('newPassword', e.target.value)
                      }
                      error={errors.newPassword && touched.newPassword}
                      onBlur={(e) => {
                        setFieldTouched('newPassword', true);
                      }}
                    />
                    {errors.newPassword && touched.newPassword ? (
                      <div className="label-error">{errors.newPassword}</div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <Input
                    fluid
                    placeholder="New Password"
                    value={values.newPassword}
                    onChange={(e) =>
                      setFieldValue('newPassword', e.target.value)
                    }
                    error={errors.newPassword && touched.newPassword}
                    onBlur={(e) => {
                      setFieldTouched('newPassword', true);
                    }}
                  />
                  {errors.newPassword && touched.newPassword ? (
                    <div className="label-error">{errors.newPassword}</div>
                  ) : (
                    ''
                  )} */}
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid columns="3">
              <Grid.Column className="">
                <Form.Field autoComplete="off">
                  <label className="label-item">
                    {t.common.confirm_password}
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <div className="form-group fv-plugins-icon-container">
                    <input
                      placeholder={t.common.confirm_password}
                      type="text"
                      className={`form-control form-control-solid h-auto py-5 px-6 email-password-ltr`}
                      name="confirmPassword"
                      onChange={(e) =>
                        setFieldValue('confirmPassword', e.target.value)
                      }
                      error={errors.confirmPassword && touched.confirmPassword}
                      onBlur={(e) => {
                        setFieldTouched('confirmPassword', true);
                      }}
                    />
                    {errors.confirmPassword && touched.confirmPassword ? (
                      <div className="label-error">
                        {errors.confirmPassword}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <Input
                    fluid
                    placeholder="Confirm Password"
                    value={values.confirmPassword}
                    onChange={(e) =>
                      setFieldValue('confirmPassword', e.target.value)
                    }
                    error={errors.confirmPassword && touched.confirmPassword}
                    onBlur={(e) => {
                      setFieldTouched('confirmPassword', true);
                    }}
                  />
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <div className="label-error">{errors.confirmPassword}</div>
                  ) : (
                    ''
                  )} */}
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid columns="4">
              <Grid.Column className=""></Grid.Column>
              <Grid.Column className="">
                <Button
                  content={t.common.save}
                  primary
                  type="submit"
                  loading={buttonStatus}
                  className="m-5"
                />
              </Grid.Column>
              <Grid.Column className="">
                <Button
                  content={t.common.cancel}
                  type="button"
                  onClick={() => {
                    if (
                      values.oldPassword ||
                      values.confirmPassword ||
                      values.newPassword
                    ) {
                      setCancelModal(true);
                    } else {
                      history.push('/profile');
                    }
                  }}
                  className="m-5"
                />
              </Grid.Column>
              <Grid.Column className=""></Grid.Column>
            </Grid>
          </FormikForm>
        )}
      </Formik>
      <Modal
        size="tiny"
        open={cancelModal}
        onClose={() => setCancelModal(false)}
      >
        <Modal.Content className="cancel-text">
          <p>{t.common.cancel_statement}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setCancelModal(false)}>
            {t.common.keep}
          </Button>
          <Button negative onClick={() => history.push('/profile')}>
            {t.common.yes_cancel}
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default ChangePassword;
