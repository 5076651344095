import React, { useMemo } from 'react';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toAbsoluteUrl } from '../../../_helpers';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { SearchDropdown } from '../extras/dropdowns/search/SearchDropdown';
import { UserNotificationsDropdown } from '../extras/dropdowns/UserNotificationsDropdown';
import { QuickActionsDropdown } from '../extras/dropdowns/QuickActionsDropdown';
import { MyCartDropdown } from '../extras/dropdowns/MyCartDropdown';
import { LanguageSelectorDropdown } from '../extras/dropdowns/LanguageSelectorDropdown';
import { QuickUserToggler } from '../extras/QuiclUserToggler';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import useGetCurrentTimeAccZone from '../../../../hooks/useGetCurrentTimeAccZone';
import DateConvert from '../../../../utilFunctions/DateFormat';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../../app/config/i18n/slice';


export function Topbar() {
  const uiService = useHtmlClassService();
  const t = useSelector(selectTranslations);
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const arabicDayAbbreviations = {
    'إثنين': 'الإثنين',
    'ثلاثاء': 'الثلاثاء',
    'أربعاء': 'الأربعاء',
    'خميس': 'الخميس',
    'جمعة': 'الجمعة',
    'سبت': 'السبت',
    'أحد': 'الأحد'
  };

  const arabicMonthAbbreviations = {
    'يناير': 'ينا',
    'فبراير': 'فبر',
    'مارس': 'مار',
    'أبريل': 'أبر',
    'مايو': 'ماي',
    'يونيو': 'يون',
    'يوليو': 'يول',
    'أغسطس': 'أغس',
    'سبتمبر': 'سبت',
    'أكتوبر': 'أكت',
    'نوفمبر': 'نوف',
    'ديسمبر': 'ديس'
  };
  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        'extras.search.display'
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        'extras.notifications.display'
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        'extras.quick-actions.display'
      ),
      viewCartDisplay: objectPath.get(uiService.config, 'extras.cart.display'),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        'extras.quick-panel.display'
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        'extras.languages.display'
      ),
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    };
  }, [uiService]);

  const getFirstLetterOfDay = (date, locale) => {
    if (locale === 'ar') {
      const fullDayName = moment(date).locale('ar').format('ddd');
      return arabicDayAbbreviations[fullDayName] || fullDayName.charAt(0); // Use custom abbreviations or fallback to first letter
    }
    return moment(date).locale(locale).format('ddd'); // Default formatting for other locales
  };

  const getAbbreviatedMonth = (date, locale) => {
    if (locale === 'ar') {
      const fullMonthName = moment(date).locale('ar').format('MMM');
      return arabicMonthAbbreviations[fullMonthName] || fullMonthName.substring(0, 3); // Use custom abbreviations or fallback to first 3 letters
    }
    return moment(date).locale(locale).format('MMM'); // Default formatting for other locales
  };
  

  return (
    <div className="topbar">
      <div
        className={`header-date ${selectedLanguage == 'en' ? '' : 'rtl-date'}`}
      >
        <p>
          <span className="">
    
            {currentDate ? (
              <span className={`day-text mr-2 ${selectedLanguage == 'en' ? '' : "ml-2"}`}>
               {selectedLanguage == 'en' ?  moment(DateConvert((currentDate), organisationTimezone)).format( 'ddd' ) : getFirstLetterOfDay(DateConvert(currentDate, organisationTimezone), "ar")}
               {/* { moment(DateConvert((currentDate), organisationTimezone)).format( 'ddd' )} */}
              </span>
            ) : null}
            {/* {currentDate && selectedLanguage == 'en' 
              ? moment(DateConvert(currentDate, organisationTimezone)).format(
                  'MMM DD,YYYY, hh:mm A'
                )
              : currentDate && selectedLanguage == 'ar' ? getAbbreviatedMonth(DateConvert(currentDate, organisationTimezone), "ar") : null} */}

{currentDate 
              ? moment(DateConvert((currentDate), organisationTimezone)).format(
                  'MMM DD,YYYY, hh:mm A'
                )
              : null}
          </span>
        </p>
      </div>
      {layoutProps.viewSearchDisplay && <SearchDropdown />}

      {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}

      <div className="added-setting-img">
        <Link to="/settings" className="svg-icon svg-icon-xl svg-icon-primary">
          <SVG src={toAbsoluteUrl('/media/svg/icons/General/setting.svg')} />
        </Link>

        {/* <a href="/settings" className="svg-icon svg-icon-xl svg-icon-primary">
          
        </a> */}
      </div>

      {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

      {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

      {/* {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-clean btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Layout/Layout-4-blocks.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )} */}

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
