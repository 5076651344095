export default {
  title: 'Timesheet',
  title_small: 'timesheet',
  filter: {
    search: 'Search by people',
    search_teams: 'Search by teams',
    people: 'People',
    location: 'Location',
    shift: 'Shift',
    shift_A: 'Shift A',
    shift_B: 'Shift B',
    shift_C: 'Shift C',
    date: 'Date',
  },
  table: {
    username: 'User Name',
    teamname: 'Team Name',
    total: 'Total',
  },
  no_timesheet_found: 'start adding timesheet',
  how_to_add_timesheet: 'how to add timesheet',
};
