import React, { useEffect, useState, forwardRef, useRef } from 'react';
import _ from 'lodash';
import { DropdownMenu, Image, Input } from 'semantic-ui-react';
import { DownChevron, SearchIcon } from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onClick={props.onClick}
        title={props.title}
        icon={props.icon}
        image={props.image}
        searchValue={props.searchValue}
        handleSearchValue={props.handleSearchValue}
        {...props}
      />
    );
  };

  return Component;
}

const SearchDropdown = forwardRef(
  (
    {
      open,
      setOpen,
      options = [],
      selectedValues = [],
      onClick = () => {},
      title = '',
      icon = true,
      image = false,
      searchValue = '',
      handleSearchValue = () => {},
    },
    ref
  ) => {
    const { DisplayFormattedText } = useDisplayFormattedText();
    return (
      <>
        <div ref={ref} className="Custom-search-dropdown">
          <UncontrolledDropdown
            className={`${open && 'active'}  ${selectedValues.length > 0 ? 'blue-border' : ''}`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)} color="primary">
              {title} <DownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu>
              <div className="table-searchbar m-3">
                <SearchIcon />
                <Input
                  autoComplete="new-password"
                  iconPosition="left"
                  value={searchValue}
                  onChange={handleSearchValue}
                  className="dropdown-search-input"
                />
              </div>
              {options.map((option) => {
                return (
                  <div>
                    <div
                      className={`custom-dropdown-item d-flex align-items-center ${
                        selectedValues.includes(option.value) ? 'selected' : ''
                      }`}
                      onClick={() => {
                        onClick(option);
                      }}
                    >
                      <Image
                        avatar
                        title={option.text}
                        src={option?.image?.src}
                      />
                      <span
                        style={{ marginLeft: '4px', marginRight: '4px' }}
                        className="custom-dropdown-text"
                      >
                        {DisplayFormattedText(option.text, 9)}
                      </span>
                      <span>{option?.type}</span>
                    </div>
                  </div>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(SearchDropdown);
