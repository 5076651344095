import axios from 'axios';

import store, { persistor } from '../../../../redux/store';
const uninterceptedAxiosInstance = axios.create();

uninterceptedAxiosInstance.interceptors.request.use(
  (config) => {
    const {
      auth: { authToken },
    } = store.getState();

    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }

    return config;
  },
  (err) => Promise.reject(err)
);
export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = 'api/auth/register';
export const REQUEST_PASSWORD_URL = '/auth/forgot-password';
export const ME_URL = `${process.env.REACT_APP_API_URL}/users/me`;
export const ROLE_PERMISSION_URL = `${process.env.REACT_APP_API_URL}users-permissions/roles`;
export const GET_ORGANISATION = `${process.env.REACT_APP_API_URL}organisations`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // console.log(
  //   '${process.env.REACT_APP_API_URL}',
  //   process.env.REACT_APP_API_URL
  // );
  // Authorization head should be fulfilled in interceptor.
  return uninterceptedAxiosInstance.get(ME_URL);
}
export function getPermissionsByRole(roleId) {
  // console.log(
  //   '${process.env.REACT_APP_API_URL}',
  //   process.env.REACT_APP_API_URL
  // );
  // Authorization head should be fulfilled in interceptor.
  return uninterceptedAxiosInstance.get(`${ROLE_PERMISSION_URL}/${roleId}`);
}

export function getOrganisation(roleId) {
  // console.log(
  //   '${process.env.REACT_APP_API_URL}',
  //   process.env.REACT_APP_API_URL
  // );
  // Authorization head should be fulfilled in interceptor.
  return uninterceptedAxiosInstance.get(
    `${GET_ORGANISATION}/${roleId}?populate=profileImage,allowed_modules`
  );
}
