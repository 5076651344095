export default {
  title: "تتبع الوقت",
  add: "إضافة سجل الحضور",
  filter: {
    search: "بحث",
    role: "المسمى الوظيفي",
    shifts: "المناوبات",
  },
  table: {
    name: "الإسم",
    ID: "الرمز",
    role: "المسمى الوظيفي",
    shifts: "المناوبات",
    workingHours: "ساعات العمل",
  },
};
