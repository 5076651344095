import React, { useState, useEffect } from 'react';
import Img1 from '../../../Assets/Icons/Logo_Animation1.gif'


function Animation({width='120px', height='120px'}) {

  const [key, setKey] = useState(Math.random(2));

  useEffect(() => {
    setKey(Math.random(2)); // Update the key to current timestamp to force re-render
  }, []);
  return (
    <img
    key={key}
    style={{borderRadius:"8px"}}
    height={height}
    width={width}
    src={`${Img1}?${key}`} // Append key as query parameter
    alt="Metronic logo"
  />
      // <img src={images[step]} width={width} height={height} alt={`Step ${step + 1}`} className="step-image" />
  );
}
export default Animation;