import React from 'react';
import { Container } from 'react-bootstrap';

const AboutUs = () => {
  return (
    <>
      <div className="terms-conditions privacy">
        <Container>
          <div className="terms">
            <h2>About Quiqflow</h2>
            <p>
              Quiqflow's cloud-based SaaS solution streamlines work processes,
              increases efficiency, optimizes resource utilization, and improves
              collaboration, ultimately enhancing operational performance,
              driving cost savings, and supporting sustainability goals.
            </p>
            <h2>Our Solution:</h2>
            <p>
              We offer a comprehensive, cloud-based Software as a Service (SaaS)
              solution designed to digitize operational procedures,
              revolutionizing the way facilities are managed and optimized. Our
              platform is founded on the principles of instant data and feedback
              mechanisms. With Quiqflow, we introduce scheduled work orders and
              advanced workforce monitoring, optimizing asset condition,
              inventory management, and work processes. This approach leads to
              streamlined operations, increased asset performance and lifespan,
              and a substantial reduction in downtime. Additionally, our
              geolocation punch-in and out features significantly expedite
              timesheet creation and verification, resulting in reporting cycles
              up to 10 times faster than traditional methods. Through visually
              intuitive dashboards, Quiqflow empowers companies with actionable
              insights and data-driven decision-making, allowing businesses to
              level up their core operations and drive efficiency.
            </p>
            <h2>Our Product:</h2>
            <p>
              Quiqflow's cloud-based SaaS offers a range of modules and API
              integrations to digitize and streamline facility and business
              processes:
            </p>
            <ul className="sub-list">
              <li>
                Work Order Management: Efficiently create, assign, and track
                work orders in real-time, optimizing resource allocation and
                task completion.
              </li>
              <li>
                Asset Management: Centralize asset records, enabling
                preventative and predictive maintenance, total cost of ownership
                analysis, and extended asset lifespans.
              </li>
              <li>
                Inventory Management: Real-time tracking and automated
                replenishment reduce stockouts and improve inventory
                optimization.
              </li>
              <li>
                Digitized Task Procedures and Global Library: Standardized task
                procedures and knowledge sharing enhance consistency and
                operational efficiency.
              </li>
              <li>
                Time Tracking Check-in/Out for Workforce: Streamlined time
                recording provides data for labor cost analysis and resource
                allocation optimization.
              </li>
              <li>
                Resource Planning: Real-time resource allocation improves
                workforce utilization and efficiency.
              </li>
              <li>
                ‍Chat System (Communication Tool): Enhance team collaboration,
                reducing communication delays and fostering a collaborative work
                environment.
              </li>
              <li>
                Dashboard: Centralize real-time monitoring of KPIs, aiding in
                assessing facility health and optimizing resource allocation.
              </li>
            </ul>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AboutUs;
