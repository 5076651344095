import React, { useEffect, useState } from "react";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import { Grid } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

import CustomIcon from "../../../../Assets/Icons/dashboard-marker.svg";
import {
  AddIconDashboard,
  CostIconEstimate,
  DashboardWOStatusIcon,
  FullArrow,
  GreenIcon,
  OrangeIcon,
  RedIcon,
  TimeIconEstimate,
} from "../../../../Assets/Icons/svg";
import { GMAPAPIKEY } from "../../../config/functions";

const AssetLocation = ({
  setOpenModal,
  setModalData,
  openModal,
  setAssetDashBoardData,
  assetDashBoardData,
}) => {
  const style = {
    position: "relative",
    width: "100%",
    height: "300px",
  };

  const containerStyle = {};

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [activeMarker, setActiveMarker] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });

  useEffect(() => {
    if (window.google && window.google.maps && assetDashBoardData?.assetsByLocation?.length) {
      const mapElement = document.getElementById("map");
      const map = new window.google.maps.Map(mapElement, {
        zoom: 12,
        center: defaultCenter,
      });

      const circlesAndMarkers = assetDashBoardData.assetsByLocation.map(
        (el) => {
          const markerPosition = {
            lat: el?.mainLocation?.lat,
            lng: el?.mainLocation?.lng,
          };

          // Adjusted circle radius and marker size
          const adjustSizes = (zoom) => {
            const baseRadius = 2000; // Base radius for the circle
            const baseIconSize = 20; // Base size for the SVG icon

            // Scale factor based on zoom level
            const scaleFactor = Math.pow(2, zoom - 12); // Example zoom level adjustment
            const adjustedRadius = baseRadius / scaleFactor;
            const adjustedIconSize = baseIconSize / scaleFactor;

            return { adjustedRadius, adjustedIconSize };
          };

          const updateSize = () => {
            const zoom = map.getZoom();
            const { adjustedRadius, adjustedIconSize } = adjustSizes(zoom);

            circle.setRadius(2000); // Set the circle radius
            marker.setIcon({
              url: CustomIcon,
              scaledSize: new window.google.maps.Size(20, 20),
            });
          };

          // Create circle
          const circle = new window.google.maps.Circle({
            strokeColor:
              el?.locationNo > 500
                ? "#D14040"
                : el?.locationNo <= 500 && el?.locationNo >= 250
                ? "#F17F16"
                : "#4FAB68",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor:
              el?.locationNo > 500
                ? "#D14040"
                : el?.locationNo <= 500 && el?.locationNo >= 250
                ? "#F17F16"
                : "#4FAB68",
            fillOpacity: 0.8,
            map: map,
            center: markerPosition,
          });

          // Create marker
          const marker = new window.google.maps.Marker({
            position: markerPosition,
            map: map,
            icon: {
              url: CustomIcon,
              scaledSize: new window.google.maps.Size(20, 20),
            },
          });

          const infoContent = `
          <button id="close-icon" style="position: absolute; top: 1px; right: 3px; background: none; border: none; cursor: pointer; font-size: 16px; color: #000;">&times;</button>
         <div style="font-size: 12px; color: #172B4D; position: relative; margin-top: 15px;">
          <strong>${el?.location}</strong> &nbsp;
          <span style="font-size: 12px; color: #788CA5;">${el?.locationNo} Assets</span>
        </div>
        `;

          const infoWindow = new window.google.maps.InfoWindow({
            content: infoContent,
            disableAutoPan: true, // Prevent map from panning when opening the info window
            pixelOffset: new window.google.maps.Size(0, -10), // Adjust position of info window
          });

          marker.addListener("click", () => {
            infoWindow.open(map, marker); // Open info window for the clicked marker
            setActiveMarker({ marker, infoWindow }); // Set this marker as active
          });

          window.google.maps.event.addListenerOnce(infoWindow, "domready", () => {
            const closeButton = document.getElementById("close-icon");
            if (closeButton) {
              closeButton.addEventListener("click", () => {
                infoWindow.close(); // Close info window on close button click
                setActiveMarker(null); // Clear active marker
              });
            }
          });

          // Initial size update
          updateSize();

          // Add zoom changed listener
          window.google.maps.event.addListener(map, "zoom_changed", updateSize);

          // Clean up listeners on component unmount
          return () => {
            window.google.maps.event.clearListeners(map, "zoom_changed");
            marker.setMap(null);
            circle.setMap(null);
          };
          //   return { circle, marker };
        }
      );
      return () => {
        // Clean up any remaining markers and circles
        circlesAndMarkers.forEach((cleanup) => cleanup());
      };
    }
  }, [window.google, assetDashBoardData, defaultCenter]);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  return (
    <Grid.Column className="completion-column pl-0 pt-0">
      <div
        className={
          openModal
            ? "completion-column-data no-border"
            : "completion-column-data"
        }
      >
        <div className="completion-cotainer d-flex justify-content-between mt-2">
          <div className="completion-title d-flex">
            <span className="title">
              {t.dashboard.asset_card.asset_location}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"}
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showTooltip}
                textBoxWidth="140px"
                fontSize="10px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="0px 0px 0px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.asset_tooltip.location_asset}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("Location");
                setShowExpandTooltip(false);
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
              {openModal ? null : (
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowExpandTooltip(true)}
                  onMouseLeave={() => setShowExpandTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showExpandTooltip}
                    textBoxWidth="80px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily="Roboto"
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="start"
                    moveRight="5px"
                    moveDown="3px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.expand_full}
                    </span>
                  </Tooltip>
                  {/* <FullArrow /> */}
                </p>
              )}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAddCustomizedTooltip(true)}
              onMouseLeave={() => setShowAddCustomizedTooltip(false)}
              className=" mt-1"
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position="top"
                show={showAddCustomizedTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily="Roboto"
                padding="8px 8px 8px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="0px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.add_customized}
                </span>
              </Tooltip>
              {/* <AddIconDashboard /> */}
            </p>
          </span>
        </div>

        <div
          style={{ padding: "0 20px 20px 20px" }}
          className="completion-status-part"
        >
          <div className="">
            <p
              className="d-flex align-items-center mb-3"
              style={
                selectedLanguage == "en"
                  ? { fontSize: "14px", color: "#44546F", gap: "8px" }
                  : { fontSize: "13px", color: "#44546F", gap: "8px" }
              }
            >
              <span
                className={selectedLanguage == "en" ? "mb-1" : "mt-1"}
                //   className={selectedLanguage == "en" ? "ml-6" : "mr-6"}
              >
                <RedIcon />
              </span>
              <b> {t.dashboard.asset_card.red}:</b>
              {t.dashboard.asset_card.asset500}
              <span
                className={selectedLanguage == "en" ? "mb-1" : "mt-1"}
                //   className={selectedLanguage == "en" ? "ml-6" : "mr-6"}
              >
                <OrangeIcon />
              </span>
              <b> {t.dashboard.asset_card.orange}:</b>{" "}
              {t.dashboard.asset_card.asset250}
              <span
                className={selectedLanguage == "en" ? "mb-1" : "mt-1"}
                //   className={selectedLanguage == "en" ? "ml-6" : "mr-6"}
              >
                <GreenIcon />
              </span>
              <b> {t.dashboard.asset_card.green}:</b>
              {t.dashboard.asset_card.assetless250}
            </p>
          </div>
          <div id="map" style={style}></div>
          {/* <Map
      google={google}
      style={style}
      containerStyle={containerStyle}
      center={defaultCenter}
      initialCenter={defaultCenter}
      zoom={12}
    >
      {assetDashBoardData?.assetsByLocation?.map((el) => {
        return (
          <Marker
            //   onClick={() => {
            //     setTargetedData(el);
            //     setTogglePreviw(true);
            //   }}
            position={{
              lat: el?.mainLocation?.lat,
              lng: el?.mainLocation?.lng,
            }}
            draggable={false}
            name={el?.location}
            // icon={{
            //   url: CustomIcon,
            //   scaledSize: new google.maps.Size(50, 50),
            // }}
            icon={{
              url: CustomIcon,
              scaledSize: new google.maps.Size(50, 50),
            }}
          />
        );
      })}
    </Map> */}
        </div>
      </div>
    </Grid.Column>
  );
};

export default AssetLocation;

// export default AssetLocation
