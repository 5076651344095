import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { selectTranslations } from '../../config/i18n/slice';

const EmptyFilter = () => {
    const t = useSelector(selectTranslations);
  return (
    <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views">
          <div className="list-view-option">
          <p className="single-view">
              <NavLink to="/parts">{t.parts.tab.first}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/sets">
                {t.parts.tab.second}
                </NavLink>
            </p>
          </div>
        </div>
      </div>
  )
}

export default EmptyFilter