import React, { useEffect, useState, forwardRef, useRef } from "react";
import _ from "lodash";
import { Divider, DropdownMenu, Image } from "semantic-ui-react";
import { DownChevron } from "../../../Assets/Icons/svg";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import DatePicker from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const visibleOptions = showMore
      ? props.options
      : props.options.slice(0, 10);
    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onChange={props.onChange}
        title={props.title}
        heading={props.heading}
        icon={props.icon}
        image={props.image}
        visibleOptions={visibleOptions}
        showMore={showMore}
        setShowMore={setShowMore}
        {...props}
      />
    );
  };

  return Component;
}

const AccordionHeader = ({ children, onClick, active }) => (
  <div
    className={`accordion-header ${active ? "active" : ""}`}
    onClick={onClick}
  >
    {children}
    <div className="accordion-icon">
      {active ? <DownChevron /> : <DownChevron />}
    </div>
  </div>
);

const DateDropdown = forwardRef(
  (
    {
      type = "present",
      setPeriodType,
      visibleOptions,
      showMore,
      setShowMore,
      open,
      setOpen,
      options = [],
      filterOptions = [],
      selectedValues = [],
      customSelectedDates = [],

      onChange = () => {},
      setCustomSelectedDates = () => {},
      setFilterOptions = () => {},
      title = "",
      heading = "",
      icon = true,
      image = false,
    },
    ref
  ) => {
    // const [selectedDates, setSelectedDates] = useState([]); // State to store selected dates
    const handleChange = (value) => {
      setFilterOptions({
        ...filterOptions,
        dueDate: [],
      });
      if (value && value.length) {
        const dateValues = value.map((date) => date.valueOf());

        const isoDates = dateValues.map((timestamp) =>
          timestamp ? new Date(timestamp).toISOString() : ""
        );
        setCustomSelectedDates(isoDates);
      }
    };
    const t = useSelector(selectTranslations);
    const selectedLanguage = useSelector((state) => {
      if (state?.i18n?.lang) {
        return state.i18n.lang;
      } else {
        return "en";
      }
    });
    return (
      <>
        <div ref={ref} className="Date-dropdown">
          <UncontrolledDropdown
            className={`${open && "active"}`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)} color="primary">
              {title}{" "}
              <span
                className={
                  type === "preset"
                    ? selectedValues?.length > 0 && selectedValues[0] !== ""
                      ? "filter-count-div"
                      : ""
                    : customSelectedDates?.length > 0
                    ? "filter-count-div"
                    : null
                }
              >
                {type === "preset"
  ? selectedValues?.length > 0 && selectedValues[0] !== ""
    ? selectedValues.length
    :null
    : customSelectedDates?.length > 0
    ? 1
    : null}
                {/* {type === "preset" ? selectedValues?.length > 0 && selectedValues[0] !== ""
                  ? selectedValues?.length
                  : customSelectedDates?.length > 0 ? customSelectedDates?.length : null} */}
              </span>{" "}
              <DownChevron />
            </DropdownToggle>
            <DropdownMenu>
              {visibleOptions.length > 0 ? (
                <>
                  <div className="d-flex date-title justify-content-between ml-6 mr-6 mt-3 c-pointer">
                    <div
                      className={`head-text mb-1 ${
                        type == "preset" ? "blue-text" : ""
                      }`}
                      onClick={() => setPeriodType("preset")}
                    >
                      {t.dashboard.dateOptions.presets}
                    </div>
                    <div
                      className={`head-text mb-1 ${
                        type == "custom" ? "blue-text" : ""
                      }`}
                      onClick={() => setPeriodType("custom")}
                    >
                      {t.dashboard.dateOptions.custom}
                    </div>
                  </div>
                  <Divider className="mb-0 mt-1" />

                  {type === "preset" ? (
                    visibleOptions.map((one) => {
                      return (
                        <>
                          <label
                            tag="label"
                            className="custom-checkbox item"
                            key={one.value}
                          >
                            <div className="checkbox-item">
                              <input
                                type="checkbox"
                                value={one.value}
                                checked={selectedValues.includes(one.value)}
                                onChange={onChange}
                              />
                              <span className="checkmark"></span>
                            </div>
                            {icon ? (
                              one.icon
                            ) : image ? (
                              <Image
                                avatar
                                src={one?.image?.src}
                                style={{ width: "20px", height: "20px" }}
                              />
                            ) : null}
                            <h3 className="">{one.text}</h3>
                          </label>
                        </>
                      );
                    })
                  ) : (
                    <div className="ml-5 mr-5 mt-5 after-map-div">
                      <DatePicker
                        value={customSelectedDates}
                        onChange={handleChange}
                        style={
                          selectedLanguage == "ar" ? { width: "162px" } : {}
                        }
                        showOtherDays={true}
                        autoFocus={true}
                        range
                        showMonthDropdown
                        showYearDropdown
                        placeholder={t.workOrders.filter.date}
                        className="active my-class"
                        weekDays={[
                          t.workOrders.details_view.sun,
                          t.workOrders.details_view.mon,
                          t.workOrders.details_view.tue,
                          t.workOrders.details_view.wed,
                          t.workOrders.details_view.thu,
                          t.workOrders.details_view.fri,
                          t.workOrders.details_view.sat,
                        ]}
                        months={[
                          t.common.january,
                          t.common.february,
                          t.common.march,
                          t.common.april,
                          t.common.may,
                          t.common.june,
                          t.common.july,
                          t.common.august,
                          t.common.september,
                          t.common.october,
                          t.common.november,
                          t.common.december,
                        ]}
                        format="DD/MM/YY"
                      >
                        <button
                          onClick={() => {
                            setCustomSelectedDates([]);
                            // setResetKey((pre) => !pre);
                          }}
                          className="btn-datepicker blue"
                        >
                          {t.common.reset}
                        </button>
                      </DatePicker>
                    </div>
                  )}
                  {/* {selectedValues.includes("date") ?   <div className="after-map-div">
 
</div> : null} */}
                </>
              ) : (
                <p className="no-option-div">{t.common.no_option}</p>
              )}
              {options.length > 7 && (
                <AccordionHeader
                  onClick={() => setShowMore(!showMore)}
                  active={showMore}
                >
                  {showMore ? "Less" : "More"}
                </AccordionHeader>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(DateDropdown);
