import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './Amiri-normal';
// import arabicFont from './TheSansPlain.ttf';
const DownloadPdfFileWithData = (headers = [], data = [], fileName = '', columnWidths = [],fileSize="A4") => {
  const doc = new jsPDF({
    orientation: 'portrait', // or 'landscape'
    unit: 'mm',
    format: fileSize,
  });
  doc.setFont('Amiri-Regular');
  const columns = Object.keys(data[0] || []);
  const rows = data?.map((item) => columns.map((column) => item[column]));

  const columnStyles = {};
  columnWidths.forEach((width, index) => {
    columnStyles[index] = { cellWidth: width };
  });

  doc.autoTable({
    head: [headers.map((header) => header.label)],
    body: rows,
    startY: 20,
    styles: { font: 'Amiri-Regular' },
    columnStyles: columnStyles,
    margin: { top: 20, right: 10, bottom: 0, left: 10 },
  });


  doc.save(`${fileName}.pdf`);
};

export default DownloadPdfFileWithData;
