import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Segment,
  Header,
  Popup,
  TextArea,
  Dropdown,
  Modal,
  Input,
  Form,
  Button,
  Divider,
  Icon,
} from 'semantic-ui-react';
import {
  getUsersByRole,
  nationalities,
  createNewTeam,
  humanize,
} from '../../../config/functions';

function AddTeamModal({ toggled, untoggle, returnAddTeam, title }) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [options, setOptions] = useState({
    supervisors: [],
    users: [],
  });

  const [data, setData] = useState({
    name: title,
    description: '',
    usersLoading: true,
    users: [],
    superior: '',
    usersFetch: [],
  });

  useEffect(() => {
    setData({ ...data, name: title });
  }, [title]);

  const [validation, setValidation] = useState({
    name: {
      error: false,
      pattern: (name) => name != '',
      skipped: false,
      message: 'Field is required',
    },
    users: {
      error: false,
      pattern: (users) => users.length > 0,
      skipped: false,
      message: 'Field is required',
    },

    superior: {
      error: false,
      pattern: (superior) => superior != '',
      skipped: false,
      message: 'Field is required',
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      ...data,
      name: '',
      description: '',
      usersLoading: false,
      users: [],
      superior: '',
    });

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != '',
        skipped: false,
        message: 'Field is required',
      },
      users: {
        error: false,
        pattern: (users) => users.length > 0,
        skipped: false,
        message: 'Field is required',
      },

      superior: {
        error: false,
        pattern: (superior) => superior != '',
        skipped: false,
        message: 'Field is required',
      },
    });
    setInitChange(false);
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = ['users', 'superior'];

    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }
  };

  const fetchSupervisorsData = async () => {
    const fetchData = await getUsersByRole(['supervisor'], organisationId);

    if (fetchData.response) {
      // console.log(fetchData);
      let g_users = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName,
        };
      });
      setOptions({
        ...options,
        supervisors: g_users,
      });
    }
  };

  const fetchUsersData = async () => {
    const fetchData = await getUsersByRole(
      ['user', 'supervisor', 'view_only', 'admin', 'requester'],
      organisationId
    );

    if (fetchData.response) {
      // console.log(fetchData);
      let g_users = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName,
          description: humanize(el.role.name),
        };
      });
      setOptions({
        ...options,
        users: g_users,
      });
    }
  };

  const validationHandler = () => {
    setValidation({
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      users: {
        ...validation.users,
        error: validation.users.pattern(data.users) ? false : true,
        skipped: true,
      },
      superior: {
        ...validation.superior,
        error: validation.superior.pattern(data.superior) ? false : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log(validationToArray);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const createNewTeamHandler = async () => {
    validationHandler();
    if (validForm) {
      setButtonStatus(1);

      const response = await createNewTeam(
        data.name,
        data.description,
        data.users,
        data.superior,
        [],
        organisationId
      );
      // console.log(response);

      if (response.status == 200) {
        setButtonStatus(0);
        untoggle();
        resetForm();
        returnAddTeam(response.data.data.id);
      }
    }
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <Modal
        size="small"
        open={toggled}
        onClose={() => {
          cancelHandler();
        }}
      >
        <Modal.Header>Add Team</Modal.Header>
        <Modal.Content scrolling>
          <Form.Field>
            <label className="label-item">
              Team Name
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Team name"
              value={data.name}
              onChange={onValuesChange('name')}
              error={validation.name.error && validation.name.skipped}
              onBlur={(e) => {
                if (validation.name.pattern(data.name)) {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.name.error && validation.name.skipped ? (
              <div className="label-error">{validation.name.message}</div>
            ) : (
              ''
            )}
          </Form.Field>

          <Form style={{ marginBottom: '20px' }}>
            <Form.Field>
              <label className="label-item">Description</label>
              <TextArea
                autoComplete="new-password"
                value={data.description}
                onChange={onValuesChange('description')}
                rows={2}
                fluid
                placeholder="Describe Team..."
              />
            </Form.Field>
          </Form>

          <Form.Field>
            <label className="label-item">
              Team Superior
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Dropdown
              placeholder="Team Superior"
              fluid
              search
              selection
              multiple
              options={options.supervisors}
              value={data.superior}
              onChange={onValuesChange('superior')}
              onOpen={fetchSupervisorsData}
              error={validation.superior.error && validation.superior.skipped}
              onBlur={(e) => {
                if (validation.superior.pattern(data.superior)) {
                  setValidation({
                    ...validation,
                    superior: {
                      ...validation.superior,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    superior: {
                      ...validation.superior,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.superior.error && validation.superior.skipped ? (
              <div className="label-error">{validation.superior.message}</div>
            ) : (
              ''
            )}
          </Form.Field>

          <Form.Field>
            <label className="label-item">
              Team Members
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Dropdown
              placeholder="Team Members"
              fluid
              search
              selection
              multiple
              options={options.users}
              value={data.users}
              onChange={onValuesChange('users')}
              onOpen={fetchUsersData}
              error={validation.users.error && validation.users.skipped}
              onBlur={(e) => {
                if (validation.users.pattern(data.users)) {
                  setValidation({
                    ...validation,
                    users: {
                      ...validation.users,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    users: {
                      ...validation.users,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.users.error && validation.users.skipped ? (
              <div className="label-error">{validation.users.message}</div>
            ) : (
              ''
            )}
          </Form.Field>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Submit"
            primary
            onClick={createNewTeamHandler}
            loading={buttonStatus == 1}
          />

          <Button
            content="Cancel"
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          setSuccessModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              Team - {responseData.name} added successfully!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              setSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddTeamModal;
