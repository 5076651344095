import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Input } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import { createCategory } from '../../../config/functions';
import { toast } from 'react-toastify';
import { CloseIcon } from '../../../../Assets/Icons/svg';
import Asterisk from '../../common/Asterisk';
function CategoryCreateModal({
  isOpen = false,
  setModalOpenState = () => {},
  handleSuccess = () => {},
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const [categoryName, setCategoryName] = useState('');
  const handleCategoryCreate = async () => {
    if (categoryName && categoryName.trim()) {
      let res = await createCategory(
        categoryName.trim(),
        organisationId,
        currentUserId
      );
      if (res && res.status === 200) {
        handleSuccess(res);
        toast.success(t.categories.category_create_success);
        setModalOpenState(false);
      } else if (res && res.status === 400) {
        toast.error(t.categories.category_already_exist);
        setModalOpenState(false);
      } else {
        toast.error(t.categories.category_not_created);
        setModalOpenState(false);
      }
      setCategoryName('');
    } else {
      toast.error(t.categories.add_category_name);
      setCategoryName('');
    }
  };
  return (
    <Modal
      size="tiny"
      style={{ height: '216px' }}
      className="assets-modal"
      open={isOpen}
      onClose={() => setModalOpenState(false)}
    >
      <div className="header-container">
        <span className="assets-header">
          {t.categories.add_a_category_name}
        </span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => setModalOpenState(false)}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content className="">
        <label className="assets-label" style={{ lineHeight: '22px' }}>
          {t.categories.category_name}
        </label>
        <span className="label-item d-inline">
          {' '}
          <Asterisk color="#172B4D" />
        </span>
        <div className="category-input">
          <Input
            autoComplete="new-password"
            fluid
            placeholder={t.categories.add_category_name}
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>
      </Modal.Content>

      <div
        className={`modal-button-container mt-4 ${
          selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
        }`}
      >
        <button
          className="modal-form-action-button black"
          onClick={() => setModalOpenState(false)}
        >
          {t.assets.form.cancel}
        </button>

        <button
          onClick={() => handleCategoryCreate()}
          className="modal-form-action-button light-blue"
        >
          {t.common.add}
        </button>
      </div>
      {/* <Modal.Actions>
        <Button color="black" onClick={() => handleCategoryCreate()}>
          {t.common.add}
        </Button>
        <Button negative onClick={() => setModalOpenState(false)}>
          {t.common.cancel}
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
}

export default CategoryCreateModal;
