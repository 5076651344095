import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Input } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import { createCategory } from '../../../config/functions';
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';

function JobtitleBulkUploadResult({
  isOpen = false,
  setModalOpenState = () => {},
  data = [],
}) {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  /**
   * Table Fields
   */
  const columns = [
    {
      name: t.jobTitle.add,
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: t.categories.status,
      selector: (row) => row.status,
      sortable: true,
      cell: (row) =>
        row?.status && row.status == 'success' ? (
          <span className="text-success">{t.categories.success}</span>
        ) : (
          <span className="text-danger">{t.categories.failed}</span>
        ),
    },
    {
      name: t.categories.error,
      selector: (row) => row?.error,
      sortable: true,
      cell: (row) =>
        row?.error && row.error === 'Job title attribute must be unique'
          ? t.jobTitle.job_title_already_exist
          : '',
    },
  ];
  return (
    <Modal
      size="small"
      open={isOpen}
      onClose={() => setModalOpenState(false)}
      className={selectedLanguage === 'en' ? '' : 'category-bulk-result-modal'}
    >
      <Modal.Header
        className={selectedLanguage === 'en' ? 'text-left' : 'text-right'}
      >
        {t.jobTitle.title}
      </Modal.Header>
      <Modal.Content>
        <DataTable
          columns={columns}
          data={data}
          noDataComponent={t.common.no_result_found}
          direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
        />
      </Modal.Content>
      <Modal.Actions
        class={selectedLanguage === 'en' ? 'text-left' : 'text-right'}
      >
        <Button onClick={() => setModalOpenState(false)}>{t.common.ok}</Button>
      </Modal.Actions>
    </Modal>
  );
}

export default JobtitleBulkUploadResult;
