/* eslint-disable no-invalid-this */
// import ReactDOM from 'react-dom';
import React, { PureComponent } from 'react';
// import ReactCrop from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
import SimpleBar from 'simplebar-react';
import { Label, Modal, Button } from 'semantic-ui-react';
// import './App.css'
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const defaultSrc =
  'https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg';

class ImageCrop extends PureComponent {
  state = {
    // crop: {
    //   unit: '%',
    //   width: 95,
    //   aspect: this.props.ratio,
    // },
    basicModal: true,
    cropData: '',
    cropper: null,
  };

  //   // If you setState the crop in here you should return false.
  //   onImageLoaded = (image) => {
  //     this.imageRef = image;
  //   };

  //   onCropComplete = (crop) => {
  //     this.makeClientCrop(crop);
  //   };

  //   onCropChange = (crop, percentCrop) => {
  //     // You could also use percentCrop:
  //     // this.setState({ crop: percentCrop });
  //     this.setState({ crop });
  //   };

  //   async makeClientCrop(crop) {
  //     if (this.imageRef && crop.width && crop.height) {
  //       const croppedImageUrl = await this.getCroppedImg(
  //         this.imageRef,
  //         crop,
  //         this.props.fileData.name
  //       );
  //       this.setState({ croppedImageUrl });
  //     }
  //   }

  //   getCroppedImg(image, crop, fileName) {
  //     const canvas = document.createElement('canvas');
  //     const scaleX = image.naturalWidth / image.width;
  //     const scaleY = image.naturalHeight / image.height;
  //     canvas.width = crop.width;
  //     canvas.height = crop.height;
  //     const ctx = canvas.getContext('2d');

  //     ctx.drawImage(
  //       image,
  //       crop.x * scaleX,
  //       crop.y * scaleY,
  //       crop.width * scaleX,
  //       crop.height * scaleY,
  //       0,
  //       0,
  //       crop.width,
  //       crop.height
  //     );

  //     return new Promise((resolve, reject) => {
  //       canvas.toBlob((blob) => {
  //         if (!blob) {
  //           //reject(new Error('Canvas is empty'));
  //           console.error('Canvas is empty');
  //           return;
  //         }
  //         blob.name = fileName;
  //         // window.URL.revokeObjectURL(this.fileUrl);
  //         // this.fileUrl = window.URL.createObjectURL(blob);
  //         resolve(blob);
  //       }, this.props.fileData.name.type);

  //       // const base64Canvas = canvas.toDataURL(this.props.fileData.type);
  //       // resolve(base64Canvas);
  //     });
  //   }
  //   getSnapshotBeforeUpdate(prevProps, prevState) {
  //     // Are we adding new items to the list?
  //     // Capture the scroll position so we can adjust scroll later.
  //     if (prevProps.src !== this.props.src) {
  //       this.setState({ basicModal: true });
  //     }
  //     return null;
  //   }

  getCropData = () => {
    if (typeof this.state.cropper !== 'undefined') {
      this.setState({
        cropData: this.state.cropper.getCroppedCanvas().toDataURL(),
      });
      //   this.props.onAccept(this.state.cropper.getCroppedCanvas().toDataURL());
      this.state.cropper.getCroppedCanvas().toBlob((blob) => {
        this.props.onAccept(blob);
      });
    }
  };

  setCropper = (instance) => {
    this.setState({ cropper: instance });
  };

  render() {
    const { croppedImageUrl, basicModal } = this.state;
    const { src, onCancel, ratio, minWidth = 10, minHeight = 10 } = this.props;

    return (
      <>
        <div className="search-head-icon">
          <Modal open={basicModal} className="" onClose={() => onCancel()}>
            <Modal.Content>
              <Label for="clogo" className={`custom-label`}>
                Crop Image
              </Label>
              <SimpleBar className="img-crop-wrapper-scroll">
                <div className="img-crop-wrapper opacity-100">
                  <Cropper
                    style={{ height: '100%', width: '100%' }}
                    zoomTo={0}
                    initialAspectRatio={ratio || 16 / 8}
                    aspectRatio={ratio ? ratio : ''}
                    //   preview='.img-preview'
                    src={src}
                    viewMode={1}
                    minCropBoxHeight={minWidth}
                    minCropBoxWidth={minHeight}
                    background={false}
                    responsive={false}
                    autoCropArea={1}
                    // zoomable={false}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => {
                      this.setCropper(instance);
                    }}
                    guides={true}
                  />
                </div>
              </SimpleBar>
              {/* <p className="text-left text-danger mt-3 mr-auto">
                
              </p> */}
            </Modal.Content>
            <Modal.Actions>
              <Button type="button" onClick={() => onCancel()}>
                Cancel
              </Button>
              <Button
                primary
                type="submit"
                onClick={() => {
                  // setCroppedSrc(croppedImageUrl)
                  // setSrc('')
                  // setFieldValue(`${fieldName}`, croppedImageUrl)
                  this.setState({ basicModal: !basicModal });
                  // this.props.addImgClass(true)
                  //   this.props.onAccept(croppedImageUrl);
                  this.getCropData();
                }}
              >
                Upload
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </>
    );
  }
}
export default ImageCrop;
