export default {
  title: "Work Requests",
  title_small: "work requests",
  work_request: "Work Request",
  work_request_small: "work request",
  create_WR:"Add Work Request",
  edit_WR:"Edit Work Request",
  tabs: {
    first: "New",
    second: "History",
  },
  add: "Order",
  filter: {
    search: "Search",
    assignees: "Assignees",
    locations: "Locations",
    dueDate: "Due Date",
    status: "Status",
    priority: "Priority",
    createdByUser: "Created By",
  },
  status:{
  approved: "Approved",
  declined:"Declined",
  pending : "Pending",
  
  },

  
  table: {
    title: "Title",
    wr_status:"WR Status",
    wo_status:"WO Status",
    asset:"Asset",
    wo_id:"WO ID",
    sub_date:"Submission Date",
    sub_by:"Submitted By",
    ID: "ID",
    status: "Status",
    priority: "Priority",
    assignees: "Assignees",
    category: "Category",
    customer: "Customer",
    location: "Location",
    dueDate: "Due Date",
    procedure: "Procedure",
    createdByUser: "Created By",
    createdAt: "Created At",
    updatedBy: "Updated At",

  },

  form:{
    add_wr:"Add Work Request",
    add_title:"Add work request title",
    description:"Description",
    add_description:"Add description",
    priority:"Priority",
    location:"Location",
    add_location:"Select location",
    assets:"Assets",
    add_assets:"Select assets",
    people:"People",
    add_people:"Select people",
    teams:"Teams",
    add_teams:"Select teams",
    add_priority:"Select priority",
    attchfile:"Attach a file to this work request",
    drag:"Drag & Drop or",
    choose:"Choose image",
    upload:"to upload",
    update_success:"Work Request updated successfully",
    approved_success:"approved successfully",
    delete_success:"Work Request deleted successfully",
    approved_statement:"This Work Request was approved and turned into a",
    declined_statement:"This Work Request has been declined",
    submitted:"submitted",
    this_wr:"this Work Request",
    unknown:"Unknown Person",
    decline_wr:"Decline Work Request",
    decline_wr_statement:"Are you sure you want to decline this work request?",
    decline_success:"declined successfully",
    cancle_statement:"Are you sure you want to cancel this work request?",
    delete_related_error:"This Work Request cannot be deleted"
  },  
  how_to_add_wr:"How to add work request",
  no_wr_added : "Start adding work requests",
  created_this : "Created this",
  approved_this : "Approved this",
  declined_this : 'Declined this',
};
