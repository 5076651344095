import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllCategories } from '../../app/config/functions';
const initialState = {
  data: [], // Initial empty array for category data
  isLoading: false,
  initLoading: false,
  error: null, //
  initData: [], //
  page: 1, //
  pageSize: 25, //
  total: 25, //
};

/*
 * Fetch categoty data
 */
export const fetchCategories = createAsyncThunk(
  'category/list',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllCategories(organisationId);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch initial categoty data
 */
export const fetchCategoriesInitData = createAsyncThunk(
  'category/initData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllCategories(organisationId, '', 1, 25);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all categoty data
 */
export const fetchAllCategoryData = createAsyncThunk(
  'category/allData',
  async (organisationId, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllCategories(organisationId, '', 1, 99999);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter categoty data
 */
export const fetchCategoriesWithFilter = createAsyncThunk(
  'category/filter',
  async (
    { organisationId, searchName, page, pageSize, sort },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllCategories(
        organisationId,
        searchName,
        page ,
        pageSize,
        sort
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setInitData: (state, action) => {
      state.initData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.initData = action.payload.response;
        state.data = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategoriesInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchCategoriesInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.initData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchCategoriesInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCategoriesWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCategoriesWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
        if (state.initData && state.initData.length <= 0) {
          state.initData = action.payload.response;
        }
      })
      .addCase(fetchCategoriesWithFilter.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllCategoryData.pending, (state) => {})
      .addCase(fetchAllCategoryData.fulfilled, (state, action) => {})
      .addCase(fetchAllCategoryData.rejected, (state, action) => {});
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = categorySlice.actions;

export const selectCategoryData = (state) => state.category.data;
export const selectCategoryLoading = (state) => state.category.isLoading;

export default categorySlice.reducer;
