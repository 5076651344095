import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import { updateUserNotificationToken } from '../../../config/functions';
import * as auth from '../_redux/authRedux';

class Logout extends Component {
  componentDidMount = async () => {
    if (this.props.userId) {
      alert('here');
      await updateUserNotificationToken(this.props.userId, '');
    }
    this.props.logout();
  };

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? (
      <LayoutSplashScreen />
    ) : (
      <Redirect to="/auth/login" />
    );
  }
}

const mapStateToProps = (state) => {
  let { authToken, user } = state.auth;
  return {
    hasAuthToken: Boolean(authToken),
    userId: user?.id,
  };
};

export default connect(mapStateToProps, auth.actions)(Logout);
