import React, { useEffect, useState, forwardRef, useRef } from 'react';
import _ from 'lodash';
import { DropdownMenu, Image, Input } from 'semantic-ui-react';
import { DownChevron, SearchIcon } from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onChange={props.onChange}
        title={props.title}
        icon={props.icon}
        image={props.image}
        issearch={props.issearch}
        procedure= {props.procedure}
        {...props}
        searchValue={props.searchValue}
        handleSearchValue={props.handleSearchValue}
      />
    );
  };

  return Component;
}

const CategoryDropdown = forwardRef(
  (
    {
      open,
      setOpen,
      options = [],
      selectedValues = [],
      onChange = () => {},
      heading = '',
      title = '',
      icon = true,
      image = false,
      searchValue = '',
      issearch = false,
      handleSearchValue = () => {},
      procedure= false,
    },
    ref
  ) => {

    const selectedLanguage = useSelector((state) => {
      if (state?.i18n?.lang) {
        return state.i18n.lang;
      } else {
        return 'en';
      }
    });
    const t = useSelector(selectTranslations);
    return (
      <>

        <div ref={ref} className={`Category-dropdown`}>
          {heading ? <div className="title-text mb-1">{heading}</div> : null}

          <UncontrolledDropdown
            className={`${open && 'active'} ${(procedure && selectedValues.length > 0 && selectedValues[0] !== "all") || (!procedure && selectedValues.length > 0 &&  selectedValues[0] !== "")  ? 'blue-border' : ''}`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)} color="primary">
              {title} <span className={selectedValues?.length > 0 && selectedValues[0] !== "" ?'filter-count-div' : ""}>{selectedValues?.length > 0 && selectedValues[0] !== "" ? selectedValues?.length  : null}</span> <DownChevron />
            </DropdownToggle>
            <DropdownMenu className={`${procedure && selectedLanguage == "en"  ? "right-dropdown" :procedure && selectedLanguage == "ar" ? "left-dropdown" : "" }`}>
              {issearch ? (
                <div className={`table-searchbar m-3 ${selectedLanguage == "en" ? "" : "rtl-searchbar"}`}>
                  <SearchIcon />
                  <Input
                    autoComplete="new-password"
                    iconPosition="left"
                    value={searchValue}
                    onChange={handleSearchValue}
                    className="dropdown-search-input"
                  />
                </div>
              ) : null}

              {options.length > 0 ? (
                options.map((one) => {
                  return (
                    <label
                      tag="label"
                      className="custom-checkbox item"
                      key={one.value}
                    >
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          value={one.value}
                          checked={selectedValues.includes(one.value)}
                          onChange={onChange}
                        />
                        <span class="checkmark"></span>
                      </div>
                      {icon ? (
                        one.icon
                      ) : image ? (
                        <Image
                          avatar
                          // title={one?.image?.src}
                          src={one?.image?.src}
                          style={{ width: '20px', height: '20px' }}
                        />
                      ) : null}
                      <h3 className="">{one.text}</h3>
                    </label>
                  );
                })
              ) : (
                <p className="no-option-div">{t.common.no_option}</p>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(CategoryDropdown);
