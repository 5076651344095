export default {
  title: "Time Tracking",
  add: "Attendance",
  filter: {
    search: "Search",
    role: "Role",
    shifts: "Shifts",
  },
  table: {
    name: "Name",
    ID: "ID",
    role: "Role",
    shifts: "Shifts",
    workingHours: "Working Hours",
  },
};
