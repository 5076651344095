import React, { useState, useEffect } from 'react';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Icon, Grid, Form, Input } from 'semantic-ui-react';
import { selectTranslations } from '../../config/i18n/slice';

import { GMAPAPIKEY } from '../../config/functions';
import { useSelector } from 'react-redux';

const MapWrapper = ({ google, locationName, submitMarker, mainLocation }) => {
  const t = useSelector(selectTranslations);

  const [defaultCenter, setDefaultCenter] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });

  const [markerPosition, setMarkerPosition] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });

  useEffect(() => {
    // console.log("mainLocation", mainLocation);
    if (mainLocation?.id > 0) {
      // console.log(mainLocation);
      setDefaultCenter({
        lat: mainLocation.lat,
        lng: mainLocation.lng,
      });
      setMarkerPosition({
        lat: mainLocation.lat,
        lng: mainLocation.lng,
      });
      submitMarker({
        lat: mainLocation.lat,
        lng: mainLocation.lng,
      });
    } else {
      submitMarker({
        lat: 32.0503808,
        lng: 35.8776832,
      });
    }
  }, [mainLocation]);

  const style = {
    width: '100%',
    height: '100%',
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '400px',
  };

  const onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();

    setMarkerPosition({ lat, lng });
    submitMarker({ lat, lng });
  };

  const handleChange = (name) => (event) => {
    setMarkerPosition({
      ...markerPosition,
      [name]: event.target.value,
    });
    submitMarker({
      ...markerPosition,
      [name]: event.target.value,
    });
  };
  return (
    // Important! Always set the container height explicitly
    <>
      <Grid columns="equal" style={{ marginBottom: '10px' }}>
        <Grid.Column>
          <Form.Field>
            <label className="label-item">{t.locations.latitude}</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.locations.latitude}
              type="number"
              value={markerPosition.lat}
              onChange={handleChange('lat')}
            />
          </Form.Field>
        </Grid.Column>

        <Grid.Column>
          <Form.Field>
            <label className="label-item">{t.locations.longitude}</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.locations.longitude}
              type="number"
              value={markerPosition.lng}
              onChange={handleChange('lng')}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
      <Map
        google={google}
        style={style}
        containerStyle={containerStyle}
        center={defaultCenter}
        initialCenter={defaultCenter}
        zoom={12}
      >
        <Marker
          position={markerPosition}
          draggable={true}
          onDragend={(t, map, coord) => onMarkerDragEnd(coord)}
          name={locationName}
        />
      </Map>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: GMAPAPIKEY,
})(MapWrapper);
