export default {
  title: 'مرحباً بك في كويك فلو',
  subtitle:"قم بتبسيط عمليات الصيانة والسلامة والعمليات لفرق الخطوط الأمامية، مما يعزز من وقت تشغيل المعدات من خلال ربط الأشخاص والبيانات بكفاءة عالية",
 
  copyright: 'حقوق الطبع والنشر',
  quiqflow: 'كويك فلو',
  all_rights_reserved: 'جميع الحقوق محفوظة ',
  login_form: {
    title: 'تسجيل الدخول إلى الحساب',
    description: 'أدخل بريدك الإلكتروني وكلمة المرور',
    email_placeholder: 'البريد الإلكتروني',
    password_placeholder: 'كلمة المرور',
    forget_password_link: 'هل نسيت كلمة السر؟',
    signin_button: 'تسجيل الدخول',
    password: 'أدخل كلمة المرور',
    email: 'أدخل البريد الإلكتروني',
  },
  forget_password_form: {
    invalid_email: 'يرجى إدخال عنوان بريد إلكتروني صالح',
    title: 'هل نسيت كلمة السر؟',
    description:
      'الرجاء إدخال بريدك الإلكتروني لمساعدتك في إعادة تعيين كلمة المرور الخاصة بك مرة أخرى',
    email_placeholder: 'البريد الإلكتروني',
    submit_button: 'إرسال',
    cancle_button: 'إلغاء',
    send_button: 'إرسال الرابط',
  },
  reset_password_form: {
    title: 'إعادة تعيين كلمة المرور',
    description: 'أدخل كلمة المرور الجديدة وقم بتأكيدها',
    new_password_placeholder: 'أدخل كلمة المرور الجديدة',
    confirm_password_placeholder: 'تأكيد كلمة المرور الجديدة',
    confirm_button: 'تأكيد كلمة المرور',
    reset_link_expired: 'رابط إنشاء كلمة المرور منتهي الصلاحية',
    new_password: 'كلمة المرور الجديدة',
    reset_success_password: 'تم إعادة ضبط كلمة المرور بنجاح',
    enter_new_password: 'أدخل كلمة المرور الجديدة',
  },
  login_message: {
    organisation_disabled: 'تم تعطيل المنظمة. لا يمكن تسجيل الدخول',
    login_fail: 'معلومات تسجيل الدخول غير صحيحة',
  },
  create_password_form: {
    title: 'كلمة المرور',
    title_password: 'من فضلك أدخل كلمة المرور الخاصة بك',
    description: 'أدخل كلمة المرور الجديدة وقم بتأكيدها',
    new_password_placeholder: 'أدخل كلمة المرور الجديدة',
    confirm_password_placeholder: 'تأكيد كلمة المرور',
    enter_placeholder: 'أدخل كلمة المرور',
    continue: 'استمرار',
    confirm_button: 'تأكيد كلمة المرور',
    create_link_expired: 'رابط إنشاء كلمة المرور قد تم استخدامه',
    accept_term_statement: 'الرجاء قبول سياسة الخصوصية والشروط والأحكام.',
    accept_term_error: 'يرجى قبول سياسة الخصوصية والشروط والأحكام',
    i_accept: 'لقد قرأت وقبلت',
    privacy: 'سياسة الخصوصية',
    end_the: 'و',
    terms: 'الشروط والأحكام',
    and: 'و',
    success: 'تم إنشاء كلمة المرور بنجاح',
  },
};
