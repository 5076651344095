import { FormatListBulletedOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
export const usePermissionsSimplified = () => {
  const modules = [
    'work-order',
    'dashboard',
    'purchase-order',
    'work-request',
    'procedure',
    'asset',
    'part',
    'customer',
    'vendor',
    'people',
    'team',
    'location',
    'category',
    'companySettings',
    'jobTitle',
    'timesheet',
  ];
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const checkForPermission = (createdByUser) => {
    if (createdByUser == currentUserId && ['user'].includes(role)) {
      return true;
    } else {
      return false;
    }
  };
  const checkForLinkPermission = (module) => {
    if (permissions?.[module].view) {
      return true;
    } else {
      return false;
    }
  };

  const checkForWRPermission = (createdByUser) => {
    if (
      createdByUser == currentUserId &&
      ['user', 'view_only', 'requester'].includes(role)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const permissions = {};
  for (let i = 0; i < modules.length; i++) {
    permissions[modules[i]] = {
      create: true, 
      view: false,
      edit: true,
      delete: true,
      assign: true,
      statusToggle: true,
      restock: true,
    };
  }

  const organisationModule = useSelector((state) => {
    if (state?.auth?.organisation) {
      return state?.auth?.organisation.allowed_modules;
    } else {
      return [];
    }
  });

  organisationModule.forEach((i) => {
    if (i.available) {
      switch (i.module) {
        case 'purchase order':
          permissions['purchase-order'] = {
            ...permissions['purchase-order'],
            view: true,
          };
          break;
        case 'work request':
          permissions['work-request'] = {
            ...permissions['work-request'],
            view: true,
          };
          break;
          case 'dashboard':
            permissions['dashboard'] = {
              ...permissions['dashboard'],
              view: true,
            };
            break;
        case 'assets':
          permissions['asset'] = {
            ...permissions['asset'],
            view: true,
          };
          break;
        case 'customer/vendor':
          permissions['customer'] = {
            ...permissions['customer'],
            view: true,
          };
          permissions['vendor'] = {
            ...permissions['vendor'],
            view: true,
          };
          break;
        case 'part inventory':
          permissions['part'] = {
            ...permissions['part'],
            view: true,
          };
          break;
        case 'procedure library':
          permissions['procedure'] = {
            ...permissions['procedure'],
            view: true,
          };
          break;
        default:
          break;
      }
    }
  });

  const alwaysViewModules = ['work-order', 'people', 'team', 'location','category','jobTitle']; // List modules for which view should always be true

alwaysViewModules.forEach((module) => {
  if (permissions[module]) {
    permissions[module].view = true; // Force view permission to true
  }
});

  switch (role) {

    case 'admin':
      permissions['asset'] = {
        ...permissions['asset'],
        // delete: false,
      }; permissions['part'] = {
        ...permissions['part'],
        // delete: false,
      };
      permissions['procedure'] = {
        ...permissions['procedure'],
        // delete: false,
      }; permissions['dashboard'] = {
        ...permissions['dashboard'],
        // delete: false,
      };

      permissions['timesheet'] = {
        ...permissions['timesheet'],
        // delete: false,
      };
      permissions['location'] = {
        ...permissions['location'],
        // delete: false,
      };
      permissions['people'] = {
        ...permissions['people'],
        // delete: false,
      };
      permissions['team'] = {
        ...permissions['team'],
        // delete: false,
      };
      permissions['vendor'] = {
        ...permissions['vendor'],
        // delete: false,
      };
      permissions['customer'] = {
        ...permissions['customer'],
        // delete: false,
      };
      permissions['category'] = {
        ...permissions['category'],
        // delete: false,
      };
      permissions['jobTitle'] = {
        ...permissions['jobTitle'],
        // delete: false,
      };
      permissions['companySettings'] = {
        ...permissions['companySettings'],
        edit: false,
        view: true,
      };
      permissions['work-request'] = {
        ...permissions['work-request'],
        // delete: false,
        // view: true,
      };
      permissions['purchase-order'] = {
        ...permissions['purchase-order'],
        // delete: false,
        // view: true,
      };
      permissions['work-order'] = {
        ...permissions['purchase-order'],
        // delete: false,
      };
      break;
    case 'limited_admin':
      permissions['asset'] = {
        ...permissions['asset'],
        delete: false,
      }; permissions['part'] = {
        ...permissions['part'],
        // delete: false,
      };
      permissions['procedure'] = {
        ...permissions['procedure'],
        // delete: false,
      };    permissions['dashboard'] = {
        ...permissions['dashboard'],
        delete: false,
      };

      permissions['timesheet'] = {
        ...permissions['timesheet'],
        delete: false,
      };
      permissions['location'] = {
        ...permissions['location'],
        delete: false,
      };
      permissions['people'] = {
        ...permissions['people'],
        delete: false,
      };
      permissions['team'] = {
        ...permissions['team'],
        delete: false,
      };
      permissions['vendor'] = {
        ...permissions['vendor'],
        delete: false,
      };
      permissions['customer'] = {
        ...permissions['customer'],
        delete: false,
      };
      permissions['category'] = {
        ...permissions['category'],
        delete: false,
      };
      permissions['jobTitle'] = {
        ...permissions['jobTitle'],
        delete: false,
      };
      permissions['companySettings'] = {
        ...permissions['companySettings'],
        edit: false,
        view: true,
      };
      permissions['work-request'] = {
        ...permissions['work-request'],
        delete: false,
        // view: true,
      };
      permissions['purchase-order'] = {
        ...permissions['purchase-order'],
        delete: false,
        // view: true,
      };
      permissions['work-order'] = {
        ...permissions['work-order'],
        // delete: false,
      };
      break;

    case 'super_admin':
      // permissions['work-request'] = {
      //   ...permissions['work-request'],
      //   view: true,
      // };
      // permissions['timesheet'] = {
      //   ...permissions['timesheet'],
      //   view: true,
      // };
      // permissions['location'] = {
      //   ...permissions['location'],
      //   view: true,
      // };
      // permissions['people'] = {
      //   ...permissions['people'],
      //   view: true,
      // };
      // permissions['team'] = {
      //   ...permissions['team'],
      //   view: true,
      // };
      // permissions['vendor'] = {
      //   ...permissions['vendor'],
      //   view: true,
      // };
      // permissions['customer'] = {
      //   ...permissions['customer'],
      //   view: true,
      // };
      // permissions['category'] = {
      //   ...permissions['category'],
      //   view: true,
      // };
      // permissions['jobTitle'] = {
      //   ...permissions['jobTitle'],
      //   view: true,
      // };
      permissions['companySettings'] = {
        ...permissions['companySettings'],
        view: true,
      };
      break;

    case 'view_only':
      permissions['work-order'] = {
        ...permissions['work-order'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['purchase-order'] = {
        ...permissions['purchase-order'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
        restock: false,
      }; permissions['dashboard'] = {
        ...permissions['dashboard'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
        restock: false,
      };
      permissions['work-request'] = {
        ...permissions['work-request'],
        create: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['timesheet'] = {
        ...permissions['timesheet'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['asset'] = {
        ...permissions['asset'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
        statusToggle: false,
      };
      permissions['part'] = {
        ...permissions['part'],
        restock: false,
        create: false,
        edit: false,
        delete: false,
        assign: false,
        view: true,
      };
      permissions['procedure'] = {
        ...permissions['procedure'],

        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['location'] = {
        ...permissions['location'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['people'] = {
        ...permissions['people'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
        statusToggle: false,
      };
      permissions['team'] = {
        ...permissions['team'],
        create: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['vendor'] = {
        ...permissions['vendor'],

        create: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['customer'] = {
        ...permissions['customer'],

        create: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['category'] = {
        ...permissions['category'],

        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['jobTitle'] = {
        ...permissions['jobTitle'],

        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['companySettings'] = {
        ...permissions['companySettings'],
        create: false,
        // view: false,
        view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      break;

    case 'requester':
      permissions['work-order'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['work-request'] = {
        create: true,
        edit: false,
        view: true,
        delete: false,
        assign: false,
      }; permissions['dashboard'] = {
        create: true,
        edit: false,
        view: false,
        delete: false,
        assign: false,
      };
      permissions['purchase-order'] = {
        ...permissions['purchase-order'],
        create: false,
        edit: false,
        view: false,
        delete: false,
        assign: false,
        restock: false,
      };
      permissions['timesheet'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['asset'] = {
        ...permissions['asset'],
        create: false,
        view: true,
        edit: false,
        delete: false,
        assign: false,
        statusToggle: false,
      };
      permissions['part'] = {
        ...permissions['part'],
        create: false,
        view: true,
        edit: false,
        delete: false,
        assign: false,
        restock: false,
      };
      permissions['procedure'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['location'] = {
        ...permissions['location'],

        create: false,
        view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['people'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
        statusToggle: false,
      };

      permissions['team'] = {
        ...permissions['team'],

        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['vendor'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['customer'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['category'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['jobTitle'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['companySettings'] = {
        create: false,
        // view: false,
        view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      break;
    case 'user':
      permissions['work-order'] = {
        ...permissions['work-order'],
        create: true,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['purchase-order'] = {
        ...permissions['purchase-order'],
        create: false,
        edit: false,
        view: false,
        delete: false,
        assign: false,
        restock: false,
      }; permissions['dashboard'] = {
        ...permissions['dashboard'],
        create: false,
        edit: false,
        view: false,
        delete: false,
        assign: false,
        restock: false,
      };
      permissions['work-request'] = {
        ...permissions['work-request'],
        create: true,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['timesheet'] = {
        ...permissions['timesheet'],
        create: true,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['asset'] = {
        ...permissions['asset'],

        create: true,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
        statusToggle: false,
      };
      permissions['part'] = {
        ...permissions['part'],

        create: true,
        edit: false,
        delete: false,
        assign: false,
        // restock: false,
      };
      permissions['procedure'] = {
        ...permissions['procedure'],
        create: true,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['location'] = {
        ...permissions['location'],
        create: false,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['people'] = {
        ...permissions['people'],
        create: false,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
        statusToggle: false,
      };
      permissions['team'] = {
        ...permissions['team'],
        create: false,
        // view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['vendor'] = {
        ...permissions['vendor'],
        create: true,
        // view: true,
        edit: true,
        delete: false,
        assign: false,
      };
      permissions['customer'] = {
        ...permissions['customer'],
        create: true,
        // view: true,
        edit: true,
        delete: false,
        assign: false,
      };
      permissions['category'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['jobTitle'] = {
        create: false,
        view: false,
        edit: false,
        delete: false,
        assign: false,
      };
      permissions['companySettings'] = {
        create: false,
        // view: false,
        view: true,
        edit: false,
        delete: false,
        assign: false,
      };
      break;
  }

  return {
    userPermissions: permissions,
    checkForLinkPermission,
    checkForPermission,
    checkForWRPermission,
  };
};
