import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllVendros } from '../../app/config/functions';
const initialState = {
  vendorData: [],
  allVendorData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  vendorInitData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch initial vendor data
 */
export const fetchVendorInitData = createAsyncThunk(
  'vendor/initData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllVendros(organisationId, '', 1, 25,[],[],[],[],role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all vendor data
 */
export const fetchAllVendorData = createAsyncThunk(
  'vendor/allData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllVendros(organisationId, '', 1, 99999,[],[],[],[],role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter vendor data
 */
export const fetchVendorWithFilter = createAsyncThunk(
  'vendor/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      location = [],
      startDate = [],
      endDate = [],
      sort = [],
      role='',
      allIds=[]
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllVendros(
        organisationId,
        searchName,
        page,
        pageSize,
        location,
        startDate,
        endDate,
        sort,
        role,
        allIds
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const vendorSlice = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.teamsData = action.payload;
    },
    setInitData: (state, action) => {
      state.teamsInitData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchVendorInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchVendorInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.vendorInitData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchVendorInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchVendorWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchVendorWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vendorData = action.payload.response;
        state.allVendorData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
        
      })
      .addCase(fetchVendorWithFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllVendorData.pending, (state) => {})
      .addCase(fetchAllVendorData.fulfilled, (state, action) => {
        state.allVendorData = action.payload.response;
      })
      .addCase(fetchAllVendorData.rejected, (state, action) => {});
  },
});

export const {
  setData,
  setLoading,
  setError,
  setInitData,
} = vendorSlice.actions;

export default vendorSlice.reducer;
