import React, { useEffect, useState, createRef } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
} from 'semantic-ui-react';

import Pagination from '../../../components/common/Pagination';

import Moment from 'react-moment';

import DataTable from 'react-data-table-component';

import moment from 'moment';

import { NavLink } from 'react-router-dom';

import { useLocation, useHistory } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../../_metronic/_helpers';

import {
  getAllAssets,
  getAllTimesheet,
  getLocationByID,
  humanize,
  IMAGES_URL,
} from '../../../config/functions';

// import Add from './add';
// import Edit from './edit';

// import Preview from './preview/index';

// import md5 from 'md5';

import useGetCurrentTimeAccZone from '../../../../hooks/useGetCurrentTimeAccZone';
import Tooltip from 'react-power-tooltip';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../../components/common/userProfilePlaceholder';
import DateConvert from '../../../../utilFunctions/DateFormat';
import {
  AddIcon,
  ArrowLeft,
  ArrowRight,
  AssetIcon,
  ProcedurePlusIcon,
  TimesheetBlue,
  TimesheetIcon,
} from '../../../../Assets/Icons/svg';
import useDisplayFormattedText from '../../../../hooks/useDisplayFormattedText';
import Filters from './filter';
import { toast } from 'react-toastify';
// import ReactTooltip from 'react-tooltip';

export const TimesheetTeams = () => {
  const { userPermissions } = usePermissionsSimplified();
  const { organisationTimezone } = useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();

  const t = useSelector(selectTranslations);

  const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDay);

    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDay));

    const isoStartDate = startOfWeek.toISOString();
    const isoEndDate = endOfWeek.toISOString();

    return [isoStartDate, isoEndDate];
  };
  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      2
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );

    return [startOfMonth, endOfMonth];
  };

  const getCurrentMonthStartDate = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    return startOfMonth;
  };

  const daysOfWeek = [
    t.workOrders.details_view.sun,
    t.workOrders.details_view.mon,
    t.workOrders.details_view.tue,
    t.workOrders.details_view.wed,
    t.workOrders.details_view.thu,
    t.workOrders.details_view.fri,
    t.workOrders.details_view.sat,
  ];

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const calculateTotal = (data, dayIndex) => {
    // Calculate the total time for the specified day
    return data.reduce((total, row) => {
      const dayData = row.days[dayIndex];
      return total + (dayData ? dayData.time : 0);
    }, 0);
  };

  const calculateOverallTotal = (data) => {
    // Calculate the overall total time
    return data.reduce((total, row) => total + (row.total || 0), 0);
  };
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const assetId = queryParams.get('id');
  const history = useHistory();

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);
  const [weekFlag, setWeekFlag] = useState(true);
  const [weekSelectedDate, setWeekSelectedDate] = useState(
    getCurrentWeekDates()
  );
  const [monthSelectedDate, setMonthSelectedDate] = useState(
    getCurrentMonthStartDate()
  );
  const [monthSelectedArrayDate, setMonthSelectedArrayDate] = useState(
    getCurrentMonthDates()
  );

  const [initData, setInitData] = useState([]);
  const [data, setData] = useState([]);
  const [expandedRow, setExpandedRow] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [targetedData, setTargetedData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [activeChecklistReport, setActiveChecklistReport] = useState({
    report: '',
    isOpen: false,
  });

  const [activeInspectionReport, setActiveInspectionReport] = useState({
    report: '',
    isOpen: false,
  });

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  useEffect(() => {
    const fetchData = async () => {
      // console.log(
      //   // weekFlag,
      //   // weekSelectedDate,
      //   // monthSelectedDate,
      //   monthSelectedArrayDate[0],
      //   'his'
      // );
      setFetchLoading(true);
      let paramsData = {
        startDate: weekFlag ? weekSelectedDate[0] : monthSelectedArrayDate[0],
        endDate: weekFlag ? weekSelectedDate[1] : monthSelectedArrayDate[1],
        team: true,
        people: false,
        isWeek: weekFlag ? true : false,
        isMonth: !weekFlag ? true : false,
      };
      const fetchData = await getAllTimesheet(organisationId, paramsData);

      if (fetchData.length > 0) {
        setData(fetchData);
        setInitData(fetchData);
        setFetchLoading(false);
      } else {
        setInitData([]);
        setData([]);
        setFetchLoading(false);
      }
    };

    fetchData();
  }, [refresh, weekFlag, weekSelectedDate, monthSelectedDate]);

  let daysColumns = [];
  const todayDate = weekFlag
    ? new Date(weekSelectedDate[0])
    : new Date(monthSelectedArrayDate[0]);

  const endDate = new Date(
    weekFlag ? weekSelectedDate[1] : monthSelectedArrayDate[1]
  );

  if (!weekFlag) {
    todayDate.setDate(1);
    endDate.setDate(0);
  }

  while (todayDate <= endDate) {
    const day = todayDate.toLocaleDateString('en-US', { weekday: 'short' });
    const formattedDate = todayDate.toLocaleDateString('en-US', {
      day: '2-digit',
    });

    if (weekFlag) {
      daysColumns.push({
        name: `${day} ${formattedDate}`,
        selector: (row) => row?.days?.find((d) => d.day === day) || t.common.na,
        sortable: true,
        cell: (row) =>
          row?.days?.find((d) => d.day === day)?.time || t.common.na,
      });
    } else {
      daysColumns.push({
        name: `${day} ${formattedDate}`,
        selector: (row) =>
          row?.days?.find((d) => d.day === formattedDate) || t.common.na,
        sortable: true,
        cell: (row) =>
          row?.days?.find((d) => d.day === formattedDate)?.time || t.common.na,
      });
    }

    todayDate.setDate(todayDate.getDate() + 1); // Move to the next day
  }
  // const dayColumns = daysOfWeek.map((day, index) => ({
  //   name: day,
  //   selector: (row) => row.days[index],
  //   sortable: true,
  //   cell: (row) => row.days[index]?.time || t.common.na,
  // }));

  /**
   * Table Fields
   */
  const columns = [
    {
      name: t.assets.table.ID,
      selector: (row) => row?.id,
      sortable: true,
      cell: (row) => <span className="id-text-style">{row?.id}</span>,
    },
    {
      name: t.timesheet.table.teamname,
      selector: (row) => row?.attributes?.name,
      sortable: true,
      width: '140px',
      cell: (row) => (
        <span onClick={() => history.push(`/teams?id=${row?.attributes?.countId}`)}>
          {row?.username || t.common.na}
        </span>
      ),
    },
    {
      width: '170px',
      name: t.assets.form.location,
      selector: (row) => row?.location,
      sortable: true,
      cell: (row) => {
        const locationData = row?.location;
        if (locationData && Array.isArray(locationData)) {
          const displayText = locationData
            .map((location) => {
              const nameParts = location?.name;

              return nameParts;
            })
            .join(', '); // Adjust the join character as needed
          return displayText || t.common.na;
        } else {
          return t.common.na;
        }
      },
    },

    ...daysColumns,

    // {
    //   name: t.workOrders.details_view.sun,
    //   selector: (row) => row.days[0],
    //   sortable: true,
    //   cell: (row) => row.days[0]?.time || t.common.na,
    // },
    // {
    //   name: t.workOrders.details_view.mon,
    //   selector: (row) => row.days[1],
    //   sortable: true,
    //   cell: (row) => row.days[1]?.time || t.common.na,
    // },
    // {
    //   name: t.workOrders.details_view.tue,
    //   selector: (row) => row.days[2],
    //   sortable: true,
    //   cell: (row) => row.days[2]?.time || t.common.na,
    // },
    // {
    //   name: t.workOrders.details_view.wed,
    //   selector: (row) => row.days[3],
    //   sortable: true,
    //   cell: (row) => row.days[3]?.time || t.common.na,
    // },
    // {
    //   name: t.workOrders.details_view.thu,
    //   selector: (row) => row.days[4],
    //   sortable: true,
    //   cell: (row) => row.days[4]?.time || t.common.na,
    // },
    // {
    //   name: t.workOrders.details_view.fri,
    //   selector: (row) => row.days[5],
    //   sortable: true,
    //   cell: (row) => row.days[5]?.time || t.common.na,
    // },
    // {
    //   name: t.workOrders.details_view.sat,
    //   selector: (row) => row.days[6],
    //   sortable: true,
    //   cell: (row) => row.days[6]?.time || t.common.na,
    // },

    {
      name: t.timesheet.table.total,
      selector: (row) => row?.total,
      sortable: true,
      cell: (row) => row?.total || t.common.na,
    },
  ];

  const handleToggleEdit = (data) => {
    setTargetedData(data);
    setTogglePreviw(false);
    setToggleEditModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    setData(newData);
    setCurrentPage(1);
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);

  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <TimesheetIcon />
            {t.timesheet.title}
          </Header>
          <div></div>
        </div>
      </div>
      {fetching ? (
        <div>
       <Placeholder fluid>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        </div>
      ) : (
        <div>
          <div className="new-listing-filter">
            <Filters
              setWeekFlag={setWeekFlag}
              setMonthSelectedArrayDate={setMonthSelectedArrayDate}
              weekFlag={weekFlag}
              setWeekSelectedDate={setWeekSelectedDate}
              weekSelectedDate={weekSelectedDate}
              data={initData}
              setDataByFilter={handleSetDataByFilter}
              fileData={data}
              refresh={refresh}
              setRefresh={setRefresh}
              monthSelectedDate={monthSelectedDate}
              setMonthSelectedDate={setMonthSelectedDate}
              monthSelectedArrayDate={monthSelectedArrayDate}
            />
            {initData.length > 0 ? (
              <>
                <DataTable
                  className="datatable-div"
                  fixedHeader
                  columns={columns}
                  data={getPaginatedData(data)}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-data-container">
                        <div className="">
                          <p className="no-data-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="no-data-container">
                        <div className="">
                          <TimesheetIcon width="40px" height="36px" />
                          <p className="no-data-text">
                            {t.timesheet.no_timesheet_found}
                          </p>
                        </div>
                      </div>
                    )
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }}
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  // subHeader
                  // subHeaderComponent={
                  //   <div>
                  //     <span>Total:</span>
                  //     {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(
                  //       (day, index) => (
                  //         <span key={day}>
                  //           {calculateTotal(yourDataArray, index)}
                  //         </span>
                  //       )
                  //     )}
                  //     <span>{calculateOverallTotal(yourDataArray)}</span>
                  //   </div>
                  // }
                />

                <div className="d-flex justify-content-end">
                  <Pagination
                    ofString={t.common.of}
                    total={data.length}
                    prevItem={
                      selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                    }
                    nextItem={
                      selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                    }
                    page={currentPage}
                    limit={pageSize}
                    handleNext={() => {
                      setCurrentPage(currentPage + 1);
                    }}
                    handlePrevious={() => {
                      setCurrentPage(currentPage - 1);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <TimesheetBlue />
                  <p className="no-data-text">
                    There are no{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <Tooltip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth="49px"
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily="Roboto"
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight="100px"
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.timesheet.how_to_add_timesheet}
                        </span>
                      </Tooltip>
                      {' ' + t.timesheet.title_small + ' '}
                    </span>{' '}
                    added
                  </p>
                  <button
                    className="work-order-button-transparent"
                    onClick={() => {
                      setToggleAsideModal(true);
                    }}
                  >
                    {t.timesheet.no_timesheet_found}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* <Add
        toggled={toggleAsideModal}
        untoggle={() => {
          setToggleAsideModal(false);
        }}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      <Edit
        targetedData={targetedData}
        toggled={toggleEditModal}
        untoggle={() => {
          setTargetedData();
          setToggleEditModal(false);
        }}
        setRefresh={setRefresh}
        refresh={refresh}
      /> */}

      {/* <Preview
        targetedData={targetedData}
        toggled={togglePreview}
        edit={() => {
          setTogglePreviw(false);
          setToggleEditModal(true);
        }}
        untoggle={() => {
          setTargetedData();
          setTogglePreviw(false);
          history.push('/assets');
        }}
        setRefresh={setRefresh}
        refresh={refresh}
      /> */}
    </div>
  );
};
