import React from 'react';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Label,
  Image,
  Grid,
  Menu,
  Tab,
} from 'semantic-ui-react';
import { DashboardrIcon } from '../../../Assets/Icons/svg';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import WorkOrderManagement from './workOrderManagement';
import AssetsManagement from './assetsManagement';
import InventryManagement from './inventryManagement';
import ResourcePlanner from './resourcePlanner';
import Timesheet from './timesheet';
import Cost from './cost';

const Dashboard = () => {
  const t = useSelector(selectTranslations);

  const panes = [
  
    {
      menuItem: t.dashboard.tabs.WO_management,
      pane: {
        key: 'workorder-management',
        content: (
          <div className="pane-body">
            <WorkOrderManagement />
            {/* <Details
                  untoggle={untoggle}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  data={data}
                /> */}
          </div>
        ),
      },
    },

    {
      menuItem: t.dashboard.tabs.assets_management,
      pane: {
        key: 'assets-management',
        content: (
          <div className="pane-body">
            <AssetsManagement />
          </div>
        ),
      },
    },
   
   
    // {
    //   menuItem: t.dashboard.tabs.inventory_management,
    //   pane: {
    //     key: 'inverntry-management',
    //     content: (
    //       <div className="pane-body">
    //         <InventryManagement />
    //       </div>
    //     ),
    //   },
    // },
    // {
    //   menuItem: t.dashboard.tabs.recource_planner,
    //   pane: {
    //     key: 'resource-planner',
    //     content: (
    //       <div className="pane-body">
    //         <ResourcePlanner />
    //       </div>
    //     ),
    //   },
    // },
    // {
    //   menuItem: t.dashboard.tabs.timesheet,
    //   pane: {
    //     key: 'timesheet',
    //     content: (
    //       <div className="pane-body">
    //         <Timesheet />
    //       </div>
    //     ),
    //   },
    // },
    // {
    //   menuItem: t.dashboard.tabs.cost,
    //   pane: {
    //     key: 'cost',
    //     content: (
    //       <div className="pane-body">
    //         <Cost />
    //       </div>
    //     ),
    //   },
    // },
  ];

  return (
    <div className="new-listing-container">
      <div className="table-header">
        
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <DashboardrIcon />
            {t.dashboard.title}
          </Header>
          <div></div>

          {/* {userPermissions?.procedure?.create && initData.length > 0 ? (
        <Button
          icon="add"
          primary
          onClick={() => {
            setToggleAsideModal(true);
          }}
        >
          {t.workOrders.create_WO}
          <ProcedurePlusIcon />
        </Button>
      ) : null} */}
        </div>
      </div>

      <div className="body">
        <Tab className="dashboard-tabs" panes={panes} renderActiveOnly={false} />

        {/* <Dimmer active inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer> */}
      </div>
    </div>
  );
};

export default Dashboard;
