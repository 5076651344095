import React, { useState, useEffect, createRef } from 'react';
import {
  List,
  TextArea,
  Header,
  Modal,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Popup,
} from 'semantic-ui-react';

import {
  createNewCustomer,
  currencies,
  country_codes,
  uploadFile,
} from '../../../config/functions';

import { Country, State, City } from 'country-state-city';
import { useSelector } from 'react-redux';

function AddCustomerModal({ toggled, untoggle, returnAddCustomer, title }) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);

  const [buttonStatus, setButtonStatus] = useState(0);
  const [logo, setLogo] = useState(null);

  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);

  const logoRef = createRef();

  const [responseData, setResponseData] = useState({});
  const [data, setData] = useState({
    name: title,
    address: '',
    countryCode: '962',
    phone: '',
    website: '',
    email: '',
    serviceType: '',
    description: '',
    countries_options: [],
    country: '',
    cities_options: [],
    city: '',
    billing_name: '',
    billing_postal_code: '',
    billing_address: '',
    billing_addressLine2: '',
    billing_addressLine3: '',
    billing_rate: 0,
    billing_currency: 'JOD',
  });

  useEffect(() => {
    setData({ ...data, name: title });
  }, [title]);

  const resetForm = () => {
    setData({
      name: '',
      address: '',
      countryCode: '962',
      phone: '',
      website: '',
      email: '',
      serviceType: '',
      description: '',
      countries_options: [],
      country: '',
      cities_options: [],
      city: '',
      billing_name: '',
      billing_postal_code: '',
      billing_address: '',
      billing_addressLine2: '',
      billing_addressLine3: '',
      billing_rate: 0,
      billing_currency: 'JOD',
    });
    setInitChange(false);
  };

  const onFileChange = (e, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setLogo({
      file: e.target.files[0],
      id: randomnumber,
      name: e.target.files[0].name,
      type: e.target.files[0].type,
      cancelPopup: false,
      isChanged: true,
    });
  };

  useEffect(() => {
    const countries = Country.getAllCountries();
    if (countries) {
      const _g_options = countries.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setData({
        ...data,
        countries_options: _g_options,
      });
    }
  }, [Country]);

  const fetchCities = (code) => {
    const fetchData = State.getStatesOfCountry(code);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setData({
        ...data,
        country: code,
        cities_options: the_options,
      });
    }
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = [
      'billing_currency',
      'countryCode',
      'country',
      'city',
    ];

    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }
    if (dropdown_fields.includes(name)) {
      if (name == 'country') {
        if (value.value) {
          fetchCities(value.value);
        }
      } else {
        // console.log(value);
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }
  };

  const createNewCustomerHandler = async () => {
    setButtonStatus(1);
    const logoUploadReponse =
      logo && logo.isChanged ? await uploadFile([logo.file]) : null;

    const logoId =
      logoUploadReponse?.status == 200 ? logoUploadReponse.data[0].id : null;

    const billingInformation = {
      billingInformation: {
        name: data.billing_name,
        country: data.country,
        city: data.city,
        postalCode: data.billing_postal_code,
        address: data.address,
        addressLine2: data.addressLine2,
        addressLine3: data.addressLine3,
        rate: data.billing_rate,
        currency: data.billing_currency,
      },
    };

    const response = await createNewCustomer(
      data.name,
      data.address,
      data.countryCode + data.phone,
      data.website,
      data.email,
      data.serviceType,
      data.description,
      billingInformation,
      logoId,
      organisationId
      // customFields
    );

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      returnAddCustomer(response.data.data.id);
    }
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };
  return (
    <>
      <Modal
        size="small"
        open={toggled}
        onClose={() => {
          untoggle();
        }}
      >
        <Modal.Header>Add Customer</Modal.Header>
        <Modal.Content scrolling>
          <Form.Field>
            <label className="label-item">Name</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Enter Location Name"
              value={data.name}
              onChange={onValuesChange('name')}
            />
          </Form.Field>

          <Form style={{ marginBottom: '20px' }}>
            <Form.Field>
              <label className="label-item">Description</label>
              <TextArea
                autoComplete="new-password"
                value={data.description}
                onChange={onValuesChange('description')}
                rows={2}
                fluid
                placeholder="Description"
              />
            </Form.Field>
          </Form>

          <Grid columns={'equal'} style={{ marginBottom: '10px' }}>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Address</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Address"
                  value={data.address}
                  onChange={onValuesChange('address')}
                />
              </Form.Field>
              <Form.Field>
                <label className="label-item">Website</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Website"
                  value={data.website}
                  onChange={onValuesChange('website')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Email</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Email"
                  value={data.email}
                  onChange={onValuesChange('email')}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Phone</label>
                <Input
                  autoComplete="new-password"
                  label={
                    <Dropdown
                      button
                      basic
                      search
                      defaultValue="962"
                      options={country_codes}
                      onChange={onValuesChange('countryCode')}
                    />
                  }
                  type="number"
                  fluid
                  placeholder="Phone"
                  value={data.phone}
                  onChange={onValuesChange('phone')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Service Type</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="ex. Plumbing, Electrical"
                  value={data.serviceType}
                  onChange={onValuesChange('serviceType')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Logo</label>
                {logo ? (
                  <List divided relaxed>
                    <Popup
                      key={logo?.id}
                      content="Remove file?"
                      on="click"
                      pinned
                      open={logo?.cancelPopup}
                      trigger={
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            let currentFile = logo;
                            currentFile.cancelPopup = !currentFile.cancelPopup;
                            setLogo(currentFile);
                          }}
                        >
                          <List.Icon
                            name={
                              logo?.type.match('image.*')
                                ? 'image'
                                : logo?.type.match('pdf')
                                ? 'file pdf'
                                : 'file'
                            }
                            size="large"
                            color="black"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header>{logo?.name}</List.Header>
                          </List.Content>
                        </List.Item>
                      }
                    >
                      <Header as="h4">Remove file?</Header>
                      <Button
                        color="black"
                        onClick={(e) => {
                          e.stopPropagation();
                          let currentFile = logo;
                          currentFile.cancelPopup = false;
                          setLogo(currentFile);
                        }}
                      >
                        Keep
                      </Button>
                      <Button
                        color="red"
                        onClick={(e) => {
                          e.stopPropagation();
                          setLogo(null);
                        }}
                      >
                        Yes, remove
                      </Button>
                    </Popup>
                  </List>
                ) : (
                  <div>
                    <input
                      ref={logoRef}
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => onFileChange(e)}
                    />
                    <div
                      className="buttun-type-link"
                      onClick={() => logoRef.current.click()}
                    >
                      <Icon name="add" />
                      Add
                    </div>
                    <p
                      style={{
                        fontSize: '12px',
                        marginTop: '5px',
                        color: '#7e7e7e',
                      }}
                    >
                      Allowed types: jpg, jpeg, png
                    </p>
                  </div>
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Divider horizontal>Billing Information</Divider>

          <Form.Field>
            <label className="label-item">Name</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Name"
              value={data.billing_name}
              onChange={onValuesChange('billing_name')}
            />
          </Form.Field>

          <Grid columns={'equal'} style={{ marginBottom: '10px' }}>
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">Country</label>
                <Dropdown
                  placeholder="Country"
                  fluid
                  search
                  selection
                  options={data.countries_options}
                  value={data.country}
                  onChange={onValuesChange('country')}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">City</label>
                <Dropdown
                  placeholder="City"
                  fluid
                  search
                  selection
                  disabled={data.cities_options.length < 1}
                  options={data.cities_options}
                  value={data.city}
                  onChange={onValuesChange('city')}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Form.Field>
            <label className="label-item">Postal Code</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Postal Code"
              value={data.billing_postal_code}
              onChange={onValuesChange('billing_postal_code')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Address</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Name"
              value={data.billing_address}
              onChange={onValuesChange('billing_address')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Address Line 2</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Address Line 2"
              value={data.billing_addressLine2}
              onChange={onValuesChange('billing_addressLine2')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Address Line 3</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Address Line 3"
              value={data.billing_addressLine3}
              onChange={onValuesChange('billing_addressLine3')}
            />
          </Form.Field>

          <Grid columns={'equal'}>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Rate</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  type="number"
                  placeholder="Rate"
                  value={data.billing_rate}
                  onChange={onValuesChange('billing_rate')}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column>
              <Form.Field>
                <label className="label-item">Currency</label>
                <Dropdown
                  placeholder="Select Currency"
                  fluid
                  search
                  selection
                  clearable
                  options={currencies}
                  value={data.billing_currency}
                  onChange={onValuesChange('billing_currency')}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Divider />
        </Modal.Content>

        <Modal.Actions>
          <Button
            content="Submit"
            primary
            onClick={createNewCustomerHandler}
            loading={buttonStatus == 1}
          />

          <Button
            content="Cancel"
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
      </Modal>

      {/* success modal */}
      <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              Customer <b>"{responseData.name}"</b> added successfully!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddCustomerModal;
