import React, { PureComponent } from 'react';  
import SimpleBar from 'simplebar-react';  
import { Label, Modal, Button } from 'semantic-ui-react';  
import Cropper from 'react-cropper';  
import 'cropperjs/dist/cropper.css';  

const defaultSrc = 'https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg';  

class ImageCrop extends PureComponent {  
  state = {  
    basicModal: true,  
    cropData: '',  
    cropper: null,  
  };  

  getCropData = () => {  
    if (typeof this.state.cropper !== 'undefined') {  
      this.state.cropper.getCroppedCanvas().toBlob((blob) => {  
        this.props.onAccept(blob);  
      });  
    }  
  };  

  setCropper = (instance) => {  
    this.setState({ cropper: instance });  
  };  

  checkCropDimensions = () => {  
    const cropper = this.state.cropper;  

    if (cropper) {  
      const cropBoxData = cropper.getCropBoxData();  
      const maxWidth = 400;  
      const maxHeight = 400;  

      // Restricting the crop box dimensions  
      if (cropBoxData.width > maxWidth || cropBoxData.height > maxHeight) {  
        let newWidth = Math.min(cropBoxData.width, maxWidth);  
        let newHeight = Math.min(cropBoxData.height, maxHeight);  

        // Set the new dimensions  
        cropper.setCropBoxData({  
          width: newWidth,  
          height: newHeight,  
          left: cropBoxData.left + (cropBoxData.width - newWidth) / 2, // Center it  
          top: cropBoxData.top + (cropBoxData.height - newHeight) / 2 // Center it  
        });  
      }  
    }  
  };  

  render() {  
    const { basicModal } = this.state;  
    const { src, onCancel, ratio, minWidth = 10, minHeight = 10 } = this.props;  

    return (  
      <>  
        <div className="search-head-icon">  
          <Modal open={basicModal} onClose={() => onCancel()}>  
            <Modal.Content>  
              <Label className="custom-label">Crop Image</Label>  
              <SimpleBar className="img-crop-wrapper-scroll">  
                <div className="img-crop-wrapper opacity-100">  
                  <Cropper  
                    style={{ height: '100%', width: '100%' }}  
                    initialAspectRatio={ratio || 16 / 8}  
                    aspectRatio={ratio ? ratio : ''}  
                    src={src}  
                    viewMode={1}  
                    minCropBoxHeight={minHeight}  
                    minCropBoxWidth={minWidth}  
                    background={false}  
                    autoCropArea={1}  
                    onInitialized={this.setCropper}  
                    crop={this.checkCropDimensions} // Use the correct event for checking dimensions  
                  />  
                </div>  
              </SimpleBar>  
            </Modal.Content>  
            <Modal.Actions>  
              <Button type="button" onClick={() => onCancel()}>  
                Cancel  
              </Button>  
              <Button  
                primary  
                onClick={() => {  
                  this.getCropData();  
                  this.setState({ basicModal: !basicModal });  
                }}  
              >  
                Upload  
              </Button>  
            </Modal.Actions>  
          </Modal>  
        </div>  
      </>  
    );  
  }  
}  

export default ImageCrop;  