import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { selectTranslations } from '../../../config/i18n/slice';

import {
  Divider,
  Grid,
  Label,
  Image,
  Segment,
  Icon,
  Button,
  List,
  Header,
} from 'semantic-ui-react';

import {
  humanize,
  IMAGES_URL,
  getUserProfileByID,
} from '../../../config/functions';

import md5 from 'md5';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../../components/common/userProfilePlaceholder';

const SubLocations = ({ data, negative }) => {
  const history = useHistory();

  const t = useSelector(selectTranslations);

  let subLocations = data?.attributes?.subLocations?.data;
  if (subLocations.length > 0) {
    subLocations = subLocations.map((sub, index) => {
      const subCitiesOfCountry = State.getStatesOfCountry(
        sub.attributes.country
      );
      const sub_city = sub?.attributes?.city
        ? subCitiesOfCountry.filter((city) => {
            return city.isoCode == sub?.attributes?.city;
          })[0]?.name
        : t.common.na;

      let createdByUserSub = sub.attributes.createdByUser?.data?.attributes ? (
        <div>
          <Image
            avatar
            title={
              sub.attributes.createdByUser?.data?.attributes.firstName +
              ' ' +
              sub.attributes.createdByUser?.data?.attributes.lastName
            }
            src={
              sub.attributes.createdByUser?.data?.attributes?.profileImage?.data
                ?.attributes?.url
                ? IMAGES_URL +
                  sub.attributes.createdByUser?.data?.attributes?.profileImage
                    ?.data?.attributes?.url
                : LetteredAvatarImageUrl(
                    sub.attributes.createdByUser?.data?.attributes.firstName +
                      ' ' +
                      sub.attributes.createdByUser?.data?.attributes.lastName
                  )
            }
            style={{ width: '20px', height: '20px' }}
          />
          <span style={{ marginLeft: '4px' }}>
            {sub.attributes.createdByUser?.data?.attributes.firstName}
          </span>
        </div>
      ) : (
        t.common.na
      );
      // let customerIMG = t.common.na;
      // if (sub.attributes.customer.data) {
      //   customerIMG = sub.attributes.customer.data
      //     .attributes?.logo.data ? (
      //     <div>
      //       <Image
      //         avatar
      //         title={
      //           sub.attributes.customer.data?.attributes
      //             .name
      //         }
      //         src={
      //           IMAGES_URL +
      //           sub.attributes.customer.data?.attributes
      //             ?.logo?.data?.attributes?.url
      //         }
      //       />
      //       <span style={{ marginLeft: '4px' }}>
      //         {
      //           sub.attributes.customer.data.attributes
      //             .name
      //         }
      //       </span>
      //     </div>
      //   ) : (
      //     <div>
      //       <Image
      //         avatar
      //         title={
      //           sub.attributes.customer.data.attributes
      //             .name
      //         }
      //         src={LetteredAvatarImageUrl(
      //           sub.attributes.customer.data.attributes
      //             .name
      //         )}
      //       />
      //       <span style={{ marginLeft: '4px' }}>
      //         {
      //           sub.attributes.customer.data.attributes
      //             .name
      //         }
      //       </span>
      //     </div>
      //   );
      // }
      let subCustomers = sub.attributes?.customers?.data;
      if (subCustomers?.length > 0) {
        subCustomers = subCustomers.map((people, index) => {
          if (people?.attributes?.logo?.data?.attributes?.url) {
            return (
              <div
                className="d-flex mt-2"
                onClick={() => history.push(`/customers?id=${people.attributes?.countId}`)}
              >
                <Image
                  key={index}
                  avatar
                  title={people.attributes.name + ' ' + ' '}
                  src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                  style={
                    index !== 0
                      ? { marginLeft: '-6px', width: '34px', height: '34px' }
                      : { width: '34px', height: '34px' }
                  }
                />

                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                  <Icon name="angle right" />
                </div>
                {/* <span className='ml-2'>
                  {people?.attributes?.name }
                </span> */}
              </div>
            );
          } else {
            return (
              <div
                className="d-flex mt-2"
                onClick={() => history.push(`/customers?id=${people?.attributes?.countId}`)}
              >
                <UserProfilePlaceholder
                  key={index}
                  name={`${
                    people?.attributes?.name ? people?.attributes?.name : ''
                  } ${''}`}
                  width={'34px'}
                  height={'34px'}
                />
                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                  <Icon name="angle right" />
                </div>
              </div>
            );
          }
        });
      } else {
        subCustomers = t.common.na;
      }

      let subVendors = sub?.attributes.vendors?.data;
      if (subVendors?.length > 0) {
        subVendors = subVendors.map((people, index) => {
          if (people?.attributes?.logo?.data?.attributes?.url) {
            return (
              <div
                className="d-flex mt-2"
                onClick={() => history.push(`/vendors?id=${people?.attributes?.countId}`)}
              >
                <Image
                  key={index}
                  avatar
                  title={people.attributes.name + ' ' + ' '}
                  src={`${IMAGES_URL}${people?.attributes?.logo?.data?.attributes?.url}`}
                  style={
                    index !== 0
                      ? { marginLeft: '-6px', width: '34px', height: '34px' }
                      : { width: '34px', height: '34px' }
                  }
                />

                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                  <Icon name="angle right" />
                </div>
                {/* <span className='ml-2'>
                  {people?.attributes?.name }
                </span> */}
              </div>
            );
          } else {
            return (
              <div
                className="d-flex mt-2"
                onClick={() => history.push(`/vendors?id=${people?.attributes?.countId}`)}
              >
                <UserProfilePlaceholder
                  key={index}
                  name={`${
                    people?.attributes?.name ? people?.attributes?.name : ''
                  } ${''}`}
                  width={'34px'}
                  height={'34px'}
                />
                <div
                  className="work-order-link mt-1"
                  basic
                  style={{ marginLeft: '4px' }}
                >
                  {people?.attributes?.name}
                  <Icon name="angle right" />
                </div>
              </div>
            );
          }
        });
      } else {
        subVendors = t.common.na;
      }

      let customFields = sub?.attributes?.customFields;

      if (customFields.length > 0) {
        customFields = customFields.map((el, index) => {
          return (
            <>
              <div className="d-flex align-items-center mt-5">
                <label className="detail-intro-label">
                  {t.locations.form.label} {index + 1}
                  {':'}
                </label>
                <p className="detail-intro-value">
                  {el?.label ? el.label : t.common.na}
                  {/* {sub.attributes.address ? sub.attributes.address : t.common.na} */}
                </p>
              </div>

              <div className="d-flex align-items-center mt-5">
                <label className="detail-intro-label">
                  {t.locations.form.value} {index + 1}
                  {':'}
                </label>
                <p className="detail-intro-value">
                  {el?.value ? el.value : t.common.na}
                  {/* {sub.attributes.address ? sub.attributes.address : t.common.na} */}
                </p>
              </div>
            </>
          );
        });
      } else {
        customFields = '';
      }

      let area = sub?.attributes?.areas?.data;
      let areaString = '';
      if (area?.length > 0) {
        areaString = area.map((people) => people?.attributes?.name).join(', ');
      } else {
        areaString = t.common.na;
      }

      return (
        <>
          <h3>{sub?.attributes.name}</h3>
          <div className="detail-intro">
            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.locations.table.country}
                {':'}
              </label>
              <p className="detail-intro-value">
                {sub?.attributes?.country
                  ? Country.getCountryByCode(sub?.attributes?.country)?.name
                  : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.locations.table.city}
                {':'}
              </label>
              <p className="detail-intro-value">{sub_city}</p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.locations.table.address}
                {':'}
              </label>
              <p className="detail-intro-value">
                {sub.attributes.address ? sub.attributes.address : t.common.na}
              </p>
            </div>

            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.assets.form.area}
                {':'}
              </label>
              <p className="detail-intro-value">
                {areaString}
                {/* {sub.attributes.address ? sub.attributes.address : t.common.na} */}
              </p>
            </div>

            {customFields.length > 0 ? customFields : null}

            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.locations.table.customer}
                {':'}
              </label>
              <p className="detail-intro-value">
                {subCustomers ? subCustomers : t.common.na}
              </p>
            </div>
            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.locations.form.vendors}
                {':'}
              </label>
              <p className="detail-intro-value">
                {subVendors ? subVendors : t.common.na}
              </p>
            </div>
          </div>
          <Divider />

          {/* <div className="d-flex align-items-center mt-5">
            <label className="detail-intro-label">
              {t.locations.form.vendors}
              {':'}
            </label>
            <p className="detail-intro-value">
              {subVendors ? subVendors : t.common.na}
            </p>
          </div> */}
        </>
      );

      // <>
      //   <h3>{sub?.attributes.name}</h3>

      //   <div className="d-flex align-items-center mt-5">
      //     <label className="detail-intro-label">
      //       {t.locations.table.address}
      //       {':'}
      //     </label>
      //     <p className="detail-intro-value">
      //       {sub?.attributes.address || t.common.na}
      //     </p>
      //   </div>
      // </>;
      // <List.Item className="d-flex mb-2">
      //    <div className="d-flex align-items-center">
      //   <List.Icon name="map outline" />

      //    </div>
      //   <List.Content className="p-0 ml-3 ">
      //     <List.Header as="a">{sub?.attributes.name}</List.Header>
      //     <List.Description style={{ fontSize: '12px' }}>
      //       {sub?.attributes.address}
      //     </List.Description>
      //   </List.Content>
      // </List.Item>
    });
  } else {
    subLocations = '';
  }

  const SubLocationName = (sub) => {
    return sub ? (
      <List.Item>
        <List.Icon name="map outline" />
        <List.Content>
          <List.Header as="a">{sub?.attributes.name}</List.Header>
          <List.Description style={{ fontSize: '12px' }}>
            {sub?.attributes.address}
          </List.Description>
        </List.Content>
      </List.Item>
    ) : (
      ''
    );
  };

  return (
    <div className="preview-details-wrapper">
      {subLocations.length > 0 ? (
        subLocations
      ) : (
        <>
          {' '}
          <div style={{ textAlign: 'center' }}>
            <Header as="h3">{t.common.na}</Header>
          </div>
        </>
      )}
    </div>
  );
};

export default SubLocations;
