export function scrollToError() {
  setTimeout(() => {
    let container = document.querySelector('.aside-modal.active .body'); // Replace with the actual class or ID of your container
    let input = null;

    if (container) {
      input = container.querySelector('.label-error');
      // console.log(container, input);
      if (input) {
        if (elementInViewport(container, input)) {
          // The element is already in the viewport of the container
        } else {
          let elPosition = findPos(input);
          container.scrollTop = elPosition;
        }
      }
    }
  });
}

// Adjusted elementInViewport to consider the container's position
function elementInViewport(container, el) {
  var containerRect = container.getBoundingClientRect();
  var elRect = el.getBoundingClientRect();

  return (
    elRect.top >= containerRect.top &&
    elRect.left >= containerRect.left &&
    elRect.bottom <= containerRect.bottom &&
    elRect.right <= containerRect.right
  );
}

// Adjusted findPos to calculate the offset relative to the container
function findPos(obj) {
  var curtop = 0;

  do {
    curtop += obj.offsetTop;
  } while ((obj = obj.offsetParent));

  return curtop - 200; // Adjust the offset as needed
}
