import React, { useState } from 'react';

import { Header, Grid, Form, Dropdown, Button } from 'semantic-ui-react';
import { NavLink, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { setLang } from '../../config/i18n/slice';
import { setCookie, updateUserLanguage } from '../../config/functions';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { checkIsActive } from '../../../_metronic/_helpers';
import { selectTranslations } from '../../config/i18n/slice';

export const Settings = () => {
  const location = useLocation();
  const t = useSelector(selectTranslations);
  const user = useSelector((state) => {
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });
  const lang = useSelector((state) => state.i18n.lang);
  const supportedLangs = useSelector((state) => state.i18n.supportedLangs);
  const { userPermissions } = usePermissionsSimplified();

  const dispatch = useDispatch();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };
  const [dropdownOptions, setDropdownOptions] = useState({
    lang: Object.entries(supportedLangs).map(([code, name]) => {
      return {
        key: code,
        value: code,
        text: name,
      };
    }),
  });

  const handleLanguageUpdate = async (value) => {
    let res = await updateUserLanguage(
      user?.id,
      value.value == 'ar' ? 'Arabic' : 'English'
    );
    if (res && res.status == 200) {
      dispatch(setLang(value.value));
      setCookie('LANG', value.value, 10);
    }
  };

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.type) {
      return state?.auth?.user?.role?.type;
    } else {
      return '';
    }
  });

  return (
    <div className="ml-5 mr-5">
      {/* <Header as="h2">Settings</Header> */}

      <div
        className={
          userRole == 'super_admin'
            ? 'list-view-option-setting'
            : 'list-view-option-setting mb-5'
        }
      >
        {userRole == 'super_admin' ? (
          <p className="single-view-setting">
            <NavLink to="/company-profile"> {t.common.company_profile}</NavLink>
          </p>
        ) : null}
        <p
          className={
            userRole == 'super_admin'
              ? 'single-view-setting ml-4 mr-4'
              : ' single-view-setting'
          }
        >
          <NavLink to="/settings"> {t.common.language}</NavLink>
        </p>
      </div>
      {/* <div className="header-actions">
        <Button.Group basic>
          {userPermissions?.companySettings?.view ? (
            <NavLink to="/company-profile">
              <Button
                color={
                  getMenuItemActive('/company-profile', false) ? 'teal' : ''
                }
              >
                {t.common.company_profile}
              </Button>
            </NavLink>
          ) : null}
          <NavLink to="/settings">
            <Button color={getMenuItemActive('/settings', false) ? 'teal' : ''}>
              {t.common.language}
            </Button>
          </NavLink>
        </Button.Group>
      </div> */}

      <Grid columns="equal">
        <Grid.Column>
          <Form.Field>
            <label>{t.common.language}</label>
            <Dropdown
              fluid
              selection
              noResultsMessage={t.common.no_results_found}
              options={dropdownOptions.lang}
              value={lang}
              placeholder="Select Language"
              onChange={(event, value) => {
                handleLanguageUpdate(value);
              }}
            />
          </Form.Field>
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid>
    </div>
  );
};
