import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import { Topbar } from './Topbar';
import { HeaderMenuWrapper } from './header-menu/HeaderMenuWrapper';
import { AnimateLoading } from '../../../_partials/controls';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_helpers';
import { useSelector, connect } from 'react-redux';
import {
  IMAGES_URL,
  getOrganisationData,
  imageUrlToFile,
} from '../../../../app/config/functions';
import { State } from 'country-state-city';
import { Image } from 'semantic-ui-react';
import useDisplayFormattedText from '../../../../hooks/useDisplayFormattedText';
import { AssetProfilePlaceholder2 } from '../../../../app/components/common/userProfilePlaceholder';
export function Header() {
  const uiService = useHtmlClassService();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const organisationUrl = useSelector((state) => {
    if (state?.auth?.organisation) {
      return state?.auth?.organisation;
    } else {
      return null;
    }
  });

  const [organisationData, setOrganisationData] = useState(null);
  const { DisplayFormattedText } = useDisplayFormattedText();

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        'header.menu.self.display'
      ),
      headerLogo: uiService.getLogo(),
    };
  }, [uiService]);

  useEffect(() => {
    (async function() {
      const res = await getOrganisationData(organisationId);
      // getUserData(user.id),

      if (res) {
        setOrganisationData(res);
      }
    })();
  }, []);

  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
        style={{padding:"0 10px"}}
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <div className="wrapper-desktop-logo">
            <div className="d-flex "> </div>
            {organisationUrl?.profileImage?.data ? (
              <>
                <div className="wrapper-desktop-logo">
                  <div className="d-flex ">
                    <Image
                      bordered
                      title={organisationUrl?.name}
                      src={`${IMAGES_URL}${organisationUrl?.profileImage?.data?.attributes?.url}`}
                      style={{
                        width: '38px',
                        height: '38px',
                        borderRadius: '7px',
                      }}
                    />
                    <span
                      style={{
                        fontSize: '16px',
                        marginTop: '11px',
                       
                      }}
                      className="id-text-style ml-3 mr-3"
                    >
                      {DisplayFormattedText(organisationUrl?.name, 18)}
                    </span>
                  </div>
                  {/* <Image/>
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/logo.svg')}
                  ></SVG> */}
                  {/* <p className="logo-icon">{organisationUrl?.name}</p> */}
                  {/* <Link to="" className="brand-logo">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link> */}
                </div>
              </>
            ) : (
              <div className="wrapper-desktop-logo">
                <div className="d-flex ">
                  <AssetProfilePlaceholder2
                    name={organisationUrl?.name}
                    width={'38px'}
                    height={'38px'}
                  />

                  <span
                    style={{ fontSize: '16px', marginTop: '11px' }}
                    className="id-text-style ml-3 mr-3"
                  >
                    {DisplayFormattedText(organisationUrl?.name, 18)}
                  </span>
                </div>
                {/* <Image/>
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/logo.svg')}
                  ></SVG> */}
                {/* <p className="logo-icon">{organisationUrl?.name}</p> */}
                {/* <Link to="" className="brand-logo">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link> */}
              </div>
            )}

            {/* <Link to="" className="brand-logo">
          <img alt="logo" src={layoutProps.headerLogo}/>
        </Link> */}
          </div>

          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          {!layoutProps.menuHeaderDisplay && <div />}
          {/*end::Header Menu Wrapper*/}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
    </>
  );
}
