import React from 'react';

function Asterisk({ color }) {
  return (
    <span className="" style={{ color: color }}>
      *
    </span>
  );
}

export default Asterisk;
