import React, { useEffect, useState } from 'react';
import {
  Dimmer,
  Loader,
  List,
  Label,
  Grid,
  Image,
  Divider,
} from 'semantic-ui-react';

import md5 from 'md5';

import {
  humanize,
  getUserProfileByID,
  IMAGES_URL,
} from '../../../config/functions';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import UserProfilePlaceholder, {
  LetteredAvatarImageUrl,
} from '../../../components/common/userProfilePlaceholder';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { LocationBlueNoBackground } from '../../../../Assets/Icons/svg';
import Moment from 'react-moment';

function Work({
  targetedData,
  supervisorData,
  attachmentData,
  createdByUserData,
  updatedUserData,
}) {
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });
  const [fetchLoading, setFetchLoading] = useState(false);
  const [data, setData] = useState({
    profile: {},
    attendence: {},
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  // const roles = [
  //   {
  //     key: 0,
  //     value: 5,
  //     text: t.people.add_modal.admin,
  //     icon: 'cog',
  //     description: t.people.add_modal.admin_description,
  //   },

  //   {
  //     key: 1,
  //     value: 8,
  //     text: t.people.add_modal.technician,
  //     icon: 'wpforms',
  //     description: t.people.add_modal.user_description,
  //   },
  //   {
  //     key: 2,
  //     value: 9,
  //     text: t.people.add_modal.limited_admin,
  //     icon: 'phone volume',
  //     description: '',
  //   },
  //   {
  //     key: 3,
  //     value: 3,
  //     text: t.people.add_modal.viewer,
  //     icon: 'sitemap',
  //     description: '',
  //   },
  // ];

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,

      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,

      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,

      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,

      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      description:
       t.people.add_modal.requester_desc
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];

  let role = roles.find((each) => each.value == targetedData?.role?.id);
  const shiftOptions = [
    {
      key: 'shiftA',
      value: 'shiftA',
      text: t.people.work_info_form.shift_A,
    },
    {
      key: 'shiftB',
      value: 'shiftB',
      text: t.people.work_info_form.shift_B,
    },
    {
      key: 'shiftC',
      value: 'shiftC',
      text: t.people.work_info_form.shift_C,
    },
  ];

  const DayOptions = [
    {
      key: 'sun',
      value: 'sun',
      text: t.people.work_info_form.working_day_options.sun,
    },
    {
      key: 'mon',
      value: 'mon',
      text: t.people.work_info_form.working_day_options.mon,
    },
    {
      key: 'tue',
      value: 'tue',
      text: t.people.work_info_form.working_day_options.tue,
    },
    {
      key: 'wed',
      value: 'wed',
      text: t.people.work_info_form.working_day_options.wed,
    },
    {
      key: 'thu',
      value: 'thu',
      text: t.people.work_info_form.working_day_options.thu,
    },
    {
      key: 'fri',
      value: 'fri',
      text: t.people.work_info_form.working_day_options.fri,
    },
    {
      key: 'sat',
      value: 'sat',
      text: t.people.work_info_form.working_day_options.sat,
    },
  ];
  useEffect(() => {
    setFetchLoading(true);

    const fetchData = async () => {
      if (targetedData.userProfile) {
        const fetchData = await getUserProfileByID(targetedData.userProfile.id);
        if (fetchData) {
          setData({
            ...data,
            profile: fetchData.data.data.attributes,
          });
          setFetchLoading(false);
        }
      } else {
        setFetchLoading(false);
      }
    };

    fetchData();
  }, [targetedData]);

  const shifts = data.profile?.shifts;
  let formattedShifts;

  if (shifts) {
    const matchShift = Object?.keys(shifts).filter(
      (key) => shifts[key] === true
    );

    const selectedShiftNames = matchShift?.map((selectedShift) => {
      const correspondingOption = shiftOptions?.find(
        (option) => option?.value === selectedShift
      );
      return correspondingOption ? correspondingOption?.text : null;
    });

    formattedShifts = selectedShiftNames?.filter(Boolean).join(', ');
  } else {
    formattedShifts = null;
  }

  const workingDay = data.profile?.days;
  let formattedDays;

  if (workingDay) {
    const matchDay = Object?.keys(workingDay).filter(
      (key) => workingDay[key] === true
    );

    const selectedDayNames = matchDay?.map((selectedShift) => {
      const correspondingOption = DayOptions?.find(
        (option) => option?.value === selectedShift
      );
      return correspondingOption ? correspondingOption?.text : null;
    });

    formattedDays = selectedDayNames?.filter(Boolean).join(', ');
  } else {
    formattedDays = null;
  }

  let this_shifts = [];

  if (data.profile?.shifts?.shiftA) {
    this_shifts.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'blue'}
        content={'A'}
      />
    );
  }

  if (data.profile?.shifts?.shiftB) {
    this_shifts.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'orange'}
        content={'B'}
      />
    );
  }

  if (data.profile?.shifts?.shiftC) {
    this_shifts.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={'C'}
      />
    );
  }

  let this_days = [];
  if (data.profile?.days?.sat) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.sat}
      />
    );
  }

  if (data.profile?.days?.sun) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.sun}
      />
    );
  }

  if (data.profile?.days?.mon) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.mon}
      />
    );
  }

  if (data.profile?.days?.thu) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.thu}
      />
    );
  }

  if (data.profile?.days?.wed) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.wed}
      />
    );
  }

  if (data.profile?.days?.tue) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.tue}
      />
    );
  }

  if (data.profile?.days?.fri) {
    this_days.push(
      <Label
        style={{ height: 'auto', borderRadius: 5 }}
        size="medium"
        color={'green'}
        content={t.workOrders.details_view.fri}
      />
    );
  }
  return (
    <div className="preview-details-wrapper">
      {/* {console.log(targetedData, 'TargettedData')} */}
      {fetchLoading ? (
       
       <Loader active inline="centered">{t.common.loading}</Loader>
       
      ) : (
        <>
          <div className="intro">
            <div className="flexbox align-center" style={{ margin: '20px 0' }}>
              <div>
                {targetedData?.profileImage?.url ? (
                  <div className="d-flex align-items-center">
                    <div className="text-center">
                      <Image
                        circular
                        title={
                          targetedData.firstName + ' ' + targetedData.lastName
                        }
                        src={`${IMAGES_URL}${targetedData?.profileImage?.url}`}
                        style={
                          selectedLanguage === 'en'
                            ? { width: '80px', height: '80px', right: '6px' }
                            : { width: '80px', height: '80px', right: '-6px' }
                        }
                        // style={
                        //   index != 0
                        //     ? {
                        //         marginLeft: '-6px',
                        //         width: '34px',
                        //         height: '34px',
                        //       }
                        //     : { width: '34px', height: '34px' }
                        // }
                      />
                    </div>
                    <div
                      className="username-container ml-2"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text">
                        {humanize(
                          targetedData.firstName + ' ' + targetedData.lastName
                        )}
                      </p>
                      <p className="people-role text-left">
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ''}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <div className="text-center">
                      <UserProfilePlaceholder
                        name={
                          targetedData.firstName + ' ' + targetedData.lastName
                        }
                        width={'80px'}
                        height={'80px'}
                        right={selectedLanguage === 'en' ? '6px' : '-6px'}
                      />
                    </div>
                    <div
                      className="username-container ml-1"
                      // onClick={() => history.push(`/people?id=${row?.id}`)}
                    >
                      <p className="people-text text-left">
                        {humanize(
                          targetedData.firstName + ' ' + targetedData.lastName
                        )}
                      </p>
                      <p
                        className={`people-role ${
                          selectedLanguage == 'en'
                            ? 'text-left mr-2'
                            : 'text-right ml-0'
                        }`}
                      >
                        {targetedData?.role?.name
                          ? role?.text || humanize(targetedData?.role?.name)
                          : ''}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="d-flex align-items-center mt-5 mb-7">
              <label className="workorder-intro-label">
                {t.common.created_by}
                {':'}
              </label>
              <p className="workorder-intro-value">
                {createdByUserData?.firstName
                  ? createdByUserData?.firstName +
                    ' ' +
                    createdByUserData?.lastName
                  : t.common.na}
              </p>
            </div> */}
          </div>

          <Divider />

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.personal_info_form.email}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {targetedData?.email ? targetedData.email : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.personal_info_form.phone}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile.phone ? `${selectedLanguage == 'en' ? '+' : ''}${data.profile.phone}${selectedLanguage == 'ar' ? '+' : ''}` : t.common.na}
            </p>
          </div>

          {/* <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label">
              {t.people.personal_info_form.email}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.email ? targetedData.email : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label">
              {t.people.personal_info_form.phone}
              {':'}
            </label>
            <p className="workorder-intro-value">
              {data.profile.phone ? `+${data.profile.phone}` : t.common.na}
            </p>
          </div> */}

          <div className="d-flex align-items-center mt-5  ">
            <label className="label-item">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.job_title}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mb-1">
              {data?.profile?.job?.data
                ? data.profile?.job?.data?.attributes?.title
                : t.common.na}
            </p>
          </div>
{(targetedData?.role?.id == 8 || targetedData?.role?.id == 9 )?  <div className="d-flex align-items-center mt-5">
            <label className="label-item ">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.supervisor}
                {':'}
              </label>
            </label>

            <p className="workorder-intro-value">
              {targetedData.supervisor ? (
                <div>
                  <Image
                    avatar
                    title={
                      targetedData.supervisor.firstName +
                      ' ' +
                      targetedData.supervisor.lastName
                    }
                    src={
                      supervisorData?.profileImage?.url
                        ? IMAGES_URL + supervisorData?.profileImage?.url
                        : LetteredAvatarImageUrl(
                            targetedData.supervisor.firstName +
                              ' ' +
                              targetedData.supervisor.lastName
                          )
                    }
                    style={{ width: '34px', height: '34px' }}
                  />
                  <span style={{ marginLeft: '4px' }}>
                    {targetedData.supervisor.firstName}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div> : null}
         

          <div className="d-flex align-items-start mt-5">
            <label className="label-item ">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.teams}
                {':'}
              </label>
            </label>

            <p className="workorder-intro-value">
              {targetedData?.teams.length > 0
                ? targetedData?.teams?.map((el) => (
                    <List.Item className="d-flex mb-2">
                      <div className="d-flex align-items-center">
                        <Image
                          avatar
                          title={el?.name}
                          src={LetteredAvatarImageUrl(el?.name)}
                          height="34px"
                          width="34px"
                        />
                      </div>

                      <List.Header className="mt-1 ml-1">{el.name}</List.Header>
                    </List.Item>
                  ))
                : t.common.na}
            </p>
          </div>

          <div className="d-flex aalign-items-start mt-5">
            <label className="label-item ">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.locations}
                {':'}
              </label>
            </label>

            <p
              style={{ gap: '10px' }}
              className="workorder-intro-value d-flex text-left flex-column"
            >
             
              {targetedData?.locations && targetedData?.locations?.length
                ? targetedData?.locations.map((each) => {
                 
                    return (
                      <span
                        className={`work-order-link  ${
                          selectedLanguage == 'en' ? 'text-left' : 'text-right'
                        } `}
                        basic
                        onClick={() =>
                          history.push(`/locations?id=${each?.countId}`)
                        }
                      >
                        <LocationBlueNoBackground />
                        <span className="ml-2">{each?.name}</span>
                      </span>
                    );
                  })
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.department}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data?.profile?.department
                ? data.profile?.department
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.hour_rate}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
            {data.profile?.hourRate 
  ? `${data.profile.hourRate} ${organisationCurrency}` 
  : t.common.na}
             
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.working_hours_day}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.whpd ? data.profile.whpd : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.working_hours_week}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.whpw ? data.profile.whpw : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.contract_start_date}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.contractStart
                ? moment(data.profile?.contractStart).format('DD.MM.YYYY')
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.contract_expiry_date}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.contractEnd
                ? moment(data.profile?.contractEnd).format('DD.MM.YYYY')
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.registration_no}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.registrationNo
                ? data.profile?.registrationNo
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.badge_ID}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.badgeId ? data.profile?.badgeId : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label ">
                {t.people.work_info_form.working_days}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {formattedDays ? formattedDays : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.shifts}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {formattedShifts ? formattedShifts : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <label className="label-item mt-5">
              {''}
              <label className="workorder-intro-label">
                {t.people.work_info_form.work_permit_no}
                {':'}
              </label>
            </label>
            <p className="workorder-intro-value mt-4">
              {data.profile?.workPermitNo
                ? data.profile?.workPermitNo
                : t.common.na}
            </p>
          </div>
          <Divider />

          <div className="d-flex align-items-center mt-5">
            <label
              className="workorder-intro-label grey"
              style={{ minWidth: '77px' }}
            >
              {t.workOrders.table.createdByUser}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {createdByUserData
                ? createdByUserData?.firstName +
                  ' ' +
                  createdByUserData?.lastName +
                  ', ' +
                  moment(data?.profile?.createdAt).format('DD-MM-YYYY LT')
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-center mt-3">
            <label className="workorder-intro-label grey">
              {t.common.update_by}
              {':'}
            </label>
            <p className="workorder-intro-value grey">
              {updatedUserData
                ? updatedUserData?.firstName +
                  ' ' +
                  updatedUserData?.lastName +
                  ', ' +
                  moment(data?.profile?.updatedAt).format('DD-MM-YYYY LT')
                : t.common.na}

              {/* {updatedUserData?.firstName && updatedUserData?.lastName
                ? updatedUserData?.firstName + ' ' + updatedUserData?.lastName
                : t.common.na}
              {', '} */}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default Work;
