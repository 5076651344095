import React, { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import {
  Icon,
  Button,
  Popup,
  Tab,
  Loader,
  Dimmer,
  Menu,
  Modal,
  Header,
  Label,
  FormField,
  Radio,
  
} from 'semantic-ui-react';
import Tooltip from 'react-power-tooltip';

import {
  deleteWorkOrder,
  getAllWorkOrderByID,
  humanize,
} from '../../../config/functions';

import Assignees from './assignes';
import Details from './details';
import Attachments from './attachments';
import Report from './report';
import Comment from './comment';
import { selectTranslations } from '../../../config/i18n/slice';

import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { useSelector } from 'react-redux';
import ProcedureItems from './ProcedureItems';
import { toast } from 'react-toastify';
import {
  Close,
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  Fail,
  FileDownloadIcon,
  ShareIcon,
  Success,
} from '../../../../Assets/Icons/svg';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';
import { useDeleteResultModal } from '../../../../hooks/Messages/useDeleteResultModal';
import { useDeletePermissionModal } from '../../../../hooks/Messages/useDeletePermissionModal';
import moment from 'moment';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import { Share } from '@material-ui/icons';
import ToolTip from 'react-power-tooltip';
import Logs from './log';
import { Resizable } from 're-resizable';
function Preview({
  toggled,
  untoggle,
  setModalWidth,
  modalWidth,
  toggleEdit,
  targetedData,
  workOrderID,
  negative,
  edit,
  refresh,
  setRefresh,
  seriesEdit,
  setSeriesEdit,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
}) {
  const t = useSelector(selectTranslations);
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [fetching, setFetchLoading] = useState(true);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [showExclTooltip, setShowExclTooltip] = useState(false);

  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [seriesModal, setSeriesModal] = useState(false);
  const [deleteSeriesModal, setDeleteSeriesModal] = useState(false);
  const [deleteSeries,setDeleteSeries]= useState(false)
  const [showCancleTooltip, setCancleTooltip] = useState(false);


  /* headers for excel and pdf file */
  const headers = [
    { label: t.workOrders.table.ID, key: 'id' },
    { label: t.workOrders.table.title, key: 'title' },
    { label: t.assets.form.description, key: 'description' },
    { label: t.workOrders.table.status, key: 'status' },
    { label: t.workOrders.table.priority, key: 'priority' },
    { label: t.workOrders.table.assignees, key: 'assignees' },
    { label: t.assets.form.category, key: 'category' },
    { label: t.workOrders.form.code, key: 'code' },
    { label: t.workOrders.table.customer, key: 'customer' },
    { label: t.workOrders.table.location, key: 'location' },
    // { label: t.workOrders.form.parts, key: 'parts' },
    { label: t.workOrders.table.procedure, key: 'procedure' },
    { label: t.workOrders.details_view.estimation_time, key: 'estimationTime' },
    { label: t.workOrders.table.dueDate, key: 'dueDate' },
    { label: t.workOrders.table.createdByUser, key: 'created By' },
    { label: t.workOrders.table.createdAt, key: 'created At' },
  ];

  const codeOptions = [
    {
      value: 'sop',
      label: t.workOrders.work_order_category.Sop,
    },

    {
      value: 'project',
      label: t.workOrders.work_order_category.Project,
    },
    {
      value: 'preventive',
      label: t.workOrders.work_order_category.Preventive,
    },
    {
      value: 'inspection',
      label: t.workOrders.work_order_category.Inspection,
    },
    {
      value: 'damage',
      label: t.workOrders.work_order_category.Damage,
    },
  ];

  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
  ];

  /* The above code is a React useEffect hook that is used to fetch data based on the `workOrderID`
value. */
  useEffect(() => {
    setFetchLoading(true);

    if (workOrderID) {
      const fetchData = async () => {
        const fetchData = await getAllWorkOrderByID(workOrderID);
        if (fetchData) {
          setData(fetchData);
          setFetchLoading(false);
        }
      };

      fetchData();
    } else {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData, workOrderID]);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      let execlude_ids = ['popupTrigger'];
      // console.log(e.target.id);
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);

  const panes =
  data?.attributes.status == 'completed' && data?.attributes?.report?.data
    ? [
        {
          menuItem: `${t.workOrders.details_view.details}`,
          pane: {
            key: 'details',
            content: (
              <div className="pane-body">
                <Details
                  data={data}
                  setData={setData}
                  negative={negative}
                  untoggle={untoggle}
                  setModalWidth={setModalWidth}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  setSearchTitle={setSearchTitle}
                  setSelectedDates={setSelectedDates}
                  setFilterOptions={setFilterOptions}
                />
              </div>
            ),
          },
        },
        // {
        //   menuItem: `${t.workOrders.details_view.assignees}`,
        //   pane: {
        //     key: 'assignes',
        //     content: (
        //       <div className="pane-body">
        //         <Assignees data={data} />
        //       </div>
        //     ),
        //   },
        // },
        // {
        //   menuItem: `${t.workOrders.details_view.attachments}`,
        //   pane: {
        //     key: 'attachments',
        //     content: (
        //       <div className="pane-body">
        //         <Attachments data={data} />
        //       </div>
        //     ),
        //   },
        // },
        {
          menuItem: `${t.workOrders.details_view.comments}`,
          pane: {
            key: 'comment',
            content: (
              <div className="pane-body">
                <Comment data={data} />
              </div>
            ),
          },
        },
        // data?.attributes?.procedure ? 
        // {
        //   menuItem: `${t.workOrders.details_view.procedure_items}`,
        //   pane: {
        //     key: 'procedure-items',
        //     content: (
        //       <div className="pane-body">
        //         <ProcedureItems
        //           targetedData={
        //             data?.attributes?.report?.data || {
        //               attributes: { fields: [] },
        //             }
        //           }
        //           procedureItemsCount={targetedData?.attributes?.procedure?.data?.attributes?.procedureItems}
        //           WOStatus = {(targetedData?.attributes?.status == "completed" || targetedData?.attributes?.status == "inReview") }
        //         />
        //       </div>
        //     ),
        //   },
        // } 
        // : {}

        // {
        //   menuItem: `${t.workOrders.details_view.log}`,
        //   pane: {
        //     key: 'log',
        //     content: (
        //       <div className="pane-body">
        //         <Logs targetedData={data} />
        //       </div>
        //     ),
        //   },
        // },
        // {
        //   menuItem: `${t.workOrders.details_view.reports}`,
        //   pane: {
        //     key: 'report',
        //     content: (
        //       <div className="pane-body">
        //         <Report report={data} />
        //       </div>
        //     ),
        //   },
        // },
      ]
    : [
        {
          menuItem: `${t.workOrders.details_view.details}`,
          pane: {
            key: 'details',
            content: (
              <div className="pane-body">
                <Details
                  data={data}
                  negative={negative}
                  untoggle={untoggle}
                  setModalWidth={setModalWidth}
                  setRefresh={setRefresh}
                  refresh={refresh}
                  setSearchTitle={setSearchTitle}
                  setSelectedDates={setSelectedDates}
                  setFilterOptions={setFilterOptions}
                />
              </div>
            ),
          },
        },
        // {
        //   menuItem: `${t.workOrders.details_view.assignees}`,
        //   pane: {
        //     key: 'assignes',
        //     content: (
        //       <div className="pane-body">
        //         <Assignees data={data} />
        //       </div>
        //     ),
        //   },
        // },
        // {
        //   menuItem: `${t.workOrders.details_view.attachments}`,
        //   pane: {
        //     key: 'attachments',
        //     content: (
        //       <div className="pane-body">
        //         <Attachments data={data} />
        //       </div>
        //     ),
        //   },
        // },
        {
          menuItem: `${t.workOrders.details_view.comments}`,
          pane: {
            key: 'comment',
            content: (
              <div className="pane-body">
                <Comment data={data} />
              </div>
            ),
          },
        },
        // {
        //   menuItem: `${t.workOrders.details_view.procedure_items}`,
        //   pane: {
        //     key: 'procedure-items',
        //     content: (
        //       <div className="pane-body">
        //         <ProcedureItems
        //           targetedData={
        //             data?.attributes?.report?.data || {
        //               attributes: { fields: [] },
        //             }
        //           }
        //           WOStatus = {(targetedData?.attributes?.status == "completed" || targetedData?.attributes?.status == "inReview") }
        //           procedureItemsCount={targetedData?.attributes?.procedure?.data?.attributes?.procedureItems}
        //         />
        //       </div>
        //     ),
        //   },
        // },
        // {
        //   menuItem: `${t.workOrders.details_view.log}`,
        //   pane: {
        //     key: 'log',
        //     content: (
        //       <div className="pane-body">
        //         <Logs targetedData={data} />
        //       </div>
        //     ),
        //   },
        // },
      ];


      if (data?.attributes?.procedure?.data) {
        const procedureItemsPane = {
          menuItem: `${t.workOrders.details_view.procedure_items}`,
          pane: {
            key: 'procedure-items',
            content: (
              <div className="pane-body">
                <ProcedureItems
                  targetedData={
                    data?.attributes?.report?.data || { attributes: { fields: [] } }
                  }
                  procedureItemsCount={
                    data?.attributes?.procedure?.data?.attributes?.procedureItems
                  }
                  WOStatus={data?.attributes?.status === 'completed' || data?.attributes?.status === 'inReview'}
                />
              </div>
            ),
          },
        };
      
        panes.push(procedureItemsPane);
      }
  /*
   * The `deleteHandler` function handles the deletion of a work order and displays a modal based on the response status.
   */
  const deleteHandler = async () => {
    const response = await deleteWorkOrder(targetedData.id, deleteSeries);

    if (response.status == 200) {
      toast.success(t.workOrders.form.deleted.msg)
      setFilterOptions({
        serialNumber: '',
        people: [],
        teams: [],
        customers: [],
        locations: [],
        createdByUser: [],
        status: [],
        priority: [],
        categories: [],
      });
      setSearchTitle('');
      toggleDeleteSuccessModal('close');
      setDeleteSeries(false)
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.workOrders.details_view.delete_not_allowed_message)
      toggleDeleteSuccessModal();
      setDeleteSeries(false)

    } else {
      toast.error(t.common.something_wrong);
      setDeleteSeries(false)
    }
  };

  /* The function `downloadXLS` fetches WO data, formats it, and downloads it as an Excel file. */
  const downloadXLS = () => {
    let csvData = [];

    let workOrder_data = [];
    workOrder_data.id = data?.id;
    workOrder_data.title = data?.attributes?.title || t.common.na;
    workOrder_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;
    const workOrderStatus = statusOptions.find(
      (status) => status.value === data?.attributes?.status
    );
    workOrder_data.status = workOrderStatus?.label || t.common.na;

    workOrder_data.priority =
      humanize(data?.attributes?.priority) || t.common.na;
    let assigneePeople = data?.attributes?.people?.data;
    let assignString = '';

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map(
          (people) =>
            `${people?.attributes?.firstName || ''} ${people.attributes
              .lastName || ''}`
        )
        .join(', ');
    } else {
      assignString = t.common.na;
    }
    workOrder_data.assignee = assignString;

    const categoryStatus = categoryOptions.find(
      (one) => one.value == data?.attributes?.category
    );
    workOrder_data.category = categoryStatus?.label || t.common.na;

    const codeStatus = codeOptions?.find(
      (one) => one.value == data.attributes.code
    );
    workOrder_data.code = codeStatus?.label || t.common.na;
    workOrder_data.customer = data?.attributes?.customer?.data?.attributes?.name
      ? data.attributes.customer.data.attributes.name
      : t.common.na;
    workOrder_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    workOrder_data.procedure = data.attributes?.procedure?.data?.attributes
      ?.title
      ? data.attributes?.procedure?.data?.attributes?.title
      : t.common.na;
    let partsData = data.attributes?.parts?.data;
    let partsString = '';

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(', ');
    } else {
      partsString = t.common.na;
    }

    workOrder_data.parts = partsString;

    workOrder_data.estimationTime = data.attributes?.estimationTime
      ? `${data.attributes?.estimationTime}` + ' Hour'
      : t.common.na;

    workOrder_data.dueDate =
      moment(data?.attributes?.dueDate).format('DD-MM-YYYY LT') || t.common.na;

    workOrder_data.createdByUser =
      data?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;

    workOrder_data.createdAt =
      moment(data.attributes.createdAt).format('DD-MM-YYYY LT') || t.common.na;

    csvData.push([
      workOrder_data.id,
      workOrder_data.title,
      workOrder_data.description,
      workOrder_data.status,
      workOrder_data.priority,
      workOrder_data.assignee,
      workOrder_data.category,
      workOrder_data.code,
      workOrder_data.customer,
      workOrder_data.location,
      workOrder_data.procedure,
      workOrder_data.estimationTime,
      workOrder_data.dueDate,
      workOrder_data.createdByUser,
      workOrder_data.createdAt,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Title column
      { wch: 35 }, // Description column
      { wch: 25 }, // Category column
      { wch: 15 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 20 }, // Created At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      data?.attributes?.title || t.workOrders.details_view.work_order_no
    );
  };

  /*
   * The function `generatePDF` fetches WO data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = () => {
    let csvData = [];

    let workOrder_data = [];
    workOrder_data.id = data?.id;
    workOrder_data.title = data?.attributes?.title || t.common.na;
    workOrder_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;
    const workOrderStatus = statusOptions.find(
      (status) => status.value === data?.attributes?.status
    );
    workOrder_data.status = workOrderStatus?.label || t.common.na;

    workOrder_data.priority =
      humanize(data?.attributes?.priority) || t.common.na;
    let assigneePeople = data?.attributes?.people?.data;
    let assignString = '';

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople
        .map(
          (people) =>
            `${people?.attributes?.firstName || ''} ${people.attributes
              .lastName || ''}`
        )
        .join(', ');
    } else {
      assignString = t.common.na;
    }
    workOrder_data.assignee = assignString;

    const categoryStatus = categoryOptions.find(
      (one) => one.value == data?.attributes?.category
    );
    workOrder_data.category = categoryStatus?.label || t.common.na;

    const codeStatus = codeOptions?.find(
      (one) => one.value == data.attributes.code
    );
    workOrder_data.code = codeStatus?.label || t.common.na;
    workOrder_data.customer = data?.attributes?.customer?.data?.attributes?.name
      ? data.attributes.customer.data.attributes.name
      : t.common.na;
    workOrder_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    workOrder_data.procedure = data.attributes?.procedure?.data?.attributes
      ?.title
      ? data.attributes?.procedure?.data?.attributes?.title
      : t.common.na;
    let partsData = data.attributes?.parts?.data;
    let partsString = '';

    if (partsData?.length > 0) {
      partsString = partsData.map((each) => each.attributes?.name).join(', ');
    } else {
      partsString = t.common.na;
    }

    workOrder_data.parts = partsString;

    workOrder_data.estimationTime = data.attributes?.estimationTime
      ? `${data.attributes?.estimationTime}` + ' Hour'
      : t.common.na;

    workOrder_data.dueDate =
      moment(data?.attributes?.dueDate).format('DD-MM-YYYY LT') || t.common.na;

    workOrder_data.createdByUser =
      data?.attributes?.createdByUser?.data?.attributes?.firstName ||
      t.common.na;

    workOrder_data.createdAt =
      moment(data.attributes.createdAt).format('DD-MM-YYYY LT') || t.common.na;

    csvData.push([
      workOrder_data.id,
      workOrder_data.title,
      workOrder_data.description,
      workOrder_data.status,
      workOrder_data.priority,
      workOrder_data.assignee,
      workOrder_data.category,
      workOrder_data.code,
      workOrder_data.customer,
      workOrder_data.location,
      workOrder_data.procedure,
      workOrder_data.estimationTime,
      workOrder_data.dueDate,
      workOrder_data.createdByUser,
      workOrder_data.createdAt,
    ]);

    const columnWidths = [10, 25, 25, 20, 15, 50, 22, 15, 20, 25, 25, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      data?.attributes?.title || t.workOrders.details_view.work_order_no,
      columnWidths,
      'A2'
    );
  };

  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    const currentPathname = window.location.pathname;
    if (data) {
      untoggle();
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setRefresh(!refresh);

      if (currentPathname == '/work-orders') {
        setSearchTitle('');
        setSelectedDates([]);
        setFilterOptions({
          serialNumber: '',
          assignees: {
            people: [],
            teams: [],
          },
          people: [],
          teams: [],
          customers: [],
          locations: [],
          createdByUser: [],
          dueDate: {
            value: 0,
            text: 'Any Date',
          },
          status: [],
          priority: [],
          categories: [],
        });
      }
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? 'aside-modal preview active procedure'
            : 'aside-modal preview procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header detail-header">
            <div className="flexbox justify-between align-center">
              <div className="name">
                {t.workOrders.details_view.work_order_no} #{data?.attributes?.countId }
              </div>

              <div className="flexbox align-center workorder-header-buttons">
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setShowPdfTooltip(true)}
                  onMouseLeave={() => setShowPdfTooltip(false)}
                  onClick={() => generatePDF()}
                >
                  <DownloadIconBucket />
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showPdfTooltip}
                    textBoxWidth="60px"
                    arrowAlign="center"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.export_pdf}
                    </span>
                  </Tooltip>
                </span>
                {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <Tooltip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </Tooltip>
              </span> */}
                {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />

                <Tooltip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </Tooltip>
              </span> */}

                {(userPermissions?.['work-order']?.edit ||
                  checkForPermission(
                    targetedData?.attributes?.createdByUser?.data?.id
                  )) &&
                targetedData?.attributes?.status !== 'completed' && targetedData?.attributes?.status !== 'inProgress' &&
                targetedData?.attributes?.status !== 'cancelled' ? (
                  <span
                    className="header-button c-pointer mt-1"
                    style={{ position: 'relative' }}
                    onMouseOver={() => setEditTooltip(true)}
                    onMouseLeave={() => setEditTooltip(false)}
                    onClick={() => {
                      if (targetedData.attributes.orderType == 'scheduled') {
                        setSeriesModal(true);
                      } else {
                        edit();
                      }
                      setPopupIsOpen(false);
                    }}
                  >
                    <EditTextIcon />
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="bottom center"
                      show={showEditTooltip}
                      textBoxWidth="33px"
                      arrowAlign="center"
                      padding="5px 5px 5px 8px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.edit}
                      </span>
                    </Tooltip>
                  </span>
                ) : null}
                {(userPermissions?.['work-order']?.delete
              // checkForPermission(
              //   targetedData?.attributes?.createdByUser?.data?.id
              // ) 
              )&& targetedData?.attributes.status == 'open'? (
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    if (targetedData.attributes.orderType == 'scheduled') {
                      setDeleteSeriesModal(true);
                    } else {
                      setDeletePermissionModal(true);
                    }
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                     arrowAlign="center"
                  position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                  
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </Tooltip>
                </span>
              ) : null}
                <span
                  className={`procedure-header-button c-pointer ${
                    selectedLanguage == 'en' ? 'ml-1' : 'mr-3'
                  }`}
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() => {
                    untoggle();
                    setModalWidth(Math.max(window.innerWidth / 2, 514));
                  }}
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          {negative &&
          (data?.attributes?.status == 'open' ||
            data?.attributes?.status == 'inProgress') ? (
            <div className="alert">
              {t.workOrders.details_view.past_due_statement}{' '}
              <Moment
                date={
                  data?.attributes?.dueDate + 'T' + data?.attributes?.dueTime
                }
                format="DD-MM-YYYY LT"
              />
            </div>
          ) : (
            ''
          )}
          <div className="body">
            {data ? (
              <Tab
                panes={panes}
                style={{ paddingBottom: '16px' }}
                className={'location-tab'}
                renderActiveOnly={false}
              />
            ) : (
              <Dimmer active inverted>
                <Loader inverted>{t.common.loading}</Loader>
              </Dimmer>
            )}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => {
          setData();
          untoggle();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
      ></div>

      {/* Delete Permission Modal */}
      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.workOrders.details_view.work_order_no}
      >
        <span>{t.workOrders.form.delete.msg}</span>
      </DeletePermissionModal>

      {/* Delete Result Modal */}
      <DeleteResultModal
        closeCallback={() => {
          toggleDeleteSuccessModal(deleteErrorMessage ? '' : 'close');
        }}
        deleteErrorMessage={deleteErrorMessage}
      >
        <div className="mb-2">
          {deleteErrorMessage ? <Close /> : <Success />}
        </div>
        <p className="status-modal-popup-message">
          {deleteErrorMessage
            ? t.workOrders.details_view.delete_not_allowed_message
            : t.workOrders.form.deleted.msg}
        </p>
      </DeleteResultModal>
      {/* series edit modal */}
      <Modal
        size="tiny"
        className="assets-modal"
        open={seriesModal}
        onClose={() => {
          setSeriesModal(false);
          setSeriesEdit(false);
        }}
        style={{ minHeight: '191px', maxWidth: '472px' }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.form_title.edit_small}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setSeriesModal(false);
                setSeriesEdit(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          <p
            className="assets-label ml-1 mt-2  "
            style={{ lineHeight: '10px' }}
          >
            {t.workOrders.WO_edit}
          </p>
          <p className="assets-label ml-1" style={{ lineHeight: '10px' }}>
            {t.workOrders.what_edit}
          </p>
          <div className="d-flex flex-column mt-8 ml-3 radio-text">
            {/* <div class="ui radio checkbox">
              <input
                onChange={() => {
                  setSeriesEdit(false);
                }}
                type="radio"
                value={seriesEdit ? 'false' : 'true'}
                class="hidden"
                checked={!seriesEdit}
              />
              <label className="radio-text mt-2">This work order </label>
            </div>

            {console.log(seriesEdit, 'asd')}

            <div class="ui radio checkbox"  onchange={handleEntireSeriesChange}>
              <input
                value={seriesEdit ? 'true' : 'false'}
                checked={seriesEdit}
               
                type="radio"
                class="hidden"
              />
              <label className="radio-text ">The entire series</label>
            </div> */}
            <div className="d-flex">
              <Radio
                // label="This work order "
                name="radioGroup"
                className="wo-radio"
                value={seriesEdit ? 'false' : 'true'}
                checked={!seriesEdit}
                onChange={() => {
                  setSeriesEdit(false);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {' '}
                {t.workOrders.this_wo}
              </label>
            </div>

            <div className="d-flex mt-3">
              <Radio
                className="wo-radio"
                name="radioGroup"
                value={seriesEdit ? 'true' : 'false'}
                checked={seriesEdit}
                onChange={() => {
                  setSeriesEdit(true);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {' '}
                {t.workOrders.this_series}
              </label>
            </div>
          </div>
        </Modal.Content>
        <div
          className={`modal-button-container ${
            selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
          }`}
        >
          <button
            onClick={() => {
              setSeriesModal(false);
              setSeriesEdit(false);
              // closeCallback();
            }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => {
              setSeriesModal(false);

              edit(seriesEdit);
            }}
            className="modal-form-action-button light-blue"
          >
            {t.common.edit}
          </button>
        </div>
        {/* <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            setIsOpen(false);
            yesCallback();
          }}
        >
          {t.common.delete}
        </Button>
        <Button
          color="black"
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
        >
          {t.common.keep}
        </Button>
      </Modal.Actions> */}
      </Modal>
      {/* series Delete modal*/}
      <Modal
        size="tiny"
        className="assets-modal"
        open={deleteSeriesModal}
        onClose={() => {
          setDeleteSeriesModal(false);
          setDeleteSeries(false);
        }}
        style={{ minHeight: '191px', maxWidth: '472px' }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.form_title.delete_small}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setDeleteSeriesModal(false);
                setDeleteSeries(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content>
          <p
            className="assets-label ml-1 mt-2  "
            style={{ lineHeight: '10px' }}
          >
            {t.workOrders.WO_edit}
          </p>
          <p className="assets-label ml-1" style={{ lineHeight: '10px' }}>
            {t.workOrders.what_delete}
          </p>
          <div className="d-flex flex-column mt-8 ml-3 radio-text">
           
            <div className="d-flex">
              <Radio
                // label="This work order "
                name="radioGroup"
                className="wo-radio"
                value={deleteSeries ? 'false' : 'true'}
                checked={!deleteSeries}
                onChange={() => {
                  setDeleteSeries(false);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {' '}
                {t.workOrders.this_wo}
              </label>
            </div>

            <div className="d-flex mt-3">
              <Radio
                className="wo-radio"
                name="radioGroup"
                value={deleteSeries ? 'true' : 'false'}
                checked={deleteSeries}
                onChange={() => {
                  setDeleteSeries(true);
                }}
              />
              <label className="radio-text ml-3 mr-3">
                {' '}
                {t.workOrders.this_series}
              </label>
            </div>
          </div>
        </Modal.Content>
        <div
          className={`modal-button-container ${
            selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
          }`}
        >
          <button
            onClick={() => {
              setDeleteSeriesModal(false);
              setDeleteSeries(false);
              // closeCallback();
            }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => {
              setDeleteSeriesModal(false);
              deleteHandler();

              // edit(seriesEdit);

            }}
            className="modal-form-action-button light-blue"
          >
            {t.common.delete}
          </button>
        </div>
       
      </Modal>
      
    </>
  );
}

export default Preview;
