import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Input } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';
import { createJobTitle } from '../../../config/functions';
import { toast } from 'react-toastify';
import { CloseIcon } from '../../../../Assets/Icons/svg';
import Asterisk from '../../common/Asterisk';

function JobTitleCreateModal({
  isOpen = false,
  setModalOpenState = () => {},
  handleSuccess = () => {},
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const [jobTitleName, setJobTitleName] = useState('');
  const handleJobTitleCreate = async () => {
    if (jobTitleName && jobTitleName.trim()) {
      let res = await createJobTitle(
        jobTitleName.trim(),
        organisationId,
        currentUserId
      );
      if (res && res.status === 200) {
        handleSuccess(res);
        toast.success(t.jobTitle.job_title_create_success);
        setModalOpenState(false);
      } else if (res && res.status === 400) {
        toast.error(t.jobTitle.job_title_already_exist);
        setModalOpenState(false);
      } else {
        toast.error(t.jobTitle.job_title_not_created);
        setModalOpenState(false);
      }
      setJobTitleName('');
    } else {
      toast.error(t.jobTitle.add_job_title_name);
      setJobTitleName('');
    }
  };
  return (
    <Modal
      size="tiny"
      style={{ height: '216px' }}
      className="assets-modal"
      open={isOpen}
      onClose={() => setModalOpenState(false)}
    >
      <div className="header-container">
        <span className="assets-header">{t.jobTitle.add_job}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => setModalOpenState(false)}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content className="">
        <label className="assets-label" style={{ lineHeight: '22px' }}>
          {t.jobTitle.add}
        </label>
      <span className='label-item d-inline'> <Asterisk color="#172B4D" /></span>
      <div className='category-input'>
        <Input
          autoComplete="new-password"
          fluid
          placeholder={t.common.enter_job_name}
          value={jobTitleName}
          onChange={(e) => setJobTitleName(e.target.value)}
        />
        </div>
      </Modal.Content>

      <div
        className={`modal-button-container mt-4 ${
          selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
        }`}
      >
        <button
        className="modal-form-action-button black"
          onClick={() => setModalOpenState(false)}
        >
          {t.common.cancel}
        </button>

        <button
          onClick={() => handleJobTitleCreate()}
          className="modal-form-action-button light-blue"
        >
          {t.common.add}
        </button>
      </div>

      {/* <Modal.Actions>
        <Button color="black" onClick={() => handleJobTitleCreate()}>
          {t.common.add}
        </Button>
        <Button negative onClick={() => setModalOpenState(false)}>
          {t.common.cancel}
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
}

export default JobTitleCreateModal;
