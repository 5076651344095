import React from 'react';
import { Container } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <>
      <div className="terms-conditions">
        <Container>
          <div className="terms">
            <h1>Terms and Conditions</h1>
            <p>
              By accessing or using the services provided by (“Quiqflow”), you
              agree, on behalf of you and your company (“Customer”), to be bound
              by and to comply with these Terms of Use (together with an Order
              Form, the “Agreement”). For good and valuable consideration, the
              receipt of which is acknowledged by each party, the parties agree
              as follows:
            </p>
            <ol className="main-list">
              <li>Definitions.</li>
              <p>
                <u>“Contract Start Date”</u> means the commencement of the
                Initial Term, as set forth in the Order Form.{' '}
              </p>
              <p>
                <u>“Customer Content”</u> means any information, data, images,
                and other content, in any form or medium, that is submitted by
                or collected from or on behalf of Customer through the Services,
                including data and information collected from or pertaining to
                Users, but excluding the Usage Data.
              </p>
              <p>
                <u>“Documentation”</u> means the documentation that Quiqflow
                makes generally available to its customers in any form related
                to the Services.
              </p>
              <p>
                <u>“Effective Date”</u> means the date set forth in the Order
                Form.
              </p>
              <p>
                <u>“Fees”</u> means the fees set forth in an Order Form for the
                Services and Professional Services, if applicable.
              </p>
              <p>
                <u>“Hardware”</u> means any hardware, such as machine sensors,
                that may be resold by Quiqflow to Customer from the original
                seller or manufacturer.
              </p>
              <p>
                <u>“Initial Term”</u> means the initial term length for the
                Services set forth in the Order Form.{' '}
              </p>
              <p>
                <u>“Intellectual Property Rights”</u> means all patent,
                copyright, trademark, trade secret, rights in know-how, business
                domain names, designs, and other proprietary and intellectual
                property rights recognized in any jurisdiction worldwide,
                including moral rights.
              </p>
              <p>
                <u>“Marks”</u> means a party’s name, logo, and trademarks.
              </p>
              <p>
                <u>“Order Form”</u> means the initial order for the Services,
                and any subsequent orders, entered into between the parties in
                writing (including via a web interface), specifying, among other
                things, the Services, Fees, payment terms, and other relevant
                terms as agreed to between the parties.{' '}
              </p>
              <p>
                <u>“Professional Services”</u> means any professional or
                consulting services that are ancillary to the Services rendered
                by Quiqflow to Customer, such as integration services, but
                specifically excluding any support and maintenance provided
                hereunder.
              </p>
              <p>
                <u>“Reports”</u> means the tangible reports generated by
                Customer’s use of the Services.
              </p>
              <p>
                <u>“Services”</u> means the products and services set forth in
                an executed Order Form that are made available by Quiqflow to
                Customer, including the Quiqflow website, software, API, and
                mobile application, but excluding the Hardware.
              </p>
              <p>
                <u>“Services Term”</u> is defined in Section 10.1 below.
              </p>
              <p>
                <u>“Term”</u> is defined in Section 10.1 below.
              </p>
              <p>
                <u>“Third-Party Services”</u> means any products or services
                provided by third parties that may be integrated with or
                accessed through the Services.
              </p>
              <p>
                <u>“Trial”</u> is defined in Section 10.2. Use of the Services
                and the Hardware during the Trial Period (as either defined in
                the Order Form, if applicable, or the period in which Customer
                uses the Services on a trial basis) is subject to the terms of
                this Agreement, as modified by Section 10.2.
              </p>
              <p>
                <u>“Usage Data”</u> means any anonymized and aggregated data
                that is derived from the processing of the Customer Content or
                the access or use of the Services by or on behalf of Customer,
                that in no way identifies or refers to Customer or Users, and
                any statistical or other analysis, information, or data based on
                or derived from the foregoing.
              </p>
              <p>
                <u>“User”</u> means Customer’s employees, independent
                contractors, or other third parties who are authorized by
                Customer to access and use the Services under the rights granted
                to Customer in the Agreement. The number of Users authorized to
                access the Services is set forth on the applicable Order Form,
                if applicable.
              </p>
            </ol>

            <ol className="main-list" start="2">
              <li>The Services.</li>
              <ol className="list list-2">
                <li>
                  Provision of the Services. During the Services Term and
                  subject to the terms and conditions of the Agreement: (i)
                  Customer may access and use the Services pursuant to the terms
                  of the Agreement; and (ii) Quiqflow hereby grants to Customer
                  a limited, non-exclusive, non-transferable (except as set
                  forth in Section 11), non-sublicensable license to use and
                  copy the Documentation as reasonably required to access and
                  use the Services. Quiqflow will use commercially reasonable
                  efforts to maintain the security of the Services.
                </li>
                <li>
                  <u>Modifications.</u> Quiqflow may revise or remove features,
                  functionality, or service levels of the Services at any time.
                  If any such revision or removal materially degrades Customer’s
                  use of the Services, Customer may within thirty (30) days’
                  notice of the revision or removal terminate the applicable
                  Order Form, without cause. Quiqflow will use commercially
                  reasonable efforts to notify Customer of any changes to the
                  Services via email and/or by posting such changes to
                  Quiqflow’s website or platforms.{' '}
                </li>
                <li>
                  Privacy Policy. Quiqflow’s privacy policy (located at
                  https://Quiqflow.com/privacy)is incorporated herein by
                  reference in relation to any data collected from Users through
                  their use of the Services. Quiqflow reserves the right to
                  modify its privacy policy in its reasonable discretion from
                  time to time.
                </li>
                <li>
                  <u>Subcontractors.</u> Customer acknowledges and agrees that
                  Quiqflow may use subcontractors and other third-party service
                  providers in its provision of the Services, provided that
                  Quiqflow remains liable for such third party’s breach of the
                  Agreement.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="3">
              <li>Customer’s Responsibilities.</li>
              <ol className="list list-3">
                <li>
                  <u>Use of the Services.</u> Customer shall: (i) have sole
                  responsibility for the accuracy, quality, integrity, legality,
                  reliability, and appropriateness of all the Customer Content;
                  (ii) use commercially reasonable efforts to prevent
                  unauthorized access to, or use of, the Services and notify
                  Quiqflow immediately of any such unauthorized access or use;
                  and (iii) provide all necessary personnel and other resources,
                  as reasonably requested by Quiqflow, to enable Quiqflow to
                  fulfill its obligations hereunder. Customer is fully
                  responsible and liable for all Users’ use of the Services. In
                  the event Customer suspects a data or security breach,
                  Customer may refer to the Privacy Policy for notification
                  procedures.{' '}
                </li>
                <li>
                  <u>Restrictions.</u> Customer may use the Services solely as
                  set forth in the Agreement and will not: (i) copy, modify, or
                  create derivative works or improvements of the Services; (ii)
                  reverse engineer, disassemble, decompile, modify, or alter the
                  Services; (iii) remove, circumvent, disable, damage, or
                  otherwise interfere with or disrupt the integrity or
                  performance of the Services or any security-related features
                  therein; (iv) input, upload, transmit, or otherwise provide to
                  or through the Services, any information or materials that are
                  unlawful or injurious, or contain, transmit, send, or store
                  material containing harmful computer code, files, scripts,
                  agents, or programs; (v) rent, lease, loan, resell,
                  sublicense, distribute, or otherwise commercially exploit or
                  make available the Services to any third party or use the
                  Services for service bureau or time-sharing purposes; or (vi)
                  develop or have developed any products, services, or other
                  materials that compete with the Services or otherwise compete
                  with Quiqflow’s business. Each User’s right to access and use
                  the Services through its individual account is personal to
                  such User and such account may not be shared with other
                  individuals. A User account may only be reassigned to a new
                  User who is replacing the applicable User.
                </li>
                <li>
                  <u>Customer Content.</u> Customer hereby grants to Quiqflow a
                  limited, non-exclusive, non-transferable (except as set forth
                  in Section 11) right and license during the applicable
                  Services Term to: (i) download, receive, collect, access,
                  modify, copy, store, retain, and otherwise use the Customer
                  Content in order to provide and support the Services in
                  accordance with the Agreement and to internally develop and
                  improve its products and services; and (ii) provide access to
                  the Customer Content by Third-Party Services selected by
                  Customer or otherwise upon Customer’s consent or direction.
                  Customer acknowledges and agrees that Quiqflow has no
                  responsibility for the services or content of any Third-Party
                  Services and will have no liability for the acts or omissions
                  of such Third-Party Services. Any activities related to
                  Third-Party Services, and any terms associated with such
                  activities, are solely between Customer and the applicable
                  Third-Party Services.
                </li>
                <li>
                  <u>Aggregate Data.</u> Customer hereby grants to Quiqflow a
                  non-exclusive, worldwide, perpetual, irrevocable, fully paid,
                  royalty-free right and license to collect, retain, store, use,
                  modify, distribute, and disclose the Customer Content in
                  aggregated and anonymized form and any inferred insight
                  relating to the Customer Content for any purpose whatsoever,
                  provided that any such disclosure does not identify Customer
                  or Users by name or description sufficient to identify
                  Customer or Users as the source or subject of such
                  information, in whole or in part.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="4">
              <li>Proprietary Rights.</li>
              <ol className="list list-4">
                <li>
                  <u>Customer IP.</u> As between Customer and Quiqflow, Customer
                  owns all right, title, and interest in and to: (i) the
                  Customer Content and Customer’s Marks; (ii) Customer’s
                  Confidential Information; (iii) the Reports (excluding the
                  Quiqflow IP as defined below); and (iv) all Intellectual
                  Property Rights in the foregoing (collectively, the “Customer
                  IP”). Except as expressly set forth in the Agreement, no
                  license or other rights to any of the Customer IP are granted
                  to Quiqflow, and all such rights are hereby expressly
                  reserved.
                </li>
                <li>
                  <u>Quiqflow IP.</u> Quiqflow owns all right, title, and
                  interest in and to: (i) the Services, the Documentation, and
                  Quiqflow’s Marks, and any and all enhancements, improvements,
                  developments, derivative works, or other modifications
                  (including any changes which incorporate any of Customer’s
                  ideas, feedback, or suggestions) made or related to the
                  Services or the Documentation; (ii) the Usage Data; (iii)
                  Quiqflow’s Confidential Information; and (iv) all Intellectual
                  Property Rights in the foregoing (collectively, the “Quiqflow
                  IP”). Except as expressly set forth in the Agreement, no
                  license or other rights to any of the Quiqflow IP are granted
                  to Customer, and all such rights are hereby expressly
                  reserved.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="5">
              <li>Confidentiality.</li>
              <ol className="list list-5">
                <li>
                  <u>Confidential Information.</u> As used herein, “Confidential
                  Information” means all non-public information disclosed by a
                  party (the “Disclosing Party”) to the other party (the
                  “Receiving Party”), whether orally or in writing, that is
                  designated as “Confidential,” “Proprietary,” or the like
                  and/or information that the Receiving Party should reasonably
                  understand as being confidential or proprietary to the
                  Disclosing Party given the nature of the information and the
                  circumstances of the disclosure, including without limitation,
                  the Agreement and the terms set forth in the Order Form (which
                  will be deemed Confidential Information of both parties),
                  business and marketing plans, financial information,
                  technology and technical information, designs, and business
                  processes. Confidential Information will not include any
                  information that: (i) is or becomes generally known to the
                  public without the Receiving Party's breach of any obligation
                  owed to the Disclosing Party; (ii) was in the rightful
                  possession or known by the Receiving Party prior to receipt
                  from the Disclosing Party without breach of any obligation
                  owed to the Disclosing Party; (iii) is received from a third
                  party who obtained such Confidential Information without any
                  third party's breach of any obligation owed to the Disclosing
                  Party; or (iv) was independently developed by the Receiving
                  Party without the Receiving Party's breach of any obligation
                  owed to the Disclosing Party.
                </li>
                <li>
                  <u>Protection.</u> Each party will not disclose the other
                  party’s Confidential Information, or use the other party’s
                  Confidential information, for any purpose other than to
                  perform its obligations or exercise its rights under the
                  Agreement, and will protect the confidentiality of the
                  Confidential Information of the other party in the same manner
                  that it protects the confidentiality of its own proprietary
                  and confidential information of like kind, but in no event
                  will either party exercise less than reasonable care in
                  protecting such Confidential Information. The Receiving Party
                  may disclose Confidential Information to its employees,
                  agents, contractors, Affiliates, and other representatives
                  having a legitimate need to know, provided that such
                  representatives are bound to confidentiality obligations no
                  less protective of the Disclosing Party than this Section 6
                  and that the Receiving Party remains responsible for
                  compliance by any such representative with the terms of this
                  Section 6.
                </li>
                <li>
                  <u>Compelled Disclosure.</u> If the Receiving Party is
                  compelled by law to disclose Confidential Information of the
                  Disclosing Party, it will provide the Disclosing Party with
                  prior written notice of such compelled disclosure and
                  reasonable assistance (at Disclosing Party's cost) if the
                  Disclosing Party wishes to contest the disclosure.
                </li>
                <li>
                  <u>Remedies.</u> If the Receiving Party discloses or uses (or
                  threatens to disclose or use) any Confidential Information of
                  the Disclosing Party in breach of this Section 6, the
                  Disclosing Party will have the right, in addition to any other
                  remedies available to it, to seek injunctive relief to enjoin
                  such acts, it being specifically acknowledged by the parties
                  that any other available remedies are inadequate.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="6">
              <li>Representations & Warranties.</li>
              <ol className="list list-6">
                <li>
                  <u>Mutual Warranties.</u> Each party represents and warrants
                  that: (i) it is duly organized, validly existing, and in good
                  standing under the laws of its jurisdiction of formation; (ii)
                  it has full right, power, and authority to enter into and
                  perform its obligations under the Agreement; (iii) the
                  Agreement, when executed and delivered, will constitute a
                  valid and binding obligation of such party and will be
                  enforceable against such party in accordance with its terms;
                  and (iv) the performance of its obligations under the
                  Agreement will not violate any applicable law, rule, or
                  regulation, including those related to data privacy.
                </li>
                <li>
                  <u>By Customer.</u> Customer represents and warrants that its
                  use of the Services, including the Customer Content provided
                  in connection with the Services, will not violate any
                  applicable law, rule, or regulation, infringe any third
                  party’s intellectual property, privacy, or publicity right, or
                  cause a breach of any agreement with any third party.
                </li>
                <li>
                  <u>By Quiqflow.</u> Quiqflow represents and warrants that the
                  Services and Professional Services, as applicable, will be
                  provided in all material respects in accordance with the
                  Documentation and otherwise be performed in a manner
                  consistent with generally accepted industry standards.
                  Customer’s sole remedy and Quiqflow’s sole obligation for any
                  such failure will be for Quiqflow to use commercially
                  reasonable efforts to correct such non-compliance, provided
                  that in the event such non-compliance cannot be corrected
                  within thirty (30) days, Customer may terminate the Agreement
                  and receive a prorated refund for any prepaid, unused Fees.
                </li>
                <li>
                  <u>Disclaimer of Warranties.</u> EXCEPT AS EXPRESSLY SET FORTH
                  IN THIS SECTION 7, THE SERVICES, THE REPORTS, AND ALL RELATED
                  DELIVERABLES AND SERVICES ARE PROVIDED “AS IS.” Quiqflow MAKES
                  NO ADDITIONAL REPRESENTATION OR WARRANTY OF ANY KIND WHETHER
                  EXPRESS, IMPLIED (EITHER IN FACT OR BY OPERATION OF LAW), OR
                  STATUTORY, AS TO ANY MATTER WHATSOEVER. Quiqflow EXPRESSLY
                  DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
                  FOR A PARTICULAR PURPOSE, QUALITY, ACCURACY, TITLE, AND
                  NON-INFRINGEMENT. Quiqflow DOES NOT WARRANT THAT THE SERVICES
                  ARE ERROR-FREE OR WILL BE SECURE OR UNINTERRUPTED. ANY
                  HARDWARE WARRANTY WILL BE AS DETERMINED BY THE OEM. Quiqflow
                  WILL NOT BE LIABLE FOR ANY IMPACT TO THE SERVICES CAUSED BY
                  THE HARDWARE OR CUSTOMER’S MACHINES OR EQUIPMENT.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="7">
              <li>Indemnification.</li>
              <ol className="list list-7">
                <li>
                  <u>By Quiqflow.</u> Subject to the other provisions of this
                  Section 8, Quiqflow will defend, indemnify, and hold Customer
                  and its officers, directors, consultants, employees,
                  successors, and assigns (“Customer Indemnitees”) harmless
                  against any and all losses, damages, liabilities, and costs,
                  including reasonable attorneys’ fees (“Damages”) incurred by
                  Customer Indemnitees arising out of or relating to any
                  third-party claim, suit, action, or proceeding (“Claims”) that
                  allege: (i) Quiqflow’s breach of any of its representations,
                  warranties, or obligations set forth in Sections 6 or 7.1 of
                  the Agreement; and (ii) Customer’s use of the Services in
                  compliance with the Agreement infringes any third party’s
                  Intellectual Property Right (an “IP Claim”). The foregoing
                  obligation does not apply to any IP Claim to the extent
                  arising out of or relating to: (a) modifications to the
                  Services not made or authorized by Quiqflow; (b) materials
                  supplied by or on behalf of Customer, including without
                  limitation the Customer Content; (c) combination of the
                  Services with products or services, including any Third-Party
                  Services and Hardware; (d) designs or instructions provided by
                  Customer to Quiqflow; (e) Customer’s continued use of the
                  allegedly infringing material after being notified of
                  modifications that would have avoided such alleged
                  infringement; or (f) Customer’s use of the Services not
                  strictly in accordance with the Agreement.
                </li>
                <li>
                  <u>Infringement Remedies.</u> If Quiqflow or a court or other
                  body of competent jurisdiction determines that the Services is
                  or may be infringing, Quiqflow may, at its option and expense:
                  (i) replace or modify the Services to be non-infringing,
                  provided that such modification or replacement contains
                  substantially similar features and functionality; (ii) obtain
                  for Customer a license to continue using the Services; or
                  (iii) if neither of the foregoing is commercially practicable,
                  terminate the Agreement and Customer’s rights hereunder and
                  provide Customer a prorated refund for any prepaid, unused
                  Fees. THE REMEDIES SET FORTH IN THIS SECTION 8 STATE
                  CUSTOMER’S SOLE AND EXCLUSIVE REMEDY AND Quiqflow’S SOLE AND
                  EXCLUSIVE LIABILITY IN RELATION TO AN IP CLAIM.
                </li>
                <li>
                  <u>By Customer.</u> Subject to the other provisions of this
                  Section 8, Customer will defend, indemnify, and hold Quiqflow
                  and its officers, directors, consultants, employees,
                  successors, and assigns (“Quiqflow Indemnitees”) harmless
                  against any and all Damages incurred by Quiqflow Indemnitees
                  arising out of or relating to a Claim that: (i) alleges
                  Customer’s breach of any of its representations, warranties,
                  or obligations set forth in Sections 3, 6, or 7 of the
                  Agreement; and (ii) relates to the Customer Content as used by
                  Quiqflow in accordance with the Agreement.
                </li>
                <li>
                  <u>Procedure.</u> Each indemnifying party’s obligations as set
                  forth in this Section 8 are contingent on: (i) the indemnitee
                  providing the indemnifying party with prompt written notice of
                  the Claim (and any information that may reasonably give rise
                  to a Claim), but only if the indemnifying party is materially
                  adversely prejudiced by failure to receive such notice; (ii)
                  the indemnifying party having the right to defend the Claim at
                  its own expense and with counsel of its choosing; (iii) the
                  indemnifying party having the right, in its sole discretion,
                  to settle the Claim so long as such settlement does not impose
                  any monetary or material non-monetary obligations on the
                  indemnitee (other than indemnitee no longer using the
                  Services, as the case may be), and provided that the
                  indemnitee will be included in any release language as part of
                  any such settlement; and (iv) the indemnitee providing (at the
                  indemnifying party’s expense) such assistance and information
                  as the indemnifying party may reasonably require to
                  investigate, defend, or settle the Claim.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="8">
              <li>Limitations of Liability.</li>
              <ol className="list list-8">
                <li>
                  <u>Consequential Damages Exclusion.</u> EXCEPT FOR: (i) A
                  BREACH BY EITHER PARTY OF ITS CONFIDENTIALITY OBLIGATIONS IN
                  SECTION 6; (ii) EACH PARTY’S RESPECTIVE INDEMNIFICATION
                  OBLIGATIONS IN THIS AGREEMENT; OR (iii) INTENTIONAL MISCONDUCT
                  OR GROSS NEGLIGENCE (THE “EXCLUSIONS”), NEITHER PARTY MAY BE
                  HELD LIABLE FOR ANY LOSS OF PROFITS, LOSS OF USE, LOSS OF GOOD
                  WILL, BUSINESS INTERRUPTION, COMPUTER FAILURE OR MALFUNCTION,
                  LOSS OF CONTENT OR DATA, COST OF COVER, OR INDIRECT, PUNITIVE,
                  SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES OF ANY KIND
                  ARISING OUT OF THE AGREEMENT.
                </li>
                <li>
                  <u>Limitation of Liability.</u> EXCEPT FOR THE EXCLUSIONS,
                  Quiqflow’S MAXIMUM CUMULATIVE LIABILITY ARISING FROM OR
                  RELATING TO THE AGREEMENT FOR ANY CAUSE WHATSOEVER, REGARDLESS
                  OF THE FORM OF ANY CLAIM OR ACTION, WHETHER BASED IN CONTRACT,
                  TORT, OR ANY OTHER LEGAL THEORY, WILL NOT EXCEED THE AGGREGATE
                  FEES PAID BY CUSTOMER TO Quiqflow IN THE TWELVE (12) MONTH
                  PERIOD IMMEDIATELY PRECEDING THE OCCURRENCE OF THE EVENT
                  GIVING RISE TO THE CLAIM. Quiqflow’S TOTAL LIABILITY WITH
                  RESPECT TO AN IP CLAIM UNDER SECTION 8.1 WILL NOT EXCEED THE
                  GREATER OF (1) TWO MILLION DOLLARS ($2,000,000), OR (2) TWO
                  TIMES THE TOTAL FEES PAID BY CUSTOMER TO Quiqflow IN THE PRIOR
                  TWELVE (12) MONTH PERIOD.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="9">
              <li>Term & Termination</li>
              <ol className="list list-9">
                <li>
                  <u>Term.</u> The Agreement begins on the Effective Date and,
                  unless earlier terminated pursuant to the Agreement, will
                  continue in effect for so long as there is an active Order
                  Form (the “Term”). The term of each Order Form will commence
                  on the Contract Start Date. Unless otherwise stated in the
                  applicable Order Form, each Order Form will automatically
                  renew for successive twelve (12) month periods (each, a
                  “Renewal Term,” and the Initial Term and each Renewal Term,
                  the “Services Term”), unless either party provides at least
                  thirty (30) days’ prior written notice (email shall suffice)
                  to the other party of its intent not to renew or to downgrade
                  the Services.
                </li>

                <li>
                  <u>Termination for Cause.</u> Either party may terminate the
                  Agreement or any Order Form with immediate effect, in whole or
                  in part, by giving the other party prior written notice, if
                  the other party: (i) commits a material breach of any of its
                  obligations under the Agreement, which breach is not cured
                  within thirty (30) days following receipt of written notice,
                  or the parties agree cannot be cured within thirty (30) days;
                  (ii) becomes insolvent or is generally unable to pay, or fails
                  to pay, its debts as they become due; (iii) files or has filed
                  against it, a petition for voluntary or involuntary bankruptcy
                  or otherwise becomes subject, voluntarily or involuntarily, to
                  any proceeding under any domestic or foreign bankruptcy or
                  insolvency law; (iv) makes or seeks to make a general
                  assignment for the benefit of its creditors; (v) applies for
                  or has appointed a receiver, trustee, custodian, or similar
                  agent appointed by order of any court of competent
                  jurisdiction to take charge of or sell any material portion of
                  its property or business; or (vi) has wound up or liquidated
                  its business, voluntarily or otherwise.
                </li>
                <li>
                  <u>Termination or Suspension by Quiqflow.</u> Quiqflow may
                  terminate the Agreement or the applicable Order Form upon
                  Customer’s failure to make any payments due hereunder, and not
                  disputed in good faith, within five (5) business days
                  following Customer’s receipt of written notice regarding such
                  failure to pay. In the event that Quiqflow reasonably suspects
                  Customer or a User is in breach of Section 3.2 or any
                  applicable laws, rules, or regulations, Quiqflow may
                  immediately terminate or suspend Customer’s or such User’s
                  access to the Services without advanced notice, in addition to
                  any other remedies as Quiqflow may have.
                </li>
                <li>
                  <u>Effects of Termination.</u> Upon expiration or termination
                  of the Agreement: (i) Customer will cease all use of the
                  applicable Services and delete, destroy, or return all copies
                  of the applicable Documentation in its possession or control;
                  (ii) Customer will be responsible for payment of any monies
                  (including any Fees and expenses) due to Quiqflow for any
                  period prior to the effective date of such termination; and
                  (iii) each party will return or destroy (and provide
                  certification of such deletion upon request) any of the other
                  party’s Confidential Information then in its possession and
                  Quiqflow will delete the Customer Content, provided that
                  Quiqflow may retain one copy of the Customer Content and
                  Customer’s Confidential Information solely for archival
                  purposes or compliance with any legal requirement and in
                  accordance with its data retention policy. Notwithstanding the
                  foregoing, Quiqflow has no obligation to retain the Customer
                  Content after termination of the Agreement.
                </li>
                <li>
                  <u>Surviving Provisions.</u> Sections 1, 3.2, 4, 5, 6, 7.4, 8,
                  9, 10.5, 10.6 and 11 (together with all other provisions,
                  including without limitation, all Order Forms, that may be
                  reasonably interpreted as surviving termination or expiration
                  of the Agreement) will survive the termination or expiration
                  of the Agreement.
                </li>
              </ol>
            </ol>

            <ol className="main-list" start="10">
              <li>General Provisions.</li>
              <ol className="list list-10 two-digit">
                <li>
                  <u>Publicity.</u> Quiqflow may include Customer on its
                  customer lists. Any other use of a party’s Marks is subject to
                  the other party’s prior consent, in each instance. All use of
                  a party’s Marks will be in accordance with its trademark
                  guidelines, if provided.
                </li>
                <li>
                  <u>No Waiver.</u> The Agreement may not be altered, amended,
                  or modified in any way except by a writing signed by both
                  parties. The failure of a party to enforce any provision of
                  the Agreement will not be construed to be a waiver of the
                  right of such party to thereafter enforce that provision or
                  any other provision or right.
                </li>
                <li>
                  <u>Severability.</u> In the event that any provision of the
                  Agreement is determined to be invalid, illegal, or
                  unenforceable by a court of competent jurisdiction, the rest
                  of the Agreement will remain in full force and effect.
                </li>
                <li>
                  <u>Relationship.</u> Quiqflow and Customer are independent
                  contractors and the Agreement will not establish any
                  relationship of partnership, joint venture, employment,
                  franchise, or agency between Quiqflow and Customer. Neither
                  party has any right or authority to assume or create any
                  obligations of any kind or to make any representation or
                  warranty on behalf of the other party, whether express or
                  implied, or to bind the other party in any respect whatsoever.
                  The Agreement does not confer any benefits on any third party
                  unless expressly stated therein.
                </li>
                <li>
                  <u>Force Majeure.</u> Except for Customer’s payment
                  obligations, neither party will be liable for failure to
                  perform or delay in performing any obligation under the
                  Agreement if such failure or delay is due to fire, flood,
                  earthquake, strike, war (declared or undeclared), embargo,
                  blockade, legal prohibition, governmental action, riot,
                  insurrection, epidemic or pandemic, damage, destruction, power
                  outage, telephone outage, internet access provider failure, or
                  any other similar cause beyond its control.
                </li>
                <li>
                  <u>Assignment.</u> Neither party may assign or delegate its
                  rights, duties, and obligations under the Agreement without
                  the other party’s prior written consent, which consent will
                  not be unreasonably withheld or delayed, except that a party
                  may assign the Agreement without the other party’s consent to
                  a successor (including a successor by way of merger,
                  acquisition, sale of assets, or operation of law) if the
                  successor agrees to assume and fulfill all of the assigning
                  party’s obligations under the Agreement; provided that in the
                  event Customer assigns the Agreement to a competitor of
                  Quiqflow (as reasonably determined by Quiqflow), Quiqflow may
                  immediately terminate the Agreement. Subject to the foregoing,
                  the Agreement will bind and inure to the benefit of the
                  parties and their respective successors and permitted assigns.
                </li>
                <li>
                  <u>Notices.</u> Any notice required or permitted by the
                  Agreement will be in writing and will be sent by facsimile,
                  email, courier, or personal delivery, if to Customer then at
                  the address provided on the Order Form, and if to Quiqflow
                  then at Quiqflow’s Address, with Attention to Legal
                  Department, or at such other address for which such party
                  gives notice hereunder. Notice is effective upon receipt.
                  Notwithstanding the foregoing, Customer consents to receive
                  electronic communications that may be sent by Quiqflow
                  (including through the Services, such as posting on Customer’s
                  account) and any such communication will constitute notice
                  under this Section 11.7.
                </li>
                <li>
                  <u>Entire Agreement.</u> The Agreement constitutes the entire
                  agreement and supersedes all prior or contemporaneous oral or
                  written agreements regarding the subject matter hereof. With
                  the exception of an Order Form, any purchase order, written
                  terms or conditions, or other document that Customer sends to
                  Quiqflow (whether prior to or after execution of the
                  Agreement) that contains terms that are different from, in
                  conflict with, or in addition to the terms of the Agreement
                  are hereby rejected and will be void and of no effect.{' '}
                </li>
              </ol>
            </ol>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TermsAndConditions;
