import React, { useState, useEffect } from 'react';
import { Dimmer, Loader, Grid, List } from 'semantic-ui-react';

import {
  humanize,
  getUserProfileByID,
  IMAGES_URL,
} from '../../../config/functions';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';

function Attachments({ targetedData }) {
  const [fetchLoading, setFetchLoading] = useState(false);
  const [data, setData] = useState({
    profile: {},
    attendence: {},
  });
  const t = useSelector(selectTranslations);

  const [attachments, setAttachments] = useState({
    contractCopy: null,
    personalPicture: null,
    passportCopy: null,
    idCopyFront: null,
    visaCopy: null,
    covidCopy: null,
  });

  useEffect(() => {
    setFetchLoading(true);

    const fetchData = async () => {
      if (targetedData.userProfile) {
        const fetchData = await getUserProfileByID(targetedData.userProfile.id);
        if (fetchData) {
          setAttachments({
            contractCopy: fetchData.data.data.attributes.contractCopy?.data,
            personalPicture:
              fetchData.data.data.attributes.personalPicture?.data,
            passportCopy: fetchData.data.data.attributes.passportCopy?.data,
            idCopyFront: fetchData.data.data.attributes.idCopyFront?.data,
            visaCopy: fetchData.data.data.attributes.visaCopy?.data,
            covidCopy: fetchData.data.data.attributes.covidCopy?.data,
          });
          setFetchLoading(false);
        }
      } else {
        setFetchLoading(false);
      }
    };

    fetchData();
  }, [targetedData]);

  return (
    <div className="preview-details-wrapper">
      {fetchLoading ? (
      
         <Loader active inline="centered">{t.common.loading}</Loader>
 
      ) : (
        <>
          <div className="d-flex align-items-center">
            <label className="workorder-intro-label ">
              {t.people.attachments_form.contract_copy}
              {':'}
            </label>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  IMAGES_URL + attachments.contractCopy?.attributes.url,
                  '_blank'
                );
              }}
              className="workorder-intro-value mt-1 d-flex text-left flex-column"
            >
              {attachments?.contractCopy ? (
                <div
                  className="work-order-link  text-left"
                  basic

                  // onClick={() =>
                  //   history.push(`/procedures?id=${each?.id}`)
                  // }
                >
                  <span className="ml-2">
                    {attachments?.contractCopy?.attributes?.name}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label ">
              {t.people.attachments_form.ID_copy_front}
              {':'}
            </label>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  IMAGES_URL + attachments.idCopyFront?.attributes?.url,
                  '_blank'
                );
              }}
              className="workorder-intro-value mt-1 d-flex text-left flex-column"
            >
              {attachments?.idCopyFront ? (
                <div
                  className="work-order-link  text-left"
                  basic

                  // onClick={() =>
                  //   history.push(`/procedures?id=${each?.id}`)
                  // }
                >
                  <span className="ml-2">
                    {attachments?.idCopyFront?.attributes?.name}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label ">
              {t.people.attachments_form.visa_copy}
              {':'}
            </label>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  IMAGES_URL + attachments.visaCopy?.attributes?.url,
                  '_blank'
                );
              }}
              className="workorder-intro-value mt-1 d-flex text-left flex-column"
            >
              {attachments?.visaCopy ? (
                <div
                  className="work-order-link  text-left"
                  basic

                  // onClick={() =>
                  //   history.push(`/procedures?id=${each?.id}`)
                  // }
                >
                  <span className="ml-2">
                    {attachments?.visaCopy?.attributes?.name}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label ">
              {t.people.attachments_form.passport_copy}
              {':'}
            </label>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  IMAGES_URL + attachments.passportCopy?.attributes.url,
                  '_blank'
                );
              }}
              className="workorder-intro-value mt-1 d-flex text-left flex-column"
            >
              {attachments?.passportCopy ? (
                <div
                  className="work-order-link  text-left"
                  basic

                  // onClick={() =>
                  //   history.push(`/procedures?id=${each?.id}`)
                  // }
                >
                  <span className="ml-2">
                    {attachments?.passportCopy?.attributes?.name}
                  </span>
                </div>
              ) : (
                t.common.na
              )}
            </p>
          </div>

          <div className="d-flex align-items-center mt-5">
            <label className="workorder-intro-label ">
              {t.people.attachments_form.certificates}
              {':'}
            </label>
            <p
              style={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(
                  IMAGES_URL + attachments.passportCopy?.attributes?.url,
                  '_blank'
                );
              }}
              className="workorder-intro-value mt-1 d-flex text-left flex-column"
            >
              {attachments?.covidCopy && attachments?.covidCopy.length
                ? attachments?.covidCopy.map((one) => {
                    return (
                      <>
                        <span
                          className="work-order-link  text-left"
                          basic
                          onClick={() => {
                            window.open(
                              IMAGES_URL + one?.attributes.url,
                              '_blank'
                            );
                          }}
                        >
                          {' '}
                          {one?.attributes?.name}
                        </span>
                      </>
                    );
                  })
                : t.common.na}
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default Attachments;
