/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo, useEffect } from "react";
import { Nav, Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import {
  getNotifications,
  markNotificationAsRead,
  markAllNotificationAsRead,
  humanize,
} from "../../../../../app/config/functions";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { selectTranslations } from "../../../../../app/config/i18n/slice";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function UserNotificationsDropdown() {
  const history = useHistory();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const t = useSelector(selectTranslations);
  const userId = useSelector((state) => {
    if (state?.auth?.user) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const [key, setKey] = useState("Alerts");
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [page, setPage] = useState(1);
  const [intervalId, setIntervalId] = useState(null);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.notifications.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  const fetchNotifications = async (page) => {
    let res = await getNotifications(userId);
    if (res && res.status == 200) {
      // console.log('Res', res);
      setNotifications(res.data.data);
      let unReadCount = res.data.data.filter((one) => !one.attributes.isRead);
      setUnreadCount(unReadCount.length);
    }
  };

  useEffect(() => {
    fetchNotifications(page);
  }, [userId]);

  const markAsReadAndNavigate = async (el) => {
    if (!el?.attributes?.isRead) {
      let res = await markNotificationAsRead(el.id);
      await fetchNotifications(page);
    }
    if (
      el?.attributes?.action == "work_order" &&
      el?.attributes?.workOrder?.data?.id
    ) {
      history.push(
        `/work-orders?id=${el?.attributes?.workOrder?.data?.attributes?.countId}`
      );
    }

    if (el?.attributes?.action == "asset" && el?.attributes?.asset?.data?.id) {
      history.push(
        `/assets?id=${el?.attributes?.asset?.data?.attributes?.countId}`
      );
    }

    if (
      el?.attributes?.action == "purchase_order" &&
      el?.attributes?.purchaseOrder?.data?.id
    ) {
      history.push(
        `/purchase-orders?id=${el?.attributes?.purchaseOrder?.data?.attributes?.countId}`
      );
    }
    if (el?.attributes?.action == "part" && el?.attributes?.part?.data?.id) {
      history.push(
        `/parts?id=${el?.attributes?.part?.data?.attributes?.countId}`
      );
    }

    if (
      el?.attributes?.action == "work_request" &&
      el?.attributes?.workRequest?.data?.id
    ) {
      history.push(
        `/work-requests?id=${el?.attributes?.workRequest?.data?.attributes?.countId}`
      );
    }
  };

  const handleFocus = async () => {
    // console.log('Tab is focused');

    const id = setInterval(async () => {
      // console.log('Interval running...');
      await fetchNotifications(page);
    }, 60000); // Interval duration in milliseconds

    // Store the intervalId in state
    setIntervalId(id);
  };

  const handleBlur = async () => {
    // console.log('Tab is blurred');
    // Clear the interval using the stored intervalId
    clearInterval(intervalId);
  };
  useEffect(() => {
    // Add event listeners when the component mounts
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    // Remove event listeners and clear interval when the component unmounts
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);

      // Clear the interval when the component is unmounted
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const handleOpenNotification = async () => {
    await markAllNotificationAsRead();
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary"
            id="kt_quick_notifications_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Compiling.svg")} />
            </span>
            <span className="pulse-ring"></span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="kt_quick_notifications_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="user-notification-tooltip">
                  {t.common.notifications}
                </Tooltip>
              }
            >
              <div
                className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-primary bell-container"
                id="kt_quick_notifications_toggle"
                onClick={handleOpenNotification}
              >
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/new-notification.svg"
                    )}
                  />
                </span>
                {unreadCount ? (
                  <span className="notification-count">{unreadCount}</span>
                ) : null}
                {/* {unreadCount ? (
                  <span className="notification-count">{unreadCount}</span>
                ) : null} */}
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg notification-container">
            <PerfectScrollbar
              options={perfectScrollbarOptions}
              className="navi navi-hover scroll my-4 notification-div"
            >
              {/* <InfiniteScroll
                dataLength={pagedNotifications}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4>Loading...</h4>}
              > */}
              {notifications.map((i, index) => {
                let msg = "";
                if (selectedLanguage == "en") {
                  if (i?.attributes?.notType == "work order status update") {
                    msg = `Work order ${
                      i?.attributes?.workOrder?.data?.attributes?.title
                    } is ${
                      t.workOrders.work_order_status[
                        i?.attributes?.workOrder?.data?.attributes?.status
                      ]
                    }. View Work Order.`;
                  } else if (
                    i?.attributes?.asset?.data &&
                    i?.attributes?.notType == "asset status update"
                  ) {
                    msg = `Asset ${
                      i?.attributes?.asset?.data?.attributes?.name
                    } is ${
                      t.assets.work_order_status[
                        i?.attributes?.asset?.data?.attributes?.status
                      ]
                    }. View Asset.`;
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "work request assign"
                  ) {
                    msg = (
                      <span>
                        {i?.attributes?.workRequest?.data?.attributes
                          ?.submittedBy?.data?.attributes?.firstName +
                          " " +
                          i?.attributes?.workRequest?.data?.attributes
                            ?.submittedBy?.data?.attributes?.lastName}{" "}
                        created{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          Work Request #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                        </span>
                      </span>
                    );
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "Work Request Submitted"
                  ) {
                    msg = (
                      <span>
                        A new work request has been submitted. Request ID:{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                        </span>
                        , Description:{" "}
                        <span
                          style={
                            i?.attributes?.workRequest?.data?.attributes
                              ?.description
                              ? {
                                  color: "#0C66E4",
                                  textDecoration: "underline",
                                }
                              : {}
                          }
                        >
                          {i?.attributes?.workRequest?.data?.attributes
                            ?.description
                            ? i?.attributes?.workRequest?.data?.attributes
                                ?.description
                            : t.common.na}
                          {/* Work Request #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          } */}
                        </span>
                      </span>
                    );
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "work request status update" &&
                    i?.attributes?.workRequest?.data?.attributes?.status ==
                      "declined"
                  ) {
                    msg = (
                      <span>
                        {i?.attributes?.workRequest?.data?.attributes
                          ?.updatedByUser?.data?.attributes?.firstName +
                          " " +
                          i?.attributes?.workRequest?.data?.attributes
                            ?.updatedByUser?.data?.attributes?.lastName}{" "}
                        declined the{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          Work Request #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                          .
                        </span>
                        {
                          i?.attributes?.workRequest?.data?.attributes
                            ?.declinedReason
                        }
                      </span>
                    );
                    // msg = `Work Request ${
                    //   i?.attributes?.workRequest?.data?.attributes?.title
                    // } is ${
                    //   t.workRequests.status[
                    //     i?.attributes?.workRequest?.data?.attributes?.status
                    //   ]
                    // }. View Work Request.`;
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "work request status update" &&
                    i?.attributes?.workRequest?.data?.attributes?.status ==
                      "approved"
                  ) {
                    msg = (
                      <span>
                        {i?.attributes?.workRequest?.data?.attributes
                          ?.updatedByUser?.data?.attributes?.firstName +
                          " " +
                          i?.attributes?.workRequest?.data?.attributes
                            ?.updatedByUser?.data?.attributes?.lastName}{" "}
                        approved the{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          Work Request #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                          .
                        </span>
                      </span>
                    );
                  } else if (i?.attributes?.notType == "work order assign") {
                    msg = `You have received a Work Order ${
                      i?.attributes?.workOrder?.data?.attributes?.title
                    } due ${moment(
                      new Date(
                        i?.attributes?.workOrder?.data?.attributes?.dueDate +
                          "T" +
                          i?.attributes?.workOrder?.data?.attributes?.dueTime
                      ).getTime()
                    ).format("lll")}.`;
                  } else if (
                    i?.attributes?.notType == "A new purchase order is created"
                  ) {
                    // msg = `A new part has been added to the inventory. Part Name: ${i?.attributes?.part?.data?.attributes?.name}, Part Number:  ${i?.attributes?.part?.data?.attributes?.countId}`;

                    msg = (
                      <>
                        <span>A new purchase order </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> has been created. Supplier: </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.vendor?.data?.attributes?.name
                          }
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "approved"
                  ) {
                    msg = (
                      <>
                        {" "}
                        Purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> has been approved by </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.purchaseOrder?.data?.attributes
                            ?.approvedByUser?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.approvedByUser?.data?.attributes?.lastName}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "declined"
                  ) {
                    msg = (
                      <>
                        {" "}
                        Purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> has been declined by </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.user?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.user?.data?.attributes?.lastName}
                        </span>
                        <span> Reason: </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.declinedReason
                          }
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "cancelled"
                  ) {
                    msg = (
                      <>
                        {" "}
                        Purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> has been cancelled by </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.user?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.user?.data?.attributes?.lastName}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "partiallyfulfilled"
                  ) {
                    msg = (
                      <>
                        Partial receipt of items for purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.en?.endsWith("close.")
                  ) {
                    msg = (
                      <>
                        Purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>{" "}
                        has been close.
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "fulfilled"
                  ) {
                    msg = (
                      <>
                        Items for purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>{" "}
                        have been received. Received by:{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.user?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.user?.data?.attributes?.lastName}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "updated purchase order"
                  ) {
                    msg = (
                      <>
                        {" "}
                        Purchase order{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span>
                          {" "}
                          has been amended. Please review the updated details.
                        </span>
                      </>
                    );
                  } else if (i?.attributes?.notType == "new part created") {
                    // msg = `A new part has been added to the inventory. Part Name: ${i?.attributes?.part?.data?.attributes?.name}, Part Number:  ${i?.attributes?.part?.data?.attributes?.countId}`;

                    msg = (
                      <>
                        <span>
                          A new part has been added to the inventory. Part Name:{" "}
                        </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>, Part Number: </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {i?.attributes?.part?.data?.attributes?.countId}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "part updated"
                  ) {
                    msg = (
                      <>
                        {" "}
                        Part{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {" "}
                          details have been updated. Please review the latest
                          information.
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "low part quantity"
                  ) {
                    msg = (
                      <>
                        <span>Alert: Stock level for part </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {" "}
                          has dropped below the minimum threshold. Current
                          Stock:{" "}
                          {i?.attributes?.part?.data?.attributes?.qtyInStock}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "out of stock"
                  ) {
                    msg = (
                      <>
                        <span>Alert: Part </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {" "}
                          is out of stock. Immediate replenishment required.
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "critical"
                  ) {
                    msg = (
                      <>
                        <span>Alert: Part </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {" "}
                          is critical for ongoing operations. Ensure adequate
                          stock levels are maintained.
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.user?.data &&
                    (i?.attributes?.notType ==
                      "User Role Changed in the System" ||
                      i?.attributes?.notType ==
                        "User Role Changed in the System One")
                  ) {
                    msg = (
                      <>
                        <span>{i?.attributes?.en}</span>
                        {/* <span
                          style={{
                            color: '#0C66E4',
                            textDecoration: 'underline',
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {' '}
                          is critical for ongoing operations. Ensure adequate
                          stock levels are maintained.
                        </span> */}
                      </>
                    );
                  }
                  // else if (i?.attributes?.notType == 'purchase order status updated') {
                  // msg = (
                  //   <span>Purchase Order {i?.attributes?.purchaseOrder?.data?.attributes?.details?.title}
                  //   has been {
                  //   t.po.po_status[
                  //     i?.attributes?.purchaseOrder?.data?.attributes?.status
                  //   ]} by {i?.attributes?.workRequest?.data?.attributes
                  //       ?.updatedByUser?.data?.attributes?.firstName +
                  //       ' ' +
                  //       i?.attributes?.workRequest?.data?.attributes
                  //         ?.updatedByUser?.data?.attributes?.lastName}{' '}

                  //     {/* <span
                  //       style={{
                  //         color: '#0C66E4',
                  //         textDecoration: 'underline',
                  //       }}
                  //     >
                  //       Work Request #{i?.attributes?.workRequest?.data?.attributes?.countId}.
                  //     </span> */}
                  //   </span>
                  // );
                  //   msg = `Purchase Order ${
                  //     i?.attributes?.purchaseOrder?.data?.attributes?.details?.title
                  //   } has been ${
                  //     t.po.po_status[
                  //       i?.attributes?.purchaseOrder?.data?.attributes?.status
                  //     ]
                  //   }. View Purchase Order.`;
                  // }
                } else {
                  if (i?.attributes?.notType == "work order status update") {
                    msg =
                      `أمر العمل` +
                      ` ${i?.attributes?.workOrder?.data?.attributes.title} ` +
                      "في حالة" +
                      ` ${
                        t.workOrders.work_order_status[
                          i?.attributes?.workOrder?.data?.attributes?.status
                        ]
                      }` +
                      ". عرض أمر العمل";
                  } else if (i?.attributes?.notType == "work order assign") {
                    msg =
                      `لقد تلقيت أمر عمل` +
                      ` ${i?.attributes?.workOrder?.data?.attributes?.title} ` +
                      "المستحق" +
                      ` ${moment(
                        new Date(
                          i?.attributes?.workOrder?.data?.attributes?.dueDate +
                            "T" +
                            i?.attributes?.workOrder?.data?.attributes?.dueTime
                        ).getTime()
                      ).format("lll")}`;
                  } else if (
                    i?.attributes?.asset?.data &&
                    i?.attributes?.notType == "asset status update"
                  ) {
                    msg =
                      `أمر أصل` +
                      ` ${i?.attributes?.asset?.data?.attributes?.name} ` +
                      "في حالة" +
                      ` ${
                        t.assets.work_order_status[
                          i?.attributes?.asset?.data?.attributes?.status
                        ]
                      }` +
                      "عرض الأصول.";
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "work request assign"
                  ) {
                    msg = (
                      <span>
                        {i?.attributes?.workRequest?.data?.attributes
                          ?.submittedBy?.data?.attributes?.firstName +
                          " " +
                          i?.attributes?.workRequest?.data?.attributes
                            ?.submittedBy?.data?.attributes?.lastName}{" "}
                        بإنشاء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          طلب العمل #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                        </span>
                      </span>
                    );
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "Work Request Submitted"
                  ) {
                    msg = (
                      <span>
                        تم تقديم طلب عمل جديد. رمز الطلب:{' '}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                        </span>
                        , الوصف:{" "}
                        <span
                          style={
                            i?.attributes?.workRequest?.data?.attributes
                              ?.description
                              ? {
                                  color: "#0C66E4",
                                  textDecoration: "underline",
                                }
                              : {}
                          }
                        >
                          {i?.attributes?.workRequest?.data?.attributes
                            ?.description
                            ? i?.attributes?.workRequest?.data?.attributes
                                ?.description
                            : t.common.na}
                          {/* Work Request #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          } */}
                        </span>
                      </span>
                    );
                  }  else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "work request status update" &&
                    i?.attributes?.workRequest?.data?.attributes?.status ==
                      "declined"
                  ) {
                    msg = (
                      <span>
                        {i?.attributes?.workRequest?.data?.attributes
                          ?.updatedByUser?.data?.attributes?.firstName +
                          " " +
                          i?.attributes?.workRequest?.data?.attributes
                            ?.updatedByUser?.data?.attributes?.lastName}{" "}
                        رفض ال{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          طلب العمل #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                          .
                        </span>
                        {
                          i?.attributes?.workRequest?.data?.attributes
                            ?.declinedReason
                        }
                      </span>
                    );
                    // msg = `Work Request ${
                    //   i?.attributes?.workRequest?.data?.attributes?.title
                    // } is ${
                    //   t.workRequests.status[
                    //     i?.attributes?.workRequest?.data?.attributes?.status
                    //   ]
                    // }. View Work Request.`;
                  } else if (
                    i?.attributes?.workRequest?.data &&
                    i?.attributes?.notType == "work request status update" &&
                    i?.attributes?.workRequest?.data?.attributes?.status ==
                      "approved"
                  ) {
                    msg = (
                      <span>
                        {i?.attributes?.workRequest?.data?.attributes
                          ?.updatedByUser?.data?.attributes?.firstName +
                          " " +
                          i?.attributes?.workRequest?.data?.attributes
                            ?.updatedByUser?.data?.attributes?.lastName}{" "}
                        وافق على ال{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          طلب العمل #
                          {
                            i?.attributes?.workRequest?.data?.attributes
                              ?.countId
                          }
                          .
                        </span>
                      </span>
                    );
                    // msg = `Work Request ${
                    //   i?.attributes?.workRequest?.data?.attributes?.title
                    // } is ${
                    //   t.workRequests.status[
                    //     i?.attributes?.workRequest?.data?.attributes?.status
                    //   ]
                    // }. View Work Request.`;
                  } else if (
                    i?.attributes?.notType == "A new purchase order is created"
                  ) {
                    // msg = `A new part has been added to the inventory. Part Name: ${i?.attributes?.part?.data?.attributes?.name}, Part Number:  ${i?.attributes?.part?.data?.attributes?.countId}`;

                    msg = (
                      <>
                        <span>تم إنشاء طلب شراء جديد </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>{" "}
                        <span>المورد: </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.vendor?.data?.attributes?.name
                          }
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "approved"
                  ) {
                    msg = (
                      <>
                        تمت الموافقة على طلب الشراء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>{" "}
                        <span>من قبل</span>{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.purchaseOrder?.data?.attributes
                            ?.approvedByUser?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.approvedByUser?.data?.attributes?.lastName}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "declined"
                  ) {
                    msg = (
                      <>
                        تم رفض طلب الشراء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> من قبل </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.user?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.user?.data?.attributes?.lastName}
                          .
                        </span>
                        <span> السبب: </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.declinedReason
                          }
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "cancelled"
                  ) {
                    msg = (
                      <>
                        <span> تم إلغاء طلب الشراء </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> من قبل </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.user?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.user?.data?.attributes?.lastName}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "partiallyfulfilled"
                  ) {
                    msg = (
                      <>
                        الاستلام الجزئي للعناصر الخاصة بطلب الشراء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.en?.endsWith("close.")
                  ) {
                    msg = (
                      <>
                        تم إغلاق طلب الشراء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>{" "}
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "purchase order status updated" &&
                    i?.attributes?.purchaseOrder?.data?.attributes?.status ==
                      "fulfilled"
                  ) {
                    msg = (
                      <>
                        تم استلام العناصر الخاصة بطلب الشراء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>{" "}
                        تم الاستلام من قبل:{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.user?.data?.attributes?.firstName +
                            " " +
                            i?.attributes?.user?.data?.attributes?.lastName}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.purchaseOrder?.data &&
                    i?.attributes?.notType == "updated purchase order"
                  ) {
                    msg = (
                      <>
                        تم تعديل طلب الشراء{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {
                            i?.attributes?.purchaseOrder?.data?.attributes
                              ?.details?.poNumber
                          }
                        </span>
                        <span> يرجى مراجعة التفاصيل المحدثة.</span>
                      </>
                    );
                  } else if (i?.attributes?.notType == "new part created") {
                    // msg = `A new part has been added to the inventory. Part Name: ${i?.attributes?.part?.data?.attributes?.name}, Part Number:  ${i?.attributes?.part?.data?.attributes?.countId}`;

                    msg = (
                      <>
                        <span>
                          تمت إضافة قطعة جديدة إلى المخزون. اسم القطعة:{" "}
                        </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>,رقم القطعة: </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {/* Part {' '} */}
                          {i?.attributes?.part?.data?.attributes?.countId}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "part updated"
                  ) {
                    msg = (
                      <>
                        {" "}
                        تم تحديث تفاصيل القطعة{" "}
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span> يرجى مراجعة المعلومات المحدثة."</span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "low part quantity"
                  ) {
                    msg = (
                      <>
                        <span>تنبيه: مستوى المخزون ل </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {" "}
                          لقد انخفض إلى ما دون الحد الأدنى. المخزون الحالي:{" "}
                          {i?.attributes?.part?.data?.attributes?.qtyInStock}
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "out of stock"
                  ) {
                    msg = (
                      <>
                        <span>تنبيه: القطعة </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span> غير متوفرة في المخزون. مطلوب تجديد فوري.</span>
                      </>
                    );
                  } else if (
                    i?.attributes?.part?.data &&
                    i?.attributes?.notType == "critical"
                  ) {
                    msg = (
                      <>
                        <span>تنبيه: القطعة </span>
                        <span
                          style={{
                            color: "#0C66E4",
                            textDecoration: "underline",
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {" "}
                          مهمة جدًا للعمليات الجارية. تأكد من الحفاظ على مستويات
                          المخزون الكافية.
                        </span>
                      </>
                    );
                  } else if (
                    i?.attributes?.user?.data &&
                    (i?.attributes?.notType ==
                      "User Role Changed in the System" ||
                      i?.attributes?.notType ==
                        "User Role Changed in the System One")
                  ) {
                    msg = (
                      <>
                        <span>{i?.attributes?.ar}</span>
                        {/* <span
                          style={{
                            color: '#0C66E4',
                            textDecoration: 'underline',
                          }}
                        >
                          {i?.attributes?.part?.data?.attributes?.name}
                        </span>
                        <span>
                          {' '}
                          is critical for ongoing operations. Ensure adequate
                          stock levels are maintained.
                        </span> */}
                      </>
                    );
                  }

                  // else if (i?.attributes?.notType == 'purchase order status updated') {
                  //   msg = `طلب الشراء ${
                  //     i?.attributes?.purchaseOrder?.data?.attributes?.details?.title
                  //   }  في حالة ${
                  //     t.po.po_status[
                  //       i?.attributes?.purchaseOrder?.data?.attributes?.status
                  //     ]
                  //   }. View Purchase Order.`;
                  // }
                }

                return i?.attributes?.workOrder?.data ||
                  i?.attributes?.asset?.data ||
                  i?.attributes?.workRequest?.data ||
                  i?.attributes?.purchaseOrder?.data ||
                  i?.attributes?.sendByUser?.data ||
                  i?.attributes?.part?.data ? (
                  <a
                    href="#"
                    className="navi-item"
                    key={`${index}`}
                    style={{
                      backgroundColor: i?.attributes?.isRead ? "" : "#9e9e9e",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      markAsReadAndNavigate(i);
                    }}
                  >
                    <div className="navi-link">
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-line-chart text-success"></i>
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold">{msg}</div>
                        <div className="text-muted">
                          {moment(
                            new Date(i.attributes.createdAt).getTime()
                          ).format("lll")}
                        </div>
                      </div>
                    </div>
                  </a>
                ) : null;
              })}
              {!notifications.length ? (
                <p className="ml-2 mr-2 text-center">
                  {" "}
                  {t.common.no_result_found}
                </p>
              ) : null}
              {/* </InfiniteScroll> */}
            </PerfectScrollbar>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
