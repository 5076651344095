import React, { useEffect, useState, forwardRef, useRef } from 'react';
import _ from 'lodash';
import { DropdownMenu, Image, Label } from 'semantic-ui-react';
import { DownChevron } from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { humanize } from '../../config/functions';

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onChange={props.onChange}
        title={props.title}
        icon={props.icon}
        image={props.image}
        {...props}
      />
    );
  };

  return Component;
}

const StatusDropdown = forwardRef(
  (
    {
      open,
      setOpen,
      options = [],
      selectedValues = [],
      onChange = () => {},
      title = '',
      icon = true,
      image = false,
    },
    ref
  ) => {
    return (
      <>
        <div ref={ref} className="Category-dropdown">
          <UncontrolledDropdown
            className={`${open && 'active'}`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)} color="primary">
              {title} <DownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu>
              {options.map((one) => {
                if (one.value) {
                  return (
                    <label
                      tag="label"
                      className={`custom-checkbox single-select item ${
                        selectedValues.length
                          ? selectedValues.includes(one.value)
                            ? 'selected'
                            : ''
                          : one.value === ''
                          ? 'selected'
                          : ''
                      }`}
                      key={one.value}
                      onClick={(e) => {
                        onChange({
                          ...e,
                          target: {
                            ...e.target,
                            checked: !selectedValues.includes(one.value),
                            value: one.value,
                          },
                        });
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <Label
                          circular
                          empty
                          color={one.color || '#788ca5'}
                          className=""
                        />
                        <div>
                          <span className="ml-4 mr-4">{one.text}</span>
                        </div>
                      </div>
                    </label>
                  );
                } else {
                  return (
                    <label
                      tag="label"
                      className={`custom-checkbox single-select item ${
                        selectedValues.length
                          ? selectedValues.includes(one.value)
                            ? 'selected'
                            : ''
                          : one.value === ''
                          ? 'selected'
                          : ''
                      }`}
                      key={one.value}
                      onClick={(e) => {
                        onChange({
                          ...e,
                          target: {
                            ...e.target,
                            checked: !selectedValues.includes(one.value),
                            value: one.value,
                          },
                        });
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {/* <Label
                          circular
                          empty
                          color={one.color || '#788ca5'}
                          className=""
                        /> */}
                        <div>
                          <span className="ml-4 mr-4 all_option_dropdown_text">
                            {one.text}
                          </span>
                        </div>
                      </div>
                    </label>
                  );
                }
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(StatusDropdown);
