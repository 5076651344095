import React from 'react';
import { ArrowLeft, ArrowRight } from '../../../Assets/Icons/svg';

function Pagination({
  page = 1,
  limit = 25,
  total = 25,
  prevItem = null,
  nextItem = null,
  ofString = 'of',
  handleNext = () => {
    alert('next');
  },
  handlePrevious = () => {
    alert('prev');
  },
}) {
  const currentPageStart = (page - 1) * limit;
  const currentPageEnd = page * limit;
  return (
    <div className="custom-pagination">
      <div
        className={`navigation-arrow ${
          currentPageStart < limit ? 'disabled' : ''
        }`}
        onClick={() => {
          if (currentPageStart >= limit) handlePrevious();
        }}
      >
        {prevItem || <ArrowLeft />}
      </div>
      <div className="current-page-detail">
        {` ${currentPageStart} `}-
        {` ${currentPageEnd >= total ? total : currentPageEnd} `}
      </div>
      <div className="total-page-detail">
        {ofString} {total}
      </div>
      <div
        className={`navigation-arrow ${
          currentPageEnd >= total ? 'disabled' : ''
        }`}
        onClick={() => {
          if (currentPageEnd < total) handleNext();
        }}
      >
        {nextItem || <ArrowRight />}
      </div>
    </div>
  );
}

export default Pagination;
