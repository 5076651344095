import serviceTableMock from "./serviceTableMock";
import MockUtils from "./mock.utils";

export default function mockService(mock) {
  mock.onPost("api/services").reply(({ data }) => {
    const { service } = JSON.parse(data);
    const {
      titleEN = "",
      titleAR = "",
      descriptionEN = "",
      descriptionAR = "",
      cover = "",
      type = 1,
      status = 0,
    } = service;

    const id = generateServiceId();
    const newService = {
      id,
      titleEN,
      titleAR,
      descriptionEN,
      descriptionAR,
      cover,
      type,
      status,
    };
    serviceTableMock.push(newService);
    return [200, { service: newService }];
  });

  mock.onPost("api/services/find").reply((config) => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdServices = mockUtils.baseFilter(serviceTableMock, queryParams);
    return [200, filterdServices];
  });

  mock.onPost("api/services/deleteServices").reply((config) => {
    const { ids } = JSON.parse(config.data);
    ids.forEach((id) => {
      const index = serviceTableMock.findIndex((el) => el.id === id);
      if (index > -1) {
        serviceTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/services/updateStatusForServices").reply((config) => {
    const { ids, status } = JSON.parse(config.data);
    serviceTableMock.forEach((el) => {
      if (ids.findIndex((id) => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/services\/\d+/).reply((config) => {
    const id = config.url.match(/api\/services\/(\d+)/)[1];
    const service = serviceTableMock.find((el) => el.id === +id);
    if (!service) {
      return [400];
    }

    return [200, service];
  });

  mock.onPut(/api\/services\/\d+/).reply((config) => {
    const id = config.url.match(/api\/services\/(\d+)/)[1];
    const { service } = JSON.parse(config.data);
    const index = serviceTableMock.findIndex((el) => el.id === +id);
    if (!index) {
      return [400];
    }

    serviceTableMock[index] = { ...service };
    return [200];
  });

  mock.onDelete(/api\/services\/\d+/).reply((config) => {
    const id = config.url.match(/api\/services\/(\d+)/)[1];
    const index = serviceTableMock.findIndex((el) => el.id === +id);
    serviceTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateServiceId() {
  const ids = serviceTableMock.map((el) => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
