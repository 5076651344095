import React from 'react'
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { selectTranslations } from '../../config/i18n/slice';

const EmptyFilter = () => {
    const t = useSelector(selectTranslations);
    const userRole = useSelector((state) => {
      if (state?.auth?.user?.role?.id) {
        return state?.auth?.user?.role?.type;
      } else {
        return null;
      }
    });
  return (
    <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between align-items-center views">
          <div className="list-view-option">
            <p className={`single-view ${userRole == "requester" ? "d-none" : ""}`}>
              <NavLink to="/people">{t.teams.filter.people}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/teams">{t.people.filter.teams}</NavLink>
            </p>
          </div>
        </div>
      </div>
  )
}

export default EmptyFilter