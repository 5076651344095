import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { CloseIcon, Fail, Success } from '../../Assets/Icons/svg';
import { selectTranslations } from '../../app/config/i18n/slice';

const DeleteResultModal = ({
  isOpen = false,
  setIsOpen = () => {},
  closeCallback = () => {},
  yesCallback = () => {},
  children,
  btnName = '',
  deleteErrorMessage,
  
  
}) => {
  const t = useSelector(selectTranslations);

  return (
    <Modal
      size="tiny"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        closeCallback();
      }}
      style={{ minHeight: !btnName ? '195px' : '239px' }}
      className={`delete-result-modal ${deleteErrorMessage ? "fail" : "success"}`}
    >
   
      <div className="text-right mt-5 mr-5 ml-5">
        <span
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
          className="c-pointer"
        >
          <CloseIcon />
        </span>
      </div>
      <Modal.Content>
        <div style={{ textAlign: 'center',marginTop:"-9px" }}>{children}</div>
      </Modal.Content>
      {btnName ? (
        <Modal.Actions>
          <Button
            color="black"
            className="button-transparent mt-2"
            onClick={() => {
              setIsOpen(false);
              yesCallback();
            }}
          >
            {btnName}
          </Button>
        </Modal.Actions>
      ) : null}
    </Modal>
  );
};

export function useDeleteResultModal() {
  const [isOpen, setIsOpen] = useState(false);

  const DeleteResultModalCallback = useCallback(
    ({ children, closeCallback, btnName,yesCallback,deleteErrorMessage}) => {
      return (
        <DeleteResultModal
          isOpen={isOpen}
         
          setIsOpen={setIsOpen}
          closeCallback={closeCallback}
          btnName={btnName}
          yesCallback={yesCallback}
          deleteErrorMessage={deleteErrorMessage}
        >
          {children}
        </DeleteResultModal>
      );
    }
  );
  return useMemo(
    () => ({
      setDeleteResultModal: setIsOpen,
      DeleteResultModal: DeleteResultModalCallback,
    }),
    [setIsOpen, DeleteResultModalCallback]
  );
}

export default useDeleteResultModal;
