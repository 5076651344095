import firebase from 'firebase/app';
import 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDeZt034nkV3HlkQfcJNAXakQ2USdxnARY',
  authDomain: 'quiqflow.firebaseapp.com',
  projectId: 'quiqflow',
  storageBucket: 'quiqflow.appspot.com',
  messagingSenderId: '868050201806',
  appId: '1:868050201806:web:282876d1e485db0ce4b49f',
  measurementId: 'G-5F937RNQR3',
};

const publicKey =
  'BCJ9UHzOJ3qgFtd69UULn8SLeRIZL6_wDxE1jC9JxaoT-Gt0SyonWWid7bUlkfOjC5f8nMnZ317owWYVzCcqvhY';

export const getToken = async () => {
  let currentToken = '';
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
    if (firebase.messaging.isSupported()) {
      if ('serviceWorker' in navigator) {
        const messaging = firebase.messaging.isSupported()
          ? firebase.messaging()
          : null;
        const swPath = `../../firebase-messaging-sw.js`;
        try {
          if (messaging) {
            let registration = await navigator.serviceWorker.register(swPath);
            let token = await messaging
              .getToken({
                vapidKey: publicKey,
                serviceWorkerRegistration: registration,
              })
              .catch((err) => {
                // console.log(err);
                return '';
              });
            // console.log('token', token);
            return token;
          }
        } catch (error) {
          // console.log('An error occurred while retrieving token. ', error);
        }
      }
    }

    // return currentToken;
  } else {
    if (firebase.messaging.isSupported()) {
      if ('serviceWorker' in navigator) {
        const messaging = firebase.messaging.isSupported()
          ? firebase.messaging()
          : null;
        const swPath = `../../firebase-messaging-sw.js`;
        try {
          if (messaging) {
            let registration = await navigator.serviceWorker.register(swPath);
            let token = await messaging
              .getToken({
                vapidKey: publicKey,
                serviceWorkerRegistration: registration,
              })
              .catch((err) => {
                // console.log(err);
                return '';
              });
            // console.log('token', token);
            return token;
          }
        } catch (error) {
          // console.log('An error occurred while retrieving token. ', error);
        }
      }
    }
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
      try {
        const messaging = firebase.messaging.isSupported()
          ? firebase.messaging()
          : null;
        if (messaging) {
          messaging.onMessage((payload) => {
            let notificationData = payload.notification;
            // console.log('foreground123', notificationData);
            const notificationTitle = notificationData.title;
            const notificationOptions = {
              body: notificationData.body,
              icon: notificationData.icon,
            };
            var notification = new Notification(
              notificationTitle,
              notificationOptions
            );
          });
        }
        // return currentToken;
        // return currentToken;
      } catch (error) {
        // console.log('An error occurred while retrieving token. ', error);
        // return '';
      }
    } else {
      const messaging = firebase.messaging.isSupported()
        ? firebase.messaging()
        : null;
      if (messaging) {
        messaging.onMessage((payload) => {
          let notificationData = payload.notification;
          // console.log('foreground123', payload);
          const notificationTitle = notificationData.title;
          const notificationOptions = {
            body: notificationData.body,
            icon: notificationData.icon,
          };
          var notification = new Notification(
            notificationTitle,
            notificationOptions
          );
        });
      }
    }
  });
