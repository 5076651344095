import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { selectTranslations } from '../../app/config/i18n/slice';
import { CloseIcon, Fail } from '../../Assets/Icons/svg';

const CancelModal = ({
  isOpen = false,
  setIsOpen = () => {},
  closeCallback = () => {},
  yesCallback = () => {},
  children,
  deleteErrorMessage,
}) => {
  const t = useSelector(selectTranslations);

  return (
    <Modal
      size="tiny"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        closeCallback();
      }}
      style={{ minHeight: '239px' }}
      className={`assets-modal delete-result-modal ${
        deleteErrorMessage ? 'fail' : 'success'
      }`}
    >
      <div className="text-right mt-5 mr-5 ml-5">
        <span
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
          className="c-pointer"
        >
          <CloseIcon />
        </span>
      </div>
      <Modal.Content>
        <div style={{ textAlign: 'center', marginTop: '-9px' }}>
          <div className="mb-3">
            <Fail />
          </div>

          <p className="status-modal-popup-message mb-2">{children}</p>
        </div>
      </Modal.Content>
      <div className="modal-button-container justify-content-center mt-3">
        <button
          onClick={() => {
            setIsOpen(false);
            yesCallback();
          }}
          style={{ color: '#FFF', fontWeight: '500' }}
          className="modal-form-action-button  light-blue"
        >
          {t.common.cancel}
        </button>

        <button
          onClick={() => {
            setIsOpen(false);
            closeCallback(false);
          }}
          style={{ fontWeight: '500' }}
          className="modal-form-action-button black"
        >
          {t.common.keep}
        </button>
      </div>
      {/* <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            setIsOpen(false);
            yesCallback();
          }}
        >
          {t.common.delete}
        </Button>
        <Button
          color="black"
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
        >
          {t.common.keep}
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
};

export function useCancelModal() {
  const [isOpen, setIsOpen] = useState(false);

  const CancelModalCallback = useCallback(
    ({ children, closeCallback, yesCallback, deleteErrorMessage }) => {
      return (
        <CancelModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          closeCallback={closeCallback}
          yesCallback={yesCallback}
          deleteErrorMessage={deleteErrorMessage}
        >
          {children}
        </CancelModal>
      );
    }
  );
  return useMemo(
    () => ({
      setCancelModal: setIsOpen,
      CancelModal: CancelModalCallback,
    }),
    [setIsOpen, CancelModalCallback]
  );
}

export default useCancelModal;
