export default {
  title: 'People',
  add: 'Person',
  add_person: 'Add People',
  next_btn: 'Next',
  filter: {
    search: 'Search',
    role: 'Role',
    select_role: 'Select Role',
    supervisors: 'Supervisors',
    select_supervisors: 'Select Supervisors',
    teams: 'Teams',
    team: 'Team',
    select_teams: 'Select Teams',
    rate: 'Rate',
    from: 'From',
    to: 'To',
    reset: 'Reset',
    contract_expiry_date: 'Contract Expiration Date',
    status: 'Status',
    select_status: 'Select Status',
  },
  table: {
    name: 'Name',
    ID: 'ID',
    mobile: 'Mobile',
    email: 'Email',
    role: 'Role',
    teams: 'Teams',
    supervisor: 'Supervisor',
    hourly_rate: 'Hr rate',
    last_login: 'Last Login',
    status: 'Status',
    createdBy: 'Created By',
    contract_expiry_date: 'Contract Expiration Date',
  },
  roles:{
    technician:"Technician",
    supervisor:"Supervisor",
    "limited admin":"Limited Admin",
    admin:"Admin",
    requester:"Requester",
    Viewer:"Viewer",
    "organization admin":"Organization Admin",

  },

  

  role_options: {
    role_user: 'User',
    role_admin: 'Admin',
    role_limited_Admin: 'Limited Admin',
  },

  status_options: {
    status_pending: 'Pending',
    status_active: 'Active',
    inactive: 'In-Active',
    status_active_account: 'Active account',
    inactive_account: 'Inactive account',
    account_activity: 'Account Activity',
  },

  add_modal: {
    super_admin:"Organization Admin",
    requester: 'Requester',
    requester_desc:'Can only view work requests and view their status',
    technician: 'Technician',
    technician_description:
      'Views, creates, works on, and closes work orders; partial editing and deletion rights.',
    viewer: 'Viewer',
    viewer_description:
      "Views admin con  tent, can't make changes but can submit and track own requests, and add comments.",
    admin: 'Administrator',
    admin_description:
      'Has full access to the system including adding, modifying, or removing system elements',
    limited_admin: 'Limited Administrator',
    limited_admin_description:
      'Enjoys full accessibility excluding deletion, and restricted from customization, settings modifications, or accessing subscription plans for security.',
    user: 'User',
    user_description:
      'Access to view, create, work-on and close work orders. Partial access to edit and delete only what they are assigned to or have worked on.',
  },
  personal_info_form: {
    assign:"Assign Work order",
    add_person: 'Add People',
    personal_info: 'Personal Info',
    work_info: 'Work Info',
    attachments: 'Attachments',
    phone: 'Phone',
    firstname: 'First Name',
    lastname: 'Last Name',
    add_firstname: 'Add first name',
    add_lastname: 'Add last name',
    add_phone: 'Add number',
    add_email: 'Add email',
    add_jobtitle: 'Add job title',
    add_location: 'Add location',
    add_teams: 'Add teams',
    add_hr_rate: 'Add hour rate',
    add_department: 'Add department',
    email: 'Email',
    address: 'Address',
    add_address: 'Add address',
    gender: 'Gender',
    gender_options: {
      gender_male: 'Male',
      gender_female: 'Female',
    },
    martial_status: 'Martial Status',
    martial_option: {
      single: 'Single',
      married: 'Married',
    },
    number_of_dependents: 'Number of Dependents',
    add_number_of_dependents: 'Add number of dependents',
    education_level: 'Education Level',
    education_options: {
      high_school: 'High School Degree',
      diploma: 'Diploma Degree',
      bachelors: 'Bachelors Degree',
      masters: 'Masters Degree',
      doctorate: 'Doctorate Degree',
      other: 'Other',
    },
    nationality: 'Nationality',
    add_nationality: 'Add nationality',
    add_country_of_birth: 'Add country of birth',
    add_date_of_birth: 'Add date of birth',
    add_passport_no: 'Add passport No',
    add_identification_no: 'Add identification No',
    add_visa_no: 'Add visa No',
    add_visa_expire_date: 'Add visa expire date',
    add_emergency_contact: 'Add emergency contact',
    add_filed_of_study: 'Add field of study',
    country_of_birth: 'Country of Birth',
    date_of_birth: 'Date of Birth',
    passport_no: 'Passport No',
    identification_no: 'Identification No',
    visa_no: 'Visa No',
    children_no: 'Children No',
    visa_expire_date: 'Visa Expire Date',
    emergency_contact: 'Emergency Contact',
    emergency_phone: 'Emergency Phone',
    filed_of_study: 'Field of Study',
  },

  work_info_form: {
    work_info: 'Work Information',
    job_title: 'Job Title',
    supervisor: 'Supervisor',
    add_supervisor: 'Add supervisor',
    teams: 'Teams',
    locations: 'Locations',
    department: 'Department',
    hour_rate: 'Hour Rate',
    working_hours_day: 'Working hours per day',
    add_working_hours_day: 'Add working hours per day',
    working_hours_week: 'Working hours per week',
    add_working_hours_week: 'Add working hours per week',
    contract_start_date: 'Contract Start Date',
    contract_expiry_date: 'Contract Expiration Date',
    add_contract_start_date: 'Add contract start date',
    add_contract_expiry_date: 'Add contract expiration date',
    registration_no: 'Registration No',
    add_registration_no: 'Add registration No',
    badge_ID: 'Badge ID',
    add_badge_ID: 'Add badge ID',
    shifts: 'Shifts',
    shift_A: 'Shift A',
    shift_B: 'Shift B',
    shift_C: 'Shift C',
    work_permit_no: 'Work Permit No',
    add_work_permit_no: 'Add work permit No',
    working_days: 'Working Days',
    working_day_options: {
      sat: 'Sat',
      sun: 'Sun',
      mon: 'Mon',
      tue: 'Tue',
      wed: 'Wed',
      thu: 'Thu',
      fri: 'Fri',
    },
  },

  attachments_form: {
    attachment_title: 'Attachments',
    contract_copy: 'Contract Copy',
    ID_copy_front: 'ID Copy',
    personal_picture: 'Personal Picture',
    passport_copy: 'Passport Copy',
    visa_copy: 'Visa Copy',
    certificates: 'Certificates',
    submit_btn: 'Submit',
    allowed_types: 'Allowed types: jpg, jpeg, png, pdf',
  },

  studyField: {
    agriculture: 'Agriculture and Forestry',
    agricultureUrban: 'Agriculture and Forestry',
    arts: 'Arts and Humanities',
    astronomy: 'Astronomy and Astrophysics',
    economics: 'Business and Economics',
    mediaStudy: 'Communication and Media Studies',
    cultural: 'Cultural Studies',
    education: 'Education',
    engineering: 'Engineering and Technology',
    environmental: 'Environmental Science',
    fine_arts: 'Fine Arts and Design',
    geology: 'Geology and Earth Sciences',
    health: 'Health and Medicine',
    hospitality: 'Hospitality and Tourism',
    law: 'Law and Legal Studies',
    library: 'Library and Information Science',
    linguistics: 'Linguistics',
    mathematics: 'Mathematics and Statistics',
    naturalSciences: 'Natural Sciences',
    performingArts: 'Performing Arts',
    philosophy: 'Philosophy',
    physicalEducation: 'Physical Education and Sports Science',
    politicalScience: 'Political Science',
    psychology: 'Psychology',
    publicAdministration: 'Public Administration',
    religiousStudies: 'Religious Studies',
    socialSciences: 'Social Sciences',
    sociology: 'Sociology',
    computerScience: 'Computer Science and Information Technology',
  },

  personal_info_form_edit: {
    approved: 'Approved',
    declined: 'Declined',
    approve_btn: 'Appeove',
    decline_btn: 'Decline',
    delete_person_statement: 'Are you sure you want to delete this person?',
    user_blocked: 'This user is blocked for security reasons for this',
    edit_person: 'Edit People',
  },

  security_form: {
    change_password: 'Change Password',
    select_one: 'Select one',
    new_password: 'New Password',
    confirm_password: 'Confirm Password',
    re_assign_to: 'Re-assign to',
    user_deactivated: 'User deactivated successfully.',
    user_activated: 'User activated successfully.',
    something_wrong: 'Something went wrong. Please try after some time',
    select_user: 'Please select a user to re-assign and deactivate',
    active_account: 'Active account?',
    deactive_account: 'Deactive account?',
    deactive: 'Deactivate account',
    activete: 'Activate account',
    unassign: 'Unassign and Deactivate',
    reassign: 'Reassign and Deactivate',
    yes_active: 'Yes, activate',
    make_active: 'Are you sure you want to make this user active?',
    make_deactive: 'Are you sure you want to deactivate this user?',
    already_has_workorder:
      'already has following work orders that needs to be re-assigned to the other team members before you can deactivate.',
    decline_reason: 'Deline Reason',
    decline_reason_spacify:
      'Please spcifiy reason to decline this user and confirm',
    person_not_deleted: 'Unable to delete profile. Linked with other forms',
    preson_deleted: 'Person deleted successfully',
  },

  security: 'Security',
  invalid_email: 'Invalid email',
  invalid_phone: 'Invalid Phone',
  no_people_statement: "Looks like you don't have People yet.",
  press_to_add_people: 'Press the ' + ' button to add your first Person.',
  form_validation_errors: {
    'This email is already exists': 'This email is already exists',
    'This phone number and email already exist':
      'This phone number and email already exist',
    'This phone is already exists': 'This phone number is already exists',
  },
  add_people: 'Start adding people',
  add_team: 'Start adding teams',
  people_teams: 'people & teams',
  small_title: 'people',
  small_title_teams: 'teams',
  people_team: 'People/Teams',
  how_to_add_people: 'how to add people',
  how_to_add_teams: 'how to add teams',
  acc_deactivated: 'Account Deactivated',
  activate_deactivate_account: 'Activation deactivate account',
  deactivate_statement: 'Are you sure you want to deactivate',
  activate_statement: 'Are you sure you want to activate',
  activate_success_statement: 'Person activated successfully',
  deactivate_success_statement: 'Person deactivated successfully',
  deactivate_related_statement: 'This person cannot be deactivated',
  activate_related_statement: 'This person cannot be activated',
  activate_account: 'Activate account',
};
