export default {
  dashboard: 'لوحة التحكم',
  workOrders: 'أوامر العمل',
  workRequests: 'طلبات العمل',
  procedureLibrary: 'الإجراءات',
  assets: 'الممتلكات',
  partsInventory: 'القطع والمخزون',
  timeTracking: 'تتبع الوقت',
  payroll: 'كشف رواتب',
  locations: 'المواقع',
  peopleTeams: 'الأفراد والفرق',
  customersVendors: 'العملاء والموردين',
  settings: 'الإعدادات',
  help: 'المساعدة',
  categories: 'الفئات',
  language: 'لغة',
  company_profile: 'ملف الشركة',
  configurations: 'تهيئة',
  today: 'اليوم',
  peopleTeam: 'الأفراد والفرق',
  customersVendor: 'العملاء والموردين',
};
