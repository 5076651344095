import moment from 'moment';
import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Header,
  Modal,
  Dropdown,
  TextArea,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Grid,
} from 'semantic-ui-react';

import {
  createNewAttendanceRecord,
  getAllParentLocations,
  getUsersByRole,
} from '../../config/functions';
function Add({ toggled, untoggle, refresh, setRefresh }) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [dropdownOptions, setDropdownOptions] = useState({
    locations: [],
    users: [],
  });
  const [data, setData] = useState({
    punchInDate: moment().format('YYYY-MM-DD'),
    punchInTime: '08:00',
    punchOutDate: moment().format('YYYY-MM-DD'),
    punchOutTime: '17:00',
    location: '',
    user: '',
    IsAddedManually: true,
    reasonToAddManually: '',
  });

  const [validation, setValidation] = useState({
    punchInDate: {
      error: false,
      pattern: (date) =>
        moment(date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
      skipped: false,
      message: 'Invalid date',
    },
    punchInTime: {
      error: false,
      pattern: (time) =>
        moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
        time != '',
      skipped: false,
      message: 'Invalid time',
    },
    punchOutDate: {
      error: false,
      pattern: (date) =>
        moment(date).format('YYYY-MM-DD') === moment(date).format('YYYY-MM-DD'),
      skipped: false,
      message: 'Invalid date',
    },
    punchOutTime: {
      error: false,
      pattern: (time) =>
        moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
        time != '',
      skipped: false,
      message: 'Invalid time',
    },
    location: {
      error: false,
      pattern: (location) => location != '',
      skipped: false,
      message: 'Field is required',
    },
    user: {
      error: false,
      pattern: (user) => user != '',
      skipped: false,
      message: 'Field is required',
    },
    reason: {
      error: false,
      pattern: (reason) => reason != '',
      skipped: false,
      message: 'Field is required',
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      punchInDate: moment().format('YYYY-MM-DD'),
      punchInTime: '08:00',
      punchOutDate: moment().format('YYYY-MM-DD'),
      punchOutTime: '17:00',
      location: '',
      user: '',
      IsAddedManually: true,
      reasonToAddManually: '',
    });

    setValidation({
      punchInDate: {
        error: false,
        pattern: (date) =>
          moment(date).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD') || date != '',
        skipped: false,
        message: 'Invalid date',
      },
      punchInTime: {
        error: false,
        pattern: (time) =>
          moment(time).format('HH:mm') === moment(time).format('HH:mm') ||
          time != '',
        skipped: false,
        message: 'Invalid time',
      },
      punchOutDate: {
        error: false,
        pattern: (date) =>
          moment(date).format('YYYY-MM-DD') ===
            moment(date).format('YYYY-MM-DD') || date != '',
        skipped: false,
        message: 'Invalid date',
      },
      punchOutTime: {
        error: false,
        pattern: (time) =>
          moment(time).format('HH:mm') === moment(time).format('HH:mm') ||
          time != '',
        skipped: false,
        message: 'Invalid time',
      },
      location: {
        error: false,
        pattern: (location) => location != '',
        skipped: false,
        message: 'Field is required',
      },
      user: {
        error: false,
        pattern: (user) => user != '',
        skipped: false,
        message: 'Field is required',
      },
      reason: {
        error: false,
        pattern: (reason) => reason != '',
        skipped: false,
        message: 'Field is required',
      },
    });
    setInitChange(false);
    setValidForm(false);
  };

  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(organisationId,"",1,99999)
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        locations: the_options,
      });
    }
  };

  const fetchUsersData = async () => {
    const fetchData = await getUsersByRole(
      ['user', 'supervisor', 'view_only', 'admin', 'requester'],
      organisationId
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName ? el.firstName : el.username,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        users: the_options,
      });
    }
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = ['location', 'user'];
    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }
    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }
  };

  const validationHandler = () => {
    setValidation({
      ...validation,
      punchInDate: {
        ...validation.punchInDate,
        error: validation.punchInDate.pattern(data.punchInDate) ? false : true,
        skipped: true,
      },
      punchInTime: {
        ...validation.punchInTime,
        error: validation.punchInTime.pattern(data.punchInTime) ? false : true,
        skipped: true,
      },
      punchOutDate: {
        ...validation.punchOutDate,
        error: validation.punchOutDate.pattern(data.punchOutDate)
          ? false
          : true,
        skipped: true,
      },
      punchOutTime: {
        ...validation.punchOutTime,
        error: validation.punchOutTime.pattern(data.punchOutTime)
          ? false
          : true,
        skipped: true,
      },
      location: {
        ...validation.location,
        error: validation.location.pattern(data.location) ? false : true,
        skipped: true,
      },
      user: {
        ...validation.user,
        error: validation.user.pattern(data.user) ? false : true,
        skipped: true,
      },
      reason: {
        ...validation.reason,
        error: validation.reason.pattern(data.reasonToAddManually)
          ? false
          : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log(cond);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const createHandler = async () => {
    validationHandler();
    if (validForm) {
      setButtonStatus(1);

      const response = await createNewAttendanceRecord(
        data.user,
        data.location,
        true,
        data.reasonToAddManually,
        moment(data.punchInDate + 'T' + data.punchInTime).format(
          'YYYY-MM-DDTHH:mm:ss.SSSZ'
        ),
        moment(data.punchOutDate + 'T' + data.punchOutTime).format(
          'YYYY-MM-DDTHH:mm:ss.SSSZ'
        )
      );

      if (response.status == 200) {
        // console.log(response.data);
        setResponseData({
          id: response.data.user.id,
          name: response.data.user.firstName,
        });
        setButtonStatus(0);
        untoggle();
        resetForm();
        toggleSuccessModal(true);
      }
    }
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div clearing className={toggled ? 'aside-modal active' : 'aside-modal'}>
        <div className="modal-header">
          <div className="flexbox justify-between">
            <div className="name">Add Attendence Record</div>
            <div>
              <Icon
                name="close"
                size="large"
                title="Close"
                onClick={() =>
                  initChange ? toggleCancelModal(true) : cancelHandler()
                }
              />
            </div>
          </div>
        </div>

        <div className="body">
          <Grid columns="equal" style={{ marginBottom: '10px' }}>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">
                  Punch In Date
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Input
                  autoComplete="new-password"
                  type="date"
                  fluid
                  value={data.punchInDate}
                  onChange={onValuesChange('punchInDate')}
                  error={
                    validation.punchInDate.error &&
                    validation.punchInDate.skipped
                  }
                  onBlur={(e) => {
                    if (!data.punchInDate)
                      setData({
                        ...data,
                        punchInDate: moment().format('YYYY-MM-DD'),
                      });

                    if (validation.punchInDate.pattern(data.punchInDate)) {
                      setValidation({
                        ...validation,
                        punchInDate: {
                          ...validation.punchInDate,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        punchInDate: {
                          ...validation.punchInDate,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.punchInDate.error &&
                validation.punchInDate.skipped ? (
                  <div className="label-error">
                    {validation.punchInDate.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  Punch In Time
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Input
                  autoComplete="new-password"
                  type="time"
                  fluid
                  value={data.punchInTime}
                  onChange={onValuesChange('punchInTime')}
                  error={
                    validation.punchInTime.error &&
                    validation.punchInTime.skipped
                  }
                  onBlur={(e) => {
                    if (validation.punchInTime.pattern(data.punchInTime)) {
                      setValidation({
                        ...validation,
                        punchInTime: {
                          ...validation.punchInTime,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        punchInTime: {
                          ...validation.punchInTime,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.punchInTime.error &&
                validation.punchInTime.skipped ? (
                  <div className="label-error">
                    {validation.punchInTime.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  Location
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Location"
                  fluid
                  search
                  selection
                  options={dropdownOptions.locations}
                  value={data.location}
                  onChange={onValuesChange('location')}
                  onOpen={() => fetchLocations()}
                  error={
                    validation.location.error && validation.location.skipped
                  }
                  onBlur={(e) => {
                    if (validation.location.pattern(data.location)) {
                      setValidation({
                        ...validation,
                        location: {
                          ...validation.location,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        location: {
                          ...validation.location,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.location.error && validation.location.skipped ? (
                  <div className="label-error">
                    {validation.location.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">
                  Punch Out Date
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Input
                  autoComplete="new-password"
                  type="date"
                  fluid
                  value={data.punchOutDate}
                  onChange={onValuesChange('punchOutDate')}
                  error={
                    validation.punchOutDate.error &&
                    validation.punchOutDate.skipped
                  }
                  onBlur={(e) => {
                    if (!data.punchOutDate)
                      setData({
                        ...data,
                        punchOutDate: moment().format('YYYY-MM-DD'),
                      });

                    if (validation.punchOutDate.pattern(data.punchOutDate)) {
                      setValidation({
                        ...validation,
                        punchOutDate: {
                          ...validation.punchOutDate,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        punchOutDate: {
                          ...validation.punchOutDate,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.punchOutDate.error &&
                validation.punchOutDate.skipped ? (
                  <div className="label-error">
                    {validation.punchOutDate.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  Punch Out Time
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Input
                  autoComplete="new-password"
                  type="time"
                  fluid
                  value={data.punchOutTime}
                  onChange={onValuesChange('punchOutTime')}
                  error={
                    validation.punchOutTime.error &&
                    validation.punchOutTime.skipped
                  }
                  onBlur={(e) => {
                    if (validation.punchOutTime.pattern(data.punchOutTime)) {
                      setValidation({
                        ...validation,
                        punchOutTime: {
                          ...validation.punchOutTime,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        punchOutTime: {
                          ...validation.punchOutTime,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.punchOutTime.error &&
                validation.punchOutTime.skipped ? (
                  <div className="label-error">
                    {validation.punchOutTime.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  User
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="User"
                  fluid
                  search
                  selection
                  options={dropdownOptions.users}
                  value={data.user}
                  onChange={onValuesChange('user')}
                  onOpen={() => fetchUsersData()}
                  error={validation.user.error && validation.user.skipped}
                  onBlur={(e) => {
                    if (validation.user.pattern(data.user)) {
                      setValidation({
                        ...validation,
                        user: {
                          ...validation.user,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        user: {
                          ...validation.user,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.user.error && validation.user.skipped ? (
                  <div className="label-error">{validation.user.message}</div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Form>
            <Form.Field
              error={validation.reason.error && validation.reason.skipped}
            >
              <label className="label-item">
                Reason To Add Manually
                <Icon name="asterisk" color="red" size="small" />
              </label>
              <TextArea
                autoComplete="new-password"
                value={data.reasonToAddManually}
                onChange={onValuesChange('reasonToAddManually')}
                rows={2}
                fluid
                placeholder="Reason To Add Manually"
                onBlur={(e) => {
                  if (validation.reason.pattern(data.reasonToAddManually)) {
                    setValidation({
                      ...validation,
                      reason: {
                        ...validation.reason,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      reason: {
                        ...validation.reason,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.reason.error && validation.reason.skipped ? (
                <div className="label-error">{validation.reason.message}</div>
              ) : (
                ''
              )}
            </Form.Field>
          </Form>
          <div>
            <Divider />
            <Button
              content={'Submit'}
              primary
              onClick={createHandler}
              loading={buttonStatus == 1}
            />
            <Button
              content="Cancel"
              basic
              onClick={() =>
                initChange ? toggleCancelModal(true) : cancelHandler()
              }
            />
          </div>
        </div>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? toggleCancelModal(true) : cancelHandler())}
      ></div>

      <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
          setRefresh(!refresh);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <Header as="h2">
              Attendence record added to user: {responseData.name} successfully!
            </Header>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
              setRefresh(!refresh);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(true)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default Add;
