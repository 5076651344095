import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  Menu,
} from 'semantic-ui-react';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import { CategoryDamage, CategoryElectrical, CategoryInspection, CategoryMechanical, CategoryOperation, CategoryProject, CategoryRefrigeration, CategorySafety, CatgoryPreventive, CorrectiveIcon, OperationProcedure, PlannedIcon, Preventive, UnPlannedIcon } from '../../../Assets/Icons/svg';

const FilterCloneList = ({ data, setDataByFilter, hasStatus }) => {
  const t = useSelector(selectTranslations);

  const [searchTitle, setSearchTitle] = useState('');
  const [openCategory, setOpenCategory] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [filterCloneListTrigger, setFilterCloneListTrigger] = useState({
    category: {
      options: [
        {
          key: 0,
          value: 'damage',
          text: t.workOrders.form.category_options[0],
          icon:    <CategoryDamage />
        },
        {
          key: 2,
          value: 'corrective action Downtime',
          text: t.workOrders.form.category_options[1],
          icon:    <CorrectiveIcon/>
        },
        {
          key: 4,
          value: 'planned Downtime',
          text: t.workOrders.form.category_options[2],
          icon:    <PlannedIcon/>
        },
        {
          key: 10,
          value: 'unplanned Downtime',
          text: t.workOrders.form.category_options[3],
          icon:    <UnPlannedIcon/>
        },
        {
          key: 11,
          value: 'inspection',
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: 'electrical',
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: 'mechanical',
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: 'HVAC',
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: 'preventive maintenance',
          text: t.workOrders.form.category_options[8],
          icon:  <CatgoryPreventive />,
        },
        {
          key: 17,
          value: 'project',
          text: t.workOrders.form.category_options[9],
          icon:    <CategoryProject />,
        },
        {
          key: 7,
          value: 'safety',
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: 'sop',
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
    },
  });

  const [filterOptions, setFilterOptions] = useState({
    category: ["all"],
  });

  const [newData, setNewData] = useState(data);


  const closeAllFilterCloneList = () => {
    setOpenCategory(false);
  };

  let _newOrders = newData;

  useEffect(() => {
    if (!filterOptions.category.includes("all")){
      _newOrders = _newOrders.filter((el) => {
        return filterOptions.category.includes(el.attributes.category);
      });
    }

    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
      const isMatch = (result) => re.test(result.attributes.title);

      _newOrders =
        searchTitle.length > 0 ? _.filter(_newOrders, isMatch) : _newOrders;
    }

    if (_newOrders.length > 0) {
      setDataByFilter(_newOrders);
    } else {
      setDataByFilter([]);
    }

    document.body.addEventListener('click', closeAllFilterCloneList);
  }, [searchTitle, filterOptions]);

  return (
    <div className="filters-wrapper">
      <div className="flexbox justify-between" style={{ flex: 1 }}>
        <Input
          icon={{ name: 'search' }}
          placeholder={t.procedures.filter.search}
          value={searchTitle}
          onChange={(e) => setSearchTitle(e.target.value)}
        />


<CategoryDropdown
            title={t.procedures.details_view.category}
            procedure ={true}
            options={filterCloneListTrigger.category.options}
            selectedValues={filterOptions.category}
            onChange={(e) => {
              if (e.target.checked) {

                setFilterOptions((prevState) =>  {
                  return {
                    ...prevState,
                    category: [e.target.value],
                  };
                });
                // setFilterOptions({
                //   ...filterOptions,
                //   category: [...filterOptions.category, e.target.value],
                // });
              } else {
                let filteredCategory = filterOptions.category.filter(
                 
                  (el) => el !== e.target.value
                );

                setFilterOptions({
                  ...filterOptions,
                  category:["all"],
                });
              }
            }}
          />
 
     
        {/* <Dropdown
          style={{ marginRight: 0 }}
          icon="grid layout"
          floating
          button
          labeled
          basic
          direction={selectedLanguage == "ar" ? "right" : "left"}
          options={filterCloneListTrigger.category.options}
          className="icon"
          text={
            filterOptions.category.value
              ? filterOptions.category.text
              : t.workOrders.filter.category
          }
          open={openCategory}
          onOpen={() => {
            closeAllFilterCloneList();

            setOpenCategory(true);
          }}
          onChange={(e, { value, text }) => {
            setFilterOptions({
              ...filterOptions,
              category: {
                ...filterOptions.category,
                value,
                text: value.text,
              },
            });
          }}
        /> */}
      </div>
    </div>
  );
};

export default FilterCloneList;
