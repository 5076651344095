import React from 'react';
import QRCode from 'react-qr-code';

const truncateText = (text, maxLength) => {
  if (text?.length <= maxLength) {
    return text;
  }
  return text?.substring(0, maxLength) + '...';
};

class QRComponentToPrint extends React.Component {
  downloadThisQR = () => {
    const { data } = this.props;
    const svg = document.getElementById('QRCodeDownloadableAsset' + data.id);
    const svgData = new XMLSerializer().serializeToString(svg);

    // Create a div element to hold the SVG and the name paragraph
    const container = document.createElement('div');

    // Append the SVG
    container.appendChild(svg.cloneNode(true));

    // Create a paragraph element for the name
    const nameParagraph = document.createElement('p');
    nameParagraph.innerText = data.attributes.name;

    // Append the name paragraph
    container.appendChild(nameParagraph);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    const padding = 100; // Set the desired padding

    img.onload = () => {
      canvas.width = 595; // Increase canvas width to accommodate the padding
      canvas.height = 700; /// Increase canvas height to accommodate the name paragraph and space

      // Set background color to white
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white background

      // Create a new SVG with a white background and padding
      const whiteBackgroundSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="${img.width +
        3 * padding}" height="${img.height +
        3 *
          padding}" style="background-color: white;"><rect width="100%" height="100%" fill="white" /><g transform="translate(${padding},${padding}) scale(2)">${svgData}</g></svg>`;

      const whiteBackgroundImg = new Image();
      whiteBackgroundImg.src = `data:image/svg+xml;base64,${btoa(
        whiteBackgroundSvg
      )}`;

      whiteBackgroundImg.onload = () => {
        const textWidth = ctx.measureText(data.attributes.name).width;
        const textWidthSerial = ctx.measureText(data.attributes.serialNumber)
          .width;
        let xCoordinate;
        let xyCoordinate;

        console.log('textWidth: ', textWidth);
        if (textWidth > 75) {
          xCoordinate = (canvas.width - textWidth) / 2.7;
        } else {
          xCoordinate = (canvas.width - textWidth) / 2.4;
        }

        xyCoordinate = (canvas.width - textWidthSerial) / 2.3;
        let finalcord;
        let textCord = this.props.titleData == 'Locations-ID' ? 180 : 180;

        // Draw the SVG (QR code with white background and padding) onto the canvas
        ctx.drawImage(whiteBackgroundImg, 0, 0);
        ctx.font = '500 16px Roboto'; // Set font size and style for the name paragraph
        ctx.fillStyle = '#000';
        const marginRight = -50; // Set font color to black // Set font color to black
        const namePosY = img.height * 2 + padding + 50;
        const maxWidth =  530;
        const drawText = (text, x, y, maxWidth, maxLines) => {
          const words = text.split(' ');
          let line = '';
          let testLine = '';
          let lineArray = [];
          let testWidth = 0;
          ctx.font = '500 28px Roboto';

          for (let n = 0; n < words.length; n++) {
            testLine += words[n] + ' ';
            testWidth = ctx.measureText(testLine).width;

            if (testWidth > maxWidth && n > 0) {
              lineArray.push(line);
              testLine = words[n] + ' ';
              line = testLine;

              if (lineArray.length === maxLines - 1) {
                // Add remaining words with "..." if text exceeds maxLines
                let remainingWords = words.slice(n).join(' ');
                while (
                  ctx.measureText(remainingWords + '...').width > maxWidth
                ) {
                  remainingWords = remainingWords
                    .split(' ')
                    .slice(0, -1)
                    .join(' ');
                }
                lineArray.push(remainingWords + '...');
                break;
              }
            } else {
              line = testLine;
            }
          }

          if (lineArray.length < maxLines) {
            lineArray.push(line);
          }

          if(textWidth > 650){
            x=x+80 
          }else if(textWidth > 400){
            x=x 
          }else if(textWidth > 200){
            x=x - 20
          }
          else if (textWidth > 75) {
            x=x - 80 
          } else if (textWidth > 60) {
            x = x - 50
          } else {
            
              x = x - 30  
          }

          for (let k = 0; k < lineArray.length; k++) {
         
            ctx.fillText(
              lineArray[k],
              x,
              y + k * 30
            );

           
              finalcord = y + k * 30;
            
          }
        };
        // ctx.font = '400 16px Roboto'; // Set font size and style for the name paragraph
        // ctx.fillStyle = '#000'; // Set font color to black
        // const namePosY = img.height + padding + 30;
        if (this.props.titleName == 'Name') {
          drawText(
            `${this.props.titleName} : ${data.attributes.name}`,
             xCoordinate,
            namePosY,
            maxWidth,
             3 
            // lineHeight
          );
        } else {
          drawText(
            `${data.attributes.name} : ${this.props.titleName}`,
            xCoordinate ,
            namePosY,
            maxWidth,
             3 
            // lineHeight
          );
        }
        // if (this.props.titleName == 'Name') {
        //   ctx.fillText(
        //     `${this.props.titleName} : ${data.attributes.name}`,
        //     xCoordinate,
        //     namePosY
        //   );
        // } else {
        //   ctx.fillText(
        //     `${data.attributes.name} : ${this.props.titleName}`,
        //     xCoordinate,
        //     namePosY
        //   );
        // }

        // Adjust the vertical position for the name paragraph
        // Position and draw the name paragraph
        ctx.fillText(
          `${this.props.titleData} : ${data.attributes.serialNumber}`,
          xyCoordinate - 60,
          finalcord + 60
        ); // Position and draw the name paragraph

        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = `${data.attributes.name}-${data.id}`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
    };

    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  exposeDownloadFunction = () => {
    // Expose the download function externally
    return this.downloadThisQR();
  };
  render() {
    const { data, titleString, titleData, titleName,fromWO=false } = this.props;

    const truncatedText = truncateText(data?.attributes?.name, 115);
    return (
      <div className="qr-code-container">
        <div>
          <QRCode
            id={'QRCodeDownloadableAsset' + data.id}
            title={data.attributes.name}
            value={titleString + String(data.id)}
            size={192}
            onClick={this.downloadThisQR}
            style={{ cursor: 'pointer' }}
          />
          <p
            style={{
              display: 'none',
              fontSize: '28px',
              marginTop: '100px',
              maxWidth: '480px',
            }}
          >
            {titleName == 'Name'
              ? `${titleName} : ${truncatedText}`
              : `${truncatedText} : ${titleName}`}
          </p>
          <p style={{ display: 'none', fontSize: '24px' }}>
          {titleName == 'Name'
              ? `${titleData} : ${data?.attributes?.count}`
              : `${data?.attributes?.count} : ${titleData}`}
            {/* {titleData} : {fromWO ? `${data?.attributes?.count}` : `${data?.attributes?.count}`} */}
          </p>
        </div>
      </div>
    );
  }
}
QRComponentToPrint.defaultProps = {
  data: {
    attributes: {},
  },
  titleData: 'Id',
  titleName: 'Name',
};

export default QRComponentToPrint;
