import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Form } from 'semantic-ui-react';
import { selectTranslations } from '../../config/i18n/slice';

const ContactUs = () => {
  const t = useSelector(selectTranslations);

  return (
    <div>
      <fieldset className="mt-5">
        <legend>{` ${t.common.contact_information} `}</legend>
        <Grid columns="equal">
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.phone_number}</label>
              <p dir="ltr">{'+9626799660030'}</p>
            </Form.Field>
          </Grid.Column>
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.sms}</label>
              <p dir="ltr">{'+9626799660030'}</p>
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Grid columns="equal">
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.reach_quiqflow}</label>
              <p>{'Admin@quiqflow.com'}</p>
            </Form.Field>
          </Grid.Column>
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.address}</label>
              <p>{'Al-Hijaz St, Fuhais, Jordan'}</p>
            </Form.Field>
          </Grid.Column>
        </Grid>
      </fieldset>
    </div>
  );
};

export default ContactUs;
