import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { requestPassword } from '../../../config/functions';
import { toast } from 'react-toastify';
import { selectTranslations } from '../../../config/i18n/slice';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ErrorIcon } from '../../../../Assets/Icons/svg';

const initialValues = {
  email: '',
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const lang = useSelector((state) => state.i18n.lang);
  const t = useSelector(selectTranslations);
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t.auth.forget_password_form.invalid_email)
      .min(6, t.common.min_6_symbol)
      .max(50, t.common.max_50_symbol)
      .required(
        intl.formatMessage({
          id: t.common.required_field,
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return `${lang == 'ar' ? 'is-invalid is-invalid-rtl' : 'is-invalid'}`;
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return `${lang == 'ar' ? 'is-valid is-valid-rtl' : 'is-valid'}`;
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      requestPassword(values.email)
        .then(() => {
          setLoading(false);
          setIsRequested(true);
          toast.success(t.common.forgot_password_success);
        })
        .catch((error) => {
          setIsRequested(false);
          setLoading(false);
          setSubmitting(false);

          if(lang == "en"){
            setStatus(
              intl.formatMessage(
                { id: 'AUTH.VALIDATION.NOT_FOUND' },
                { name: values.email }
              )
            );
          }else{
            setStatus("لم يتم العثور على معلومات")
          }
        });
    },
  });

  useEffect(() => {
    if (formik && formik.touched) {
      formik.setFieldTouched('email', false);
    }
  }, [lang]);

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div
          className={` login-form d-flex flex-column login-forgot `}
          style={{ display: 'block', gap: '103px' }}
        >
          <div className="text-center mt-8">
            <img
              style={{ marginLeft: '-52px' }}
              alt="Logo"
              src={toAbsoluteUrl('/media/bg/logo.svg')}
            />
          </div>
          {/* <h3 className="font-size-h1">
              {t.auth.forget_password_form.title}
            </h3>
            <div className="text-muted font-weight-bold">
              {t.auth.forget_password_form.description}
            </div> */}

          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp d-block"
          >
            <div>
              <h3 className="font-size-h1">
                {t.auth.forget_password_form.title}
              </h3>
            </div>

            {formik.status && (
              <div className="mt-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div
              className={`form-group fv-plugins-icon-container ${
                formik.status ? 'mt-8' : 'mt-17'
              }`}
            >
              <label className={`form-label ${lang == "ar"?"direction-rtl":""}`} style={{ width: '370px' }}>
                {t.auth.forget_password_form.description}
              </label>
              <input
                style={
                  formik.touched.email && formik.errors.email
                    ? { borderColor: 'red' }
                    : {}
                }
                type="email"
                className={`form-control form-control-solid h-auto ${getInputClasses(
                  'email'
                )} email-password-ltr`}
                name="email"
                placeholder={t.auth.login_form.email}
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block d-flex mt-2">
                    <ErrorIcon />

                    <p className="ml-1">{formik.errors.email}</p>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="">
              <button
                style={{
                  color: 'white',
                  fontWeight: '500',
                  borderRadius: '3px',
                  backgroundColor: 'rgba(12, 102, 228, 1)',
                }}
                id="kt_login_forgot_submit"
                type="submit"
                className="common-text justify-content-center mt-10 w-100 d-flex btn"
                disabled={formik.isSubmitting}
              >
                {t.auth.forget_password_form.send_button}

                {loading && (
              <span
                className={`mt-4 spinner spinner-white  ${
                  lang == 'ar' ? 'mr-8' : 'ml-3'
                }`}
              ></span>
            )}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
