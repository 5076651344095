import moment from 'moment';
import React, { useMemo, useLayoutEffect, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useGetCurrentTimeAccZone() {
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });
  const [currentDate, setCurrentDate] = useState(
    new Date(moment().startOf('day')).getTime()
  );
  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentDate(new Date(moment().startOf('day')).getTime());
    }, 60000);

    return () => {
      clearInterval(intervalID); // Clear the interval when the component unmounts.
    };
  }, [organisationTimezone]);
  return { currentDate, organisationTimezone };
}

export default useGetCurrentTimeAccZone;
