/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect, useDispatch, useSelector } from 'react-redux';
import objectPath from 'object-path';
import SVG from 'react-inlinesvg';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import { useHistory } from 'react-router-dom';
import {
  IMAGES_URL,
  updateUserNotificationToken,
} from '../../../../../app/config/functions';
import { selectTranslations } from '../../../../../app/config/i18n/slice';
import { Image } from 'semantic-ui-react';
import UserProfilePlaceholder from '../../../../../app/components/common/userProfilePlaceholder';
function UserProfileDropdown(props) {
  const t = useSelector(selectTranslations);

  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const dispatch = useDispatch();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, 'extras.user.dropdown.style') ===
        'light',
    };
  }, [uiService]);

  const changeRole = (role) => {
    dispatch({ type: '[Change Role] Action', payload: { role } });
  };

  const handleLogout = async () => {
    if (user?.id) {
      await updateUserNotificationToken(user.id, '','',true);
    }
    props.logout();
  };
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            'btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2'
          }
        >
          {/* <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            {t.common.hi},
          </span>{' '}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3 ml-3">
            {user.firstName} {user.lastName}
          </span> */}
          {user?.profileImage?.url ? (
            <span>
              <Image
                avatar
                title={user.firstName + ' ' + user.lastName}
                src={`${IMAGES_URL}${user?.profileImage?.url}`}
                style={{ width: '34px', height: '34px', right: '6px' }}
                // style={
                //   index != 0
                //     ? {
                //         marginLeft: '-6px',
                //         width: '34px',
                //         height: '34px',
                //       }
                //     : { width: '34px', height: '34px' }
                // }
              />
              {/* <Image
                src={toAbsoluteUrl(
                  '/media/svg/icons/General/profile.svg'
                )}
                alt="profile"
                style={{width:"35px",height:"35px"}}
                avatar
              /> */}
            </span>
          ) : (
            // <span className="symbol symbol-35 symbol-light-success">
            //   <span className="symbol-label font-size-h5 font-weight-bold">
            //     {user.firstName
            //       ? `${user.firstName[0]}${
            //           user.lastName[0] ? user.lastName[2].toUpperCase() : ''
            //         }`
            //       : ''}
            //   </span>
            // </span>
            <UserProfilePlaceholder
                    name={user.firstName + ' ' + user.lastName}
                    width={'32px'}
                    height={'32px'}
                    right="6px"
                  />
            // <span className="svg-icon svg-icon-xl ">
            //   <img
            //     src={toAbsoluteUrl(
            //       '/media/svg/icons/General/header-profile.svg'
            //     )}
            //   />
            // </span>
          )}
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center p-8 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={toAbsoluteUrl('/media/users/300_21.jpg')} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.firstName} {user.lastName}
                </div>
                <span className="label label-light-success label-lg font-weight-bold label-inline">
                  3 messages
                </span>
              </div>
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundColor: '#1b5a83' }}
            >
              <div>
                {user?.profileImage?.url ? (
                  <Image
                    avatar
                    title={user.firstName + ' ' + user.lastName}
                    src={`${IMAGES_URL}${user?.profileImage?.url}`}
                    style={{ width: '40px', height: '40px', right: '6px' }}
                    // style={
                    //   index != 0
                    //     ? {
                    //         marginLeft: '-6px',
                    //         width: '34px',
                    //         height: '34px',
                    //       }
                    //     : { width: '34px', height: '34px' }
                    // }
                  />
                ) : (
                  // <span>
                  //   <img
                  //     src={toAbsoluteUrl(
                  //       '/media/svg/icons/General/profile.svg'
                  //     )}
                  //     width="50px"
                  //     height="50px"
                  //     alt="profile"
                  //   />

                  // </span>
                  <UserProfilePlaceholder
                    name={user.firstName + ' ' + user.lastName}
                    width={'40px'}
                    height={'40px'}
                    right="6px"
                  />
                  // <span className="symbol-label text-success font-weight-bold font-size-h4">
                  //   {user.firstName
                  //     ? `${user.firstName[0]}${
                  //         user.lastName[0] ? user.lastName[0].toUpperCase() : ''
                  //       }`
                  //     : ''}
                  // </span>
                )}
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.firstName} {user.lastName}
              </div>
              {/* <span className="label label-success label-lg font-weight-bold label-inline">
                3 messages
              </span> */}
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 text-success" />
              </div>
              <div
                className="navi-text"
                onClick={() => history.push('/profile')}
              >
                <div className="font-weight-bold cursor-pointer">
                  {t.common.my_profile}
                </div>
                {/* <div className="text-muted">
                  Account settings and more
                   <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span> 
                </div> */}
              </div>
            </div>
            {/* <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-settings text-success" />
              </div>
              <div
                className="navi-text"
                onClick={() => history.push('/settings')}
              >
                <div className="font-weight-bold cursor-pointer">
                  {t.common.settings}
                </div>
              </div>
            </div> */}
          </a>

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-mail text-warning"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Messages</div>
                <div className="text-muted">Inbox and tasks</div>
              </div>
            </div>
          </a> */}

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-rocket-1 text-danger"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Activities</div>
                <div className="text-muted">Logs and notifications</div>
              </div>
            </div>
          </a> */}

          {/* <a className="navi-item px-8">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-hourglass text-primary"></i>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Tasks</div>
                <div className="text-muted">latest tasks and projects</div>
              </div>
            </div>
          </a> */}
          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
              onClick={(e) => {
                e.preventDefault();
                handleLogout();
              }}
            >
              {t.common.sign_out}
            </Link>
            {user.roles?.findIndex((role) => role == 1) != -1 ? (
              <></>
            ) : (
              // <div
              //   onClick={() => changeRole(0)}
              //   className="btn btn-light-primary font-weight-bold"
              // >
              //   Vendor Login
              // </div>
              <div
                onClick={() => changeRole(1)}
                className="btn btn-light-primary font-weight-bold"
              >
                Admin Login
              </div>
            )}
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default connect(null, auth.actions)(UserProfileDropdown);
