import React, { useState, useEffect, createRef } from 'react';
import {
  List,
  Header,
  Modal,
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Popup,
  Icon,
  TextArea,
} from 'semantic-ui-react';

import MapMarker from './map-marker';

import GeofenceMarker from './geofence-marker';

import Autocomplete from 'react-google-autocomplete';

import {
  createNewInventory,
  getAllCustomers,
  getAllVendros,
  uploadFile,
} from '../../../config/functions';

import QRCode from 'react-qr-code';
import { v4 as uuidv4 } from 'uuid';

import { Country, State, City } from 'country-state-city';
import { useSelector } from 'react-redux';

function AddPartModal({ toggled, untoggle, returnAddPart, title }) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);

  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);

  const [image, setImage] = useState(null);
  const [files, setFiles] = useState([]);

  const [responseData, setResponseData] = useState({});

  const [dropdownOptions, setDropdownOptions] = useState({
    vendors: [],
    customers: [],
    costPer: [
      {
        key: 0,
        value: 'piece',
        text: 'Per Piece',
      },
      {
        key: 1,
        value: 'set',
        text: 'Per Set',
      },
      {
        key: 2,
        value: 'liter',
        text: 'Per Liter',
      },
    ],
  });

  const [customFields, setCustomFields] = useState([]);

  const [data, setData] = useState({
    name: title,
    description: '',
    category: '',
    cost: '',
    costPer: 'piece',
    quantity: '',
    minimumQuantity: '',
    isNonStock: false,
    barcode: '',
    qrcode: '',
    serialNumber: '',
    area: '',
    additionalInformation: '',
    vendor: '',
    customer: '',
  });

  useEffect(() => {
    setData({ ...data, name: title });
  }, [title]);

  const [validation, setValidation] = useState({
    name: {
      error: false,
      pattern: (name) => name != '',
      skipped: false,
      message: 'Field is required',
    },
    customer: {
      error: false,
      pattern: (customer) => customer != '',
      skipped: false,
      message: 'Field is required',
    },
    vendor: {
      error: false,
      pattern: (vendor) => vendor != '',
      skipped: false,
      message: 'Field is required',
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      name: '',
      description: '',
      category: '',
      cost: '',
      costPer: 'piece',
      quantity: '',
      minimumQuantity: '',
      isNonStock: false,
      barcode: '',
      qrcode: '',
      serialNumber: '',
      area: '',
      additionalInformation: '',
      vendor: '',
      customer: '',
    });

    setImage(null);
    setFiles([]);

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != '',
        skipped: false,
        message: 'Field is required',
      },
      customer: {
        error: false,
        pattern: (customer) => customer != '',
        skipped: false,
        message: 'Field is required',
      },
      vendor: {
        error: false,
        pattern: (vendor) => vendor != '',
        skipped: false,
        message: 'Field is required',
      },
    });

    setValidForm(false);
    setInitChange(false);
  };

  const inventoryImageRef = createRef();
  const inventoryFilesRef = createRef();

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = ['vendor', 'customer', 'costPer'];

    const boolean_fields = ['isNonStock'];

    if (!dropdown_fields.includes(name) && !boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }
  };

  const onImageChange = (e, name) => {
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setImage({
      file: e.target.files[0],
      id: randomnumber,
      name: e.target.files[0].name,
      type: e.target.files[0].type,
      cancelPopup: false,
      isChanged: true,
    });
  };

  const onFilesChange = (e) => {
    let newFiles = [];
    Array.from(e.target.files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    newFiles = files.concat(newFiles);

    setFiles(newFiles);
  };

  const fetchCustomers = async () => {
    const fetchData = await getAllCustomers(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        customers: the_options,
      });
    }
  };

  const fetchVendorsData = async () => {
    const fetchData = await getAllVendros(organisationId);
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        vendors: the_options,
      });
    }
  };

  const generatedCustomDataFields = () => {
    return customFields?.map((field, index) => {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <Form.Field>
              <label className="label-item">Label</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder="Field Label"
                value={field.label}
                onChange={handleCustomDataChange(index, 'label')}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label className="label-item">Value</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder="Field Label"
                value={field.value}
                onChange={handleCustomDataChange(index, 'value')}
              />
            </Form.Field>
          </Grid.Column>

          <div
            className="buttun-type-link remove"
            basic
            onClick={removeThisData(field.id)}
          >
            <Icon name="trash alternate outline" />
            Remove
          </div>
        </Grid>
      );
    });
  };

  const addCustomData = () => {
    // console.log("clicked");
    setCustomFields([
      ...customFields,
      {
        id: customFields.length,
        label: '',
        value: '',
      },
    ]);
  };

  const removeThisData = (id) => (e) => {
    e.stopPropagation();
    const index = id;
    let newfields = [...customFields];
    newfields.splice(index, 1);

    setCustomFields(newfields);
  };

  const handleCustomDataChange = (id, type) => (event) => {
    // 1. Make a shallow copy of the items
    let newfields = [...customFields];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...customFields[id] };
    // 3. Replace the property you're intested in

    if (type == 'label') item.label = event.target.value;
    if (type == 'value') item.value = event.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    newfields[id] = item;
    // 5. Set the state to our new copy
    setCustomFields(newfields);
  };

  const uploadFilessHandler = async () => {
    let theFiles = files.map((el) => el.file);
    theFiles = theFiles.filter((el) => el.isChanged);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };

  const downloadThisQR = () => {
    const svg = document.getElementById('QRCodeDownloadable');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const validationHandler = () => {
    setValidation({
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      customer: {
        ...validation.customer,
        error: validation.customer.pattern(data.customer) ? false : true,
        skipped: true,
      },
      vendor: {
        ...validation.vendor,
        error: validation.vendor.pattern(data.vendor) ? false : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const createNewInventoryHandler = async () => {
    validationHandler();
    if (validForm) {
      setButtonStatus(1);
      const inventoryImageReponse =
        image && image.isChanged ? await uploadFile([image.file]) : null;

      const inventoryFilesReponse = await uploadFilessHandler();
      let theAttachments =
        inventoryFilesReponse?.status == 200
          ? inventoryFilesReponse.data.map((el) => el.id)
          : [];
      let unchangedAttachments = files.filter((el) => !el.isChanged);
      // console.log(unchangedAttachments)
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedFiles = theAttachments.concat(unchangedAttachments);
      // console.log(tobeSendedAttachments)

      createNewInventory2Handler(
        inventoryImageReponse?.status == 200
          ? inventoryImageReponse.data[0].id
          : null,
        toBeSendedFiles?.length > 0 ? toBeSendedFiles : []
      );
    }
  };

  const createNewInventory2Handler = async (image, files) => {
    const response = await createNewInventory(
      String(data.name),
      String(data.description),
      String(data.category),
      Number(data.cost),
      String(data.costPer),
      Number(data.quantity),
      Number(data.minimumQuantity),
      Boolean(data.isNonStock),
      String(data.barcode),
      String(data.area),
      String(data.additionalInformation),
      customFields,
      Number(data.vendor),
      Number(data.customer),
      image,
      files,
      data.qrcode ? true : false,
      organisationId
    );
    // console.log(response);

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      returnAddPart(response.data.data.id);
    }
  };

  return (
    <>
      <Modal
        size="small"
        open={toggled}
        onClose={() => {
          untoggle();
        }}
      >
        <Modal.Header>Add Part</Modal.Header>
        <Modal.Content scrolling>
          <Form.Field>
            <label className="label-item">
              Name
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Enter Asset Name"
              value={data.name}
              onChange={onValuesChange('name')}
              error={validation.name.error && validation.name.skipped}
              onBlur={(e) => {
                if (validation.name.pattern(data.name)) {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.name.error && validation.name.skipped ? (
              <div className="label-error">{validation.name.message}</div>
            ) : (
              ''
            )}
          </Form.Field>

          <Form style={{ marginBottom: '20px' }}>
            <Form.Field>
              <label className="label-item">Description</label>
              <TextArea
                autoComplete="new-password"
                value={data.description}
                onChange={onValuesChange('description')}
                rows={2}
                fluid
                placeholder="Describe Part..."
              />
            </Form.Field>
          </Form>

          <Grid columns="equal">
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Category</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Part Category"
                  value={data.category}
                  onChange={onValuesChange('category')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Quantity</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Part Quantity"
                  value={data.quantity}
                  onChange={onValuesChange('quantity')}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Area</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Part Area"
                  value={data.area}
                  onChange={onValuesChange('area')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Minimum Quantity</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Part Minimum Quantity"
                  value={data.minimumQuantity}
                  onChange={onValuesChange('minimumQuantity')}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  value={data.isNonStock}
                  label=" Non-stock"
                  onChange={onValuesChange('isNonStock')}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">Barcode</label>

                <div className="flexbox">
                  <Input
                    style={{ flex: 1 }}
                    autoComplete="new-password"
                    fluid
                    placeholder="Enter Asset Barcode"
                    value={data.barcode}
                    onChange={onValuesChange('barcode')}
                  />
                  <Button.Group icon style={{ marginLeft: '5px' }}>
                    <Button
                      icon
                      primary
                      title="Auto-Gen"
                      onClick={() => {
                        setData({
                          ...data,
                          barcode:
                            Math.floor(Math.random() * 9000000000) + 1000000000,
                        });
                      }}
                    >
                      <Icon name="shuffle" />
                    </Button>

                    <Button
                      icon
                      secondary
                      title="QR-Gen"
                      onClick={() => {
                        setData({
                          ...data,
                          qrcode: data.qrcode ? null : uuidv4(),
                        });
                      }}
                    >
                      <Icon name="qrcode" />
                    </Button>
                  </Button.Group>
                </div>

                {data.qrcode ? (
                  <div style={{ marginTop: '5px' }}>
                    <QRCode
                      id="QRCode"
                      title="Asset QR Code"
                      value={data.qrcode}
                      size={100}
                      disabled
                    />
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">Serial Number</label>

                <div className="flexbox">
                  <Input
                    style={{ flex: 1 }}
                    autoComplete="new-password"
                    fluid
                    placeholder="Enter Serial Number"
                    value={data.serialNumber}
                    onChange={onValuesChange('serialNumber')}
                  />
                  <Button
                    icon
                    primary
                    title="Auto-Gen"
                    onClick={() => {
                      setData({
                        ...data,
                        serialNumber:
                          Math.floor(Math.random() * 9000000000) + 1000000000,
                      });
                    }}
                  >
                    <Icon name="shuffle" />
                  </Button>
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Cost</label>
                <Input
                  type="number"
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Part Cost"
                  value={data.cost}
                  onChange={onValuesChange('cost')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  Customer
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Select Customer"
                  fluid
                  search
                  selection
                  options={dropdownOptions.customers}
                  value={data.customer}
                  onChange={onValuesChange('customer')}
                  onOpen={() => fetchCustomers()}
                  error={
                    validation.customer.error && validation.customer.skipped
                  }
                  onBlur={(e) => {
                    if (validation.customer.pattern(data.customer)) {
                      setValidation({
                        ...validation,
                        customer: {
                          ...validation.customer,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        customer: {
                          ...validation.customer,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.customer.error && validation.customer.skipped ? (
                  <div className="label-error">
                    {validation.customer.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Cost Type</label>
                <Dropdown
                  placeholder="Select Cost Type"
                  fluid
                  search
                  selection
                  options={dropdownOptions.costPer}
                  value={data.costPer}
                  onChange={onValuesChange('costPer')}
                />
              </Form.Field>
              <Form.Field>
                <label className="label-item">
                  Vendor
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Select Vendor"
                  fluid
                  search
                  selection
                  options={dropdownOptions.vendors}
                  value={data.vendor}
                  onChange={onValuesChange('vendor')}
                  onOpen={() => fetchVendorsData()}
                  error={validation.vendor.error && validation.vendor.skipped}
                  onBlur={(e) => {
                    if (validation.vendor.pattern(data.vendor)) {
                      setValidation({
                        ...validation,
                        vendor: {
                          ...validation.vendor,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        vendor: {
                          ...validation.vendor,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Form.Field>
            <label className="label-item">Additional Part Details</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Additional Part Details"
              value={data.additionalInformation}
              onChange={onValuesChange('additionalInformation')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Image</label>
            {image ? (
              <List divided relaxed>
                <Popup
                  key={image?.id}
                  content="Remove file?"
                  on="click"
                  pinned
                  open={image?.cancelPopup}
                  trigger={
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        let currentFile = image;
                        currentFile.cancelPopup = !currentFile.cancelPopup;
                        setImage(currentFile);
                      }}
                    >
                      <List.Icon
                        name={
                          image?.type.match('image.*')
                            ? 'image'
                            : image?.type.match('pdf')
                            ? 'file pdf'
                            : 'file'
                        }
                        size="large"
                        color="black"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>{image?.name}</List.Header>
                      </List.Content>
                    </List.Item>
                  }
                >
                  <Header as="h4">Remove file?</Header>
                  <Button
                    color="black"
                    onClick={(e) => {
                      e.stopPropagation();
                      let currentFile = image;
                      currentFile.cancelPopup = false;
                      setImage(currentFile);
                    }}
                  >
                    Keep
                  </Button>
                  <Button
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      setImage(null);
                    }}
                  >
                    Yes, remove
                  </Button>
                </Popup>
              </List>
            ) : (
              <div>
                <input
                  ref={inventoryImageRef}
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => onImageChange(e, 'image')}
                />
                <div
                  className="buttun-type-link"
                  onClick={() => inventoryImageRef.current.click()}
                >
                  <Icon name="add" />
                  Add
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                    color: '#7e7e7e',
                  }}
                >
                  Allowed types: jpg, jpeg, png
                </p>
              </div>
            )}
          </Form.Field>

          <Form.Field>
            <label className="label-item">Files</label>
            {files.length > 0 ? (
              <List divided relaxed>
                {files.map((el, i) => (
                  <Popup
                    key={el.id}
                    content="Remove file?"
                    on="click"
                    pinned
                    open={el.cancelPopup}
                    trigger={
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let newFiles = [...files];
                          let currentFile = { ...files[i] };
                          currentFile.cancelPopup = !currentFile.cancelPopup;
                          newFiles[i] = currentFile;
                          setFiles(newFiles);
                        }}
                      >
                        <List.Icon
                          name={
                            el.type.match('image.*')
                              ? 'image'
                              : el.type.match('pdf')
                              ? 'file pdf'
                              : 'file'
                          }
                          size="large"
                          color="black"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header>{el.name}</List.Header>
                        </List.Content>
                      </List.Item>
                    }
                  >
                    <Header as="h4">Remove file?</Header>
                    <Button
                      color="black"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...files];
                        let currentFile = { ...files[i] };
                        currentFile.cancelPopup = false;
                        newFiles[i] = currentFile;
                        setFiles(newFiles);
                      }}
                    >
                      Keep
                    </Button>
                    <Button
                      color="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...files];
                        newFiles.splice(i, 1);
                        setFiles(newFiles);
                      }}
                    >
                      Yes, remove
                    </Button>
                  </Popup>
                ))}
              </List>
            ) : (
              ''
            )}
            <div>
              <input
                ref={inventoryFilesRef}
                type="file"
                accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                text/plain, application/pdf, image/*"
                hidden
                multiple
                onChange={(e) => onFilesChange(e)}
              />
              <div
                className="buttun-type-link"
                onClick={() => inventoryFilesRef.current.click()}
              >
                <Icon name="add" />
                Add files
              </div>
              <p
                style={{
                  fontSize: '12px',
                  marginTop: '5px',
                  color: '#7e7e7e',
                }}
              >
                Allowed types: jpg, jpeg, png, pdf, doc, docx, xlsx, csv
              </p>
            </div>
          </Form.Field>

          <Divider />
          {generatedCustomDataFields()}
          <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            Custom Data
          </div>
        </Modal.Content>

        <Modal.Actions>
          <Button
            content="Submit"
            primary
            onClick={createNewInventoryHandler}
            loading={buttonStatus == 1}
          />
          <Button
            content="Cancel"
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
      </Modal>

      {/* success modal */}

      <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="flexbox align-center">
              <div className="checkmark-wrapper">
                <Icon name="checkmark" color="white" size="huge" />
              </div>
              {responseData.qrcode ? (
                <QRCode
                  id="QRCodeDownloadable"
                  title={responseData.name}
                  value={String(responseData.id)}
                  size={200}
                  onClick={downloadThisQR}
                />
              ) : (
                ''
              )}
            </div>
            <p className="status-modal-popup-message">
              Part <b>{responseData.name}</b> added successfully!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      {/* cancel modal */}
      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddPartModal;
