export default {
  po_small: 'طلب الشراء',
  title: 'طلبات الشراء',
  title_single: 'طلب الشراء',
  add: 'إضافة أمر الشراء',
  edit: 'تعديل طلب الشراء',
  start_add: 'ابدء في إضافة طلبات الشراء',
  how_to_add: 'كيفية إضافة طلبات الشراء',

  table: {
    po_number: 'رقم طلب الشراء',
    vendor: 'المورد',
    no_items: 'عدد العناصر',
    total_qty: 'مجموع الكمية',
    total_cost: 'مجموع التكلفة',
    due_date: 'تاريخ الاستحقاق',
    approved_by: 'تمت الموفقة من قبل',
  },

  po_status: {
    requested: 'مطلوب',
    approved: 'موافق عليه',
    declined: 'مرفوض',
    cancelled: 'ملغي',
    fulfilled: 'منجز',
    partiallyfulfilled: 'منجز جزئياً',
    
  },

  status: {
    requested: 'مطلوب',
    approved: 'موافق عليه',
    declined: 'مرفوض',
    cancelled: 'ملغي',
    fulfilled: 'منجز',
    partially_fulfilled: 'منجز جزئياً',
  },
  form: {
    vendor_address:"موقع المورد",
    address_place:"أضف موقع المورد",
    add_po: 'إضافة رقم طلب الشراء',
    part_name: 'اسم القطعة',
    part_id: 'رمز القطعة',
    qty_to_order: 'الكمية المطلوبة',
    fill_max: 'ملىء الى الحد الأقصى',
    price: 'السعر',
    item: 'اسم العنصر',
    add_part: 'اختيار قطعة',
    ponumber: 'رقم طلب الشراء',
    add_vendor: 'اختر المورد',
    add_dueDate: 'اختر تاريخ الاستحقاق',
    additionalInformation: 'معلومات إضافية',
    add_additionalInformation: 'أضف المعلومات',
    details: 'التفاصيل',
    order_items: 'عناصر الطلب',
    search: 'بحث القطع',
    cancel_msg:
      'لديك تغييرات غير محفوظة في طلب الشراء هذا. هل أنت متأكد أنك تريد إلغاء؟',

    subTotal: 'المجموع الفرعي',
    taxes: 'الضرائب',
    shipping_cost: 'تكلفة الشحن',
    other_cost: 'تكاليف أخرى',
    shipping_address: 'عنوان الشحن',
    location_name: 'اسم/موقع الشركة',
    add_location_name: 'إضافة اسم/موقع الشركة',
    contact_number: 'رقم هاتف جهة الاتصال',
    state: 'الولاية/المقاطعة',
    add_state: 'أضف الولاية/المقاطعة',
    zip_code: 'الرمز البريدي',
    add_zip_code: 'أضف الرمز البريدي',
    billing_address: 'عنوان الفوترة',
    use_same: 'استخدم نفس عنوان الشحن',
    use_different: 'استخدم عنوان مختلف',
    company_name: 'اسم الشركة',
    add_company_name: 'إضافة اسم الشركة',
    attach_file: 'قم بإرفاق ملف لطلب الشراء هذا',
    add_valiadtion: 'يرجى تحديد عنصر طلب واحد على الأقل',
    add_success: 'تم إضافة طلب الشراء بنجاح',
    update_success: 'تم تحديث طلب الشراء بنجاح',
    item_name: 'اسم العنصر',
    part_no: 'رقم القطعة',
    order_qty: 'الكمية المطلوبة',
    receive_qty: 'سعر القطعة',
    total: 'المجموع',
    decline_po: 'رفض طلب الشراء',
    decline_po_statement: 'هل أنت متأكد أنك تريد رفض طلب الشراء هذا ؟',
    decline_success: 'تم رفض طلب الشراء بنجاح',
    approve_success: 'تم الموافقة على طلب الشراء بنجاح',
    approve_po: 'الموافقة على طلب الشراء',
    approve_po_statement: 'هل أنت متأكد أنك تريد الموافقة على طلب الشراء هذا ؟',
    send_to_vendor: 'الموافقة والإرسال إلى المورد',
    fulfill: 'إنجاز',
    fulfill_title: 'إنجاز طلب الشراء',
    qty_items: 'كمية العناصر',
    no: 'الرقم',
    fulfill_statement: 'تأكيد أو تعديل العناصر المستلمة والتكاليف أيضاً',
    recievedQty: 'الكمية المستلمة',
    update_status_error: 'ليس لديك الإذن لتحديث حالة طلب الشراء',
    close_po: 'إغلاق طلب الشراء',
    close_statement: 'هل أنت متأكد أنك تريد إغلاق طلب الشراء هذا؟',
    delete_statement: 'هل أنت متأكد أنك تريد حذف طلب الشراء هذا؟',
    close_success: 'تم إغلاق طلب الشراء بنجاح',
    cancelled_success: 'تم إلغاء طلب الشراء بنجاح',
    delete_success: 'تم حذف طلب الشراء بنجاح',
    fulFill_success: 'تم إنجاز طلب الشراء بنجاح',
    pfulFill_success: 'تم إنجاز طلب الشراء جزئيًا بنجاح',
    vendor_email: 'البريد الإلكتروني للمورد',
    vendor_email_not_found: 'هذا المورد ليس لديه عنوان بريد إلكتروني',
    subTotal_error: 'يجب أن يكون المجموع الفرعي أكثر من 0 لتنفيذ الطلب',
    decline_purchase_order: 'لقد تم رفض طلب الشراء هذا',
    approve_download_pdf: 'الموافقة والتنزيل PDF',
    people_tooltip:
      'Select the responsible for approving and fulfilling this purchase order',
  },
};
