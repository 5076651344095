import React, { useEffect, useState, forwardRef, useRef } from 'react';
import _ from 'lodash';
import { DropdownMenu, Image, Input } from 'semantic-ui-react';
import {
  ClockIcon,
  CloseIcon,
  DownChevron,
  SearchIcon,
} from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        disabled={props.disabled}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onChange={props.onChange}
        title={props.title}
        icon={props.icon}
        image={props.image}
        issearch={props.issearch}
        procedure={props.procedure}
        {...props}
        searchValue={props.searchValue}
        handleSearchValue={props.handleSearchValue}
        handleRemovePart={props.handleRemovePart}
      />
    );
  };

  return Component;
}

const CategoryDropdown = forwardRef(
  (
    {
      open,
      setOpen,
      options = [],
      selectedValues = [],
      onChange = () => {},
      heading = '',
      title = '',
      icon = true,
      image = false,
      searchValue = '',
      disabled = false,
      issearch = false,
      handleSearchValue = () => {},
      handleRemovePart = () => {},
      procedure = false,
    },
    ref
  ) => {
    const selectedLanguage = useSelector((state) => {
      if (state?.i18n?.lang) {
        return state.i18n.lang;
      } else {
        return 'en';
      }
    });
    const t = useSelector(selectTranslations);
    return (
      <>
        <div ref={ref} className={`Part-dropdown`}>
          {heading ? <div className="title-text">{heading}</div> : null}

          <UncontrolledDropdown
            className={`${open && !disabled ? 'active' : ''} ${
              (procedure &&
                selectedValues.length > 0 &&
                selectedValues[0] !== 'all') ||
              (!procedure &&
                selectedValues.length > 0 &&
                selectedValues[0] !== '')
                ? ''
                : ''
            }`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)}>
              {selectedValues == '' ? (
                <span className='ml-2'>{title}</span>
              ) : (
                <div>
                  {selectedValues.map((value, index) => {
                    const option = options.find(
                      (option) => option.value === value
                    );
                    return (
                      <React.Fragment key={value}>
                        {index > 0 && <span className="separator"></span>}
                        <span className='option-part-drop'>{option ? option.text : null}</span>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              <DownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu
              className={`${
                procedure && selectedLanguage == 'en'
                  ? 'right-dropdown'
                  : procedure && selectedLanguage == 'ar'
                  ? 'left-dropdown'
                  : ''
              }`}
            >
              {issearch ? (
                <div
                  className={`table-searchbar m-3 ${
                    selectedLanguage == 'en' ? '' : 'rtl-searchbar'
                  }`}
                >
                  <SearchIcon />
                  <Input
                    autoComplete="new-password"
                    iconPosition="left"
                    value={searchValue}
                    onChange={handleSearchValue}
                    className="dropdown-search-input"
                  />
                </div>
              ) : null}

              {options.length > 0 ? (
                options.map((one) => {
                  return (
                    <>
                    <label
                      tag="label"
                      className="custom-checkbox item"
                      key={one.value}
                    >
                      <div className="checkbox-item">
                        <input
                          type="checkbox"
                          value={one.value}
                          checked={selectedValues.includes(one.value)}
                          onChange={onChange}
                          disabled={one.disabled}
                        />
                        <span class="checkmark"></span>
                      </div>
                      {icon ? (
                        one.icon
                      ) : image ? (
                        <Image
                          avatar
                          // title={one?.image?.src}
                          src={one?.image?.src}
                          style={{ width: '20px', height: '20px' }}
                        />
                      ) : null}
                      <div className='d-flex align-items-center mt-1 justify-content-between widt-avai-part'>
                        <h3  style={{fontSize:"14px",color:"#172B4D"}} className="">{one.text}</h3>
                        <label style={{minWidth:"72px"}} className={one.disabled ? 'disable-txt-part' : ''}>{one.description}</label>
                      </div>
                     
                    </label>
                    </>
                  );
                })
              ) : (
                <p className="no-option-div">{t.common.no_option}</p>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(CategoryDropdown);
