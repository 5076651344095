export default {
  title: 'Job Titles',
  add: 'Job Title',
  title_small: 'job titles',
  add_job: 'Add Job Title',
  filter: {
    searchByName: 'Search by name',
    searchBySerialNumber: 'Search by serial number',
    area: 'Area',
    model: 'Model',
    wed: 'WED',
    barcode: 'Barcode',
    vendors: 'Vendors',
    createdByUser: 'Created By',
    createdAt: 'Created At',
    qrcode: 'QR Code',
  },
  table: {
    name: 'Title',
    ID: 'ID',
    createdByUser: 'Created By',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
  },
  cannot_delete_job_title_statement:
    'This job title cannot be deleted as it is already assigned to people.',
  edit_job_title: 'Edit Job Title',
  save: 'Save',
  jobTitle: 'Job Title',
  delete_job_title_statement: 'Are you sure you want to delete this job title?',
  unique: 'Job title must be unique name',
  no_job_title_available: "Looks like you don't have job titles yet.",
  job_title_updated_success: 'Job Title updated successfully',
  add_job_title_name: 'Please add job title name.',
  job_title_delete_success: 'Job Title deleted successfully',
  job_title_create_success: 'Job Title created successfully',
  job_title_already_exist: 'Job Title already exist with given name',
  job_title_not_created: 'Job Title cannot be created',
  import: 'Import',
  import_job_titles: 'Import Job Titles',
  uplaod_job_titles: 'Upload Job Titles',
  upload_excel_category_file:
    'Please upload correct excel file to add job titles',
  download_sample: 'Click to download sample file format',
  status: 'Status',
  error: 'Error',
  failed: 'Failed',
  success: 'Success',
  xls: 'JobTitle Template.xlsx',
  how_to_add_jobtitle: 'How to add a job titles',
  no_jobtitle_added: 'Start adding job titles',
  not_updated:"Job Title cannot be updated."
};
