export const extractAddress = (place = { address_components: [] }) => {
  let lat = place.geometry.location.lat();
  let lng = place.geometry.location.lng();
  const address = {
    address1: '',
    address2: '',
    address3: '',
    route: '',
    city: '',
    state: '',
    country: '',
    countryCode: '',
    postcode: '',
    lat,
    lng,
  };
  for (const component of place.address_components) {
    address.address1 = `${place.name}`;
    address.address3 = `${place.formatted_address}`;
    const componentType = component.types[0];
    switch (componentType) {
      case 'locality':
        address.city = `${component.long_name}`;
        break;
      // case "street_number": {
      //   address.address2 += ` ${component.long_name}`;
      //   break;
      // }
      case 'route': {
        address.route = ` ${component.long_name}`;
        break;
      }
      case 'sublocality_level_1': {
        address.address2 += `${component.long_name}`;
        break;
      }
      case 'postal_code': {
        address.postcode = `${component.long_name}${address.postcode}`;
        break;
      }
      case 'postal_code_suffix': {
        address.postcode = `${address.postcode}-${component.long_name}`;
        break;
      }
      case 'administrative_area_level_2': {
       
      }
      case 'administrative_area_level_1': {
        address.state = component.long_name;
        address.stateCode = component.short_name;
        break;
      }
      case 'country':
       
        address.country = component.long_name;
        address.countryCode = component.short_name;
        break;
    }
  }
  return address;
};
