import React, { useState, useEffect } from "react";
import { List, Loader, Dimmer, Grid } from "semantic-ui-react";

import { humanize, getUserProfileByID } from "../../../config/functions";

function HR({ targetedData }) {
  // console.log(targetedData);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [data, setData] = useState({
    profile: {},
    attendence: {},
  });
  useEffect(() => {
    setFetchLoading(true);
    if (targetedData.userProfile) {
      setData({
        ...data,
        profile: targetedData.userProfile,
      });
      setFetchLoading(false);
    } else {
      setFetchLoading(false);
    }
  }, [targetedData]);

  return (
    <div className="details-wrapper">
      {fetchLoading ? (
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      ) : (
        <Grid columns="equal">
          <Grid.Column>
            <ul>
              {targetedData?.supervisor ? (
                <li>
                  <div className="label">Related User</div>
                  <div className="value">
                    {targetedData?.supervisor.firstName}
                  </div>
                </li>
              ) : (
                ""
              )}

              <li>
                <div className="label">Registration No</div>
                <div className="value">{data.profile.registrationNo}</div>
              </li>

              <li>
                <div className="label">Pin Code</div>
                <div className="value">{data.profile.pinCode}</div>
              </li>

              {targetedData?.teams?.length > 0 ? (
                <li>
                  <div className="label">Teams related to</div>
                  <div className="value">
                    <List>
                      {targetedData?.teams?.map((el) => (
                        <List.Item>{el.name}</List.Item>
                      ))}
                    </List>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
          </Grid.Column>

          <Grid.Column>
            <ul>
              <li>
                <div className="label">Job Position</div>
                <div className="value">{targetedData.role.name}</div>
              </li>

              <li>
                <div className="label">Timesheet Cost</div>
                <div className="value">{data.profile.timesheetCost}</div>
              </li>

              <li>
                <div className="label">Badge ID</div>
                <div className="value">{data.profile.badgeId}</div>
              </li>
            </ul>
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
}

export default HR;
