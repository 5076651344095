import 'jspdf-autotable';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
const DownloadExcelFileWithData = (
  headers = [],
  data = [],
  columnWidths = [],
  fileName = ''
) => {
  const wsHeaders = headers.map((header) => header.label);
  const ws = XLSX.utils.aoa_to_sheet([wsHeaders, ...data]);
  ws['!cols'] = columnWidths;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${fileName}.xlsx`, { sheetRows: 1000 });
};

export default DownloadExcelFileWithData;
