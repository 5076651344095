import React, { useState, useEffect, createRef, useRef } from 'react';
import {
  Icon,
  Button,
  Label,
  Table,
  Loader,
  Dimmer,
  TextArea,
  Form,
  Header,
  Modal,
  Tab,
  Divider,
} from 'semantic-ui-react';
import Moment from 'react-moment';
import { useLocation, useHistory } from 'react-router';

import moment from 'moment';
import {
  humanize,
  bytesToSize,
  deleteWorkRequest,
  deleteSet,
  getAllWorkOrdersBySetId,
  currencyDecimalPlaces,
} from '../../../config/functions';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import ToolTip from 'react-power-tooltip';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import {
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  ShareIcon,
} from '../../../../Assets/Icons/svg';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import useDeletePermissionModal from '../../../../hooks/Messages/useDeletePermissionModal';
import { toast } from 'react-toastify';
import useDisplayFormattedText from '../../../../hooks/useDisplayFormattedText';
import Details from './details';
import Log from './log';
import { Resizable } from 're-resizable';
// import HandleUnitType from '../../../components/parts/handleUnitype';

function Preview({
  targetedData,
  toggled,
  approveOrder,
  declineOrder,
  approveLoading,
  declineLoading,
  untoggle,
  edit,
  setFilterOptions,
  setSearchTitle,
  setSelectedDates,
  setRefresh,
  refresh,
  setModalWidth,modalWidth
}) {
  const [fetching, setFetchLoading] = useState(true);
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });


  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  let allQtyOptions = [
    {
      key: 0,
      value: 'pieces',
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: 'items',
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: 'units',
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: 'liters (L)',
      text: selectedLanguage == 'en' ? 'l' : 'لتر',
    },
    {
      key: 4,
      value: 'gallons (gal)',
      text: selectedLanguage == 'en' ? 'gal' : 'جالون',
    },
    {
      key: 5,
      value: 'cubic meters (m³)',
      text: selectedLanguage == 'en' ? 'm³' : 'م³',
    },
    {
      key: 6,
      value: 'cubic feet (ft³)',
      text: selectedLanguage == 'en' ? 'ft³' : 'ق³',
    },
    {
      key: 7,
      value: 'barrels',
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: 'kilograms (kg)',
      text: selectedLanguage == 'en' ? 'kg' : 'كجم',
    },
    {
      key: 9,
      value: 'pounds (lb)',
      text: selectedLanguage == 'en' ? 'lb' : 'رطل',
    },

    {
      key: 10,
      value: 'tons',
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: 'metric tonnes',
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const [message, setMessage] = useState('');
  const [data, setData] = useState(null);
  const [WOdata, setWOData] = useState(null);

  const [toggleDeclineModal, setToggleDeclineModal] = useState(false);
  const [declinedReason, setDeclinedReason] = useState('');
  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [toggleWOModal, setToggleWOModal] = useState(false);

  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const [toggleAsideModal, setToggleAsideModal] = useState(false);

  const { userPermissions, checkForPermission } = usePermissionsSimplified();

  const [deleteModal, setDeleteModal] = useState(false);

  const history = useHistory();

  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const headers = [
    { label: t.workOrders.table.ID, key: 'id' },
    { label: t.workOrders.table.title, key: 'title' },
    { label: t.assets.form.description, key: 'description' },
    { label: t.workRequests.table.wr_status, key: 'status' },
    { label: t.workRequests.table.wo_status, key: 'wo_status' },
    { label: t.workOrders.table.priority, key: 'priority' },
    { label: t.workOrders.table.location, key: 'location' },
    { label: t.workRequests.table.asset, key: 'asset' },
    { label: t.workRequests.form.people, key: 'people' },
    { label: t.workRequests.form.teams, key: 'teams' },
    { label: t.workRequests.table.wo_id, key: 'wo_id' },
    { label: t.workRequests.table.sub_date, key: 'sub_date' },
    { label: t.workRequests.table.sub_by, key: 'sub_by' },
  ];

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  const generatePDF = () => {
    let csvData = [];

    let wr_data = [];

    wr_data.id = data.id;
    wr_data.title = data.attributes.title || t.common.na;

    wr_data.description = data?.attributes?.description
      ? data.attributes.description
      : t.common.na;

    // const workRequestStatus = WRstatusOptions.find(
    //   (status) => status?.value === data?.attributes?.status
    // );

    // wr_data.status = workRequestStatus?.label || t.common.na;

    // const PriorityStatus = priorityOptions.find(
    //   (status) => status.value === data.attributes.priority
    // );

    wr_data.location = data.attributes?.location?.data?.attributes?.name
      ? data.attributes.location.data.attributes.name
      : t.common.na;

    let assets = data.attributes?.assets?.data;
    let assetsString = '';

    if (assets?.length > 0) {
      assetsString = assets.map((asset) => asset?.attributes?.name).join(', ');
    } else {
      assetsString = t.common.na;
    }
    wr_data.asset = assetsString;

    let people = data?.attributes?.people?.data;
    let peopleString = '';

    if (people?.length > 0) {
      peopleString = people
        .map(
          (people) =>
            people?.attributes?.firstName + ' ' + people?.attributes?.lastName
        )
        .join(', ');
    } else {
      peopleString = t.common.na;
    }
    wr_data.people = peopleString;

    let teams = data?.attributes?.teams?.data;
    let teamsString = '';

    if (teams?.length > 0) {
      teamsString = teams.map((team) => team?.attributes?.name).join(', ');
    } else {
      teamsString = t.common.na;
    }
    wr_data.team = teamsString;

    wr_data.wo_id = data.attributes?.workOrder?.data?.id
      ? data.attributes?.workOrder?.data?.id
      : '';

    wr_data.sub_date = data?.attributes?.createdAt
      ? moment(data.attributes?.createdAt).format('YYYY-MM-DD')
      : t.common.na;

    wr_data.sub_by =
      data.attributes?.submittedBy?.data?.attributes?.firstName ||
      data?.attributes?.submittedBy?.data?.attributes?.lastName
        ? data.attributes?.submittedBy?.data?.attributes?.firstName +
          ' ' +
          data.attributes.submittedBy.data.attributes.lastName
        : t.common.na;

    csvData.push([
      wr_data.id,
      wr_data.title,
      wr_data.description,
      wr_data.status,
      wr_data.wo_status,
      wr_data.priority,
      wr_data.location,
      wr_data.asset,
      wr_data.people,
      wr_data.team,
      wr_data.wo_id,
      wr_data.sub_date,
      wr_data.sub_by,
    ]);

    const columnWidths = [10, 22, 22, 22, 23, 20, 23, 25, 25, 23, 16, 26, 25];
    DownloadPdfFileWithData(
      headers,
      csvData,
      data?.attributes?.title || t.workRequests.title,
      columnWidths,
      'A3'
    );
  };

  //     {
  //       menuItem: t.common.details,
  //       pane: {
  //         key: 'details',
  //         content: (
  //           <div className="pane-body">
  //             <Details
  //               untoggle={untoggle}
  //               setToggleAsideModal={setToggleAsideModal}

  //               toggleAsideModal={toggleAsideModal}
  //               data={data}
  //               setMessage={setMessage}
  //               message={message}
  //               setData={setData}
  //               setFilterOptions={setFilterOptions}
  //               setSearchTitle={setSearchTitle}
  //               setSelectedDates={setSelectedDates}
  //               targetedData={targetedData}
  //             />
  //           </div>
  //         ),
  //       },
  //     },
  //     {
  //       menuItem: t.workOrders.details_view.log,
  //       pane: {
  //         key: 'logs',
  //         content: (
  //           <div className="pane-body">
  //             <Logs targetedData={data} />
  //           </div>
  //         ),
  //       },
  //     },
  //   ];
  const handleWOFind = async () => {
    let response = await getAllWorkOrdersBySetId(
      organisationId,
      targetedData?.id
    );

    if (response && response.length > 0) {
      setWOData(response);
      setToggleWOModal(true);
    } else {
      edit();
      setPopupIsOpen(false);
    }
  };

  const panes = [
    {
      menuItem: t.common.details,
      pane: {
        key: 'details',
        content: (
          <div className="pane-body">
            <Details untoggle={untoggle} data={targetedData} />
          </div>
        ),
      },
    },
      // {
      //   menuItem: t.workOrders.details_view.log,
      //   pane: {
      //     key: 'log',
      //     content: (
      //       <div className="pane-body">
      //         <Log targetedData={targetedData} />
      //       </div>
      //     ),
      //   },
      // },
  ];

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      let execlude_ids = ['popupTrigger'];
      // console.log(e.target.id);
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);

  const deleteHandler = async () => {
    const response = await deleteSet(targetedData.id);

    if (response.status == 200) {
      toast.success(t.sets.delete_success);
      setData();
      untoggle();
      setModalWidth( Math.max(window.innerWidth / 2, 514));
      setRefresh(!refresh);
    } else if (response.status == 400) {
      toast.error(t.sets.delete_error);
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  return (
    
    <>
    
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? 'aside-modal preview active procedure' : 'aside-modal preview procedure'
        }
      >
         <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
        <div className="modal-header detail-header">
          <div className="flexbox justify-between align-center">
            <div className="name">
              {t.sets.add} #{data?.attributes?.countId }
            </div>
            {/* {orderActions()} */}

            <div className="flexbox align-center detail-header-buttons">
              <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowPdfTooltip(true)}
                onMouseLeave={() => setShowPdfTooltip(false)}
                // onClick={() => generatePDF()}
              >
                <DownloadIconBucket />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showPdfTooltip}
                  textBoxWidth="60px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_pdf}
                  </span>
                </ToolTip>
              </span>
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </ToolTip>
              </span> */}
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </ToolTip>
              </span> */}
              {userPermissions?.part?.edit ||
              checkForPermission(
                targetedData?.attributes?.createdByUser?.data?.id
              ) ? (
                <span
                  className="header-button c-pointer mt-1"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setEditTooltip(true)}
                  onMouseLeave={() => setEditTooltip(false)}
                  onClick={() => {
                    handleWOFind();
                  }}
                >
                  <EditTextIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    arrowAlign="center"
                    position="bottom center"
                    show={showEditTooltip}
                    textBoxWidth="33px"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.edit}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              {userPermissions?.part?.delete ? 
               (
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    setDeletePermissionModal(true);
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    arrowAlign="center"
                    position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              <span
                className={`procedure-header-button c-pointer ${
                  selectedLanguage == 'en' ? 'ml-1' : 'mr-3'
                }`}
                style={{ position: 'relative' }}
                onMouseOver={() => setCancleTooltip(true)}
                onMouseLeave={() => setCancleTooltip(false)}
                onClick={() => {
                  untoggle();
                  setModalWidth( Math.max(window.innerWidth / 2, 514));
                }}
              >
                <CloseIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={
                    selectedLanguage == 'en' ? 'bottom center' : 'bottom center'
                  }
                  arrowAlign="center"
                  show={showCancleTooltip}
                  textBoxWidth="36px"
                  padding="5px 5px 5px 7px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.close}
                  </span>
                </ToolTip>
              </span>
            </div>
          </div>
        </div>

        <div className="body">
          {data ? (
            <Tab panes={panes} renderActiveOnly={false} />
          ) : (
            <Dimmer active inverted>
              <Loader inverted>{t.common.loading}</Loader>
            </Dimmer>
          )}
          {/* {data ? (
            <div className="pane-body">
             
            </div>
          ) : (
            // <Tab panes={panes} renderActiveOnly={false} />
            <Dimmer active inverted>
              <Loader inverted>{t.common.loading}</Loader>
            </Dimmer>
          )} */}
        </div>
        </Resizable>
      </div>
      <div
        className={
          toggled || toggleAsideModal
            ? 'aside-modal-background active'
            : 'aside-modal-background'
        }
        onClick={() => {
          setData();
          untoggle();
          setModalWidth( Math.max(window.innerWidth / 2, 514));
          setMessage('');
          setToggleAsideModal(false);
        }}
      ></div>

      {/* delete modal */}

      <DeletePermissionModal
        yesCallback={() => {
          setDeleteModal(false);
          deleteHandler();
        }}
        title={t.sets.add}
      >
        <span>{t.sets.delete_set_statement}</span>
      </DeletePermissionModal>

      <Modal
        size="tiny"
        className="assets-modal"
        open={toggleWOModal}
        onClose={() => {
          setToggleWOModal(false);
          setWOData([]);
          // setToggleStatus(true);
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.sets.edit_set}</span>

          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setToggleWOModal(false);
                setWOData([]);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <span className="assets-label ml-1">{t.sets.wo_statement}</span>

          {WOdata?.length ? (
            <div
              style={{ maxHeight: '136px' }}
              className="deactive_work_order_list"
            >
              {WOdata.map((one) => {
                return (
                  <p
                    className={`detail-intro-link mb-1 ml-1 mr-1 ${
                      selectedLanguage == 'en' ? 'text-left' : 'text-right'
                    }`}
                    basic
                    onClick={() => history.push(`/work-orders?id=${one.attributes?.countId}`)}
                  >
                    {`#${one.id} `} {one?.attributes?.title}
                  </p>
                );
              })}
            </div>
          ) : null}
        </Modal.Content>

        <div
          className={`modal-button-container mt-3 ${
            selectedLanguage == 'en' ? 'mr-6' : 'ml-10'
          }`}
        >
          <button
            onClick={() => {
              setToggleWOModal(false);
              setWOData([]);
            }}
            style={{ color: 'black' }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>
        </div>
      </Modal>
    </>
  );
}

export default Preview;
