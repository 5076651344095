export default {
  title: 'الفئات',
  add: 'فئة',
  create_category: 'إضافة فئة',
  title_small: 'الفئات',
  filter: {
    searchByName: 'البحث عن طريق إسم الممتلك',
    searchBySerialNumber: 'البحث عن طريق الرقم التسلسلي',
    area: 'المجال',
    model: 'النموذج',
    barcode: 'الباركود',
    wed: 'تاريخ انتهاء الضمان',
    vendors: 'البائعين',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    qrcode: 'رمز الاستجابة السريعة',
  },
  table: {
    name: 'الإسم',
    ID: 'الرمز',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    updatedAt: 'تاريخ التحديث',
    action: 'الإجراءات',
  },
  cannot_delete_category_statement:
    'لا يمكن حذف هذه الفئة لأنها مخصصة لبعض الممتلكات والقطع',
  edit_category: 'تعديل فئة',
  save: 'حفظ',
  category: 'فئة',
  category_name: 'اسم الفئة',
  jobTitle: 'مسمى وظيفي',
  add_a_category_name: 'إضافة فئة',
  delete_category_statement: 'هل أنت متأكد أنك تريد حذف هذا الفئة؟',
  no_category_available: 'يبدو أنك لا تمتلك فئات بعد',
  category_updated_success: 'تم تحديث الفئة بنجاح',
  category_already_exist: 'الفئة موجودة بالفعل باسم معين',
  add_category_name: 'يرجى إضافة اسم الفئة',
  category_delete_success: 'تم حذف الفئة بنجاح',
  category_create_success: 'تم إنشاء الفئة بنجاح',
  category_upload_success: 'تم رفع الفئة بنجاح',
  category_already_exist: 'الفئة موجودة بالفعل باسم معين',
  category_not_created: 'لا يمكن إنشاء الفئة',
  import: 'تحميل ملفات',
  import_categories: 'استيراد فئات',
  upload_categories:"استيراد الفئات",
  upload_excel_category_file: 'يرجى تحميل ملف Excel الصحيح لإضافة فئات',
  upload_excel_jobtitle_file:
  'يرجى تحميل ملف Excel الصحيح لإضافة المسمى الوظيفي',
  download_sample: 'تحميل ملف عينة',
  status: 'الحالة',
  error: 'خطأ',
  failed: 'فشل',
  warn: 'تنبيه',
  success: 'نجاح',
  unique: 'يجب أن يكون اسم الفئة فريدًا',
  how_to_add_category: 'كيفية إضافة الفئات',
  no_category_added: 'ابدأ بإضافة الفئات',
  job_title_already_exist: 'المسمى الوظيفي موجود',
  not_updated:"لا يمكن تحديث الفئة"
};
