import React, { useState, useEffect, createRef } from 'react';
import {
  Loader,
  Segment,
  Header,
  Dimmer,
  Modal,
  Dropdown,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  List,
  Grid,
  Popup,
} from 'semantic-ui-react';
import { format } from 'date-fns';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';

import { Country, State, City } from 'country-state-city';

import {
  validateEmail,
  uploadFile,
  country_codes,
  updateUserRole,
  updateUserStatusToPending,
  updateUser,
  getAllParentLocations,
  getAllTeams,
  IMAGES_URL,
  getUsersByRole,
  updateUserProfile,
  urlToFile,
  verifyPersonEmailAndPhone,
  getAllJobTitles,
  getAllLocations,
  getActiveUsersByRole,
} from '../../config/functions';
import { at, truncate } from 'lodash';

import md5 from 'md5';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import PeopleForm from './PeopleForm';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { selectTranslations } from '../../config/i18n/slice';
import {
  AdminIcon,
  CloseIcon,
  LimitedAdminIcon,
  TechnicianIcon,
  ViewerIcon,
} from '../../../Assets/Icons/svg';
import useCancelModal from '../../../hooks/Messages/useCancelModal';
import ToolTip from 'react-power-tooltip';
import { Resizer } from 're-resizable/lib/resizer';
import { Resizable } from 're-resizable';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const firstTabValidationFields = [
  'firstName',
  'lastName',
  'department',
  'supervisor',
  // 'whpw',
  // 'whpd',
  // 'contractStart',
  // 'contractEnd',
  // 'badgeId',
  // 'registrationNo',
  // 'workPermitNo',
  // 'username',
  'email',
  'phone',
  'jobTitle',
  'hourRate',
  'shifts',
  'days',
  'locations',
];
const secondTabValidationFields = ['emergencyPhone'];

function Edit({
  targetedData,
  targetedUserProfile,
  targetedUserProfileID,
  toggled,
  untoggle,
  refresh,
  setRefresh,
  setFilterOptions,
  setSearchTitle,
  setSortData,
  roleData,
  allIds
}) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(true);
  const [activePane, setActivePane] = useState(0);
  const [errorPane, setErrorPane] = useState({
    pane0: false,
    pane1: false,
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [buttonStatus, setButtonStatus] = useState(0);
  const [isLoadingActive, activateLoading] = useState(false);
  const [isAsideModalOpen, toggleAsideModalOpen] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [fetchingLocations, setFetchingLocations] = useState(true);
  const [fetchingJobTitle, setFetchingJobTitle] = useState(true);
  const [fetchingTeams, setFetchingTeams] = useState(true);
  const [fetchingSupervisors, setFetchingSupervisors] = useState(true);
  const [fetchingCountries, setFetchingCountries] = useState(true);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [personalImgId, setPersonalImgId] = useState();
  const t = useSelector(selectTranslations);

  const PersonValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
    // department: Yup.string().required(t.common.required_field),
    // whpw: Yup.string().required(t.common.required_field),
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),

    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .required(t.common.required_field),
    phone: Yup.string()
      .required(t.common.required_field)
      .test('correct-format', t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    emergencyPhone: Yup.string().test(
      'correct-format',
      t.people.invalid_phone,
      function(value) {
        if (!value || isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }
    ),
    // jobTitle: Yup.string().required(t.common.required_field),
    // hourRate: Yup.string()
    //   .matches(/^\d+$/, 'Should contain only numeric values')
    //   .required(t.common.required_field),
    shifts: Yup.object()
      .shape({
        shiftA: Yup.boolean().required(),
        shiftB: Yup.boolean().required(),
        shiftC: Yup.boolean().required(),
      })
      .test('at-least-one-true', t.common.required_field, function(object) {
        const { shiftA, shiftB, shiftC } = object;

        // Check if at least one value is true
        if (shiftA || shiftB || shiftC) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    days: Yup.object()
      .shape({
        sat: Yup.boolean().required(),
        sun: Yup.boolean().required(),
        mon: Yup.boolean().required(),
        tue: Yup.boolean().required(),
        wed: Yup.boolean().required(),
        thu: Yup.boolean().required(),
        fri: Yup.boolean().required(),
      })
      .test('at-least-one-true', t.common.required_field, function(object) {
        const { sat, sun, mon, tue, wed, thu, fri } = object;

        // Check if at least one value is true
        if (sat || sun || mon || tue || wed || thu || fri) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
  });

  const PersonLimitedValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
    supervisor: Yup.string().required(t.common.required_field),
    // department: Yup.string().required(t.common.required_field),
    // whpw: Yup.string().required(t.common.required_field),
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),

    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .required(t.common.required_field),
    phone: Yup.string()
      .required(t.common.required_field)
      .test('correct-format', t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    emergencyPhone: Yup.string().test(
      'correct-format',
      t.people.invalid_phone,
      function(value) {
        if (!value || isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }
    ),

    // hourRate: Yup.string()
    //   .matches(/^\d+$/, 'Should contain only numeric values')
    //   .required(t.common.required_field),
    shifts: Yup.object()
      .shape({
        shiftA: Yup.boolean().required(),
        shiftB: Yup.boolean().required(),
        shiftC: Yup.boolean().required(),
      })
      .test('at-least-one-true', t.common.required_field, function(object) {
        const { shiftA, shiftB, shiftC } = object;

        // Check if at least one value is true
        if (shiftA || shiftB || shiftC) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    days: Yup.object()
      .shape({
        sat: Yup.boolean().required(),
        sun: Yup.boolean().required(),
        mon: Yup.boolean().required(),
        tue: Yup.boolean().required(),
        wed: Yup.boolean().required(),
        thu: Yup.boolean().required(),
        fri: Yup.boolean().required(),
      })
      .test('at-least-one-true', t.common.required_field, function(object) {
        const { sat, sun, mon, tue, wed, thu, fri } = object;

        // Check if at least one value is true
        if (sat || sun || mon || tue || wed || thu || fri) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
    // jobTitle: Yup.string().required(t.common.required_field),
  });

  const PersonTechnicianValidationSchema = Yup.object().shape({
    firstName: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),

    // whpw: Yup.string().required(t.common.required_field),
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),
    // department: Yup.string().required(t.common.required_field),

    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string().email(t.people.invalid_email),
    phone: Yup.string()
      .required(t.common.required_field)
      .test('correct-format', t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    emergencyPhone: Yup.string().test(
      'correct-format',
      t.people.invalid_phone,
      function(value) {
        if (!value || isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }
    ),

    // hourRate: Yup.string()
    //   .matches(/^\d+$/, 'Should contain only numeric values')
    //   .required(t.common.required_field),
    shifts: Yup.object()
      .shape({
        shiftA: Yup.boolean().required(),
        shiftB: Yup.boolean().required(),
        shiftC: Yup.boolean().required(),
      })
      .test('at-least-one-true', t.common.required_field, function(object) {
        const { shiftA, shiftB, shiftC } = object;

        // Check if at least one value is true
        if (shiftA || shiftB || shiftC) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    days: Yup.object()
      .shape({
        sat: Yup.boolean().required(),
        sun: Yup.boolean().required(),
        mon: Yup.boolean().required(),
        tue: Yup.boolean().required(),
        wed: Yup.boolean().required(),
        thu: Yup.boolean().required(),
        fri: Yup.boolean().required(),
      })
      .test('at-least-one-true', t.common.required_field, function(object) {
        const { sat, sun, mon, tue, wed, thu, fri } = object;

        // Check if at least one value is true
        if (sat || sun || mon || tue || wed || thu || fri) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
    locations: Yup.array()
      .of(Yup.number())
      .min(1, t.common.required_field)
      .required(t.common.required_field),
    // jobTitle: Yup.string().required(t.common.required_field),
    supervisor: Yup.string().required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
  });

  const PersonRequesterValidationSchema = Yup.object().shape({
    // whpd: Yup.string().required(t.common.required_field),
    // contractStart: Yup.string().required(t.common.required_field),
    // contractEnd: Yup.string().required(t.common.required_field),
    // badgeId: Yup.string().required(t.common.required_field),
    // registrationNo: Yup.string().required(t.common.required_field),
    // workPermitNo: Yup.string().required(t.common.required_field),
    firstName: Yup.string().required(t.common.required_field),
    locations: Yup.array()
    .of(Yup.number())
    .min(1, t.common.required_field)
    .required(t.common.required_field),
    role: Yup.string().required(t.common.required_field),
    lastName: Yup.string().required(t.common.required_field),
    tab: Yup.number(),
    countryCode: Yup.string(),
    // username: Yup.string().required(t.common.required_field),
    email: Yup.string()
      .email(t.people.invalid_email)
      .nullable(),
    phone: Yup.string()
      .required(t.common.required_field)
      .test('correct-format', t.people.invalid_phone, function(value) {
        if (isPhoneValid(value)) {
          return true; // Validation passes
        }

        return false; // Validation fails
      }),
  });

  const [options, setOptions] = useState({
    locations: [],
    roles: [
      {
        key: 0,
        value: 5,
        text: t.people.add_modal.admin,
        icon: <AdminIcon />,
        description: t.people.add_modal.admin_description,
      },
      // {
      //   key: 89,
      //   value: 7,
      //   text: 'Supervisor',
      //   icon: 'handshake outline',
      //   description: 'Can add orders, assets, locations, people',
      // },
      {
        key: 2,
        value: 9,
        text: t.people.add_modal.limited_admin,
        icon: <LimitedAdminIcon />,
        description: t.people.add_modal.limited_admin_description,
      },
      {
        key: 1,
        value: 8,
        text: t.people.add_modal.technician,
        icon: <TechnicianIcon />,
        description: t.people.add_modal.user_description,
      },

      {
        key: 3,
        value: 3,
        text: t.people.add_modal.viewer,
        icon: <ViewerIcon />,
        description: t.people.add_modal.viewer_description,
      },
      // {
      //   key: 2,
      //   value: 6,
      //   text: t.people.add_modal.requester,
      //   icon: <ViewerIcon />,
      //   description:
      //    t.people.add_modal.requester_desc
      // },
      // {
      //   key: 3,
      //   value: 3,
      //   text: 'Viewer only',
      //   icon: 'sitemap',
      //   description:
      //     'Lorem ipsum is placeholder text commonly used in the graphic',
      // },
    ],
    martialStatus: [
      {
        key: 0,
        value: 'single',
        text: t.people.personal_info_form.martial_option.single,
      },

      {
        key: 1,
        value: 'married',
        text: t.people.personal_info_form.martial_option.married,
      },
    ],

    userRole : [
      {
        key: 5,
        value: 5,
        text: t.people.add_modal.admin,
      },

      {
        key: 9,
        value: 9,
        text: t.people.add_modal.limited_admin,
      },
      {
        key: 8,
        value: 8,
        text: t.people.add_modal.technician,
      },
      {
        key: 3,
        value: 3,
        text: t.people.add_modal.viewer,
      },
      {
        key: 6,
        value: 6,
        text: t.people.add_modal.requester,
      },
    ],

    limitedRoles : [
     
      {
        key: 9,
        value: 9,
        text: t.people.add_modal.limited_admin,
      },
      {
        key: 8,
        value: 8,
        text: t.people.add_modal.technician,
      },
      {
        key: 3,
        value: 3,
        text: t.people.add_modal.viewer,
      },
      {
        key: 6,
        value: 6,
        text: t.people.add_modal.requester,
      },
    ],

    childrenNumber: [
      {
        key: 0,
        value: 0,
        text: '0',
      },

      {
        key: 1,
        value: 1,
        text: '1',
      },
      {
        key: 2,
        value: 2,
        text: '2',
      },
      {
        key: 3,
        value: 3,
        text: '3',
      },
      {
        key: 4,
        value: 4,
        text: '4',
      },
      {
        key: 5,
        value: 5,
        text: '5',
      },
      {
        key: 6,
        value: 6,
        text: '6',
      },
      {
        key: 7,
        value: 7,
        text: '7',
      },
    ],

    studyFiled: [
      {
        key: 0,
        value: 'Agriculture and Forestry',
        text: t.people.studyField.agriculture,
      },

      {
        key: 1,
        value: 'Architecture and Urban Planning',
        text: t.people.studyField.agricultureUrban,
      },
      {
        key: 2,
        value: 'Arts and Humanities',
        text: t.people.studyField.arts,
      },
      {
        key: 3,
        value: 'Astronomy and Astrophysics',
        text: t.people.studyField.astronomy,
      },
      {
        key: 4,
        value: 'Business and Economics',
        text: t.people.studyField.economics,
      },
      {
        key: 5,
        value: 'Communication and Media Studies',
        text: t.people.studyField.mediaStudy,
      },
      {
        key: 6,
        value: 'Computer Science and Information Technology',
        text: t.people.studyField.computerScience,
      },
      {
        key: 7,
        value: 'Cultural Studies',
        text: t.people.studyField.cultural,
      },
      {
        key: 8,
        value: 'Education',
        text: t.people.studyField.education,
      },
      {
        key: 9,
        value: 'Engineering and Technology',
        text: t.people.studyField.engineering,
      },
      {
        key: 10,
        value: 'Environmental Science',
        text: t.people.studyField.environmental,
      },
      {
        key: 11,
        value: 'Fine Arts and Design',
        text: t.people.studyField.fine_arts,
      },
      {
        key: 12,
        value: 'Geology and Earth Sciences',
        text: t.people.studyField.geology,
      },
      {
        key: 13,
        value: 'Health and Medicine',
        text: t.people.studyField.health,
      },
      {
        key: 14,
        value: 'Hospitality and Tourism',
        text: t.people.studyField.hospitality,
      },
      {
        key: 15,
        value: 'Law and Legal Studies',
        text: t.people.studyField.law,
      },
      {
        key: 16,
        value: 'Library and Information Science',
        text: t.people.studyField.library,
      },
      {
        key: 17,
        value: 'Linguistics',
        text: t.people.studyField.linguistics,
      },
      {
        key: 18,
        value: 'Mathematics and Statistics',
        text: t.people.studyField.mathematics,
      },
      {
        key: 19,
        value: 'Natural Sciences',
        text: t.people.studyField.naturalSciences,
      },
      {
        key: 20,
        value: 'Performing Arts',
        text: t.people.studyField.performingArts,
      },
      {
        key: 21,
        value: 'Philosophy',
        text: t.people.studyField.philosophy,
      },
      {
        key: 22,
        value: 'Physical Education and Sports Science',
        text: t.people.studyField.physicalEducation,
      },
      {
        key: 23,
        value: 'Political Science',
        text: t.people.studyField.politicalScience,
      },
      {
        key: 24,
        value: 'Psychology',
        text: t.people.studyField.psychology,
      },
      {
        key: 25,
        value: 'Public Administration',
        text: t.people.studyField.publicAdministration,
      },
      {
        key: 26,
        value: 'Religious Studies',
        text: t.people.studyField.religiousStudies,
      },
      {
        key: 27,
        value: 'Social Sciences',
        text: t.people.studyField.socialSciences,
      },
      {
        key: 28,
        value: 'Sociology',
        text: t.people.studyField.sociology,
      },
    ],

    educationLevel: [
      {
        key: 0,
        value: 'highSchoolDegree',
        text: t.people.personal_info_form.education_options.high_school,
      },
      {
        key: 1,
        value: 'diplomaDegree',
        text: t.people.personal_info_form.education_options.diploma,
      },
      {
        key: 2,
        value: 'bachelorsDegree',
        text: t.people.personal_info_form.education_options.bachelors,
      },
      {
        key: 3,
        value: 'mastersDegree',
        text: t.people.personal_info_form.education_options.masters,
      },
      {
        key: 4,
        value: 'doctorateDegree',
        text: t.people.personal_info_form.education_options.doctorate,
      },
      {
        key: 5,
        value: 'other',
        text: t.people.personal_info_form.education_options.other,
      },
    ],
    countries: [],

    gender: [
      {
        key: 0,
        value: 'male',
        text: t.people.personal_info_form.gender_options.gender_male,
      },

      {
        key: 1,
        value: 'female',
        text: t.people.personal_info_form.gender_options.gender_female,
      },
    ],

    users: [],
    teams: [],
    jobTitle: '',
  });

  const [data, setData] = useState({
    locations: [],
    teams: [],
    hourRate: '',
    whpd: '',
    whpw: '',
    department: '',
    contractStart: '',
    contractEnd: '',
    shifts: {
      shiftA: false,
      shiftB: false,
      shiftC: false,
    },
    days: {
      sat: false,
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
    },
    firstName: '',
    lastName: '',
    username: '',
    address: '',
    email: '',
    countryCode: '962',
    phone: '',
    martialStatus: 'single',
    emergencyName: '',
    emergencyCountryCode: '962',
    emergencyPhone: '',
    educationLevel: 'highSchoolDegree',
    fieldOfStudy: '',
    nationality: '',
    identificationNo: '',
    passportNo: '',
    gender: 'male',
    dob: '',
    cob: '',
    childrenNo: '',
    visaNo: '',
    workPermitNo: '',
    visaExpireDate: '',
    supervisor: '',
    jobTitle: '',
    role: '',
    registrationNo: '',
    badgeId: '',
  });

  const [validation, setValidation] = useState({
    // whpd: {
    //   error: false,
    //   pattern: (whpd) => whpd != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    // whpw: {
    //   error: false,
    //   pattern: (whpw) => whpw != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },

    // contractStart: {
    //   error: false,
    //   pattern: (contractStart) =>
    //     moment(contractStart).format('YYYY-MM-DD') ===
    //       moment(contractStart).format('YYYY-MM-DD') || contractStart != '',
    //   skipped: false,
    //   message: 'Invalid date',
    // },
    locations: {
      error: false,
      pattern: (locations) => locations.length > 0 && locations != '',
      skipped: false,
      message: t.common.required_field,
    },
    firstName: {
      error: false,
      pattern: (firstName) => firstName != '',
      skipped: false,
      message: t.common.required_field,
    },
    lastName: {
      error: false,
      pattern: (lastName) => lastName != '',
      skipped: false,
      message: t.common.required_field,
    },
    // username: {
    //   error: false,
    //   pattern: (username) => username != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },
    email: {
      error: false,
      pattern: (email) => validateEmail(email),
      skipped: false,
      message: t.people.invalid_email,
    },

    phone: {
      error: false,
      pattern: (phone) => phone != '',
      skipped: false,
      message: t.common.required_field,
    },

    // dob: {
    //   error: false,
    //   pattern: (dob) =>
    //     moment(dob).format('YYYY-MM-DD') === moment(dob).format('YYYY-MM-DD') ||
    //     dob != '',
    //   skipped: false,
    //   message: 'Invalid date',
    // },

    jobTitle: {
      error: false,
      pattern: (title) => title != '',
      skipped: false,
      message: t.common.required_field,
    },
    // identificationNo: {
    //   error: false,
    //   pattern: (num) => num != '',
    //   skipped: false,
    //   message: t.common.required_field,
    // },

    shifts: {
      error: false,
      pattern: (shifts) => shifts.some((el) => el != false),
      skipped: false,
      message: t.common.required_field,
    },

    days: {
      error: false,
      pattern: (days) => days.some((el) => el != false),
      skipped: false,
      message: t.common.required_field,
    },
    hourRate: {
      error: false,
      pattern: (hourRate) => hourRate != '',
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  const [attachments, setAttachments] = useState({
    contractCopy: null,
    personalPicture: null,
    passportCopy: null,
    idCopyFront: null,
    visaCopy: null,
    covidCopy: [],
  });

  const resetForm = () => {
    setData({
      locations: [],
      teams: [],
      hourRate: '',
      whpd: '',
      whpw: '',
      department: '',
      contractStart: '',
      contractEnd: '',
      shifts: {
        shiftA: false,
        shiftB: false,
        shiftC: false,
      },
      days: {
        sat: false,
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
      },
      firstName: '',
      lastName: '',
      username: '',
      address: '',
      email: '',
      countryCode: '962',
      phone: '',
      martialStatus: 'single',
      emergencyName: '',
      emergencyCountryCode: '962',
      emergencyPhone: '',
      educationLevel: 'highSchoolDegree',
      fieldOfStudy: '',
      nationality: '',
      identificationNo: '',
      passportNo: '',
      gender: 'male',
      dob: '',
      cob: '',
      childrenNo: '',
      visaNo: '',
      workPermitNo: '',
      visaExpireDate: '',
      supervisor: '',
      jobTitle: '',
      role: '',
      registrationNo: '',
      badgeId: '',
    });
    setModalWidth(Math.max(window.innerWidth / 2, 514));

    setAttachments({
      contractCopy: null,
      personalPicture: null,
      passportCopy: null,
      idCopyFront: null,
      visaCopy: null,
      covidCopy: [],
    });

    setActivePane(0);

    setValidation({
      // whpd: {
      //   error: false,
      //   pattern: (whpd) => whpd != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      // whpw: {
      //   error: false,
      //   pattern: (whpw) => whpw != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },

      // contractStart: {
      //   error: false,
      //   pattern: (contractStart) =>
      //     moment(contractStart).format('YYYY-MM-DD') ===
      //       moment(contractStart).format('YYYY-MM-DD') || contractStart != '',
      //   skipped: false,
      //   message: 'Invalid date',
      // },
      // contractEnd: {
      //   error: false,
      //   pattern: (contractEnd) =>
      //     moment(contractEnd).format('YYYY-MM-DD') ===
      //       moment(contractEnd).format('YYYY-MM-DD') || contractEnd != '',
      //   skipped: false,
      //   message: 'Invalid date',
      // },
      locations: {
        error: false,
        pattern: (locations) => locations.length > 0 && locations != '',
        skipped: false,
        message: t.common.required_field,
      },
      firstName: {
        error: false,
        pattern: (firstName) => firstName != '',
        skipped: false,
        message: t.common.required_field,
      },
      lastName: {
        error: false,
        pattern: (lastName) => lastName != '',
        skipped: false,
        message: t.common.required_field,
      },
      // username: {
      //   error: false,
      //   pattern: (username) => username != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },
      email: {
        error: false,
        pattern: (email) => validateEmail(email),
        skipped: false,
        message: t.people.invalid_email,
      },

      phone: {
        error: false,
        pattern: (phone) => phone != '',
        skipped: false,
        message: t.common.required_field,
      },

      // dob: {
      //   error: false,
      //   pattern: (dob) =>
      //     moment(dob).format('YYYY-MM-DD') ===
      //       moment(dob).format('YYYY-MM-DD') || dob != '',
      //   skipped: false,
      //   message: 'Invalid date',
      // },

      // identificationNo: {
      //   error: false,
      //   pattern: (num) => num != '',
      //   skipped: false,
      //   message: t.common.required_field,
      // },

      shifts: {
        error: false,
        pattern: (shifts) => shifts.some((el) => el != false),
        skipped: false,
        message: t.common.required_field,
      },

      days: {
        error: false,
        pattern: (days) => days.some((el) => el != false),
        skipped: false,
        message: t.common.required_field,
      },
      hourRate: {
        error: false,
        pattern: (hourRate) => hourRate != '',
        skipped: false,
        message: t.common.required_field,
      },
    });

    setErrorPane({
      pane0: false,
      pane1: false,
    });

    setFetchingCountries(true);
    setFetchingLocations(true);
    setFetchingJobTitle(true);
    setFetchingSupervisors(true);
    setInitChange(false);
    setFetchingTeams(true);
  };

  useEffect(() => {
    if (toggled) {
      fetchOptions();
      // if (
      //   [
      //     fetchingCountries,
      //     fetchingLocations,
      //     fetchingSupervisors,
      //     fetchingTeams,
      //   ].every((v) => v !== false)
      // ) {
      if (targetedData && targetedUserProfile && targetedUserProfileID) {
        setData({
          userId: targetedData?.id,
          profileId: targetedUserProfileID,
          hourRate: targetedUserProfile?.hourRate || '',
          whpd: targetedUserProfile?.whpd ? targetedUserProfile?.whpd : '',
          whpw: targetedUserProfile?.whpw ? targetedUserProfile?.whpw : '',
          department: targetedUserProfile?.department || '',
          contractStart: targetedUserProfile?.contractStart
            ? format(new Date(targetedUserProfile.contractStart), 'yyyy-MM-dd')
            : '',
          contractEnd: targetedUserProfile?.contractEnd
            ? format(new Date(targetedUserProfile.contractEnd), 'yyyy-MM-dd')
            : '',
          shifts: {
            shiftA: targetedUserProfile?.shifts?.shiftA || false,
            shiftB: targetedUserProfile?.shifts?.shiftB || false,
            shiftC: targetedUserProfile?.shifts?.shiftC || false,
          },
          days: {
            sat: targetedUserProfile?.days?.sat || false,
            sun: targetedUserProfile?.days?.sun || false,
            mon: targetedUserProfile?.days?.mon || false,
            tue: targetedUserProfile?.days?.tue || false,
            wed: targetedUserProfile?.days?.wed || false,
            thu: targetedUserProfile?.days?.thu || false,
            fri: targetedUserProfile?.days?.fri || false,
          },
          firstName: targetedData?.firstName ? targetedData?.firstName : '',
          lastName: targetedData?.lastName ? targetedData?.lastName : '',
          // username: targetedData?.username,
          address: targetedUserProfile?.address
            ? targetedUserProfile?.address
            : '',
          email: targetedData?.email ? targetedData?.email : '',
          countryCode: targetedUserProfile?.phone
            ? String(targetedUserProfile?.phone).slice(0, 3)
            : '',
          phone: targetedUserProfile?.phone || '',
          martialStatus: targetedUserProfile?.martialStatus
            ? targetedUserProfile?.martialStatus
            : 'single',
          emergencyName: targetedUserProfile?.emergencyName
            ? targetedUserProfile?.emergencyName
            : '',
          emergencyCountryCode: targetedUserProfile?.emergencyPhone
            ? String(targetedUserProfile?.emergencyPhone).slice(0, 3)
            : '',
          emergencyPhone: targetedUserProfile?.emergencyPhone || '',
          educationLevel: targetedUserProfile?.educationLevel
            ? targetedUserProfile?.educationLevel
            : 'highSchoolDegree',
          fieldOfStudy: targetedUserProfile?.fieldOfStudy
            ? targetedUserProfile?.fieldOfStudy
            : '',
          identificationNo: targetedUserProfile?.identificationNo
            ? targetedUserProfile?.identificationNo
            : '',
          passportNo: targetedUserProfile?.passportNo
            ? targetedUserProfile?.passportNo
            : '',
          gender: targetedUserProfile?.gender
            ? targetedUserProfile?.gender
            : 'male',
          dob: targetedUserProfile?.dob ? moment(targetedUserProfile.dob) : '',
          childrenNo: targetedUserProfile?.childrenNo
            ? targetedUserProfile?.childrenNo
            : '',
          visaNo: targetedUserProfile?.visaNo
            ? targetedUserProfile?.visaNo
            : '',
          workPermitNo: targetedUserProfile?.workPermitNo,
          visaExpireDate: targetedUserProfile?.visaExpireDate
            ? moment(targetedUserProfile?.visaExpireDate)
            : '',

          jobTitle: targetedUserProfile?.job?.data?.id || '',
          role: targetedData?.role?.id ? targetedData?.role?.id : '',
          registrationNo: targetedUserProfile?.registrationNo
            ? targetedUserProfile?.registrationNo
            : '',
          badgeId: targetedUserProfile?.badgeId
            ? targetedUserProfile?.badgeId
            : '',
          supervisor: targetedData?.supervisor?.id
            ? targetedData?.supervisor?.id
            : '',
          teams: targetedData?.teams?.map((team) => team.id),
          nationality: targetedUserProfile?.nationality
            ? targetedUserProfile?.nationality
            : '',
          cob: targetedUserProfile?.cob ? targetedUserProfile?.cob : '',
          locations:
            targetedData?.locations.length > 0
              ? targetedData?.locations?.map((loc) => loc.id)
              : '',
        });

        // console.log('targetedUserProfile', targetedData);
        setAttachments({
          contractCopy: targetedUserProfile?.contractCopy?.data
            ? {
                id: targetedUserProfile?.contractCopy?.data?.id,
                file: urlToFile(
                  targetedUserProfile?.contractCopy?.data?.attributes?.url,
                  targetedUserProfile?.contractCopy?.data?.attributes?.name
                ),

                name: targetedUserProfile?.contractCopy?.data?.attributes.name,
                type: targetedUserProfile?.contractCopy?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          personalPicture: targetedData?.profileImage?.id
            ? {
                id: targetedData?.profileImage?.id,
                url: targetedData?.profileImage?.url,
                file: urlToFile(
                  targetedData?.profileImage?.url,
                  targetedData?.profileImage?.name
                ),
                name: targetedData?.profileImage?.name,
                type: targetedData?.profileImage?.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          passportCopy: targetedUserProfile?.passportCopy?.data
            ? {
                id: targetedUserProfile?.passportCopy?.data?.id,
                file: urlToFile(
                  targetedUserProfile?.passportCopy?.data?.attributes?.url,
                  targetedUserProfile?.passportCopy?.data?.attributes?.name
                ),
                name: targetedUserProfile?.passportCopy?.data?.attributes.name,
                type: targetedUserProfile?.passportCopy?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          idCopyFront: targetedUserProfile?.idCopyFront?.data
            ? {
                id: targetedUserProfile?.idCopyFront?.data?.id,
                file: urlToFile(
                  targetedUserProfile?.idCopyFront?.data?.attributes?.url,
                  targetedUserProfile?.idCopyFront?.data?.attributes?.name
                ),
                name: targetedUserProfile?.idCopyFront?.data?.attributes.name,
                type: targetedUserProfile?.idCopyFront?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          visaCopy: targetedUserProfile?.visaCopy?.data
            ? {
                id: targetedUserProfile?.visaCopy?.data?.id,
                file: urlToFile(
                  targetedUserProfile?.visaCopy?.data?.attributes?.url,
                  targetedUserProfile?.visaCopy?.data?.attributes?.name
                ),
                name: targetedUserProfile?.visaCopy?.data?.attributes.name,
                type: targetedUserProfile?.visaCopy?.data?.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
          covidCopy:
            targetedUserProfile?.covidCopy?.data &&
            targetedUserProfile?.covidCopy?.data?.length
              ? targetedUserProfile?.covidCopy?.data?.map((one) => {
                  return {
                    id: one?.id,
                    file: urlToFile(
                      one?.attributes?.url,
                      one?.attributes?.name
                    ),
                    name: one?.attributes?.name,
                    type: one?.attributes?.mime,
                    cancelPopup: false,
                    isChanged: false,
                  };
                })
              : [],
        });
      }
      // }
    }
  }, [toggled, targetedData, targetedUserProfile]);

  // const [attachmentsResponse, setAttachmentsResponse] = useState([]);
  const contractCopyRef = createRef();
  const personalPictureRef = createRef();
  const passportCopyRef = createRef();
  const idCopyFrontRef = createRef();
  const visaCopyRef = createRef();
  const covidCopyRef = createRef();

  const fetchOptions = async () => {
    const [teams, users, locations, countries, jobTitle] = await Promise.all([
      getAllTeams(organisationId,'',1,99999,[],[],[],!(roleData == "super_admin"|| roleData == "admin") ? targetedData?.locations?.map((loc) => loc.id) : []),
      getActiveUsersByRole(
        ['admin', 'limited_admin', 'super_admin'],
        organisationId,
        !(roleData == "super_admin"|| roleData == "admin") ? targetedData?.locations?.map((loc) => loc.id) : []
      ),
      getAllLocations(organisationId),
      Country.getAllCountries(),
      getAllJobTitles(organisationId, '', 1, 99999),
    ]);

    let teams_options = [];
    if (teams.response) {
      teams_options = teams.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingTeams(false);
    }

    let users_options = [];
    if (users.data) {
      users_options = users.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
        return {
          key: el.id,
          value: el.id,
          text: `${el.firstName} ${el.lastName ? el.lastName : ''}`,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });

      setFetchingSupervisors(false);
    }

    let locations_options = [];
    if (locations) {
      locations.forEach((el) => {
        if ( !(roleData == "super_admin"|| roleData == "admin")) {
          if(allIds?.includes(el.id)){
 
            locations_options.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
         
            })
  
          // return {
          //   key: el.id,
          //   value: el.id,
          //   text: el.attributes.name,
          //   people,
          //   teams,
          //   // image: { avatar: true, src: the_image, size: 'tiny' },
          // };
          }
        }else{
 
 
          locations_options.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
          })
        
     } });

      setFetchingLocations(false);
    }

    let jobTitle_options = [];
    if (jobTitle.response) {
      jobTitle_options = jobTitle.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setFetchingJobTitle(false);
    }

    let countries_options = [];
    if (countries) {
      countries_options = countries.map((el) => {
        return {
          key: el?.isoCode?.toLowerCase(),
          value: el?.isoCode,
          text: el.name,
          flag: el?.isoCode?.toLowerCase(),
        };
      });
      setFetchingCountries(false);
    }

    setOptions({
      ...options,
      teams: teams.response?.length > 0 ? teams_options : [],
      users: users.data?.length > 0 ? users_options : [],
      locations: locations?.length > 0 ? locations_options : [],
      nationalities: countries?.length > 0 ? countries_options : [],
      jobTitle: jobTitle?.response.length > 0 ? jobTitle_options : [],
    });
  };
  const onFileChange = async (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    let file;

    if (name == 'personalPicture') {
      const personalPictureResponse = await uploadFile([files[0]]);

      if (personalPictureResponse?.status == 200) {
        file = personalPictureResponse.data[0].url;
        setPersonalImgId(personalPictureResponse.data[0].id);
      }
    }

    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
        url: file ? file : null,
      },
    });
  };
  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });
    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const boolean_fields = ['hasWorkPermit'];
    const dropdown_fields = [
      'locations',
      'martialStatus',
      'educationLevel',
      'countryCode',
      'emergencyCountryCode',
      'nationality',
      'gender',
      'supervisor',
      'role',
      'cob',
    ];

    // console.log(data.role)

    if (!boolean_fields.includes(name) || !dropdown_fields.includes(name))
      setData({
        ...data,
        [name]: event.target.value,
      });

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }
  };

  const validationHandler = () => {
    setValidForm(false);
    const shiftsArray = [
      data.shifts.shiftA,
      data.shifts.shiftB,
      data.shifts.shiftC,
    ];

    const daysArray = [
      data.days.sat,
      data.days.sun,
      data.days.mon,
      data.days.tue,
      data.days.wed,
      data.days.thu,
      data.days.fri,
    ];

    const pane0Errors = [
      validation.firstName.pattern(data.firstName),
      validation.lastName.pattern(data.lastName),
      validation.username.pattern(data.username),
      validation.email.pattern(data.email),
      validation.phone.pattern(data.phone),
      // validation.identificationNo.pattern(data.identificationNo),
      // validation.dob.pattern(data.dob),
    ];

    const pane1Errors = [
      validation.jobTitle.pattern(data.jobTitle),
      validation.hourRate.pattern(data.hourRate),
      validation.locations.pattern(data.locations),
      // validation.whpw.pattern(data.whpw),
      // validation.contractStart.pattern(data.contractStart),
      // validation.contractEnd.pattern(data.contractEnd),
      validation.days.pattern(daysArray),
      validation.shifts.pattern(shiftsArray),
    ];

    setErrorPane({
      ...errorPane,
      pane0: pane0Errors.includes(false),
      pane1: pane1Errors.includes(false),
    });

    setValidation({
      ...validation,
      // whpd: {
      //   ...validation.whpd,
      //   error: validation.whpd.pattern(data.whpd) ? false : true,
      //   skipped: true,
      // },
      // whpw: {
      //   ...validation.whpw,
      //   error: validation.whpw.pattern(data.whpw) ? false : true,
      //   skipped: true,
      // },

      // contractStart: {
      //   ...validation.contractStart,
      //   error: validation.contractStart.pattern(data.contractStart)
      //     ? false
      //     : true,
      //   skipped: true,
      // },
      // contractEnd: {
      //   ...validation.contractEnd,
      //   error: validation.contractEnd.pattern(data.contractEnd) ? false : true,
      //   skipped: true,
      // },

      firstName: {
        ...validation.firstName,
        error: validation.firstName.pattern(data.firstName) ? false : true,
        skipped: true,
      },
      lastName: {
        ...validation.lastName,
        error: validation.lastName.pattern(data.lastName) ? false : true,
        skipped: true,
      },
      username: {
        ...validation.username,
        error: validation.username.pattern(data.username) ? false : true,
        skipped: true,
      },
      email: {
        ...validation.email,
        error: validation.email.pattern(data.email) ? false : true,
        skipped: true,
      },

      phone: {
        ...validation.phone,
        error: validation.phone.pattern(data.phone) ? false : true,
        skipped: true,
      },
      locations: {
        ...validation.locations,
        error: validation.locations.pattern(data.locations) ? false : true,
        skipped: true,
      },

      // dob: {
      //   ...validation.dob,
      //   error: validation.dob.pattern(data.dob) ? false : true,
      //   skipped: true,
      // },

      jobTitle: {
        ...validation.jobTitle,
        error: validation.jobTitle.pattern(data.jobTitle) ? false : true,
        skipped: true,
      },
      // identificationNo: {
      //   ...validation.identificationNo,
      //   error: validation.identificationNo.pattern(data.identificationNo)
      //     ? false
      //     : true,
      //   skipped: true,
      // },

      shifts: {
        ...validation.shifts,
        error: validation.shifts.pattern(shiftsArray) ? false : true,
        skipped: true,
      },

      days: {
        ...validation.days,
        error: validation.days.pattern(daysArray) ? false : true,
        skipped: true,
      },

      // identificationNo: {
      //   ...validation.hourRate,
      //   error: validation.hourRate.pattern(data.hourRate) ? false : true,
      //   skipped: true,
      // },
    });

    return validForm;
  };

  // useEffect(() => {
  //   const validationToArray = Object.entries(validation);
  //   const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
  //   // console.log(validationToArray);

  //   if (initChange) {
  //     setValidForm(!cond);
  //     setErrorPane({
  //       ...errorPane,
  //       pane0: cond,
  //       pane1: cond,
  //     });
  //   }
  //   // console.log(data);
  // }, [validation, initChange]);

  // useEffect(() => {
  //   validationHandler();
  // }, [data]);

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const updateUserHandler = async (data) => {
    try {
      setButtonStatus(1);
      const contractCopyResponse =
        attachments.contractCopy && attachments.contractCopy.isChanged
          ? await uploadFile([attachments.contractCopy.file])
          : null;

      const personalPictureResponse =
        attachments.personalPicture && attachments.personalPicture.isChanged
          ? await uploadFile([attachments.personalPicture.file])
          : null;

      // console.log('personalPictureResponse', personalPictureResponse);

      const passportCopyResponse =
        attachments.passportCopy && attachments.passportCopy.isChanged
          ? await uploadFile([attachments.passportCopy.file])
          : null;
      const idFrontCopyResponse =
        attachments.idCopyFront && attachments.idCopyFront.isChanged
          ? await uploadFile([attachments.idCopyFront.file])
          : null;

      const visaCopyResponse =
        attachments.visaCopy && attachments.visaCopy.isChanged
          ? await uploadFile([attachments.visaCopy.file])
          : null;
      const covidCopyResponse =
        attachments.covidCopy && attachments.covidCopy.length
          ? await uploadFilessHandler(attachments.covidCopy)
          : null;

      let theAttachments =
        covidCopyResponse?.status == 200
          ? covidCopyResponse.data.map((el) => el.id)
          : [];
      let unchangedAttachments = attachments.covidCopy.filter(
        (el) => !el.isChanged
      );
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedCertificateFiles = theAttachments.concat(
        unchangedAttachments
      );

      const response = await updateUser(
        data.userId,
        data.role,
        data.firstName.trim() + ' ' + data.lastName.trim(),
        data.email ? data.email : null,
        data.teams ? data.teams : null,
        data.firstName.trim(),
        data.lastName.trim(),
        (data.role == 8 ||  data.role == 9)? data?.supervisor || null : null,
        data.locations ? data.locations : null,
        data.phone ? data.phone : null,
        personalImgId,
        organisationId,
        {
          id: data.profileId,
          phone: data.phone ? data.phone : null,
          address: data.address ? String(data.address) : null,
          emergencyName: data.emergencyName ? String(data.emergencyName) : null,
          emergencyPhone: data.emergencyPhone ? data.emergencyPhone : null,
          educationLevel: String(data.educationLevel) || null,
          fieldOfStudy: String(data.fieldOfStudy) || null,
          martialStatus: String(data.martialStatus) || null,
          nationality: String(data.nationality) || null,
          passportNo: String(data.passportNo) || null,
          identificationNo: String(data.identificationNo) || null,
          dob: data.dob ? format(new Date(data.dob), 'yyyy-MM-dd') : null,
          cob: data.cob ? String(data.cob) : null,
          gender: data.gender ? String(data.gender) : null,
          childrenNo: Number(data.childrenNo) || null,
          visaNo: String(data.visaNo) || null,
          visaExpireDate: data.visaExpireDate
            ? format(new Date(data.visaExpireDate), 'yyyy-MM-dd')
            : null,
          job: Number(data.jobTitle) || null,
          department: String(data.department) || null,
          hourRate: Number(data.hourRate) || null,
          whpd: Number(data.whpd) || null,
          whpw: Number(data.whpw) || null,
          contractStart: data.contractStart
            ? format(new Date(data.contractStart), 'yyyy-MM-dd')
            : null,
          contractEnd: data.contractEnd
            ? format(new Date(data.contractEnd), 'yyyy-MM-dd')
            : null,
          registrationNo: Number(data.registrationNo) || null,
          badgeId: String(data.badgeId) || null,
          shifts: data.shifts || null,
          workPermitNo: String(data.workPermitNo) || null,
          days: data.days ? data.days : null,
          contractCopy:
            contractCopyResponse?.status == 200
              ? contractCopyResponse.data[0].id
              : attachments?.contractCopy?.id
              ? attachments.contractCopy.id
              : null,
          idCopyFront:
            idFrontCopyResponse?.status == 200
              ? idFrontCopyResponse.data[0].id
              : attachments?.idCopyFront?.id
              ? attachments.idCopyFront.id
              : null,
          personalPicture: personalImgId ? personalImgId : null,
          passportCopy:
            passportCopyResponse?.status == 200
              ? passportCopyResponse.data[0].id
              : attachments?.passportCopy?.id
              ? attachments.passportCopy.id
              : null,
          visaCopy:
            visaCopyResponse?.status == 200
              ? visaCopyResponse.data[0].id
              : attachments?.visaCopy?.id
              ? attachments.visaCopy.id
              : null,
          covidCopy: toBeSendedCertificateFiles,
        }
      );

      if (response.status == 200) {
        // console.log(response.data);
        setResponseData({
          id: response.data.id,
          name: response.data.firstName,
        });

        setButtonStatus(0);
        cancelHandler();
        setSortData([]);
        setFilterOptions({
          role: [],
          supervisors: [],
          jobTitle: [],
          teams: [],
          status: [],
          joiningDate: {
            value: 'any_day',
            text: 'Any Date',
          },
          rate: {
            from: null,
            to: null,
          },

          contractEnd: {
            from: null,
            to: null,
          },
        });
        setSearchTitle('');
        // toggleSuccessModal(true);
        toast.success(t.people.title + ' ' + t.common.updated_success);

        // const roleUpdated = await updateUserRole(
        //   data.role,
        //   response.data.id,
        //   organisationId
        // );

        // if (roleUpdated.status == 200) {
        //   const pendingUser = await updateUserStatusToPending(response.data.id);

        //   if (pendingUser.status == 200) {
        // console.log('Data', data);
        // updateUserProfileHandler(
        //   data.profileId,

        //   contractCopyResponse?.status == 200
        //     ? contractCopyResponse.data[0].id
        //     : attachments?.contractCopy?.id ?attachments.contractCopy.id : null,
        //   personalImgId ? personalImgId : null,
        //   passportCopyResponse?.status == 200
        //     ? passportCopyResponse.data[0].id
        //     :  attachments?.passportCopy?.id ?attachments.passportCopy.id : null,
        //   idFrontCopyResponse?.status == 200
        //     ? idFrontCopyResponse.data[0].id
        //     : attachments?.idCopyFront?.id ?attachments.idCopyFront.id : null,
        //   visaCopyResponse?.status == 200
        //     ? visaCopyResponse.data[0].id
        //     :attachments?.visaCopy?.id ?attachments.visaCopy.id : null,
        //   toBeSendedCertificateFiles,
        //   data
        // );
        //   }
        // }
      }

      setButtonStatus(0);
    } catch (err) {
      if (
        err.message == 'This email is already exists' ||
        err.message == 'This phone number and email already exist' ||
        err.message == 'This phone is already exists'
      ) {
        // hasError = true;
        toast.error(
          t.people.form_validation_errors[err.message] || err.message
        );
      } else {
        toast.error(err.message);
      }
      setButtonStatus(0);
    }
  };

  const updateUserProfileHandler = async (
    id,
    contractCopy,
    personalPicture,
    passportCopy,
    idCopyFront,
    visaCopy,
    covidCopy,
    data
  ) => {
    const response = await updateUserProfile(
      id,
      data.phone ? data.phone : null,
      data.address ? String(data.address) : null,
      data.emergencyName ? String(data.emergencyName) : null,
      data.emergencyPhone ? data.emergencyPhone : null,
      String(data.educationLevel) || null,
      String(data.fieldOfStudy) || null,
      String(data.martialStatus) || null,
      String(data.nationality) || null,
      String(data.passportNo) || null,
      String(data.identificationNo) || null,
      data.dob ? format(new Date(data.dob), 'yyyy-MM-dd') : null,
      data.cob ? String(data.cob) : null,
      data.gender ? String(data.gender) : null,
      Number(data.childrenNo) || null,
      String(data.visaNo) || null,
      data.visaExpireDate
        ? format(new Date(data.visaExpireDate), 'yyyy-MM-dd')
        : null,
      Number(data.jobTitle) || null,
      String(data.department) || null,
      Number(data.hourRate) || null,
      Number(data.whpd) || null,
      Number(data.whpw) || null,
      data.contractStart
        ? format(new Date(data.contractStart), 'yyyy-MM-dd')
        : null,
      data.contractEnd
        ? format(new Date(data.contractEnd), 'yyyy-MM-dd')
        : null,
      Number(data.registrationNo) || null,
      String(data.badgeId) || null,
      data.shifts || null,
      String(data.workPermitNo) || null,
      data.days ? data.days : null,
      contractCopy,
      idCopyFront,
      personalPicture,
      passportCopy,
      visaCopy,
      covidCopy
    );

    if (response.status == 200) {
      setButtonStatus(0);
      cancelHandler();
      setSortData([]);
      setFilterOptions({
        role: [],
        supervisors: [],
        jobTitle: [],
        teams: [],
        status: [],
        joiningDate: {
          value: 'any_day',
          text: 'Any Date',
        },
        rate: {
          from: null,
          to: null,
        },

        contractEnd: {
          from: null,
          to: null,
        },
      });
      setSearchTitle('');
      // toggleSuccessModal(true);
      toast.success(t.people.title + ' ' + t.common.updated_success);
    }
  };

  const cancelHandler = () => {
    resetForm();
    // toggleAsideModalOpen(false);
    toggleCancelModal(false);
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setNewCancelModal(false);
    activateLoading(false);
    setInitChange(false);
    untoggle();
  };
  const changeTab = async (tab, errors, setFieldTouched, values) => {
    let errorFields = Object.keys(errors);
    let hasError = false;
    if (activePane > tab) {
      setActivePane(tab);
      return;
    }
    if (tab == 1) {
      if (errorFields && errorFields.length) {
        for (let i = 0; i < firstTabValidationFields.length; i++) {
          if (errorFields.includes(firstTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(firstTabValidationFields[i], true);
          }
        }
      }

      if (!hasError) {
        let validateEmailAndPhone = await verifyPersonEmailAndPhone(
          values.userId || null,
          values.email ? values.email : null,
          values.phone,
          organisationId,
          values.role
        );
        if (validateEmailAndPhone && validateEmailAndPhone.status != 200) {
          hasError = true;
          toast.error(
            t.people.form_validation_errors[validateEmailAndPhone.error] ||
              validateEmailAndPhone.error
          );
        }
      }
      // console.log(validateEmailAndPhone, 'validateEmailAndPhone');
    } else if (tab == 2) {
      if (errorFields && errorFields.length) {
        for (let i = 0; i < firstTabValidationFields.length; i++) {
          if (errorFields.includes(firstTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(firstTabValidationFields[i], true);
          }
        }
      }

      if (!hasError) {
        let validateEmailAndPhone = await verifyPersonEmailAndPhone(
          values.userId || null,
          values.email ? values.email : null,
          values.phone,
          organisationId,
          values.role
        );
        if (validateEmailAndPhone && validateEmailAndPhone.status != 200) {
          hasError = true;
          toast.error(
            t.people.form_validation_errors[validateEmailAndPhone.error] ||
              validateEmailAndPhone.error
          );
        }
      }
      if (errorFields && errorFields.length) {
        for (let i = 0; i < firstTabValidationFields.length; i++) {
          if (errorFields.includes(firstTabValidationFields[i])) {
            hasError = true;
            setFieldTouched(firstTabValidationFields[i], true);
          }
        }
      }
      for (let i = 0; i < firstTabValidationFields.length; i++) {
        if (errorFields.includes(firstTabValidationFields[i])) {
          hasError = true;
          setFieldTouched(firstTabValidationFields[i], true);
        }
      }
    }
    if (!hasError) {
      setActivePane(tab);
    }
  };

  const fetchLocations = async () => {
    let the_options = [];
    const fetchData = await getAllLocations(organisationId);
    if (fetchData) {
      fetchData.forEach((el) => {
        if ( !(roleData == "super_admin"|| roleData == "admin")) {
         if(allIds?.includes(el.id)){

           the_options.push({
             key: el.id,
             value: el.id,
             text: el.attributes.name,
        
           })
 
         // return {
         //   key: el.id,
         //   value: el.id,
         //   text: el.attributes.name,
         //   people,
         //   teams,
         //   // image: { avatar: true, src: the_image, size: 'tiny' },
         // };
         }
       }else{


         the_options.push({
           key: el.id,
           value: el.id,
           text: el.attributes.name,
         })
       
     
     } });
     setOptions((prev) => {
       return {
         ...prev,
         locations: the_options,
       };
     });
     return the_options;
   }
  };

  const fetchCountries = () => {
    const fetchData = Country.getAllCountries();
    let the_options = [];
    if (fetchData) {
      the_options = fetchData.map((el) => {
        return {
          key: el?.isoCode?.toLowerCase(),
          value: el.isoCode,
          text: el.name,
          flag: el?.isoCode?.toLowerCase(),
        };
      });

      setOptions({ ...options, nationalities: the_options });
    }
  };



  const fetchTeamsData = async () => {
    const fetchData = await getAllTeams(organisationId,'',1,99999,[],[],[],!(roleData == "super_admin"|| roleData == "admin") ? allIds : []);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions({
        ...options,
        teams: the_options,
      });
    }
  };

  const fetchJobTitleData = async () => {
    const fetchData = await getAllJobTitles(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          // value: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setOptions({
        ...options,
        jobTitle: the_options,
      });

      return the_options;
    }
  };

  // useEffect(() => {
  //   if ([10, 8].includes(data?.role)) {
  //     setData({
  //       ...data,
  //       username: data?.firstName + '_' + data?.phone,
  //       // email: data?.firstName + '_' + data?.phone + '@quiqflow.com',
  //     });
  //   }
  // }, [data?.firstName, data?.phone]);
  // console.log(data);

  // console.log(attachments);

  return (
    <>
      {/* <Modal
        size="tiny"
        open={toggled}
        onClose={() => {
          untoggle();
          cancelHandler();
        }}
      >
        <Modal.Content>
          <List>
            {isLoadingActive ? (
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            ) : (
              ''
            )}

            {options.roles.map((el) => {
              return (
                <Segment
                  key={el.key}
                  id={el.value}
                  className="flexbox"
                  style={
                    data.role == el.value
                      ? { background: '#e2f5fc', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => {
                    setInitChange(!initChange);
                    setData({
                      ...data,
                      role: el.value,
                    });
                  }}
                >
                  <Icon name={el.icon} size="big" color="black" />
                  <div style={{ marginLeft: '10px' }}>
                    <Header style={{ background: 'transparent' }}>
                      {el.text}
                    </Header>
                    <p>{el.description}</p>
                  </div>
                </Segment>
              );
            })}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!data.role}
            onClick={() => {
              activateLoading(true);
              setTimeout(() => {
                untoggle(false);
                toggleAsideModalOpen(true);
              }, 1000);
            }}
          >
            Next
          </Button>
        </Modal.Actions>
      </Modal> */}
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? 'aside-modal active procedure' : 'aside-modal procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.people.personal_info_form_edit.edit_person} #
                {targetedData?.countId }
              </div>
              <div>
                <span
                  className="c-pointer text-right"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>

          {/* Formik Component */}
          <PeopleForm
            setData={setData}
            setDropdownOptions={setOptions}
            PersonLimitedValidationSchema={PersonLimitedValidationSchema}
            setPersonalImgId={setPersonalImgId}
            data={data}
            PersonValidationSchema={
              // data?.role == 8
              //   ? PersonTechnicianValidationSchema
              //   : data?.role == 6
              //   ? PersonRequesterValidationSchema
              // :
              PersonValidationSchema
            }
            PersonTechnicianValidationSchema={
              PersonTechnicianValidationSchema
            }
            organisationId={organisationId}
            
            PersonRequesterValidationSchema={PersonRequesterValidationSchema}
            handleSubmit={updateUserHandler}
            activePane={activePane}
            changeTab={changeTab}
            dropdownOptions={options}
            fetchCountries={fetchCountries}
            fetchJobTitleData={fetchJobTitleData}
            // fetchTeamsData={fetchTeamsData}
            // fetchUsersData={fetchUsersData}
            fetchLocations={fetchLocations}
            cancelHandler={cancelHandler}
            toggleCancelModal={toggleCancelModal}
            setNewCancelModal={setNewCancelModal}
            onFileChange={onFileChange}
            initChange={initChange}
            buttonStatus={buttonStatus}
            attachments={attachments}
            setAttachments={setAttachments}
            onCertificateFileChange={onCertificateFileChange}
            edit={true}
          />
        </Resizable>
      </div>

      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
          setRefresh(!refresh);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              <b>{responseData.name}</b> {t.common.updated_success}!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal> */}

      {/* <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(true)}
      >
        <Modal.Content className="cancel-text">
          <p>{t.common.cancel_statement}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            {t.common.keep}
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            {t.common.yes_cancel}
          </Button>
        </Modal.Actions>
      </Modal> */}

<NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
             
             <p style={{lineHeight:"24px"}}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

    </>
  );
}

export default Edit;
