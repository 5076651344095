import React, { useState, useEffect, useRef } from 'react';
import {
  Icon,
  Menu,
  Button,
  Tab,
  Loader,
  Dimmer,
  Popup,
  Label,
  Modal,
  Form,
  TextArea,
  Message,
  Header,
} from 'semantic-ui-react';
import moment from 'moment';

import {
  getUserProfileByID,
  declineUser,
  approveUser,
  getUsersByID,
  deleteUser,
} from '../../../config/functions';

import Work from './work';
import Personal from './personal';
import Security from './security';
import Attachments from './attachments';
import HR from './hr';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import { useDeletePermissionModal } from '../../../../hooks/Messages/useDeletePermissionModal';
import { toast } from 'react-toastify';
import { selectTranslations } from '../../../config/i18n/slice';
import { useSelector } from 'react-redux';
import {
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  FileDownloadIcon,
  ShareIcon,
} from '../../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import Logs from './log';
import { Resizable } from 're-resizable';

function Preview({
  toggled,
  untoggle,
  targetedData,
  edit,
  refresh,
  setRefresh,
  setFilterOptions,
  setSearchTitle,
  setSortData,
  setModalWidth,
  modalWidth,
  setTogglePreviw,
}) {
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  let panes;
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.type) {
      return state?.auth?.user?.role?.type;
    } else {
      return '';
    }
  });
  const userId = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.id;
    } else {
      return null;
    }
  });

  // console.log(targetedData.role.type,"Added")

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);

  const [fetching, setFetchLoading] = useState(true);
  const [data, setData] = useState(null);

  const [attachmentData, setAttachmentData] = useState(null);

  const [declinedModal, setDeclinedModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [declineReason, setDeclineReason] = useState('');

  const [declinedButton, setDeclindButton] = useState(0);

  const [approveButton, setApproveButton] = useState(0);

  const [statusUpdated, setStatusUpdated] = useState(false);

  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const t = useSelector(selectTranslations);

  useEffect(() => {
    setFetchLoading(true);

    const fetchData = async () => {
      const res = await getUsersByID(targetedData.id);
      if (res.status == 200) {
        setData(res.data);
        setFetchLoading(false);
      }

      if (res?.data?.userProfile) {
        setFetchLoading(false);
        const fetchData = await getUserProfileByID(targetedData.userProfile.id);

        if (fetchData) {
          setAttachmentData({
            contractCopy: fetchData.data.data.attributes.contractCopy?.data,
            personalPicture:
              fetchData.data.data.attributes.personalPicture?.data,
            passportCopy: fetchData.data.data.attributes.passportCopy?.data,
            idCopyFront: fetchData.data.data.attributes.idCopyFront?.data,
            visaCopy: fetchData.data.data.attributes.visaCopy?.data,
            covidCopy: fetchData.data.data.attributes.covidCopy?.data,
          });
          setFetchLoading(false);
        } else {
          setFetchLoading(false);
        }
      }
    };
    if (targetedData) fetchData();
  }, [targetedData, statusUpdated]);

  // console.log('mapped', data);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      let execlude_ids = ['popupTrigger'];
      // console.log(e.target.id);
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,

      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,

      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,

      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,

      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      description: t.people.add_modal.requester_desc,
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];
  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.people.table.name, key: 'name' },
    { label: t.customers.table.phone, key: 'mobile' },
    { label: t.jobTitle.add, key: 'jobtitle' },
    { label: t.people.table.role, key: 'role' },
    { label: t.people.table.teams, key: 'teams' },
    { label: t.people.table.supervisor, key: 'supervisor' },
    { label: t.people.table.hourly_rate, key: 'hourly_rate' },
    { label: t.people.table.status, key: 'status' },
    { label: t.people.table.contract_expiry_date, key: 'contract_expiry_date' },
  ];

  const downloadXLS = () => {
    let csvData = [];

    let people_data = [];

    people_data.id = data.id;
    people_data.name =
      data?.firstName && data?.lastName
        ? data.firstName + data?.lastName
        : t.common.na;

    people_data.mobile = data?.userProfile?.phone
      ? `+${data?.userProfile?.phone}`
      : t.common.na;

    people_data.jobtitle = data?.userProfile?.job?.title
      ? data?.userProfile?.job.title
      : t.common.na;

    let roleData = roles.find((each) => each.value == data.role.id);

    people_data.role = data?.role.id
      ? roleData?.text || data?.role?.type
      : t.common.na;

    let assigneePeople = data.teams;
    let assignString = '';

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople.map((people) => people?.name).join(', ');
    } else {
      assignString = t.common.na;
    }
    people_data.teams = assignString;

    people_data.supervisor = data?.supervisor?.firstName
      ? data?.supervisor?.firstName + ' ' + data?.supervisor?.lastName
      : t.common.na;

    people_data.hourRate = data.userProfile?.hourRate
      ? `${data.userProfile?.hourRate} ${organisationCurrency}`
      : t.common.na;
    people_data.status =
      data.confirmed && !data.blocked
        ? t.assets.active
        : data.blocked
        ? t.assets.inActive
        : t.people.status_options.status_pending;

    people_data.contract_expiry_date = data?.userProfile?.contractEnd
      ? moment(data?.userProfile?.contractEnd).format('DD.MM.YYYY')
      : t.common.na;

    csvData.push([
      people_data.id,
      people_data.name,
      people_data.mobile,
      people_data.jobtitle,

      people_data.role,
      people_data.teams,
      people_data.supervisor,
      people_data.hourRate,
      people_data.status,

      people_data.contract_expiry_date,
    ]);

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 30 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 15 }, // Updated At column
      { wch: 15 }, // Updated At column

      { wch: 25 }, // Description column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      data?.firstName || 'Single-People'
    );
  };

  const generatePDF = () => {
    let csvData = [];

    let people_data = [];

    people_data.id = data.id;
    people_data.name =
      data?.firstName && data?.lastName
        ? data.firstName + data?.lastName
        : t.common.na;

    people_data.mobile = data?.userProfile?.phone
      ? `+${data?.userProfile?.phone}`
      : t.common.na;

    people_data.jobtitle = data?.userProfile?.job?.title
      ? data?.userProfile?.job.title
      : t.common.na;

    let roleData = roles.find((each) => each.value == data.role.id);

    people_data.role = data?.role.id
      ? roleData?.text || data?.role?.type
      : t.common.na;

    let assigneePeople = data.teams;
    let assignString = '';

    if (assigneePeople?.length > 0) {
      assignString = assigneePeople.map((people) => people?.name).join(', ');
    } else {
      assignString = t.common.na;
    }
    people_data.teams = assignString;

    people_data.supervisor = data?.supervisor?.firstName
      ? data?.supervisor?.firstName + ' ' + data?.supervisor?.lastName
      : t.common.na;

    people_data.hourRate = data.userProfile?.hourRate
      ? `${data.userProfile?.hourRate} ${organisationCurrency}`
      : t.common.na;
    people_data.status =
      data.confirmed && !data.blocked
        ? t.assets.active
        : data.blocked
        ? t.assets.inActive
        : t.people.status_options.status_pending;

    people_data.contract_expiry_date = data?.userProfile?.contractEnd
      ? moment(data?.userProfile?.contractEnd).format('DD.MM.YYYY')
      : t.common.na;

    csvData.push([
      people_data.id,
      people_data.name,
      people_data.mobile,
      people_data.jobtitle,

      people_data.role,
      people_data.teams,
      people_data.supervisor,
      people_data.hourRate,
      people_data.status,

      people_data.contract_expiry_date,
    ]);
    const columnWidths = [, , , , , , , 20, , , , ,];

    // const columnWidths = [
    //   10,
    //   20,
    //   ,
    //   20,
    //   50,
    //   20,
    //   22,
    //   15,
    //   20,
    //   ,
    //   ,
    //   25,
    //   ,
    //   ,
    //   ,
    //   45,
    //   20,
    // ];
    DownloadPdfFileWithData(headers, csvData, t.people.add, [], 'A3');
  };

  panes = [
    {
      menuItem: t.people.personal_info_form.work_info,
      pane: {
        key: 'work',
        content: (
          <div className="pane-body">
            <Work
              targetedData={data}
              supervisorData={targetedData?.supervisor}
              attachmentData={attachmentData}
              createdByUserData={targetedData?.createdByUser}
              updatedByUserData={targetedData?.updatedByUser}
            />
          </div>
        ),
      },
    },

    {
      menuItem: t.people.personal_info_form.personal_info,
      pane: {
        key: 'personal',
        content: (
          <div className="pane-body">
            <Personal
              targetedData={data}
              setTargetData={setData}
              untoggle={untoggle}
              setRefresh={setRefresh}
              refresh={refresh}
              attachmentData={attachmentData}
              createdByUserData={targetedData?.createdByUser}
              updatedByUserData={targetedData?.updatedByUser}
              setFilterOptions={setFilterOptions}
              setSearchTitle={setSearchTitle}
              setSortData={setSortData}
              setTogglePreviw={setTogglePreviw}
            />
          </div>
        ),
      },
    },
    {
      menuItem: t.people.attachments_form.attachment_title,
      pane: {
        key: 'attachments',
        content: (
          <div className="pane-body">
            <Attachments targetedData={data} />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Logs targetedData={data} />
    //       </div>
    //     ),
    //   },
    // },
  ];
  if ((userRole == 'super_admin' || userRole == 'admin') && data?.role?.id !== 6) {
    panes = [
      {
        menuItem: t.people.personal_info_form.work_info,
        pane: {
          key: 'work',
          content: (
            <div className="pane-body">
              <Work
                targetedData={data}
                supervisorData={targetedData?.supervisor}
                attachmentData={attachmentData}
                createdByUserData={targetedData?.createdByUser}
                updatedByUserData={targetedData?.updatedByUser}
              />
            </div>
          ),
        },
      },

      {
        menuItem: t.people.personal_info_form.personal_info,
        pane: {
          key: 'personal',
          content: (
            <div className="pane-body">
              <Personal
                targetedData={data}
                setTargetData={setData}
                untoggle={untoggle}
                setRefresh={setRefresh}
                refresh={refresh}
                attachmentData={attachmentData}
                createdByUserData={targetedData?.createdByUser}
                updatedByUserData={targetedData?.updatedByUser}
                setFilterOptions={setFilterOptions}
                setSearchTitle={setSearchTitle}
                setSortData={setSortData}
                setTogglePreviw={setTogglePreviw}
              />
            </div>
          ),
        },
      },
      {
        menuItem: t.people.attachments_form.attachment_title,
        pane: {
          key: 'attachments',
          content: (
            <div className="pane-body">
              <Attachments targetedData={data} />
            </div>
          ),
        },
      },
      // {
      //   menuItem: t.people.security,
      //   pane: {
      //     key: 'security',
      //     content: (
      //       <div className="pane-body">
      //         <Security
      //           targetedData={data}
      //           setTargetData={setData}
      //           setStatusUpdated={setStatusUpdated}
      //           statusUpdated={statusUpdated}
      //         />
      //       </div>
      //     ),
      //   },
      // },
      // {
      //   menuItem: t.workOrders.details_view.log,
      //   pane: {
      //     key: 'log',
      //     content: (
      //       <div className="pane-body">
      //         <Logs targetedData={data} />
      //       </div>
      //     ),
      //   },
      // },
    ];
  }

  const declineUserHandler = async () => {
    setDeclindButton(1);
    const res = await declineUser(targetedData.id, declineReason);

    if (res.status == 200) {
      setDeclindButton(0);

      setTimeout(() => {
        setDeclinedModal(false);
        setStatusUpdated(!statusUpdated);
        setDeclineReason('');
      }, 500);
    }
  };

  const approveUserHandler = async () => {
    setApproveButton(1);
    const res = await approveUser(targetedData.id, declineReason);

    if (res.status == 200) {
      setApproveButton(0);
      setStatusUpdated(!statusUpdated);
    }
  };

  const confirmDeclineUser = () => {
    if (data?.confirmed) {
      return (
        <Label size="medium" basic color="green" style={{ height: 'auto' }}>
          <Icon name="check" color="green" />{' '}
          {t.people.personal_info_form_edit.approved}
        </Label>
      );
    }

    if (data?.declined && !data?.confirmed) {
      return (
        <Label size="medium" basic color="red" style={{ height: 'auto' }}>
          <Icon name="dont" color="red" />{' '}
          {t.people.personal_info_form_edit.declined}
        </Label>
      );
    }

    if (!data?.declined && !data?.confirmed) {
      return (
        <>
          <Button
            color="green"
            onClick={approveUserHandler}
            loading={approveButton > 0}
          >
            {t.people.personal_info_form_edit.approve_btn}
          </Button>
          <Button negative onClick={() => setDeclinedModal(true)}>
            {t.people.personal_info_form_edit.decline_btn}
          </Button>
        </>
      );
    }
  };
  const toggleDeleteSuccessModal = (data) => {
    setDeleteSuccessModal(!DeleteSuccessModal);
    if (data) {
      untoggle();
    }
  };

  const deleteHandler = async () => {
    const response = await deleteUser(targetedData.id);

    // console.log(response);

    if (response.status == 200) {
      setDeleteErrorMessage(false);
      setSortData([]);
      setFilterOptions({
        role: [],
        supervisors: [],
        jobTitle: [],
        teams: [],
        status: [],
        joiningDate: {
          value: 'any_day',
          text: 'Any Date',
        },
        rate: {
          from: null,
          to: null,
        },

        contractEnd: {
          from: null,
          to: null,
        },
      });
      setSearchTitle('');
      toast.success(t.people.security_form.preson_deleted);
      toggleDeleteSuccessModal('close');
    } else if (response.status == 400) {
      setDeleteErrorMessage(true);
      toast.error(t.people.security_form.person_not_deleted);
      toggleDeleteSuccessModal();

      // toast.error(
      //   'Asset cannot be deleted as it is connected with other Work orders and procedures.'
      // );
    } else {
      toast.error(t.common.something_wrong);
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled ? 'aside-modal preview active procedure' : 'aside-modal preview procedure'
        }
      >
<Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >

        {data?.blocked ? (
          <div
            className="alert"
            style={{ marginBottom: '18px', marginTop: '18px' }}
          >
            {t.people.personal_info_form_edit.user_blocked}
          </div>
        ) : (
          ''
        )}
        <div className="modal-header detail-header">
          <div className="flexbox justify-between align-center">
            <div className="name">
              {t.people.title} #{data?.countId }
            </div>

            <div className="flexbox align-center workorder-header-buttons">
              <span
                className="header-button  c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowPdfTooltip(true)}
                onMouseLeave={() => setShowPdfTooltip(false)}
                onClick={() => generatePDF()}
              >
                <DownloadIconBucket />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showPdfTooltip}
                  textBoxWidth="60px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_pdf}
                  </span>
                </ToolTip>
              </span>
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </ToolTip>
              </span> */}

              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  arrowAlign="center"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </ToolTip>
              </span> */}


              {(userRole == 'admin' || userRole == 'limited_admin') &&
              targetedData?.role?.type ===
                'super_admin' ? null :( userRole == 'limited_admin' && targetedData?.role?.type === 'admin') ? null :userPermissions?.people?.edit ||
                checkForPermission(targetedData?.id) ||
                (targetedData?.role?.type === 'super_admin' &&
                  userRole == 'super_admin') ? (
                <span
                  className="header-button c-pointer mt-1"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setEditTooltip(true)}
                  onMouseLeave={() => setEditTooltip(false)}
                  onClick={() => {
                    data?.userProfile?.id
                      ? edit(data?.userProfile?.id)
                      : toast.error("User Profile doesn't exists");
                    setPopupIsOpen(false);
                  }}
                >
                  <EditTextIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showEditTooltip}
                    textBoxWidth="33px"
                    arrowAlign="center"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.edit}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              {targetedData?.role?.type === 'super_admin' ? null :(userRole =='super_admin' && targetedData?.role?.id != userId && targetedData?.role?.type !== 'super_admin') || (userPermissions?.people?.delete && ( targetedData?.role?.type != 'admin'  || targetedData?.role?.type !== 'super_admin') && targetedData?.role?.id != userId)
              // checkForPermission(
              //   targetedData?.attributes?.createdByUser?.data?.id
              // ) 
              ? (
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    setDeleteModal(true);
                    setPopupIsOpen(false);
                    setDeletePermissionModal(true);
                  }}
                >
                  <DeleteIcon />
                   <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                    arrowAlign="center"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              <span
                className={`procedure-header-button c-pointer ${
                  selectedLanguage == 'en' ? 'ml-1' : 'mr-3'
                }`}
                style={{ position: 'relative' }}
                onMouseOver={() => setCancleTooltip(true)}
                onMouseLeave={() => setCancleTooltip(false)}
                onClick={() => {
                  untoggle();
                }}
              >
                <CloseIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={
                    selectedLanguage == 'en' ? 'bottom center' : 'bottom center'
                  }
                  arrowAlign="center"
                  show={showCancleTooltip}
                  textBoxWidth="36px"
                  padding="5px 5px 5px 7px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.close}
                  </span>
                </ToolTip>
              </span>
            </div>

            {/* {userPermissions['people']?.edit ||
            userPermissions['people']?.delete ? (
              <div className="flexbox align-center">

                <Popup
                  trigger={
                    <Icon
                      name="ellipsis vertical"
                      size="large"
                      title="More"
                      color="black"
                      onClick={() => setPopupIsOpen(true)}
                      id="popupTrigger"
                    />
                  }
                  ref={popUpRef}
                  open={popupIsOpen}
                  on="click"
                  content={
                    <Menu secondary vertical>
                      {userPermissions['people']?.edit ? (
                        <Menu.Item
                          name={t.common.edit}
                          onClick={() => {
                            if (data.userProfile) {
                              edit(data.userProfile.id);
                              setPopupIsOpen(false);
                            }
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      ) : null}

                    
                      {userPermissions['people']?.delete ? (
                        <Menu.Item
                          name={t.common.delete}
                          onClick={() => {
                            setDeleteModal(true);
                            setPopupIsOpen(false);
                          }}
                          style={{ color: '#ff3835', cursor: 'pointer' }}
                        />
                      ) : null}
                    </Menu>
                  }
                  position="bottom right"
                />
              </div>
            ) : null} */}
          </div>
        </div>
        <div className="body">
          {data?.declined && data?.declinedReason.length > 0 ? (
            <Message
              negative
              attached="bottom"
              style={{ margin: '0 25px 10px' }}
            >
              <Icon name="dont" color="red" />
              {t.people.security_form.decline_reason}:{' '}
              <b>{data?.declinedReason}</b>
            </Message>
          ) : (
            ''
          )}
          {data ? (
            <Tab
              panes={panes}
              style={{ paddingBottom: '16px' }}
              className={'location-tab'}
              renderActiveOnly={false}
            />
          ) : (
      
                <Loader active inline="centered">{t.common.loading}</Loader>
    
          )}
        </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => {
          setData();
          untoggle();
        }}
      ></div>

      <Modal
        size="tiny"
        open={declinedModal}
        onClose={() => setDeclinedModal(false)}
      >
        <Modal.Content>
          <Form style={{ marginBottom: '20px' }}>
            <Form.Field>
              <label className="label-item">
                {t.people.security_form.decline_reason_spacify}
              </label>
              <TextArea
                autoComplete="new-password"
                value={declineReason}
                onChange={(e) => setDeclineReason(e.target.value)}
                rows={2}
                fluid
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setDeclinedModal(false)}>
            {t.common.cancel}
          </Button>
          <Button
            negative
            onClick={declineUserHandler}
            loading={declinedButton > 0}
          >
            {t.people.personal_info_form_edit.decline_btn}
          </Button>
        </Modal.Actions>
      </Modal>

      {/* <Modal
        size="tiny"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper red">
              <Icon name="close" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              {t.people.personal_info_form_edit.delete_person_statement}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setDeleteModal(false);
              deleteHandler();
            }}
          >
            {t.common.delete}
          </Button>
          <Button color="black" onClick={() => setDeleteModal(false)}>
            {t.common.keep}
          </Button>
        </Modal.Actions>
      </Modal> */}

      <DeletePermissionModal
        yesCallback={() => {
          deleteHandler();
        }}
        title={t.people.title}
      >
        <span>{t.people.personal_info_form_edit.delete_person_statement}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
