import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAllParts, getAllWorkOrders } from '../../app/config/functions';
const initialState = {
  partsData: [],
  allpartsData: [],
  isLoading: false,
  initLoading: false,
  error: null,
  partsInitData: [],
  page: 1,
  pageSize: 25,
  total: 25,
};

/*
 * Fetch parts initial data
 */
export const fetchPartsInitData = createAsyncThunk(
  'parts/initData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllParts(organisationId, '', 1, 25,[],[],[],[],[],[],[],[],'',[],role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch all Parts data
 */
export const fetchAllPartsData = createAsyncThunk(
  'parts/allData',
  async ({organisationId,role,allIds}, { dispatch, rejectWithValue }) => {
    try {
      const data = await getAllParts(organisationId, '', 1, 99999,[],[],[],[],[],[],[],[],'',[],role,allIds);

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };

        return dataObj;
        // dispatch(setData(data.response));
        // dispatch(setInitData(data.response));
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

/*
 * Fetch filter parts data
 */
export const fetchPartsWithFilter = createAsyncThunk(
  'parts/filter',
  async (
    {
      organisationId,
      searchName,
      page,
      pageSize,
      partType = [],
      status = [],
      assets = [],
      vendors = [],
      locations = [],
      area = [],
      teams = [],
      category = [],
      serialNumber='',
      sort = [],
      role='',
      allIds=[],
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await getAllParts(
        organisationId,
        searchName,
        page,
        pageSize,
        partType,
        status,
        assets,
        vendors,
        locations,
        area,
        teams,
        category,
        serialNumber,
        sort,
        role,
        allIds,
      );

      if (data.status == 200) {
        const dataObj = {
          response: data.response,
          pagination: data.pagination,
        };
        return dataObj;
      } else if (data.error) {
        return rejectWithValue(data.error.message);
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const partsSlice = createSlice({
  name: 'parts',
  initialState,

  extraReducers: (builder) => {
    builder

      .addCase(fetchPartsInitData.pending, (state) => {
        state.initLoading = true;
        state.error = null;
      })
      .addCase(fetchPartsInitData.fulfilled, (state, action) => {
        state.initLoading = false;
        state.partsInitData = action.payload.response;
        state.error = null;
      })
      .addCase(fetchPartsInitData.rejected, (state, action) => {
        state.initLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchPartsWithFilter.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchPartsWithFilter.fulfilled, (state, action) => {
        state.isLoading = false;
        state.partsData = action.payload.response;
        state.allpartsData = action.payload.response;
        state.page = action.payload.pagination.page;
        state.pageSize = action.payload.pagination.pageSize;
        state.total = action.payload.pagination.total;
        state.error = null;
      })
      .addCase(fetchPartsWithFilter.rejected, (state, action) => {
        state.isLoading = false;
      })

      .addCase(fetchAllPartsData.pending, (state) => {})
      .addCase(fetchAllPartsData.fulfilled, (state, action) => {
        state.allpartsData = action.payload.response;
      })
      .addCase(fetchAllPartsData.rejected, (state, action) => {});
  },
});

export default partsSlice.reducer;
