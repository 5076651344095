export default {
  title: 'My Procedures',
  title2: 'Global Library',
  tabs: {
    first: 'My Procedures',
    second: 'Global Library',
  },
  add: 'Procedure',
  procedure: 'Procedure',
  filter: {
    search: 'Search',
  },

  form: {
    form_title: {
      add: 'Add Procedure',
      edit: 'Edit Procedure',
    },
    procedure_title: 'Procedure Title',
    add_procedure_title: 'Add procedure title',
    description: 'Description',
    add_description: 'Add description',
    procedure_items: 'Procedure Items',
    title: 'Title',
    title_placeholder: 'Enter Title',
    type: 'Type',
    type_options: [
      'Checkbox',
      'Text',
      'Number',
      'Amount',
      'Multiple Choice',
      'Checklist',
      'Inspection Check',
      'Signature',
      'Date',
      'File',
      'Photo',
    ],
    checkbox_label: 'Checkbox Label',
    text_placeholder: 'Assignees will add a text here',
    number_placeholder: 'Assignees will add a number here',
    wo_placeholder: 'Assignees will add a WO',
    amount_placeholder: 'Assignees will add an amount here',  
    signature_placeholder: 'Assignees will add a signature here',
    file_placeholder: 'Assignees will add a File here',
    photo_placeholder: 'Assignees will add a Photo here',
    date_placeholder: 'Assignees will add a date here',
    options: 'Options',
    option: 'Option',
    add_option: 'Add Option',
    inspection_type: 'Inspection Type',
    inspection_types: [
      ['Yes', 'No', 'N/A'],
      ['Good', 'Fair', 'Poor', 'N/A'],
      ['Pass', 'Fail', 'N/A'],
      ['Safe', 'Risk', 'N/A'],
      ['Compliant', 'Non-Compliant', 'N/A'],
      ['Scale To Five (0 - 5)'],
    ],
    inspection_type_placeholder: 'Choose Inspection Type',
    failed_value: 'Failed Value',
    failed_value_placeholder: 'Choose Failed Value',
    add_logic: 'Add Logic',
    if_answer_is: 'If answer is',
    if_answer_is_placeholder: 'Select Answer',
    then: 'then',
    then_options: ['Required WO', 'Required Evidence'],
    then_placeholder: 'Select trigger',
    required_evidence: 'Required Evidence',
    required_action: 'Required WO',
    submit: {
      add: 'Submit',
      edit: 'Update',
    },
    cancel_button: 'Cancel',
    success: {
      msg: 'Procedure added successfully',
      ok: 'Ok',
    },
    cancel: {
      msg: 'Are you sure you want to cancel?',
      cancel: 'Yes, cancel',
      keep: 'Keep',
    },
    is_required: 'Is Required',
    remove_button: 'Remove',
    add_procedure_item: 'Procedure item',
    header: 'Header',
    add_heading: 'Heading',
    heading: 'Heading',
    clone_template: 'Clone Template',
    select_local_statement: 'Select from Local Library',
    add: 'Add',
    update_success_statement: 'Procedure updated successfully.',
    delete_procedure_statement:
      'Are you sure you want to delete this procedure?',
    delete_success_statement: 'Procedure deleted successfully',
    delete_connected_error_statement: 'Unable to delete procedure. Linked with other forms',
  },
  table: {
    title: 'Title',
    ID: 'ID',
    description: 'Description',
    fields: 'Fields',
    fields_small: ' fields',
    createdByUser: 'Created By',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
  },
  details_view: {
    category: 'Category',
    add_category: 'Select category',
    all: 'All',
    created_by: 'Created by',
    parts: 'Parts Inventory',
    assets: 'Assets',
    locations: 'Locations',
    procedure_items: 'Procedure Items',
  },
  procedure_category: {
    Damage: 'Damage',
    Electrical: 'Electrical',
    Inspection: 'Inspection',
    Mechanical: 'Mechanical',
    Preventive: 'Preventive',
    Project: 'Project',
    Refrigeration: 'Refrigeration',
    Safety: 'Safety',
    Sop: 'Standard Operating Procedure',
    sop_short: 'SOP',
  },
  inspectionTypes: {
    yes: 'Yes',
    no: 'No',
    'n/a': 'N/A',
    good: 'Good',
    fair: 'Fair',
    poor: 'Poor',
    pass: 'Pass',
    fail: 'Fail',
    safe: 'Safe',
    risk: 'Risk',
    compliant: 'Compliant',
    'non-compliant': 'Non-Compliant',
    'scale to five (0 - 5)': 'Scale To Five (0 - 5)',
  },
  global:"Global View",
  item: ' item',
  no_procedure_available: "Looks like you don't have Procedures yet.",
  press_to_add_procedure: 'Press the ' + ' button to add your first Procedure.',
  procedures: 'Procedures',
  procedures_small: 'procedures',
  create_procedure: 'Add Procedure',
  add_procedure: 'Start adding procedure',
  procedure_library: 'procedure library',
  how_to_add_procedure: 'how to add procedure',
  answer: 'If answer is',
  logic: 'Logic',
  duplicate:"Duplicate",
  add_type:"Add type",
  length_valid:"Please add at least one procedure item.",
  refer:"Refer to action here"
};
