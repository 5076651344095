import React, { useEffect, useState, forwardRef, useRef } from 'react';
import _ from 'lodash';
import { DropdownMenu, Image, Label } from 'semantic-ui-react';
import { DownChevron } from '../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { humanize } from '../../config/functions';
import { useSelector } from 'react-redux';

function withClickOutside(WrappedComponent) {
  const Component = (props) => {
    const [open, setOpen] = useState(false);

    const ref = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref?.current && !ref?.current?.contains(event.target)) {
          setOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
      <WrappedComponent
        open={open}
        setOpen={setOpen}
        ref={ref}
        options={props.options || []}
        selectedValues={props.selectedValues || []}
        onChange={props.onChange}
        title={props.title}
        icon={props.icon}
        image={props.image}
        {...props}
        pill={props.pill}
        label={props.label}
        PO={props.PO}
      />
    );
  };

  return Component;
}

const PriorityDropdown = forwardRef(
  (
    {
      open,
      setOpen,
      options = [],
      selectedValues = [],
      onChange = () => {},
      title = '',
      icon = true,
            heading = '',
      image = false,
      pill = true,
      label = false,
      PO = false,
    },
    ref
  ) => {

    const selectedLanguage = useSelector((state) => {
      if (state?.i18n?.lang) {
        return state.i18n.lang;
      } else {
        return 'en';
      }
    });
    return (
      <>
        <div  ref={ref} className={`Category-dropdown priority ${PO ? "purchase-order" : ""}`}>
        {heading ? <div className="title-text mb-1">{heading}</div> : null}
          <UncontrolledDropdown
            className={`${open && 'active'} ${selectedValues.length > 0 ? 'blue-border' : ''}`}
            direction="down"
          >
            <DropdownToggle onClick={() => setOpen(!open)} color="primary">
              {title} <span className={selectedValues?.length > 0 && selectedValues[0] !== "" ?'filter-count-div' : ""}>{selectedValues?.length > 0 && selectedValues[0] !== "" ? selectedValues?.length  : null}</span> <DownChevron />
            </DropdownToggle>
            <DropdownMenu>
              {options.map((one) => {
               
                if (one.value) {
                  return (
                    <label
                      tag="label"
                      className={`custom-checkbox single-select item ${
                        selectedValues.length
                          ? selectedValues.includes(one.value)
                            ? 'selected'
                            : ''
                          : one.value === ''
                          ? 'selected'
                          : ''
                      }`}
                      key={one.value}
                      onClick={(e) => {
                        onChange({
                          ...e,
                          target: {
                            ...e.target,
                            checked: !selectedValues.includes(one.value),
                            value: one.value,
                          },
                        });
                      }}
                    >
                      {pill ? (
                        <span className={`pill ${one?.value}`}>{`${humanize(
                          one?.text
                        )}`}</span>
                      ) : (
                        <div className='d-flex'>
                        {label ? 
                          <Label
                            circular
                            color={one?.color}
                            empty
                            size="medium" // Change the size to "big" to make it larger
                            style={selectedLanguage == "en" ? { width: '12px', height: '12px',marginTop:"3px",borderRadius: '500rem', marginRight: '5px' }: { width: '12px', height: '12px',marginTop:"3px",borderRadius: '500rem', marginLeft: '5px' }}
                            title="Medium"
                          /> 
                          : null
                        }
                        <span>{`${humanize(one?.text)}`}</span>
                      </div>
                      )}
                    </label>
                  );
                } else {
                  return (
                    <label
                      tag="label"
                      className={`custom-checkbox single-select item ${
                        selectedValues.length
                          ? selectedValues.includes(one.value)
                            ? 'selected'
                            : ''
                          : one.value === ''
                          ? 'selected'
                          : ''
                      }`}
                      key={one.value}
                      onClick={(e) => {
                        onChange({
                          ...e,
                          target: {
                            ...e.target,
                            checked: !selectedValues.includes(one.value),
                            value: one.value,
                          },
                        });
                      }}
                    >
                      {/* <div className="checkbox-item">
                        <input
                          type="checkbox"
                          value={one.value}
                          checked={
                            selectedValues.length
                              ? selectedValues.includes(one.value)
                              : one.value === ''
                          }
                          onChange={onChange}
                          hidden
                        />
                        <span class="checkmark"></span>
                      </div> */}

                      <span className={`all_option_dropdown_text`}>{`${humanize(
                        one?.text
                      )}`}</span>
                    </label>
                  );
                }
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </>
    );
  }
);
export default withClickOutside(PriorityDropdown);
