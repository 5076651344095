import React, { useState } from "react";
import WorkOrderSearchDropdown from "../../../components/WorkOrder/WorkOrderSearchDropdown";
import DateDropdown from "../../../components/common/DateDropdown";
import { selectTranslations } from "../../../config/i18n/slice";
import { useSelector } from "react-redux";
import CategoryDropdown from "../../../components/common/CategoryDropdown";
import { useEffect } from "react";
import calendar_icon from "../../../../Assets/Icons/calender-icon.svg";
import {
  getActiveUsersByRole,
  getAllAreaByLocationId,
  getAllAssets,
  getAllCategories,
  getAllCustomers,
  getAllLocations,
  getAllTeams,
  getAllVendros,
  getUserDataDashBoard,
  getUsersByRole,
  IMAGES_URL,
  toggle,
} from "../../../config/functions";
import { LetteredAvatarImageUrl } from "../../../components/common/userProfilePlaceholder";
import _, { isEqual } from "lodash";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  ActionBtnIcon,
  CloseIcon,
  DashboardFilterIcon,
  DownloadIcon,
  RefreshIcon,
} from "../../../../Assets/Icons/svg";
import { DropdownItem, DropdownMenu } from "semantic-ui-react";
import PriorityDropdown from "../../../components/common/PriorityDropdown";
import DatePicker from "react-multi-date-picker";
import { toast } from "react-toastify";
import ToolTip from "react-power-tooltip";

const AssetFilter = ({
  setFilterOptions,
  currentTime,
  setCurrentTime,
  filterOptions,
  setFiltersTrigger,
  filtersTrigger,
  setOtherFilter,
  otherFilter,
  createSelectedDates,
  setCreateSelectedDates,
  setCustomSelectedDates,
  customSelectedDates,
  hasNoFilterValues,
  warrentySelectedDates,
  setWarrentySelectedDates
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });



  const [showTooltip, setShowTooltip] = useState(false);
  const [showRefreshTooltip, setShowRefreshTooltip] = useState(false);

  useEffect(() => {
    // Function to format the date and time
    const formatDate = (date) => {
      const options = {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      return `${formatter.format(date)}`;
    };

    // Function to set the current time
    const setTime = () => {
      const now = new Date();
      setCurrentTime(formatDate(now));
    };

    // Set the initial time after 3 seconds
    const timeoutId = setTimeout(() => {
      setTime();

      // Optionally update the time periodically (e.g., every minute)
      const intervalId = setInterval(() => {
        setTime();
      }, 60000); // 60000 milliseconds = 1 minute

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }, 2000); // 3000 milliseconds = 3 seconds

    // Clean up the timeout on component unmount
    return () => clearTimeout(timeoutId);
  }, []);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const [filterSearch, setFilterSearch] = useState({
    location: "",
    vendor:"",
    people: "",
    createdByUser: "",
    teams: "",
    categories: "",
    asset: "",
    customer: "",
    manufacturer: "",
  });
  const [periodType, setPeriodType] = useState("preset");
  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setCreateSelectedDates(isoDates);
    }
  };

  const handleWarrentyChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setWarrentySelectedDates(isoDates);
    }
  };

  const handleOptionChange = async (name, value) => {

    let otherFilterValue = otherFilter;
    otherFilterValue[name] = value;
    const filterData = await getUserDataDashBoard(
      user?.id,
      otherFilterValue,
      true
    );
  };

  useEffect(() => {
    (async function() {
        let the_options_customer = [];
        let the_options_vendor = [];
        let the_options_manufacturer = [];
        let the_options_location = [];
        let the_options_teams = [];
        let the_options_user = [];
        let the_options_people = [];
        let the_options_category = [];
        let the_created_user = [];
        let the_options_asset = [];
      const fetchData = await getAllCustomers(organisationId, "", 1, 99999);

      if (fetchData?.response) {
        fetchData.response.forEach((el) => {
          the_options_customer.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + " "),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      const fetchVendorData = await getAllVendros(organisationId, "", 1, 99999);
      if (fetchVendorData?.response) {
        fetchVendorData.response.forEach((el) => {
            the_options_vendor.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + " "),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }
      const fetchAssetData = await getAllAssets(
        organisationId,
        "all_asset",
        1,
        9999
      );

      if (fetchAssetData?.response) {
        fetchAssetData.response.forEach((el) => {
          the_options_asset.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.image.data
                ? el.attributes.image.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + " "),
              style: { width: "20px", height: "20px" },
            },
          });

          if(el?.attributes?.manufacturer){
            if (!the_options_manufacturer.some((inv) => inv?.text ==el?.attributes?.manufacturer)) {
              the_options_manufacturer.push({
                key: `${el.attributes.manufacturer}`,
                value: `${el.attributes.manufacturer}`,
                text: el.attributes.manufacturer,
              });
            }
           
  
          }
        });
      }

      const fetchDataCategory = await getAllCategories(organisationId);
      if (fetchDataCategory.response) {
        fetchDataCategory.response.forEach((el) => {
          the_options_category.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
        });
      }
      const fetchTeamsData = await getAllTeams(organisationId, "", 1, 99999);

      if (fetchTeamsData?.response) {
        fetchTeamsData.response.map((el) => {
          the_options_teams.push({
            key: el.id,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: LetteredAvatarImageUrl(`${el.attributes.name}`),
              style: { width: "16px", height: "16px" },
            },
          });
        });
      }

      // getAllParentLocations,
      const fetchDataLocation = await getAllLocations(
        organisationId,
        "",
        1,
        99999
      );

      if (fetchDataLocation) {
        fetchDataLocation.forEach((el) => {
          // if (roleData !== "super_admin" ) {
          //   if(allIds.includes(el.id)){
          //     the_options_location.push({
          //       key: `${el.id}`,
          //       value: `${el.id}`,
          //       text: `${el.attributes.name}`,
          //     });
          //   }

          // }else{
          the_options_location.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
          // }
        });
      }

      const fetchDataPeople = await getUsersByRole(
        [
          "user",
          "view_only",
          "admin",
          "requester",
          "super_admin",
          "limited_admin",
        ],
        organisationId,
        "",
        1,
        99999
      );

      if (fetchDataPeople.response) {
        fetchDataPeople.response.map((el) => {
          the_options_user.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.firstName + " " + el.lastName,
            image: {
              avatar: true,
              src: el?.profileImage?.url
                ? IMAGES_URL + el?.profileImage?.url
                : LetteredAvatarImageUrl(el.firstName + " " + el.lastName),
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,
        customer: {
          ...filtersTrigger.customer,
          initOptions: the_options_customer,
          options: the_options_customer,
        }, vendor: {
          ...filtersTrigger.vendor,
          initOptions: the_options_vendor,
          options: the_options_vendor,
        },
        categories: {
          ...filtersTrigger.categories,
          initOptions: the_options_category,
          options: the_options_category,
        }, manufacturer: {
          ...filtersTrigger.categories,
          initOptions: the_options_manufacturer,
          options: the_options_manufacturer,
        },
        location: {
          ...filtersTrigger.location,
          initOptions: the_options_location,
          options: the_options_location,
        },
        asset: {
          ...filtersTrigger.asset,
          initOptions: the_options_asset,
          options: the_options_asset,
        },
        // createdByUser: {
        //   ...filtersTrigger.createdByUser,
        //   initOptions: the_options_people,
        //   options: the_options_people,
        // },
        teams: {
          ...filtersTrigger.teams,
          initOptions: the_options_teams,
          options: the_options_teams,
        },
        people: {
          ...filtersTrigger.people,
          initOptions: the_options_user,
          options: the_options_user,
        },
      });
    })();
  }, []);

  //   useEffect(() => {
  //     (async function() {
  //       let the_options_area = [];
  //       if (filterOptions?.location?.length > 0) {
  //         const fetchDataLocation = await getAllAreaByLocationId(
  //           organisationId,
  //           "",
  //           filterOptions.location
  //         );

  //         if (fetchDataLocation) {
  //           console.log("fetchDataLocation: ", fetchDataLocation);
  //           fetchDataLocation.forEach((el) => {
  //             the_options_area.push({
  //               key: `${el.id}`,
  //               value: `${el.id}`,
  //               text: `${el.attributes.name}`,
  //             });
  //           });
  //         }

  //         // console.log('THE COUNTRY SELECTED', the_options);

  //         setFiltersTrigger((prev) => {
  //           return {
  //             ...prev,

  //             area: {
  //               ...filtersTrigger.area,
  //               initOptions: the_options_area,
  //               options: the_options_area,
  //             },
  //           };
  //         });
  //       } else {
  //         setFiltersTrigger((prev) => {
  //           return {
  //             ...prev,

  //             area: {
  //               initOptions: [],
  //               options: [],
  //             },
  //           };
  //         });
  //       }
  //     })();
  //   }, [filterOptions.location]);

  // useEffect(() => {
  //   (async function() {
  //     const filterData = await getUserDataDashBoard(user?.id, otherFilter);
  //     console.log('filterData: ', filterData);

  //     // if (
  //     //   filterData?.woDashboardSavedFilter &&
  //     //   !isEqual(filterData.woDashboardSavedFilter, otherFilter)
  //     // ) {
  //     //   console.log("jisaa")
  //     //   setOtherFilter(filterData.woDashboardSavedFilter);
  //     // }
  //   })();
  // }, [otherFilter]);


  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.location), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      location: {
        initOptions: filtersTrigger.location.initOptions,
        options:
          filterSearch.location.length > 0
            ? _.filter(filtersTrigger.location.initOptions, isMatch)
            : filtersTrigger.location.initOptions,
      },
    });
  }, [filterSearch.location]);


  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.asset), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      asset: {
        initOptions: filtersTrigger.asset.initOptions,
        options:
          filterSearch.asset.length > 0
            ? _.filter(filtersTrigger.asset.initOptions, isMatch)
            : filtersTrigger.asset.initOptions,
      },
    });
  }, [filterSearch.asset]);
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.customer), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      customer: {
        initOptions: filtersTrigger.customer.initOptions,
        options:
          filterSearch.customer.length > 0
            ? _.filter(filtersTrigger.customer.initOptions, isMatch)
            : filtersTrigger.customer.initOptions,
      },
    });
  }, [filterSearch.customer]); 
  
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.vendor), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      vendor: {
        initOptions: filtersTrigger.vendor.initOptions,
        options:
          filterSearch.vendor.length > 0
            ? _.filter(filtersTrigger.vendor.initOptions, isMatch)
            : filtersTrigger.vendor.initOptions,
      },
    });
  }, [filterSearch.vendor]);  
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.manufacturer), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      manufacturer: {
        initOptions: filtersTrigger.manufacturer.initOptions,
        options:
          filterSearch.manufacturer.length > 0
            ? _.filter(filtersTrigger.manufacturer.initOptions, isMatch)
            : filtersTrigger.manufacturer.initOptions,
      },
    });
  }, [filterSearch.manufacturer]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.people), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      people: {
        initOptions: filtersTrigger.people.initOptions,
        options:
          filterSearch.people.length > 0
            ? _.filter(filtersTrigger.people.initOptions, isMatch)
            : filtersTrigger.people.initOptions,
      },
    });
  }, [filterSearch.people]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.categories), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      categories: {
        initOptions: filtersTrigger.categories.initOptions,
        options:
          filterSearch.categories.length > 0
            ? _.filter(filtersTrigger.categories.initOptions, isMatch)
            : filtersTrigger.categories.initOptions,
      },
    });
  }, [filterSearch.categories]);
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        initOptions: filtersTrigger.teams.initOptions,
        options:
          filterSearch.teams.length > 0
            ? _.filter(filtersTrigger.teams.initOptions, isMatch)
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  const handleSearchLocationChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      location: e.target.value,
    });
  };
  const handleSearchAreaChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      area: e.target.value,
    });
  };
  const handleSearchTeamsChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      teams: e.target.value,
    });
  };
  const handleSearchAssetChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      asset: e.target.value,
    });
  };
  const handleSearchCategoryChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      categories: e.target.value,
    });
  };
  const handleSearchAssigneesChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      people: e.target.value,
    });
  };
  const handleSearchCustomerChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      customer: e.target.value,
    });
  };
  const handleSearchVendorChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      vendor: e.target.value,
    });
  };  
  const handleSearchManufacturerChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      manufacturer: e.target.value,
    });
  };
  return (
    <>
      <div className="dashboard-filters d-flex ">
        <div style={{ gap: "20px", flexWrap: "wrap" }} className="d-flex">
          {otherFilter.period ? (
            <DateDropdown
              title={t.dashboard.filter.period}
              selectedValues={filterOptions.dueDate}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              setCustomSelectedDates={setCustomSelectedDates}
              customSelectedDates={customSelectedDates}
              setPeriodType={setPeriodType}
              type={periodType}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions((prevState) => {
                    return {
                      ...prevState,
                      dueDate: [e.target.value],
                    };
                  });
                  setCustomSelectedDates([]);
                } else {
                  let filteredStatus = filterOptions.dueDate.filter(
                    (el) => el !== e.target.value
                  );

                  setFilterOptions({
                    ...filterOptions,
                    dueDate: filteredStatus,
                  });
                  setCustomSelectedDates([]);
                }
              }}
              options={filtersTrigger.dueDate.options}
            />
          ) : null}
          {otherFilter.location ? (
            <WorkOrderSearchDropdown
              icon={false}
              image={false}
              title={t.workOrders.table.location}
              options={filtersTrigger.location.options}
              selectedValues={filterOptions.location}
              searchValue={filterSearch.location}
              handleSearchValue={handleSearchLocationChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.location, value.value);

                setFilterOptions({
                  ...filterOptions,
                  location: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  location: "",
                });
              }}
            />
          ) : null}
          {otherFilter?.category ? (
            <WorkOrderSearchDropdown
              icon={false}
              image={false}
              title={t.workOrders.table.category}
              options={filtersTrigger.categories.options}
              selectedValues={filterOptions.categories}
              searchValue={filterSearch.categories}
              handleSearchValue={handleSearchCategoryChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.categories, value.value);

                setFilterOptions({
                  ...filterOptions,
                  categories: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  categories: "",
                });
              }}
            />
          ) : null}

          {otherFilter?.asset ? (
            <WorkOrderSearchDropdown
              title={t.assets.asset_name_data}
              options={filtersTrigger.asset.options}
              selectedValues={filterOptions.asset}
              searchValue={filterSearch.asset}
              handleSearchValue={handleSearchAssetChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.asset, value.value);

                setFilterOptions({
                  ...filterOptions,
                  asset: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  asset: "",
                });
              }}
            />
          ) : null}

          {otherFilter?.status ? (
            <PriorityDropdown
              title={t.assets.table.status}
              options={filtersTrigger.status.options}
              selectedValues={filterOptions.status}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    status: e.target.value ? [e.target.value] : [],
                  });
                } else {
                  setFilterOptions({
                    ...filterOptions,
                    satus: [],
                  });
                }
              }}
              icon={false}
              image={false}
              pill={false}
            />
          ) : null}

          {otherFilter.people ? (
            <WorkOrderSearchDropdown
              title={t.workRequests.form.people}
              options={filtersTrigger.people.options}
              selectedValues={filterOptions.people}
              searchValue={filterSearch.people}
              handleSearchValue={handleSearchAssigneesChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.people, value.value);

                setFilterOptions({
                  ...filterOptions,
                  people: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  people: "",
                });
              }}
            />
          ) : null}

          {otherFilter?.teams ? (
            <WorkOrderSearchDropdown
              icon={false}
              image={false}
              title={t.teams.add}
              options={filtersTrigger.teams.options}
              selectedValues={filterOptions.teams}
              searchValue={filterSearch.teams}
              handleSearchValue={handleSearchTeamsChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.teams, value.value);

                setFilterOptions({
                  ...filterOptions,
                  teams: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  teams: "",
                });
              }}
            />
          ) : null}

          {otherFilter?.customer ? (
            <WorkOrderSearchDropdown
              title={t.workOrders.table.customer}
              options={filtersTrigger.customer.options}
              selectedValues={filterOptions.customer}
              searchValue={filterSearch.customer}
              handleSearchValue={handleSearchCustomerChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.customer, value.value);

                setFilterOptions({
                  ...filterOptions,
                  customer: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  customer: "",
                });
              }}
            />
          ) : null}

{otherFilter?.vendor ? (
            <WorkOrderSearchDropdown
              title={t.vendors.vendor}
              options={filtersTrigger.vendor.options}
              selectedValues={filterOptions.vendor}
              searchValue={filterSearch.vendor}
              handleSearchValue={handleSearchVendorChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.vendor, value.value);

                setFilterOptions({
                  ...filterOptions,
                  vendor: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  vendor: "",
                });
              }}
            />
          ) : null}

     

{otherFilter?.manufacturer ? (
            <WorkOrderSearchDropdown
              title={t.assets.sheet.manufacturer}
              image={false}
              icon={false}
              options={filtersTrigger.manufacturer.options}
              selectedValues={filterOptions.manufacturer}
              searchValue={filterSearch.manufacturer}
              handleSearchValue={handleSearchManufacturerChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.manufacturer, value.value);

                setFilterOptions({
                  ...filterOptions,
                  manufacturer: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  manufacturer: "",
                });
              }}
            />
          ) : null}

          {otherFilter?.date ? (
            <div
              // style={createSelectedDates.length <= 1 ? {width:"138px"}:{width:"200px"}}
              className="wo-date-picker"
              // key={resetKey}
            >
              {/* <div
                style={{ color: "#788ca5", fontSize: "14px" }}
                className="mb-1"
              >
                {t.workOrders.form.date_created}
              </div> */}
              <DatePicker
                style={
                  createSelectedDates.length <= 1
                    ? { width: "166px" }
                    : { width: "166px" }
                }
                value={createSelectedDates}
                onChange={handleChange}
                showOtherDays={true}
                range
                showMonthDropdown
                showYearDropdown
                placeholder={t.assets.sheet.placed_date}
                className="active"
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
                format="DD/MM/YY"
              >
                <button
                  onClick={() => {
                    setCreateSelectedDates([]);
                    // setResetKey((pre) => !pre);
                  }}
                  className="btn-datepicker blue"
                >
                  {t.common.reset}
                </button>
              </DatePicker>
            </div>
          ) : null}

{otherFilter?.warranty_date ? (
            <div
              // style={createSelectedDates.length <= 1 ? {width:"138px"}:{width:"200px"}}
              className="wo-date-picker"
              // key={resetKey}
            >
              {/* <div
                style={{ color: "#788ca5", fontSize: "14px" }}
                className="mb-1"
              >
                {t.workOrders.form.date_created}
              </div> */}
              <DatePicker
                style={
                  warrentySelectedDates.length <= 1
                    ? { width: "181px" }
                    : { width: "166px" }
                }
                value={warrentySelectedDates}
                onChange={handleWarrentyChange}
                showOtherDays={true}
                range
                showMonthDropdown
                showYearDropdown
                placeholder=   {t.assets.sheet.w_date}
                className="active"
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
                format="DD/MM/YY"
              >
                <button
                  onClick={() => {
                    setWarrentySelectedDates([]);
                    // setResetKey((pre) => !pre);
                  }}
                  className="btn-datepicker blue"
                >
                  {t.common.reset}
                </button>
              </DatePicker>
            </div>
          ) : null}



          <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 d-flex">
            <UncontrolledDropdown className="" direction="down">
              <DropdownToggle
                style={{ position: "relative", cursor: "pointer" }}
                onMouseOver={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                color="primary"
                className="option-toggle"
              >
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={selectedLanguage == "ar" ? "top right" : "top left"}
                  show={showTooltip}
                  textBoxWidth={selectedLanguage == "ar" ? "85px" : "80px"}
                  arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                  fontSize="12px"
                  fontWeight="400"
                  fontFamily={
                    selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                  }
                  padding="4px 4px 4px 8px"
                  borderRadius="5px"
                  static
                  moveRight="10px"
                  moveUp={selectedLanguage == "ar" ? "3px" : "4px"}
                >
                  <span className="work-order-tooltip">
                    {t.dashboard.cards.other_filters}
                  </span>
                </ToolTip>
                <DashboardFilterIcon />{" "}
              </DropdownToggle>
              <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
                {/* {role !== 'view_only' ? (
  <>
    <DropdownItem>
      <ShareIcon /> {t.common.share}
    </DropdownItem>
    <DropdownItem className="divider" />
  </>
) : null} */}
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.period ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.period === false) {
                      setOtherFilter({
                        ...otherFilter,
                        period: true,
                      });

                      handleOptionChange("period", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        period: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("period", false);
                    }
                  }}
                >
                  {t.dashboard.filter.period}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.location ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.location === false) {
                      setOtherFilter({
                        ...otherFilter,
                        location: true,
                      });

                      handleOptionChange("location", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        location: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("location", false);
                    }
                  }}
                >
                  {t.workOrders.table.location}
                </DropdownItem>
                <DropdownItem className="divider" />

                <DropdownItem
                  className={otherFilter?.category ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.category === false) {
                      setOtherFilter({
                        ...otherFilter,
                        category: true,
                      });

                      handleOptionChange("category", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        category: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("category", false);
                    }
                  }}
                >
                  {t.workOrders.table.category}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.asset ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.asset === false) {
                      setOtherFilter({
                        ...otherFilter,
                        asset: true,
                      });

                      handleOptionChange("asset", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        asset: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("asset", false);
                    }
                  }}
                >
                  {t.assets.asset_name_data}
                </DropdownItem>
                <DropdownItem className="divider" />

                <DropdownItem
                  className={otherFilter?.status ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.status === false) {
                      setOtherFilter({
                        ...otherFilter,
                        status: true,
                      });

                      handleOptionChange("status", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        status: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("status", false);
                    }
                  }}
                >
                  {t.assets.table.status}
                </DropdownItem>

                <DropdownItem className="divider" />

                <DropdownItem
                  className={otherFilter?.people ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.people === false) {
                      setOtherFilter({
                        ...otherFilter,
                        people: true,
                      });

                      handleOptionChange("people", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        people: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("people", false);
                    }
                  }}
                >
                  {t.workRequests.form.people}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.teams ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.teams === false) {
                      setOtherFilter({
                        ...otherFilter,
                        teams: true,
                      });

                      handleOptionChange("teams", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        teams: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("teams", false);
                    }
                  }}
                >
                  {t.workOrders.form.teams}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.customer ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.customer === false) {
                      setOtherFilter({
                        ...otherFilter,
                        customer: true,
                      });
                      handleOptionChange("customer", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        customer: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("customer", false);
                    }
                  }}
                >
                  {t.workOrders.form.customer}
                </DropdownItem>
                <DropdownItem className="divider" />
              
                <DropdownItem
                  className={otherFilter?.vendor ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.vendor === false) {
                      setOtherFilter({
                        ...otherFilter,
                        vendor: true,
                      });
                      handleOptionChange("vendor", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        vendor: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("vendor", false);
                    }
                  }}
                >
                  {t.vendors.vendor}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.manufacturer ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.manufacturer === false) {
                      setOtherFilter({
                        ...otherFilter,
                        manufacturer: true,
                      });
                      handleOptionChange("manufacturer", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        manufacturer: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("manufacturer", false);
                    }
                  }}
                >
                  {t.assets.sheet.manufacturer}
                </DropdownItem>
                <DropdownItem className="divider" />

                <DropdownItem
                  className={otherFilter?.date ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.date === false) {
                      setOtherFilter({
                        ...otherFilter,
                        date: true,
                      });
                      handleOptionChange("date", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        date: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("date", false);
                    }
                  }}
                >
                  {t.assets.sheet.placed_date}
                </DropdownItem>
                <DropdownItem className="divider" />
                <DropdownItem
                  className={otherFilter?.warranty_date ? "selected" : ""}
                  onClick={() => {
                    if (otherFilter?.warranty_date === false) {
                      setOtherFilter({
                        ...otherFilter,
                        warranty_date: true,
                      });
                      handleOptionChange("warranty_date", true);
                    } else {
                      // Optionally, handle the else case if needed
                      setOtherFilter({
                        ...otherFilter,
                        warranty_date: false, // Or any other logic you want to apply when `asd` is false
                      });
                      handleOptionChange("warranty_date", false);
                    }
                  }}
                >
                  {t.assets.sheet.w_date}
                </DropdownItem>
              
                {/* <DropdownItem className="divider" />
    <DropdownItem onClick={() => generatePDF()}>
      <PdfIcon /> {t.common.download_pdf}
    </DropdownItem> */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>

          <div className="form-button-container mt-3">
            <button
              // content={t.procedures.form.submit.add}
              // primary
              onClick={() => {
                if (
                  !hasNoFilterValues() ||
                  createSelectedDates?.length !== 0 ||
                  customSelectedDates?.length !== 0||
                  warrentySelectedDates?.length !== 0
                ) {
                  setFilterOptions({
                    dueDate: [],
                    people: [],
                    location: [],
                    orderType: [""],
                    categories: [],
                    asset: [],
                    priority: [],
                    customer: [],
                    teams: [],
                    area: [],
                  });
                  setCustomSelectedDates([]);
                  setCreateSelectedDates([]);
                  setWarrentySelectedDates([]);
                }
              }}
              // loading={buttonStatus == 1}
              style={{ marginTop: "-10px" }}
              className="form-action-button dashboard"
            >
              {t.dashboard.cards.clear}
              <span className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                <CloseIcon />
              </span>
            </button>
          </div>

          {/* 
<Accordion styled>
{visibleOptions.map(option => (
  <Accordion.Title key={option.key}>
    {option.text}
  </Accordion.Title>
))}
{showMore && (
  <Accordion.Content>
    <MoreOptions optionsToShow={options.slice(7)} />
  </Accordion.Content>
)}
<Accordion.Title
  active={showMore}
  onClick={() => setShowMore(!showMore)}
  style={{ cursor: 'pointer' }}
>
  More
</Accordion.Title>
</Accordion> */}
        </div>

        <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2 d-flex">
          <div
            style={
              selectedLanguage == "en"
                ? { minWidth: "180px" }
                : { minWidth: "188px" }
            }
            className="mt-3"
          >
            {otherFilter?.refresh
              ? `${t.dashboard.cards.refreshed} ${otherFilter?.refresh}`
              : `${t.dashboard.cards.refreshed} ${currentTime}`}
          </div>
          <p
            onMouseOver={() => setShowRefreshTooltip(true)}
            onMouseLeave={() => setShowRefreshTooltip(false)}
            style={{
              position: "relative",
              cursor: "pointer",
            }}
            onClick={() => {
              handleOptionChange("refresh", currentTime);
              setFilterOptions({
                ...filterOptions,
              });
            }}
            className="dashboard-refresh-btn c-pointer ml-2 mr-2"
          >
            <ToolTip
              color="#fff"
              backgroundColor="#172B4D"
              position={selectedLanguage == "ar" ? "top right" : "top right"}
              show={showRefreshTooltip}
              textBoxWidth={selectedLanguage == "ar" ? "53px" : "60px"}
              arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
              fontSize="12px"
              fontWeight="400"
              fontFamily={
                selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
              }
              padding="4px 4px 4px 8px"
              borderRadius="5px"
              static
              moveRight="12px"
              moveUp={selectedLanguage == "ar" ? "3px" : "4px"}
              // moveDown="10px"
            >
              <span
                style={{ padding: "0px 10px" }}
                className="work-order-tooltip"
              >
                {t.dashboard.cards.refresh}
              </span>
            </ToolTip>
            <RefreshIcon />
          </p>

          {/* <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle
              style={{ position: "relative", cursor: "pointer" }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              color="primary"
              className="option-toggle"
            >
              <ToolTip
      color="#fff"
      backgroundColor="#172B4D"
      position={
        selectedLanguage == 'ar' ? 'top right' : 'top left'
      }
      show={showTooltip}
      textBoxWidth={selectedLanguage == 'ar' ? '53px' : '87px'}
      arrowAlign={selectedLanguage == 'ar' ? 'center' : 'start'}
      fontSize="12px"
      fontWeight="400"
      fontFamily={
        selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
      }
      padding="4px 4px 4px 8px"
      borderRadius="5px"
      static
      moveRight="10px"
      moveUp={selectedLanguage == 'ar' ? '-1px' : '4px'}
    >
      <span className="work-order-tooltip">
        {t.common.page_action}
      </span>
    </ToolTip>
              <ActionBtnIcon />{" "}
            </DropdownToggle>
            <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
              {role !== 'view_only' ? (
  <>
    <DropdownItem>
      <ShareIcon /> {t.common.share}
    </DropdownItem>
    <DropdownItem className="divider" />
  </>
) : null}
              <DropdownItem className="divider" />
              <DropdownItem
              // onClick={() => downloadXLS()}
              >
                <DownloadIcon /> {t.common.download_sheet}
              </DropdownItem>
              <DropdownItem className="divider" />
    <DropdownItem onClick={() => generatePDF()}>
      <PdfIcon /> {t.common.download_pdf}
    </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
      </div>
    </>
  );
};

export default AssetFilter;
