export default {
  title: 'ورقة التوقيت',
  filter: {
    search: 'البحث عن طريق الناس',
    search_teams: 'البحث عن طريق الفرق',
    people: 'الناس',
    location: 'موقع',
    shift: 'يحول',
    shift_A: 'التحول أ',
    shift_B: 'التحول ب',
    shift_C: 'التحول ج',
    date:"تاريخ",
  },
  table: {
    username: 'اسم المستخدم',
    teamname: 'اسم الفريق',
    total:"المجموع"
  },
  no_timesheet_found: 'لم يتم العثور على جدول زمني لهذا التاريخ',
  how_to_add_timesheet: 'كيفية إضافة الجدول الزمني',
};
