import React from 'react';
import { Container } from 'react-bootstrap';

const PrivayAndPolicy = () => {
  return (
    <>
      <div className="terms-conditions privacy">
        <Container>
          <div className="terms">
            <h1>This Privacy Policy</h1>
            <h3>Quiqflow</h3>
            <p>
              Quiqflow Technologies, Inc. (“Quiqflow,” “we,” “our,” or “us”)
              values the privacy of individuals who use the Quiqflow website,
              mobile app, and related services (collectively, the “Services”).
              This Privacy Policy (the “Policy”) describes the information that
              we gather from visitors, users, and others who access or use the
              Services (“Users,” “you,” or “your”), how we use and disclose such
              information, and the steps we take to protect such information. By
              using the Services, you consent to the privacy practices described
              in this Policy.{' '}
            </p>
            <p>
              This Policy is incorporated into and is subject to the Quiqflow
              Terms of Use. Capitalized terms used but not defined in this
              Policy have the meaning given to them in the Terms of Use.{' '}
            </p>
            <p>
              This Privacy Policy is provided to help you understand: (i) the
              Personal Information we collect, how we collect it, how we use it,
              and how we share it, (ii) the applicable rights you have regarding
              your Personal Information, and (iii) how Quiqflow protects your
              Personal Information. Personal Information does not include, and
              this Privacy Policy does not apply to, aggregate information or
              information that has been fully de-identified or anonymized in
              accordance with applicable law.
            </p>

            <ol className="main-list" start="1">
              <li>Types of Personal Information We Collect</li>
              <ul className="list list-1">
                <li>
                  <u>Personal Information</u>
                  <p>
                    {' '}
                    While using the Services, we will ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you (“Personal Information”).
                  </p>
                  <p>
                    Personal Information may include, but is not limited to,
                    your name, address, email address, phone number, company
                    information, and payment information. You may provide us
                    with Personal Information when you register for an account,
                    use the Services, make a purchase, contact customer support,
                    sign up for marketing material, or otherwise communicate
                    with us. Please note that if you choose not to share certain
                    Personal Information with us, or refuse certain contact
                    permissions, we might not be able to provide certain parts
                    of the Services. Additionally, other Users (such as within
                    your company) may give us your Personal Information, such as
                    when a User invites you to join the Services or create an
                    account. In such case, if you wish to exercise any of your
                    rights with respect to your Personal Information, please
                    contact the applicable third party directly.
                  </p>
                </li>
                <li>
                  <u>Usage Information</u>
                  <p>
                    We collect information about how you interact with the
                    Services, such as device type, IP address, operating system,
                    browser type, address of a referring website, activity on
                    our website, device ID, access dates and times, location,
                    information related to your transactions on the Services,
                    and other system activity. We may collect certain
                    information by automated means using technologies such as
                    cookies, web beacons, pixels, browser analysis tools, server
                    logs, and mobile identifiers. In many cases the information
                    we collect using cookies and other tools is only used in a
                    non-identifiable manner without reference to Personal
                    Information. For example, we may use information we collect
                    to better understand website traffic patterns and to
                    optimize your experience. We track users’ use of the
                    Services, but we do not track users across third party
                    websites. We do not process or respond to web browsers’ “do
                    not track” signals or other similar transmissions that
                    indicate a request to disable online tracking of users who
                    visit or use the Services.
                  </p>
                </li>
                <li>
                  <u>Information Received from Third Parties </u>
                  <p>
                    If you choose to connect your account to your account with a
                    third-party service, we may receive or be granted access to
                    information from such third-party service, including
                    Personal Information. You can stop sharing your information
                    from a third-party service with us by removing our access to
                    that service.
                  </p>
                </li>
              </ul>
            </ol>

            <ol className="main-list" start="2">
              <li>How We Use Your Information</li>
              <ul className="list list-2">
                <li>
                  <u>Provide, Maintain, and Improve the Services</u>
                  <p>We may use your information to:</p>
                  <ul className="sub-list">
                    <li>
                      communicate with you if you contact us directly or sign up
                      for marketing material
                    </li>
                    <li>
                      perform our obligations under the contract we have entered
                      into with you
                    </li>
                    <li>
                      provide customer service and process any transactions
                    </li>
                    <li>
                      send you confirmations, updates, security alerts, and
                      support and administrative messages
                    </li>
                    <li>detect and prevent fraud</li>
                    <li>
                      operate, protect, and optimize the Services and your
                      experience, such as by performing analytics and conducting
                      research{' '}
                    </li>
                    <li>‍troubleshoot and protect against errors</li>
                    <li>
                      personalize and improve the Services, including to
                      recognize and contact you across multiple devices
                    </li>
                    <li>
                      monitor and analyze usage and trends and otherwise measure
                      the effectiveness of the Services
                    </li>
                    <li>
                      advertise and market our Services, including delivering
                      interest-based ads, cross-device linking, and analyzing
                      the performance of such activities
                    </li>
                    <li>develop new features</li>
                  </ul>
                </li>
              </ul>
              <ul className="list list-2">
                <li>
                  <u>Sharing Your Information</u>
                  <p>
                    ‍If you use the Services, you are authorizing us to share
                    information:
                  </p>
                  <ul className="sub-list">
                    <li>that you direct us to disclose to others</li>
                    <li>
                      with our third-party business partners, vendors, and
                      consultants who perform services on our behalf or who help
                      us provide the Services, such as accounting, managerial,
                      technical, marketing, or analytic services
                    </li>
                    <li>
                      if required to do so by law or in the good-faith belief
                      that such action is necessary to comply with local, state,
                      federal, or international laws, or to respond to a court
                      order, judicial or other governmental subpoena or warrant,
                      or in the event of bankruptcy proceedings
                    </li>
                    <li>
                      if we believe in good faith that doing so is reasonably
                      necessary or appropriate to protect the rights, property,
                      or safety of Quiqflow, our users, our employees, copyright
                      owners, third parties, or the public, including without
                      limitation to protect Quiqflow or our users from
                      fraudulent, abusive, inappropriate, or unlawful use of the
                      Services
                    </li>
                    <li>
                      to enforce or apply this Policy, our Terms of Use, or our
                      other policies or agreements
                    </li>
                    <li>
                      in connection with, or during negotiations of, any merger,
                      sale of company assets, financing, or acquisition, or in
                      any other situation where information may be disclosed or
                      transferred as one of the business assets of Quiqflow
                    </li>
                  </ul>
                  <p>
                    Nothing herein restricts the sharing of aggregated or
                    anonymized information, which may be shared with third
                    parties without your consent. This Policy in no way
                    restricts or limits our collection and use of aggregate or
                    de-identified information.
                  </p>
                </li>
              </ul>
              <ul className="list list-2">
                <li>
                  <u>Links to Third-Party Sites and Services</u>
                  <p>
                    We consider the privacy of your Personal Information very
                    important and are committed to handling your Personal
                    Information with care. You have certain rights regarding
                    your Personal Information, which include:The Services may
                    contain links to other websites. We are not responsible for
                    the practices employed by websites or services linked to or
                    from the Services, nor the information or content provided
                    to or contained therein. Please remember that when you use a
                    link to go from the Services to another website, this Policy
                    does not apply to those third-party websites or services.
                    Your browsing and interaction on any third-party website or
                    service, including those that have a link on the Services,
                    are subject to that third party’s own rules and policies.
                    Please read those rules and policies before proceeding.
                  </p>
                </li>
              </ul>
            </ol>

            <ol className="main-list" start="3">
              <li>Your Rights Regarding Personal Information</li>
              <ul className="list list-2">
                <li>
                  <u>Right to Access:</u>
                  <p>
                    We acknowledge your right to access the Personal Information
                    that we maintain about you. You have the right to obtain a
                    list of the categories of Personal Information collected
                    about you as well as other related information along with
                    the source of the information. You also have the right to
                    know the categories of information shared (or sold) to third
                    parties and the purpose for such sharing as provided for in
                    this Privacy Policy. More information on how you can request
                    your right to access the Personal Information we collect,
                    use, disclose, or sell can be found under Requests to
                    Exercise Rights section of this Privacy Policy.
                  </p>
                </li>
                <li>
                  <u>Right to Deletion (the Right to be Forgotten):</u>
                  <p>
                    We acknowledge your right to delete certain Personal
                    Information that we may have collected about you. More
                    information on how you can request your right to delete the
                    Personal Information we collect, use, disclose, or sell can
                    be found under Requests to Exercise Rights section of this
                    Privacy Policy.
                  </p>
                </li>
                <li>
                  <u>Right to Opt-out of Sharing:</u>
                  <p>
                    You may have the right to opt-out of sharing certain
                    Personal Information with third parties. More information on
                    how to exercise this right to opt-out can be found under
                    Requests to Exercise Rights section of this Privacy Policy.
                  </p>
                </li>
                <li>
                  <u>Right to Amend/Rectify:</u>
                  <p>
                    You have the right to amend or rectify any inaccuracies that
                    you have identified with the information that we have about
                    you. We process Personal Information only for the purposes
                    for which it was collected and in accordance with this
                    Privacy Policy or any applicable service-specific privacy
                    notice. We review our data collection, storage, and
                    processing practices to ensure that we only collect, store,
                    and process the Personal Information needed to provide or
                    improve our services. We take reasonable steps to ensure
                    that the Personal Information we process is accurate,
                    complete, and current, but we depend on our users to update
                    or correct their Personal Information whenever necessary.
                    More information on how you can request to amend or rectify
                    your information can be found under Requests to Exercise
                    Rights section of this Privacy Policy.
                  </p>
                </li>
                <li>
                  <u>Right to Object/Restrict/Withdrawal Consent:</u>
                  <p>
                    You have the right to object to the processing of your
                    Personal Information if it is being processed under
                    legitimate interests such as for direct marketing. You also
                    have the right to withdraw your consent for the processing
                    of your Personal Information at any time. We will cease
                    processing of your information unless there is a legitimate
                    purpose or required by law. We will primarily rely on your
                    consent or legitimate interests for any use or disclosures
                    that are not required by law.
                  </p>
                </li>
                <li>
                  <u>Right to Non-Discrimination:</u>
                  <p>
                    We will not discriminate or penalize you for exercising your
                    rights.
                  </p>
                </li>
              </ul>
            </ol>

            <ol className="main-list" start="4">
              <li>How We Protect Your Information</li>
              <ul className="list list-2">
                <li>
                  <u></u>
                  <p>
                    We maintain appropriate administrative, physical, technical,
                    organizational measures, and security controls to protect
                    Personal Information, and to prevent loss, misuse,
                    unauthorized access, disclosure, alteration, and
                    destruction. We will only keep your Personal Information for
                    as long as necessary to fulfill the purposes we collected it
                    for, including for the purposes of satisfying any legal,
                    accounting, or reporting requirements. We may also retain
                    and use your Personal Information for as long as necessary
                    to resolve disputes and/or enforce our rights. Where we rely
                    on your consent to process your Personal Information, you
                    have the right to decline consent and/or if provided, to
                    withdraw consent at any time. This will not affect the
                    lawfulness of processing prior to the withdrawal of your
                    consent.{' '}
                  </p>
                  <p>
                    We limit access to Personal Information to our employees,
                    agents, contractors, and other third parties who have a
                    business need to know. They will only process Personal
                    Information on our instructions, and they are subject to a
                    duty of confidentiality. We have implemented procedures to
                    handle any suspected Personal Information breach and will
                    notify you and any applicable regulator of a breach as
                    legally required.{' '}
                  </p>
                  <p>
                    Please be aware, however, that despite our efforts, no
                    security measures are perfect or impenetrable and no method
                    of data transmission can be guaranteed against any
                    interception or other type of misuse. To protect the
                    confidentiality of information maintained in your account,
                    you must keep your password confidential and not disclose it
                    to any other person. You are responsible for all uses of the
                    Services by any person using your password. Please advise us
                    immediately by contacting us as set forth in the “Contact”
                    section below if you believe your password has been misused
                    or if you suspect a security breach.{' '}
                  </p>
                </li>
              </ul>
            </ol>

            <ol className="main-list" start="5">
              <li>Accessing and Modifying Your Information</li>
              <ul className="list list-2">
                <li>
                  <u></u>
                  <p>
                    If you have an account on the Services, you can access and
                    modify your Personal Information associated with your
                    account in your profile settings or by emailing us at the
                    email address below.{' '}
                  </p>
                  <p>
                    You may “opt out” of receiving marketing or promotional
                    emails from us by following the instructions in those emails
                    or by emailing us at the email address below. If you opt
                    out, we may still send you non-promotional emails, such as
                    emails about your account or our ongoing business relations
                    (i.e. account verification, transactional communications,
                    changes/updates to features of the Services, and technical
                    and security notices).
                  </p>
                </li>
              </ul>
            </ol>

            <ol className="main-list" start="6">
              <li>Changes and Updates to the Policy</li>
              <ul className="list list-2">
                <li>
                  <u></u>
                  <p>
                    We reserve the right to change the provisions of this Policy
                    at any time. Please revisit this page periodically to stay
                    aware of any changes. If we modify this Policy, we will make
                    it available through the Services and indicate the date of
                    the latest revision. Your continued use of the Services
                    after the revised Policy has become effective indicates that
                    you have read, understood, and agreed to the current version
                    of this Policy.{' '}
                  </p>
                </li>
              </ul>
            </ol>

            <ol className="main-list" start="7">
              <li>Contact</li>
              <ul className="list list-2">
                <li>
                  <u></u>
                  <p>
                    Please contact us with any questions regarding this Policy
                    at privacy@quiqflow.com. To submit a request related to your
                    Personal Information, please contact us at the email above
                    or at 00962 799660030.
                  </p>
                </li>
              </ul>
            </ol>
          </div>
        </Container>
      </div>
    </>
  );
};

export default PrivayAndPolicy;
