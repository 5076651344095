import React, { useState, useEffect, createRef } from 'react';
import {
  Popup,
  Header,
  Modal,
  List,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  TextArea,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';

import moment from 'moment';

import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import {
  getUsersByRole,
  getAllLocations,
  getAllInspections,
  getAllTeams,
  uploadFile,
  getAllVendros,
  getAllChecklists,
  createNewWorkRequest,
  getAllAssets,
  getActiveUsersByRole,
  getAllPeopleByLocation,
  getAllTeamsByLocation,
} from '../../config/functions';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { selectTranslations } from '../../config/i18n/slice';
import {
  AddIconWithCircle,
  AddImage,
  AddedFileIcon,
  CloseIcon,
  TextSnippet,
} from '../../../Assets/Icons/svg';
import ToolTip from 'react-power-tooltip';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import useCancelModal from '../../../hooks/Messages/useCancelModal';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import Asterisk from '../../components/common/Asterisk';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
import {
  fetchWRInitData,
  fetchWRWithFilter,
} from '../../../redux/reducers/workRequestReducer';
import { Resizable } from 're-resizable';

const FileUpload = ({
  onFileChange,
  onCertificateFileChange,
  name = '',
  attachments = {},
  setAttachments = () => {},
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (name === 'warrantyFile') {
        onCertificateFileChange(acceptedFiles, name);
      } else {
        onFileChange(acceptedFiles, name);
      }
    },
    accept:
      name === 'image'
        ? { 'image/jpeg': ['.jpeg', '.png', '.jpg'] }
        : undefined,
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const renderFile = (file, index) => (
    <div
      className={`image-dropzone file d-flex justify-content-between text-center ${
        index == 0 ? '' : 'mt-5'
      }`}
    >
      <div>
        <label style={{ fontSize: '16px' }} className="mb-0">
          <span
            className={`${
              selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
            }`}
          >
            <TextSnippet />
          </span>

          {file?.name}
        </label>
      </div>

      <div
        className="mr-5 ml-5 mt-1 c-pointer"
        onClick={(e) => {
          e.stopPropagation();
          const newCovidCopy = attachments.warrantyFile.filter(
            (covidFile, i) => i !== index
          );
          setAttachments({
            ...attachments,
            warrantyFile: newCovidCopy,
          });
        }}
      >
        <CloseIcon />
      </div>
    </div>
    // <div>
    //   <label>
    //     {file?.name}
    //     <span
    //       className="ml-3 mr-3 c-pointer"
    //       onClick={(e) => {
    //         e.stopPropagation();
    //         const newCovidCopy = attachments.warrantyFile.filter(
    //           (covidFile, i) => i !== index
    //         );
    //         setAttachments({
    //           ...attachments,
    //           warrantyFile: newCovidCopy,
    //         });
    //       }}
    //     >
    //       <DeleteIcon />
    //     </span>
    //   </label>
    // </div>
  );
  // console.log(attachments);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === 'image' ? (
        attachments?.image?.name ? (
          <div className="image-dropzone file d-flex justify-content-between text-center">
            <div>
              <label style={{ fontSize: '16px' }} className="mb-0">
                <span
                  className={`${
                    selectedLanguage == 'en' ? 'ml-5 mr-8' : 'ml-8 mr-5'
                  }`}
                >
                  <AddedFileIcon />
                </span>

                {attachments?.image?.name}
              </label>
            </div>

            <div
              className="mr-5 ml-5 mt-1 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <CloseIcon />
            </div>
          </div>
        ) : (
          <div className="image-dropzone text-center">
            <div>
              <div className="mb-3">
                <AddImage />
              </div>
              <p className="p1">
                <span className="mr-2 ml-2"></span>
                <span className="mt-1">{t.workRequests.form.drag}</span>
                <span className="mt-1" style={{ color: '#0C66E4' }}>
                  {' '}
                  {t.workRequests.form.choose}
                </span>{' '}
                <span className="mt-1">{t.workRequests.form.upload}</span>
                <p className="text-center">PNG or JPEG</p>
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.length > 0 ? (
        <>
          {attachments.warrantyFile.map((file, index) =>
            renderFile(file, index)
          )}

          <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between mt-5">
            <div className="d-flex">
              <span className="mt-1">
                <TextSnippet />
              </span>
              <div className="pl-8 pr-8">
                <p className="p1 m-0">{t.workOrders.form.file}</p>
                <p className="p2 m-0">{t.workRequests.form.attchfile}</p>
              </div>
            </div>
            <div>
              <AddIconWithCircle />
            </div>
          </div>
        </>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex">
            <span className="mt-1">
              <TextSnippet />
            </span>
            <div className="pl-8 pr-8">
              <p className="p1 m-0">{t.workOrders.form.file}</p>
              <p className="p2 m-0">{t.workRequests.form.attchfile}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};

function AddRequest({
  toggled,
  untoggle,
  dueDate,
  setSelectedDates,
  setSearchTitle,
  setFilterOptions,
  filterOptions,
  selectedDates,
  debounceValue,
  sortData,
  currentUserId,
}) {
 
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const currentUserLocations = useSelector((state) => {
    if (state?.auth?.user) {
      return state?.auth?.user?.locations;
    } else {
      return null;
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const dispatch = useDispatch();
  const t = useSelector(selectTranslations);
  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: [],
  });

  const { initData } = useSelector((state) => ({
    initData: state.workRequest.WRInitData,
  }));

  const submittedBy = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [cancelModal, toggleCancelModal] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [initChange, setInitChange] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [mapModal, toggleMapModal] = useState(false);
  const [locationAddedModal, toggleLocationAddedModal] = useState(false);
  const [geofencingModal, toggleGeofencingModal] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [requestTypeError, setRequestTypeError] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [templateOptions, setTemplateOptions] = useState([]);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [validation, setValidation] = useState({
    title: {
      error: true,
      pattern: (name) => name != '',
      skipped: false,
      message: t.common.required_field,
    },
  });
  const [data, setData] = useState({
    title: '',
    description: '',
    requestType: '',
    requestedByOptions: [],
    requestedBy: '',
    dueDate: moment().format('YYYY-MM-DD'),
    dueTime: '09:00',
    priority: 'none',
    locationOptions: [],
    location: '',
    locations: '',
    people: [],
    teams: [],
    assets: [],
    attachmentsAdded: false,
  });

  const [options, setOptions] = useState({
    locations: [],
    priority: [
      {
        key: 4,
        value: 'none',
        text: t.workOrders.form.priority_options[0],
      },
      {
        key: 0,
        value: 'low',
        text: t.workOrders.form.priority_options[1],
      },

      {
        key: 1,
        value: 'medium',
        text: t.workOrders.form.priority_options[2],
      },
      {
        key: 2,
        value: 'high',
        text: t.workOrders.form.priority_options[3],
      },
    ],

    people: [],
    teams: [],
    assets: [],
  });

  // const [attachmentsResponse, setAttachmentsResponse] = useState([]);
  const attachmentsRef = createRef();

  const resetForm = () => {
    setData({
      title: '',
      description: '',
      requestType: '',
      requestedByOptions: [],
      requestedBy: '',
      dueDate: moment().format('YYYY-MM-DD'),
      dueTime: '09:00',
      priority: 'low',
      locationOptions: [],
      location: '',
      locations: '',
      people: [],
      teams: [],
      assets: [],
      attachmentsAdded: false,
    });

    setValidation({
      title: {
        error: true,
        pattern: (name) => name != '',
        skipped: false,
        message: t.common.required_field,
      },
    });
    setAttachments({
      image: null,
      warrantyFile: [],
    });
    setInitChange(false);
    setOptions({
      locations: [],
      priority: [
        {
          key: 4,
          value: 'none',
          text: t.workOrders.form.priority_options[0],
        },
        {
          key: 0,
          value: 'low',
          text: t.workOrders.form.priority_options[1],
        },

        {
          key: 1,
          value: 'medium',
          text: t.workOrders.form.priority_options[2],
        },
        {
          key: 2,
          value: 'high',
          text: t.workOrders.form.priority_options[3],
        },
      ],

      people: [],
      teams: [],
      assets: [],
    });
    setModalWidth(Math.max(window.innerWidth / 2, 514));
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    // console.log(event.target.value);
    let currentFieldValue = event.target.value;
    const dropdown_fields = [
      'requestType',
      'requestedBy',
      'locations',
      'people',
      'teams',
      'assets',
    ];
    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      if (name == 'locations' &&!(role === 'super_admin' || role === 'admin')) {
        setData({
          ...data,
          [name]: value.value,
          people: [],
          teams: [],
          assets: [],
        });

        setOptions({
          ...options,
          people: [],
          teams: [],
          assets: [],
        });
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }

    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
  };

  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  console.log(currentUserLocations, 'sssssss');

  const fetchLocations = async () => {
    let the_options = [];
    // const fetchData = await getAllLocations(organisationId);

    if (!(role === 'super_admin' || role === 'admin')) {
      if (currentUserLocations?.length > 0) {
        currentUserLocations.forEach((location) => {
          if (!the_options.some((inv) => inv?.key == location?.id)) {
            the_options.push({
              key: location.id,
              value: location.id,
              text: location.name,
            });
          }

          location?.subLocations?.length &&
            location.subLocations.forEach((subLocation) => {
              if (!the_options.some((inv) => inv?.key == subLocation?.id)) {
                the_options.push({
                  key: subLocation.id,
                  value: subLocation.id,
                  text: subLocation.name,
                });
              }
            });
        });
      }
    } else {
      const fetchData = await getAllLocations(organisationId);
      fetchData.forEach((el) => {
        the_options.push({
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        });
      });
    }

    setOptions({
      ...options,
      locations: the_options,
    });
  };

  const fetchAssets = async () => {
    const fetchData = await getAllAssets(
      organisationId,
      'all_asset',
      1,
      9999,
      !(role === 'super_admin' || role === 'admin')? data?.locations ? data?.locations  : currentUserId :[]
    );
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setOptions({
        ...options,
        assets: the_options,
      });
    }
  };

  /*
   * The 'fetchPeopleOptions'
   */
  const fetchPeopleOptions = async (customerID, locations) => {
    const fetchData = await getAllPeopleByLocation(!(role === 'super_admin' || role === 'admin')? data?.locations ? data?.locations  : currentUserId :[], organisationId);
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            ' ' +
            el.lastName +
            ' (' +
            t.people.roles[el.role.name] +
            ')',
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  const fetchTeamsOptions = async (customerID, locations) => {
    const fetchData = await getAllTeamsByLocation(!(role === 'super_admin' || role === 'admin')? data?.locations ? data?.locations  : currentUserId :[], organisationId);
    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: 'tiny' },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches active users by role and organizes their data into options for a dropdown menu.
   */
  const fetchPeople = async () => {
    if (!data.locations) {
      const fetchData = await getActiveUsersByRole(
        ['limited_admin'],
        organisationId,
        !(role === 'super_admin' || role === 'admin') ? currentUserId : []
      );
      if (fetchData.data) {
        let the_options = fetchData.data.map((el) => {
          const the_image = el.profileImage?.url
            ? el.profileImage?.url
            : LetteredAvatarImageUrl(el?.firstName + ' ' + el.lastName);
          let teams_string_array = el?.teams?.map((one) => one.name);
          return {
            key: el.id,
            value: el.id,
            text:
              el.firstName +
              ' ' +
              el.lastName +
              ' (' +
              t.people.roles[el.role.name] +
              ')',
            // description: `${teams_string_array.join(' , ')}`,
            image: { avatar: true, src: the_image, size: 'tiny' },
          };
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          people: the_options,
        }));

        return the_options;
      }
    }
  };

  /*
   * The function fetches team options data from an API and formats it for display.
   */
  const fetchTeams = async () => {
    if (!data.locations) {
      const fetchData = await getAllTeams(
        organisationId,
        '',
        1,
        99999,
        [],
        [],
        [],
        !(role === 'super_admin' || role === 'admin') ? currentUserId : []
      );

      if (fetchData.response) {
        let the_options = fetchData.response.map((el) => {
          const the_image = LetteredAvatarImageUrl(el.attributes.name);

          return {
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { avatar: true, src: the_image, size: 'tiny' },
          };
        });
        setOptions((prevOptions) => ({
          ...prevOptions,
          teams: the_options,
        }));

        return the_options;
      }
    }
  };

  // useEffect(() => {
  //   if (data?.locations) {
  //     setData({
  //       ...data,
  //       people: [],
  //       teams: [],
  //     });
  //     setOptions({
  //       ...options,
  //       people: [],
  //       teams: [],
  //     });

  //     // fetchPeopleOptions(data.locations ? data.locations : []);
  //     // fetchTeamsOptions(data.locations ? data.locations : []);
  //   }
  // }, [data.locations]);

  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    // toggleCancelModal(false);
    setNewCancelModal(false);
    setInitChange(false);
  };

  const requestTypeOptions = [
    {
      key: 0,
      value: 'work',
      text: 'Work',
    },
    {
      key: 1,
      value: 'issue',
      text: 'Issue',
    },
  ];

  /*
   * The `onFileChange` function sets a new file attachment with random ID and details based on the input files and name.
   */
  const onFileChange = (files, name) => {
    setInitChange(true);
    // console.log('show', e.target.files[0]);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const onCertificateFileChange = (files, name) => {
    setInitChange(true);
    let newFiles = [];
    Array.from(files).forEach((file) => {
      var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
      newFiles.push({
        file: file,
        id: randomnumber,
        name: file.name,
        type: file.type,
        cancelPopup: false,
        isChanged: true,
      });
    });

    setAttachments({
      ...attachments,
      [name]: [...attachments[name], ...newFiles],
    });
  };

  const uploadAttachmentsHandler = async () => {
    let attachmentsResponse = [];
    // attachments.forEach(async (el) => {
    //   const reponse = await uploadFile(el.attributes.file);
    //   if (reponse.status == 200) {
    //     // attachmentsResponse.push(reponse.data[0].id);
    //     attachmentsResponse.push(reponse.data[0].id);
    //   }
    // });

    // return attachmentsResponse;
    // console.log(attachments);
    let theFiles = attachments
      .filter((el) => el.isChanged)
      .map((el) => el.file);

    // console.log(theFiles);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  const createNewWorkRequestHandler = async () => {
    let errorsInData = validationHandler();
    if (!errorsInData) {
      setButtonStatus(1);

      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;
      const warrantyFileResponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;

      createNewWorkRequest2Handler(
        assetImageReponse?.status == 200 ? assetImageReponse.data[0].id : null,
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : null
      );

      // const attachments_response = await uploadAttachmentsHandler();
      // // isAttachmentsNotChanged = isAttachmentsNotChanged.length == 0
      // if (attachments_response.status == 200 || attachments_response == false) {
      //   let theAttachments = attachments_response
      //     ? attachments_response.data.map((el) => el.attributes.id)
      //     : [];
      //   let unchangedAttachments = attachments.filter((el) => !el.isChanged);
      //   // console.log(unchangedAttachments)
      //   unchangedAttachments =
      //     unchangedAttachments.length > 0
      //       ? unchangedAttachments.map((el) => el.id)
      //       : [];
      //   // console.log(unchangedAttachments)
      //   const tobeSendedAttachments = theAttachments.concat(
      //     unchangedAttachments
      //   );

      //   const response = await createNewWorkRequest(
      //     String(data.title),
      //     String(data.description),
      //     String(data.requestType),
      //     Number(data.requestedBy),
      //     moment(data.dueDate).format('YYYY-MM-DD'),
      //     moment(data.dueTime).format('HH:mm:ss.SSS'),
      //     String(data.priority),
      //     Number(data.location),
      //     tobeSendedAttachments,
      //     organisationId
      //   );
      //   // console.log(response);
      //   if (response.status == 200) {
      //     setButtonStatus(0);
      //     untoggle();
      //     resetForm();
      //     setResponseData({
      //       id: response.data.data.id,
      //       name: response.data.data.attributes.title,
      //     });
      //     toggleSuccessModal(true);
      //   }
      // }
    } else {
      scrollToError();
    }
  };

  /*
   * The function `createNewAsset2Handler` handles the creation of a new WR with various data inputs and displays success or error messages accordingly.
   */
  const createNewWorkRequest2Handler = async (image, warranty) => {
    const response = await createNewWorkRequest(
      String(data.title),
      String(data.description),
      String(data.priority),
      data.locations ? Number(data.locations) : null,
      data.assets ? data.assets : null,
      data.people ? data.people : null,
      data.teams ? data.teams : null,
      image,
      warranty,
      organisationId,
      submittedBy
    );

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      setResponseData({
        id: response.data.id,
        name: response.data.name,
        qrcode: response.data.qrcode,
      });

      setFilterOptions({
        requestedBy: [],
        locations: [],
        assets: [],
        priority: [],
        status: [],
        dueDate: {
          value: 'any_day',
          text: 'Any Date',
        },
        priority: [],
        status: [],
      });

      setSearchTitle('');
      setSelectedDates([]);
      if (initData?.length == 0) {
        const fetchData = await dispatch(
          fetchWRInitData({ organisationId, role, currentUserId })
        );
      }

      toast.success(t.workRequests.work_request + ' ' + t.common.added_success);

      // setSuccessModal(true);
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        toast.error(response.error.message);
        // untoggle();
      } else {
        toast.error(t.common.something_wrong);
        untoggle();
        resetForm();
      }
      setButtonStatus(0);
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing={true}
        className={
          toggled ? 'aside-modal active procedure' : 'aside-modal procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">{t.workRequests.form.add_wr}</div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                  // onClick={() => toggleCancelModal(true)}
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    show={showCancleTooltip}
                    fontFamily={
                      selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                    }
                    textBoxWidth="36px"
                    arrowAlign="center"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
              {/* <div>
              <Icon
                name="close"
                size="large"
                title="Close"
                onClick={() => toggleCancelModal(true)}
              />
            </div> */}
            </div>
          </div>
          <div className="body">
            <Form.Field>
              <label className="label-item">
                {t.workOrders.table.title}
                <Asterisk color="#172B4D" />
              </label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder={t.workOrders.form.title_name}
                value={data.title}
                onChange={onValuesChange('title')}
                error={validation.title.error && validation.title.skipped}
                onBlur={(e) => {
                  if (validation.title.pattern(data.title)) {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: false,
                        skipped: true,
                      },
                    });
                  } else {
                    setValidation({
                      ...validation,
                      title: {
                        ...validation.title,
                        error: true,
                        skipped: true,
                      },
                    });
                  }
                }}
              />
              {validation.title.error && validation.title.skipped ? (
                <div className="label-error">{validation.title.message}</div>
              ) : (
                ''
              )}
            </Form.Field>

            <Form.Field>
              <FileUpload
                onFileChange={onFileChange}
                name="image"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>

            <Form style={{ marginBottom: '20px' }}>
              <Form.Field>
                <label className="label-item mt-5">
                  {t.workOrders.form.description}
                </label>
                <TextArea
                  autoComplete="new-password"
                  value={data.description}
                  onChange={onValuesChange('description')}
                  rows={4}
                  fluid
                  placeholder={t.workOrders.form.description_text}
                />
              </Form.Field>
            </Form>

            <Form.Field>
              <label className="label-item">
                {' '}
                <label className="label-item">
                  {t.workOrders.table.priority}
                </label>
              </label>
              <Dropdown
                placeholder={t.workOrders.table.add_priority}
                fluid
                selection
                noResultsMessage={t.common.no_results_found}
                options={options.priority}
                value={data.priority}
                onChange={(e, value) => {
                  let findPriority = value.options.find(
                    (one) => one.value == value.value
                  );
                  if (findPriority) {
                    setData({
                      ...data,
                      priority: value.value,
                    });
                  }
                }}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">
                {t.workRequests.form.location}
              </label>
              <Dropdown
                placeholder={t.workRequests.form.add_location}
                fluid
                noResultsMessage={t.common.no_results_found}
                selection
                search
                options={options.locations}
                value={data.locations}
                clearable
                onChange={onValuesChange('locations')}
                onOpen={fetchLocations}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workRequests.form.assets}</label>
              <Dropdown
                placeholder={t.workRequests.form.add_assets}
                fluid
                // disabled={!data.locations && !(role === 'super_admin' || role === 'admin')}
                noResultsMessage={t.common.no_results_found}
                selection
                search
                multiple
                options={options.assets}
                value={data.assets}
                onChange={onValuesChange('assets')}
                onOpen={fetchAssets}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workRequests.form.people}</label>
              <Dropdown
                placeholder={t.workRequests.form.add_people}
                // disabled={!data?.locations.length}
                fluid
                // disabled={!data.locations && !(role === 'super_admin' || role === 'admin')}
                noResultsMessage={t.common.no_results_found}
                selection
                search
                multiple
                options={options.people}
                value={data.people}
                onChange={onValuesChange('people')}
                onOpen={fetchPeopleOptions}
              />
            </Form.Field>

            <Form.Field>
              <label className="label-item">{t.workRequests.form.teams}</label>
              <Dropdown
                placeholder={t.workRequests.form.add_teams}
                fluid
                // disabled={!data.locations && !(role === 'super_admin' || role === 'admin')}
                noResultsMessage={t.common.no_results_found}
                selection
                search
                multiple
                options={options.teams}
                value={data.teams}
                onChange={onValuesChange('teams')}
                onOpen={fetchTeamsOptions}
              />
            </Form.Field>

            <Form.Field>
              <FileUpload
                onCertificateFileChange={onCertificateFileChange}
                name="warrantyFile"
                attachments={attachments}
                setAttachments={setAttachments}
              />
            </Form.Field>

            {/* <Form.Field>
            <label className="label-item">Request Type</label>
            <Dropdown
              placeholder="Select request type"
              fluid
              selection
              options={requestTypeOptions}
              value={data.requestType}
              onChange={onValuesChange("requestType")}
            />
          </Form.Field> */}

            {/* <Form.Field>
            <label className="label-item">Request Type</label>
            <Dropdown
              placeholder="Select request type"
              fluid
              selection
              options={requestTypeOptions}
              value={data.requestType}
              onChange={onValuesChange('requestType')}
              error={formSubmitted && !data.requestType}
            />
            {formSubmitted && !data.requestType && (
              <div style={{ color: 'red', marginTop: '5px' }}>
                Please select a Request Type.
              </div>
            )}
          </Form.Field>

          <Form.Field>
            <label className="label-item">Priority</label>
            <Button.Group basic>
              <Button
                onClick={() => {
                  setData({ ...data, priority: 'none' });
                }}
                className={data.priority == 'none' ? 'active' : ''}
              >
                None
              </Button>

              <Button
                onClick={() => {
                  setData({ ...data, priority: 'low' });
                }}
                className={data.priority == 'low' ? 'active' : ''}
              >
                Low
              </Button>

              <Button
                onClick={() => {
                  setData({ ...data, priority: 'medium' });
                }}
                className={data.priority == 'medium' ? 'active' : ''}
              >
                Medium
              </Button>

              <Button
                onClick={() => {
                  setData({ ...data, priority: 'high' });
                }}
                className={data.priority == 'high' ? 'active' : ''}
              >
                High
              </Button>
            </Button.Group>
          </Form.Field>

          <Form.Field>
            <label className="label-item">Requested By</label>

            <Dropdown
              placeholder="Requested By"
              fluid
              selection
              search
              options={data.requestedByOptions}
              value={data.requestedBy}
              onChange={onValuesChange('requestedBy')}
              onOpen={() => fetchUsersData()}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Location</label>
            <Dropdown
              placeholder="Select Location"
              fluid
              search
              clearable
              selection
              options={data.locationOptions}
              value={data.location}
              onChange={onValuesChange('location')}
              onOpen={() => fetchLocations()}
            />
          </Form.Field> */}

            {/* <Form.Field>
            <Grid columns="equal">
              <Grid.Column>
                <label className="label-item">Start day</label>
                <Input
                  autoComplete="new-password"
                  type="date"
                  fluid
                  disabled={dueDate ? true : false}
                  placeholder="Start day"
                  value={dueDate ? dueDate : data.dueDate}
                  onChange={onValuesChange('dueDate')}
                />
              </Grid.Column>
              <Grid.Column>
                <label className="label-item">Start Time</label>
                <Input
                  autoComplete="new-password"
                  type="time"
                  fluid
                  placeholder="Start time"
                  value={data.dueTime}
                  format="HH:mm:ss.SSS"
                  onChange={onValuesChange('dueTime')}
                />
              </Grid.Column>
            </Grid>
          </Form.Field> */}

            {/* <Form.Field>
            <label className="label-item">Attachments</label>
            {attachments.length > 0 ? (
              <List divided relaxed>
                {console.warn(attachments, 'jk')}
                {attachments.map((el, i) => (
                  <Popup
                    key={el?.id}
                    content="Remove file?"
                    on="click"
                    pinned
                    open={el?.cancelPopup}
                    trigger={
                      <List.Item
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let newFiles = [...attachments];
                          let currentFile = { ...attachments[i] };
                          currentFile.cancelPopup = !currentFile.cancelPopup;
                          newFiles[i] = currentFile;
                          setAttachments(newFiles);
                        }}
                      >
                        <List.Icon
                          name={
                            el?.type.match('image.*')
                              ? 'image'
                              : el?.type.match('pdf')
                              ? 'file pdf'
                              : 'file'
                          }
                          size="large"
                          color="black"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header>{el?.name}</List.Header>
                        </List.Content>
                      </List.Item>
                    }
                  >
                    <Header as="h4">Remove file?</Header>
                    <Button
                      color="black"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...attachments];
                        let currentFile = { ...attachments[i] };
                        currentFile.cancelPopup = false;
                        newFiles[i] = currentFile;
                        setAttachments(newFiles);
                      }}
                    >
                      Keep
                    </Button>
                    <Button
                      color="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        let newFiles = [...attachments];
                        newFiles.splice(i, 1);
                        setAttachments(newFiles);
                      }}
                    >
                      Yes, remove
                    </Button>
                  </Popup>
                ))}
              </List>
            ) : (
              ''
            )} */}
            {/* <div>
                <input
                  ref={attachmentsRef}
                  type="file"
                  accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                  text/plain, application/pdf, image/*"
                  hidden
                  multiple
                  onChange={(e) => onFilesChange(e)}
                />
                <div
                  className="buttun-type-link"
                  onClick={() => attachmentsRef.current.click()}
                >
                  <Icon name="add" />
                  Add files
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                    color: '#7e7e7e',
                  }}
                >
                  Allowed types: jpg, jpeg, png, pdf, doc, docx, xlsx, csv
                </p>
              </div> */}
            {/* </Form.Field> */}

            <div className="form-button-container mt-13">
              <button
                onClick={() =>
                  initChange
                    ? setNewCancelModal(initChange && true)
                    : cancelHandler()
                }
                className="form-action-button"
              >
                {t.assets.form.cancel}
              </button>

              <button
                onClick={createNewWorkRequestHandler}
                className="form-action-button blue"
              >
                {t.common.submit}
              </button>
            </div>

            {/* <div>
            <Button
              content="Submit"
              primary
              onClick={createNewWorkRequestHandler}
              loading={buttonStatus == 1}
            />
            <Button
              content="Cancel"
              basic
              onClick={() =>
                initChange
                  ? setCancelModal(initChange && true)
                  : cancelHandler()
              }
            />
          </div> */}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      <Modal
        size="tiny"
        open={successModal}
        onClose={() => toggleSuccessModal(false)}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <Header as="h2">
              Work request <b>{responseData.name}</b> added successfully!
            </Header>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleSuccessModal(false)}>
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: '24px' }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
      {/* <Modal
        size="tiny"
        open={cancelModal}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal> */}
    </>
  );
}

export default AddRequest;
