export default {
  title: 'Welcome to Quiqflow',
  subtitle:
    'Streamline maintenance, safety, and operations for frontline teams, enhancing equipment uptime by connecting people and data efficiently',
  copyright: 'Copyright',
  quiqflow: 'Quiq Flow',
  all_rights_reserved: 'All rights reserved',
  login_form: {
    title: 'Login Account',
    description: 'Enter your email and password',
    email: 'Enter email',
    password: 'Enter password',
    email_placeholder: 'Email',
    password_placeholder: 'Password',
    forget_password_link: 'Forgot password?',
    signin_button: 'Sign in',
  },
  forget_password_form: {
    title: 'Forgot Password?',
    invalid_email: 'Please enter a valid email address',
    description: 'Please enter your email to help you reset your password again',
    email_placeholder: 'Email',
    submit_button: 'Submit',
    cancle_button: 'Cancel',
    send_button: 'Send Link',
  },
  reset_password_form: {
    title: 'Reset Password',
    description: 'Enter your new password and confirm',
    new_password_placeholder: 'Enter new password',
    confirm_password_placeholder: 'Confirm new password',
    confirm_button: 'Confirm Password',
    new_password: 'New Password',
    enter_new_password: 'Enter new password',
    reset_success_password: 'Password reset successfully',
    reset_link_expired: 'The link for creating a password has already been used.',
  },

  login_message: {
    organisation_disabled: 'Organization is disabled. Cannot log in.',
    login_fail: 'The login detail is incorrect',
  },
  create_password_form: {
    title: 'Password',
    title_password: 'Please enter your password',
    description: 'Enter your new password and confirm',
    new_password_placeholder: 'Enter New Password',
    confirm_password_placeholder: 'Confirm password',
    enter_placeholder: 'Enter password',
    continue: 'Continue',
    confirm_button: 'Confirm Password',
    create_link_expired: 'The link for creating a password has already been used.',
    accept_term_statement: 'I accept Privacy Policy and Terms & Conditions.',
    accept_term_error: 'Please accept Privacy Policy and Terms & Conditions.',
    i_accept: 'I’v read and accept the ',
    privacy: 'Privacy Policy ',
    end_the: '& the',
    terms: 'Terms & Conditions',
    and: 'and',
    privacy: 'Privacy Policy',
    success: 'Password created successfully',
  },
};
//  'Privacy Policy’  & the 'Terms & Conditions'.
