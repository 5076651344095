import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import {
  Label,
  Header,
  List,
  TextArea,
  Image,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import { IMAGES_URL, getAllLogs } from '../../../config/functions';
import { selectTranslations } from '../../../config/i18n/slice';
import UserProfilePlaceholder from '../../../components/common/userProfilePlaceholder';

import { useHistory } from 'react-router-dom';

const Logs = ({ targetedData }) => {
  const t = useSelector(selectTranslations);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const fetchData = await getAllLogs('location', targetedData?.id);
      if (fetchData) {
        setData(fetchData);
        setLoading(true);
      }
      setLoading(false);
    };

    fetchData();
  }, [targetedData]);




  return (
    <div className="preview-details-wrapper">
      <div className="intro">
        {/* <div style={{ fontSize: '15px' }}>{confirmWorkRequest()}</div> */}
      </div>

      {loading ? (
        <Dimmer active inverted>
          <Loader inverted>{t.common.loading}</Loader>
        </Dimmer>
      ) : (
        <>
          {data && data?.length ? (
            data.map((one) => {
              return (
                <>
                  <div className="comment work-request">
                    <div className="">
                      <span className="user-img">
                        {one?.attributes?.user?.data?.attributes?.profileImage
                          ?.data?.attributes?.url ? (
                          <Image
                            avatar
                            title={`${one?.attributes?.user?.data?.attributes
                              ?.firstName || ''} ${one?.attributes?.user?.data
                              ?.attributes?.lastName || ''}`}
                            src={`${one?.attributes?.user?.data?.attributes?.profileImage?.data?.attributes?.url}`}
                            style={{
                              marginRight: '8px',
                              width: '32px',
                              height: '32px',
                            }}
                          />
                        ) : (
                          <UserProfilePlaceholder
                            name={`${one?.attributes?.user?.data?.attributes
                              ?.firstName || ''} ${one?.attributes?.user?.data
                              ?.attributes?.lastName || ''}`}
                            style={{
                              marginRight: '8px',
                            }}
                            width="32px"
                            height="32px"
                          />
                        )}
                        <label className="item-label font-weight-bold">
                          <span className="font-weight-bold">{`${one?.attributes
                            ?.user?.data?.attributes?.firstName || ''} ${one
                            ?.attributes?.user?.data?.attributes?.lastName ||
                            ''}`}</span>
                            <span> <Moment
                            format="DD-MM-YYYY LT"
                            className="time ml-0"
                            date={one?.attributes?.createdAt}
                          /></span>
                         
                        </label>
                      </span>
                    </div>
                    {/* <p className="desc-text ml-15 mr-15">{one.description}</p> */}
                    <p className="desc-text ml-15 mr-15">
                   
                      {one?.attributes?.description}
                    
                    </p>
                    <div>
                      {/* {one?.image?.data && one?.image?.data.length
                    ? one?.image?.data.map((el) => {
                        return (
                          <List divided relaxed>
                            <List.Item
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                window.open(
                                  IMAGES_URL + el?.attributes.url,
                                  '_blank'
                                );
                              }}
                            >
                              <List.Icon
                                name={
                                  el?.attributes.mime.match('image.*')
                                    ? 'image'
                                    : el?.attributes.mime.match('pdf')
                                    ? 'file pdf'
                                    : 'file'
                                }
                                size="large"
                                color="black"
                                verticalAlign="middle"
                              />
                              <List.Content>
                                <p className="font-weight-bold ml-2 mr-2">
                                  {el?.attributes.name}
                                </p>
                              </List.Content>
                            </List.Item>
                          </List>
                        );
                      })
                    : null} */}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className="text-center mt-5 ">
              <p className="required-text">{t.common.na}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Logs;