/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Link, Switch, Redirect } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import Registration from './Registration';
import ForgotPassword from './ForgotPassword';
import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import ResetPassword from './ResetPassword';
import { useState } from 'react';
import { Header, Grid, Form, Dropdown } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { setCookie } from '../../../config/functions';
import { selectTranslations, setLang } from '../../../config/i18n/slice';
import CreatePassword from './CreatePassword';
import { useLocation } from 'react-router';

export function AuthPage() {
  const dispatch = useDispatch();

  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const language = queryParams.get('language');

  const lang = useSelector((state) => state.i18n.lang);
  const supportedLangs = useSelector((state) => state.i18n.supportedLangs);
  const t = useSelector(selectTranslations);

  const [dropdownOptions, setDropdownOptions] = useState({
    lang: Object.entries(supportedLangs).map(([code, name]) => {
      return {
        key: code,
        value: code,
        text: name,
      };
    }),
  });

  const handleLanguageChange = (lang) => {
    dispatch(setLang(lang));
    setCookie('LANG', lang, 10);
  };
  useEffect(() => {
    if (language) {
      handleLanguageChange(language === 'Arabic' ? 'ar' : 'en');
    }
  }, [language]);

  return (
    <>
      <div
        className={`d-flex flex-column flex-root ${
          lang == 'ar' ? 'direction-rtl' : ''
        }`}
      >
        {/*begin::Login*/}
        <div
          // style={{overflow:"auto",height:"100vh"}}
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white scroll-width"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-none  d-lg-flex  bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={
              lang == 'en'
                ? {
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/bg/bg-color.svg'
                    )})`,
                    borderRadius: '0px 60px 60px 0px',
                  }
                : {
                    borderRadius: '60px 0px 0px 60px',
                    backgroundImage: `url(${toAbsoluteUrl(
                      '/media/bg/bg-arabic.svg'
                    )})`,
                  }
            }
          >
            {/*begin: Aside Container*/}
            <div className="d-flex flex-row-fluid flex-column justify-content-between login-container">
              {/* start:: Aside header */}
              <div className="mt-29">
                <h3
                  style={{ fontWeight: '600', fontFamily: 'Roboto' }}
                  className="b1-font-size mb-5 text-black"
                >
                  {t.auth.title}
                </h3>
                <p
                  className="font-weight-normal opacity-80"
                  style={{
                    width: '470px',
                    fontSize: '16px',
                    letterSpacing: '0.3px',
                    color: 'rgba(68, 84, 111, 1)',
                  }}
                >
                  {t.auth.subtitle}
                </p>
              </div>
              {/* <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  src={toAbsoluteUrl('/media/logos/quickfix-logo-blue.png')}
                />
              </Link> */}
              {/* end:: Aside header */}

              {/* start:: Aside content */}
              <div className="flex-column-fluid d-flex flex-column justify-content-center login-text">
                <img
                  className="mt-18 image-responsive"
                  style={
                    lang == 'en'
                      ? { marginLeft: '-52px' }
                      : { marginRight: '-52px' }
                  }
                  alt="Logo"
                  src={toAbsoluteUrl('/media/bg/bg-home.svg')}
                />
              </div>

              {/* end:: Aside content */}

              {/* start:: Aside footer for desktop */}
              <div className="d-none flex-column-auto d-lg-flex justify-content-between">
                <div
                  className="opacity-70 font-weight-bold	text-black"
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    color: 'rgba(68, 84, 111, 1)',
                    lineHeight: '16px',
                  }}
                >
                  {t.auth.copyright} &copy; {new Date().getFullYear()}{' '}
                  {t.auth.quiqflow}
                  <br />
                  {t.auth.all_rights_reserved}
                  <br /> V1.0.0
                </div>
                {/* <div className="d-flex">
                  <Link to="/terms" className="text-black">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-black ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-black ml-10">
                    Contact
                  </Link>
                </div> */}
              </div>
              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7">
            <div className="d-flex justify-content-end mt-5">
              <Grid columns="equal">
                {/* <Grid.Column></Grid.Column>
                <Grid.Column></Grid.Column> */}
                <Grid.Column>
                  <Form.Field>
                    <label>{t.common.language}</label>
                    <Dropdown
                      fluid
                      selection
                      options={dropdownOptions.lang}
                      value={lang}
                      className="lang-dropdown"
                      placeholder="Select Language"
                      onChange={(event, value) => {
                        handleLanguageChange(value.value);
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </div>
            {/*begin::Content header*/}

            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid justify-content-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/create-password"
                  component={CreatePassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between  mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {t.auth.copyright} &copy; {new Date().getFullYear()}{' '}
                {t.auth.quiqflow} <br />
                {t.auth.all_rights_reserved}
                <br /> V1.0
              </div>
              {/* <div className="d-flex order-1 order-sm-2 my-2">
                <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link>
              </div> */}
            </div>
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
