import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import {
  getCurrentUserData,
  login,
  updateUserNotificationToken,
} from '../../../config/functions';
import { useHistory } from 'react-router-dom';
import { selectTranslations } from '../../../config/i18n/slice';
import { getToken } from '../../../../services/firebase';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ErrorIcon, EyeIcon } from '../../../../Assets/Icons/svg';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: '',
  password: '',
};

function Login(props) {
  const { intl } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const lang = useSelector((state) => state.i18n.lang);
  const t = useSelector(selectTranslations);
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t.auth.forget_password_form.invalid_email)
      .min(6, t.common.min_6_symbol)
      .max(50, t.common.max_50_symbol)
      .required(
        intl.formatMessage({
          id: t.common.required_field,
        })
      ),
    password: Yup.string()
      .min(6, t.common.min_6_symbol)
      .max(50, t.common.max_50_symbol)
      .matches(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#!*&]).{6,50}$/,
        t.common.password_validation
      )
      .required(
        intl.formatMessage({
          id: t.common.required_field,
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return `${lang == 'ar' ? 'is-invalid is-invalid-rtl' : 'is-invalid'}`;
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return `${lang == 'ar' ? 'is-valid is-valid-rtl' : 'is-valid'}`;
    }

    return '';
  };
  const handleForgotPasswordClick = () => {
    history.push('/auth/forgot-password');
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        login(values.email, values.password)
          .then(async ({ data: { jwt, user } }) => {
            disableLoading();
            if (
              user.role.type === 'admin' ||
              user.role.type === 'super_admin' ||
              user.role.type === 'user' ||
              user.role.type === 'technician' ||
              user.role.type === 'limited_admin' ||
              user.role.type === 'requester' ||
              user.role.type === 'view_only'
            ) {
              props.login(jwt);
              let deviceToken = await getToken();
              await updateUserNotificationToken(user?.id, deviceToken);
            } else {
              setStatus(
                intl.formatMessage({
                  id: 'AUTH.VALIDATION.INVALID_LOGIN',
                })
              );
            }
          })
          .catch((err) => {
            if (err.status === 403) {
              setStatus(t.auth.login_message.organisation_disabled);
            } else {
              setStatus(t.auth.login_message.login_fail);
            }
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  useEffect(() => {
    if (formik && formik.touched) {
      formik.setFieldTouched('email', false);
      formik.setFieldTouched('password', false);
    }
  }, [lang]);

  return (
    <div
      style={{ gap: '103px' }}
      className="login-form d-flex flex-column login-signin"
      id="kt_login_signin_form"
    >
      {/* begin::Head */}
      <div className="text-center mt-8 image-container">
        <img
          style={{ marginLeft: '-52px' }}
          alt="Logo"
          src={toAbsoluteUrl('/media/bg/logo.svg')}
        />
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework d-block"
      >
        <div>
          <h3 className="font-size-h1">{t.auth.login_form.signin_button}</h3>
        </div>

        {formik.status ? (
          <div className="mt-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div
          className={`form-group  fv-plugins-icon-container ${
            formik.status ? 'mt-8' : 'mt-17'
          }`}
        >
          <label className={`form-label ${lang == "ar"?"direction-rtl":""}`}>
            {t.auth.login_form.email_placeholder}
          </label>
          <input
            placeholder={t.auth.login_form.email}
            style={
              formik.touched.email && formik.errors.email
                ? { borderColor: 'red' }
                : {}
            }
            type="email"
            className={`form-control form-control-solid  ${getInputClasses(
              'email'
            )} email-password-ltr`}
            name="email"
            {...formik.getFieldProps('email')}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block d-flex mt-2">
                {' '}
                <ErrorIcon /> <p className="ml-1">{formik.errors.email}</p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="password-container mt-8">
            <label className={`form-label ${lang == "ar"?"direction-rtl":""}`}>
              {t.auth.login_form.password_placeholder}
            </label>
            <input
              style={
                formik.touched.password && formik.errors.password
                  ? { borderColor: 'red' }
                  : {}
              }
              placeholder={t.auth.login_form.password}
              type={showPassword ? 'text' : 'password'}
              className={`form-control form-control-solid ${getInputClasses(
                'password'
              )} email-password-ltr`}
              name="password"
              {...formik.getFieldProps('password')}
            />
            <span
              className={`${
                lang == 'ar' ? 'password-toggle-rtl' : 'password-toggle'
              }`}
            >
              {showPassword ? (
                <i
                  id="eyeIcon"
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={() => setShowPassword(false)}
                ></i>
              ) : (
                <span onClick={() => setShowPassword(true)}>
                  <EyeIcon />
                </span>
                // <i
                //   id="eyeIcon"
                //   className="fa fa-eye"
                //   aria-hidden="true"
                //   onClick={() => setShowPassword(true)}
                // ></i>
              )}
            </span>
          </div>

          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block d-flex mt-2">
                {' '}
                <ErrorIcon /> <p className="ml-1">{formik.errors.password}</p>
              </div>
            </div>
          ) : null}
        </div>
      
        <a
          className={`forgot-password-text ${lang == "ar"?"direction-rtl":""} d-block mt-2 ${
            lang == 'ar' ? 'text-left' : 'text-right'
          }`}
          onClick={handleForgotPasswordClick}
        >
          {t.auth.login_form.forget_password_link}
        </a>
        <div className="">
          <button
            style={{
              color: 'white',
              fontWeight: '500',
              borderRadius: '3px',
              backgroundColor: 'rgba(12, 102, 228, 1)',
            }}
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`common-text justify-content-center mt-10 w-100 d-flex btn`}
          >
            <span>{t.auth.login_form.signin_button}</span>
            {loading && (
              <span
                className={`mt-4 spinner spinner-white  ${
                  lang == 'ar' ? 'mr-8' : 'ml-3'
                }`}
              ></span>
            )}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
