import React, { createRef, useEffect, useState } from 'react';
import {
  Header,
  Form,
  Button,
  Icon,
  List,
  Grid,
  Popup,
} from 'semantic-ui-react';
import { Country } from 'country-state-city';
import Moment from 'react-moment';
import ImageCrop from './ImageCrop';
import {
  getOrganisationData,
  getUserData,
  updateCompanyProfile,
  IMAGES_URL,
  uploadFile,
  urlToFile,
  updateUserProfileImage,
  imageUrlToFile,
} from '../../config/functions';
import { useSelector, connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as auth from '../../modules/Auth/_redux/authRedux';
import { selectTranslations } from '../../config/i18n/slice';

const MyProfile = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const t = useSelector(selectTranslations);

  const userProfileRef = createRef();
  const [userProfileSrc, setUserProfileSrc] = useState(''); // initially uploaded image
  const [userFile, setUserFile] = useState(''); //

  const [attachments, setAttachments] = useState({
    profileImage: null,
    userProfileImage: null,
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });
  const [userImageButtonStatus, setUserImageButtonStatus] = useState(0);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async function() {
      let attachmentData = {};
      const [userData] = await Promise.all([getUserData(user.id)]);
      if (userData) {
        dispatch(props.fulfillUser(userData));

        setCurrentUserData(userData);
        attachmentData.userProfileImage = userData?.profileImage
          ? {
              id: userData?.profileImage?.id,
              file: imageUrlToFile(
                userData?.profileImage?.url,
                userData?.profileImage?.name
              ),
              name: userData?.profileImage?.name,
              type: userData?.profileImage?.mime,
              cancelPopup: false,
              isChanged: false,
            }
          : null;
      }
      setAttachments(attachmentData);
    })();
  }, [reload]);

  const handleUserProfileImageChange = async (imageData, update = true) => {
    setUserImageButtonStatus(1);
    let image = null;
    if (update) {
      const profileImageResponse =
        imageData && imageData.isChanged
          ? await uploadFile([imageData.file])
          : null;
      if (profileImageResponse && profileImageResponse.status == 200) {
        image = profileImageResponse.data[0].id;
      } else if (imageData.id) {
        image = imageData.id;
      } else {
        image = null;
      }
    }
    let res = await updateUserProfileImage(user.id, image);
    if (res && res.status == 200) {
      toast.success('Profile image updated successfully.');
    } else {
      toast.error('Profile image not updated.');
    }
    setUserProfileSrc('');
    setUserFile('');
    setUserImageButtonStatus(0);
    setReload(!reload);
  };

  //console.log('Image', userProfileSrc, userFile);
  return (
    <div className="ml-5 mr-5">
      <div className="d-flex justify-content-between">
        <div></div>
        {currentUserData?.createdByUser ? (
          <div>
            <p className="invited-by-tag">
              {t.common.invited_by}{' '}
              {`${currentUserData?.createdByUser?.firstName} ${currentUserData?.createdByUser?.lastName}`}{' '}
              on{' '}
              <Moment
                format="MMM, DD, YYYY"
                date={currentUserData?.createdAt}
              />
            </p>
          </div>
        ) : null}
      </div>

      <fieldset className="mt-5">
        <legend>{` ${t.common.profile_information} `}</legend>
        <Grid columns="equal">
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.user_name}</label>
              <p>
                {user.firstName} {user.lastName}
              </p>
            </Form.Field>
          </Grid.Column>
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.user_email}</label>
              <p>{user.email}</p>
            </Form.Field>
          </Grid.Column>
        </Grid>
        <Grid columns="equal">
          <Grid.Column className="m-5">
            <Form.Field autoComplete="off">
              <label className="label-item">{t.common.phone}</label>
              <p dir="ltr">
                {currentUserData?.userProfile?.phone
                  ? currentUserData?.userProfile?.phone
                  : currentUserData?.phone
                  ? currentUserData?.phone
                  : t.common.na}
              </p>
            </Form.Field>
          </Grid.Column>
          {userImageButtonStatus ? (
            <Grid.Column className="m-5">
              <p>Loading ...</p>
            </Grid.Column>
          ) : (
            <Grid.Column className="m-5">
              <Form.Field>
                <label className="label-item">{t.common.profile_image}</label>
                {userProfileSrc && (
                  <ImageCrop
                    // ratio={1 / 1}
                    src={userProfileSrc}
                    minWidth={20}
                    minHeight={20}
                    fileData={userFile}
                    onAccept={(croppedImageUrl) => {
                      //console.log('Cropped', croppedImageUrl);
                      let CroppedFile = new File(
                        [croppedImageUrl],
                        userFile.name,
                        {
                          type: userFile.type,
                        }
                      );
                      //console.log(CroppedFile);
                      var randomnumber =
                        Math.floor(Math.random() * (0 - 100 + 1)) + 100;

                      handleUserProfileImageChange(
                        {
                          file: CroppedFile,
                          id: randomnumber,
                          name: CroppedFile.name,
                          type: CroppedFile.type,
                          cancelPopup: false,
                          isChanged: true,
                        },
                        true
                      );
                    }}
                    onCancel={() => {
                      setUserProfileSrc('');
                      setUserFile('');
                    }}
                  />
                )}
                {attachments?.userProfileImage ? (
                  <List divided relaxed>
                    <Popup
                      key={attachments?.userProfileImage?.id}
                      content="Remove file?"
                      on="click"
                      pinned
                      open={attachments?.userProfileImage?.cancelPopup}
                      trigger={
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            let currentFile = attachments?.userProfileImage;
                            currentFile.cancelPopup = !currentFile.cancelPopup;
                            setAttachments({
                              ...attachments,
                              userProfileImage: currentFile,
                            });
                          }}
                        >
                          <List.Icon
                            name={
                              attachments?.userProfileImage?.type.match(
                                'image.*'
                              )
                                ? 'image'
                                : attachments?.userProfileImage?.type.match(
                                    'pdf'
                                  )
                                ? 'file pdf'
                                : 'file'
                            }
                            size="large"
                            color="black"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header>
                              {attachments?.userProfileImage?.name}
                              <Button
                                icon
                                primary
                                title="Edit"
                                className="ml-5"
                                type="button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  let currentFile =
                                    attachments?.userProfileImage;
                                  currentFile.cancelPopup = !currentFile.cancelPopup;
                                  setAttachments({
                                    ...attachments,
                                    userProfileImage: currentFile,
                                  });
                                }}
                              >
                                <Icon name="edit" />
                              </Button>
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      }
                    >
                      <Header as="h4">{t.common.remove_file}</Header>
                      <Button
                        color="black"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          let currentFile = attachments?.userProfileImage;
                          currentFile.cancelPopup = false;
                          setAttachments({
                            ...attachments,
                            userProfileImage: currentFile,
                          });
                        }}
                      >
                        {t.common.keep}
                      </Button>
                      <Button
                        color="red"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAttachments({
                            ...attachments,
                            userProfileImage: null,
                          });
                          setUserFile('');

                          setUserProfileSrc('');
                          handleUserProfileImageChange({}, false);
                        }}
                      >
                        {t.common.yes_remove}
                      </Button>
                    </Popup>
                  </List>
                ) : (
                  <div>
                    {!userFile && (
                      <input
                        ref={userProfileRef}
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => {
                          // onFileChange(e, 'userProfileImage');
                          let acceptedFiles = e.target.files;
                          if (acceptedFiles && acceptedFiles.length > 0) {
                            if (!acceptedFiles[0].type.startsWith('image/')) {
                              toast.error(
                                'Uploaded file type is not allowed. Please select any image file to upload.'
                              );
                              return;
                            }
                            setUserFile({
                              name: acceptedFiles[0].name,
                              type: acceptedFiles[0].type,
                            });
                            const reader = new FileReader();
                            reader.addEventListener('load', () => {
                              setUserProfileSrc(reader.result);
                            });
                            reader.readAsDataURL(acceptedFiles[0]);
                          }
                        }}
                      />
                    )}
                    <div
                      className="buttun-type-link"
                      onClick={() => userProfileRef.current.click()}
                    >
                      <Icon name="add" />
                      {t.common.add}
                    </div>
                    <p
                      style={{
                        fontSize: '12px',
                        marginTop: '5px',
                        color: '#7e7e7e',
                      }}
                    >
                      {t.common.allowed_type} jpg, jpeg, png
                    </p>
                  </div>
                )}
              </Form.Field>
            </Grid.Column>
          )}
        </Grid>
      </fieldset>
      <div>
        <Button
          // color="pimary"
          className="mt-5"
          type="button"
          onClick={(e) => {
            history.push('/change-password');
          }}
        >
          {t.common.change_password}
        </Button>
      </div>
    </div>
  );
};

export default connect(null, auth.actions)(MyProfile);
