import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as auth from '../_redux/authRedux';
import { useLocation } from 'react-router';
import {
  getCurrentUserData,
  login,
  resetPassword,
  updateUserStatusToActive,
} from '../../../config/functions';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { selectTranslations } from '../../../config/i18n/slice';
import { Checkbox } from 'semantic-ui-react';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { ErrorIcon, EyeIcon } from '../../../../Assets/Icons/svg';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  password: '',
  passwordConfirmation: '',
  agreeTerms: false,
};

function CreatePassword(props) {
  const { intl } = props;
  const t = useSelector(selectTranslations);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const lang = useSelector((state) => state.i18n.lang);

  const curentUrl = useLocation();
  const queryParams = new URLSearchParams(curentUrl.search);
  const codeData = queryParams.get('code');

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t.common.min_6_symbol)
      .max(50, t.common.max_50_symbol)
      .matches(
        /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[@#!*&]).{6,50}$/,
        t.common.password_validation
      )
      .required(
        intl.formatMessage({
          id: t.common.required_field,
        })
      ),
    agreeTerms: Yup.boolean()
      .oneOf([true], t.auth.create_password_form.accept_term_error) // Ensure the terms and conditions are accepted
      .required(t.auth.create_password_form.accept_term_error),

    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t.common.password_should_match) // Ensure it matches the "password" field
      .required(t.common.required_field),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return `${lang == 'ar' ? 'is-invalid is-invalid-rtl' : 'is-invalid'}`;
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return `${
        lang == 'ar' ? 'is-valid active is-valid-rtl' : 'is-valid active'
      }`;
    }

    return '';
  };
  const handleForgotPasswordClick = () => {
    history.push('/auth/forgot-password');
  };

  const handleLoginSuccess = async (jwt, user) => {
    // Call updateUserStatusToActive if lastLogin exists

    if (!user.lastLogin) {
      let confirm = await updateUserStatusToActive(user.id);

      if (confirm.status == 200) {
        disableLoading();
        // if (user.role.name === 'admin' || user.role.name === 'super_admin') {
          props.login(jwt);
        // } else {
        //   history.push('/auth/login');
        // }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      disableLoading();
      // if (user.role.name === 'admin' || user.role.name === 'super_admin') {
        props.login(jwt);
      // } else {
      //   history.push('/auth/login');
      // }
    }
  };

  useEffect(() => {
    if (codeData) {
      setCode(codeData);
    } else {
      history.push('/auth/login');
    }
  }, [lang]);

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        resetPassword(code, values.password, values.passwordConfirmation)
          .then(({ data: { jwt, user } }) => {
            disableLoading();
            toast.success(t.auth.create_password_form.success);
            login(user.email, values.password)
              .then(({ data: { jwt, user } }) => {
              
                handleLoginSuccess(jwt, user);
                // console.log(user, 'iuseer');

                // if (user.lastLogin) {
                //   updateUserStatusToActive(user.id);
                // }
                // disableLoading();
                // if (
                //   user.role.name === 'admin' ||
                //   user.role.name === 'super_admin'
                // ) {
                //   props.login(jwt);
                // } else {
                //   history.push('/auth/login');
                // }
              })
              .catch((err) => {
                history.push('/auth/login');
                // console.log(err);
              });
          })
          .catch(() => {
            setStatus(t.auth.create_password_form.create_link_expired);
          })
          .finally(() => {
            disableLoading();
            setSubmitting(false);
          });
      }, 1000);
    },
  });

  useEffect(() => {
    if (formik && formik.touched) {
      formik.setFieldTouched('password', false);
      formik.setFieldTouched('passwordConfirmation', false);
      formik.setFieldTouched('agreeTerms', false);
    }
  }, [lang]);

  return (
    <div
      style={{ gap: '103px' }}
      className="login-form d-flex flex-column login-signin"
      id="kt_login_signin_form"
    >
      {/* begin::Head */}
      <div className="text-center mt-8">
        <img
          style={{ marginLeft: '-52px' }}
          alt="Logo"
          src={toAbsoluteUrl('/media/bg/logo.svg')}
        />
        {/* <h3 className="font-size-h1">{t.auth.create_password_form.title}</h3>
        <p className="text-muted font-weight-bold">
          {t.auth.create_password_form.description}
        </p> */}
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework d-block"
      >
        <div style={{ width: '444px' }}>
          <h3 className="font-size-h1">
            {t.auth.create_password_form.title_password}
          </h3>
        </div>

        {formik.status ? (
          <div className="mt-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        <div
          className={`form-group fv-plugins-icon-container ${
            formik.status ? 'mt-8' : 'mt-17'
          }`}
        >
          <div className="password-container">
            <label
              className={`form-label mb-0 ${
                lang == 'ar' ? 'direction-rtl' : ''
              }`}
            >
              {t.auth.create_password_form.title}
            </label>
            <input
              placeholder={t.auth.create_password_form.enter_placeholder}
              style={
                formik.touched.password && formik.errors.password
                  ? { borderColor: 'red' }
                  : {}
              }
              type={showNewPassword ? 'text' : 'password'}
              className={`active form-control form-control-solid ${getInputClasses(
                'password'
              )} email-password-ltr`}
              name="password"
              {...formik.getFieldProps('password')}
            />
            <span
              className={`${
                lang == 'ar' ? 'password-toggle-rtl' : 'password-toggle'
              }`}
            >
              {showNewPassword ? (
                <i
                  id="eyeIcon"
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={() => setShowNewPassword(false)}
                ></i>
              ) : (
                <span onClick={() => setShowNewPassword(true)}>
                  <EyeIcon />
                </span>
                // <i
                //   id="eyeIcon"
                //   className="fa fa-eye"
                //   aria-hidden="true"
                //   onClick={() => setShowNewPassword(true)}
                // ></i>
              )}
            </span>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block d-flex mt-2">
                <ErrorIcon /> <p className="ml-1"> {formik.errors.password}</p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="password-container mt-8">
            <label
              className={`form-label mb-0 ${
                lang == 'ar' ? 'direction-rtl' : ''
              }`}
            >
              {t.auth.create_password_form.confirm_button}
            </label>
            <input
              style={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
                  ? { borderColor: 'red' }
                  : {}
              }
              placeholder={
                t.auth.create_password_form.confirm_password_placeholder
              }
              type={showConfirmPassword ? 'text' : 'password'}
              className={`form-control form-control-solid ${getInputClasses(
                'passwordConfirmation'
              )} email-password-ltr`}
              name="passwordConfirmation"
              {...formik.getFieldProps('passwordConfirmation')}
            />
            <span
              className={`${
                lang == 'ar' ? 'password-toggle-rtl' : 'password-toggle'
              }`}
            >
              {showConfirmPassword ? (
                <i
                  id="eyeIcon"
                  className="fa fa-eye"
                  aria-hidden="true"
                  onClick={() => setShowConfirmPassword(false)}
                ></i>
              ) : (
                <span onClick={() => setShowConfirmPassword(true)}>
                  <EyeIcon />
                </span>
                // <i
                //   id="eyeIcon"
                //   className="fa fa-eye"
                //   aria-hidden="true"
                //   onClick={() => setShowConfirmPassword(true)}
                // ></i>
              )}
            </span>
          </div>

          {formik.touched.passwordConfirmation &&
          formik.errors.passwordConfirmation ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block d-flex mt-2">
                <ErrorIcon />{' '}
                <p className="ml-1"> {formik.errors.passwordConfirmation}</p>
              </div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label className="d-flex align-items-center mt-2">
            <Checkbox
              checked={formik.values.agreeTerms}
              onChange={(e, value) =>
                formik.setFieldValue('agreeTerms', value.checked)
              }
              name="agreeTerms"
            />
            <span className={`${lang == 'ar' ? 'mr-2' : 'ml-2'}`}>
              <span
                className={`accept-text ${lang == 'ar' ? 'direction-rtl' : ''}`}
              >{` ${t.auth.create_password_form.i_accept} `}</span>
              <a
                className={`forgot-password-text ${
                  lang == 'ar' ? 'direction-rtl' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open('/privacy', '_blank');
                }}
              >
                {`'${t.auth.create_password_form.privacy}'`}
              </a>{' '}
              <span
                className={`accept-text ${lang == 'ar' ? 'direction-rtl' : ''}`}
              >
                {t.auth.create_password_form.end_the}
              </span>{' '}
              <a
                className={`forgot-password-text ${
                  lang == 'ar' ? 'direction-rtl' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open('/terms', '_blank');
                }}
              >
                {`'${t.auth.create_password_form.terms}'`}
              </a>
              {'.'}
              {/* <a
                className="forgot-password-text"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  window.open('/privacy', '_blank');
                }}
              >
                {t.auth.create_password_form.privacy}
              </a> */}
            </span>
          </label>

          {formik.errors.agreeTerms && formik.touched.agreeTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block d-flex mt-2">
                <ErrorIcon />{' '}
                <p className="ml-1"> {formik.errors.agreeTerms}</p>
              </div>
            </div>
          ) : null}
        </div>

        <div className="">
          <button
            style={{
              color: 'white',
              fontWeight: '500',
              borderRadius: '3px',
              backgroundColor: 'rgba(12, 102, 228, 1)',
            }}
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`common-text justify-content-center mt-10 w-100 d-flex btn`}
          >
            <span>{t.auth.create_password_form.continue}</span>
            {loading && (
              <span
                className={`mt-4 spinner spinner-white  ${
                  lang == 'ar' ? 'mr-8' : 'ml-3'
                }`}
              ></span>
            )}
          </button>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(CreatePassword));
