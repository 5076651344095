export default {
  title: 'طلبات عمل',
  create_WR: 'إضافة طلب عمل',
  title_small: 'طلبات عمل',
  work_request: 'طلب العمل',
  work_request_small: 'طلب العمل',
  edit_WR: 'تعديل طلب عمل',
  tabs: {
    first: 'جدول',
    second: 'تقويم',
  },
  add: 'إضافة أمر',
  filter: {
    search: 'بحث',
    assignees: 'المعينون',
    locations: 'المواقع',
    dueDate: 'تاريخ الاستحقاق',
    status: 'الحالة',
    priority: 'الأفضلية',
    createdByUser: 'انشأ من قبل',
  },
  status: {
    approved: 'تمت الموافقة',
    declined: 'تم الرفض',
    pending: 'قيد الانتظار',
  },
  table: {
    title: 'العنوان',
    wr_status: 'حالة طلب العمل',
    wo_status: 'حالة أمر العمل',
    asset: 'الممتلك',
    wo_id: 'الرمز أمر العمل',
    sub_date: 'تاريخ الإنشاء',
    sub_by: 'تم التقديم من قبل',
    ID: 'الرمز',
    status: 'الحالة',
    priority: 'الأفضلية',
    assignees: 'المعينون',
    category: 'الفئة',
    customer: 'العميل',
    location: 'الموقع',
    dueDate: 'تاريخ الاستحقاق',
    procedure: 'الإجراء',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    updatedBy: 'تاريخ التحديث',
  },
  form: {
    add_wr: 'إضافة طلب عمل',
    add_title: 'أضف العنوان',
    description: 'الوصف',
    add_description: 'أضف الوصف',
    priority: 'الأولوية',
    location: 'الموقع',
    add_location: 'اختر الموقع',
    assets: 'الممتلكات',
    add_assets: 'اختر الممتلكات',
    people: 'الأفراد',
    add_people: 'اختر الأفراد',
    teams: 'الفرق',
    add_teams: 'اختر الفرق',
    add_priority: 'اختر الأولوية',
    attchfile: 'قم بإرفاق ملف لطلب العمل هذا',
    drag: 'قم بالسحب ةالإسقاط أو',
    choose: 'اختر صورة',
    upload: 'تحميل',
    update_success: 'تم تحديث طلب العمل بنجاح',
    delete_success: 'تم تحديث طلب العمل بنجاح',
    approved_success: 'تمت الموافقة بنجاح',
    approved_statement: 'تمت الموافقة على هذا طلب العمل وتم تحويله إلى',
    declined_statement: 'لقد تم رفض طلب العمل هذا',
    this_wr: 'طلب العمل هذا',
    submitted: 'تم التقديم',
    unknown: 'شخص غير معروف',
    decline_wr: 'رفض طلب العمل',
    decline_wr_statement: 'هل أنت متأكد أنك تريد رفض طلب العمل هذا؟',
    decline_success: 'تم الرفض بنجاح',
    cancle_statement: 'هل أنت متأكد أنك تريد إلغاء طلب العمل هذا؟',
    delete_related_error:'لا يمكن حذف طلب العمل هذا'
  },
  how_to_add_wr: 'كيفية إضافة طلبات العمل',
  no_wr_added: 'ابدء في إضافة طلبات العمل',
  created_this: 'قام بإنشاء هذا',
  approved_this: 'قام بالموافقة على هذا',
  declined_this: 'قام برفض هذا',
};
