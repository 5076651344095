import React, { useRef, useEffect, useState } from 'react';
import { shallowEqual, useSelector, connect, useDispatch } from 'react-redux';
import { LayoutSplashScreen } from '../../../../_metronic/layout';
import * as auth from './authRedux';
import { getUserByToken, getPermissionsByRole, getOrganisation } from './authCrud';
import moment from 'moment';
import {
  getUserData,
  updateUserNotificationToken,
  setCookie,
} from '../../../config/functions';
import { getToken, onMessageListener } from '../../../../services/firebase';
import { setLang } from '../../../config/i18n/slice';
import 'moment/locale/en-gb';
import 'moment/locale/ar';

function AuthInit(props) {
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  moment.locale(selectedLanguage);
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );

  useEffect(() => {
    // Add your CSS class to the body tag
    if (selectedLanguage == 'ar') {
      document.body.classList.add('rtl-body');
    }

    // Cleanup function to remove the class when the component unmounts
    return () => {
      document.body.classList.remove('rtl-body');
    };
  }, [selectedLanguage]);

  useEffect(() => {
    onMessageListener();
  }, [authToken]);

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken();
          if (user) {
            const userData = await getUserData(user.id);
            const {
              data: {
                role: { permissions },
              },
            } = await getPermissionsByRole(user?.role?.id);

            const {
              data: organisation
            } = await getOrganisation(user?.organisation?.id);

           
            dispatch(props.fulfillUser(userData));
            dispatch(props.userPermissions(permissions));
            dispatch(props.SetOrganisation(organisation?.data?.attributes));
            // dispatch(setLang(language));
            // setCookie('LANG', language, 10);
            let deviceToken = await getToken();
            await updateUserNotificationToken(user.id, deviceToken,selectedLanguage == "en" ? "English":"Arabic");
          }
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, [selectedLanguage, authToken]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
