import React, { useEffect, useState, createRef } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Loader,
} from 'semantic-ui-react';
import Pagination from '../../components/common/CommonPagination';

import Tooltip from 'react-power-tooltip';
import { useDebounce } from 'use-debounce';

import Moment from 'react-moment';

import DataTable from 'react-data-table-component';

import { useLocation, useHistory } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import { getAllProcedures, humanize, IMAGES_URL } from '../../config/functions';

import Add from './add3';
import Edit from './edit3';
import Preview from './preview/index';

import Filters from './filters';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import {
  ProcedureIcon,
  ProcedurePlusIcon,
  Electrical,
  Damage,
  Inspections,
  Mechanical,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  OperationProcedure,
  ArrowLeft,
  ArrowRight,
  ProcedureIconBlue,
  SortIcon,
  CloseIcon,
  CorrectiveIcon,
  PlannedIcon,
  UnPlannedIcon,
  CatgoryPreventive,
} from '../../../Assets/Icons/svg';
import '../../../scss/pages/procedure/procedure.scss';
import moment from 'moment';
import DateConvert from '../../../utilFunctions/DateFormat';
import useGetCurrentTimeAccZone from '../../../hooks/useGetCurrentTimeAccZone';
import { Me } from 'react-flags-select';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import {
  fetchAllprocedureData,
  fetchProcedureInitData,
  fetchProcedureWithFilter,
} from '../../../redux/reducers/procedureReducer';
import Animation from '../../components/common/CustomLoader';
// import ReactTooltip from 'react-tooltip';
const customStyles = {
  rows: {
    style: {
      height: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingTop: '11px', // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px', // override the cell padding for data cells
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
  },
};
export const Procedures = () => {
  const { userPermissions } = usePermissionsSimplified();

  const [searchTitle, setSearchTitle] = useState('');
  const [selectedDates, setSelectedDates] = useState([]);
  const [debounceValue] = useDebounce(searchTitle, 500);
  const { currentDate, organisationTimezone } = useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();

  const dispatch = useDispatch();
  const t = useSelector(selectTranslations);
  const [sortData, setSortData] = useState();
  const [loader, setloader] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const procedureId = queryParams.get('id');
  const history = useHistory();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.procedure.procedureData,
    isLoading: state.procedure.isLoading,
    error: state.procedure.error,
    initData: state.procedure.procedureInitData,
    page: state.procedure.page,
    pageSize: state.procedure.pageSize,
    total: state.procedure.total,
    initLoading: state.procedure.initLoading,
  }));

  /**
   * Table Fields
   */

  const handleSort = async (column, sortDirection) => {
    setloader(true);

    // Assuming the selector represents the field to sort
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchProcedureWithFilter({
        isGlobalTemplate: false,
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        category: filterOptions.category,
        createdby: filterOptions.createdByUser,
        createdat:
          selectedDates.length == 1 ? [selectedDates[0]] : selectedDates,
        sort: sort,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    await dispatch(
      fetchProcedureWithFilter({
        isGlobalTemplate: false,
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        category: filterOptions.category,
        createdby: filterOptions.createdByUser,
        createdat:
          selectedDates.length == 1 ? [selectedDates[0]] : selectedDates,
        sort: sort,
      })
    );

    setloader(false);
  };
  const columns = [
    {
      name: t.procedures.table.ID,
      // selector: (row) => row?.id,
      sortField: 'countNumId',
      width: selectedLanguage === 'en' ? '126px' : '143px',
      sortable: true,
      cell: (row) => <span onClick={()=>{
        setTargetedData(row);
        setTogglePreviw(true);
      }} className="id-text-style">{row?.attributes?.countId}</span>,
    },
    {
      name: t.procedures.table.title,
      minWidth: '172px',
      // selector: (row) => row?.attributes?.title,
      sortField: 'title',
      sortable: true,
      cell: (row) => (
        <span onClick={()=>{
          setTargetedData(row);
          setTogglePreviw(true);
        }}  className="id-text-style">
          {DisplayFormattedText(row?.attributes?.title) || t.common.na}
        </span>
      ),
      allowOverflow: true,
    },
    {
      minWidth: '207px',
      name: t.procedures.table.description,
      // selector: (row) => row?.attributes?.description,
      sortField: 'description',
      sortable: true,
      cell: (row) =>
        DisplayFormattedText(row?.attributes?.description) || t.common.na,
    },
    {
      minWidth: '166px',
      name: t.procedures.details_view.category,
      sortField: 'category',
      // selector: (row) => row?.attributes?.category,
      sortable: true,
      cell: (row) => {
        const selectedCategory = categoryOptions.find(
          (one) => one.value === row?.attributes?.category
        );
        return (
          <>
            {' '}
            <span onClick={()=>{
        setTargetedData(row);
        setTogglePreviw(true);
      }}  className={` ${selectedLanguage == 'en' ? 'mr-2' : 'ml-2'}`}>
              {selectedCategory?.tableIcon}
            </span>
            {selectedCategory?.text_short ||
              selectedCategory?.text ||
              t.common.na}
          </>
        );
      },
    },
    {
      // width: '189px',
      name: t.procedures.details_view.procedure_items,
      selector: (row) => row?.attributes?.procedureItems,
      // sortable: true,
      cell: (row) => {
        return (
          <span onClick={()=>{
            setTargetedData(row);
            setTogglePreviw(true);
          }}  className="pill">{`${
            row?.attributes?.procedureItems
              ? row?.attributes?.procedureItems
              : 0
          } ${t.procedures.table.fields}`}</span>
        );
      },
    },
    {
      // width: '198px',
      name: t.procedures.table.createdByUser,
      sortField: 'createdByUser.firstName',
      // selector: (row) =>
      //   row?.attributes?.createdByUser?.data?.attributes
      //     ? row?.attributes?.createdByUser?.data?.attributes?.firstName +
      //       ' ' +
      //       row?.attributes?.createdByUser?.data?.attributes?.lastName
      //     : t.common.na,
      sortable: true,
      cell: (row) => {
        let createdByUser = row?.attributes?.createdByUser?.data ? (
          <div onClick={()=>{
            setTargetedData(row);
            setTogglePreviw(true);
          }} >
            <Image
              avatar
              title={
                row.attributes.createdByUser.data.attributes.firstName +
                ' ' +
                row.attributes.createdByUser.data.attributes.lastName
              }
              src={
                row?.attributes?.createdByUser?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? IMAGES_URL +
                    row?.attributes?.createdByUser?.data?.attributes
                      ?.profileImage?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.createdByUser?.data?.attributes
                        .firstName +
                        ' ' +
                        row?.attributes?.createdByUser?.data?.attributes
                          ?.lastName
                    )
              }
              style={{
                width: '34px',
                height: '34px',
                marginLeft: '0px',
                marginRight: '0px',
              }}
            />
            <span
              style={{ marginLeft: '4px', marginRight: '4px' }}
              className="id-text-style"
            >
              {row.attributes.createdByUser.data.attributes.firstName}
            </span>
          </div>
        ) : (
          t.common.na
        );
        return createdByUser;
      },
    },
    {
      // width: 'auto',
      name: t.procedures.table.createdAt,
      width: '144px',
      // selector: (row) => row?.attributes?.createdAt,
      sortField: 'createdAt',
      sortable: true,
      cell: (row) => (
        <div onClick={()=>{
          setTargetedData(row);
          setTogglePreviw(true);
        }} >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },
    {
      // width: 'auto',
      name: t.procedures.table.updatedAt,
      // selector: (row) => row?.attributes?.updatedAt,
      sortField: 'updatedAt',
      sortable: true,
      width: '144px',
      cell: (row) => (
        <div onClick={()=>{
          setTargetedData(row);
          setTogglePreviw(true);
        }} >
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.updatedAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.updatedAt} />
          </div>
        </div>
      ),
    },
  ];

  const [filterOptions, setFilterOptions] = useState({
    category: [],
    createdByUser: [],
  });

  const categoryOptions = [
    {
      key: 0,
      value: 'all',
      text: t.procedures.details_view.category,
      icon: {
        circular: true,
        name: 'check circle outline',
        color: 'blue',
      },
      tableIcon: null,
    },
   
    {
      key: 1,
      value: 'damage',
      text: t.workOrders.form.category_options[0],
      icon: {
        circular: true,
        name: 'warning sign',
        color: 'red',
      },
      tableIcon: <Damage />,
    },
    {
      key: 10,
      value: 'corrective action Downtime',
      text:t.workOrders.form.category_options[1],
      icon: {
        circular: true,
        name: 'check circle outline',
        color: 'blue',
      },
      tableIcon:  <CorrectiveIcon/>,
    },
    {
      key: 11,
      value: 'planned Downtime',
      text:t.workOrders.form.category_options[2],
      icon: {
        circular: true,
        name: 'check circle outline',
        color: 'blue',
      },
      tableIcon: <PlannedIcon/>,
    },
    {
      key: 12,
      value: 'unplanned Downtime',
      text:t.workOrders.form.category_options[3],
      icon: {
        circular: true,
        name: 'check circle outline',
        color: 'blue',
      },
      tableIcon:  <UnPlannedIcon/>,
    },
    {
      key: 3,
      value: 'inspection',
      text: t.workOrders.form.category_options[4],
      icon: {
        circular: true,
        name: 'clipboard list',
        color: 'violet',
      },
      tableIcon: <Inspections />,
    },
    {
      key: 2,
      value: 'electrical',
      text: t.workOrders.form.category_options[5],
      icon: {
        circular: true,
        name: 'bolt',
        color: 'yellow',
      },
      tableIcon: <Electrical />,
    },

    {
      key: 4,
      value: 'mechanical',
      text: t.workOrders.form.category_options[6],
      icon: {
        circular: true,
        name: 'cog',
        color: 'orange',
      },
      tableIcon: <Mechanical />,
    },
    {
      key: 7,
      value: 'HVAC',
      text: t.workOrders.work_order_category.HVAC,
      icon: {
        circular: true,
        name: 'snowflake',
        color: 'blue',
      },
      tableIcon: <Refrigeration />,
    },
    {
      key: 5,
      value: 'preventive maintenance',
      text: t.workOrders.form.category_options[8],
      icon: {
        circular: true,
        name: 'refresh',
        color: 'olive',
      },
      tableIcon: <CatgoryPreventive />,
    },
    {
      key: 6,
      value: 'project',
      text: t.workOrders.form.category_options[9],
      icon: {
        circular: true,
        name: 'file excel',
        color: 'brown',
      },
      tableIcon: <Project />,
    },

    {
      key: 8,
      value: 'safety',
      text: t.workOrders.form.category_options[10],
      icon: {
        circular: true,
        name: 'shield',
        color: 'green',
      },
      tableIcon: <Safety />,
    },
    {
      key: 9,
      value: 'sop',
      text: t.workOrders.form.category_options[11],
      text_short: t.procedures.procedure_category.sop_short,
      icon: {
        circular: true,
        name: 'clipboard check',
        color: 'pink',
      },
      tableIcon: <OperationProcedure />,
    },
  ];

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [fetching, setFetchLoading] = useState(true);

  // const [data, setData] = useState([]);
  // const [initData, setInitData] = useState([]);

  const [targetedData, setTargetedData] = useState();
  const [refresh, setRefresh] = useState(false);
  const [togglePreview, setTogglePreviw] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [initCloneList, setInitCloneList] = useState([]);
  // const [pageSize, setPageSize] = useState(25);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  const rowStyle = { cursor: 'pointer' };

  useEffect(() => {
    setFetchLoading(true);
    const fetchData = async () => {
      const fetchData = await dispatch(fetchProcedureInitData(organisationId));
      // console.log(fetchData);
      if (fetchData) {
        setFetchLoading(false);
        if (procedureId) {
          const fetchAllData = await dispatch(
            fetchAllprocedureData(organisationId)
          );
          let selectedProcedure = fetchAllData.payload.response.find(
            (each) => each.attributes?.countId   == procedureId
          );
          if (selectedProcedure) {
            setTargetedData(selectedProcedure);
            setTogglePreviw(true);
          }
        }
      }
    };

    fetchData();
  }, [refresh, procedureId]);

  useEffect(() => {
    const fetchData = async () => {
      const fetchAllData = await dispatch(
        fetchAllprocedureData(organisationId)
      );
      setInitCloneList(fetchAllData.payload.response);
    };

    fetchData();
  }, [filterOptions]);

  const handelToggleEdit = (targetedData) => {
    setTargetedData(targetedData);
    setTogglePreviw(false);

    setToggleEdit(true);
  };

  const handleSetDataByFilter = (newData) => {
    // setData(newData);
    setCurrentPage(1);
  };

  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const totalFilteredPages = Math.ceil(data.length / pageSize);
  const paginationOptions = {
    // rowsPerPageText: 'Filas por página',
    rangeSeparatorText: t.common.of,
    // selectAllRowsItem: true,
    // selectAllRowsItemText: 'Todos',
    noRowsPerPage: true,
  };

  return (
    <div className="procedure-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <ProcedureIcon />
            {t.procedures.procedures}
          </Header>
          <div></div>

          {userPermissions?.procedure?.create && initData.length > 0 ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setToggleAsideModal(true);
              }}
            >
              {t.procedures.create_procedure}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>
      {initLoading ? (
         <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
         <Animation/>
         {/* <Placeholder fluid>
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
           <Placeholder.Line />
         </Placeholder> */}
       </div>
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div className="procedure-filter">
          {initData.length > 0 ? (
            <>
              <Filters
                data={initData}
                setDataByFilter={handleSetDataByFilter}
                hasStatus={false}
                fileData={data}
                setFilterOptions={setFilterOptions}
                filterOptions={filterOptions}
                setSearchTitle={setSearchTitle}
                searchTitle={searchTitle}
                debounceValue={debounceValue}
                selectedDates={selectedDates}
                setSelectedDates={setSelectedDates}
                setSortData={setSortData}
                sortData={sortData}
                loader={loader}
                setloader={setloader}
              />
              {error ? (
                <div className="mt-8 mb-8 ">
                <Animation/>
                </div>
              ) : (
                <DataTable
                  className="procedure-table"
                  columns={columns}
                  onSort={handleSort}
                  fixedHeader
                  data={data}
                  customStyles={customStyles}
                  noDataComponent={
                    initData.length > 0 ? (
                      <div className="no-procedure-container">
                        <div className="">
                          <p className="no-procedure-text">
                            {t.common.no_result_found}
                          </p>
                        </div>
                      </div>
                    ) : null
                  }
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  onRowClicked={(row) => {
                    setTargetedData(row);
                    setTogglePreviw(true);
                  }}
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">
                      { t.common.loading}
                    </div>
                  }
                />
              )}
              <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={total}
                  prevItem={
                    selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={page}
                  limit={pageSize}
                  handleNext={async () => {
                    setloader(true);
                    await dispatch(
                      fetchProcedureWithFilter({
                        isGlobalTemplate: false,
                        organisationId,
                        searchName: debounceValue,
                        page: page + 1,
                        pageSize: pageSize,
                        category: filterOptions.category,
                        createdby: filterOptions.createdByUser,
                        createdat:
                          selectedDates.length == 1
                            ? [selectedDates[0]]
                            : selectedDates,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                  handlePrevious={async () => {
                    setloader(true);
                    await dispatch(
                      fetchProcedureWithFilter({
                        isGlobalTemplate: false,
                        organisationId,
                        searchName: debounceValue,
                        page: page - 1,
                        pageSize: pageSize,
                        category: filterOptions.category,
                        createdby: filterOptions.createdByUser,
                        createdat:
                          selectedDates.length == 1
                            ? [selectedDates[0]]
                            : selectedDates,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="no-procedure-container">
              <div className="no-work-orders pb-15">
                <ProcedureIconBlue />
                <p className="no-data-text">
                  {t.common.there_no}{' '}
                  <span
                    className="link-text"
                    style={{ position: 'relative', cursor: 'pointer' }}
                    onMouseOver={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                  >
                    {/* <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position="top left"
                      show={showTooltip}
                      textBoxWidth="41px"
                      arrowAlign="start"
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily="Roboto"
                      padding="0px 0px 0px 8px"
                      borderRadius="5px"
                      static
                      moveRight="150px"
                      moveUp="-1px"
                    >
                      <span className="work-order-tooltip">
                        {t.procedures.how_to_add_procedure}
                      </span>
                    </Tooltip>   */}
                    <Tooltip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={
                        selectedLanguage == 'ar' ? 'top center' : 'top left'
                      }
                      show={showTooltip}
                      textBoxWidth={selectedLanguage == 'ar' ? '109px' : '41px'}
                      arrowAlign="start"
                      fontSize="14px"
                      fontWeight="400"
                      fontFamily={
                        selectedLanguage == 'ar' ? 'Noto Kufi Arabic' : 'Roboto'
                      }
                      padding="0px 0px 0px 8px"
                      borderRadius="5px"
                      static
                      moveRight={selectedLanguage == 'ar' ? '50px' : '114px'}
                      moveUp={selectedLanguage == 'ar' ? '-12px' : '-1px'}
                    >
                      <span className="work-order-tooltip">
                        {t.procedures.how_to_add_procedure}
                      </span>
                    </Tooltip>
                    {' ' + t.procedures.procedures_small + ' '}
                  </span>{' '}
                  {t.common.added}
                </p>
                {userPermissions?.procedure?.create ? (
                  <button
                    className="work-order-button-transparent"
                    onClick={() => {
                      setToggleAsideModal(true);
                    }}
                  >
                    {t.procedures.add_procedure}
                  </button>
                ) : null}
              </div>
            </div>

            // <div className="no-procedure-container">
            //   <div className="">
            //     <ProcedureIcon width="40px" height="36px" />
            //     <p className="no-procedure-text">
            //       {t.procedures.no_procedure_added}
            //     </p>
            //   </div>
            // </div>
          )}
        </div>
      )}

      <Add
        toggled={toggleAsideModal}
        setInitCloneList={setInitCloneList}
        initCloneList={initCloneList}
        untoggle={() => {
          setToggleAsideModal(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        setSearchTitle={setSearchTitle}
        searchTitle={searchTitle}
        debounceValue={debounceValue}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        setSortData={setSortData}
        sortData={sortData}
        loader={loader}
        setloader={setloader}
      />
      <Edit
        targetedData={targetedData}
        toggled={toggleEdit}
        untoggle={() => {
          setTargetedData();
          setToggleEdit(false);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
        }}
        setRefresh={setRefresh}
        setInitCloneList={setInitCloneList}
        initCloneList={initCloneList}
        refresh={refresh}
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        setSearchTitle={setSearchTitle}
        searchTitle={searchTitle}
        debounceValue={debounceValue}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        setSortData={setSortData}
        sortData={sortData}
        loader={loader}
        setloader={setloader}
      />

      <Preview
        setSearchTitle={setSearchTitle}
        setFilterOptions={setFilterOptions}
        targetedData={targetedData}
        setModalWidth={setModalWidth}
        modalWidth={modalWidth}
        edit={() => {
          setTogglePreviw(false);
          setToggleEdit(true);
        }}
        toggled={togglePreview}
        handelToggleEdit={handelToggleEdit}
        untoggle={() => {
          setTargetedData();
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          setTogglePreviw(false);
          history.push('/procedures');
        }}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};
