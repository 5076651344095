export default {
  title: 'المسميات الوظيفية',
  add: 'مسمى وظيفي',
  title_small: 'المسميات الوظيفية',
  add_job: 'إضافة مسمى وظيفي',
  filter: {
    searchByName: 'البحث عن طريق إسم الممتلك',
    searchBySerialNumber: 'البحث عن طريق الرقم التسلسلي',
    area: 'المجال',
    model: 'النموذج',
    barcode: 'الباركود',
    wed: 'تاريخ انتهاء الضمان',
    vendors: 'البائعين',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    qrcode: 'رمز الاستجابة السريعة',
  },
  table: {
    name: 'العنوان',
    ID: 'الرمز',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    updatedAt: 'تاريخ التحديث',
  },
  cannot_delete_job_title_statement: 'لا يمكن حذف هذا المسمى الوظيفي',
  edit_job_title: "تعديل مسمى وظيفي",
  save: 'حفظ',
  unique: 'يجب أن يكون المسمى الوظيفي اسمًا فريدًا',
  jobTitle: 'مسمى وظيفي',
  delete_job_title_statement: 'هل أنت متأكد أنك تريد حذف هذا المسمى الوظيفي؟',
  no_job_title_available: 'يبدو أنه ليس لديك مسميات وظيفية بعد.',
  job_title_updated_success: 'تم تحديث المسمى الوظيفي بنجاح',
  job_title_already_exist: 'المسمى الوظيفي موجود ',
  add_job_title_name: 'يرجى إضافة المسمى الوظيفي',
  job_title_delete_success: 'تم حذف المسمى الوظيفي بنجاح',
  job_title_create_success: 'تم إنشاء المسمى الوظيفي بنجاح',
  job_title_not_created: 'لا يمكن إنشاء المسمى الوظيفي',
  import: 'استيراد',
  uplaod_job_titles: 'تحميل المسميات الوظيفية',
  import_job_titles: 'استيراد المسميات الوظيفية',
  upload_excel_category_file:
    'يرجى تحميل ملف Excel الصحيح لإضافة المسميات الوظيفية',
  download_sample: 'تحميل ملف عينة',
  status: 'الحالة',
  error: 'خطأ',
  failed: 'فشل',
  success: 'نجاح',
  xls: 'نموذج المسمى الوظيفي.xlsx',
  how_to_add_jobtitle: 'كيفية إضافة المسميات الوظيفية',
  no_jobtitle_added: 'ابدأ بإضافة المسميات الوظيفية',
  not_updated:"لا يمكن تحديث المسمى الوظيفي."
};
