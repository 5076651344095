export default {
  title: 'أفراد', 
  add: 'شخص',
  next_btn: 'التالي',
  add_person: 'إضافة أفراد',
  filter: {
    search: 'بحث',
    role: 'دور المستخدم',
    select_role: 'اختر دور المستخدم',
    supervisors: 'المشرف',
    select_supervisors: 'اختر المشرف',
    teams: 'الفرق',
    team: 'الفرق',
    select_teams: 'اختر الفرق',
    rate: 'السعر',
    from: 'من',
    to: 'إلى',
    reset: 'إعادة الضبط',
    contract_expiry_date: 'تاريخ انتهاء العقد',
    status: 'الحالة',
    select_status: 'حدد الحالة',
  },
  table: {
    name: 'الإسم',
    ID: 'الرمز',
    mobile: 'الهاتف المحمول',
    email: 'البريد الإلكتروني',
    role: 'الدور الوظيفي',
    teams: 'الفرق',
    supervisor: 'المشرف',
    hourly_rate: 'معدل ساعات العمل',
    last_login: 'آخر تسجيل دخول',
    status: 'الحالة',
    createdBy: 'أنشىء من قبل',
    contract_expiry_date: 'تاريخ انتهاء العقد',
    createdAt: 'أنشىء في',
    updatedAt: "تم التحديث في",
  },

  roles:{
    technician:"فني",
    supervisor:"المشرف",
    "limited admin":'مسؤول إداري محدود',
    admin:"مسؤل",
    requester: 'مقدم الطلب',
    Viewer:"مشاهد",
    "organization admin":"مسؤول المؤسسة الإداري",

  },

  role_options: {
    role_user: 'المستخدم',
    role_admin: 'المسؤول الإداري',
    role_limited_Admin: 'مسؤول إداري محدود',
  },

  status_options: {
    account_activity: 'تفعيل الحساب',
    status_pending: 'قيد الانتظار',
    status_active: 'مفعّل',
    inactive: 'غير مفعّل',
    status_active_account: 'حساب مفعّل',
    inactive_account: 'حساب غير مفعّل',
  },

  studyField: {
    agriculture: 'الزراعة والغابات',
    agricultureUrban: 'الزراعة والغابات',
    arts: 'الفنون والعلوم الإنسانية',
    astronomy: 'علم الفلك والفيزياء الفلكية',
    economics: 'الأعمال والاقتصاد',
    mediaStudy: 'دراسات الاتصال والإعلام',
    cultural: 'الدراسات الثقافية',
    education: 'التعليم',
    engineering: 'الهندسة و التكنولوجيا',
    environmental: 'العلوم البيئية',
    fine_arts: 'الفنون الجميلة والتصميم',
    geology: 'الجيولوجيا وعلوم الأرض',
    health: 'الصحة والطب',
    hospitality: 'الضيافة والسياحة',
    law: 'القانون والدراسات القانونية',
    library: 'علم المكتبات والمعلومات',
    linguistics: 'اللغات',
    mathematics: 'الرياضيات والإحصاء',
    naturalSciences: 'العلوم الطبيعية',
    performingArts: 'الفنون التمثيلية',
    philosophy: 'الفلسفة',
    physicalEducation: 'التربية البدنية وعلوم الرياضة',
    politicalScience: 'العلوم السياسية',
    psychology: 'علم النفس',
    publicAdministration: 'الإدارة العامة',
    religiousStudies: 'الدراسات الدينية',
    socialSciences: 'العلوم الاجتماعية',
    sociology: 'علم الاجتماع',
    computerScience: 'علوم الحاسوب وتكنولوجيا المعلومات',
  },

  add_modal: {
    super_admin:"مسؤول المؤسسة الإداري",
    admin: 'مسؤول إداري',
    admin_description:
      'لديه كامل صلاحيات الوصول إلى النظام بما في ذلك إضافة أو تعديل أو إزالة عناصر النظام.',
    user: 'المستخدم',
    user_description:
      "يتمتع بصلاحيات عرض أوامر العمل وإنشاؤها والعمل عليها وإغلاقها؛ بالإضافة الى حقوق التعديل والحذف الجزئية.",
    technician: 'فني',
    requester: 'مقدم الطلب',
    requester_desc: 'يتمتع بصلاحيات عرض طلبات العمل فقط وعرض حالتها.',
   
   
    technician_description:
      'يقوم بعرض أوامر العمل وإنشائها والعمل عليها وإغلاقها؛ لديه حقوق التحرير والحذف الجزئية',
    viewer: 'مشاهد',
    viewer_description:'يتمتع بصلاحيات عرض محتوى المسؤول الإداري، ولا يمكنه إجراء تغييرات، ولكن يمكنه إرسال الطلبات الخاصة به وتتبعها وإضافة التعليقات.',
    limited_admin: 'مسؤول إداري محدود',
    limited_admin_description:"يتمتع بصلاحيات الوصول الكاملة باستثناء الحذف والتخصيص وتعديل الإعدادات والوصول إلى خطط الاشتراك.",
  },
  personal_info_form: {
    assign:"تكليف أمر العمل",
    add_number_of_dependents: 'اختر عدد المعالين',
    add_firstname: 'أضف الإسم الأول',
    add_lastname: 'أضف اسم العائلة',
    add_phone: ' رقم الهاتف',
    add_email: 'أضف البريد الإلكتروني',
    add_jobtitle: 'اختر المسمى الوظيفي',
    add_location: 'اختر الموقع',
    add_teams: 'اختر الفرق',
    add_hr_rate: 'اختر معدل ساعات العمل',
    add_department: 'اختر القسم',
    add_person: 'إضافة أفراد',
    personal_info: 'المعلومات الشخصية',
    work_info: 'معلومات العمل',
    attachments: 'المرفقات',
    firstname: 'الإسم الأول',
    lastname: 'اسم العائلة',
    phone: 'رقم الهاتف',
    email: 'البريد الإلكتروني',
    address: 'العنوان',
    add_address: 'أضف العنوان',

    gender: 'الجنس',
    gender_options: {
      gender_male: 'ذكر',
      gender_female: 'أنثى',
    },
    martial_status: 'الحالة الاجتماعية',
    martial_option: {
      single: 'أعزب',
      married: 'متزوج',
    },
    number_of_dependents: 'عدد المعالين',
    education_level: 'المستوى التعليمي',
    education_options: {
      high_school: 'شهادة الثانوية',
      diploma: 'درجة الدبلوم',
      bachelors: 'درجة البكالريوس',
      masters: 'درجة الماجستير',
      doctorate: 'درجة الدكتوراه',
      other: 'أخرى',
    },
    nationality: 'الجنسية',
    add_nationality: 'اختر الجنسية',
    add_country_of_birth: 'اختر بلد الولادة',
    add_date_of_birth: 'أضف تاريخ الميلاد',
    add_passport_no: 'أضف رقم جزاز السفر',
    add_identification_no: 'أدخل رقم التعريف',
    add_visa_no: 'أدخل رقم التأشيرة',
    add_visa_expire_date: 'أضف تاريخ انتهاء التأشيرة',
    add_emergency_contact: 'أضف جهة الاتصال في حالة الطوارىء',
    add_filed_of_study: 'اختر مجال الدراسة',
    country_of_birth: 'بلد الولادة ',
    date_of_birth: 'تاريخ الميلاد',
    passport_no: 'رقم جواز السفر',
    identification_no: 'رقم التعريف',
    identification_no: 'رقم التعريف',
    visa_no: 'رقم التأشيرة',
    visa_expire_date: 'تاريخ انتهاء التأشيرة',
    emergency_contact: 'جهة الاتصال في حالة الطوارىء',
    emergency_phone: 'رقم الهاتف',
    children_no: 'عدد الاطفال',
    filed_of_study: 'مجال الدراسة',
  },

  work_info_form: {
    add_supervisor: 'اختر المشرف',
    add_contract_start_date: 'أضف تاريخ بدء العقد',
    add_contract_expiry_date: 'أضف تاريخ انتهاء العقد',
    add_working_hours_day: 'أضف ساعات العمل في اليوم',
    add_working_hours_week:"أضف ساعات العمل في الأسبوع",
    add_registration_no: 'أضف رقم التسجيل',
    add_work_permit_no: 'أضف رقم تصريح العمل',
    add_badge_ID: 'أضف الرمز التعريفي للشارة',
    work_info: 'معلومات العمل',
    job_title: 'المسمى الوظيفي',
    supervisor: 'المشرف',
    teams: 'فرق العمل',
    locations: 'المواقع',
    department: 'القسم',
    hour_rate: 'معدل ساعات العمل',
    working_hours_day: 'ساعات العمل في اليوم',
    working_hours_week: 'ساعات العمل في الأسبوع',
    contract_start_date: 'تاريخ بدء العقد',
    contract_expiry_date: 'تاريخ انتهاء العقد',
    registration_no: 'رقم التسجيل',
    badge_ID: 'الرمز التعريفي للشارة',
    shifts: 'فترات العمل',
    shift_A: 'الفترة A',
    shift_B: 'الفترة B',
    shift_C: 'الفترة C',
    work_permit_no: 'رقم تصريح العمل',
    working_days: 'أيام العمل',
    working_day_options: {
      sat: 'سبت',
      sun: 'أحد',
      mon: 'اثنين',
      tue: 'ثلاثاء',
      wed: 'أربعاء',
      thu: 'خميس',
      fri: 'جمعة',
    },
  },

  attachments_form: {
    attachment_title: 'المرفقات',
    contract_copy: 'نسخة العقد',
    ID_copy_front: 'نسخة الهوية ',
    personal_picture: 'الصورة الشخصية',
    passport_copy: 'نسخة جواز السفر',
    visa_copy: 'نسخة التأشيرة',
    certificates: 'الشهادات',
    submit_btn: 'تقديم الطلب',
    allowed_types: 'الأنواع المسموحة: jpq, jpeg, png, pdf',
  },

  personal_info_form_edit: {
    approved: 'تم الموافقة',
    declined: 'تم الرفض',
    approve_btn: 'الموافقة',
    decline_btn: 'الرفض',
    delete_person_statement: "هل أنت متأكد أنك تريد تعطيل الأفراد؟",
    user_blocked: 'تم حظر هذا المستخدم لأسباب أمنية',
    edit_person: 'تعديل أفراد',
  },

  security_form: {
    deactive: 'تعطيل الحساب',
    activete: 'تفعيل الحساب',
    change_password: 'تغيير كلمة المرور',
    select_one: 'اختر واحدة',
    new_password: 'كلمة مرور جديدة',
    confirm_password: 'تأكيد كلمة المرور',
    re_assign_to: 'إعادة التعيين إلى',
    user_deactivated: 'تم تعطيل المستخدم بنجاح',
    user_activated: 'تم تفعيل المستخدم بنجاح',
    something_wrong: 'حدث خطأ ما. يرجى المحاولة بعد فترة من الوقت',
    select_user: 'يرجى تحديد مستخدم لإعادة التعيين والتعطيل',
    active_account: 'تفعيل الحساب؟',
    deactive_account: 'تعطيل الحساب؟',
    deactive: 'تعطيل الحساب',
    activete: 'تفعيل الحساب',
    unassign: 'إلغاء التكليف وتعطيل',
    reassign: 'إعادة التكليف وتنشيط',
    yes_active: 'نعم، قم بالتنشيط',
    make_active: 'هل أنت متأكد أنك تريد جعل هذا المستخدم نشطًا؟',
    make_deactive: 'هل أنت متأكد أنك تريد تعطيل هذا المستخدم؟',
    already_has_workorder:
      'لديه بالفعل أوامر عمل تحتاج إلى إعادة التعيين لأعضاء الفريق الآخرين قبل أن تتمكن من تعطيله.',
      decline_reason: 'سبب الرفض',
    decline_reason_spacify: "يرجى تحديد سبب رفض هذا المستخدم والتأكيد",
    person_not_deleted: 'لايمكن حذف الافراد مرتبط باشكال اخرى',
      preson_deleted: 'تم حذف الأفراد بنجاح',
  },

  security: 'حماية',
  invalid_email: 'البريد الإلكتروني غير صحيح',
  invalid_phone: 'رقم الهاتف غير صحيح',
  no_people_statement: 'يبدو أنه ليس لديك أشخاص حتى الآن.',
  press_to_add_people: 'اضغط على الزر ' + ' لإضافة الشخص الأول.',
  form_validation_errors: {
    'This email is already exists': 'البريد الإلكتروني موجود بالفعل',
    'This phone number and email already exist':
      'رقم الهاتف موجود',
    'This phone is already exists': 'رقم الهاتف موجود بالفعل',
  },
  add_people: 'ابدء في إضافة الأفراد',
  small_title: 'أفراد',
  small_title_teams: 'الفرق',
  add_team: 'ابدء في إضافة الفرق',
  how_to_add_people: 'كيفية إضافة الأفراد',
  how_to_add_teams: 'كيفية إضافة الفرق',
  activate_deactivate_account: 'تفعيل تعطيل الحساب',
  deactivate_statement: 'هل أنت متأكد أنك تريد إلغاء التنشيط',
  activate_statement: 'هل أنت متأكد أنك تريد التنشيط',
  activate_success_statement: 'تم تفعيل الشخص بنجاح',
  deactivate_success_statement: 'تم إلغاء تنشيط الشخص بنجاح',
  deactivate_related_statement: 'لا يمكن إلغاء تنشيط هذا الشخص',
  activate_related_statement: 'لا يمكن تفعيل هذا الشخص',
  people_team: 'الناس/الفرق',
  people_teams: 'الناس والفرق',
  acc_deactivated: 'تم تعطيل الحساب',
  activate_account: 'تفعيل الحساب',
};
