import { createSlice } from "@reduxjs/toolkit";
import { defaultLang, supportedLangs } from "./config";

import Vocabs from "./vocabs/index";
const initialState = {
  lang: defaultLang, // "en" when app loads
  supportedLangs: { ...supportedLangs },
  // We'll keep our translations in Redux to start.
  // Later on we'll move them to their own files so
  // that they're easier to manage.
  translations: Vocabs,
};

export const i18nSlice = createSlice({
  name: "i18n",
  initialState, 
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const { setLang } = i18nSlice.actions;

export const selectTranslations = (state) =>
  state.i18n.translations[state.i18n.lang];

export default i18nSlice.reducer;
