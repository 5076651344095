import React, { useState, useEffect, createRef } from 'react';
import {
  Card,
  Loader,
  Segment,
  Header,
  Dimmer,
  Modal,
  Dropdown,
  Image,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  List,
  Grid,
  Popup,
} from 'semantic-ui-react';

import { Country, State, City } from 'country-state-city';

import {
  uploadFile,
  country_codes,
  updateUserRole,
  updateUserStatusToPending,
  createNewUser,
  getAllParentLocations,
  getAllTeams,
  getUsersByRole,
  createNewUserProfile,
  validateEmail,
} from '../../../config/functions';
import { at } from 'lodash';

import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';

function AddPeopleModal({ toggled, untoggle, returnAddPeople, title }) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [activePane, setActivePane] = useState(0);
  const [errorPane, setErrorPane] = useState({
    pane0: false,
    pane1: false,
  });
  const t = useSelector(selectTranslations);

  const [buttonStatus, setButtonStatus] = useState(0);
  const [isLoadingActive, activateLoading] = useState(false);
  const [isAsideModalOpen, toggleAsideModalOpen] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [responseData, setResponseData] = useState({});

  const [dropdownOptions, setDropdownOptions] = useState({
    locations: [],
    roles: [
      {
        key: 0,
        value: 6,
        text: 'Admin',
        icon: 'cog',
        description:
          'Full access; including editing, adding and deleting records',
      },
      {
        key: 89,
        value: 9,
        text: 'Supervisor',
        icon: 'handshake outline',
        description: 'Can add orders, assets, locations, people',
      },
      {
        key: 1,
        value: 10,
        text: 'User',
        icon: 'wpforms',
        description:
          'Lorem ipsum is placeholder text commonly used in the graphic',
      },
      {
        key: 2,
        value: 8,
        text: 'Requester only',
        icon: 'phone volume',
        description:
          'Lorem ipsum is placeholder text commonly used in the graphic',
      },
      {
        key: 3,
        value: 12,
        text: 'Viewer only',
        icon: 'sitemap',
        description:
          'Lorem ipsum is placeholder text commonly used in the graphic',
      },
    ],
    martialStatus: [
      {
        key: 0,
        value: 'single',
        text: 'Single',
      },

      {
        key: 1,
        value: 'married',
        text: 'Married',
      },
    ],

    educationLevel: [
      {
        key: 0,
        value: 'highSchoolDegree',
        text: 'High School Degree',
      },
      {
        key: 1,
        value: 'diplomaDegree',
        text: 'Diploma Degree',
      },
      {
        key: 2,
        value: 'bachelorsDegree',
        text: 'Bachelors Degree',
      },
      {
        key: 3,
        value: 'magisterDegree',
        text: 'Magister Degree',
      },
      {
        key: 4,
        value: 'doctorateDegree',
        text: 'Doctorate Degree',
      },
      {
        key: 5,
        value: 'other',
        text: 'Other',
      },
    ],
    nationalities: [],

    gender: [
      {
        key: 0,
        value: 'male',
        text: 'Male',
      },

      {
        key: 1,
        value: 'female',
        text: 'Female',
      },
    ],

    users: [],
    teams: [],
  });
  const [data, setData] = useState({
    locations: [],
    teams: [],
    hourRate: '',
    whpd: '',
    whpw: '',
    department: '',
    contractStart: moment().format('YYYY-MM-DD'),
    contractEnd: moment().format('YYYY-MM-DD'),
    shifts: {
      shiftA: false,
      shiftB: false,
      shiftC: false,
    },
    days: {
      sat: false,
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
    },
    firstName: title,
    lastName: '',
    username: '',
    address: '',
    email: '',
    countryCode: '962',
    phone: '',
    martialStatus: 'single',
    emergencyName: '',
    emergencyCountryCode: '962',
    emergencyPhone: '',
    educationLevel: 'highSchoolDegree',
    fieldOfStudy: '',
    nationality: '',
    identificationNo: '',
    passportNo: '',
    gender: 'male',
    dob: moment().format('YYYY-MM-DD'),
    cob: '',
    childrenNo: '',
    visaNo: '',
    workPermitNo: '',
    visaExpireDate: moment().format('YYYY-MM-DD'),
    supervisor: null,
    jobTitle: '',
    role: '',
    registrationNo: '',
    badgeId: '',
  });

  useEffect(() => {
    setData({ ...data, firstName: title });
  }, [title]);

  const [attachments, setAttachments] = useState({
    contractCopy: null,
    personalPicture: null,
    passportCopy: null,
    idCopyFront: null,
    visaCopy: null,
    covidCopy: null,
  });

  const [validation, setValidation] = useState({
    whpd: {
      error: false,
      pattern: (whpd) => whpd != '',
      skipped: false,
      message: t.common.required_field,
    },
    whpw: {
      error: false,
      pattern: (whpw) => whpw != '',
      skipped: false,
      message: t.common.required_field,
    },

    contractStart: {
      error: false,
      pattern: (contractStart) =>
        moment(contractStart).format('YYYY-MM-DD') ===
          moment(contractStart).format('YYYY-MM-DD') || contractStart != '',
      skipped: false,
      message: 'Invalid date',
    },
    contractEnd: {
      error: false,
      pattern: (contractEnd) =>
        moment(contractEnd).format('YYYY-MM-DD') ===
          moment(contractEnd).format('YYYY-MM-DD') || contractEnd != '',
      skipped: false,
      message: 'Invalid date',
    },
    firstName: {
      error: false,
      pattern: (firstName) => firstName != '',
      skipped: false,
      message: t.common.required_field,
    },
    lastName: {
      error: false,
      pattern: (lastName) => lastName != '',
      skipped: false,
      message: t.common.required_field,
    },
    username: {
      error: false,
      pattern: (username) => username != '',
      skipped: false,
      message: t.common.required_field,
    },
    email: {
      error: false,
      pattern: (email) => validateEmail(email),
      skipped: false,
      message: t.common.invalid_email,
    },

    phone: {
      error: false,
      pattern: (phone) => phone != '',
      skipped: false,
      message: t.common.required_field,
    },

    dob: {
      error: false,
      pattern: (dob) =>
        moment(dob).format('YYYY-MM-DD') === moment(dob).format('YYYY-MM-DD') ||
        dob != '',
      skipped: false,
      message: 'Invalid date',
    },

    jobTitle: {
      error: false,
      pattern: (title) => title != '',
      skipped: false,
      message: t.common.required_field,
    },
    identificationNo: {
      error: false,
      pattern: (num) => num != '',
      skipped: false,
      message: t.common.required_field,
    },

    shifts: {
      error: false,
      pattern: (shifts) => shifts.some((el) => el != false),
      skipped: false,
      message: t.common.required_field,
    },

    days: {
      error: false,
      pattern: (days) => days.some((el) => el != false),
      skipped: false,
      message: t.common.required_field,
    },
    hourRate: {
      error: false,
      pattern: (hourRate) => hourRate != '',
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      locations: [],
      teams: [],
      hourRate: '',
      whpd: '',
      whpw: '',
      department: '',
      contractStart: moment().format('YYYY-MM-DD'),
      contractEnd: moment().format('YYYY-MM-DD'),
      shifts: {
        shiftA: false,
        shiftB: false,
        shiftC: false,
      },
      days: {
        sat: false,
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
      },
      firstName: '',
      lastName: '',
      username: '',
      address: '',
      email: '',
      countryCode: '962',
      phone: '',
      martialStatus: 'single',
      emergencyName: '',
      emergencyCountryCode: '962',
      emergencyPhone: '',
      educationLevel: 'highSchoolDegree',
      fieldOfStudy: '',
      nationality: '',
      identificationNo: '',
      passportNo: '',
      gender: 'male',
      dob: moment().format('YYYY-MM-DD'),
      cob: '',
      childrenNo: '',
      visaNo: '',
      workPermitNo: '',
      visaExpireDate: moment().format('YYYY-MM-DD'),
      supervisor: null,
      jobTitle: '',
      role: '',
      registrationNo: '',
      badgeId: '',
    });

    setAttachments({
      contractCopy: null,
      personalPicture: null,
      passportCopy: null,
      idCopyFront: null,
      visaCopy: null,
      covidCopy: null,
    });

    setActivePane(0);

    setValidation({
      whpd: {
        error: false,
        pattern: (whpd) => whpd != '',
        skipped: false,
        message: t.common.required_field,
      },
      whpw: {
        error: false,
        pattern: (whpw) => whpw != '',
        skipped: false,
        message: t.common.required_field,
      },

      contractStart: {
        error: false,
        pattern: (contractStart) =>
          moment(contractStart).format('YYYY-MM-DD') ===
            moment(contractStart).format('YYYY-MM-DD') || contractStart != '',
        skipped: false,
        message: 'Invalid date',
      },
      contractEnd: {
        error: false,
        pattern: (contractEnd) =>
          moment(contractEnd).format('YYYY-MM-DD') ===
            moment(contractEnd).format('YYYY-MM-DD') || contractEnd != '',
        skipped: false,
        message: 'Invalid date',
      },
      firstName: {
        error: false,
        pattern: (firstName) => firstName != '',
        skipped: false,
        message: t.common.required_field,
      },
      lastName: {
        error: false,
        pattern: (lastName) => lastName != '',
        skipped: false,
        message: t.common.required_field,
      },
      username: {
        error: false,
        pattern: (username) => username != '',
        skipped: false,
        message: t.common.required_field,
      },
      email: {
        error: false,
        pattern: (email) => validateEmail(email),
        skipped: false,
        message: t.common.invalid_email,
      },

      phone: {
        error: false,
        pattern: (phone) => phone != '',
        skipped: false,
        message: t.common.required_field,
      },

      dob: {
        error: false,
        pattern: (dob) =>
          moment(dob).format('YYYY-MM-DD') ===
            moment(dob).format('YYYY-MM-DD') || dob != '',
        skipped: false,
        message: 'Invalid date',
      },

      jobTitle: {
        error: false,
        pattern: (title) => title != '',
        skipped: false,
        message: t.common.required_field,
      },
      identificationNo: {
        error: false,
        pattern: (num) => num != '',
        skipped: false,
        message: t.common.required_field,
      },

      shifts: {
        error: false,
        pattern: (shifts) => shifts.some((el) => el != false),
        skipped: false,
        message: t.common.required_field,
      },

      days: {
        error: false,
        pattern: (days) => days.some((el) => el != false),
        skipped: false,
        message: t.common.required_field,
      },
      hourRate: {
        error: false,
        pattern: (hourRate) => hourRate != '',
        skipped: false,
        message: t.common.required_field,
      },
    });

    setErrorPane({
      pane0: false,
      pane1: false,
    });
    setInitChange(false);
  };

  // const [attachmentsResponse, setAttachmentsResponse] = useState([]);
  const contractCopyRef = createRef();
  const personalPictureRef = createRef();
  const passportCopyRef = createRef();
  const idCopyFrontRef = createRef();
  const visaCopyRef = createRef();
  const covidCopyRef = createRef();

  const fetchLocations = async () => {
    const fetchData = await getAllParentLocations(organisationId,"",1,99999)
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        locations: the_options,
      });
    }
  };

  const fetchCountries = () => {
    const fetchData = Country.getAllCountries();
    let the_options = [];
    if (fetchData) {
      the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });

      setDropdownOptions({ ...dropdownOptions, nationalities: the_options });
    }
  };

  const fetchUsersData = async () => {
    const fetchData = await getUsersByRole(['supervisor'], organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName ? el.firstName : el.username,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        users: the_options,
      });
    }
  };

  const fetchTeamsData = async () => {
    const fetchData = await getAllTeams(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        teams: the_options,
      });
    }
  };

  const onFileChange = (e, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: e.target.files[0],
        id: randomnumber,
        name: e.target.files[0].name,
        type: e.target.files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const boolean_fields = ['hasWorkPermit'];
    const dropdown_fields = [
      'locations',
      'martialStatus',
      'educationLevel',
      'countryCode',
      'emergencyCountryCode',
      'nationality',
      'gender',
      'supervisor',
      'role',
      'cob',
    ];

    // console.log(data.role)

    if (!boolean_fields.includes(name) || !dropdown_fields.includes(name))
      setData({
        ...data,
        [name]: event.target.value,
      });

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }
  };

  const validationHandler = () => {
    setValidForm(false);
    const shiftsArray = [
      data.shifts.shiftA,
      data.shifts.shiftB,
      data.shifts.shiftC,
    ];

    const daysArray = [
      data.days.sat,
      data.days.sun,
      data.days.mon,
      data.days.tue,
      data.days.wed,
      data.days.thu,
      data.days.fri,
    ];

    const pane0Errors = [
      validation.firstName.pattern(data.firstName),
      validation.lastName.pattern(data.lastName),
      validation.username.pattern(data.username),
      validation.email.pattern(data.email),
      validation.phone.pattern(data.phone),
      validation.identificationNo.pattern(data.identificationNo),
      validation.dob.pattern(data.dob),
    ];

    const pane1Errors = [
      validation.jobTitle.pattern(data.jobTitle),
      validation.hourRate.pattern(data.hourRate),
      validation.whpd.pattern(data.whpd),
      validation.whpw.pattern(data.whpw),
      validation.contractStart.pattern(data.contractStart),
      validation.contractEnd.pattern(data.contractEnd),
      validation.days.pattern(daysArray),
      validation.shifts.pattern(shiftsArray),
    ];

    setErrorPane({
      ...errorPane,
      pane0: pane0Errors.includes(false),
      pane1: pane1Errors.includes(false),
    });

    setValidation({
      ...validation,
      whpd: {
        ...validation.whpd,
        error: validation.whpd.pattern(data.whpd) ? false : true,
        skipped: true,
      },
      whpw: {
        ...validation.whpw,
        error: validation.whpw.pattern(data.whpw) ? false : true,
        skipped: true,
      },

      contractStart: {
        ...validation.contractStart,
        error: validation.contractStart.pattern(data.contractStart)
          ? false
          : true,
        skipped: true,
      },
      contractEnd: {
        ...validation.contractEnd,
        error: validation.contractEnd.pattern(data.contractEnd) ? false : true,
        skipped: true,
      },

      firstName: {
        ...validation.firstName,
        error: validation.firstName.pattern(data.firstName) ? false : true,
        skipped: true,
      },
      lastName: {
        ...validation.lastName,
        error: validation.lastName.pattern(data.lastName) ? false : true,
        skipped: true,
      },
      username: {
        ...validation.username,
        error: validation.username.pattern(data.username) ? false : true,
        skipped: true,
      },
      email: {
        ...validation.email,
        error: validation.email.pattern(data.email) ? false : true,
        skipped: true,
      },

      phone: {
        ...validation.phone,
        error: validation.phone.pattern(data.phone) ? false : true,
        skipped: true,
      },

      dob: {
        ...validation.dob,
        error: validation.dob.pattern(data.dob) ? false : true,
        skipped: true,
      },

      jobTitle: {
        ...validation.jobTitle,
        error: validation.jobTitle.pattern(data.jobTitle) ? false : true,
        skipped: true,
      },
      identificationNo: {
        ...validation.identificationNo,
        error: validation.identificationNo.pattern(data.identificationNo)
          ? false
          : true,
        skipped: true,
      },

      shifts: {
        ...validation.shifts,
        error: validation.shifts.pattern(shiftsArray) ? false : true,
        skipped: true,
      },

      days: {
        ...validation.days,
        error: validation.days.pattern(daysArray) ? false : true,
        skipped: true,
      },

      identificationNo: {
        ...validation.hourRate,
        error: validation.hourRate.pattern(data.hourRate) ? false : true,
        skipped: true,
      },
    });

    return validForm;
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log(validationToArray);

    if (initChange) {
      setValidForm(!cond);
      setErrorPane({
        ...errorPane,
        pane0: cond,
        pane1: cond,
      });
    }
    // console.log(data);
  }, [validation, initChange]);

  const createNewUserHandler = async () => {
    const checkValidForm = validationHandler();
    // console.log(checkValidForm)
    if (checkValidForm) {
      setButtonStatus(1);

      const contractCopyResponse =
        attachments.contractCopy && attachments.contractCopy.isChanged
          ? await uploadFile([attachments.contractCopy.file])
          : null;

      const personalPictureResponse =
        attachments.personalPicture && attachments.personalPicture.isChanged
          ? await uploadFile([attachments.personalPicture.file])
          : null;

      const passportCopyResponse =
        attachments.passportCopy && attachments.passportCopy.isChanged
          ? await uploadFile([attachments.passportCopy.file])
          : null;
      const idFrontCopyResponse =
        attachments.idCopyFront && attachments.idCopyFront.isChanged
          ? await uploadFile([attachments.idCopyFront.file])
          : null;

      const visaCopyResponse =
        attachments.visaCopy && attachments.visaCopy.isChanged
          ? await uploadFile([attachments.visaCopy.file])
          : null;
      const covidCopyResponse =
        attachments.covidCopy && attachments.covidCopy.isChanged
          ? await uploadFile([attachments.covidCopy.file])
          : null;

      const response = await createNewUser(
        data.role,
        data.username,
        data.email,
        data.teams,
        data.firstName,
        data.lastName,
        data.supervisor,
        data.locations,
        organisationId
      );

      if (response.status == 200) {
        // console.log(response.data);
        setResponseData({
          id: response.data.user.id,
          name: response.data.user.firstName,
        });

        const roleUpdated = await updateUserRole(
          data.role,
          response.data.user.id,
          organisationId
        );

        if (roleUpdated.status == 200) {
          const pendingUser = await updateUserStatusToPending(
            response.data.user.id
          );

          if (pendingUser.status == 200) {
            createUserProfileHandler(
              response.data.user.id,

              contractCopyResponse?.status == 200
                ? personalPictureResponse.data[0].id
                : null,
              personalPictureResponse?.status == 200
                ? personalPictureResponse.data[0].id
                : null,
              passportCopyResponse?.status == 200
                ? passportCopyResponse.data[0].id
                : null,
              idFrontCopyResponse?.status == 200
                ? idFrontCopyResponse.data[0].id
                : null,

              visaCopyResponse?.status == 200
                ? visaCopyResponse.data[0].id
                : null,
              covidCopyResponse?.status == 200
                ? covidCopyResponse.data[0].id
                : null
            );
          }
        }
      }
    }
  };

  const createUserProfileHandler = async (
    id,
    contractCopy,
    personalPicture,
    passportCopy,
    idCopyFront,
    visaCopy,
    covidCopy
  ) => {
    const response = await createNewUserProfile(
      id,
      Number(data.countryCode + data.phone),
      String(data.address),
      String(data.emergencyName),
      Number(data.emergencyCountryCode + data.emergencyPhone),
      String(data.educationLevel),
      String(data.fieldOfStudy),
      String(data.martialStatus),
      String(data.nationality),
      String(data.passportNo),
      String(data.identificationNo),
      moment(data.dob).format('YYYY-MM-DD'),
      String(data.cob),
      String(data.gender),
      Number(data.childrenNo),
      String(data.visaNo),
      moment(data.visaExpireDate).format('YYYY-MM-DD'),
      String(data.jobTitle),
      String(data.department),
      Number(data.hourRate),
      Number(data.whpd),
      Number(data.whpw),
      moment(data.contractStart).format('YYYY-MM-DD'),
      moment(data.contractEnd).format('YYYY-MM-DD'),
      Number(data.registrationNo),
      String(data.badgeId),
      data.shifts,
      String(data.workPermitNo),
      data.days,
      contractCopy,
      idCopyFront,
      personalPicture,
      passportCopy,
      visaCopy,
      covidCopy
    );

    if (response.status == 200) {
      setButtonStatus(0);
      cancelHandler();
      returnAddPeople(id);
    }
  };

  const cancelHandler = () => {
    resetForm();
    toggleAsideModalOpen(false);
    toggleCancelModal(false);
    activateLoading(false);
    setInitChange(false);
  };

  useEffect(() => {
    setData({
      ...data,
      username: (data?.firstName + '_' + data?.phone).toLowerCase(),
      email: (
        data?.firstName +
        '_' +
        data?.phone +
        '@quiqflow.com'
      ).toLocaleLowerCase(),
    });
  }, [data?.firstName, data?.phone]);

  return (
    <>
      <Modal
        size="tiny"
        open={toggled}
        onClose={() => {
          untoggle();
          cancelHandler();
        }}
      >
        <Modal.Content scrolling>
          <List>
            {isLoadingActive ? (
              <Dimmer active inverted>
                <Loader inverted content="Loading" />
              </Dimmer>
            ) : (
              ''
            )}

            {dropdownOptions.roles.map((el) => {
              return (
                <Segment
                  key={el.key}
                  id={el.value}
                  className="flexbox"
                  style={
                    data.role == el.value
                      ? { background: '#e2f5fc', cursor: 'pointer' }
                      : { cursor: 'pointer' }
                  }
                  onClick={() => {
                    setData({
                      ...data,
                      role: el.value,
                    });
                  }}
                >
                  <Icon name={el.icon} size="big" color="black" />
                  <div style={{ marginLeft: '10px' }}>
                    <Header style={{ background: 'transparent' }}>
                      {el.text}
                    </Header>
                    <p>{el.description}</p>
                  </div>
                </Segment>
              );
            })}
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={!data.role}
            onClick={() => {
              activateLoading(true);
              setTimeout(() => {
                untoggle(false);
                toggleAsideModalOpen(true);
              }, 1000);
            }}
          >
            Next
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="small"
        open={isAsideModalOpen}
        onClose={() => {
          untoggle();
        }}
      >
        <Modal.Header>Add Person</Modal.Header>
        <Modal.Content scrolling>
          <Button.Group basic fluid size="medium">
            <Button
              active={activePane == 0}
              onClick={() => setActivePane(0)}
              negative={errorPane.pane0}
            >
              <Icon name="id card" color="black" />
              Personal Information
            </Button>
            <Button
              active={activePane == 1}
              onClick={() => setActivePane(1)}
              negative={errorPane.pane1}
            >
              <Icon name="cogs" color="black" />
              Work Information
            </Button>

            <Button active={activePane == 2} onClick={() => setActivePane(2)}>
              <Icon name="attach" color="black" />
              Attachments
            </Button>
          </Button.Group>
          <Divider />
          <div style={{ display: activePane == 0 ? 'block' : 'none' }}>
            <Grid columns="equal">
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">
                    First Name
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="First Name"
                    value={data.firstName}
                    onChange={onValuesChange('firstName')}
                    error={
                      validation.firstName.error && validation.firstName.skipped
                    }
                    onBlur={(e) => {
                      if (validation.firstName.pattern(data.firstName)) {
                        setValidation({
                          ...validation,
                          firstName: {
                            ...validation.firstName,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          firstName: {
                            ...validation.firstName,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.firstName.error &&
                  validation.firstName.skipped ? (
                    <div className="label-error">
                      {validation.firstName.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Username
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Username"
                    value={data.username}
                    disabled={[10].includes(data.role)}
                    onChange={onValuesChange('username')}
                    error={
                      validation.username.error && validation.username.skipped
                    }
                    onBlur={(e) => {
                      if (validation.username.pattern(data.username)) {
                        setValidation({
                          ...validation,
                          username: {
                            ...validation.username,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          username: {
                            ...validation.username,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.username.error && validation.username.skipped ? (
                    <div className="label-error">
                      {validation.username.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Phone
                    <Icon name="asterisk" color="red" size="small" />
                  </label>

                  <Input
                    autoComplete="new-password"
                    label={
                      <Dropdown
                        button
                        basic
                        search
                        defaultValue="962"
                        options={country_codes}
                        onChange={onValuesChange('countryCode')}
                      />
                    }
                    type="number"
                    fluid
                    placeholder="Phone"
                    value={data.phone}
                    onChange={onValuesChange('phone')}
                    error={validation.phone.error && validation.phone.skipped}
                    onBlur={(e) => {
                      if (validation.phone.pattern(data.phone)) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.phone.error && validation.phone.skipped ? (
                    <div className="label-error">
                      {validation.phone.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Emergency Name</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Emergency Name"
                    value={data.emergencyName}
                    onChange={onValuesChange('emergencyName')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Education Level</label>
                  <Dropdown
                    placeholder="Education Level"
                    fluid
                    selection
                    options={dropdownOptions.educationLevel}
                    value={data.educationLevel}
                    onChange={onValuesChange('educationLevel')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Martial Status</label>
                  <Dropdown
                    placeholder="Martial Status"
                    selection
                    fluid
                    options={dropdownOptions.martialStatus}
                    value={data.martialStatus}
                    onChange={onValuesChange('martialStatus')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Passport No</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Passport No"
                    value={data.passportNo}
                    onChange={onValuesChange('passportNo')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Date of birth
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="date"
                    fluid
                    placeholder="Date of birth"
                    value={data.dob}
                    onChange={onValuesChange('dob')}
                    error={validation.dob.error && validation.dob.skipped}
                    onBlur={(e) => {
                      if (validation.dob.pattern(data.dob)) {
                        setValidation({
                          ...validation,
                          dob: {
                            ...validation.dob,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          dob: {
                            ...validation.dob,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.dob.error && validation.dob.skipped ? (
                    <div className="label-error">{validation.dob.message}</div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Gender</label>
                  <Dropdown
                    placeholder="Gender"
                    fluid
                    selection
                    options={dropdownOptions.gender}
                    value={data.gender}
                    onChange={onValuesChange('gender')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Visa No</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Visa No"
                    value={data.visaNo}
                    onChange={onValuesChange('visaNo')}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">
                    Last Name
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Last Name"
                    value={data.lastName}
                    onChange={onValuesChange('lastName')}
                    error={
                      validation.lastName.error && validation.lastName.skipped
                    }
                    onBlur={(e) => {
                      if (validation.lastName.pattern(data.lastName)) {
                        setValidation({
                          ...validation,
                          lastName: {
                            ...validation.lastName,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          lastName: {
                            ...validation.lastName,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.lastName.error && validation.lastName.skipped ? (
                    <div className="label-error">
                      {validation.lastName.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>
                <Form.Field>
                  <label className="label-item">
                    Email
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="email"
                    fluid
                    placeholder="Working hours per day"
                    value={data.email}
                    disabled={[10].includes(data.role)}
                    onChange={onValuesChange('email')}
                    error={validation.email.error && validation.email.skipped}
                    onBlur={(e) => {
                      if (validation.email.pattern(data.email)) {
                        setValidation({
                          ...validation,
                          email: {
                            ...validation.email,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          email: {
                            ...validation.email,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.email.error && validation.email.skipped ? (
                    <div className="label-error">
                      {validation.email.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Address</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Address"
                    value={data.address}
                    onChange={onValuesChange('address')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Emergency Phone</label>

                  <Input
                    autoComplete="new-password"
                    label={
                      <Dropdown
                        button
                        basic
                        search
                        defaultValue="962"
                        options={country_codes}
                        onChange={onValuesChange('emergencyCountryCode')}
                      />
                    }
                    type="number"
                    fluid
                    placeholder="Emergency Phone"
                    value={data.emergencyPhone}
                    onChange={onValuesChange('emergencyPhone')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Field of study</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Field of study"
                    value={data.fieldOfStudy}
                    onChange={onValuesChange('fieldOfStudy')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Nationality</label>
                  <Dropdown
                    placeholder="Nationality"
                    fluid
                    search
                    selection
                    options={dropdownOptions.nationalities}
                    value={data.nationality}
                    onChange={onValuesChange('nationality')}
                    onOpen={() => fetchCountries()}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Identification No
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Identification No"
                    value={data.identificationNo}
                    onChange={onValuesChange('identificationNo')}
                    error={
                      validation.identificationNo.error &&
                      validation.identificationNo.skipped
                    }
                    onBlur={(e) => {
                      if (
                        validation.identificationNo.pattern(
                          data.identificationNo
                        )
                      ) {
                        setValidation({
                          ...validation,
                          identificationNo: {
                            ...validation.identificationNo,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          identificationNo: {
                            ...validation.identificationNo,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.identificationNo.error &&
                  validation.identificationNo.skipped ? (
                    <div className="label-error">
                      {validation.identificationNo.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Country of birth</label>
                  <Dropdown
                    placeholder="Country of birth"
                    fluid
                    search
                    selection
                    options={dropdownOptions.nationalities}
                    value={data.cob}
                    onChange={onValuesChange('cob')}
                    onOpen={() => fetchCountries()}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Children No</label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    placeholder="Number of children"
                    value={data.childrenNo}
                    onChange={onValuesChange('childrenNo')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Visa Expire Date</label>
                  <Input
                    autoComplete="new-password"
                    type="date"
                    fluid
                    placeholder="Visa Expire Date"
                    value={data.visaExpireDate}
                    onChange={onValuesChange('visaExpireDate')}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
          </div>
          <div style={{ display: activePane == 1 ? 'block' : 'none' }}>
            <Grid columns="equal">
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">
                    Job Title
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Job Title"
                    value={data.jobTitle}
                    onChange={onValuesChange('jobTitle')}
                    error={
                      validation.jobTitle.error && validation.jobTitle.skipped
                    }
                    onBlur={(e) => {
                      if (validation.jobTitle.pattern(data.jobTitle)) {
                        setValidation({
                          ...validation,
                          jobTitle: {
                            ...validation.jobTitle,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          jobTitle: {
                            ...validation.jobTitle,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.jobTitle.error && validation.jobTitle.skipped ? (
                    <div className="label-error">
                      {validation.jobTitle.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Teams</label>
                  <Dropdown
                    placeholder="Teams"
                    fluid
                    search
                    selection
                    multiple
                    options={dropdownOptions.teams}
                    value={data.teams}
                    onChange={onValuesChange('teams')}
                    onOpen={() => fetchTeamsData()}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Department</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Department"
                    value={data.department}
                    onChange={onValuesChange('department')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Working hours per day
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    placeholder="Working hours per day"
                    value={data.whpd}
                    onChange={onValuesChange('whpd')}
                    error={validation.whpd.error && validation.whpd.skipped}
                    onBlur={(e) => {
                      if (validation.whpd.pattern(data.whpd)) {
                        setValidation({
                          ...validation,
                          whpd: {
                            ...validation.whpd,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          whpd: {
                            ...validation.whpd,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.whpd.error && validation.whpd.skipped ? (
                    <div className="label-error">{validation.whpd.message}</div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Contract Start Date
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="date"
                    fluid
                    placeholder="Contract Start Date"
                    value={data.contractStart}
                    onChange={onValuesChange('contractStart')}
                    error={
                      validation.contractStart.error &&
                      validation.contractStart.skipped
                    }
                    onBlur={(e) => {
                      if (
                        validation.contractStart.pattern(data.contractStart)
                      ) {
                        setValidation({
                          ...validation,
                          contractStart: {
                            ...validation.contractStart,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          contractStart: {
                            ...validation.contractStart,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.contractStart.error &&
                  validation.contractStart.skipped ? (
                    <div className="label-error">
                      {validation.contractStart.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Registration No</label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    placeholder="Registration No"
                    value={data.registrationNo}
                    onChange={onValuesChange('registrationNo')}
                  />
                </Form.Field>

                <Form.Field
                  error={validation.shifts.error && validation.shifts.skipped}
                >
                  <label className="label-item">
                    Shifts
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Button.Group basic>
                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          shifts: {
                            ...data.shifts,
                            shiftA: !data.shifts.shiftA,
                          },
                        });

                        // console.log(data);
                      }}
                      className={data.shifts.shiftA ? 'active' : ''}
                    >
                      Shift A
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          shifts: {
                            ...data.shifts,
                            shiftB: !data.shifts.shiftB,
                          },
                        });
                      }}
                      className={data.shifts.shiftB ? 'active' : ''}
                    >
                      Shift B
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          shifts: {
                            ...data.shifts,
                            shiftC: !data.shifts.shiftC,
                          },
                        });
                      }}
                      className={data.shifts.shiftC ? 'active' : ''}
                    >
                      Shift C
                    </Button>
                  </Button.Group>

                  {validation.shifts.error && validation.shifts.skipped ? (
                    <div className="label-error">
                      {validation.shifts.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">Supervisor</label>
                  <Dropdown
                    placeholder="Supervisor"
                    fluid
                    search
                    selection
                    options={dropdownOptions.users}
                    value={data.supervisor}
                    onChange={onValuesChange('supervisor')}
                    onOpen={() => fetchUsersData()}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Locations</label>
                  <Dropdown
                    placeholder="Locations"
                    fluid
                    search
                    selection
                    multiple
                    options={dropdownOptions.locations}
                    value={data.locations}
                    onChange={onValuesChange('locations')}
                    onOpen={() => fetchLocations()}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Hour Rate
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    placeholder="Hour Rate"
                    value={data.hourRate}
                    onChange={onValuesChange('hourRate')}
                    error={
                      validation.hourRate.error && validation.hourRate.skipped
                    }
                    onBlur={(e) => {
                      if (validation.hourRate.pattern(data.hourRate)) {
                        setValidation({
                          ...validation,
                          hourRate: {
                            ...validation.hourRate,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          hourRate: {
                            ...validation.hourRate,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.hourRate.error && validation.hourRate.skipped ? (
                    <div className="label-error">
                      {validation.hourRate.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Working hours per week
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="number"
                    fluid
                    placeholder="Working hours per week"
                    value={data.whpw}
                    onChange={onValuesChange('whpw')}
                    error={validation.whpw.error && validation.whpw.skipped}
                    onBlur={(e) => {
                      if (validation.whpw.pattern(data.whpw)) {
                        setValidation({
                          ...validation,
                          whpw: {
                            ...validation.whpw,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          whpw: {
                            ...validation.whpw,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.whpw.error && validation.whpw.skipped ? (
                    <div className="label-error">{validation.whpw.message}</div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Contract Expiration Date
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    type="date"
                    fluid
                    placeholder="Contract Expiration Date"
                    value={data.contractEnd}
                    onChange={onValuesChange('contractEnd')}
                    error={
                      validation.contractEnd.error &&
                      validation.contractEnd.skipped
                    }
                    onBlur={(e) => {
                      if (validation.contractEnd.pattern(data.contractEnd)) {
                        setValidation({
                          ...validation,
                          contractEnd: {
                            ...validation.contractEnd,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          contractEnd: {
                            ...validation.contractEnd,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.contractEnd.error &&
                  validation.contractEnd.skipped ? (
                    <div className="label-error">
                      {validation.contractEnd.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Badge ID</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Badge ID"
                    value={data.badgeId}
                    onChange={onValuesChange('badgeId')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Work permit No</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder="Work permit No"
                    value={data.workPermitNo}
                    onChange={onValuesChange('workPermitNo')}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid columns="equal">
              <Grid.Column>
                <Form.Field
                  error={validation.days.error && validation.days.skipped}
                >
                  <label className="label-item">
                    Working Days
                    <Icon name="asterisk" color="red" size="small" />
                  </label>
                  <Button.Group basic>
                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            sat: !data.days.sat,
                          },
                        });
                      }}
                      className={data.days.sat ? 'active' : ''}
                    >
                      Sat
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            sun: !data.days.sun,
                          },
                        });
                      }}
                      className={data.days.sun ? 'active' : ''}
                    >
                      Sun
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            mon: !data.days.mon,
                          },
                        });
                      }}
                      className={data.days.mon ? 'active' : ''}
                    >
                      Mon
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            tue: !data.days.tue,
                          },
                        });
                      }}
                      className={data.days.tue ? 'active' : ''}
                    >
                      Tue
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            wed: !data.days.wed,
                          },
                        });
                      }}
                      className={data.days.wed ? 'active' : ''}
                    >
                      Wed
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            thu: !data.days.thu,
                          },
                        });
                      }}
                      className={data.days.thu ? 'active' : ''}
                    >
                      Thu
                    </Button>

                    <Button
                      onClick={() => {
                        setData({
                          ...data,
                          days: {
                            ...data.days,
                            fri: !data.days.fri,
                          },
                        });
                      }}
                      className={data.days.fri ? 'active' : ''}
                    >
                      Fri
                    </Button>
                  </Button.Group>

                  {validation.days.error && validation.days.skipped ? (
                    <div className="label-error">{validation.days.message}</div>
                  ) : (
                    ''
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid>
          </div>
          <div style={{ display: activePane == 2 ? 'block' : 'none' }}>
            <Grid columns="equal">
              <Grid.Column>
                <Form.Field>
                  <label className="label-item">Contract Copy</label>
                  {attachments?.contractCopy ? (
                    <List divided relaxed>
                      <Popup
                        key={attachments?.contractCopy?.id}
                        content="Remove file?"
                        on="click"
                        pinned
                        open={attachments?.contractCopy?.cancelPopup}
                        trigger={
                          <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let currentFile = attachments?.contractCopy;
                              currentFile.cancelPopup = !currentFile.cancelPopup;
                              setAttachments({
                                ...attachments,
                                contractCopy: currentFile,
                              });
                            }}
                          >
                            <List.Icon
                              name={
                                attachments?.contractCopy?.type.match('image.*')
                                  ? 'image'
                                  : attachments?.contractCopy?.type.match('pdf')
                                  ? 'file pdf'
                                  : 'file'
                              }
                              size="large"
                              color="black"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {attachments?.contractCopy?.name}
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        }
                      >
                        <Header as="h4">Remove file?</Header>
                        <Button
                          color="black"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentFile = attachments?.contractCopy;
                            currentFile.cancelPopup = false;
                            setAttachments({
                              ...attachments,
                              contractCopy: currentFile,
                            });
                          }}
                        >
                          Keep
                        </Button>
                        <Button
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttachments({
                              ...attachments,
                              contractCopy: null,
                            });
                          }}
                        >
                          Yes, remove
                        </Button>
                      </Popup>
                    </List>
                  ) : (
                    <div>
                      <input
                        ref={contractCopyRef}
                        type="file"
                        accept="application/pdf, image/*"
                        hidden
                        onChange={(e) => onFileChange(e, 'contractCopy')}
                      />
                      <div
                        className="buttun-type-link"
                        onClick={() => contractCopyRef.current.click()}
                      >
                        <Icon name="add" />
                        Add
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#7e7e7e',
                        }}
                      >
                        Allowed types: jpg, jpeg, png, pdf
                      </p>
                    </div>
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">Personal Picture</label>
                  {attachments?.personalPicture ? (
                    <List divided relaxed>
                      <Popup
                        key={attachments?.personalPicture?.id}
                        content="Remove file?"
                        on="click"
                        pinned
                        open={attachments?.personalPicture?.cancelPopup}
                        trigger={
                          <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let currentFile = attachments?.personalPicture;
                              currentFile.cancelPopup = !currentFile.cancelPopup;
                              setAttachments({
                                ...attachments,
                                personalPicture: currentFile,
                              });
                            }}
                          >
                            <List.Icon
                              name={
                                attachments?.personalPicture?.type.match(
                                  'image.*'
                                )
                                  ? 'image'
                                  : attachments?.personalPicture?.type.match(
                                      'pdf'
                                    )
                                  ? 'file pdf'
                                  : 'file'
                              }
                              size="large"
                              color="black"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {attachments?.personalPicture?.name}
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        }
                      >
                        <Header as="h4">Remove file?</Header>
                        <Button
                          color="black"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentFile = attachments?.personalPicture;
                            currentFile.cancelPopup = false;
                            setAttachments({
                              ...attachments,
                              personalPicture: currentFile,
                            });
                          }}
                        >
                          Keep
                        </Button>
                        <Button
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttachments({
                              ...attachments,
                              personalPicture: null,
                            });
                          }}
                        >
                          Yes, remove
                        </Button>
                      </Popup>
                    </List>
                  ) : (
                    <div>
                      <input
                        ref={personalPictureRef}
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={(e) => onFileChange(e, 'personalPicture')}
                      />
                      <div
                        className="buttun-type-link"
                        onClick={() => personalPictureRef.current.click()}
                      >
                        <Icon name="add" />
                        Add
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#7e7e7e',
                        }}
                      >
                        Allowed types: jpg, jpeg, png
                      </p>
                    </div>
                  )}
                </Form.Field>
                <Form.Field>
                  <label className="label-item">Visa Copy</label>
                  {attachments?.visaCopy ? (
                    <List divided relaxed>
                      <Popup
                        key={attachments?.idCovisaCopypyBack?.id}
                        content="Remove file?"
                        on="click"
                        pinned
                        open={attachments?.visaCopy?.cancelPopup}
                        trigger={
                          <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let currentFile = attachments?.visaCopy;
                              currentFile.cancelPopup = !currentFile.cancelPopup;
                              setAttachments({
                                ...attachments,
                                visaCopy: currentFile,
                              });
                            }}
                          >
                            <List.Icon
                              name={
                                attachments?.visaCopy?.type.match('image.*')
                                  ? 'image'
                                  : attachments?.visaCopy?.type.match('pdf')
                                  ? 'file pdf'
                                  : 'file'
                              }
                              size="large"
                              color="black"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {attachments?.visaCopy?.name}
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        }
                      >
                        <Header as="h4">Remove file?</Header>
                        <Button
                          color="black"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentFile = attachments?.visaCopy;
                            currentFile.cancelPopup = false;
                            setAttachments({
                              ...attachments,
                              visaCopy: currentFile,
                            });
                          }}
                        >
                          Keep
                        </Button>
                        <Button
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttachments({
                              ...attachments,
                              visaCopy: null,
                            });
                          }}
                        >
                          Yes, remove
                        </Button>
                      </Popup>
                    </List>
                  ) : (
                    <div>
                      <input
                        ref={visaCopyRef}
                        type="file"
                        accept="application/pdf, image/*"
                        hidden
                        onChange={(e) => onFileChange(e, 'visaCopy')}
                      />
                      <div
                        className="buttun-type-link"
                        onClick={() => visaCopyRef.current.click()}
                      >
                        <Icon name="add" />
                        Add
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#7e7e7e',
                        }}
                      >
                        Allowed types: jpg, jpeg, png, pdf
                      </p>
                    </div>
                  )}
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label className="label-item">{t.people.attachments_form.ID_copy_front}</label>
                  {attachments?.idCopyFront ? (
                    <List divided relaxed>
                      <Popup
                        key={attachments?.idCopyFront?.id}
                        content="Remove file?"
                        on="click"
                        pinned
                        open={attachments?.idCopyFront?.cancelPopup}
                        trigger={
                          <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let currentFile = attachments?.idCopyFront;
                              currentFile.cancelPopup = !currentFile.cancelPopup;
                              setAttachments({
                                ...attachments,
                                idCopyFront: currentFile,
                              });
                            }}
                          >
                            <List.Icon
                              name={
                                attachments?.idCopyFront?.type.match('image.*')
                                  ? 'image'
                                  : attachments?.idCopyFront?.type.match('pdf')
                                  ? 'file pdf'
                                  : 'file'
                              }
                              size="large"
                              color="black"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {attachments?.idCopyFront?.name}
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        }
                      >
                        <Header as="h4">Remove file?</Header>
                        <Button
                          color="black"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentFile = attachments?.idCopyFront;
                            currentFile.cancelPopup = false;
                            setAttachments({
                              ...attachments,
                              idCopyFront: currentFile,
                            });
                          }}
                        >
                          Keep
                        </Button>
                        <Button
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttachments({
                              ...attachments,
                              idCopyFront: null,
                            });
                          }}
                        >
                          Yes, remove
                        </Button>
                      </Popup>
                    </List>
                  ) : (
                    <div>
                      <input
                        ref={idCopyFrontRef}
                        type="file"
                        accept="application/pdf, image/*"
                        hidden
                        onChange={(e) => onFileChange(e, 'idCopyFront')}
                      />
                      <div
                        className="buttun-type-link"
                        onClick={() => idCopyFrontRef.current.click()}
                      >
                        <Icon name="add" />
                        Add
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#7e7e7e',
                        }}
                      >
                        Allowed types: jpg, jpeg, png, pdf
                      </p>
                    </div>
                  )}
                </Form.Field>
                <Form.Field>
                  <label className="label-item">Passport Copy</label>
                  {attachments?.passportCopy ? (
                    <List divided relaxed>
                      <Popup
                        key={attachments?.passportCopy?.id}
                        content="Remove file?"
                        on="click"
                        pinned
                        open={attachments?.passportCopy?.cancelPopup}
                        trigger={
                          <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let currentFile = attachments?.passportCopy;
                              currentFile.cancelPopup = !currentFile.cancelPopup;
                              setAttachments({
                                ...attachments,
                                passportCopy: currentFile,
                              });
                            }}
                          >
                            <List.Icon
                              name={
                                attachments?.passportCopy?.type.match('image.*')
                                  ? 'image'
                                  : attachments?.passportCopy?.type.match('pdf')
                                  ? 'file pdf'
                                  : 'file'
                              }
                              size="large"
                              color="black"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {attachments?.passportCopy?.name}
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        }
                      >
                        <Header as="h4">Remove file?</Header>
                        <Button
                          color="black"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentFile = attachments?.passportCopy;
                            currentFile.cancelPopup = false;
                            setAttachments({
                              ...attachments,
                              passportCopy: currentFile,
                            });
                          }}
                        >
                          Keep
                        </Button>
                        <Button
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttachments({
                              ...attachments,
                              passportCopy: null,
                            });
                          }}
                        >
                          Yes, remove
                        </Button>
                      </Popup>
                    </List>
                  ) : (
                    <div>
                      <input
                        ref={passportCopyRef}
                        type="file"
                        accept="application/pdf, image/*"
                        hidden
                        onChange={(e) => onFileChange(e, 'passportCopy')}
                      />
                      <div
                        className="buttun-type-link"
                        onClick={() => passportCopyRef.current.click()}
                      >
                        <Icon name="add" />
                        Add
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#7e7e7e',
                        }}
                      >
                        Allowed types: jpg, jpeg, png, pdf
                      </p>
                    </div>
                  )}
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    Covid Vaccine Certificate
                  </label>
                  {attachments?.covidCopy ? (
                    <List divided relaxed>
                      <Popup
                        key={attachments?.covidCopy?.id}
                        content="Remove file?"
                        on="click"
                        pinned
                        open={attachments?.covidCopy?.cancelPopup}
                        trigger={
                          <List.Item
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              let currentFile = attachments?.covidCopy;
                              currentFile.cancelPopup = !currentFile.cancelPopup;
                              setAttachments({
                                ...attachments,
                                covidCopy: currentFile,
                              });
                            }}
                          >
                            <List.Icon
                              name={
                                attachments?.covidCopy?.type.match('image.*')
                                  ? 'image'
                                  : attachments?.covidCopy?.type.match('pdf')
                                  ? 'file pdf'
                                  : 'file'
                              }
                              size="large"
                              color="black"
                              verticalAlign="middle"
                            />
                            <List.Content>
                              <List.Header>
                                {attachments?.covidCopy?.name}
                              </List.Header>
                            </List.Content>
                          </List.Item>
                        }
                      >
                        <Header as="h4">Remove file?</Header>
                        <Button
                          color="black"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentFile = attachments?.covidCopy;
                            currentFile.cancelPopup = false;
                            setAttachments({
                              ...attachments,
                              covidCopy: currentFile,
                            });
                          }}
                        >
                          Keep
                        </Button>
                        <Button
                          color="red"
                          onClick={(e) => {
                            e.stopPropagation();
                            setAttachments({
                              ...attachments,
                              passportCopy: null,
                            });
                          }}
                        >
                          Yes, remove
                        </Button>
                      </Popup>
                    </List>
                  ) : (
                    <div>
                      <input
                        ref={covidCopyRef}
                        type="file"
                        accept="application/pdf, image/*"
                        hidden
                        onChange={(e) => onFileChange(e, 'covidCopy')}
                      />
                      <div
                        className="buttun-type-link"
                        onClick={() => covidCopyRef.current.click()}
                      >
                        <Icon name="add" />
                        Add
                      </div>
                      <p
                        style={{
                          fontSize: '12px',
                          marginTop: '5px',
                          color: '#7e7e7e',
                        }}
                      >
                        Allowed types: jpg, jpeg, png, pdf
                      </p>
                    </div>
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid>
          </div>
          <Divider />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={activePane == 2 ? 'Submit' : 'Next'}
            primary
            onClick={() => {
              activePane == 2
                ? createNewUserHandler()
                : setActivePane(activePane + 1);
            }}
            loading={buttonStatus == 1}
          />
          <Button
            content="Cancel"
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
        {errorPane.pane0 || errorPane.pane1 ? (
          <span className="label-error">
            There are some errors occured in some fields, please double check.
          </span>
        ) : (
          ''
        )}
      </Modal>

      <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              <b>{responseData.name}</b> added successfully!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(true)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddPeopleModal;
