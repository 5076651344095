import React, { useEffect, useState } from "react";
import { Grid, Label } from "semantic-ui-react";
import Tooltip from "react-power-tooltip";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../../config/i18n/slice";
import Chart from "react-apexcharts";
import {
  AddIconDashboard,
  DashboardWOStatusIcon,
  FullArrow,
} from "../../../../Assets/Icons/svg";
import ProgressBar from "@ramonak/react-progress-bar";
const Utilisation = ({
  setDashBoardData,
  dashBoardData,
  setModalData,
  setOpenModal,
  openModal,
  data12,
}) => {
  const t = useSelector(selectTranslations);

  const [showTooltip, setShowTooltip] = useState(false);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [userData, setUserData] = useState([]);
  const [showExpandTooltip, setShowExpandTooltip] = useState(false);
  const [showAddCustomizedTooltip, setShowAddCustomizedTooltip] = useState(
    false
  );

  const [utilizationOption, setUtilizationOption] = useState(
    {
      series: [],
      options: {
        chart: {
          width: 187,
          height: 187,
          type: "donut",
        },
        labels: ["Incurred hours", "Available hours"],
        legend: {
          show: false,
        },
        colors: ["#0C66E4", "#788CA5"],
        plotOptions: {
          pie: {
            donut: {
              size: "90%",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "18px",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  color: "#8A92A6",
                  offsetY: -10,
                  formatter: function() {
                    return "Utilization";
                  },
                },
                //   value: {
                //     show: true,
                //     fontSize: '24px',
                //     fontFamily: 'Roboto',
                //     fontWeight: 'bold',
                //     color: '#0C66E4',
                //     offsetY: 10,
                //     formatter: function (val) {
                //       return `${val}%`;
                //     }
                //   },
                total: {
                  show: true,
                  label: "Total",
                  color: "#0C66E4",
                  formatter: function(w) {
                    return `${w.globals.seriesTotals.reduce(
                      (a, b) => a + b,
                      0
                    )}%`;
                  },
                },
              },
            },
          },
        },
        //   plotOptions: {
        //     pie: {
        //       donut: {
        //         size: '90%',
        //       },
        //     },
        //   },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: "25px",
            fontFamily: "Roboto",
            fontWeight: 100,
            colors: ["#e9f2ff"],
          },
        },

        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val, opts) {
        //     const percentages = opts.w.config.seriesPercentages || [];
        //     return `${percentages[opts.seriesIndex] || 0}%`;
        //   },
        //   style: {
        //     fontSize: "25px",
        //     fontFamily: "Roboto",
        //     fontWeight: 100,
        //     colors: ["#e9f2ff"],
        //   },
        // },
        stroke: {
          show: false,
          width: 0,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "14px",
            fontFamily: "Roboto",
          },
          custom: function({ seriesIndex, dataPointIndex, w }) {
            const value =
              w.config.series[seriesIndex][dataPointIndex] ||
              w.config.series[seriesIndex];
            const label = w.config.labels[seriesIndex];
            const color = w.config.colors[seriesIndex];

            return `
                <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
                  <div style="display: flex; align-items: center;">
                    <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                    <span>${label}: </span>&nbsp; ${value}
                  </div>
                </div>
              `;
          },
        },
      },
    }
    //     {
    //     chart: {
    //       height: 200,
    //       type: "radialBar",
    //     },
    //     plotOptions: {
    //       radialBar: {
    //         hollow: {
    //           size: "70%",
    //         },
    //         track: {
    //           background: "rgba(120, 140, 165, 1)", // Unfilled part color
    //           strokeWidth: "100%", // Width of the unfilled part
    //         },
    //         dataLabels: {
    //           name: {
    //             show: true,
    //             color: "rgba(138, 146, 166, 1)", // Label color
    //             fontSize: "16px", // Font size
    //           },
    //           value: {
    //             show: true,
    //             color: "rgba(35, 45, 66, 1)", // Label color
    //             fontSize: "22px", // Font size
    //           },
    //         },
    //         thickness: 12, // Width of the filled part
    //         stroke: {
    //           lineCap: "round", // Rounded edges
    //         },
    //       },
    //     },
    //     fill: {
    //       colors: ["rgba(12, 102, 228, 1)"], // Filled part color
    //     },
    //     tooltip: {
    //       enabled: true,
    //       style: {
    //         fontSize: "14px",
    //         fontFamily: "Roboto",
    //       },
    //       custom: function({ seriesIndex, w }) {
    //         const value = w.config.series[seriesIndex];
    //         const label = w.config.labels[seriesIndex];
    //         const color = w.config.fill.colors[seriesIndex]; // Get the color for the circle

    //         return `
    //           <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
    //             <div style="display: flex; align-items: center;">
    //               <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
    //               <span>${label}: </span>&nbsp; ${value}%
    //             </div>
    //           </div>
    //         `;
    //       },
    //     },
    //     series: [],
    //     labels: ["Utilization"],
    //   }
  );

  useEffect(() => {
    if (Object.values(dashBoardData?.utilization)?.length > 0) {
      const { utilization } = dashBoardData;
      setUtilizationOption((prevState) => ({
        ...prevState,
        series: [
          utilization?.incurredHours,
          utilization?.totalUsersAvailableHours,
        ],
        options: {
          ...prevState.options,
          plotOptions: {
            pie: {
              donut: {
                size: "90%",
                labels: {
                  show: true,
                  name: {
                    show: true,
                    fontSize: "18px",
                    fontFamily: "Roboto",
                    fontWeight: "bold",
                    color: "#788CA5",
                    offsetY: -10,
                    formatter: function() {
                      return t.dashboard.cards.uti;
                    },
                  },
                  value: {
                    show: true,
                    color: "#788CA5",
                    formatter: function() {
                      return `${utilization?.totalUtilization} %`; // Use the fixed value here
                    },
                  },
                  total: {
                    show: true,
                    label: "Total",
                    color: "#788CA5",
                    formatter: function() {
                      return `${utilization?.totalUtilization} %`; // Use the fixed value here
                    },
                  },
                },
              },
            },
          },
        },
      }));

      if (dashBoardData?.utilization?.usersUtilization?.length > 0) {
        setUserData(dashBoardData?.utilization?.usersUtilization);
      }
    }
  }, [dashBoardData, dashBoardData?.utilization?.usersUtilization]);
  return (
    <Grid.Column className="completion-column pl-0 pt-0">
      <div
        // style={{ overflow: "auto", height: "388px" }}
        className={
          openModal
            ? "completion-column-data no-border"
            : "completion-column-data"
        }
      >
        <div className="completion-cotainer d-flex justify-content-between mt-2">
          <div className="completion-title d-flex">
            <span className="title">{t.dashboard.cards.utilisation_rate}</span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={
                selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
              }
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "en" ? "right" : "left"}
                show={showTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="0px 0px 0px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="8px"
                // moveDown="10px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.tooltip.utilisation}
                </span>
              </Tooltip>
              <DashboardWOStatusIcon />
            </p>
          </div>

          <span style={{ marginTop: "-10px", gap: "10px" }} className="d-flex">
            <span
              onClick={() => {
                setOpenModal(true);
                setModalData("Utilisation");
                setShowExpandTooltip(false);
              }}
              style={{ marginTop: "2px" }}
              className=""
            >
              {openModal ? null : (
                <p
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    marginTop: "-10px",
                  }}
                  onMouseOver={() => setShowExpandTooltip(true)}
                  onMouseLeave={() => setShowExpandTooltip(false)}
                  className=" mt-1"
                >
                  <Tooltip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position="top"
                    show={showExpandTooltip}
                    textBoxWidth="80px"
                    fontSize="10px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="8px 8px 8px 8px"
                    borderRadius="5px"
                    static
                    arrowAlign="start"
                    moveRight="5px"
                    moveDown="3px"
                  >
                    <span
                      style={{ padding: "0px 10px" }}
                      className="work-order-tooltip"
                    >
                      {t.dashboard.expand_full}
                    </span>
                  </Tooltip>
                  <FullArrow />
                </p>
              )}
            </span>
            <p
              style={{
                position: "relative",
                cursor: "pointer",
                marginTop: "-10px",
              }}
              onMouseOver={() => setShowAddCustomizedTooltip(true)}
              onMouseLeave={() => setShowAddCustomizedTooltip(false)}
              className=" mt-1"
            >
              <Tooltip
                color="#fff"
                backgroundColor="#172B4D"
                position={selectedLanguage == "ar" ? "right" : "left"}
                show={showAddCustomizedTooltip}
                textBoxWidth="105px"
                fontSize="10px"
                fontWeight="400"
                fontFamily={
                  selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                }
                padding="8px 8px 8px 8px"
                borderRadius="5px"
                static
                arrowAlign="end"
                moveRight="0px"
              >
                <span
                  style={{ padding: "0px 10px" }}
                  className="work-order-tooltip"
                >
                  {t.dashboard.add_customized}
                </span>
              </Tooltip>
              {/* <AddIconDashboard /> */}
            </p>
          </span>
        </div>
        <div id="completion-cotainer d-flex justify-content-between mt-2">
          <div className="d-flex align-items-center">
            {" "}
            <div style={{ display: "inline-block" }} className="ml-5 mr-5">
              {" "}
              <Chart
                options={utilizationOption?.options}
                series={utilizationOption.series || []}
                type="donut"
                height={180}
                width={150}
              />
            </div>
            <div
              style={{ gap: "16px", padding: "15px 10px 13px 18px" }}
              className={`d-flex`}
            >
              <div className="label-text-util">
                {t.dashboard.cards.avail_hr}
                <p className={selectedLanguage == "en"? "text-right" : "text-left"} >
                  {dashBoardData?.utilization?.totalUsersAvailableHours}
                </p>
              </div>
              <div className="mt-2">
                <Label
                  circular
                  empty
                  color={"darkgrey"}
                  style={{
                    marginRight: "5px",
                    width: "12px",
                    height: "12px",
                  }}
                  size="medium"
                />
              </div>

              <div className="label-text-util">
                {t.dashboard.cards.incurred_hr}
                <p className={selectedLanguage == "en"? "text-right" : "text-left"} >
                  {dashBoardData?.utilization?.incurredHours}
                </p>
              </div>
              <div className="mt-2">
                <Label
                  circular
                  empty
                  color={"blue"}
                  style={{
                    marginRight: "5px",
                    width: "12px",
                    height: "12px",
                  }}
                  size="medium"
                />
              </div>
            </div>
          </div>

          <div className="resolution-time-table w-100">
            {userData?.length > 0 && (
              <div
                className={
                  openModal
                    ? "table-container utilisation h-500"
                    : "table-container utilisation"
                }
              >
                <table style={{ width: "-webkit-fill-available" }}>
                  <thead>
                    <tr>
                      <th rowspan="2">{t.dashboard.cards.technician_name}</th>
                      <th rowspan="2">{t.dashboard.cards.actual_hr}</th>
                      <th className="">{t.dashboard.cards.avail_hr}</th>
                      <th className="">{t.dashboard.cards.uti}</th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                    {userData?.length > 0 &&
                      userData?.map((technician, index) => (
                        <tr key={index}>
                          <td style={{ maxWidth: "235px" }}>
                            {technician?.userName}
                          </td>
                          <td><div style={{width:"74px",textAlign:"center"}}>{technician?.totalWorkOrderTime}</div></td>
                          <td><div style={{width:"94px",textAlign:"center"}}>{technician?.allocatedTime}</div></td>
                          <td style={{ display: "flex", alignItems: "center" }}>
                            {" "}
                            <ProgressBar
                              completed={
                                technician?.utilization? technician?.utilization
                                  : 0
                              }
                              bgColor="#0C66E4"
                              isLabelVisible={false}
                              height="6px"
                              width="158px"
                              baseBgColor="#dcdcdc"
                              labelColor="#333333"
                            />{" "}
                            <span
                              style={{ color: "#333333" }}
                              className="ml-3 mr-3"
                            >
                              {technician?.utilization ? technician?.utilization : 0}
                              %
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </Grid.Column>
  );
};

export default Utilisation;
