import React, { useMemo } from 'react';
import { AsideMenuList } from './AsideMenuList';
import { useHtmlClassService } from '../../../_core/MetronicLayout';
import { toAbsoluteUrl } from '../../../../_helpers';
import { useSelector } from 'react-redux';

import packageJson from "../../../../../../package.json";
import { selectTranslations } from '../../../../../app/config/i18n/slice';

export function AsideMenu({ disableScroll }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes('aside_menu'),
      ulClasses: uiService.getClasses('aside_menu_nav', true),
      asideClassesFromConfig: uiService.getClasses('aside_menu', true),
    };
  }, [uiService]);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const t = useSelector(selectTranslations);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu  d-flex align-items-center justify-content-between flex-column custom-aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        <div style={selectedLanguage == 'en' ? {} : { width: '237px' }}>
          <AsideMenuList layoutProps={layoutProps} />
        </div>
        <div className="power-by-container mb-30  align-items-center justify-content-center">
      <div className='d-flex' style={{fontSize:"14px"}}> <span style={{marginTop:"7px"}}>{t.common.power_by} </span>
          <span className="ml-2">
            <img src={toAbsoluteUrl('/media/bg/white-logo.svg')}></img>
          </span></div>
         
          <div className='text-center mt-3'>{packageJson?.webappversion}</div>
        </div>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
