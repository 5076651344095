import { cloneDeep, filter } from 'lodash';
import React, { useEffect, useState, createRef, useRef } from 'react';
import _ from 'lodash';
import { Input, DropdownMenu, DropdownItem } from 'semantic-ui-react';
import CategoryDropdown from '../../../components/common/CategoryDropdown';
import calendar_icon from '../../../../Assets/Icons/calender-icon.svg';

import {
  getUsersByRole,
  getAllCustomers,
  IMAGES_URL,
  getAllCategories,
  getAllParentLocations,
} from '../../../config/functions';

import {
  ArrowCalendarLeft,
  ArrowCalendarRight,
  DownloadIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  UploadIcon,
  WhiteDownChevron,
} from '../../../../Assets/Icons/svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';

import { LetteredAvatarImageUrl } from '../../../components/common/userProfilePlaceholder';
import DownloadPdfFileWithData from '../../../../services/DownloadPdfFileWithData';
import DownloadExcelFileWithData from '../../../../services/DownloadExcelFileWithData';

import DatePicker from 'react-multi-date-picker';
import AssetsBulkUploadModal from '../../../components/modal/assets/UploadBulkAssetsModal';
import AssetsBulkUploadResult from '../../../components/modal/assets/AssetsBulkUploadResult';

import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

const Filters = ({
  refresh,
  data,
  setDataByFilter,
  fileData = [],
  setRefresh,
  weekSelectedDate,
  setWeekSelectedDate,
  setWeekFlag,
  weekFlag,
  monthSelectedDate,
  setMonthSelectedDate,
  setMonthSelectedArrayDate,
  monthSelectedArrayDate,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });


  const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday

    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDay);

    const endOfWeek = new Date(currentDate);
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDay));

    const isoStartDate = startOfWeek.toISOString();
    const isoEndDate = endOfWeek.toISOString();

    return [isoStartDate, isoEndDate];
  };

  const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      2
    );
    const endOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );

    return [startOfMonth, endOfMonth];
  };

  const getCurrentMonthStartDate = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );
    return startOfMonth;
  };

  const handleWeekChange = (value) => {

    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setWeekSelectedDate(isoDates);
    } else {
      const selectedDate = value.month;

      // Extract month information from the object
      const monthName = selectedDate.name; // "February"
      const monthIndex = selectedDate.index; // 1 (January is 0, February is 1, etc.)
      const year = value.year; // You can get the current year or use another logic to determine the year

      // Construct the start and end dates of the month
      const startDate = new Date(year, monthIndex, 2);
      const endDate = new Date(year, monthIndex + 1, 1); // 0 day of the next month is the last day of the current month

      // Convert to ISO format
      const isoStartDate = startDate.toISOString();
      const isoEndDate = endDate.toISOString();
      setMonthSelectedArrayDate([isoStartDate, isoEndDate]);
      setMonthSelectedDate(value);

      // Extract month and year from the selected date
      // const year = selectedDate.getFullYear();
      // const month = selectedDate.getMonth() + 1; // Month is zero-indexed, so add 1

      // // Assuming you want to construct the ISO format as "YYYY-MM"
      // const isoMonth = `${year}-${month < 10 ? '0' : ''}${month}`;
    }
  };

  let daysColumns = [];
  let daysColumnsMonth = [];
  const todayDate = weekFlag
    ? new Date(weekSelectedDate[0])
    : new Date(monthSelectedArrayDate[0]);

  const endDate = new Date(
    weekFlag ? weekSelectedDate[1] : monthSelectedArrayDate[1]
  );

  if (!weekFlag) {
    todayDate.setDate(1);
    endDate.setDate(0);
  }
  while (todayDate <= endDate) {
    const day = todayDate.toLocaleDateString('en-US', { weekday: 'short' });
    const formattedDate = todayDate.toLocaleDateString('en-US', {
      day: '2-digit',
    });

    if (weekFlag) {
      daysColumns.push({
        label: `${day} ${formattedDate}`,
        key: day,
      });
    } else {
      daysColumnsMonth.push({
        label: `${day} ${formattedDate}`,
        key: formattedDate,
      });
    }

    todayDate.setDate(todayDate.getDate() + 1); // Move to the next day
  }

  const headers = [
    { label: t.assets.table.ID, key: 'id' },
    { label: t.timesheet.table.teamname, key: 'name' },
    { label: t.assets.form.location, key: 'location' },
    ...(weekFlag ? daysColumns : daysColumnsMonth),
    { label: t.timesheet.table.total, key: 'total' },
  ];
  const [filtersTrigger, setFiltersTrigger] = useState({
    shift: {
      initOptions: [],
      options: [
        {
          key: 'shiftA',
          value: 'shiftA',
          text: t.timesheet.filter.shift_A,
        },
        {
          key: 'shiftB',
          value: 'shiftB',
          text: t.timesheet.filter.shift_B,
        },
        {
          key: 'shiftC',
          value: 'shiftC',
          text: t.timesheet.filter.shift_C,
        },
      ],
    },

    locations: {
      initOptions: [],
      options: [],
    },
  });

  const [filterOptions, setFilterOptions] = useState({
    shift: [],

    locations: [],
  });

  const [filterSearch, setFilterSearch] = useState({
    customer: '',
    vendor: '',
    createdByUser: '',
    area: '',
    model: '',
    barcode: '',
  });

  const [searchTitle, setSearchTitle] = useState('');

  const [newData, setNewData] = useState(data);

  const [selectedDates, setSelectedDates] = useState([]);
  const [assetUploadModal, setAssetUploadModal] = useState(false);
  const [assetUploadResultModal, setAssetUploadResultModal] = useState(false);
  const [bulkUploadResultData, setBulkUploadResultData] = useState([]);

  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedDates([]);
    setWeekSelectedDate([]);
    setMonthSelectedDate({});
  };

  const handleChange = (value) => {

    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setSelectedDates(isoDates);
    }
  };

  const downloadXLS = () => {
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let timesheet_data = [];

      timesheet_data.id = fileData[i].id;
      timesheet_data.name = fileData[i].username || t.common.na;
      const locationData = fileData[i]?.location;

      const displayText = locationData
        .map((location) => {
          const nameParts = location?.name;

          return nameParts;
        })
        .join(', '); // Adjust the join character as needed

      timesheet_data.location = displayText || t.common.na;

      if (weekFlag) {
        timesheet_data.Sun = fileData[i].days[0].time;
        timesheet_data.Mon = fileData[i].days[1].time;
        timesheet_data.Tue = fileData[i].days[2].time;
        timesheet_data.Wed = fileData[i].days[3].time;
        timesheet_data.Thu = fileData[i].days[4].time;
        timesheet_data.Fri = fileData[i].days[5].time;
        timesheet_data.Sat = fileData[i].days[6].time;
        timesheet_data.total = fileData[i].total;
      } else {
        timesheet_data.timesheet_data01 = fileData[i].days[0].time;
        timesheet_data.timesheet_data02 = fileData[i].days[1].time;
        timesheet_data.timesheet_data03 = fileData[i].days[2].time;
        timesheet_data.timesheet_data04 = fileData[i].days[3].time;
        timesheet_data.timesheet_data05 = fileData[i].days[4].time;
        timesheet_data.timesheet_data06 = fileData[i].days[5].time;
        timesheet_data.timesheet_data07 = fileData[i].days[6].time;
        timesheet_data.timesheet_data08 = fileData[i].days[7].time;
        timesheet_data.timesheet_data09 = fileData[i].days[8].time;
        timesheet_data.timesheet_data10 = fileData[i].days[9].time;
        timesheet_data.timesheet_data11 = fileData[i].days[10].time;
        timesheet_data.timesheet_data12 = fileData[i].days[11].time;
        timesheet_data.timesheet_data13 = fileData[i].days[12].time;
        timesheet_data.timesheet_data14 = fileData[i].days[13].time;
        timesheet_data.timesheet_data15 = fileData[i].days[14].time;
        timesheet_data.timesheet_data16 = fileData[i].days[15].time;
        timesheet_data.timesheet_data17 = fileData[i].days[16].time;
        timesheet_data.timesheet_data18 = fileData[i].days[17].time;
        timesheet_data.timesheet_data19 = fileData[i].days[18].time;
        timesheet_data.timesheet_data20 = fileData[i].days[19].time;
        timesheet_data.timesheet_data21 = fileData[i].days[20].time;
        timesheet_data.timesheet_data22 = fileData[i].days[21].time;
        timesheet_data.timesheet_data23 = fileData[i].days[22].time;
        timesheet_data.timesheet_data24 = fileData[i].days[23].time;
        timesheet_data.timesheet_data25 = fileData[i].days[24].time;
        timesheet_data.timesheet_data26 = fileData[i].days[25].time;
        timesheet_data.timesheet_data27 = fileData[i].days[26].time;
        timesheet_data.timesheet_data28 = fileData[i].days[27].time;
        timesheet_data.timesheet_data29 = fileData[i].days[28].time
          ? fileData[i].days[28].time
          : t.common.na;
        timesheet_data.timesheet_data30 = fileData[i]?.days[29]?.time
          ? fileData[i].days[29].time
          : t.common.na;
        timesheet_data.timesheet_data31 = fileData[i].days[30].time
          ? fileData[i].days[30].time
          : t.common.na;
        timesheet_data.total = fileData[i].total;
      }

      if (weekFlag) {
        csvData.push([
          timesheet_data.id,
          timesheet_data.name,
          timesheet_data.location,
          timesheet_data.Sun,
          timesheet_data.Mon,
          timesheet_data.Tue,
          timesheet_data.Wed,
          timesheet_data.Thu,
          timesheet_data.Fri,
          timesheet_data.Sat,
          timesheet_data.total,
        ]);
      } else {
        csvData.push([
          timesheet_data.id,
          timesheet_data.name,
          timesheet_data.location,
          timesheet_data.timesheet_data01,
          timesheet_data.timesheet_data02,
          timesheet_data.timesheet_data03,
          timesheet_data.timesheet_data04,
          timesheet_data.timesheet_data05,
          timesheet_data.timesheet_data06,

          timesheet_data.timesheet_data07,
          timesheet_data.timesheet_data08,
          timesheet_data.timesheet_data09,
          timesheet_data.timesheet_data10,
          timesheet_data.timesheet_data11,
          timesheet_data.timesheet_data12,
          timesheet_data.timesheet_data13,
          timesheet_data.timesheet_data14,
          timesheet_data.timesheet_data15,
          timesheet_data.timesheet_data16,
          timesheet_data.timesheet_data17,
          timesheet_data.timesheet_data18,
          timesheet_data.timesheet_data19,
          timesheet_data.timesheet_data20,
          timesheet_data.timesheet_data21,
          timesheet_data.timesheet_data22,
          timesheet_data.timesheet_data23,
          timesheet_data.timesheet_data24,
          timesheet_data.timesheet_data25,
          timesheet_data.timesheet_data26,
          timesheet_data.timesheet_data27,
          timesheet_data.timesheet_data28,
          timesheet_data.timesheet_data29,
          timesheet_data.timesheet_data30,
          timesheet_data.timesheet_data31,
          timesheet_data.total,
        ]);
      }
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column, // Category column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, 'Timesheet');
  };

  const generatePDF = () => {
    let csvData = [];
    for (let i = 0; i < fileData.length; i++) {
      let timesheet_data = [];

      timesheet_data.id = fileData[i].id;
      timesheet_data.name = fileData[i].username || t.common.na;
      const locationData = fileData[i]?.location;

      const displayText = locationData
        .map((location) => {
          const nameParts = location?.name;

          return nameParts;
        })
        .join(', '); // Adjust the join character as needed

      timesheet_data.location = displayText || t.common.na;

      if (weekFlag) {
        timesheet_data.Sun = fileData[i].days[0].time;
        timesheet_data.Mon = fileData[i].days[1].time;
        timesheet_data.Tue = fileData[i].days[2].time;
        timesheet_data.Wed = fileData[i].days[3].time;
        timesheet_data.Thu = fileData[i].days[4].time;
        timesheet_data.Fri = fileData[i].days[5].time;
        timesheet_data.Sat = fileData[i].days[6].time;
        timesheet_data.total = fileData[i].total;
      } else {
        timesheet_data.timesheet_data01 = fileData[i].days[0].time;
        timesheet_data.timesheet_data02 = fileData[i].days[1].time;
        timesheet_data.timesheet_data03 = fileData[i].days[2].time;
        timesheet_data.timesheet_data04 = fileData[i].days[3].time;
        timesheet_data.timesheet_data05 = fileData[i].days[4].time;
        timesheet_data.timesheet_data06 = fileData[i].days[5].time;
        timesheet_data.timesheet_data07 = fileData[i].days[6].time;
        timesheet_data.timesheet_data08 = fileData[i].days[7].time;
        timesheet_data.timesheet_data09 = fileData[i].days[8].time;
        timesheet_data.timesheet_data10 = fileData[i].days[9].time;
        timesheet_data.timesheet_data11 = fileData[i].days[10].time;
        timesheet_data.timesheet_data12 = fileData[i].days[11].time;
        timesheet_data.timesheet_data13 = fileData[i].days[12].time;
        timesheet_data.timesheet_data14 = fileData[i].days[13].time;
        timesheet_data.timesheet_data15 = fileData[i].days[14].time;
        timesheet_data.timesheet_data16 = fileData[i].days[15].time;
        timesheet_data.timesheet_data17 = fileData[i].days[16].time;
        timesheet_data.timesheet_data18 = fileData[i].days[17].time;
        timesheet_data.timesheet_data19 = fileData[i].days[18].time;
        timesheet_data.timesheet_data20 = fileData[i].days[19].time;
        timesheet_data.timesheet_data21 = fileData[i].days[20].time;
        timesheet_data.timesheet_data22 = fileData[i].days[21].time;
        timesheet_data.timesheet_data23 = fileData[i].days[22].time;
        timesheet_data.timesheet_data24 = fileData[i].days[23].time;
        timesheet_data.timesheet_data25 = fileData[i].days[24].time;
        timesheet_data.timesheet_data26 = fileData[i].days[25].time;
        timesheet_data.timesheet_data27 = fileData[i].days[26].time;
        timesheet_data.timesheet_data28 = fileData[i].days[27].time;
        timesheet_data.timesheet_data29 = fileData[i].days[28].time
          ? fileData[i].days[28].time
          : t.common.na;
        timesheet_data.timesheet_data30 = fileData[i]?.days[29]?.time
          ? fileData[i].days[29].time
          : t.common.na;
        timesheet_data.timesheet_data31 = fileData[i].days[30].time
          ? fileData[i].days[30].time
          : t.common.na;
        timesheet_data.total = fileData[i].total;
      }

      if (weekFlag) {
        csvData.push([
          timesheet_data.id,
          timesheet_data.name,
          timesheet_data.location,
          timesheet_data.Sun,
          timesheet_data.Mon,
          timesheet_data.Tue,
          timesheet_data.Wed,
          timesheet_data.Thu,
          timesheet_data.Fri,
          timesheet_data.Sat,
          timesheet_data.total,
        ]);
      } else {
        csvData.push([
          timesheet_data.id,
          timesheet_data.name,
          timesheet_data.location,
          timesheet_data.timesheet_data01,
          timesheet_data.timesheet_data02,
          timesheet_data.timesheet_data03,
          timesheet_data.timesheet_data04,
          timesheet_data.timesheet_data05,
          timesheet_data.timesheet_data06,

          timesheet_data.timesheet_data07,
          timesheet_data.timesheet_data08,
          timesheet_data.timesheet_data09,
          timesheet_data.timesheet_data10,
          timesheet_data.timesheet_data11,
          timesheet_data.timesheet_data12,
          timesheet_data.timesheet_data13,
          timesheet_data.timesheet_data14,
          timesheet_data.timesheet_data15,
          timesheet_data.timesheet_data16,
          timesheet_data.timesheet_data17,
          timesheet_data.timesheet_data18,
          timesheet_data.timesheet_data19,
          timesheet_data.timesheet_data20,
          timesheet_data.timesheet_data21,
          timesheet_data.timesheet_data22,
          timesheet_data.timesheet_data23,
          timesheet_data.timesheet_data24,
          timesheet_data.timesheet_data25,
          timesheet_data.timesheet_data26,
          timesheet_data.timesheet_data27,
          timesheet_data.timesheet_data28,
          timesheet_data.timesheet_data29,
          timesheet_data.timesheet_data30,
          timesheet_data.timesheet_data31,
          timesheet_data.total,
        ]);
      }
    }
    // const columnWidths = [10, 25, 50, 23, 20, 22, 15, 28];
    DownloadPdfFileWithData(headers, csvData, 'Teams-Timesheet', [],weekFlag ? "A2" : "A1");
  };
  useEffect(() => {
    (async function() {
      let the_options_location = [];
      const fetchDataLocation = await getAllParentLocations(organisationId,"",1,99999);

      if (fetchDataLocation.response) {
        fetchDataLocation.response.forEach((el) => {
          the_options_location.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: `${el.attributes.name}`,
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,

        locations: {
          ...filtersTrigger.locations,
          initOptions: the_options_location,
          options: the_options_location,
        },
      });
    })();
  }, []);
  let _newData = newData;


  const handleCalendarDateLeftArrow = () => {
    if (weekFlag) {


      const newDates = weekSelectedDate.map((dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setDate(originalDate.getDate() - 7); // Add 7 days
        return originalDate.toISOString();
      });

      setWeekSelectedDate([newDates[0], newDates[1]]);
      // let date = new Date(calendarDate);
      // date.setMonth(date.getMonth() - 1);
      // let formattedValue = format(date, 'yyyy-MM-dd');
      // setCalendarDate(formattedValue);
      // calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else {
      const originalDate = new Date(monthSelectedDate);
      const nextMonthDate = new Date(originalDate);
      nextMonthDate.setMonth(nextMonthDate.getMonth() - 1);
      let finalDate = nextMonthDate.toDateString();
      const currentDate = new Date(finalDate);
   
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const startMonth = new Date(
        originalDate.getFullYear(),
        originalDate.getMonth() - 1,
        2
      );
      const endOfMonth = new Date(
        originalDate.getFullYear(),
        originalDate.getMonth(),
        1
      );

      const isoStartDate = startMonth.toISOString();
      const isoEndDate = endOfMonth.toISOString();
      setMonthSelectedArrayDate([isoStartDate, isoEndDate]);

      setMonthSelectedDate(startOfMonth);
    }
    // let date = new Date(calendarDate);
    // date.setMonth(date.getMonth() - 1);
    // let formattedValue = format(date, 'yyyy-MM-dd');
    // setCalendarDate(formattedValue);
    // calendarRef.current.getApi().gotoDate(new Date(formattedValue));
  };
  const handleCalendarDateRightArrow = () => {
    if (weekFlag) {
    

      const newDates = weekSelectedDate.map((dateString) => {
        const originalDate = new Date(dateString);
        originalDate.setDate(originalDate.getDate() + 7); // Add 7 days
        return originalDate.toISOString();
      });
    
      setWeekSelectedDate([newDates[0], newDates[1]]);
      // let date = new Date(calendarDate);
      // date.setMonth(date.getMonth() - 1);
      // let formattedValue = format(date, 'yyyy-MM-dd');
      // setCalendarDate(formattedValue);
      // calendarRef.current.getApi().gotoDate(new Date(formattedValue));
    } else {
      const originalDate = new Date(monthSelectedDate);
      const nextMonthDate = new Date(originalDate);
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
      let finalDate = nextMonthDate.toDateString();
      const currentDate = new Date(finalDate);
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const startMonth = new Date(
        originalDate.getFullYear(),
        originalDate.getMonth() + 1,
        2
      );
      const endOfMonth = new Date(
        originalDate.getFullYear(),
        originalDate.getMonth() + 2,
        1
      );

      const isoStartDate = startMonth.toISOString();
      const isoEndDate = endOfMonth.toISOString();
      setMonthSelectedArrayDate([isoStartDate, isoEndDate]);

      setMonthSelectedDate(startOfMonth);
    }

    // let date = new Date(calendarDate);
    // date.setMonth(date.getMonth() + 1);
    // let formattedValue = format(date, 'yyyy-MM-dd');
    // setCalendarDate(formattedValue);
    // calendarRef.current.getApi().gotoDate(new Date(formattedValue));
  };

  useEffect(() => {
    if (filterOptions.locations.length > 0) {
      _newData = _newData.filter((el) => {
      
        return el.location.some((item) =>
          filterOptions.locations.includes(String(item.id))
        );
      });
    }

    if (selectedDates.length == 2) {
      if (selectedDates[0] == selectedDates[1]) {
        _newData = _newData.filter((el) => {
          var toDate = moment(selectedDates[1]).format('YYYY-MM-DD') || '';
          var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
          var createdAt =
            moment(el.attributes.createdAt).format('YYYY-MM-DD') || '';

          return createdAt >= fromDate && createdAt <= toDate;
        });
      
      } else {
        _newData = _newData.filter((el) => {
          var toDate =
            moment(selectedDates[1])
              .add(1, 'days')
              .format('YYYY-MM-DD') || '';
          var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
          var createdAt =
            moment(el.attributes.createdAt).format('YYYY-MM-DD') || '';

          return createdAt >= fromDate && createdAt <= toDate;
        });
     
      }
    } else if (selectedDates.length == 1) {
      _newData = _newData.filter((el) => {
        var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';

        return el.attributes.createdAt >= fromDate;
      });
    }

    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
      const isMatch = (result) => re.test(result.username);
      _newData =
        searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    }

    if (_newData.length > 0) {
      setDataByFilter(_newData);
    } else {
      setDataByFilter([]);
    }
  }, [filterOptions, searchTitle, selectedDates]);



  return (
    <>
      <div className="filters">
        <div className="text-right mb-5 pb-5 d-flex justify-content-between views">
          <div className="list-view-option d-flex">
            <p className="single-view">
              <NavLink to="/timesheet">{t.people.title}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/timesheet-teams">{t.teams.title}</NavLink>
            </p>
          </div>
          <div className="d-flex timesheet-filter">
            <div className="calendar-view-options timesheet">
              <div
                className={`${weekFlag ? 'active' : ''}`}
                onClick={() => {
                  setWeekFlag(true);
                  setMonthSelectedArrayDate([]);
                  setMonthSelectedDate({});
                  setWeekSelectedDate(getCurrentWeekDates());
                }}
              >
                {t.workOrders.week}
              </div>
              <div
                className={`${!weekFlag ? 'active' : ''}`}
                onClick={() => {
                  setWeekFlag(false);
                  setWeekSelectedDate([]);
                  setMonthSelectedArrayDate(getCurrentMonthDates());
                  setMonthSelectedDate(getCurrentMonthStartDate());
                }}
              >
                {t.workOrders.month}
              </div>
            </div>
            <div style={{ marginTop: '-2px' }}>
              <span className="c-pointer" onClick={handleCalendarDateLeftArrow}>
                {selectedLanguage == 'ar' ? (
                  <ArrowCalendarRight />
                ) : (
                  <ArrowCalendarLeft />
                )}
              </span>

              <DatePicker
               style={weekFlag ? { maxWidth: '180px' } : {}}
                weekPicker={weekFlag}
                value={weekFlag ? weekSelectedDate : monthSelectedDate}
                onChange={handleWeekChange}
                // Set range property based on weekFlag
                onlyMonthPicker={!weekFlag} // Show month dropdown only if weekFlag is false
                // Show year dropdown only if weekFlag is false
                placeholder={t.timesheet.filter.date}
                className={'active'}
                format="DD/MM/YY"
                highlightToday={false}
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
              >
                <button
                  onClick={handleDeselect}
                  className="btn-datepicker blue"
                >
                  {t.assets.reset}
                </button>
              </DatePicker>
              <span
                className="c-pointer"
                onClick={handleCalendarDateRightArrow}
              >
                {selectedLanguage == 'ar' ? (
                  <ArrowCalendarLeft />
                ) : (
                  <ArrowCalendarRight />
                )}
              </span>
            </div>

            <div className="text-right mb-5 pb-5 d-flex week-filter">
              {/* <div class="ui basic buttons view-btn">
            <div
              className={`ui button week-btn ${weekFlag ? 'active' : ''}`}
              onClick={() => {
                setWeekFlag(true);
                setMonthSelectedArrayDate([]);
                setMonthSelectedDate({});
                setWeekSelectedDate(getCurrentWeekDates());
              }}
            >
              Week
            </div>
            <div
              className={`ui button day-btn ${!weekFlag ? 'active' : ''}`}
              onClick={() => {
                setWeekFlag(false);
                setWeekSelectedDate([]);
              }}
            >
              Month
            </div>
          </div>

          <div>
            <DatePicker
              weekPicker={weekFlag}
              value={weekFlag ? weekSelectedDate : monthSelectedDate}
              onChange={handleWeekChange}
              // Set range property based on weekFlag
              onlyMonthPicker={!weekFlag} // Show month dropdown only if weekFlag is false
              // Show year dropdown only if weekFlag is false
              placeholder={t.timesheet.filter.date}
              className={'active'}
              format="DD/MM/YY"
              highlightToday={false}
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
            >
              <button onClick={handleDeselect} className="btn-datepicker blue">
                {t.assets.reset}
              </button>
            </DatePicker>
            <img className="date-picker-image" src={calendar_icon} />
          </div> */}
              <div className="table-searchbar">
                <span className="search-svg">
                  <SearchIcon />
                </span>
                <Input
                  icon={{ name: 'search' }}
                  placeholder={t.timesheet.filter.search_teams}
                  value={searchTitle}
                  onChange={(e) => setSearchTitle(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="filters-wrapper justify-content-end">
        <div className="flexbox table-right-dropdown">
          <CategoryDropdown
            title={t.assets.form.location}
            options={filtersTrigger.locations.options}
            selectedValues={filterOptions.locations}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  locations: [...filterOptions.locations, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.locations.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  locations: filteredLocations,
                });
              }
            }}
            icon={false}
            image={false}
          />

          {/* <CategoryDropdown
            title={t.assets.form.category}
            options={filtersTrigger.categories.options}
            selectedValues={filterOptions.categories}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  categories: [...filterOptions.categories, e.target.value],
                });
              } else {
                let filteredCategories = filterOptions.categories.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  categories: filteredCategories,
                });
              }
            }}
            icon={false}
            image={false}
          />

          <CategoryDropdown
            title={t.assets.form.customer}
            options={filtersTrigger.customers.options}
            selectedValues={filterOptions.customers}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  customers: [...filterOptions.customers, e.target.value],
                });
              } else {
                let filteredCustomers = filterOptions.customers.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  customers: filteredCustomers,
                });
              }
            }}
            icon={false}
            image={true}
          />

          <CategoryDropdown
            title={createdByPlaceholder}
            options={filtersTrigger.createdByUser.options}
            selectedValues={filterOptions.createdByUser}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  createdByUser: [
                    ...filterOptions.createdByUser,
                    e.target.value,
                  ],
                });
              } else {
                let filteredCategory = filterOptions.createdByUser.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  createdByUser: filteredCategory,
                });
              }
            }}
            icon={false}
            image={true}
          /> */}

          {/* <div>
            <DatePicker
              value={selectedDates}
              onChange={handleChange}
              range
              showMonthDropdown
              showYearDropdown
              placeholder={t.timesheet.filter.date}
              className={'active'}
              format="DD/MM/YY"
              // locale={lang == 'ar' ? arabic_ar : ''}
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
            >
              <button onClick={handleDeselect} className="btn-datepicker blue">
                {t.assets.reset}
              </button>
            </DatePicker>
            <img className="date-picker-image" src={calendar_icon} />
          </div> */}

          {/* Page Actions Dropdown */}

          <div className="custom-menu-dropdown">
            <UncontrolledDropdown className="mr-2" direction="down">
              <DropdownToggle color="primary" className="option-toggle">
              {t.common.page_action}<WhiteDownChevron />{' '}
              </DropdownToggle>
              <DropdownMenu className={selectedLanguage == "ar" ?'rtl' : ""}>
              {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
                <DropdownItem className="divider" />
                <DropdownItem onClick={() => downloadXLS()}>
                  <DownloadIcon />  {t.common.download_sheet}
                </DropdownItem>
                <DropdownItem className="divider" />
                {/* <DropdownItem onClick={() => generatePDF()}>
                  <PdfIcon /> {t.common.download_pdf}
                </DropdownItem>
                <DropdownItem className="divider" /> */}
                <DropdownItem>
                  <UploadIcon />  {t.common.upload}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>

        {/* <AssetsBulkUploadModal
          isOpen={assetUploadModal}
          setModalOpenState={setAssetUploadModal}
          handleSuccess={(res) => {
            console.log('djdjasd');
            setBulkUploadResultData(res.data);
            setAssetUploadResultModal(true);
            setAssetUploadModal(false);
            setRefresh(!refresh);
          }}
        />

        <AssetsBulkUploadResult
          isOpen={assetUploadResultModal}
          setModalOpenState={setAssetUploadResultModal}
          data={bulkUploadResultData}
        /> */}
      </div>
    </>
  );
};

export default Filters;
