export default {
  na: 'N/A',
  required_field: 'Field is required',
  characters_field: 'Your text exceeds the character limit',
  cancel_statement: 'Are you sure you want to cancel?',
  cancel_2statement: 'Are you sure you want to cancel? The changes you made won’t be saved.',
  ok: 'OK',
  yes_cancel: 'Yes, cancel',
  submit: 'Submit',
  next:"Next",
  keep: 'Keep',
  edit: 'Edit',
  back: 'Back',
  delete: 'Delete',
  remove_file: 'Remove file?',
  remove: 'Remove',
  add: 'Add',
  allowed_type: 'Allowed Type:',
  added_success: 'added successfully',
  update: 'Update',
  update_by: 'Updated By',
  cancel: 'Cancel',
  updated_success: 'Updated successfully',
  add_a_category: 'Add a New category',
  add_a_jobTitle: 'Add Job Title',
  enter_category_name: 'Enter category name',
  enter_job_name: 'Enter job title name',
  enter_jobTitle_name: 'Enter job title name',
  details: 'Details',
  orders: 'Orders',
  created_by: 'Created By',
  yes_remove: 'Yes, Remove',
  files: 'Files',
  click_qr_to_download: 'Click on QR code to download',
  name: 'Name',
  type: 'Type',
  size: 'Size',
  view: 'View',
  no_result_found: 'No result found',
  no_results_found: 'No results found',
  yes: 'Yes',
  no: 'No',
  hi: 'Hi',
  my_profile: 'My Profile',
  settings: 'Settings',
  sign_out: 'Sign out',
  profile_information: 'Profile Information',
  user_name: 'User Name',
  user_email: 'User Email',
  phone: 'Phone',
  profile_image: 'Profile Image',
  change_password: 'Change Password',
  old_password: 'Old Password',
  new_password: 'New Password',
  confirm_password: 'Confirm Password',
  save: 'Save',
  saved: 'Saved',
  password_validation:
    "'The password should contain numbers, capital letters, small letters and specials characters #@!*& '",
  min_6_symbol: 'Minimum 6 symbols',
  max_50_symbol: 'Maximum 50 symbols',
  password_should_match: "Password doesn't match, re-enter password",
  language: 'Language',
  company_profile: 'Company Profile',
  on: 'on',
  invited_by: 'Invited By',
  company_information: 'Company Information',
  description: 'Description',
  company_name: 'Company Name',
  company_logo: 'Company Logo',
  industry: 'Industry',
  country: 'Country',
  currency: 'Currency',
  timezone: 'Timezone',
  contact_information: 'Contact Information',
  license_information: 'License Information',
  plan: 'Plan',
  licenses: 'Licenses',
  company_profile_update_success: 'Company profile updated successfully.',
  later: 'Later',
  add_files: 'Add files',
  invalid_email: 'Invalid email',
  forgot_password_success: 'Link sent successfully. Please check your mail.',
  phone_number: 'Phone Number',
  address: 'Address',
  sms: 'SMS',
  reach_quiqflow: 'Reach Quiqflow',
  about: 'About',
  contact: 'Contact',
  notifications: 'Notifications',
  date_placeholder: 'dd/mm/yyyy',
  something_went_wrong: 'Something went wrong, Please try again later.',
  of: 'of',
  january: 'January',
  february: 'February',
  march: 'March',
  april: 'April',
  may: 'May',
  june: 'June',
  july: 'July',
  august: 'August',
  september: 'September',
  october: 'October',
  november: 'November',
  december: 'December',
  all: 'All',
  export_pdf: 'Export Pdf',
  export_excel: 'Export Excel',
  close: 'Close',
  page_action: 'Page actions',
  share: 'Share',
  download_sheet: 'Download sheets',
  download_pdf: 'Download as PDF',
  upload: 'Upload',
  print: 'Print',
  download_QR: 'Download QR Code',
  there_no:'There are no',
  added:"added",
  something_wrong: 'Something went wrong. Please try after some time',
  loading:"Loading",
  reset: "Reset",
  hrs:"Hrs",
  yr:"Yr",
  no_option:"No option Found",
  add_to:"Add to",
  newOrder:"New Order",
  reason:"Reason",
  power_by:"Powered by",
  quantity_must_be_greater_than_zero:"Quantity should be greater than zero",
  cost_must_be_greater_than_zero:"Unit cost should be greater than zero",
  valid_digits:"Exceeds 8 digits",
  excel_download:"Download Sheet",
  upload_sheet:"Upload Sheet",


};
