import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import { CloseIcon, Success } from '../../Assets/Icons/svg';
import { selectTranslations } from '../../app/config/i18n/slice';

const SuccessModal = ({
  isOpen = false,
  setIsOpen = () => {},
  closeCallback = () => {},
  children,
}) => {
  const t = useSelector(selectTranslations);

  return (
    <Modal
      size="tiny"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        closeCallback();
      }}
      style={{ minHeight: '195px' }}
      className={`delete-result-modal success`}
    >
      <div className="text-right mt-5 mr-5 ml-5">
        <span
          className="c-pointer"
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
        >
          <CloseIcon />
        </span>
      </div>
      <Modal.Content>
        <div style={{ textAlign: 'center',marginTop:"-9px" }}>
          <div className="mb-5">
            <Success />
          </div>
          <p className="status-modal-popup-message">{children}</p>
        </div>
      </Modal.Content>
      <Modal.Actions>
        {/* <Button
          color="black"
          className="button-transparent"
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
        >
          {t.common.ok}
        </Button> */}
      </Modal.Actions>
    </Modal>
  );
};

export function useSuccessModal() {
  const [isOpen, setIsOpen] = useState(false);

  const SuccessModalCallback = useCallback(({ children, closeCallback }) => {
    return (
      <SuccessModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        closeCallback={closeCallback}
      >
        {children}
      </SuccessModal>
    );
  });
  return useMemo(
    () => ({
      setSuccessModal: setIsOpen,
      SuccessModal: SuccessModalCallback,
    }),
    [setIsOpen, SuccessModalCallback]
  );
}

export default useSuccessModal;
