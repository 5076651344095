import { filter } from 'lodash';
import React, { useEffect, useState, createRef } from 'react';
import _ from 'lodash';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  DropdownMenu,
  Menu,
  DropdownItem,
} from 'semantic-ui-react';

import calendar_icon from '../../../Assets/Icons/calender-icon.svg';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import {
  getAllLocations,
  getAllCustomers,
  getUsersByRole,
  humanize,
  toggle,
  IMAGES_URL,
} from '../../config/functions';

import md5 from 'md5';

import moment from 'moment';

import { Country, State, City } from 'country-state-city';

import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import {
  DownloadIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  UploadIcon,
  WhiteDownChevron,
} from '../../../Assets/Icons/svg';
import CategoryDropdown from '../../components/common/CategoryDropdown';
import DatePicker from 'react-multi-date-picker';
import SearchDropdown from '../../components/common/SearchDropdown';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import {
  fetchAllLoctionData,
  fetchLocationsWithFilter,
} from '../../../redux/reducers/locationReducer';
import { toast } from 'react-toastify';

const Filters = ({
  data,
  setDataByFilter,
  hasStatus,
  fileData,
  setFilterOptions,
  filterOptions,
  setSearchTitle,
  searchTitle,
  debounceValue,
  setloader,
  loader,
  sortData,
  role,allIds,currentUserId
}) => {
  const t = useSelector(selectTranslations);
  const dispatch = useDispatch();
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const [filtersTrigger, setFiltersTrigger] = useState({
    customers: {
      initOptions: [],
      options: [],
    },
    countries: {
      initOptions: [],
      options: [],
    },
    cities: {
      initOptions: [],
      options: [],
    },
    createdByUser: {
      initOptions: [],
      options: [],
    },

    createdAt: {
      options: [
        { key: 0, text: t.locations.created_at_options.any_date, value: 0 },
        { key: 1, text: t.locations.created_at_options.today, value: 1 },
        { key: 2, text: t.locations.created_at_options.this_month, value: 2 },
        { key: 3, text: t.locations.created_at_options.this_year, value: 3 },
        { key: 4, text: t.locations.created_at_options.last_7_days, value: 4 },
        { key: 5, text: t.locations.created_at_options.last_month, value: 5 },
        { key: 7, text: t.locations.created_at_options.last_year, value: 6 },
      ],
    },
    priority: {
      options: [
        { key: 0, text: 'None', value: 'none', color: null },
        { key: 1, text: 'Low', value: 'low', color: 'blue' },
        { key: 2, text: 'Medium', value: 'medium', color: 'orange' },
        { key: 3, text: 'High', value: 'high', color: 'red' },
      ],
    },

    status: {
      options: [
        { key: 1, text: 'Declined', value: 'declined', color: 'red' },
        { key: 2, text: 'Approved', value: 'approved', color: 'green' },
      ],
    },
  });

  const [filterSearch, setFilterSearch] = useState({
    country: '',
    city: '',
    customers: '',
    createdByUser: '',
  });

  const [newData, setNewData] = useState(data);

  const [openCustomer, setOpenCustomer] = useState(false);
  const [openCountry, setOpenCountry] = useState(false);
  const [openCity, setOpenCity] = useState(false);
  const [openCreatedAt, setOpenCreatedAt] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openCreatedBy, setOpenCreatedBy] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const closeAllFilters = () => {
    setOpenCustomer(false);
    setOpenCountry(false);
    setOpenCity(false);
    setOpenCreatedAt(false);
    setOpenPriority(false);
    setOpenStatus(false);
    setOpenCreatedBy(false);
  };

  const fetchCountryOptions = () => {
    let the_options = [];

    // if (filtersTrigger.countries.initOptions.length < 1) {
    const fetchData = Country.getAllCountries();

    if (fetchData) {
      the_options = fetchData.map((el) => {
        return {
          key: el?.isoCode?.toLowerCase(),
          value: el?.isoCode,
          flag: el?.isoCode?.toLowerCase(),
          text: el.name,
        };
      });

      setFiltersTrigger({
        ...filtersTrigger,
        countries: {
          ...filtersTrigger.countries,
          initOptions: the_options,
          options: the_options,
        },
      });
    }
    // }
  };
  const headers = [
    { label: t.locations.table.ID, key: 'id' },
    { label: t.locations.form.location_name, key: 'name' },
    { label: t.locations.table.address, key: 'address' },
    { label: t.locations.table.country, key: 'country' },
    { label: t.locations.table.city, key: 'city' },
    { label: t.locations.table.customer, key: 'customer' },
    // { label: t.locations.form.vendors, key: 'vendors' },
    // { label: t.locations.form.people, key: 'people' },
    // { label: t.locations.form.teams, key: 'teams' },
    { label: t.locations.form.propertyType, key: 'propertyType' },
    // { label: t.locations.table.createdByUser, key: 'createdBy' },
    // { label: t.procedures.table.createdAt, key: 'createdAt' },
  ];

  const downloadXLS = async () => {
    const hasValues = Object.values(filterOptions).some(
      (option) => option.length > 0
    );

    const currentPathname = window.location.pathname;
    let csvData = [];
    if (currentPathname == '/locations-map-view') {
    } else {
      if (!hasValues && searchTitle == '') {
        let mydata = await dispatch(fetchAllLoctionData({organisationId,role,allIds,currentUserId}));
        if (mydata.payload.response) {
          fileData = mydata.payload.response;
        } else {
          toast.error(t.common.something_wrong);
        }
      }
    }

    for (let i = 0; i < fileData.length; i++) {
      let location_data = [];

      location_data.id = fileData[i].id;
      location_data.name = fileData[i].attributes.name || t.common.na;
      location_data.address = fileData[i]?.attributes?.address
        ? fileData[i].attributes.address
        : t.common.na;
      location_data.country =
        fileData[i]?.attributes?.country &&
        Country.getCountryByCode(fileData[i].attributes.country)?.name
          ? Country.getCountryByCode(fileData[i].attributes.country)?.name
          : t.common.na;
      location_data.city =
        fileData[i]?.attributes?.city &&
        State.getStateByCodeAndCountry(
          fileData[i]?.attributes?.city,
          fileData[i]?.attributes?.country
        )?.name
          ? State.getStateByCodeAndCountry(
              fileData[i]?.attributes?.city,
              fileData[i]?.attributes?.country
            )?.name
          : t.common.na;

      let customerData = fileData[i].attributes?.customers?.data;
      let customerString = '';

      if (customerData?.length > 0) {
        customerString = customerData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        customerString = t.common.na;
      }

      location_data.customer = customerString;

      let vendorData = fileData[i].attributes?.vendors?.data;
      let vendorString = '';

      if (vendorData?.length > 0) {
        vendorString = vendorData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        vendorString = t.common.na;
      }

      location_data.vendor = vendorString;

      const locationData = fileData[i].attributes?.propertyType;
      let displayText;

      if (locationData && Array.isArray(locationData)) {
        displayText = locationData
          .map((location) => {
            const nameParts = location?.property;
            return nameParts;
          })
          .join(', '); // Adjust the join character as needed
      } else {
        displayText = t.common.na;
      }

      let peopleData = fileData[i].attributes?.users?.data;
      let peopleString = '';

      if (peopleData?.length > 0) {
        peopleString = peopleData
          .map(
            (each) =>
              each.attributes?.firstName + ' ' + each.attributes?.lastName
          )
          .join(', ');
      } else {
        peopleString = t.common.na;
      }

      location_data.people = peopleString;

      let teamsData = fileData[i].attributes?.teams?.data;
      let teamsString = '';

      if (teamsData?.length > 0) {
        teamsString = teamsData.map((each) => each.attributes.name).join(', ');
      } else {
        teamsString = t.common.na;
      }

      location_data.teams = teamsString;

      location_data.createdByUser =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;

      location_data.createdAt =
        moment(fileData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        location_data.id,
        location_data.name,
        location_data.address,
        location_data.country,
        location_data.city,
        location_data.customer,
        displayText ? displayText : t.common.na,
        // location_data.vendor,
        // location_data.people,
        // location_data.teams,
        // location_data.createdByUser,
        // location_data.createdAt,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 25 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 25 }, // Created By column
      { wch: 25 }, // Created At column
      { wch: 25 }, // Updated At column
      { wch: 20 }, // Updated At column
      { wch: 20 }, // Updated At column
    ];
    DownloadExcelFileWithData(
      headers,
      csvData,
      csvColumnWidths,
      t.locations.title
    );
  };

  const generatePDF = async () => {
    const currentPathname = window.location.pathname;
    let csvData = [];
    if (currentPathname == '/locations-map-view') {
      fileData = data;
    } else {
      let mydata = await dispatch(fetchAllLoctionData({organisationId,role,allIds,currentUserId}));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error('something went wrong');
      }
    }
    for (let i = 0; i < fileData.length; i++) {
      let location_data = [];

      location_data.id = fileData[i].id;
      location_data.name = fileData[i].attributes.name || t.common.na;
      location_data.address = fileData[i]?.attributes?.address
        ? fileData[i].attributes.address
        : t.common.na;
      location_data.country =
        fileData[i]?.attributes?.country &&
        Country.getCountryByCode(fileData[i]?.attributes.country)?.name
          ? Country.getCountryByCode(fileData[i]?.attributes.country)?.name
          : t.common.na;
      location_data.city =
        fileData[i]?.attributes?.city &&
        State.getStateByCodeAndCountry(
          fileData[i]?.attributes?.city,
          fileData[i]?.attributes?.country
        )?.name
          ? State.getStateByCodeAndCountry(
              fileData[i]?.attributes?.city,
              fileData[i]?.attributes?.country
            )?.name
          : t.common.na;

      let customerData = fileData[i].attributes?.customers?.data;
      let customerString = '';

      if (customerData?.length > 0) {
        customerString = customerData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        customerString = t.common.na;
      }

      location_data.customer = customerString;

      let vendorData = fileData[i].attributes?.vendors?.data;
      let vendorString = '';

      if (vendorData?.length > 0) {
        vendorString = vendorData
          .map((each) => each.attributes?.name)
          .join(', ');
      } else {
        vendorString = t.common.na;
      }

      const locationData = fileData[i].attributes?.propertyType;
      let displayText;

      if (locationData && Array.isArray(locationData)) {
        displayText = locationData
          .map((location) => {
            const nameParts = location?.property;
            return nameParts;
          })
          .join(', '); // Adjust the join character as needed
      } else {
        displayText = t.common.na;
      }

      location_data.vendor = vendorString;

      let peopleData = fileData[i].attributes?.users?.data;
      let peopleString = '';

      if (peopleData?.length > 0) {
        peopleString = peopleData
          .map(
            (each) =>
              each.attributes?.firstName + ' ' + each.attributes?.lastName
          )
          .join(', ');
      } else {
        peopleString = t.common.na;
      }

      location_data.people = peopleString;

      let teamsData = fileData[i].attributes?.teams?.data;
      let teamsString = '';

      if (teamsData?.length > 0) {
        teamsString = teamsData.map((each) => each.attributes.name).join(', ');
      } else {
        teamsString = t.common.na;
      }

      location_data.teams = teamsString;

      location_data.createdByUser =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;

      location_data.createdAt =
        moment(fileData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        location_data.id,
        location_data.name,
        location_data.address,
        location_data.country,
        location_data.city,
        location_data.customer,
        displayText ? displayText : t.common.na,
        // location_data.vendor,
        // location_data.people,
        // location_data.teams,
        // location_data.createdByUser,
        // location_data.createdAt,
      ]);
    }

    // const columnWidths = [10, 20, 25, 20, 30, 30, 30, 30, 30, 20];
    DownloadPdfFileWithData(headers, csvData, 'Locations', [], 'A3');
  };

  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedDates([]);
  };

  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ''
      );
      setSelectedDates(isoDates);
    } else {
      setSelectedDates([]);
    }
  };

  const fetchPeopleOptions = async () => {
    const fetchData = await getUsersByRole(
      ['user', 'supervisor', 'view_only', 'admin','limited_admin', 'requester', 'super_admin'],
      organisationId
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName + ' ' + el.lastName,
          image: {
            avatar: true,
            src: el.profileImage?.url
              ? IMAGES_URL + el.profileImage?.url
              : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
            style: { width: '16px', height: '16px' },
          },
        };
      });
      setFiltersTrigger({
        ...filtersTrigger,
        // createdByUser: {
        //   ...filtersTrigger.createdByUser,
        //   initOptions: the_options,
        //   options: the_options,
        // },
      });
    }
  };

  const handleSearchCountryChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      country: e.target.value,
    });
  };

  const handleSearchCityChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      city: e.target.value,
    });
  };

  const handleSearchCustomersChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      customer: e.target.value,
    });
  };

  const handleSearchCreatedByChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      createdByUser: e.target.value,
    });
  };

  useEffect(() => {
    (async function() {
      let the_options_customer = [];
      let the_options = [];
      let the_options_createdBy = [];

      const fetchData = Country.getAllCountries();
      if (fetchData) {
        fetchData.forEach((el) => {
          the_options.push({
            key: el?.isoCode?.toLowerCase(),
            value: el?.isoCode,
            text: el.name,
            flag: el?.isoCode?.toLowerCase(),
          });
        });
      }



      const fetchCustomerData = await  getAllCustomers(organisationId,'',1,99999,  !(role === "super_admin" || role === "admin") ? allIds : [])
      

      if (fetchCustomerData?.response) {
        fetchCustomerData.response.forEach((el) => {
          the_options_customer.push({
            key: `${el.id}`,
            value: `${el.id}`,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: el.attributes.logo.data
                ? IMAGES_URL + el.attributes.logo.data.attributes.url
                : LetteredAvatarImageUrl(el.attributes.name + ' '),
              style: { width: '20px', height: '20px' },
            },
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,
        customers: {
          ...filtersTrigger.customers,
          initOptions: the_options_customer,
          options: the_options_customer,
        },
        countries: {
          ...filtersTrigger.countries,
          initOptions: the_options,
          options: the_options,
        },
        // createdByUser: {
        //   ...filtersTrigger.createdByUser,
        //   initOptions: the_options_createdBy,
        //   options: the_options_createdBy,
        // },
      });
    })();
  }, []);


  useEffect(() => {
    (async function() {
      let the_options = [];

      // setFiltersTrigger({
      //   ...filtersTrigger,
      //   cities: {
      //     ...filtersTrigger.cities,
      //     initOptions: [],
      //     options: [],
      //   },
      // });

      if (filterOptions.countries) {
        const fetchData = await State.getStatesOfCountry(
          filterOptions.countries[0]
        );

        if (fetchData) {
          fetchData.forEach((el) => {
            the_options.push({
              key: `${el.isoCode}`,
              value: `${el.isoCode}`,
              text: el.name,
            });
          });
        }

        // console.log('THE COUNTRY SELECTED', the_options);

        setFiltersTrigger({
          ...filtersTrigger,
          cities: {
            initOptions: the_options,
            options: the_options,
          },
        });
      } else {
        setFiltersTrigger({
          ...filtersTrigger,
          cities: {
            ...filtersTrigger.cities,
            initOptions: [],
            options: [],
          },
        });
      }
    })();
  }, [filterOptions.countries]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.country), 'i');
    const isMatch = (result) => re.test(result.text);
    setFiltersTrigger({
      ...filtersTrigger,
      countries: {
        ...filtersTrigger.countries,
        options:
          filterSearch.country.length > 0
            ? _.filter(filtersTrigger.countries.initOptions, isMatch)
            : filtersTrigger.countries.initOptions,
      },
    });
    document.body.addEventListener('click', closeAllFilters);

    // handleRequestedByTypeChange(filtersTrigger.requestedByType);
  }, [filterSearch.country]);

  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.requestedBy), 'i');
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      customers: {
        ...filtersTrigger.customers,
        options:
          filterSearch.customers.length > 0
            ? _.filter(filtersTrigger.customers.initOptions, isMatch)
            : filtersTrigger.customers.initOptions,
      },
    });
  }, [filterSearch.customers]);

  // useEffect(() => {
  //   const re = new RegExp(_.escapeRegExp(filterSearch.createdByUser), 'i');
  //   const isMatch = (result) => re.test(result.text);

  //   setFiltersTrigger({
  //     ...filtersTrigger,
  //     createdByUser: {
  //       ...filtersTrigger.createdByUser,
  //       options:
  //         filterSearch.createdByUser.length > 0
  //           ? _.filter(filtersTrigger.createdByUser.initOptions, isMatch)
  //           : filtersTrigger.createdByUser.initOptions,
  //     },
  //   });
  // }, [filterSearch.createdByUser]);

  let _newData = newData;
  useEffect(() => {
    if (filterOptions?.customers?.length > 0)
      _newData = _newData.filter((el) => {
        return el?.attributes?.customers?.data?.some((item) =>
          filterOptions.customers.includes(`${item.id}`)
        );
      });

    if (filterOptions.countries.length > 0)
      _newData = _newData.filter((el) => {
        return filterOptions.countries.includes(el.attributes.country);
      });

    if (filterOptions.cities.length > 0)
      _newData = _newData.filter((el) => {
        return filterOptions.cities.includes(el.attributes.city);
      });

    if (filterOptions.createdByUser.length > 0)
      _newData = _newData.filter((el) => {
        return filterOptions.createdByUser.includes(
          el.attributes.createdByUser?.data?.id
        );
      });

    if (selectedDates.length == 2) {
      if (selectedDates[0] == selectedDates[1]) {
        _newData = _newData.filter((el) => {
          var toDate = moment(selectedDates[1]).format('YYYY-MM-DD') || '';
          var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
          var createdAt =
            moment(el.attributes.createdAt).format('YYYY-MM-DD') || '';

          return createdAt >= fromDate && createdAt <= toDate;
        });
      } else {
        _newData = _newData.filter((el) => {
          var toDate =
            moment(selectedDates[1])
              .add(1, 'days')
              .format('YYYY-MM-DD') || '';
          var fromDate = moment(selectedDates[0]).format('YYYY-MM-DD') || '';
          var createdAt =
            moment(el.attributes.createdAt).format('YYYY-MM-DD') || '';

          return createdAt >= fromDate && createdAt <= toDate;
        });
      }
    } else if (selectedDates.length == 1) {
      _newData = _newData.filter((el) => {
        var fromDate =
          moment(selectedDates[0])
            .subtract(1, 'days')
            .format('YYYY-MM-DD') || '';

        return el.attributes.createdAt >= fromDate;
      });
    }

    if (filterOptions.createdAt.value > 0) {
      if (filterOptions.createdAt.value == 1) {
        _newData = _newData.filter((el) => {
          return moment().isSame(el.attributes.createdAt, 'd');
        });
        //console.log('today');
      }

      if (filterOptions.createdAt.value == 2) {
        _newData = _newData.filter((el) => {
          return moment().isSame(el.attributes.createdAt, 'month');
        });
        //console.log('This Month');
      }

      if (filterOptions.createdAt.value == 3) {
        _newData = _newData.filter((el) => {
          return moment().isSame(el.attributes.createdAt, 'year');
        });
        //console.log('This Year');
      }

      if (filterOptions.createdAt.value == 4) {
        _newData = _newData.filter((el) => {
          var toDate = moment().format('YYYY-MM-DD');
          var fromDate = moment()
            .subtract(7, 'days')
            .format('YYYY-MM-DD');

          return (
            el.attributes.createdAt >= fromDate &&
            el.attributes.createdAt <= toDate
          );
        });
        //console.log('last 7 days');
      }

      if (filterOptions.createdAt.value == 5) {
        _newData = _newData.filter((el) => {
          return moment()
            .subtract(1, 'months')
            .isSame(el.attributes.createdAt, 'month');
        });
        //console.log('Last month');
      }

      if (filterOptions.createdAt.value == 6) {
        _newData = _newData.filter((el) => {
          return moment()
            .subtract(1, 'years')
            .isSame(el.attributes.createdAt, 'year');
        });
        //console.log('Last year');
      }
    }

    const currentPathname = window.location.pathname;

    (async function() {
    

      // Update the state with the current pathname
      if (currentPathname == '/locations-map-view') {
        await dispatch(
          fetchLocationsWithFilter({
            organisationId,
            searchName: debounceValue,
            page: '1',
            pageSize: '9999',
            customer: filterOptions.customers,
            country: filterOptions.countries,
            city: filterOptions.cities,
            role,
            allIds,
            currentUserId
          })
        );
      } else {
        setloader(true);
        await dispatch(
          fetchLocationsWithFilter({
            organisationId,
            searchName: debounceValue,
            page: '1',
            pageSize: '25',
            customer: filterOptions.customers,
            country: filterOptions.countries,
            city: filterOptions.cities,
            sort: sortData,
            role,
            allIds,
            currentUserId
          })
        );
        setloader(false);
      }
      
    })();

    // if (searchTitle.length > 0) {
    //   const re = new RegExp(_.escapeRegExp(searchTitle), 'i');
    //   const isMatch = (result) =>
    //     re.test(result.attributes.name) || re.test(result.id);

    //   _newData =
    //     searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    // }

    // if (_newData.length > 0) {
    //   setDataByFilter(_newData);
    // } else {
    //   setDataByFilter([]);
    // }
  }, [filterOptions, debounceValue, selectedDates]);

  let customersPlaceholder = t.locations.filter.customers;
  if (!(filterOptions.customers.length > 0)) {
    customersPlaceholder = t.locations.filter.customers;
  } else {
    customersPlaceholder =
      filterOptions.customers.length > 0
        ? filterOptions.customers.length + ' customers'
        : '';
  }

  let createdByPlaceholder = t.locations.filter.createdByUser;
  if (!(filterOptions.createdByUser.length > 0)) {
    createdByPlaceholder = t.locations.filter.createdByUser;
  } else {
    createdByPlaceholder =
      filterOptions.createdByUser.length > 0
        ? filterOptions.createdByUser.length + ' People'
        : '';
  }

  return (
    <>
      <div className="filters">
        <div className="text-right mb-3 pb-5 d-flex justify-content-between align-items-center views">
          <div className="list-view-option">
            <p className="single-view">
              <NavLink to="/locations">{t.locations.tabs.first}</NavLink>
            </p>
            <p className="single-view">
              <NavLink to="/locations-map-view">
                {t.locations.tabs.second}
              </NavLink>
            </p>
          </div>
          <div className="table-searchbar" style={{ width: '240px' }}>
            <span className="search-svg">
              <SearchIcon />
            </span>
            <Input
              icon={{ name: 'search' }}
              placeholder={t.locations.filter.search}
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* <Input
              // icon={{ name: 'search' }}
              placeholder={t.locations.filter.search}
              value={searchTitle}
              onChange={(e) => setSearchTitle(e.target.value)}
            /> */}

      <div className="filters-wrapper justify-content-between">
        <div className="flexbox table-right-dropdown">
          {/* customer */}
          {/* <Dropdown
          icon="briefcase"
          floating
          labeled
          button
          basic
          multiple
          className="icon"
          text={customersPlaceholder}
          open={openCustomer}
          style={{ marginRight: '10px' }}
          onOpen={() => {
            closeAllFilters();
            setOpenCustomer(true);
            fetchCustomersOptions();
          }}
        >
          <Dropdown.Menu>
            <Input
              autoComplete="new-password"
              icon="search"
              iconPosition="left"
              className="search"
              value={filterSearch.requestedBy}
              onChange={handleSearchCustomersChange}
            />
            <Dropdown.Divider />
            <Dropdown.Header content={t.locations.filter.customers} />
            <Dropdown.Menu scrolling>
              {filtersTrigger.customers.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  {...option}
                  selected={filterOptions.customers.includes(option.value)}
                  onClick={() => {
                    const newArray = toggle(
                      filterOptions.customers,
                      option.value
                    );

                    setFilterOptions({
                      ...filterOptions,
                      customers: newArray,
                    });

                    setFilterSearch({
                      ...filterSearch,
                      customers: '',
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown> */}

          <CategoryDropdown
            title={t.locations.table.customer}
            options={filtersTrigger.customers.options}
            selectedValues={filterOptions.customers}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions({
                  ...filterOptions,
                  customers: [...filterOptions.customers, e.target.value],
                });
              } else {
                let filteredLocations = filterOptions.customers.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  customers: filteredLocations,
                });
              }
            }}
            icon={false}
            image={true}
          />

          {/* countries */}

          <CategoryDropdown
            title={t.locations.table.country}
            options={filtersTrigger.countries.options}
            selectedValues={filterOptions.countries}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions((prevState) => {
                  return {
                    ...prevState,
                    countries: [e.target.value],
                  };
                });
              } else {
                let filteredStatus = filterOptions.countries.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  countries: filteredStatus,
                });
              }
            }}
            icon={false}
            image={false}
          />
          {/* <Dropdown
          text={
            filterOptions.countries
              ? Country.getCountryByCode(filterOptions.countries).name
              : t.locations.filter.countries
          }
          icon="globe"
          floating
          labeled
          button
          basic
          className="icon"
          style={{ marginRight: '10px' }}
          onOpen={() => fetchCountryOptions()}
          open={openCountry}
        >
          <Dropdown.Menu>
            <Input
              autoComplete="new-password"
              icon="search"
              iconPosition="left"
              className="search"
              value={filterSearch.country}
              onChange={handleSearchCountryChange}
            />
            <Dropdown.Divider />
            <Dropdown.Header content={t.locations.filter.search_by_country} />
            <Dropdown.Menu scrolling>
              {filtersTrigger.countries.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  {...option}
                  selected={filterOptions.countries == option.value}
                  onClick={() => {
                    setFilterOptions({
                      ...filterOptions,
                      countries: option.value,
                    });
                    setFilterOptions({
                      ...filterOptions,
                      countries:
                        filterOptions.countries == option.value
                          ? ''
                          : option.value,
                    });

                    setFilterSearch({
                      ...filterSearch,
                      country: '',
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown> */}

          {/* cities */}
          {/* {console.log(filtersTrigger, filterOptions)} */}

          <CategoryDropdown
            title={t.locations.table.city}
            options={filtersTrigger.cities.options}
            selectedValues={filterOptions.cities}
            onChange={(e) => {
              if (e.target.checked) {
                setFilterOptions((prevState) => {
                  return {
                    ...prevState,
                    cities: [e.target.value],
                  };
                });
              } else {
                let filteredStatus = filterOptions.cities.filter(
                  (el) => el !== e.target.value
                );
                setFilterOptions({
                  ...filterOptions,
                  cities: filteredStatus,
                });
              }
            }}
            icon={false}
            image={false}
          />

          {/* <Dropdown
          text={
            filterOptions.cities.length > 0
              ? filterOptions.cities.length + ` ${t.locations.filter.selected} `
              : t.locations.filter.cities
          }
          icon="building outline"
          floating
          labeled
          button
          basic
          multiple
          className="icon"
          style={{ marginRight: '10px' }}
          onOpen={() => {
            closeAllFilters();

            setOpenCity(true);
          }}
          open={openCity}
        >
          <Dropdown.Menu>
            <Input
              autoComplete="new-password"
              icon="search"
              iconPosition="left"
              className="search"
              value={filterSearch.city}
              onChange={handleSearchCityChange}
            />
            <Dropdown.Divider />
            <Dropdown.Header content={t.locations.filter.search_by_cities} />
            <Dropdown.Menu scrolling>
              {filtersTrigger.cities.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  {...option}
                  selected={filterOptions.cities.includes(option.value)}
                  onClick={() => {
                    const newArray = toggle(filterOptions.cities, option.value);
                    setFilterOptions({
                      ...filterOptions,
                      cities: newArray,
                    });

                    setFilterSearch({
                      ...filterSearch,
                      city: '',
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown> */}

          {/* craeted at */}
          {/* <Dropdown
          icon="calendar alternate outline"
          style={{ marginRight: '10px' }}
          floating
          button
          labeled
          basic
          options={filtersTrigger.createdAt.options}
          className="icon"
          text={filterOptions.createdAt.text}
          open={openCreatedAt}
          onOpen={() => {
            closeAllFilters();

            setOpenCreatedAt(true);
            setFiltersTrigger({
              ...filtersTrigger,
              createdAt: {
                ...filtersTrigger.createdAt,
                open: true,
              },
            });
          }}
          onChange={(e, { value, text }) => {
            // console.log(value);
            setFilterOptions({
              ...filterOptions,
              createdAt: {
                ...filterOptions.createdAt,
                value,
                text: value.text,
              },
            });
          }}
        /> */}

          {/* created by */}
          {/* <SearchDropdown
            title={t.workOrders.filter.createdByUser}
            options={filtersTrigger.createdByUser.options}
            selectedValues={filterOptions.createdByUser}
            searchValue={filterSearch.createdByUser}
            handleSearchValue={handleSearchCreatedByChange}
            onClick={(value) => {
              const newArray = toggle(filterOptions.createdByUser, value);

              setFilterOptions({
                ...filterOptions,
                createdByUser: newArray,
              });

              setFilterSearch({
                ...filterSearch,
                createdByUser: '',
              });
            }}
          /> */}
          {/* <Dropdown
          icon="user circle"
          floating
          labeled
          button
          basic
          multiple
          className="icon"
          text={createdByPlaceholder}
          open={openCreatedBy}
          style={{ marginRight: '10px' }}
          onOpen={() => {
            closeAllFilters();
            setOpenCreatedBy(true);
            fetchPeopleOptions();
          }}
        >
          <Dropdown.Menu>
            <Input
              autoComplete="new-password"
              icon="search"
              iconPosition="left"
              className="search"
              value={filterSearch.createdByUser}
              onChange={handleSearchCreatedByChange}
            />
            <Dropdown.Divider />
            <Dropdown.Header content={t.locations.filter.select_people} />
            <Dropdown.Menu scrolling>
              {filtersTrigger.createdByUser.options.map((option) => (
                <Dropdown.Item
                  key={option.key}
                  {...option}
                  selected={filterOptions.createdByUser.includes(option.value)}
                  onClick={() => {
                    const newArray = toggle(
                      filterOptions.createdByUser,
                      option.value
                    );

                    setFilterOptions({
                      ...filterOptions,
                      createdByUser: newArray,
                    });

                    setFilterSearch({
                      ...filterSearch,
                      createdByUser: '',
                    });
                  }}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown.Menu>
        </Dropdown> */}

          {/* <div>
            <DatePicker
              value={selectedDates}
              onChange={handleChange}
              range
              showMonthDropdown
              showYearDropdown
              placeholder={t.locations.filter.createdAt}
              className={'active'}
              // locale={lang == 'ar' ? arabic_ar : ''}
              weekDays={[
                t.workOrders.details_view.sun,
                t.workOrders.details_view.mon,
                t.workOrders.details_view.tue,
                t.workOrders.details_view.wed,
                t.workOrders.details_view.thu,
                t.workOrders.details_view.fri,
                t.workOrders.details_view.sat,
              ]}
              months={[
                t.common.january,
                t.common.february,
                t.common.march,
                t.common.april,
                t.common.may,
                t.common.june,
                t.common.july,
                t.common.august,
                t.common.september,
                t.common.october,
                t.common.november,
                t.common.december,
              ]}
              format="DD/MM/YY"
            >
              <button onClick={handleDeselect} className="btn-datepicker blue">
                {t.assets.reset}
              </button>
            </DatePicker>
            <img className="date-picker-image" src={calendar_icon} />
          </div> */}
        </div>
        <div className="custom-menu-dropdown">
          <UncontrolledDropdown className="mr-2" direction="down">
            <DropdownToggle color="primary" className="option-toggle">
              {t.common.page_action} <WhiteDownChevron />{' '}
            </DropdownToggle>
            <DropdownMenu className={selectedLanguage == 'ar' ? 'rtl' : ''}>
              {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon />
                    {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
              <DropdownItem className="divider" />
              <DropdownItem onClick={() => downloadXLS()}>
                <DownloadIcon /> {t.common.download_sheet}
              </DropdownItem>
              <DropdownItem className="divider" />
              {/* <DropdownItem onClick={() => generatePDF()}>
                <PdfIcon /> {t.common.download_pdf}
              </DropdownItem>
              <DropdownItem className="divider" /> */}
              {/* <DropdownItem>
                <UploadIcon />
                {t.common.upload}
              </DropdownItem> */}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </>
  );
};

export default Filters;
