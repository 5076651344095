export default {
  na: 'غير متوفر',
  required_field: 'الحقل مطلوب',
  characters_field:"النص الخاص بك يتجاوز الحد الأقصى لعدد الأحرف",
  cancel_statement: 'هل أنت متأكد أنك تريد إلغاء؟',
  cancel_2statement:'هل أنت متأكد أنك تريد الإلغاء؟ لن يتم حفظ التغييرات التي أجريتها.',
  ok: 'موافق',
  submit: 'تقديم',
  back: 'رجوع',
  next: 'تقديم',
  yes_cancel: 'نعم ، إلغاء',
  keep: 'استمر',
  edit: 'تعديل',
  delete: 'حذف',
  remove_file: 'إزالة الملف؟',
  remove: 'إزالة',
  add: 'إنشاء',
  allowed_type: 'الانواع المسموحة:',
  added_success: 'تمت الإضافة بنجاح',
  update: 'تحديث',
  update_by: 'تم التحديث من قبل',
  cancel: "إلغاء",
  updated_success: 'تم التحديث بنجاح',
  add_a_category: 'إضافة فئة',
  add_a_jobTitle: 'إنشاء مسمى وظيفي',
  enter_category_name: 'أدخل اسم الفئة',
  enter_job_name: 'أضف المسمى الوظيفي',
  enter_jobTitle_name: 'أدخل اسم المسمى الوظيفي',
  category_name: 'اسم الفئة',
  details: 'التفاصيل',
  orders: 'أوامر العمل  ',
  created_by: 'انشا من قبل',
  export_pdf: 'تصدير الى ملف PDF',
  saved: 'تم الحفظ',
  yes_remove: 'نعم، إزالة',
  files: 'ملفات',
  click_qr_to_download: 'انقر على رمز الاستجابة السريعة للتحميل',
  name: 'الاسم',
  type: 'النوع',
  size: 'الحجم',
  view: 'عرض',
  no_result_found: 'لم يتم العثور على نتائج',
  no_results_found: 'لم يتم العثور على نتائج',
  yes: 'نعم',
  no: 'لا',
  hi: 'مرحبا',
  my_profile: 'ملفي',
  settings: 'إعدادات',
  sign_out: 'خروج',
  profile_information: 'معلومات الملف الشخصي',
  user_name: 'اسم المستخدم',
  user_email: 'البريد الالكتروني',
  phone: 'رقم الهاتف',
  profile_image: 'صورة الملف الشخصي',
  change_password: 'تغيير كلمة المرور',
  old_password: 'كلمة المرور القديمة',
  new_password: 'كلمة المرور الجديدة',
  confirm_password: 'تأكيد كلمة المرور',
  save: 'حفظ',
  password_validation:
    "يجب أن تحتوي كلمة المرور على أرقام وأحرف كبيرة وأحرف صغيرة وأحرف خاصة #@!*& ",
  min_6_symbol: 'الحد الأدنى 6 رموز',
  max_50_symbol: 'الحد الأقصى 50 رمزًا',
  password_should_match: 'كلمة المرور غير متطابقة، أعد إدخال كلمة المرور',
  language: 'اللغة',
  company_profile: 'ملف الشركة',
  on: 'في',
  invited_by: 'تمت الدعوة بواسطة',
  company_information: 'معلومات الشركة',
  description: 'الوصف',
  company_name: 'اسم المؤسسة',
  company_logo: 'شعار المؤسسة',
  industry: 'القطاع',
  country: 'الدولة',
  currency: 'العملة',
  timezone: 'وحدة زمنية',
  contact_information: 'معلومات الاتصال',
  license_information: 'معلومات الترخيص',
  plan: 'خطة',
  licenses: 'التراخيص',
  company_profile_update_success: 'تم تحديث ملف الشركة بنجاح',
  later: 'لاحقًا',
  add_files: 'إضافة ملفات',
  invalid_email: 'بريد إلكتروني غير صالح',
  forgot_password_success: 'تم إرسال الرابط بنجاح. يرجى التأكد من بريدك الإلكتروني.',
  phone_number: 'رقم التليفون',
  address: 'العنوان',
  sms: 'الرسالة قصيرة',
  reach_quiqflow: 'تواصل ينا',
  about: 'عن Quiqflow',
  contact: 'اتصل بنا',
  notifications: 'الإشعارات',
  date_placeholder: 'يوم/شهر/سنة',
  something_went_wrong: 'هناك شئ خاطئ، يرجى المحاولة فى وقت لاحق',
  of: 'مِن',
  january: 'يناير',
  february: 'فبراير',
  march: 'مارس',
  april: 'أبريل',
  may: 'مايو',
  june: 'يونيو',
  july: 'يوليو',
  august: 'أغسطس',
  september: 'سبتمبر',
  october: 'أكتوبر',
  november: 'نوفمبر',
  december: 'ديسمبر',
  all: 'الفئة',

  page_action: 'إجراءات الصفحة',
  share: 'مشاركة',
  download_sheet: 'تنزيل كملف Excel',
  download_pdf: 'تنزيل كملف PDF',
  upload: 'تحميل',
  export_excel: 'تصدير الى Excel',
  close: 'إغلاق',
  loading:"جارِ التحميل",
  print: 'طباعة',
  download_QR: 'تحميل رمز الاستجابة السريعة',
  there_no:'لا يوجد',
  added:"مضاف",
  something_wrong: 'هناك خطأ ما. يرجى المحاولة لاحقاً',
  reset: "إعادة ضبط",
  hrs:"ساعات",
  yr:"سنة",
  power_by:"مطور بواسطة",
  no_option:"لم يتم العثور على أي خيار",
  add_to:"أضف إلى",
  newOrder:"أمر جديد",
  reason:"نص",
  quantity_must_be_greater_than_zero:"يجب أن تكون الكمية أكبر من الصفر",
  cost_must_be_greater_than_zero:"يجب أن يكون سعر القطعة أكبر من الصفر",
  valid_digits:"تجاوز الحد الأقصى المكون من 8 أرقام",
  excel_download:"تنزيل قائمة البيانات",
  upload_sheet:"تحميل قائمة البيانات",

};
