export default {
  sets_small: 'sets',
  title: 'Sets',
  add: 'Set',
  create_set: 'Add Set',
  add_set: 'Add Set',
  edit_set: 'Edit Set',
  start_add: 'Start adding sets',
  how_to_add: 'How to add sets',

  table: {
    no_of_parts: 'No. of Parts',
    total_cost: 'Total Cost',
    items: 'items',
  },
  form: {
    already:"Already includes a set",
    add_name: 'Add set name',
    add_parts: 'Add Parts',
    add_sets: 'Add Sets',
    search_part: 'Search parts',
    search: 'Search sets',
    qty: 'Qty',
    cancel_msg:
      'Are you sure you want to cancel this set? You will loose the information you have populated',
  },
  used:"Used",
  add_success: 'Set added successfully',
  update_success: 'Set updated successfully',
  delete_success: 'Set deleted successfully',
  delete_set_statement: 'Are you sure you want to delete this set?',
  delete_error:
    'Unable to delete set. Linked with work order or purchase order',
  wo_statement: 'This set is currently being used by the following work orders',
  add_valiadtion: 'Please add either 1 part and 1 set, or 2 parts, or 2 sets',
  no_part: 'There are no parts currently added to the system',
  no_set: 'There are no sets currently added to the system',
  no_data: 'There are no records to display',
  search_sets: 'Search sets',
  unique:"Set name must be unique",
};
