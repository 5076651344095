const DateConvert = (timestamp, timezone) => {
  // console.log(timezone);
  var targetTimezone =
    timezone.split(' ') && timezone.split('+')[1] ? timezone.split('+')[1] : 0;
  // Create a new Date object using the provided timestamp
  var date = new Date();
  // console.log(timestamp);

  // Get the UTC time by adding the timezone offset
  var utcTime =
    timestamp +
    // date.getTimezoneOffset() * 60000 +
    new Date().getUTCHours() * 60 * 60 * 1000 +
    new Date().getUTCMinutes() * 60 * 1000;
  // console.log('UTC', utcTime);

  // Create a new Date object using the UTC time
  //   var convertedDate = new Date(utcTime);

  // Get the target timezone offset in minutes
  var targetOffset = targetTimezone;

  // Get the target timezone time by adding the target timezone offset
  var targetTime = utcTime + targetOffset * 60 * 60 * 1000;

  return targetTime;
};
export default DateConvert;
