import React, { useEffect, useMemo, useState } from "react";
import DateDropdown from "../../components/common/DateDropdown";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../config/i18n/slice";
import {
  Accordion,
  Dropdown,
  Grid,
  GridColumn,
  Label,
  Modal,
} from "semantic-ui-react";
import moment from "moment";
import WorkOrderSearchDropdown from "../../components/WorkOrder/WorkOrderSearchDropdown";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import {
  IMAGES_URL,
  getAllCustomers,
  getAllParentLocations,
  getCostEstimateTimeData,
  getDashBoardData,
  getDashboardUsersByID,
  getResolutionTimeByPriorityData,
  getResponseTimeByPriorityData,
  getTimeEstimateData,
  getUsersByRole,
} from "../../config/functions";
import Tooltip from "react-power-tooltip";
import {
  AddIconDashboard,
  CategoryElectrical,
  CategoryInspection,
  CategoryMechanical,
  CategoryRefrigeration,
  CategorySafety,
  CatgoryPreventive,
  CloseIcon,
  CorrectiveIcon,
  Damage,
  DashboardWOStatusIcon,
  FullArrow,
  HashBlueIcon,
  HashSvg,
  OperationProcedure,
  PercentageBlueIcon,
  PercentageDashBoardBlueIcon,
  PercentageLightBlueIcon,
  PlannedIcon,
  Project,
  RefreshSvg,
  UnPlannedIcon,
} from "../../../Assets/Icons/svg";
import Chart from "react-apexcharts";
import ReactApexChart from "react-apexcharts";
import WorkOrderFilter from "./Filters/workOrderFilter";
import { toast } from "react-toastify";
import Animation from "../../components/common/CustomLoader";
import WoStatusChart from "./WoCharts/WoStatusChart";
import WoAssigneeChart from "./WoCharts/WoAssigneeChart";
import ResolutionTimeByTechnician from "./WoCharts/ResolutionTimeByTechnician";
import ResponseTimeByTechnician from "./WoCharts/ResponseTimeByTechnician";
import TopWorkOrders from "./WoCharts/TopWorkOrders";
import Utilisation from "./WoCharts/Utilisation";
import ResponseTimeByPriority from "./WoCharts/ResponseTimeByPriority";
import ResolutionTimeByPriority from "./WoCharts/ResolutionTimeByPriority";
import CostEstimateChart from "./WoCharts/CostEstimateChart";
import TimeEstimateChart from "./WoCharts/TimeEstimateChart";
import { isEqual } from "lodash";

const data12 = [
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,
    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  {
    technicianName: "Name Surname ddsfds f fdsfd sfds dsf",
    workOrderCount: 311,

    avgResponseTimeHigh: 311,
    avgResponseTimeMedium: 311,
    avgResponseTimeLow: 311,
  },
  // ... other technicians
];

const WorkOrderManagement = () => {
  const t = useSelector(selectTranslations);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [showUnAssign, setShowUnAssign] = useState(false);
  const [showOverDue, setShowOverDue] = useState(false);
  const [showOnTime, setShowOnTime] = useState(false);
  const [createSelectedDates, setCreateSelectedDates] = useState([]);
  const [customSelectedDates, setCustomSelectedDates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dashBoardData, setDashBoardData] = useState({
    onTimeWorkOrders: {},
    overDueWorkOrders: {},
    unassignedWorkOrders: {},
    totalWorkOrder: "",
    workOrderStatus: {},
    averageResolutionTimesByPriority: [],
    averageResponseTimesByPriority: [],
    resolutionTimesByTechnician: [],
    responseTimesByTechnician: [],
    timeEstimateVsActual: [],
    topTenWorkOrders: {},
    utilization: {},
    workOrdersByAssigneeData: [],
  });

  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });

  const [chartDateOption, setChartDateOption] = useState({
    responseTime: "week",
    resolutionTime: "week",
    estimateTime: "week",
    estimateCost: "week",
  });

  const [filterOptions, setFilterOptions] = useState({
    dueDate: [],
    people: [],
    location: [],
    orderType: [""],
    categories: [],
    asset: [],
    priority: [],
    customer: [],
    teams: [],
    area: [],
  });

  let dateDropdownOption = [
    {
      key: 0,
      value: "day",
      text: t.dashboard.dateOptions.day,
    },

    {
      key: 1,
      value: "week",
      text: t.dashboard.dateOptions.week,
    },
    {
      key: 2,
      value: "month",
      text: t.dashboard.dateOptions.month,
    },
  ];

  const hasNoFilterValues = () => {
    return Object.values(filterOptions).every(
      (value) =>
        (Array.isArray(value) &&
          value.every((item) => item === "" || item.length === 0)) || // Check if all arrays are empty or only contain empty strings
        value === ""
    );
  };

  useEffect(() => {
    (async function() {
      const filterData = await getDashboardUsersByID(user?.id);

      if (
        filterData?.woDashboardSavedFilter &&
        !isEqual(filterData.woDashboardSavedFilter, otherFilter)
      ) {
        // setCurrentTime(filterData.woDashboardSavedFilter?.refresh)
        setOtherFilter(filterData.woDashboardSavedFilter);
      }
    })();
  }, []);

  const [chartConfig, setChartConfig] = useState({
    series: [
      {
        name: "High",
        data: [],
      },
      {
        name: "Medium",
        data: [],
      },
      {
        name: "Low",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: [
        "rgba(209, 64, 64, 1)",
        "rgba(226, 178, 3, 1)",
        "rgba(79, 171, 104, 1)",
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.cards.date,
          opposite: true,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
        },
        labels: {
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
          formatter: function(value) {
            const date = new Date(value);
            const day = date?.getDate();
            const month = date?.toLocaleString("default", { month: "short" });
            const year = date?.getFullYear();
            return `${day}, ${month} ${year}`;
          },
        },
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
          groups: [
            { title: "2019", cols: 4 },
            { title: "2020", cols: 4 },
          ],
        },
      },
      yaxis: {
        opposite: selectedLanguage == "ar" ? true : false,
        title: {
          text: t.dashboard.cards.response_time_label,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        formatter: function(val) {
          return val;
        },
      },
    },
  });

  const [currentTime, setCurrentTime] = useState("");

  const [chartConfigCostEstimate, setChartConfigCostEstimate] = useState({
    series: [
      {
        name: t.dashboard.cards.avg_cost,
        data: [],
      },
      {
        name: t.dashboard.cards.avg_actual,
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["rgba(12, 102, 228, 1)", "rgba(6, 50, 112, 1)"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.cards.days,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
        labels: {
          formatter: function(value, timestamp, index) {
            const date = new Date(value);
            const day = date?.getDate();
            const month = date?.toLocaleString("default", { month: "short" });
            const year = date?.getFullYear();
            return `${day}, ${month} ${year}`;
          },
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.cards.cost_currency + `  (${organisationCurrency})`,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        formatter: function(val) {
          return val;
        },
      },
    },
  });

  const [chartConfigTimeEstimate, setChartConfigTimeEstimate] = useState({
    series: [
      {
        name: "Avg. Estimate Time",
        data: [],
      },
      {
        name: "Avg. Actual Time",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: ["rgba(12, 102, 228, 1)", "rgba(6, 50, 112, 1)"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.cards.days,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
        labels: {
          style: {
            color: "#788ca5", // Set label color
            fontSize: "14px",
            fontWeight: "400", // Set label font size
          },
          formatter: function(value) {
            const date = new Date(value);
            const day = date?.getDate();
            const month = date?.toLocaleString("default", { month: "short" });
            const year = date?.getFullYear();
            return `${day}, ${month} ${year}`;
          },
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.cards.time_hrs,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "14px",
            fontWeight: "400", // Set title font size
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        formatter: function(val) {
          return val;
        },
      },
    },
  });

  const [chartConfigResolution, setchartConfigResolution] = useState({
    series: [
      {
        name: "High",
        data: [],
      },
      {
        name: "Medium",
        data: [],
      },
      {
        name: "Low",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false, // Disable the toolbar
        },
        // direction: "ltr", // Right-to-left
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25%", // Adjust this percentage to control overall column width
          endingShape: "rounded",
          barHeight: "75%",
        },
      },
      colors: [
        "rgba(209, 64, 64, 1)",
        "rgba(226, 178, 3, 1)",
        "rgba(79, 171, 104, 1)",
      ],
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false, // Hide the legend
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
        title: {
          text: t.dashboard.cards.date,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
        },
        labels: {
          style: {
            color: "#788ca5", // Set label color
            fontSize: "12px",
            fontWeight: "400", // Set label font size
          },
          formatter: function(value) {
            const date = new Date(value);
            const day = date?.getDate();
            const month = date?.toLocaleString("default", { month: "short" });
            const year = date?.getFullYear();
            return `${day}, ${month} ${year}`;
          },
        },
      },
      yaxis: {
        title: {
          text: t.dashboard.cards.resolution_time_label,
          style: {
            color: "#788ca5", // Set title color
            fontSize: "12px",
            fontWeight: "400", // Set title font size
          },
        },
        labels: {
          style: {
            colors: "#788ca5", // Set label color
            fontSize: "12px",
            fontWeight: "400", // Set label font size
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        formatter: function(val) {
          return val;
        },
      },
    },
  });

  const [showPercentage, setShowPercentage] = useState({
    box1: true,
    box2: true,
    box3: true,
  });
  const [percentageTooltip, setpercentageTooltip] = useState({
    box1P: false,
    box1C: false,
    box2P: false,
    box2C: false,
    box3P: false,
    box3C: false,
  });
  const [expandTooltip, setExpandTooltip] = useState({
    box1: false,
    box2: false,
    box3: false,
  });
  const [addCustomizedTolltip, setAddCustomizedTolltip] = useState({
    box1: false,
    box2: false,
    box3: false,
  });

  const [otherFilter, setOtherFilter] = useState({
    period: true,
    location: true,
    orderType: true,
    people: true,
    category: true,
    asset: false,
    teams: false,
    date: false,
    priority: false,
    customer: false,
    area: false,
    refresh: "",
  });

  useEffect(() => {
    setLoading(true);
    const fetchDashboardData = async () => {
      let dashboardApiData = await getDashBoardData(
        organisationId,
        filterOptions?.dueDate,
        filterOptions?.location,
        filterOptions?.orderType,
        filterOptions?.people,
        filterOptions?.categories,
        filterOptions?.asset,
        filterOptions?.teams,
        filterOptions?.priority,
        filterOptions?.customer,
        createSelectedDates,
        customSelectedDates,
        filterOptions?.area
      );

      // const fetchData = dispatch(
      //   fetchAssetsInitData({ organisationId, role, allIds })
      // );
      if (dashboardApiData) {
        setDashBoardData({
          ...dashBoardData,
          onTimeWorkOrders: dashboardApiData?.OnTimeWorkOrders,
          overDueWorkOrders: dashboardApiData.OverDueWorkOrders,
          unassignedWorkOrders: dashboardApiData.UnassignedWorkOrders,
          totalWorkOrder: dashboardApiData.TotalWorkOrder,
          workOrderStatus: dashboardApiData.WorkOrderStatus,
          averageResolutionTimesByPriority:
            dashboardApiData.averageResolutionTimesByPriority,
          averageResponseTimesByPriority:
            dashboardApiData.averageResponseTimesByPriority,
          resolutionTimesByTechnician:
            dashboardApiData.resolutionTimesByTechnician,
          responseTimesByTechnician: dashboardApiData.responseTimesByTechnician,
          timeEstimateVsActual: dashboardApiData.timeEstimateVsActual,
          topTenWorkOrders: dashboardApiData.topTenWorkOrders,
          utilization: dashboardApiData.utilization,
          workOrdersByAssigneeData: dashboardApiData.workOrdersByAssigneeData,
        });

        const updatedSeries = [
          dashboardApiData.WorkOrderStatus.open.count,
          dashboardApiData.WorkOrderStatus.inReview.count,
          dashboardApiData.WorkOrderStatus.onHold.count,
          dashboardApiData.WorkOrderStatus.completed.count,
          dashboardApiData.WorkOrderStatus.inProgress.count,
        ];

        // const seriesPercentages = [
        //   open.percentage,
        //   inReview.percentage,
        //   onHold.percentage,
        //   completed.percentage,
        //   inProgress.percentage,
        // ];

        const updatedLabels = [
          "Open",
          "In-Review",
          "On-Hold",
          "Completed",
          "In-Progress",
        ];

        // setWoStatusChart(prevState => ({
        //   ...prevState,
        //   series: updatedSeries,
        //   options: {
        //     ...prevState.options,
        //     labels: updatedLabels,
        //   },
        // }));

        const updatedStatusOptions = statusOptions.map((option) => {
          const count =
            dashboardApiData?.WorkOrderStatus[option.value]?.count || 0;
          return { ...option, count };
        });

        // setStatusOptions(updatedStatusOptions);
      } else {
        toast.error(t.common.something_wrong);
      }
      setLoading(false);
    };

    fetchDashboardData();
  }, [filterOptions, createSelectedDates, customSelectedDates]);

  useEffect(() => {
    const fetchDashboardChartData = async () => {
      let chartData = await getResponseTimeByPriorityData(
        organisationId,
        chartDateOption?.responseTime,
        filterOptions,
        createSelectedDates,
        customSelectedDates
      );
      if (chartData?.length > 0) {
        // const categories = chartData.map((item) => item.date);

        const categories = chartData.map((item) =>
          chartDateOption?.responseTime == "week"
            ? `${new Date(item.startDate).getDate()}-${new Date(
                item.endDate
              ).getDate()} ${new Date(item.startDate).toLocaleString(
                "default",
                { month: "short" }
              )}`
            : item.date
        );
        const highData = chartData.map((item) => item.high);

        const mediumData = chartData.map((item) => item.medium);
        const lowData = chartData.map((item) => item.low);

        setChartConfig((prevConfig) => ({
          ...prevConfig,
          series: [
            { name: "High", data: highData },
            { name: "Medium", data: mediumData },
            { name: "Low", data: lowData },
          ],
          options: {
            ...prevConfig.options,
            xaxis: {
              ...prevConfig.options.xaxis,
              categories: categories,
              labels: {
                formatter: function(value, timestamp, index) {
                  if (chartDateOption?.responseTime === "week") {
                    // Return the formatted week range from the categories
                    return `${categories[index]}`;
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.responseTime === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
            },
            tooltip: {
              ...prevConfig.options.tooltip,
              x: {
                formatter: function(value, { dataPointIndex }) {
                  // Use dataPointIndex to access the correct category for weekly intervals
                  if (chartDateOption?.responseTime === "week") {
                    return categories[dataPointIndex];
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.responseTime === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
              formatter: function(val) {
                return val; // Return the value as is for the cost
              },
            },
          },
        }));
      }

      // const fetchData = dispatch(
      //   fetchAssetsInitData({ organisationId, role, allIds })
      // );
    };
    fetchDashboardChartData();
  }, [
    chartDateOption.responseTime,
    filterOptions,
    createSelectedDates,
    customSelectedDates,
  ]);

  useEffect(() => {
    const fetchDashboardChartData = async () => {
      let chartData = await getCostEstimateTimeData(
        organisationId,
        chartDateOption?.estimateCost,
        filterOptions,
        createSelectedDates,
        customSelectedDates
      );

      if (chartData?.length > 0) {
        // const categories = chartData.map((item) => item.date);
        const categories = chartData.map((item) =>
          chartDateOption?.estimateCost == "week"
            ? `${new Date(item.startDate).getDate()}-${new Date(
                item.endDate
              ).getDate()} ${new Date(item.startDate).toLocaleString(
                "default",
                { month: "short" }
              )}`
            : item.date
        );
        const estimateCost = chartData.map((item) => item?.laborCost);
        const actualCost = chartData.map((item) => item?.actualCost);
        setChartConfigCostEstimate((prevConfig) => ({
          ...prevConfig,
          series: [
            { name: "Avg. Estimate Cost", data: estimateCost },
            { name: "Avg. Actual Cost", data: actualCost },
          ],
          options: {
            ...prevConfig.options,
            xaxis: {
              ...prevConfig.options.xaxis,
              categories: categories,
              labels: {
                formatter: function(value, timestamp, index) {
               
                  if (chartDateOption?.estimateCost === "week") {
                    // Return the formatted week range from the categories
                    return `${categories[index]}`;
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.estimateCost === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
            },
            tooltip: {
              ...prevConfig.options.tooltip,
              x: {
                formatter: function(value, { dataPointIndex }) {
                  // Use dataPointIndex to access the correct category for weekly intervals
                  if (chartDateOption?.estimateCost === "week") {
                    return categories[dataPointIndex];
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.estimateCost === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
              formatter: function(val) {
                return val; // Return the value as is for the cost
              },
            },
          },
        }));
      }

      // const fetchData = dispatch(
      //   fetchAssetsInitData({ organisationId, role, allIds })
      // );
    };
    fetchDashboardChartData();
  }, [
    chartDateOption.estimateCost,
    filterOptions,
    createSelectedDates,
    customSelectedDates,
  ]);

  useEffect(() => {
    const fetchDashboardChartData = async () => {
      let chartData = await getTimeEstimateData(
        organisationId,
        chartDateOption?.estimateTime,
        filterOptions,
        createSelectedDates,
        customSelectedDates
      );
      if (chartData?.length > 0) {
        // const categories = chartData.map((item) => item.date);
        const categories = chartData.map((item) =>
          chartDateOption?.estimateTime == "week"
            ? `${new Date(item.startDate).getDate()}-${new Date(
                item.endDate
              ).getDate()} ${new Date(item.startDate).toLocaleString(
                "default",
                { month: "short" }
              )}`
            : item.date
        );
        const estimateTime = chartData.map(
          (item) => item?.averageEstimationTime
        );
        const actualTime = chartData.map((item) => item?.averageActualTime);

        setChartConfigTimeEstimate((prevConfig) => ({
          ...prevConfig,
          series: [
            { name: "Avg. Estimate Time", data: estimateTime },
            { name: "Avg. Actual Time", data: actualTime },
          ],
          options: {
            ...prevConfig.options,
            xaxis: {
              ...prevConfig.options.xaxis,
              categories: categories,
              labels: {
                formatter: function(value, timestamp, index) {
                  if (chartDateOption?.estimateTime === "week") {
                    // Return the formatted week range from the categories
                    return `${categories[index]}`;
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.estimateTime === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
            },
            tooltip: {
              ...prevConfig.options.tooltip,
              x: {
                formatter: function(value, { dataPointIndex }) {
                  // Use dataPointIndex to access the correct category for weekly intervals
                  if (chartDateOption?.estimateTime === "week") {
                    return categories[dataPointIndex];
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.estimateTime === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
              formatter: function(val) {
                return val; // Return the value as is for the cost
              },
            },
          },
        }));
      }

      // const fetchData = dispatch(
      //   fetchAssetsInitData({ organisationId, role, allIds })
      // );
    };
    fetchDashboardChartData();
  }, [
    chartDateOption.estimateTime,
    filterOptions,
    createSelectedDates,
    customSelectedDates,
  ]);

  useEffect(() => {
    const fetchDashboardChartData = async () => {
      let chartData = await getResolutionTimeByPriorityData(
        organisationId,
        chartDateOption?.resolutionTime,
        filterOptions,
        createSelectedDates,
        customSelectedDates
      );
      if (chartData?.length > 0) {
        // const categories = chartData.map((item) => item.date);
        const categories = chartData.map((item) =>
          chartDateOption?.resolutionTime == "week"
            ? `${new Date(item.startDate).getDate()}-${new Date(
                item.endDate
              ).getDate()} ${new Date(item.startDate).toLocaleString(
                "default",
                { month: "short" }
              )}`
            : item.date
        );
        const highData = chartData.map((item) => item.high);

        const mediumData = chartData.map((item) => item.medium);
        const lowData = chartData.map((item) => item.low);

        setchartConfigResolution((prevConfig) => ({
          ...prevConfig,
          series: [
            { name: "High", data: highData },
            { name: "Medium", data: mediumData },
            { name: "Low", data: lowData },
          ],
          options: {
            ...prevConfig.options,
            xaxis: {
              ...prevConfig.options.xaxis,
              categories: categories,
              labels: {
                style: {
                  color: "#788ca5", // Set label color
                  fontSize: "12px",
                  fontWeight: "400", // Set label font size
                },
                formatter: function(value, timestamp, index) {
        
                  if (chartDateOption?.resolutionTime === "week") {
                    // Return the formatted week range from the categories
                    return `${categories[index]}`;
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.resolutionTime === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
            },
            tooltip: {
              ...prevConfig.options.tooltip,
              x: {
                formatter: function(value, { dataPointIndex }) {
                  // Use dataPointIndex to access the correct category for weekly intervals
                  if (chartDateOption?.resolutionTime === "week") {
                    return categories[dataPointIndex];
                  }
                  const date = new Date(value);
                  const day = date?.getDate();
                  const month = date?.toLocaleString("default", {
                    month: "short",
                  });
                  const year = date?.getFullYear();
                  return chartDateOption?.resolutionTime === "month"
                    ? `${month} ${year}`
                    : `${day}, ${month} ${year}`;
                },
              },
              formatter: function(val) {
                return val; // Return the value as is for the cost
              },
            },
          },
        }));
      }

      // const fetchData = dispatch(
      //   fetchAssetsInitData({ organisationId, role, allIds })
      // );
    };
    fetchDashboardChartData();
  }, [
    chartDateOption.resolutionTime,
    filterOptions,
    createSelectedDates,
    customSelectedDates,
  ]);

  const [chartData] = useState({
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: "series3",
        data: [1, 40, 55, 320, 304, 42, 45],
      },
    ],
    options: {
      chart: {
        height: 350,
        width: 1200,
        type: "area",
        zoom: {
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [
          "2018-09-01T00:00:00.000Z",
          "2018-09-02T00:00:00.000Z",
          "2018-09-03T00:00:00.000Z",
          "2018-09-04T00:00:00.000Z",
          "2018-09-05T00:00:00.000Z",
          "2018-09-06T00:00:00.000Z",
          "2018-09-07T00:00:00.000Z",
        ],
        min: new Date("2018-09-01").getTime(),
        max: new Date("2018-09-30").getTime(),
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
    },
  });

  const [openModal, setOpenModal] = useState(false);

  const [modalData, setModalData] = useState();

  let optionsData = {
    series: [44, 55, 13, 33, 20, 40],
    options: {
      chart: {
        width: 187,
        height: 187,
        type: "donut",
      },
      labels: [
        "Open",
        "In-Review",
        "On-hold",
        "Completed",
        "In-progress",
        "Cancelled",
      ],
      legend: {
        show: false,
      },
      colors: [
        "#0C66E4",
        "#172B4D",
        "#D14040",
        "#4FAB68",
        "#E2B203",
        "#788CA5",
      ],
      plotOptions: {
        pie: {
          donut: {
            size: "70%",
          },
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "25px",
          fontFamily: "Roboto",
          fontWeight: 100,
          colors: ["#e9f2ff"],
        },
      },
      stroke: {
        show: false,
        width: 0,
      },
      tooltip: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Roboto",
        },
        custom: function({ seriesIndex, dataPointIndex, w }) {
          const value =
            w.config.series[seriesIndex][dataPointIndex] ||
            w.config.series[seriesIndex];
          const label = w.config.labels[seriesIndex];
          const color = w.config.colors[seriesIndex]; // Get the color for the circle

          return `
            <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
              <div style="display: flex; align-items: center;">
                <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
                <span>${label}: </span>&nbsp; ${value}
              </div>
            </div>
          `;
        },
      },
    },
  };
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  let data = {
    workOrderCompletion: {
      open: {
        count: 112,
        percentage: 78.32167832167832,
      },
      inProgress: {
        count: 21,
        percentage: 14.685314685314685,
      },
      onHold: {
        count: 3,
        percentage: 2.097902097902098,
      },
      inReview: {
        count: 1,
        percentage: 0.6993006993006993,
      },
      completed: {
        count: 6,
        percentage: 4.195804195804196,
      },
    },
    workOrderOnTime: {
      count: 2,
      percentage: 33.33333333333333,
    },
    overDueWorkOrders: {
      count: 4,
      percentage: 66.66666666666666,
    },
    workOrderResponseTime: 293.7519022222222,
    topTenWorkOrders: {
      mechanical: {
        received: 46,
        completed: 5,
        overdue: 0,
      },
      electrical: {
        received: 18,
        completed: 1,
        overdue: 0,
      },
      damage: {
        received: 81,
        completed: 0,
        overdue: 0,
      },
      safety: {
        received: 1,
        completed: 0,
        overdue: 0,
      },
      null: {
        received: 2,
        completed: 0,
        overdue: 0,
      },
    },
    estimatedHours: 1,
    actualHours: "0h 8m",
    age: {
      workorderAge: 251,
      workOrderAverageAge: 50.2,
    },
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [showAssign, setShowAssign] = useState(false);

  const today = moment().format("YYYY-MM-DD");
  const yesterday = moment()
    .subtract(1, "days")
    .format("YYYY-MM-DD");
  const last_7 = moment()
    .subtract(7, "days")
    .format("YYYY-MM-DD");

  let options = [
    {
      key: "1",
      value: "lastWeek",
      text: t.dashboard.filter.week,
    },
    {
      key: "2",
      value: "lastMonth",
      text: t.dashboard.filter.month,
    },
    {
      key: "3",
      value: "lastSixMonth",
      text: t.dashboard.filter.month_6,
    },
    {
      key: "4",
      value: "lastYear",
      text: t.dashboard.filter.year,
    },
    {
      key: "5",
      value: "date",
      text: t.dashboard.filter.date,
    },
    // {
    //   key: '6',
    //   value: '',
    //   text: t.dashboard.filter.last_30,
    // },
    // {
    //   key: '7',
    //   value: '',
    //   text: t.dashboard.filter.last_90,
    // },
    // {
    //   key: '8',
    //   value: '',
    //   text: t.dashboard.filter.last_180,
    // },
    // {
    //   key: '9',
    //   value: '',
    //   text: t.dashboard.filter.last_365,
    // },
    // {
    //   key: '10',
    //   value: '',
    //   text: t.dashboard.filter.year_to_date,
    // },
    // {
    //   key: '11',
    //   value: '',
    //   text: t.dashboard.filter.this_week,
    // },
    // {
    //   key: '12',
    //   value: '',
    //   text: t.dashboard.filter.this_month,
    // },
    // {
    //   key: '13',
    //   value: '',
    //   text: t.dashboard.filter.this_quarter,
    // },
    // {
    //   key: '14',
    //   value: '',
    //   text: t.dashboard.filter.this_year,
    // },
    // {
    //   key: '15',
    //   value: '',
    //   text: t.dashboard.filter.previous_week,
    // },
    // {
    //   key: '16',
    //   value: '',
    //   text: t.dashboard.filter.previous_month,
    // },
    // {
    //   key: '17',
    //   value: '',
    //   text: t.dashboard.filter.previous_quarter,
    // },
    // {
    //   key: '18',
    //   value: '',
    //   text: t.dashboard.filter.previous_year,
    // },
  ];

  const [filtersTrigger, setFiltersTrigger] = useState({
    assigneeType: "people",
    people: {
      initOptions: [],
      options: [],
    },
    area: {
      initOptions: [],
      options: [],
    },
    orderType: {
      options: [
        {
          key: 1,
          text: t.common.all,
          value: "",
          color: "",
        },
        {
          key: 2,
          text: t.workOrders.form.order_type_1,
          value: "reactive",
        },
        {
          key: 2,
          text: t.workOrders.form.order_type_2,
          value: "scheduled",
        },
      ],
    },
    location: {
      initOptions: [],
      options: [],
    },

    priority: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: "",
          color: null,
        },
        {
          key: 1,
          text: t.workOrders.priority_status.low,
          value: "low",
          color: "blue",
        },
        {
          key: 2,
          text: t.workOrders.priority_status.medium,
          value: "medium",
          color: "orange",
        },
        {
          key: 3,
          text: t.workOrders.priority_status.high,
          value: "high",
          color: "red",
        },
      ],
    },

    dueDate: {
      options: [
        {
          key: "1",
          value: "lastWeek",
          text: t.dashboard.filter.week,
        },
        {
          key: "2",
          value: "lastMonth",
          text: t.dashboard.filter.month,
        },
        {
          key: "3",
          value: "lastSixMonth",
          text: t.dashboard.filter.month_6,
        },
        {
          key: "4",
          value: "lastYear",
          text: t.dashboard.filter.year,
        },

        // {
        //   key: '5',
        //   value: 'date',
        //   text: t.dashboard.filter.date,
        // },
      ],

      initOptions: [
        {
          key: "1",
          value: "lastWeek",
          text: t.dashboard.filter.week,
        },
        {
          key: "2",
          value: "lastMonth",
          text: t.dashboard.filter.month,
        },
        {
          key: "3",
          value: "lastSixMonth",
          text: t.dashboard.filter.month_6,
        },
        {
          key: "4",
          value: "lastYear",
          text: t.dashboard.filter.year,
        },
      ],
    },

    categories: {
      options: [
        {
          key: 0,
          value: "damage",
          text: t.workOrders.form.category_options[0],
          icon: <Damage />,
        },
        {
          key: 2,
          value: "corrective action Downtime",
          text: t.workOrders.form.category_options[1],
          icon: <CorrectiveIcon />,
        },
        {
          key: 4,
          value: "planned Downtime",
          text: t.workOrders.form.category_options[2],
          icon: <PlannedIcon />,
        },
        {
          key: 10,
          value: "unplanned Downtime",
          text: t.workOrders.form.category_options[3],
          icon: <UnPlannedIcon />,
        },
        {
          key: 11,
          value: "inspection",
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: "electrical",
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: "mechanical",
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: "HVAC",
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: "preventive maintenance",
          text: t.workOrders.form.category_options[8],
          icon: <CatgoryPreventive />,
        },
        {
          key: 17,
          value: "project",
          text: t.workOrders.form.category_options[9],
          icon: <Project />,
        },
        {
          key: 7,
          value: "safety",
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: "sop",
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
      initOptions: [
        {
          key: 0,
          value: "damage",
          text: t.workOrders.form.category_options[0],
          icon: <Damage />,
        },
        {
          key: 2,
          value: "corrective action Downtime",
          text: t.workOrders.form.category_options[1],
          icon: <CorrectiveIcon />,
        },
        {
          key: 4,
          value: "planned Downtime",
          text: t.workOrders.form.category_options[2],
          icon: <PlannedIcon />,
        },
        {
          key: 10,
          value: "unplanned Downtime",
          text: t.workOrders.form.category_options[3],
          icon: <UnPlannedIcon />,
        },
        {
          key: 11,
          value: "inspection",
          text: t.workOrders.form.category_options[4],
          icon: <CategoryInspection />,
        },
        {
          key: 1,
          value: "electrical",
          text: t.workOrders.form.category_options[5],
          icon: <CategoryElectrical />,
        },

        {
          key: 3,
          value: "mechanical",
          text: t.workOrders.form.category_options[6],
          icon: <CategoryMechanical />,
        },

        {
          key: 6,
          value: "HVAC",
          text: t.workOrders.work_order_category.HVAC,
          icon: <CategoryRefrigeration />,
        },
        {
          key: 16,
          value: "preventive maintenance",
          text: t.workOrders.form.category_options[8],
          icon: <CatgoryPreventive />,
        },
        {
          key: 17,
          value: "project",
          text: t.workOrders.form.category_options[9],
          icon: <Project />,
        },
        {
          key: 7,
          value: "safety",
          text: t.workOrders.form.category_options[10],
          icon: <CategorySafety />,
        },
        {
          key: 27,
          value: "sop",
          text: t.workOrders.form.category_options[11],
          icon: <OperationProcedure />,
        },
      ],
    },

    asset: {
      initOptions: [],
      options: [],
    },
    customer: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },
  });

  const statusOptions = [
    {
      value: "open",
      color: "blue",
      label: t.workOrders.work_order_status.open,
    },
    {
      value: "inProgress",
      color: "yellow",
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: "onHold",
      color: "red",
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: "inReview",
      color: "black",
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: "completed",
      color: "green",
      label: t.workOrders.work_order_status.completed,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.workOrders.work_order_status.cancel,
    },
  ];

  const optionsRadial = {
    chart: {
      height: 200,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        track: {
          background: "rgba(120, 140, 165, 1)", // Unfilled part color
          strokeWidth: "100%", // Width of the unfilled part
        },
        dataLabels: {
          name: {
            show: true,
            color: "rgba(138, 146, 166, 1)", // Label color
            fontSize: "16px", // Font size
          },
          value: {
            show: true,
            color: "rgba(35, 45, 66, 1)", // Label color
            fontSize: "22px", // Font size
          },
        },
        thickness: 12, // Width of the filled part
        stroke: {
          lineCap: "round", // Rounded edges
        },
      },
    },
    fill: {
      colors: ["rgba(12, 102, 228, 1)"], // Filled part color
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "14px",
        fontFamily: "Roboto",
      },
      custom: function({ seriesIndex, w }) {
        const value = w.config.series[seriesIndex];
        const label = w.config.labels[seriesIndex];
        const color = w.config.fill.colors[seriesIndex]; // Get the color for the circle

        return `
          <div style="background: white; box-shadow: 5px 4px 6px #00000026; border: 1px solid white; color: black; padding: 5px; border-radius: 8px;">
            <div style="display: flex; align-items: center;">
              <div style="width: 8px; height: 8px; background-color: ${color}; border-radius: 50%; margin-right: 4px;"></div>
              <span>${label}: </span>&nbsp; ${value}%
            </div>
          </div>
        `;
      },
    },
    series: [70],
    labels: [t.dashboard.cards.uti],
  };

  const [showMore, setShowMore] = useState(false);
  return (
    <>
      <div className="dashboard-container">
        <WorkOrderFilter
          hasNoFilterValues={hasNoFilterValues}
          createSelectedDates={createSelectedDates}
          setCurrentTime={setCurrentTime}
          currentTime={currentTime}
          setCustomSelectedDates={setCustomSelectedDates}
          customSelectedDates={customSelectedDates}
          setCreateSelectedDates={setCreateSelectedDates}
          setFilterOptions={setFilterOptions}
          filterOptions={filterOptions}
          setFiltersTrigger={setFiltersTrigger}
          filtersTrigger={filtersTrigger}
          setOtherFilter={setOtherFilter}
          otherFilter={otherFilter}
        />
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "1000px " }}
          >
            <Animation />
            {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
          </div>
        ) : (
          <div className="all-cards">
            {
              <Grid columns={"equal"} className="graph-container m-0">
                <Grid.Column className="completion-column pl-0">
                  <div className="completion-column-data">
                    <div className="completion-cotainer d-flex justify-content-between mt-2">
                      <div className="completion-title d-flex">
                        <span className="title">
                          {t.dashboard.box.unassign}
                        </span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowUnAssign(true)}
                          onMouseLeave={() => setShowUnAssign(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showUnAssign}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.tooltip.unAssgin}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                      </div>

                      <span
                        style={{ marginTop: "-10px", gap: "10px" }}
                        className="d-flex"
                      >
                        <span
                          onClick={() => {
                            setOpenModal(true);
                            setExpandTooltip({
                              ...expandTooltip,
                              box1: false,
                            });
                            setModalData("unassign");
                          }}
                          style={{ marginTop: "2px" }}
                          className=""
                        >
                          <p
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              marginTop: "-10px",
                            }}
                            onMouseOver={() =>
                              setExpandTooltip({
                                ...expandTooltip,
                                box1: true,
                              })
                            }
                            onMouseLeave={() =>
                              setExpandTooltip({
                                ...expandTooltip,
                                box1: false,
                              })
                            }
                            className=" mt-1"
                          >
                            <Tooltip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position="top"
                              show={expandTooltip?.box1}
                              textBoxWidth="80px"
                              fontSize="10px"
                              fontWeight="400"
                              fontFamily={
                                selectedLanguage == "ar"
                                  ? "Noto Kufi Arabic"
                                  : "Roboto"
                              }
                              padding="8px 8px 8px 8px"
                              borderRadius="5px"
                              static
                              arrowAlign="start"
                              moveRight="5px"
                              moveDown="3px"
                            >
                              <span
                                style={{ padding: "0px 10px" }}
                                className="work-order-tooltip"
                              >
                                {t.dashboard.expand_full}
                              </span>
                            </Tooltip>
                            {/* <FullArrow /> */}
                          </p>
                        </span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box1: true,
                            })
                          }
                          onMouseLeave={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box1: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position="top"
                            show={addCustomizedTolltip?.box1}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="0px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.add_customized}
                            </span>
                          </Tooltip>
                          {/* <AddIconDashboard /> */}
                        </p>
                      </span>
                    </div>
                    <div
                      // style={{
                      //   padding: "20px 6px",
                      //   background: "rgba(245, 246, 248, 1)",
                      //   borderRadius: "4px",
                      //   marginInline: "13px",
                      // }}
                      className="d-flex hover-div"
                    >
                      <div style={{ fontSize: "40px" }}>
                        {showPercentage?.box1 === true
                          ? `${
                              dashBoardData?.unassignedWorkOrders?.percentage
                                ? dashBoardData?.unassignedWorkOrders
                                    ?.percentage
                                : 0
                            }%`
                          : dashBoardData?.unassignedWorkOrders?.count
                          ? dashBoardData?.unassignedWorkOrders?.count
                          : 0}
                      </div>
                      {hasNoFilterValues() &&
                        createSelectedDates?.length == 0 &&
                        customSelectedDates?.length == 0 && (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                dashBoardData?.unassignedWorkOrders
                                  ?.findOpenWorkOrderPer?.increase ==
                                "decreased"
                                  ? { color: "rgba(209, 64, 64, 1)" }
                                  : { color: "rgba(79, 171, 104, 1)" }
                              }
                            >
                              {showPercentage?.box1 === false
                                ? dashBoardData?.unassignedWorkOrders
                                    ?.findOpenWorkOrderPer?.count
                                  ? dashBoardData?.unassignedWorkOrders
                                      ?.findOpenWorkOrderPer?.count
                                  : 0
                                : `${
                                    dashBoardData?.unassignedWorkOrders
                                      ?.findOpenWorkOrderPer?.percentage
                                      ? dashBoardData?.unassignedWorkOrders
                                          ?.findOpenWorkOrderPer?.percentage
                                      : 0
                                  }%`}
                            </span>{" "}
                            {dashBoardData?.unassignedWorkOrders
                              ?.findOpenWorkOrderPer?.increase == "decreased"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                    </div>

                    <div
                      style={{ gap: "10px" }}
                      className="mt-2 mb-4 d-flex justify-content-center"
                    >
                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box1: true,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1P: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1P: false,
                            })
                          }
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={percentageTooltip?.box1P}
                            textBoxWidth="68px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveLeft="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.percantage}
                            </span>
                          </Tooltip>
                          {showPercentage?.box1 ? (
                            <PercentageDashBoardBlueIcon />
                          ) : (
                            <PercentageLightBlueIcon />
                          )}
                        </p>
                      </span>

                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box1: false,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1C: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1C: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={percentageTooltip?.box1C}
                            textBoxWidth="50px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveRight="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.number}
                            </span>
                          </Tooltip>
                          {showPercentage?.box1 ? (
                            <HashSvg />
                          ) : (
                            <HashBlueIcon />
                          )}
                        </p>{" "}
                      </span>
                    </div>
                  </div>
                </Grid.Column>

                <Grid.Column className="completion-column pl-0">
                  <div className="completion-column-data">
                    <div className="completion-cotainer d-flex justify-content-between mt-2">
                      <div className="completion-title d-flex">
                        <span className="title">{t.dashboard.box.overDue}</span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowOverDue(true)}
                          onMouseLeave={() => setShowOverDue(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showOverDue}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.tooltip.overDue}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                      </div>

                      <span
                        style={{ marginTop: "-10px", gap: "10px" }}
                        className="d-flex"
                      >
                        <span
                          onClick={() => {
                            setOpenModal(true);
                            setExpandTooltip({
                              ...expandTooltip,
                              box2: false,
                            });
                            setModalData("overDue");
                          }}
                          style={{ marginTop: "2px" }}
                          className=""
                        >
                          {openModal ? null : (
                            <p
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                marginTop: "-10px",
                              }}
                              onMouseOver={() =>
                                setExpandTooltip({
                                  ...expandTooltip,
                                  box2: true,
                                })
                              }
                              onMouseLeave={() =>
                                setExpandTooltip({
                                  ...expandTooltip,
                                  box2: false,
                                })
                              }
                              className=" mt-1"
                            >
                              <Tooltip
                                color="#fff"
                                backgroundColor="#172B4D"
                                position="top"
                                show={expandTooltip?.box2}
                                textBoxWidth="80px"
                                fontSize="10px"
                                fontWeight="400"
                                fontFamily={
                                  selectedLanguage == "ar"
                                    ? "Noto Kufi Arabic"
                                    : "Roboto"
                                }
                                padding="8px 8px 8px 8px"
                                borderRadius="5px"
                                static
                                arrowAlign="start"
                                moveRight="5px"
                                moveDown="3px"
                              >
                                <span
                                  style={{ padding: "0px 10px" }}
                                  className="work-order-tooltip"
                                >
                                  {t.dashboard.expand_full}
                                </span>
                              </Tooltip>
                              {/* <FullArrow /> */}
                            </p>
                          )}
                        </span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box2: true,
                            })
                          }
                          onMouseLeave={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box2: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position="top"
                            show={addCustomizedTolltip?.box2}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="0px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.add_customized}
                            </span>
                          </Tooltip>
                          {/* <AddIconDashboard /> */}
                        </p>
                      </span>
                    </div>
                    <div className="d-flex hover-div">
                      <div style={{ fontSize: "40px" }}>
                        {showPercentage?.box2 === true
                          ? `${
                              dashBoardData?.overDueWorkOrders?.percentage
                                ? dashBoardData?.overDueWorkOrders?.percentage
                                : 0
                            }%`
                          : dashBoardData?.overDueWorkOrders?.count
                          ? dashBoardData?.overDueWorkOrders?.count
                          : 0}
                      </div>
                      {hasNoFilterValues() &&
                        createSelectedDates?.length == 0 &&
                        customSelectedDates?.length == 0 && (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                dashBoardData?.overDueWorkOrders
                                  ?.findOverDueWorkOrdersPer?.increase ==
                                "decreased"
                                  ? { color: "rgba(209, 64, 64, 1)" }
                                  : { color: "rgba(79, 171, 104, 1)" }
                              }
                            >
                              {showPercentage?.box2 === false
                                ? dashBoardData?.overDueWorkOrders
                                    ?.findOverDueWorkOrdersPer?.count
                                  ? dashBoardData?.overDueWorkOrders
                                      ?.findOverDueWorkOrdersPer?.count
                                  : 0
                                : `${
                                    dashBoardData?.overDueWorkOrders
                                      ?.findOverDueWorkOrdersPer?.percentage
                                      ? dashBoardData?.overDueWorkOrders
                                          ?.findOverDueWorkOrdersPer?.percentage
                                      : 0
                                  }%`}
                            </span>{" "}
                            {dashBoardData?.overDueWorkOrders
                              ?.findOverDueWorkOrdersPer?.increase ==
                            "decreased"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                    </div>

                    <div
                      style={{ gap: "10px" }}
                      className="mt-2 mb-4 d-flex justify-content-center"
                    >
                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box2: true,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2P: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2P: false,
                            })
                          }
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={percentageTooltip?.box2P}
                            textBoxWidth="68px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveLeft="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.percantage}
                            </span>
                          </Tooltip>
                          {showPercentage?.box2 ? (
                            <PercentageDashBoardBlueIcon />
                          ) : (
                            <PercentageLightBlueIcon />
                          )}
                        </p>
                      </span>

                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box2: false,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2C: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2C: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={percentageTooltip?.box2C}
                            textBoxWidth="50px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveRight="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.number}
                            </span>
                          </Tooltip>
                          {showPercentage?.box2 ? (
                            <HashSvg />
                          ) : (
                            <HashBlueIcon />
                          )}
                        </p>
                      </span>
                    </div>
                  </div>
                </Grid.Column>

                <Grid.Column className="completion-column pl-0">
                  <div className="completion-column-data">
                    <div className="completion-cotainer d-flex justify-content-between mt-2">
                      <div className="completion-title d-flex">
                        <span className="title">{t.dashboard.box.ontime}</span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowOnTime(true)}
                          onMouseLeave={() => setShowOnTime(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showOnTime}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.tooltip.onTime}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                      </div>

                      <span
                        style={{ marginTop: "-10px", gap: "10px" }}
                        className="d-flex"
                      >
                        <span
                          onClick={() => {
                            setOpenModal(true);
                            setExpandTooltip({
                              ...expandTooltip,
                              box3: false,
                            });
                            setModalData("onTime");
                          }}
                          style={{ marginTop: "2px" }}
                          className=""
                        >
                          <p
                            style={{
                              position: "relative",
                              cursor: "pointer",
                              marginTop: "-10px",
                            }}
                            onMouseOver={() =>
                              setExpandTooltip({
                                ...expandTooltip,
                                box3: true,
                              })
                            }
                            onMouseLeave={() =>
                              setExpandTooltip({
                                ...expandTooltip,
                                box3: false,
                              })
                            }
                            className=" mt-1"
                          >
                            <Tooltip
                              color="#fff"
                              backgroundColor="#172B4D"
                              position="top"
                              show={expandTooltip?.box3}
                              textBoxWidth="80px"
                              fontSize="10px"
                              fontWeight="400"
                              fontFamily={
                                selectedLanguage == "ar"
                                  ? "Noto Kufi Arabic"
                                  : "Roboto"
                              }
                              padding="8px 8px 8px 8px"
                              borderRadius="5px"
                              static
                              arrowAlign="start"
                              moveRight="5px"
                              moveDown="3px"
                            >
                              <span
                                style={{ padding: "0px 10px" }}
                                className="work-order-tooltip"
                              >
                                {t.dashboard.expand_full}
                              </span>
                            </Tooltip>
                            {/* <FullArrow /> */}
                          </p>
                        </span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box3: true,
                            })
                          }
                          onMouseLeave={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box3: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={addCustomizedTolltip?.box3}
                            textBoxWidth="100px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="5px"
                            moveDown="-2px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.add_customized}
                            </span>
                          </Tooltip>
                          {/* <AddIconDashboard /> */}
                        </p>
                      </span>
                    </div>
                    <div className="d-flex hover-div">
                      <div style={{ fontSize: "40px" }}>
                        {showPercentage?.box3 === true
                          ? `${
                              dashBoardData?.onTimeWorkOrders?.percentage
                                ? dashBoardData?.onTimeWorkOrders?.percentage
                                : 0
                            }%`
                          : dashBoardData?.onTimeWorkOrders?.count
                          ? dashBoardData?.onTimeWorkOrders?.count
                          : 0}
                      </div>
                      {hasNoFilterValues() &&
                        createSelectedDates?.length == 0 &&
                        customSelectedDates?.length == 0 && (
                          <div
                            style={{
                              fontSize: "14px",
                              color: "rgba(120, 140, 165, 1)",
                            }}
                          >
                            <span
                              className={
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }
                              style={
                                dashBoardData?.onTimeWorkOrders
                                  ?.findOnTimeWorkOrdersPer?.increase ==
                                "decreased"
                                  ? { color: "rgba(209, 64, 64, 1)" }
                                  : { color: "rgba(79, 171, 104, 1)" }
                              }
                            >
                              {showPercentage?.box3 === false
                                ? dashBoardData?.onTimeWorkOrders
                                    ?.findOnTimeWorkOrdersPer?.count
                                  ? dashBoardData?.onTimeWorkOrders
                                      ?.findOnTimeWorkOrdersPer?.count
                                  : 0
                                : `${
                                    dashBoardData?.onTimeWorkOrders
                                      ?.findOnTimeWorkOrdersPer?.percentage
                                      ? dashBoardData?.onTimeWorkOrders
                                          ?.findOnTimeWorkOrdersPer?.percentage
                                      : 0
                                  }%`}
                            </span>{" "}
                            {dashBoardData?.onTimeWorkOrders
                              ?.findOnTimeWorkOrdersPer?.increase == "decreased"
                              ? t.dashboard.box.decrease
                              : t.dashboard.box.increase}
                          </div>
                        )}
                    </div>

                    <div
                      style={{ gap: "10px" }}
                      className="mt-2 mb-4 d-flex justify-content-center"
                    >
                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box3: true,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3P: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3P: false,
                            })
                          }
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right "
                            }
                            show={percentageTooltip?.box3P}
                            textBoxWidth="68px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveLeft="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.percantage}
                            </span>
                          </Tooltip>
                          {showPercentage?.box3 ? (
                            <PercentageDashBoardBlueIcon />
                          ) : (
                            <PercentageLightBlueIcon />
                          )}
                        </p>
                      </span>

                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box3: false,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3C: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3C: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={percentageTooltip?.box3C}
                            textBoxWidth="50px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveRight="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.number}
                            </span>
                          </Tooltip>
                          {showPercentage?.box3 ? (
                            <HashSvg />
                          ) : (
                            <HashBlueIcon />
                          )}
                        </p>{" "}
                      </span>
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            }
            <Grid columns={"equal"} className="graph-container m-0">
              <WoStatusChart
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              />
              <WoAssigneeChart
                optionsData={optionsData}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              />
            </Grid>
            {/* <div className="wo-label-container d-flex">
            <span>Work order status</span>
            <p className="ml-2 mt-1">
              <DashboardWOStatusIcon />
            </p>
          </div> */}

            <Grid columns={"equal"} className="graph-container m-0">
              <ResponseTimeByPriority
                setChartDateOption={setChartDateOption}
                openModal={openModal}
                chartDateOption={chartDateOption}
                dateDropdownOption={dateDropdownOption}
                setChartConfig={setChartConfig}
                chartConfig={chartConfig}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              />

              <ResponseTimeByTechnician
                data12={data12}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                openModal={openModal}
                setModalData={setModalData}
              />
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <ResolutionTimeByPriority
                openModal={openModal}
                setChartDateOption={setChartDateOption}
                chartDateOption={chartDateOption}
                dateDropdownOption={dateDropdownOption}
                setchartConfigResolution={setchartConfigResolution}
                chartConfigResolution={chartConfigResolution}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              />
              <ResolutionTimeByTechnician
                data12={data12}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              />
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <CostEstimateChart
                setChartDateOption={setChartDateOption}
                chartDateOption={chartDateOption}
                dateDropdownOption={dateDropdownOption}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
                chartConfigCostEstimate={chartConfigCostEstimate}
                openModal={openModal}
                setChartConfigCostEstimate={setChartConfigCostEstimate}
              />

              <TimeEstimateChart
                setChartDateOption={setChartDateOption}
                chartDateOption={chartDateOption}
                dateDropdownOption={dateDropdownOption}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                openModal={openModal}
                setModalData={setModalData}
                chartConfigTimeEstimate={chartConfigTimeEstimate}
                setChartConfigTimeEstimate={setChartConfigTimeEstimate}
              />
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <TopWorkOrders
                data12={data12}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                openModal={openModal}
                setModalData={setModalData}
              />
              {/* <Grid.Column className="completion-column pl-0 pt-0"></Grid.Column> */}
            </Grid>

            <Grid columns={"equal"} className="graph-container m-0">
              <Utilisation
                optionsRadial={optionsRadial}
                data12={data12}
                openModal={openModal}
                setDashBoardData={setDashBoardData}
                dashBoardData={dashBoardData}
                setOpenModal={setOpenModal}
                setModalData={setModalData}
              />
            </Grid>
          </div>
        )}

        {/* <div>  <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} width={1000} /></div> */}
      </div>

      <Modal
        size="large"
        open={openModal}
        className="assets-modal new-dashboard-modal"
        style={{ height: "auto" }}
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {modalData == "status"
              ? t.dashboard.cards.status
              : modalData == "Assignee"
              ? t.dashboard.cards.assignee
              : modalData == "Priority"
              ? t.dashboard.cards.response
              : modalData == "ResponseByTechnician"
              ? t.dashboard.cards.technician_res
              : modalData == "PriorityResolution"
              ? t.dashboard.cards.resolution
              : modalData == "ResolutionByTechnician"
              ? t.dashboard.cards.resolution_technician
              : modalData == "CostEstimate"
              ? t.dashboard.cards.cost
              : modalData == "TimeEstimate"
              ? t.dashboard.cards.time
              : modalData == "Top10"
              ? t.dashboard.cards.top10Wo
              : modalData == "Utilisation"
              ? t.dashboard.cards.utilisation_rate
              : modalData == "overDue"
              ? t.dashboard.box.overDue
              : modalData == "unassign"
              ? t.dashboard.box.unassign
              : modalData == "onTime"
              ? t.dashboard.box.ontime
              : ""}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="">
          <div className="all-cards">
            {modalData == "status" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <WoStatusChart
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                  height={400}
                />
              </Grid>
            ) : modalData == "Assignee" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <WoAssigneeChart
                  height={400}
                  optionsData={optionsData}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "Priority" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <ResponseTimeByPriority
                  setChartDateOption={setChartDateOption}
                  chartDateOption={chartDateOption}
                  dateDropdownOption={dateDropdownOption}
                  setChartConfig={setChartConfig}
                  chartConfig={chartConfig}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "ResponseByTechnician" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <ResponseTimeByTechnician
                  data12={data12}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "PriorityResolution" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <ResolutionTimeByPriority
                  openModal={openModal}
                  setChartDateOption={setChartDateOption}
                  chartDateOption={chartDateOption}
                  dateDropdownOption={dateDropdownOption}
                  setchartConfigResolution={setchartConfigResolution}
                  chartConfigResolution={chartConfigResolution}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "ResolutionByTechnician" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <ResolutionTimeByTechnician
                  data12={data12}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  openModal={openModal}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "CostEstimate" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <CostEstimateChart
                  setChartDateOption={setChartDateOption}
                  chartDateOption={chartDateOption}
                  dateDropdownOption={dateDropdownOption}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                  chartConfigCostEstimate={chartConfigCostEstimate}
                  openModal={openModal}
                  setChartConfigCostEstimate={setChartConfigCostEstimate}
                />
              </Grid>
            ) : modalData == "TimeEstimate" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <TimeEstimateChart
                  setChartDateOption={setChartDateOption}
                  chartDateOption={chartDateOption}
                  dateDropdownOption={dateDropdownOption}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  setModalData={setModalData}
                  chartConfigTimeEstimate={chartConfigTimeEstimate}
                  setChartConfigTimeEstimate={setChartConfigTimeEstimate}
                />
              </Grid>
            ) : modalData == "Top10" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <TopWorkOrders
                  data12={data12}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  openModal={openModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "Utilisation" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <Utilisation
                  optionsRadial={optionsRadial}
                  data12={data12}
                  openModal={openModal}
                  setDashBoardData={setDashBoardData}
                  dashBoardData={dashBoardData}
                  setOpenModal={setOpenModal}
                  setModalData={setModalData}
                />
              </Grid>
            ) : modalData == "overDue" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <Grid.Column className="completion-column pl-0">
                  <div className="completion-column-data ">
                    <div className="completion-cotainer d-flex justify-content-between mt-2">
                      <div className="completion-title d-flex">
                        <span className="title">{t.dashboard.box.overDue}</span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowOverDue(true)}
                          onMouseLeave={() => setShowOverDue(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showOverDue}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.tooltip.overDue}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                      </div>

                      <span
                        style={{ marginTop: "-10px", gap: "10px" }}
                        className="d-flex"
                      >
                        <span
                          onClick={() => {
                            setOpenModal(true);
                            setModalData("overDue");
                          }}
                          style={{ marginTop: "2px" }}
                          className=""
                        >
                          {openModal ? null : (
                            <p
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                marginTop: "-10px",
                              }}
                              onMouseOver={() =>
                                setExpandTooltip({
                                  ...expandTooltip,
                                  box2: true,
                                })
                              }
                              onMouseLeave={() =>
                                setExpandTooltip({
                                  ...expandTooltip,
                                  box2: false,
                                })
                              }
                              className=" mt-1"
                            >
                              <Tooltip
                                color="#fff"
                                backgroundColor="#172B4D"
                                position="top"
                                show={expandTooltip?.box2}
                                textBoxWidth="80px"
                                fontSize="10px"
                                fontWeight="400"
                                fontFamily={
                                  selectedLanguage == "ar"
                                    ? "Noto Kufi Arabic"
                                    : "Roboto"
                                }
                                padding="8px 8px 8px 8px"
                                borderRadius="5px"
                                static
                                arrowAlign="start"
                                moveRight="5px"
                                moveDown="3px"
                              >
                                <span
                                  style={{ padding: "0px 10px" }}
                                  className="work-order-tooltip"
                                >
                                  {t.dashboard.expand_full}
                                </span>
                              </Tooltip>
                              <FullArrow />
                            </p>
                          )}
                        </span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box2: true,
                            })
                          }
                          onMouseLeave={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box2: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position="top"
                            show={addCustomizedTolltip?.box2}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="0px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.add_customized}
                            </span>
                          </Tooltip>
                          {/* <AddIconDashboard /> */}
                        </p>
                      </span>
                    </div>
                    <div
                      style={{
                        padding: "20px 6px",
                        borderRadius: "4px",
                        marginInline: "13px",
                      }}
                      className="d-flex"
                    >
                      <div style={{ fontSize: "40px" }}>
                        {showPercentage?.box2 === true
                          ? `${dashBoardData?.overDueWorkOrders?.percentage}%`
                          : dashBoardData?.overDueWorkOrders?.count}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "rgba(120, 140, 165, 1)",
                        }}
                        className="d-flex align-items-center"
                      >
                        <span
                          className="mr-2 ml-4"
                          style={
                            dashBoardData?.unassignedWorkOrders
                              ?.findOpenWorkOrderPer?.increase == "decreased"
                              ? { color: "rgba(209, 64, 64, 1)" }
                              : { color: "rgba(79, 171, 104, 1)" }
                          }
                        >
                          {showPercentage?.box2 === false
                            ? dashBoardData?.overDueWorkOrders
                                ?.findOverDueWorkOrdersPer?.count
                            : `${dashBoardData?.overDueWorkOrders?.findOverDueWorkOrdersPer?.percentage}%`}
                        </span>{" "}
                        {dashBoardData?.overDueWorkOrders?.findOpenWorkOrderPer
                          ?.increase == "decreased"
                          ? t.dashboard.box.decrease
                          : t.dashboard.box.increase}
                      </div>
                    </div>

                    <div
                      style={{ gap: "10px" }}
                      className="mt-2 mb-4 d-flex justify-content-center"
                    >
                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box2: true,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2P: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2P: false,
                            })
                          }
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={percentageTooltip?.box2P}
                            textBoxWidth="68px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveLeft="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.percantage}
                            </span>
                          </Tooltip>
                          {showPercentage?.box2 ? (
                            <PercentageDashBoardBlueIcon />
                          ) : (
                            <PercentageLightBlueIcon />
                          )}
                        </p>
                      </span>

                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box2: false,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2C: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box2C: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={percentageTooltip?.box2C}
                            textBoxWidth="50px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveRight="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.number}
                            </span>
                          </Tooltip>
                          {showPercentage?.box2 ? (
                            <HashSvg />
                          ) : (
                            <HashBlueIcon />
                          )}
                        </p>
                      </span>
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            ) : modalData == "unassign" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <Grid.Column className="completion-column pl-0">
                  <div className="completion-column-data">
                    <div className="completion-cotainer d-flex justify-content-between mt-2">
                      <div className="completion-title d-flex">
                        <span className="title">
                          {t.dashboard.box.unassign}
                        </span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowUnAssign(true)}
                          onMouseLeave={() => setShowUnAssign(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showUnAssign}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.tooltip.unAssgin}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                      </div>

                      <span
                        style={{ marginTop: "-10px", gap: "10px" }}
                        className="d-flex"
                      >
                        <span
                          onClick={() => {
                            setOpenModal(true);
                            setExpandTooltip({
                              ...expandTooltip,
                              box1: false,
                            });
                            setModalData("unassign");
                          }}
                          style={{ marginTop: "2px" }}
                          className=""
                        ></span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box1: true,
                            })
                          }
                          onMouseLeave={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box1: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position="top"
                            show={addCustomizedTolltip?.box1}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="0px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.add_customized}
                            </span>
                          </Tooltip>
                          {/* <AddIconDashboard /> */}
                        </p>
                      </span>
                    </div>
                    <div
                      style={{
                        padding: "20px 6px",
                        background: "rgba(245, 246, 248, 1)",
                        borderRadius: "4px",
                        marginInline: "13px",
                      }}
                      className="d-flex"
                    >
                      <div style={{ fontSize: "40px" }}>
                        {showPercentage?.box1 === true
                          ? `${dashBoardData?.unassignedWorkOrders?.percentage}%`
                          : dashBoardData?.unassignedWorkOrders?.count}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "rgba(120, 140, 165, 1)",
                        }}
                        className="d-flex align-items-center"
                      >
                        <span
                          className="mr-2 ml-4"
                          style={
                            dashBoardData?.unassignedWorkOrders
                              ?.findOpenWorkOrderPer?.increase == "decreased"
                              ? { color: "rgba(209, 64, 64, 1)" }
                              : { color: "rgba(79, 171, 104, 1)" }
                          }
                        >
                          {showPercentage?.box1 === false
                            ? dashBoardData?.unassignedWorkOrders
                                ?.findOpenWorkOrderPer?.count
                            : `${dashBoardData?.unassignedWorkOrders?.findOpenWorkOrderPer?.percentage}%`}
                        </span>{" "}
                        {dashBoardData?.unassignedWorkOrders
                          ?.findOpenWorkOrderPer?.increase == "decreased"
                          ? t.dashboard.box.decrease
                          : t.dashboard.box.increase}
                      </div>
                    </div>

                    <div
                      style={{ gap: "10px" }}
                      className="mt-2 mb-4 d-flex justify-content-center"
                    >
                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box1: true,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1P: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1P: false,
                            })
                          }
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={percentageTooltip?.box1P}
                            textBoxWidth="68px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveLeft="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.percantage}
                            </span>
                          </Tooltip>
                          {showPercentage?.box1 ? (
                            <PercentageDashBoardBlueIcon />
                          ) : (
                            <PercentageLightBlueIcon />
                          )}
                        </p>
                      </span>

                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box1: false,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1C: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box1C: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={percentageTooltip?.box1C}
                            textBoxWidth="50px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveRight="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.number}
                            </span>
                          </Tooltip>
                          {showPercentage?.box1 ? (
                            <HashSvg />
                          ) : (
                            <HashBlueIcon />
                          )}
                        </p>{" "}
                      </span>
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            ) : modalData == "onTime" ? (
              <Grid columns={"equal"} className="graph-container m-0">
                <Grid.Column className="completion-column pl-0">
                  <div className="completion-column-data">
                    <div className="completion-cotainer d-flex justify-content-between mt-2">
                      <div className="completion-title d-flex">
                        <span className="title">{t.dashboard.box.ontime}</span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() => setShowOnTime(true)}
                          onMouseLeave={() => setShowOnTime(false)}
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={showOnTime}
                            textBoxWidth="105px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="0px 0px 0px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="8px"
                            // moveDown="10px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.tooltip.onTime}
                            </span>
                          </Tooltip>
                          <DashboardWOStatusIcon />
                        </p>
                      </div>

                      <span
                        style={{ marginTop: "-10px", gap: "10px" }}
                        className="d-flex"
                      >
                        <span style={{ marginTop: "2px" }} className=""></span>
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box3: true,
                            })
                          }
                          onMouseLeave={() =>
                            setAddCustomizedTolltip({
                              ...addCustomizedTolltip,
                              box3: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={addCustomizedTolltip?.box3}
                            textBoxWidth="100px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="end"
                            moveRight="5px"
                            moveDown="-2px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.add_customized}
                            </span>
                          </Tooltip>
                          {/* <AddIconDashboard /> */}
                        </p>
                      </span>
                    </div>
                    <div
                      style={{
                        padding: "20px 6px",
                        borderRadius: "4px",
                        marginInline: "13px",
                      }}
                      className="d-flex"
                    >
                      <div style={{ fontSize: "40px" }}>
                        {showPercentage?.box3 === true
                          ? `${dashBoardData?.onTimeWorkOrders?.percentage}%`
                          : dashBoardData?.onTimeWorkOrders?.count}
                      </div>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "rgba(120, 140, 165, 1)",
                        }}
                        className="d-flex align-items-center"
                      >
                        <span
                          className="mr-2 ml-4"
                          style={
                            dashBoardData?.onTimeWorkOrders
                              ?.findOnTimeWorkOrdersPer?.increase == "decreased"
                              ? { color: "rgba(79, 171, 104, 1)" }
                              : { color: "rgba(209, 64, 64, 1)" }
                          }
                        >
                          {showPercentage?.box3 === false
                            ? dashBoardData?.onTimeWorkOrders
                                ?.findOnTimeWorkOrdersPer?.count
                            : `${dashBoardData?.onTimeWorkOrders?.findOnTimeWorkOrdersPer?.percentage}%`}
                        </span>{" "}
                        {dashBoardData?.onTimeWorkOrders
                          ?.findOnTimeWorkOrdersPer?.increase == "decreased"
                          ? t.dashboard.box.decrease
                          : t.dashboard.box.increase}
                      </div>
                    </div>

                    <div
                      style={{ gap: "10px" }}
                      className="mt-2 mb-4 d-flex justify-content-center"
                    >
                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box3: true,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3P: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3P: false,
                            })
                          }
                          className={
                            selectedLanguage == "en" ? "ml-3 mt-1" : "mr-3 mt-1"
                          }
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "left" : "right"
                            }
                            show={percentageTooltip?.box3P}
                            textBoxWidth="68px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveLeft="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.percantage}
                            </span>
                          </Tooltip>
                          {showPercentage?.box3 ? (
                            <PercentageDashBoardBlueIcon />
                          ) : (
                            <PercentageLightBlueIcon />
                          )}
                        </p>
                      </span>

                      <span
                        className="c-pointer"
                        onClick={() =>
                          setShowPercentage({
                            ...showPercentage,
                            box3: false,
                          })
                        }
                      >
                        <p
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            marginTop: "-10px",
                          }}
                          onMouseOver={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3C: true,
                            })
                          }
                          onMouseLeave={() =>
                            setpercentageTooltip({
                              ...percentageTooltip,
                              box3C: false,
                            })
                          }
                          className=" mt-1"
                        >
                          <Tooltip
                            color="#fff"
                            backgroundColor="#172B4D"
                            position={
                              selectedLanguage == "en" ? "right" : "left"
                            }
                            show={percentageTooltip?.box3C}
                            textBoxWidth="50px"
                            fontSize="10px"
                            fontWeight="400"
                            fontFamily={
                              selectedLanguage == "ar"
                                ? "Noto Kufi Arabic"
                                : "Roboto"
                            }
                            padding="8px 8px 8px 8px"
                            borderRadius="5px"
                            static
                            arrowAlign="start"
                            moveRight="5px"
                            moveDown="3px"
                          >
                            <span
                              style={{ padding: "0px 10px" }}
                              className="work-order-tooltip"
                            >
                              {t.dashboard.number}
                            </span>
                          </Tooltip>
                          {showPercentage?.box3 ? (
                            <HashSvg />
                          ) : (
                            <HashBlueIcon />
                          )}
                        </p>{" "}
                      </span>
                    </div>
                  </div>
                </Grid.Column>
              </Grid>
            ) : null}
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default WorkOrderManagement;
