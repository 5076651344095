export default {
  dashboard: 'Dashboard',
  workOrders: 'Work Orders',
  workRequests: 'Work Request',
  procedureLibrary: 'Procedures',
  assets: 'Assets',
  partsInventory: 'Parts & Inventory',
  timeTracking: 'Time Tracking',
  payroll: 'Payroll',
  locations: 'Locations',
  peopleTeams: 'People & Teams',
  peopleTeam: 'People/Teams',
  customersVendor: 'Customers/Vendors',
  customersVendors: 'Customers & Vendors',
  settings: 'Settings',
  help: 'Help',
  categories: 'Categories',
  language: 'Language',
  company_profile: 'Company Profile',
  configurations: 'Configurations',
  today: 'Today',
};
