import React, { useState, useEffect, createRef } from 'react';
import {
  List,
  Header,
  Modal,
  TextArea,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Popup,
  Icon,
} from 'semantic-ui-react';

import QRCode from 'react-qr-code';

import { v4 as uuidv4 } from 'uuid';

import {
  getUsersByRole,
  getAllLocations,
  createNewAsset,
  getAllTeams,
  getAllCustomers,
  getAllVendros,
  uploadFile,
  GMAPAPIKEY,
} from '../../../config/functions';

import { Country, State, City } from 'country-state-city';
import moment from 'moment';
import { useSelector } from 'react-redux';

function AddAssetModal({ toggled, untoggle, returnAddAsset, title }) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);

  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: null,
  });
  const [responseData, setResponseData] = useState({});

  const [dropdownOptions, setDropdownOptions] = useState({
    vendors: [],
    customers: [],
    locations: [],
  });

  const [data, setData] = useState({
    name: title,
    description: '',
    model: '',
    category: '',
    area: '',
    qrcode: '',
    barcode: '',
    serialNumber: '',
    vendor: '',
    customer: '',
    location: '',
    placedDate: moment(),
    warrantyExpirationDate: moment(),
    additionalInformation: '',
  });

  useEffect(() => {
    setData({ ...data, name: title });
  }, [title]);

  const [validation, setValidation] = useState({
    name: {
      error: false,
      pattern: (name) => name != '',
      skipped: false,
      message: 'Field is required',
    },
    customer: {
      error: false,
      pattern: (customer) => customer != '',
      skipped: false,
      message: 'Field is required',
    },
    vendor: {
      error: false,
      pattern: (vendor) => vendor != '',
      skipped: false,
      message: 'Field is required',
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      name: '',
      description: '',
      model: '',
      category: '',
      area: '',
      qrcode: '',
      barcode: '',
      serialNumber: '',
      vendor: '',
      customer: '',
      location: '',
      placedDate: moment(),
      warrantyExpirationDate: moment(),
      additionalInformation: '',
    });

    setAttachments({
      image: null,
      warrantyFile: null,
    });

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != '',
        skipped: false,
        message: 'Field is required',
      },
      customer: {
        error: false,
        pattern: (customer) => customer != '',
        skipped: false,
        message: 'Field is required',
      },
      vendor: {
        error: false,
        pattern: (vendor) => vendor != '',
        skipped: false,
        message: 'Field is required',
      },
    });
    setValidForm(false);
    setInitChange(false);
  };

  const assetImageRef = createRef();
  const assetWarrantyRef = createRef();

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = ['vendor', 'customer'];

    if (!dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.value,
      });
    }
  };

  const onFileChange = (e, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: e.target.files[0],
        id: randomnumber,
        name: e.target.files[0].name,
        type: e.target.files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };

  const fetchCustomers = async () => {
    const fetchData = await getAllCustomers(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        customers: the_options,
      });
    }
  };

  const fetchVendorsData = async () => {
    const fetchData = await getAllVendros(organisationId);
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        vendors: the_options,
      });
    }
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };

  const downloadThisQR = () => {
    const svg = document.getElementById('QRCodeDownloadable');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const validationHandler = () => {
    setValidation({
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      customer: {
        ...validation.customer,
        error: validation.customer.pattern(data.customer) ? false : true,
        skipped: true,
      },
      vendor: {
        ...validation.vendor,
        error: validation.vendor.pattern(data.vendor) ? false : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log(cond);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const createNewAssetHandler = async () => {
    validationHandler();
    if (validForm) {
      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;
      const warrantyFileResponse =
        attachments.warrantyFile && attachments.warrantyFile.isChanged
          ? await uploadFile([attachments.warrantyFile.file])
          : null;

      createNewAsset2Handler(
        assetImageReponse?.status == 200 ? assetImageReponse.data[0].id : null,
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data[0].id
          : null
      );
    }
  };

  const createNewAsset2Handler = async (image, warranty) => {
    const response = await createNewAsset(
      String(data.name),
      String(data.description),
      String(data.model),
      String(data.category),
      String(data.area),
      String(data.serialNumber),
      String(data.barcode),
      Number(data.vendor),
      Number(data.customer),
      moment(data.placedDate).format('YYYY-MM-DD'),
      moment(data.warrantyExpirationDate).format('YYYY-MM-DD'),
      String(data.additionalInformation),
      image,
      warranty,
      data.qrcode ? true : false,
      organisationId
    );
    // console.log(response);

    if (response.status == 200) {
      setButtonStatus(0);
      untoggle();
      resetForm();
      returnAddAsset(response.data.data.id);
    }
  };

  return (
    <>
      <Modal
        size="small"
        open={toggled}
        onClose={() => {
          untoggle();
        }}
      >
        <Modal.Header>Add Asset</Modal.Header>
        <Modal.Content scrolling>
          <Form.Field>
            <label className="label-item">
              Name
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Enter Asset Name"
              value={data.name}
              onChange={onValuesChange('name')}
              error={validation.name.error && validation.name.skipped}
              onBlur={(e) => {
                if (validation.name.pattern(data.name)) {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.name.error && validation.name.skipped ? (
              <div className="label-error">{validation.name.message}</div>
            ) : (
              ''
            )}
          </Form.Field>

          <Form style={{ marginBottom: '20px' }}>
            <Form.Field>
              <label className="label-item">Description</label>
              <TextArea
                autoComplete="new-password"
                value={data.description}
                onChange={onValuesChange('description')}
                rows={2}
                fluid
                placeholder="Describe Asset..."
              />
            </Form.Field>
          </Form>

          <Grid columns="equal">
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">Barcode</label>

                <div className="flexbox">
                  <Input
                    style={{ flex: 1 }}
                    autoComplete="new-password"
                    fluid
                    placeholder="Enter Asset Barcode"
                    value={data.barcode}
                    onChange={onValuesChange('barcode')}
                  />
                  <Button.Group icon style={{ marginLeft: '5px' }}>
                    <Button
                      icon
                      primary
                      title="Auto-Gen"
                      onClick={() => {
                        setData({
                          ...data,
                          barcode:
                            Math.floor(Math.random() * 9000000000) + 1000000000,
                        });
                      }}
                    >
                      <Icon name="shuffle" />
                    </Button>

                    <Button
                      icon
                      secondary
                      title="QR-Gen"
                      onClick={() => {
                        setData({
                          ...data,
                          qrcode: data.qrcode ? null : uuidv4(),
                        });
                      }}
                    >
                      <Icon name="qrcode" />
                    </Button>
                  </Button.Group>
                </div>

                {data.qrcode ? (
                  <div style={{ marginTop: '5px' }}>
                    <QRCode
                      id="QRCode"
                      title="Asset QR Code"
                      value={data.qrcode}
                      size={100}
                      disabled
                    />
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">Serial Number</label>

                <div className="flexbox">
                  <Input
                    style={{ flex: 1 }}
                    autoComplete="new-password"
                    fluid
                    placeholder="Enter Serial Number"
                    value={data.serialNumber}
                    onChange={onValuesChange('serialNumber')}
                  />
                  <Button
                    icon
                    primary
                    title="Auto-Gen"
                    onClick={() => {
                      setData({
                        ...data,
                        serialNumber:
                          Math.floor(Math.random() * 9000000000) + 1000000000,
                      });
                    }}
                  >
                    <Icon name="shuffle" />
                  </Button>
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Grid columns="equal">
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Model</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Asset Model"
                  value={data.model}
                  onChange={onValuesChange('model')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Category</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Asset Category"
                  value={data.category}
                  onChange={onValuesChange('category')}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">Area</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder="Enter Asset Area"
                  value={data.area}
                  onChange={onValuesChange('area')}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">Image</label>
                {attachments?.image ? (
                  <List divided relaxed>
                    <Popup
                      key={attachments?.image?.id}
                      content="Remove file?"
                      on="click"
                      pinned
                      open={attachments?.image?.cancelPopup}
                      trigger={
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            let currentFile = attachments?.image;
                            currentFile.cancelPopup = !currentFile.cancelPopup;
                            setAttachments({
                              ...attachments,
                              image: currentFile,
                            });
                          }}
                        >
                          <List.Icon
                            name={
                              attachments?.image?.type.match('image.*')
                                ? 'image'
                                : attachments?.image?.type.match('pdf')
                                ? 'file pdf'
                                : 'file'
                            }
                            size="large"
                            color="black"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header>
                              {attachments?.image?.name}
                            </List.Header>
                          </List.Content>
                        </List.Item>
                      }
                    >
                      <Header as="h4">Remove file?</Header>
                      <Button
                        color="black"
                        onClick={(e) => {
                          e.stopPropagation();
                          let currentFile = attachments?.image;
                          currentFile.cancelPopup = false;
                          setAttachments({
                            ...attachments,
                            image: currentFile,
                          });
                        }}
                      >
                        Keep
                      </Button>
                      <Button
                        color="red"
                        onClick={(e) => {
                          e.stopPropagation();
                          setAttachments({
                            ...attachments,
                            image: null,
                          });
                        }}
                      >
                        Yes, remove
                      </Button>
                    </Popup>
                  </List>
                ) : (
                  <div>
                    <input
                      ref={assetImageRef}
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => onFileChange(e, 'image')}
                    />
                    <div
                      className="buttun-type-link"
                      onClick={() => assetImageRef.current.click()}
                    >
                      <Icon name="add" />
                      Add
                    </div>
                    <p
                      style={{
                        fontSize: '12px',
                        marginTop: '5px',
                        color: '#7e7e7e',
                      }}
                    >
                      Allowed types: jpg, jpeg, png
                    </p>
                  </div>
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Divider />

          <Grid columns={'equal'} style={{ marginBottom: '10px' }}>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">
                  Vendor
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Select Vendor"
                  fluid
                  search
                  selection
                  options={dropdownOptions.vendors}
                  value={data.vendor}
                  onChange={onValuesChange('vendor')}
                  onOpen={() => fetchVendorsData()}
                  error={validation.vendor.error && validation.vendor.skipped}
                  onBlur={(e) => {
                    if (validation.vendor.pattern(data.vendor)) {
                      setValidation({
                        ...validation,
                        vendor: {
                          ...validation.vendor,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        vendor: {
                          ...validation.vendor,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.vendor.error && validation.vendor.skipped ? (
                  <div className="label-error">{validation.vendor.message}</div>
                ) : (
                  ''
                )}
              </Form.Field>
              <Form.Field>
                <label className="label-item">Placed in Service Date</label>
                <Input
                  autoComplete="new-password"
                  type="date"
                  fluid
                  placeholder="Placed in Service Date"
                  value={data.placedDate}
                  onChange={onValuesChange('placedDate')}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label className="label-item">
                  Customer
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Select Customer"
                  fluid
                  search
                  selection
                  options={dropdownOptions.customers}
                  value={data.customer}
                  onChange={onValuesChange('customer')}
                  onOpen={() => fetchCustomers()}
                  error={
                    validation.customer.error && validation.customer.skipped
                  }
                  onBlur={(e) => {
                    if (validation.customer.pattern(data.customer)) {
                      setValidation({
                        ...validation,
                        customer: {
                          ...validation.customer,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        customer: {
                          ...validation.customer,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.customer.error && validation.customer.skipped ? (
                  <div className="label-error">
                    {validation.customer.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
              <Form.Field>
                <label className="label-item">Warranty Expiration Date</label>
                <Input
                  autoComplete="new-password"
                  type="date"
                  fluid
                  placeholder="Warranty Expiration Date"
                  value={data.warrantyExpirationDate}
                  onChange={onValuesChange('warrantyExpirationDate')}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Form.Field autoComplete="off">
            <label className="label-item">Additional Information</label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Additional Information"
              value={data.additionalInformation}
              onChange={onValuesChange('additionalInformation')}
            />
          </Form.Field>

          <Form.Field>
            <label className="label-item">Warranty file</label>
            {attachments?.warrantyFile ? (
              <List divided relaxed>
                <Popup
                  key={attachments?.warrantyFile?.id}
                  content="Remove file?"
                  on="click"
                  pinned
                  open={attachments?.warrantyFile?.cancelPopup}
                  trigger={
                    <List.Item
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        let currentFile = attachments?.warrantyFile;
                        currentFile.cancelPopup = !currentFile.cancelPopup;
                        setAttachments({
                          ...attachments,
                          warrantyFile: currentFile,
                        });
                      }}
                    >
                      <List.Icon
                        name={
                          attachments?.warrantyFile?.type.match('image.*')
                            ? 'image'
                            : attachments?.warrantyFile?.type.match('pdf')
                            ? 'file pdf'
                            : 'file'
                        }
                        size="large"
                        color="black"
                        verticalAlign="middle"
                      />
                      <List.Content>
                        <List.Header>
                          {attachments?.warrantyFile?.name}
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  }
                >
                  <Header as="h4">Remove file?</Header>
                  <Button
                    color="black"
                    onClick={(e) => {
                      e.stopPropagation();
                      let currentFile = attachments?.warrantyFile;
                      currentFile.cancelPopup = false;
                      setAttachments({
                        ...attachments,
                        warrantyFile: currentFile,
                      });
                    }}
                  >
                    Keep
                  </Button>
                  <Button
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      setAttachments({
                        ...attachments,
                        warrantyFile: null,
                      });
                    }}
                  >
                    Yes, remove
                  </Button>
                </Popup>
              </List>
            ) : (
              <div>
                <input
                  ref={assetWarrantyRef}
                  type="file"
                  accept="application/pdf, image/*"
                  hidden
                  onChange={(e) => onFileChange(e, 'warrantyFile')}
                />
                <div
                  className="buttun-type-link"
                  onClick={() => assetWarrantyRef.current.click()}
                >
                  <Icon name="add" />
                  Add
                </div>
                <p
                  style={{
                    fontSize: '12px',
                    marginTop: '5px',
                    color: '#7e7e7e',
                  }}
                >
                  Allowed types: jpg, jpeg, png, pdf
                </p>
              </div>
            )}
          </Form.Field>
        </Modal.Content>

        <Modal.Actions>
          <Button
            content="Submit"
            primary
            onClick={createNewAssetHandler}
            loading={buttonStatus == 1}
          />
          <Button
            content="Cancel"
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
      </Modal>

      {/* success modal */}
      <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="flexbox align-center">
              <div className="checkmark-wrapper">
                <Icon name="checkmark" color="white" size="huge" />
              </div>
              {responseData.qrcode ? (
                <QRCode
                  id="QRCodeDownloadable"
                  title={responseData.name}
                  value={String(responseData.id)}
                  size={200}
                  onClick={downloadThisQR}
                />
              ) : (
                ''
              )}
            </div>

            <p className="status-modal-popup-message">
              Asset <b>{responseData.name}</b> added successfully!
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
            }}
          >
            Ok
          </Button>
        </Modal.Actions>
      </Modal>

      {/* cancel modal */}
      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddAssetModal;
