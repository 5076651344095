import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { put, takeLatest } from 'redux-saga/effects';
import { getUserByToken, getPermissionsByRole, getOrganisation } from './authCrud';
import {
  getUserData,
  updateUserNotificationToken,
} from '../../../config/functions';

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  SetOrganisation: '[Set organisation] Action',
  userPermissionsLoaded: '[Load User Permissions] Action',
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  permissions: undefined,
};

export const reducer = persistReducer(
  { storage, key: 'v726-demo1-auth', whitelist: ['authToken'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.userPermissionsLoaded: {
        const { permissions } = action.payload;
        return { ...state, permissions };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      } case actionTypes.SetOrganisation: {
        const { organisation } = action.payload;
        return { ...state, organisation };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  SetOrganisation: (organisation) => ({ type: actionTypes.SetOrganisation, payload: { organisation } }),
  userPermissions: (permissions) => ({
    type: actionTypes.userPermissionsLoaded,
    payload: { permissions },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    const {
      data: {
        role: { permissions },
      },
    } = yield getPermissionsByRole(user?.role?.id);
    let m
    const userData = yield getUserData(user.id);

    const {
      data:  organisation ,
    } = yield getOrganisation(user?.organisation?.id);


    yield put(actions.fulfillUser(userData));
    yield put(actions.userPermissions(permissions));
    yield put(actions.SetOrganisation(organisation?.data?.attributes));
  });
  // yield takeLatest(actionTypes.Logout, function* userLogout() {
  //   const { data: user } = yield getUserByToken();
  //   yield updateUserNotificationToken(user?.id, '');
  // });
}
