import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "semantic-ui-react";
import { selectTranslations } from "../../../config/i18n/slice";
import { createBulkAsset, createBulkPart } from "../../../config/functions";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import useGetJsonDataFromExcelFile from "../../../../hooks/useGetJsonDataFromExcelFile";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CloseIcon } from "../../../../Assets/Icons/svg";
import { Spinner } from "reactstrap";
function PartsBulkUploadModal({
  isOpen = false,
  setModalOpenState = () => {},
  handleSuccess = () => {},
}) {
  const t = useSelector(selectTranslations);
  const { getJsonDataFromExcelFile } = useGetJsonDataFromExcelFile();
  const [buttonStatus, setButtonStatus] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef(null);

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const handlePartData = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        try {
          const binaryString = event.target.result;
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const parsedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          const headers = parsedData[3];

          for (let i = 0; i < headers.length; i++) {
            if (
              headers[i].includes("Asset Name*") ||
              headers[i].includes("اسم الممتلك*")
            ) {
              resolve(true); // Return true if the condition matches
              return;
            }
          }
          resolve(false); // Return false if no match is found
        } catch (error) {
          reject(error); // Handle any errors that occur
        }
      };

      reader.readAsBinaryString(file);
    });
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  useEffect(() => {
    return () => {
      setSelectedFile(null);
    };
  }, [isOpen]);

  const isObjectAllNull = (obj) => {
    // Check if all values in the object are null
    return Object.entries(obj)
      .filter(([key]) => key !== "serialNumber")
      .every(([key, value]) => value === null);
  };

  const handleBrowseButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleAssetCreate = async () => {
    setButtonStatus(1);
    if (
      selectedFile &&
      (selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel")
    ) {
      let jsonData = await getJsonDataFromExcelFile(
        selectedFile,
        [
          t.parts.sheet.sheet_name,
          t.parts.sheet.description,
          t.parts.sheet.category,
          t.parts.sheet.unit_cost,
          t.parts.sheet.unit_type,
          t.parts.sheet.min_qty,
          t.parts.sheet.max_qty,
          t.parts.sheet.part_type,
          t.parts.sheet.stock,
          t.parts.sheet.location,
          t.parts.sheet.area,
          t.parts.sheet.quantity,
          t.parts.sheet.assets,
          t.parts.sheet.vendors,
          t.parts.sheet.people,
          t.parts.sheet.teams,
          t.parts.sheet.serialNumber,
          t.parts.sheet.qr_barcode,

          t.parts.sheet2.sheet_name,
          t.parts.sheet2.description,
          t.parts.sheet2.category,
          t.parts.sheet2.unit_cost,
          t.parts.sheet2.unit_type,
          t.parts.sheet2.min_qty,
          t.parts.sheet2.max_qty,
          t.parts.sheet2.part_type,
          t.parts.sheet2.stock,
          t.parts.sheet2.location,
          t.parts.sheet2.area,
          t.parts.sheet2.quantity,
          t.parts.sheet2.assets,
          t.parts.sheet2.vendors,
          t.parts.sheet2.people,
          t.parts.sheet2.teams,
          t.parts.sheet2.serialNumber,
          t.parts.sheet2.qr_barcode,
        ],
        "part"
      );
      if (jsonData && jsonData.length) {
        const filteredData = jsonData.filter((item) => {
          // Include the item if not all its values are null
          return !isObjectAllNull(item);
        });

        if (filteredData?.length > 201) {
          return toast.error(t.assets.max_length_error);
        }
        let res = await createBulkPart(filteredData, organisationId);

        const allSuccess = res?.data?.updatedData.every(
          (obj) => obj.importStatus === "success"
        );
        const failedData = res?.data?.updatedData.filter(
          (obj) => obj.importStatus !== "success"
        );

        let data = await handlePartData(selectedFile);

        if (data) {
          toast.error(t.assets.asset_error);
        } else if (allSuccess && res?.data?.updatedData?.length == 0) {
          toast.error(t.assets.data_error);
        }
        // console.log(res,"hiiii")
        else if (allSuccess) {
          // toast.success(t.parts.form.part_create_success);
          handleSuccess(res?.data, failedData);
        } else if (res?.status === 500) {
          // toast.error(t.common.something_went_wrong);
          handleSuccess(res?.data, failedData);
        } else {
          handleSuccess(res?.data, failedData);
        }
      }
    } else {
      toast.error(t.assets.upload_excel_asset_file);
    }
    setButtonStatus(0);
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = toAbsoluteUrl(
      selectedLanguage == "en"
        ? "/media/sample-xls/part_en.xlsx"
        : "/media/sample-xls/part_ar.xlsx"
    ); // Set the path to your static file
    downloadLink.download =
      selectedLanguage == "en" ? "Part Template.xlsx" : "نموذج القطع.xlsx"; // Set the desired file name

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <Modal
      className="assets-modal aside-modal-background active"
      size="tiny"
      open={isOpen}
      onClose={() => {
        if (buttonStatus !== 1) {
          setModalOpenState(false);
        }
      }}
    >
      <div className="header-container">
        <span className="assets-header">{t.assets.bulk_part}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => {
              if (buttonStatus !== 1) {
                setModalOpenState(false);
              }
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </div>
      <Modal.Content>
        <span className="assets-label">{t.assets.upload_part}</span>
        <div className="modal-input">
          <div
            class={`small-box ${
              selectedLanguage == "en" ? "text-left" : "text-right"
            }`}
          >
            <label
              className={`text-label ${
                selectedLanguage == "en" ? "ml-2" : "mr-2"
              }`}
            >
              {selectedFile?.name}
            </label>
          </div>
          <input
            id="barcodePopup"
            type="file"
            hidden
            className="file-input"
            ref={fileInputRef}
            fluid
            onChange={(e) => {
              if (e.target.files && e.target.files.length) {
                setSelectedFile(e.target.files[0]);
              }
            }}
          />
          <button onClick={handleBrowseButtonClick} className="browse-btn">
            {t.assets.browse_btn}
          </button>
        </div>
        <div style={{color:"#788CA5",fontSize:"12px"}} className="">{t.assets.upload_file_valid}</div>
        <div className="assets-label mt-1">{t.assets.part_temp}</div>

        <div className="buttun-type-link" basic onClick={handleDownload}>
          {t.assets.parts_temp_xls}
        </div>
      </Modal.Content>

      <div
        className={`modal-button-container ${
          selectedLanguage == "en" ? "mr-6" : "ml-10"
        }`}
      >
        <button
          onClick={() => setModalOpenState(false)}
          disabled={buttonStatus == 1}
          className="modal-form-action-button"
        >
          {t.assets.form.cancel}
        </button>

        <button
          disabled={buttonStatus == 1}
          style={{ minWidth: "68px" }}
          onClick={() => handleAssetCreate()}
          className="modal-form-action-button blue"
        >
          {buttonStatus == 1 ? (
            <Spinner color="light" size="sm">
              <div>{null}</div>
            </Spinner>
          ) : (
            t.categories.import
          )}
        </button>
      </div>
    </Modal>
  );
}

export default PartsBulkUploadModal;
