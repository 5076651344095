export default {
  customers: 'Customers',
  customer: 'Customer',
  customer_vendors: 'Customers/Vendors',
  add_customer: 'Add Customer',
  vendor: 'Vendor',
  table: {
    id: 'ID',
    name: 'Business Name',
    phone: 'Phone',
    email: 'Email',
    website: 'Website',
    country: 'Country',
    city: 'City',
    service_type: 'Service type',
    hourly_rate: 'Hr rate',
    hour_rate: 'Hour rate',
    created_by: 'Created By',
    created_at: 'Created At',
    updated_at: 'Updated At',
    details: 'Details',
  },
  industry: {
    commercial: 'Commercial Real Estate Companies',
    corporate: 'Corporate Offices',
    manufacturing: 'Manufacturing Companies',
    healthcare: 'Healthcare Organizations',
    educational: 'Educational Institutions',
    government: 'Government and Public Sector Organizations',
    hospitality: 'Hospitality and Entertainment Companies',
    data_centers: 'Data Centers',
    retails: 'Retail Companies',
    sports: 'Sports and Recreation Facilities',
  },
  filter: {
    search: 'Search',
    country: 'Country',
    search_by_country: 'Search by Country',
    cities: 'Cities',
    search_by_cities: 'Search by Cities',
    created_at: 'Created At',
    updated_at: 'Updated At',
    selected: 'Selected',
  },
  created_at_options: {
    any_date: 'Any Date',
    today: 'Today',
    this_month: 'This Month',
    this_year: 'This Year',
    last_7_days: 'Last 7 Days',
    last_month: 'Last Month',
    last_year: 'Last Year',
  },
  no_customer_statement: "Looks like you don't have Customers yet.",
  press_to_add_customer: 'Press the ' + ' button to add your first Customer.',
  form: {
    preferences:"Preferred Language",
    add_preferences:"Select preferred language",
    add_name: 'Add name',
    add_description: 'Add description',
    add_location: 'Add location',
    add_email: 'Add email',
    add_address: 'Add address',
    add_rate: 'Add rate',
    add_currency: 'Add currency',
    add_website: 'Add website',
    add_service_type: 'Add service type',
    add_postal: 'Add postal code',
    add_industry: 'Add industry',
    add_number: 'Add number',
    add_contact_name: 'Add contact name',
    add_contact_email: 'Add contact email',
    add_customer: 'Add Customer',
    edit_customer: 'Edit Customer',
    name: 'Name',
    add_customer_name: 'Add customer name',
    description: 'Description',
    location: 'Location',
    select_location: 'Select Location',
    phone: 'Phone',
    website: 'Website',
    service_type: 'Service Type',
    example: 'Ex. Plumbing, Electrical, etc',
    email: 'Email',
    logo: 'Logo',
    add: 'Add+',
    billing_information: 'Billing Information',
    contact_information: 'Contact Information',
    country: 'Country',
    city: 'City',
    postal_code: 'Postal Code',
    address: 'Address',
    address_line_2: 'Address Line 2',
    address_line_3: 'Address Line 3',
    rate: 'Rate',
    currency: 'Currency',
    submit: 'Submit',
    cancel: 'Cancel',
    customer_add_success: 'Customer added successfully',
    customer_updated_success: 'Customer updated successfully',
    invalid_email: 'Invalid email',
    invalid_phone: 'Invalid phone',
    delete_customer_statement: 'Are you sure you want to delete this customer?',
    customer_delete_success: 'Customer deleted successfully',
    customer_delete_related_error: 'This Customer cannot be deleted',
  },
  no_customer_added: 'Start adding customer',
  how_to_add_customer: 'How to add customer',
  title_small: 'customers',
  contact_name: 'Contact Name',
  contact_person: 'Contact Person',
  contact_phone: 'Contact Phone',
  contact_email: 'Contact Email',
  billing_name: 'Billing Name',

  same_info: 'same information listed above ',
};
