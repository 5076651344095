import React, { useEffect, useState } from 'react';
import {
  Image,
  Table,
  Button,
  Header,
  Icon,
  Placeholder,
  Modal,
  Input,
  DropdownItem,
  DropdownMenu,
  Loader,
} from 'semantic-ui-react';
import { useLocation, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';
import { useDebounce } from 'use-debounce';
import Moment from 'react-moment';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import DataTable from 'react-data-table-component';
import Pagination from '../../components/common/CommonPagination';
import _ from 'lodash';

import {
  getAllCategories,
  updateCategory,
  deleteCategory,
  IMAGES_URL,
} from '../../config/functions';
import { toast } from 'react-toastify';
import moment from 'moment';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import { LetteredAvatarImageUrl } from '../../components/common/userProfilePlaceholder';
import AddCategoryModal from '../../components/modal/category/CategoryCreateModal';
import AddBulkCategoryModal from '../../components/modal/category/UploadBulkCategoryModal';
import CategoryBulkUploadResult from '../../components/modal/category/CategoryBulkUploadResult';
import useGetCurrentTimeAccZone from '../../../hooks/useGetCurrentTimeAccZone';
import DateConvert from '../../../utilFunctions/DateFormat';
import {
  AssetIcon,
  ProcedurePlusIcon,
  WhiteDownChevron,
  ShareIcon,
  DownloadIcon,
  PdfIcon,
  UploadIcon,
  ArrowLeft,
  ArrowRight,
  Fail,
  CloseIcon,
  CategoryIcon,
  CategoryEditIcon,
  DeleteIcon,
  Close,
  SearchIcon,
  CategoryBlueIcon,
  SortIcon,
} from '../../../Assets/Icons/svg';
import DownloadExcelFileWithData from '../../../services/DownloadExcelFileWithData';
import DownloadPdfFileWithData from '../../../services/DownloadPdfFileWithData';
import useDisplayFormattedText from '../../../hooks/useDisplayFormattedText';
import useDeletePermissionModal from '../../../hooks/Messages/useDeletePermissionModal';
import useDeleteResultModal from '../../../hooks/Messages/useDeleteResultModal';
import Asterisk from '../../components/common/Asterisk';
import ToolTip from 'react-power-tooltip';
import {
  fetchAllCategoryData,
  fetchCategories,
  fetchCategoriesInitData,
  fetchCategoriesWithFilter,
  setData,
} from '../../../redux/reducers/categoryReducer';
import { Pages } from '@material-ui/icons';
import Animation from '../../components/common/CustomLoader';

const customStyles = {
  headCells: {
    style: {
      paddingTop: '11px', // override the cell padding for head cells
      // paddingBottom: '4px',
    },
  },
  cells: {
    style: {
      paddingLeft: '16px', // override the cell padding for data cells
      paddingRight: '16px',
      paddingTop: '19px',
      paddingBottom: '19px',
    },
  },
};

/* The `Categories` function is a component that handles displaying and managing categories with features like sorting, filtering, pagination, and modals for editing and deleting categories.*/
const Categories = () => {
  const { userPermissions } = usePermissionsSimplified();
  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();
  const dispatch = useDispatch();
  const [sortData, setSortData] = useState();
  const [loader, setloader] = useState(false);
  useGetCurrentTimeAccZone();
  const { DisplayFormattedText } = useDisplayFormattedText();
  const { setDeleteResultModal, DeleteResultModal } = useDeleteResultModal();

  const [searchTitle, setSearchTitle] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [debounceValue] = useDebounce(searchTitle, 500);

  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  /* The function `handleSort` sorts data based on a specified column and sort direction */
  const handleSort = async (column, sortDirection) => {
    setloader(true);
    const sort = [`${column.sortField}:${sortDirection}`];
    setSortData(sort);
    const sortedData = await dispatch(
      fetchCategoriesWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        sort: sort,
      })
    );

    setloader(false);

    // Update state or data variable with sortedData
    // setData(sortedData); // Assuming you have state or variable named 'data'
  };

  /* The function `handleRemoveSorting` for remove sorts data */
  const handleRemoveSorting = async () => {
    setloader(true);

    // Assuming the select or represents the field to sort
    const sort = undefined;
    setSortData(sort);
    const sortedData = await dispatch(
      fetchCategoriesWithFilter({
        organisationId,
        searchName: debounceValue,
        page: page,
        pageSize: pageSize,
        sort: sort,
      })
    );

    setloader(false);
  };

  /**
   * Table Fields
   */
  const columns = [
    {
      width: selectedLanguage === 'en' ? '124px' : '141px',
      name: t.categories.table.ID,
      sortField: 'countNumId',
      sortable: true,
      cell: (row) => <span className="id-text-style">{row?.attributes?.countId}</span>,
    },
    {
      minWidth: '180px',
      name: t.categories.table.name,
      sortField: 'name',
      sortable: true,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <div>
            <span className="id-text-style">
              {row?.attributes?.name
                ? DisplayFormattedText(row?.attributes?.name, 9)
                : ''}
            </span>
          </div>
        </div>
      ),
    },

    {
      name: t.categories.table.createdByUser,
      minWidth: '180px',
      sortField: 'createdByUser.firstName',
      // selector: (row) =>
      //   row?.attributes?.createdByUser?.data?.attributes
      //     ? row?.attributes?.createdByUser?.data?.attributes?.firstName +
      //       ' ' +
      //       row?.attributes?.createdByUser?.data?.attributes?.lastName
      //     : t.common.na,
      sortable: true,
      cell: (row) => {
        let createdByUser = row?.attributes?.createdByUser?.data ? (
          <div>
            <Image
              avatar
              title={
                row.attributes.createdByUser.data.attributes.firstName +
                ' ' +
                row.attributes.createdByUser.data.attributes.lastName
              }
              src={
                row?.attributes?.createdByUser?.data?.attributes?.profileImage
                  ?.data?.attributes?.url
                  ? IMAGES_URL +
                    row?.attributes?.createdByUser?.data?.attributes
                      ?.profileImage?.data?.attributes?.url
                  : LetteredAvatarImageUrl(
                      row?.attributes?.createdByUser?.data?.attributes
                        .firstName +
                        ' ' +
                        row?.attributes?.createdByUser?.data?.attributes
                          ?.lastName
                    )
              }
              style={
                selectedLanguage == 'en'
                  ? { width: '34px', height: '34px', right: '5px' }
                  : { width: '34px', height: '34px', left: '5px' }
              }
            />
            <span
           
              className="id-text-style"
            >
              {row?.attributes?.createdByUser?.data?.attributes?.firstName
                ? DisplayFormattedText(
                    row?.attributes?.createdByUser?.data?.attributes?.firstName,
                    8
                  )
                : ''}
            </span>
          </div>
        ) : (
          t.common.na
        );
        return <span className="ml-1 mr-1">{createdByUser}</span>;
      },
    },
    {
      minWidth: '194px',
      name: t.categories.table.createdAt,
      sortField: 'createdAt',
      // selector: (row) => row?.attributes?.createdAt,
      sortable: true,
      cell: (row) => (
        <div>
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.createdAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.createdAt} />
          </div>
        </div>
      ),
    },
    {
      sortField: 'updatedAt',
      name: t.categories.table.updatedAt,
      minWidth: '194px',
      // selector: (row) => row?.attributes?.updatedAt,
      sortable: true,
      cell: (row) => (
        <div>
          <div>
            <Moment format="DD-MM-YYYY" date={row?.attributes?.updatedAt} />
          </div>
          <div className="time-text-style text-left">
            <Moment format="LT" date={row?.attributes?.updatedAt} />
          </div>
        </div>
      ),
    },
    {
      name: t.categories.table.action,

      cell: (row) => (
        <div className="d-flex">
          {userPermissions?.category?.edit ? (
            <div
              onClick={() => {
                setTargetedId(row.id);
                setCategoryName(row.attributes.name || '');
                setCategoryEditModal(true);
              }}
            >
              {' '}
              <CategoryEditIcon />{' '}
            </div>
          ) : null}

          {userPermissions?.category?.delete ? (
            <div
              onClick={() => {
                if (
                  !row.attributes?.assets?.data?.length &&
                  !row.attributes?.parts?.data?.length
                ) {
                  setTargetedId(row.id);
                  setDeletePermissionModal(true);
                } else {
                  setDeletePermissionModal(true);
                }
              }}
              className="ml-15 mr-15"
            >
              {' '}
              <DeleteIcon />{' '}
            </div>
          ) : null}
        </div>
      ),
    },
  ];

  const [openCategoryAddModal, setOpenCategoryAddModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fetching, setFetchLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [targetedId, setTargetedId] = useState(null);
  const [categoryEditModal, setCategoryEditModal] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteInfoModal, setDeleteInfoModal] = useState(false);
  const [categoryUploadModal, setCategoryUploadModal] = useState(false);
  const [categoryUploadResultModal, setCategoryUploadResultModal] = useState(
    false
  );
  const role = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  const {
    data,
    isLoading: loading,
    error,
    initData,
    page,
    pageSize,
    total,
    initLoading,
  } = useSelector((state) => ({
    data: state.category.data,
    isLoading: state.category.isLoading,
    error: state.category.error,
    initData: state.category.initData,
    page: state.category.page,
    pageSize: state.category.pageSize,
    total: state.category.total,
    initLoading: state.category.initLoading,
  }));

  const [errorMsg, setErrorMsg] = useState('');
  const [bulkUploadResultData, setBulkUploadResultData] = useState([]);

  /* Excel and Pdf file Headers Data */
  const headers = [
    { label: t.categories.table.ID, key: 'id' },
    { label: t.categories.table.name, key: 'name' },
    { label: t.categories.table.createdByUser, key: 'CreatedBy' },
    { label: t.categories.table.createdAt, key: 'CreatedAt' },
    { label: t.categories.table.updatedAt, key: 'UpdatedAt' },
  ];

  /* Fetching initial data for categories based on the `organisationId`. */
  useEffect(() => {
    dispatch(fetchCategoriesInitData(organisationId));
  }, []);

  /* Fetching Filter data for categories based on the `organisationId`. */
  useEffect(() => {
    (async function() {
      setloader(true);

      await dispatch(
        fetchCategoriesWithFilter({
          organisationId,
          searchName: debounceValue,
          page: '1',
          pageSize: '25',
          sort: sortData,
        })
      );

      setloader(false);
    })();
  }, [debounceValue]);

  /* The function `downloadXLS` fetches category data, formats it, and downloads it as an Excel file. */
  const downloadXLS = async () => {
    let csvData = [];

    let fileData = data;

    if (debounceValue == '') {
      let mydata = await dispatch(fetchAllCategoryData(organisationId));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }

    for (let i = 0; i < fileData.length; i++) {
      let category_date = [];

      category_date.id = fileData[i].id;
      category_date.name = fileData[i].attributes.name || t.common.na;
      category_date.createdBy =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;
      category_date.createdAt =
        moment(fileData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;
      category_date.updatedAt =
        moment(fileData[i].attributes.updatedAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        category_date.id,
        category_date.name,
        category_date.createdBy,
        category_date.createdAt,
        category_date.updatedAt,
      ]);
    }
    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 20 }, // Title column
      { wch: 20 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, 'Categories');
  };

  /*
   * The function `generatePDF` fetches category data, formats it, and then downloads a PDF file with the data.
   */
  const generatePDF = async () => {
    let csvData = [];

    let fileData = data;

    if (debounceValue == '') {
      let mydata = await dispatch(fetchAllCategoryData(organisationId));

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    for (let i = 0; i < fileData.length; i++) {
      let category_date = [];

      category_date.id = fileData[i].id;
      category_date.name = fileData[i].attributes.name || t.common.na;
      category_date.createdBy =
        fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName ||
        t.common.na;
      category_date.createdAt =
        moment(fileData[i].attributes.createdAt).format('DD-MM-YYYY LT') ||
        t.common.na;
      category_date.updatedAt =
        moment(fileData[i].attributes.updatedAt).format('DD-MM-YYYY LT') ||
        t.common.na;

      csvData.push([
        category_date.id,
        category_date.name,
        category_date.createdBy,
        category_date.createdAt,
        category_date.updatedAt,
      ]);
    }
    DownloadPdfFileWithData(headers, csvData, 'Categories');
  };

  /*
   * The function `handleCategoryEdit` updates a category with a new name and displays success or error messages accordingly.
   */
  const handleCategoryEdit = async () => {
    if (categoryName && categoryName.trim()) {
      let res = await updateCategory(targetedId, categoryName.trim());
      if (res && res.status === 200) {
        toast.success(t.categories.category_updated_success);
        await dispatch(
          fetchCategoriesWithFilter({
            organisationId,
            searchName: debounceValue,
            page: page,
            pageSize: pageSize,
            sort: sortData,
          })
        );
        setCategoryName('');
        setTargetedId(null);
        setCategoryEditModal(false);
      } else if (res && res.status === 400) {
        // toast.error(t.categories.category_already_exist);
        setErrorMsg(t.categories.category_already_exist);
      } else {
        toast.error(t.categories.not_updated);
      }
    } else {
      toast.error(t.categories.add_category_name);
    }
  };

  /*
   * The function `handleDeleteCategory` deletes a category based on the targeted ID.
   */
  const handleDeleteCategory = async () => {
    if (targetedId) {
      let res = await deleteCategory(targetedId, categoryName.trim());

      if (res && res.status === 200) {
        toast.success(t.categories.category_delete_success);
        await dispatch(
          fetchCategoriesWithFilter({
            organisationId,
            searchName: debounceValue,
            page: page,
            pageSize: pageSize,
            sort: sortData,
          })
        );
      } else {
        toast.error(t.categories.cannot_delete_category_statement);
      }
    } else {
      setDeleteResultModal(true);
    }
    setTargetedId(null);
    setDeleteModal(false);
  };

  /*
   * The above code is a React component that displays a list of categories with various functionalities such as filtering, sorting, pagination, and adding/editing categories.
   */
  return (
    <div className="new-listing-container">
      <div className="table-header">
        <div className="header-actions">
          <Header className="procedure-title" as="h2">
            {' '}
            <CategoryIcon />
            {t.categories.title}
          </Header>
          <div></div>

          {userPermissions?.category?.create &&
          userPermissions?.category?.view &&
          initData?.length > 0 ? (
            <Button
              icon="add"
              primary
              onClick={() => {
                setOpenCategoryAddModal(true);
              }}
            >
              {t.categories.create_category}
              {/* <ProcedurePlusIcon /> */}
            </Button>
          ) : null}
        </div>
      </div>

      {initLoading || initData.length < 0 ? (
        <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
        <Animation/>
        {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
      </div>
        // <div>
        //   <Placeholder fluid>
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //     <Placeholder.Line />
        //   </Placeholder>
        // </div>
      ) : (
        <div className="new-listing-filter">
          {initData?.length > 0 ? (
            <>
              <div className="filters-wrapper mt-2 justify-content-between">
                <div
                  className="table-searchbar d-flex work-order-upload"
                  style={{ width: '240px' }}
                >
                  <span className="search-svg">
                    <SearchIcon />
                  </span>
                  <Input
                    icon={{ name: 'search' }}
                    placeholder={t.people.filter.search}
                    value={searchTitle}
                    onChange={(e) => setSearchTitle(e.target.value)}
                  />
                </div>
                <div className="flexbox table-right-dropdown">
                  <div className="custom-menu-dropdown">
                    <UncontrolledDropdown className="mr-2" direction="down">
                      <DropdownToggle color="primary" className="option-toggle">
                        {t.common.page_action} <WhiteDownChevron />{' '}
                      </DropdownToggle>
                      <DropdownMenu
                        className={selectedLanguage == 'ar' ? 'rtl' : ''}
                      >
                        {/* {role !== 'view_only' ? (
                          <>
                            <DropdownItem>
                              <ShareIcon /> {t.common.share}
                            </DropdownItem>
                            <DropdownItem className="divider" />
                          </>
                        ) : null} */}
                        <DropdownItem className="divider" />
                        <DropdownItem onClick={() => downloadXLS()}>
                          <DownloadIcon /> {t.common.download_sheet}
                        </DropdownItem>
                        <DropdownItem className="divider" />
                        {/* <DropdownItem onClick={() => generatePDF()}>
                        <PdfIcon /> {t.common.download_pdf}
                      </DropdownItem>
                      <DropdownItem className="divider" /> */}
                        <DropdownItem
                          onClick={() => setCategoryUploadModal(true)}
                        >
                          <UploadIcon /> {t.common.upload}
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>

              {error ? (
                <div className="mt-8 mb-8 ">
                <Animation/>
                </div>
              ) : (
                <DataTable
                  className="datatable-div"
                  columns={columns}
                  fixedHeader
                  onSort={handleSort}
                  customStyles={customStyles}
                  data={data}
                  noDataComponent={
                    <div className="no-new-listing-container">
                      <div className="">
                        <p className="no-new-listing-text">
                          {t.common.no_result_found}
                        </p>
                      </div>
                    </div>
                  }
                  direction={selectedLanguage === 'en' ? 'ltr' : 'rtl'}
                  sortIcon={
                    sortData ? (
                      <div>
                        <span>
                          <SortIcon />
                        </span>
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveSorting();
                          }}
                        >
                          <CloseIcon />
                        </span>
                      </div>
                    ) : (
                      <span></span>
                    )
                  }
                  persistTableHead={true}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  progressPending={loader}
                  progressComponent={
                    <div className="mt-8 mb-8 ">
                      { t.common.loading}
                    </div>
                  }
                />
              )}

              <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={total}
                  prevItem={
                    selectedLanguage === 'en' ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === 'en' ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={page}
                  limit={pageSize}
                  handleNext={async () => {
                    setloader(true);
                    await dispatch(
                      fetchCategoriesWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page + 1,
                        pageSize: pageSize,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                  handlePrevious={async () => {
                    setloader(true);
                    await dispatch(
                      fetchCategoriesWithFilter({
                        organisationId,
                        searchName: debounceValue,
                        page: page - 1,
                        pageSize: pageSize,
                        sort: sortData,
                      })
                    );
                    setloader(false);
                  }}
                />
              </div>
            </>
          ) : (
            <div className="">
              <div className="no-data-container">
                <div className="no-work-orders pb-15">
                  <CategoryBlueIcon />
                  <p className="no-data-text">
                    {t.common.there_no}{' '}
                    <span
                      className="link-text"
                      style={{ position: 'relative', cursor: 'pointer' }}
                      onMouseOver={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <ToolTip
                        color="#fff"
                        backgroundColor="#172B4D"
                        position="top left"
                        show={showTooltip}
                        textBoxWidth={
                          selectedLanguage == 'ar' ? '80px' : '58px'
                        }
                        arrowAlign="start"
                        fontSize="14px"
                        fontWeight="400"
                        fontFamily={
                          selectedLanguage == 'ar'
                            ? 'Noto Kufi Arabic'
                            : 'Roboto'
                        }
                        padding="0px 0px 0px 8px"
                        borderRadius="5px"
                        static
                        moveRight={selectedLanguage == 'ar' ? '60px' : '100px'}
                        moveUp="-1px"
                      >
                        <span className="work-order-tooltip">
                          {t.categories.how_to_add_category}
                        </span>
                      </ToolTip>
                      {' ' + t.categories.title_small + ' '}
                    </span>{' '}
                    {t.common.added}
                  </p>
                  {userPermissions?.category?.create ? (
                    <button
                      className="work-order-button-transparent"
                      onClick={() => {
                        setOpenCategoryAddModal(true);
                      }}
                    >
                      {t.categories.no_category_added}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Modal component for editing category. */}
      <Modal
        size="tiny"
        open={categoryEditModal}
        className="assets-modal"
        onClose={() => {
          setCategoryEditModal(false);
          setErrorMsg('');
        }}
      >
        <div className="header-container">
          <span className="assets-header">{t.categories.edit_category}</span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setErrorMsg('');
                setCategoryEditModal(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="">
          <label className="assets-label" style={{ lineHeight: '22px' }}>
            {t.categories.category_name}
          </label>
          <span className="label-item d-inline">
            {' '}
            <Asterisk color="#172B4D" />
          </span>
          <div className="category-input">
            <Input
              autoComplete="new-password"
              fluid
              placeholder={t.categories.add_category_name}
              value={categoryName}
              onChange={(e) => {
                setCategoryName(e.target.value);
                setErrorMsg('');
              }}
            />
            {errorMsg ? <p className="mt-2 text-danger">{errorMsg}</p> : null}
          </div>
        </Modal.Content>

        <div
          className={`modal-button-container mt-4 ${
            selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
          }`}
        >
          <button
            className="modal-form-action-button black"
            onClick={() => {
              setErrorMsg('');
              setCategoryEditModal(false);
            }}
          >
            {t.assets.form.cancel}
          </button>

          <button
            onClick={() => handleCategoryEdit()}
            className="modal-form-action-button blue"
          >
            {t.categories.save}
          </button>
        </div>
      </Modal>

      {/* Modal component for add category. */}
      <AddCategoryModal
        isOpen={openCategoryAddModal}
        setModalOpenState={setOpenCategoryAddModal}
        handleSuccess={() =>
           dispatch(
            fetchCategoriesWithFilter({
              organisationId,
              searchName: debounceValue,
              page: page,
              pageSize: pageSize,
              sort: sortData,
            })
          )
        }
      />

      {/* Modal component for upload category. */}
      <AddBulkCategoryModal
        isOpen={categoryUploadModal}
        setModalOpenState={setCategoryUploadModal}
        handleSuccess={(res) => {
          setBulkUploadResultData(res.data);
          setCategoryUploadResultModal(true);
          setCategoryUploadModal(false);
           dispatch(
            fetchCategoriesWithFilter({
              organisationId,
              searchName: debounceValue,
              page: page,
              pageSize: pageSize,
              sort: sortData,
            })
          );
        }}
      />

      {/* Modal component for upload category result. */}
      <CategoryBulkUploadResult
        isOpen={categoryUploadResultModal}
        setModalOpenState={setCategoryUploadResultModal}
        data={bulkUploadResultData}
      />

      {/* Modal component for delete category premission. */}
      <DeletePermissionModal
        yesCallback={() => {
          handleDeleteCategory();
        }}
        title={t.categories.add}
      >
        <span>{t.categories.delete_category_statement}</span>
      </DeletePermissionModal>

      {/* Modal component for delete category result. */}
      <DeleteResultModal
        closeCallback={() => {
          setDeleteInfoModal(false);
        }}
        deleteErrorMessage={'delete'}
        btnName={t.common.ok}
      >
        <div className="mb-2">
          <Close />
        </div>
        <p
          style={{
            paddingInline: '62px',
            fontSize: '16px',
            fontWeight: '400',
          }}
          className="status-modal-popup-message mt-5"
        >
          {t.categories.cannot_delete_category_statement}
        </p>
      </DeleteResultModal>
    </div>
  );
};
export default Categories;
