import React, { useState, useEffect, useCallback, useRef } from "react";

import { LoadScript, GoogleMap, Polygon } from "@react-google-maps/api";

import { Icon, Grid, Form, Input } from "semantic-ui-react";

import { GMAPAPIKEY } from "../../config/functions";

const MapWrapper = ({ google, submitMarker, center_map, geofencingCoords }) => {
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 32.0503808,
    lng: 35.8776832,
  });

  // Store Polygon path in state
  const [actualPaths, setActualPaths] = useState([
    { lat: 32.06522, lng: 35.903089 },
    { lat: 32.037867, lng: 35.932615 },
    { lat: 32.03583, lng: 35.88661 },
  ]);

  useEffect(() => {
    if (!geofencingCoords?.length > 0) {
      if (center_map) {
        setDefaultCenter({
          lat: center_map.lat,
          lng: center_map.lng,
        });

        setActualPaths([
          {
            lat: center_map.lat + 0.002,
            lng: center_map.lng,
          },
          {
            lat: center_map.lat - 0.001,
            lng: center_map.lng + 0.001,
          },
          {
            lat: center_map.lat - 0.001,
            lng: center_map.lng - 0.001,
          },
        ]);
      }
    } else {
      setDefaultCenter({
        lat: geofencingCoords[0].lat,
        lng: geofencingCoords[0].lng,
      });

      setActualPaths(geofencingCoords);
    }
  }, [center_map, geofencingCoords]);

  useEffect(() => {
    submitMarker(actualPaths);
  }, [actualPaths]);

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  // Call setActualPaths with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setActualPaths(nextPath);
    }
  }, [setActualPaths]);

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener("set_at", onEdit),
        path.addListener("insert_at", onEdit),
        path.addListener("remove_at", onEdit)
      );
    },
    [onEdit]
  );

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  const style = {
    width: "100%",
    height: "100%",
  };

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "400px",
  };

  return (
    // Important! Always set the container height explicitly
    <GoogleMap
      google={google}
      mapContainerStyle={containerStyle}
      style={style}
      center={defaultCenter}
      zoom={15}
      version="weekly"
      on
    >
      <Polygon
        editable
        draggable
        paths={actualPaths}
        onMouseUp={onEdit}
        // Event used when dragging the whole Polygon
        onDragEnd={onEdit}
        onLoad={onLoad}
        onUnmount={onUnmount}
      />
    </GoogleMap>
  );
};

export default MapWrapper;
