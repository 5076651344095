import React, { useState, useEffect, createRef } from 'react';
import {
  Segment,
  Header,
  Modal,
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
} from 'semantic-ui-react';

import Autocomplete from 'react-google-autocomplete';

import {
  getUsersByRole,
  getAllParentLocations,
  createNewLocation,
  getAllTeams,
  getAllCustomers,
  getAllVendros,
  updateMainLocation,
  updateGeofencingCoords,
  GMAPAPIKEY,
} from '../../../config/functions';

import { Country, State, City } from 'country-state-city';
import { useSelector } from 'react-redux';

function AddLocationModal({
  toggled,
  untoggle,
  customer,
  returnAddLocation,
  title,
}) {
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const [initChange, setInitChange] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [mapModal, toggleMapModal] = useState(false);
  const [locationAddedModal, toggleLocationAddedModal] = useState(false);

  const [geofencingSuccessModal, toggleGeofencingSuccessModal] = useState(
    false
  );

  const [responseData, setResponseData] = useState({});

  const [dropdownOptions, setDropdownOptions] = useState({
    parentLocations: [],
    countries: [],
    cities: [],
    customers: [],
    vendors: [],
    people: [],
    teams: [],
  });

  const [data, setData] = useState({
    name: title,
    address: '',
    isParent: false,
    parentLocation: '',
    country: '',
    city: '',
    customer: customer,
    vendors: [],
    teams: [],
    workers: [],
    mainLocation: {},
    geofencingCoords: [],
  });
  const [customFields, setCustomData] = useState([]);

  const [mapMarker, setMapMarker] = useState({});
  const [geofenceMarkers, setGeofencingCoords] = useState([]);

  const [validation, setValidation] = useState({
    name: {
      error: false,
      pattern: (name) => name != '',
      skipped: false,
      message: 'Field is required',
    },
    address: {
      error: false,
      pattern: (address) => address != '',
      skipped: false,
      message: 'Field is required',
    },
    parentLocation: {
      error: false,
      pattern: (parentLocation) => parentLocation != '',
      skipped: false,
      message: 'Field is required',
    },
    country: {
      error: false,
      pattern: (country) => country != '',
      skipped: false,
      message: 'Field is required',
    },
    city: {
      error: false,
      pattern: (city) => city != '',
      skipped: false,
      message: 'Field is required',
    },
    customer: {
      error: false,
      pattern: (customer) => customer != '',
      skipped: false,
      message: 'Field is required',
    },
  });

  const [validForm, setValidForm] = useState(false);

  const resetForm = () => {
    setData({
      name: '',
      address: '',
      isParent: false,
      parentLocation: '',
      country: '',
      city: '',
      customer: '',
      vendors: [],
      teams: [],
      workers: [],
      mainLocation: {},
      geofencingCoords: [],
    });

    setCustomData([]);

    setDropdownOptions({
      parentLocations: [],
      countries: [],
      cities: [],
      customers: [],
      vendors: [],
      users: [],
      teams: [],
    });

    setValidation({
      name: {
        error: false,
        pattern: (name) => name != '',
        skipped: false,
        message: 'Field is required',
      },
      address: {
        error: false,
        pattern: (address) => address != '',
        skipped: false,
        message: 'Field is required',
      },
      parentLocation: {
        error: false,
        pattern: (parentLocation) => parentLocation != '',
        skipped: false,
        message: 'Field is required',
      },
      country: {
        error: false,
        pattern: (country) => country != '',
        skipped: false,
        message: 'Field is required',
      },
      city: {
        error: false,
        pattern: (city) => city != '',
        skipped: false,
        message: 'Field is required',
      },
      customer: {
        error: false,
        pattern: (customer) => customer != '',
        skipped: false,
        message: 'Field is required',
      },
    });

    setInitChange(false);
    setValidForm(false);
  };

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    const dropdown_fields = [
      'parentLocation',
      'country',
      'city',
      'workers',
      'teams',
      'customer',
    ];
    const boolean_fields = ['isParent'];

    if (!dropdown_fields.includes(name) && !boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (dropdown_fields.includes(name)) {
      // console.log(value);
      setData({
        ...data,
        [name]: value.value,
      });
    }

    if (boolean_fields.includes(name)) {
      setData({
        ...data,
        [name]: value.checked,
      });
    }
  };

  useEffect(() => {
    if (toggled) {
      fetchCustomerData().then((resp_data) => {
        if (resp_data) setData({ ...data, customer, name: title });
      });
    }
  }, [toggled, title]);

  useEffect(() => {
    const fetchData = State.getStatesOfCountry(data.country);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        cities: the_options,
      });
    }
  }, [data.country]);

  const fetchCountriesOptions = () => {
    const countries = Country.getAllCountries();
    if (countries) {
      const _g_options = countries.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        countries: _g_options,
      });
    }
  };

  const fetchUsersData = async () => {
    const fetchData = await getUsersByRole(
      ['user', 'supervisor', 'view_only', 'admin', 'requester'],
      organisationId
    );

    if (fetchData) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.firstName,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        people: the_options,
      });
    }
  };

  const fetchParentLocations = async () => {
    const fetchData = await getAllParentLocations(organisationId,"",1,99999)
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        parentLocations: the_options,
      });
    }
  };

  const fetchTeamsData = async () => {
    const fetchData = await getAllTeams(organisationId);

    if (fetchData.response) {
      // console.log(fetchData);
      // console.log("fetchTeamsData", fetchData);
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        teams: the_options,
      });
    }
  };

  const fetchCustomerData = async () => {
    const fetchData = await getAllCustomers(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        customers: the_options,
      });

      return the_options;
    }
  };

  const fetchVendorsData = async () => {
    const fetchData = await getAllVendros(organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
        };
      });
      setDropdownOptions({
        ...dropdownOptions,
        vendors: the_options,
      });
    }
  };

  const generatedCustomDataFields = () => {
    return customFields.map((field, index) => {
      return (
        <Grid columns="equal">
          <Grid.Column>
            <Form.Field>
              <label className="label-item">Label</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder="Field Label"
                value={field.label}
                onChange={handleCustomDataChange(index, 'label')}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label className="label-item">Value</label>
              <Input
                autoComplete="new-password"
                fluid
                placeholder="Field Label"
                value={field.value}
                onChange={handleCustomDataChange(index, 'value')}
              />
            </Form.Field>
          </Grid.Column>

          <div
            className="buttun-type-link remove"
            basic
            onClick={removeThisData(field.id)}
          >
            <Icon name="trash alternate outline" />
            Remove
          </div>
        </Grid>
      );
    });
  };

  const addCustomData = () => {
    // console.log("clicked");
    setCustomData([
      ...customFields,
      {
        label: '',
        value: '',
      },
    ]);
  };

  const removeThisData = (id) => (e) => {
    e.stopPropagation();
    const index = id;
    let newfields = [...customFields];
    newfields.splice(index, 1);

    setCustomData(newfields);
  };

  const handleCustomDataChange = (id, type) => (event) => {
    // 1. Make a shallow copy of the items
    let newfields = [...customFields];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...customFields[id] };
    // 3. Replace the property you're intested in

    if (type == 'label') item.label = event.target.value;
    if (type == 'value') item.value = event.target.value;
    // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
    newfields[id] = item;
    // 5. Set the state to our new copy
    setCustomData(newfields);
  };

  const cancelHandler = () => {
    resetForm();
    untoggle();
    toggleCancelModal(false);
    setInitChange(false);
  };

  const validationHandler = () => {
    setValidation({
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      address: {
        ...validation.address,
        error: validation.address.pattern(data.address) ? false : true,
        skipped: true,
      },
      parentLocation: {
        ...validation.parentLocation,
        error: data.isParent
          ? validation.parentLocation.pattern(data.parentLocation)
            ? false
            : true
          : false,
        skipped: true,
      },
      country: {
        ...validation.country,
        error: validation.country.pattern(data.country) ? false : true,
        skipped: true,
      },
      city: {
        ...validation.city,
        error: validation.city.pattern(data.city) ? false : true,
        skipped: true,
      },
      customer: {
        ...validation.customer,
        error: validation.customer.pattern(data.customer) ? false : true,
        skipped: true,
      },
    });
  };

  useEffect(() => {
    const validationToArray = Object.entries(validation);
    const cond = validationToArray.some((el) => el[1].error && el[1].skipped);
    // console.log(cond);

    if (initChange) setValidForm(!cond);
  }, [validation, initChange]);

  const createNewLocationHandler = async () => {
    validationHandler();
    if (validForm) {
      setButtonStatus(1);

      const isParentHandler = data.parentLocation ? false : true;

      const response = await createNewLocation(
        String(data.name),
        String(data.address),
        Boolean(isParentHandler),
        data.parentLocation ? Number(data.parentLocation) : null,
        String(data.country),
        String(data.city),
        Number(data.customer),
        data.vendors,
        data.teams,
        data.workers,
        data.mainLocation,
        data.geofencingCoords,
        customFields,
        organisationId
      );
      // console.log('location response', response);

      if (response.status == 200) {
        setButtonStatus(0);
        resetForm();
        untoggle();
        returnAddLocation(response.data.data.id);
      }
    }
  };

  return (
    <>
      <Modal
        size="small"
        open={toggled}
        onClose={() => {
          untoggle();
        }}
      >
        <Modal.Header>Add Location</Modal.Header>
        <Modal.Content scrolling>
          <Form.Field>
            <label className="label-item">
              Location Name
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Enter Location Name"
              value={data.name}
              onChange={onValuesChange('name')}
              error={validation.name.error && validation.name.skipped}
              onBlur={(e) => {
                if (validation.name.pattern(data.name)) {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    name: {
                      ...validation.name,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.name.error && validation.name.skipped ? (
              <div className="label-error">{validation.name.message}</div>
            ) : (
              ''
            )}
          </Form.Field>

          <Form.Field>
            <label className="label-item">
              Address
              <Icon name="asterisk" color="red" size="small" />
            </label>
            <Input
              autoComplete="new-password"
              fluid
              placeholder="Address"
              value={data.address}
              onChange={onValuesChange('address')}
              error={validation.address.error && validation.address.skipped}
              onBlur={(e) => {
                if (validation.address.pattern(data.address)) {
                  setValidation({
                    ...validation,
                    address: {
                      ...validation.address,
                      error: false,
                      skipped: true,
                    },
                  });
                } else {
                  setValidation({
                    ...validation,
                    address: {
                      ...validation.address,
                      error: true,
                      skipped: true,
                    },
                  });
                }
              }}
            />
            {validation.address.error && validation.address.skipped ? (
              <div className="label-error">{validation.address.message}</div>
            ) : (
              ''
            )}
            {/* <div className="ui fluid input">
              <Autocomplete
                apiKey={GMAPAPIKEY}
                onPlaceSelected={onPlaceSelected}
                componentRestrictions={{ country: "us" }}
                options={{
                  types: ["geocode", "establishment"],
                }}
              />
            </div> */}
          </Form.Field>

          <Form.Field>
            <Checkbox
              checked={data.isParent}
              label="Is sub location?"
              onChange={onValuesChange('isParent')}
            />
          </Form.Field>

          {data.isParent ? (
            <Form.Field>
              <label className="label-item">
                Parent Location
                <Icon name="asterisk" color="red" size="small" />
              </label>
              <Dropdown
                placeholder="Choose from existing Location"
                fluid
                search
                selection
                clearable
                options={dropdownOptions.parentLocations}
                value={data.parentLocation}
                onChange={onValuesChange('parentLocation')}
                onOpen={() => fetchParentLocations()}
                error={
                  validation.parentLocation.error &&
                  validation.parentLocation.skipped
                }
                onBlur={(e) => {
                  if (data.isParent) {
                    if (
                      validation.parentLocation.pattern(data.parentLocation)
                    ) {
                      setValidation({
                        ...validation,
                        parentLocation: {
                          ...validation.parentLocation,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        parentLocation: {
                          ...validation.parentLocation,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }
                }}
              />
              {validation.parentLocation.error &&
              validation.parentLocation.skipped ? (
                <div className="label-error">
                  {validation.parentLocation.message}
                </div>
              ) : (
                ''
              )}
            </Form.Field>
          ) : (
            ''
          )}

          <Grid columns="equal">
            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">
                  Country
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Country"
                  fluid
                  search
                  selection
                  options={dropdownOptions.countries}
                  value={data.country}
                  onChange={onValuesChange('country')}
                  onOpen={fetchCountriesOptions}
                  error={validation.country.error && validation.country.skipped}
                  onBlur={(e) => {
                    if (validation.country.pattern(data.country)) {
                      setValidation({
                        ...validation,
                        country: {
                          ...validation.country,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        country: {
                          ...validation.country,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.country.error && validation.country.skipped ? (
                  <div className="label-error">
                    {validation.country.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">People</label>
                <Dropdown
                  placeholder="Select Users"
                  fluid
                  multiple
                  search
                  selection
                  options={dropdownOptions.people}
                  value={data.workers}
                  onChange={onValuesChange('workers')}
                  onOpen={() => fetchUsersData()}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  Customer
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="Select Customer"
                  fluid
                  search
                  selection
                  options={dropdownOptions.customers}
                  value={data.customer}
                  onChange={onValuesChange('customer')}
                  disabled={customer != ''}
                  onOpen={() => fetchCustomerData()}
                  error={
                    validation.customer.error && validation.customer.skipped
                  }
                  onBlur={(e) => {
                    if (validation.customer.pattern(data.customer)) {
                      setValidation({
                        ...validation,
                        customer: {
                          ...validation.customer,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        customer: {
                          ...validation.customer,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.customer.error && validation.customer.skipped ? (
                  <div className="label-error">
                    {validation.customer.message}
                  </div>
                ) : (
                  ''
                )}
              </Form.Field>
            </Grid.Column>

            <Grid.Column>
              <Form.Field autoComplete="off">
                <label className="label-item">
                  City
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <Dropdown
                  placeholder="City"
                  fluid
                  search
                  selection
                  disabled={dropdownOptions.cities.length < 1}
                  options={dropdownOptions.cities}
                  value={data.city}
                  onChange={onValuesChange('city')}
                  error={validation.city.error && validation.city.skipped}
                  onBlur={(e) => {
                    if (validation.city.pattern(data.city)) {
                      setValidation({
                        ...validation,
                        city: {
                          ...validation.city,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        city: {
                          ...validation.city,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.city.error && validation.city.skipped ? (
                  <div className="label-error">{validation.city.message}</div>
                ) : (
                  ''
                )}
              </Form.Field>
              <Form.Field>
                <label className="label-item">Teams</label>
                <Dropdown
                  placeholder="Select Teams"
                  fluid
                  multiple
                  search
                  selection
                  options={dropdownOptions.teams}
                  value={data.teams}
                  onChange={onValuesChange('teams')}
                  onOpen={() => fetchTeamsData()}
                />
              </Form.Field>
              <Form.Field>
                <label className="label-item">Vendors</label>
                <Dropdown
                  placeholder="Select Vendors"
                  fluid
                  search
                  multiple
                  selection
                  options={dropdownOptions.vendors}
                  value={data.vendors}
                  onChange={onValuesChange('vendors')}
                  onOpen={() => fetchVendorsData()}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>

          <Divider />
          {generatedCustomDataFields()}
          <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            Custom Data
          </div>

          <Divider />
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Submit"
            primary
            onClick={createNewLocationHandler}
            loading={buttonStatus == 1}
          />

          <Button
            content="Cancel"
            basic
            onClick={() =>
              initChange ? toggleCancelModal(true) : cancelHandler()
            }
          />
        </Modal.Actions>
      </Modal>

      {/* success modal */}
      <Modal
        size="tiny"
        open={successModal}
        onClose={() => toggleSuccessModal(false)}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              Location <b>{responseData.name}</b> added successfully!
            </p>
            <p className="status-modal-popup-message">
              Would you like to add <b>{responseData.name}</b> on map?
            </p>
          </div>
        </Modal.Content>
        {responseData.isParent ? (
          <Modal.Actions>
            <Button
              color="black"
              onClick={() => {
                toggleSuccessModal(false);
              }}
            >
              Later
            </Button>
            <Button
              content="Add on map"
              labelPosition="right"
              icon="add"
              positive
              onClick={() => {
                toggleSuccessModal(false);
              }}
            />
          </Modal.Actions>
        ) : (
          <Modal.Actions>
            <Button
              color="black"
              onClick={() => {
                toggleSuccessModal(false);
              }}
            >
              Ok
            </Button>
          </Modal.Actions>
        )}
      </Modal>

      {/* cancel modal */}
      <Modal
        size="tiny"
        open={cancelModal && initChange}
        onClose={() => toggleCancelModal(false)}
      >
        <Modal.Content>
          <p>Are you sure you want to cancel?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => toggleCancelModal(false)}>
            Keep
          </Button>
          <Button negative onClick={() => cancelHandler()}>
            Yes, cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}

export default AddLocationModal;
