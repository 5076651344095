import React, { useEffect, useState, createRef } from 'react';
import {
  Checkbox,
  Table,
  Button,
  Header,
  Pagination,
  Icon,
  Placeholder,
  Label,
  Image,
  Grid,
  Menu,
} from 'semantic-ui-react';

import Moment from 'react-moment';

import { NavLink } from 'react-router-dom';

import { useLocation, useHistory } from 'react-router';

import md5 from 'md5';

import moment from 'moment';

import {
  getPreventiveOrdersOfAsset,
  getUserProfileByID,
  humanize,
  IMAGES_URL,
} from '../../../config/functions';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../config/i18n/slice';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';
import Animation from '../../../components/common/CustomLoader';

const Orders = ({ data,fetching }) => {
  const t = useSelector(selectTranslations);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const {checkForLinkPermission } = usePermissionsSimplified();

  const history = useHistory();

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  // const [fetching, setFetchLoading] = useState(true);
  // const [data, setData] = useState([]);
  // const [initData, setInitData] = useState([]);

  const [refresh, setRefresh] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const fetchData = await getPreventiveOrdersOfAsset(id, organisationId);

  //     if (fetchData) {
  //       setData(fetchData);
  //       setInitData(fetchData);
  //       setFetchLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [refresh]);

  const priorityOptions = [
    {
      value: 'none',
      label: t.workOrders.priority_status.none,
      color: null,
    },
    {
      value: 'low',
      label: t.workOrders.priority_status.low,

      color: 'yellow',
    },
    {
      value: 'medium',
      label: t.workOrders.priority_status.medium,

      color: 'orange',
    },
    {
      value: 'high',
      label: t.workOrders.priority_status.high,

      color: 'red',
    },
  ];

  const statusOptions = [
    {
      value: 'open',
      color: 'blue',
      label: t.workOrders.work_order_status.open,
    },
    {
      value: 'cancelled',
      color: 'darkgrey',
      label: t.workOrders.work_order_status.cancel,
    },
    {
      value: 'inProgress',
      color: 'yellow',
      label: t.workOrders.work_order_status.inProgress,
    },
    {
      value: 'onHold',
      color: 'red',
      label: t.workOrders.work_order_status.onHold,
    },
    {
      value: 'inReview',
      color: 'black',
      label: t.workOrders.work_order_status.inReview,
    },
    {
      value: 'completed',
      color: 'green',
      label: t.workOrders.work_order_status.completed,
    },
  ];
  const categoryOptions = [
    {
      value: 'damage',
      label: t.workOrders.form.category_options[0],
    },
    {
      value: 'corrective action Downtime',
      label: t.workOrders.form.category_options[1],
    },
    {
      value: 'planned Downtime',
      label: t.workOrders.form.category_options[2],
    },
    {
      value: 'unplanned Downtime',
      label: t.workOrders.form.category_options[3],
    },
    {
      value: 'inspection',
      label: t.workOrders.form.category_options[4],
    },
    {
      value: 'electrical',
      label: t.workOrders.form.category_options[5],
    },
    {
      value: 'mechanical',
      label: t.workOrders.work_order_category.Mechanical,
    },
    {
      value: 'HVAC',
      label: t.workOrders.work_order_category.HVAC,
    },
    {
      value: 'preventive maintenance',
      label: t.workOrders.form.category_options[8],
    },
    {
      value: 'project',
      label: t.workOrders.form.category_options[9],
    },
    {
      value: 'safety',
      label: t.workOrders.work_order_category.Safety,
    },
    {
      value: 'sop',
      label: t.workOrders.form.category_options[11],
    },
  ];

  // const handleSetDataByFilter = (newData) => {
  //   setData(newData);
  // };

  const isPastDue = (date) => {
    var date = moment(date).format('YYYY-MM-DD');
    var now = moment().format('YYYY-MM-DD');

    return now > date;
  };

  return (
    <div>
      {fetching  ? (
        <div className='d-flex justify-content-center align-items-center' style={{minHeight:"500px"}}>
        <Animation/>
        {/* <Placeholder fluid>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder> */}
      </div>
      ) : (
        <div>
          {data?.length > 0 ? (
            <>
              <div className="detail-intro">
                {data.map((el) => {
                  const workOrderPriorityColor = priorityOptions.find(
                    (pro) => pro.value == el.attributes.priority
                  );

                  const categoryStatus = categoryOptions.find(
                    (one) => one.value == el.attributes.category
                  );

                  const workOrderStatus = statusOptions.find(
                    (status) => status.value == el.attributes.status
                  );

                  return (
                    <div className="log-detail-card mt-5">
                      <div className="data-header">
                        <div className=" d-flex justify-content-between align-items-center">
                          <div className="header-pill-container">
                            <p style={{width:"250px"}} className="detail-title">
                            {checkForLinkPermission('work-order') ? 
                            <div
                            className="location-link mt-1"
                            basic
                            onClick={()=>
                              history.push(
                                `/work-orders?id=${el?.attributes?.countId}`
                              )}
                            style={{ marginLeft: '4px' }}
                          > {el?.attributes?.title}</div> :
                          <div
                          className=" mt-1"
                          basic
                          style={{ marginLeft: '4px' }}
                        > {el?.attributes?.title}</div>}
                            </p>
                            <div>
                              <span
                                className={`pill ${el?.attributes?.priority}`}
                              >
                                {el?.attributes?.priority
                                  ? workOrderPriorityColor?.label
                                  : 'None'}
                              </span>
                            </div>
                          </div>
                          <div>
                            <label className={`${selectedLanguage == 'en' ? '' : 'ml-2'}`}>{t.assets.table.ID}</label>
                            {':'}
                            <span>{el?.attributes?.countId}</span>
                          </div>
                        </div>
                        <div>
                          <p className="detail-updated-title">
                            {t.assets.table.updatedAt}
                            {':'}{' '}
                            <span>
                              {
                                <Moment
                                  format="DD-MM-YYYY LT"
                                  date={el?.attributes?.updatedAt}
                                />
                              }
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center mt-5">
                        <label className="detail-intro-label">
                          {t.workOrders.table.status}
                          {':'}
                        </label>
                        <p className="detail-intro-value">
                          <Label
                            circular
                            empty
                            color={workOrderStatus?.color}
                            style={selectedLanguage == 'en' ? { marginRight: '5px' } : {marginLeft: '5px'}}
                          />
                          {el.attributes.status
                            ? workOrderStatus?.label
                            : t.common.na}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mt-5">
                        <label className="detail-intro-label">
                          {t.assets.form.category}
                          {':'}
                        </label>
                        <p className="detail-intro-value">
                          {categoryStatus?.label || t.common.na}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mt-5">
                        <label className="detail-intro-label">
                          {t.workOrders.table.dueDate}
                          {':'}
                        </label>
                        <p className="detail-intro-value">
                          {el.attributes.dueDate ? (
                            <Moment
                              format="DD-MM-YYYY LT"
                              date={
                                el.attributes.dueDate +
                                'T' +
                                el.attributes.dueTime
                              }
                            />
                          ) : (
                            t.common.na
                          )}
                        </p>
                      </div>
                      <div className="d-flex align-items-start mt-5">
                        <label className="detail-intro-label">
                          {t.assets.form.procedure}
                          {':'}
                        </label>
                        <p className="detail-intro-value">
                          {el?.attributes?.procedure?.data ? checkForLinkPermission('procedure') ? (
                            <span
                              className="detail-intro-link ml-4 mr-4"
                              basic
                              onClick={() =>
                                history.push(
                                  `/procedures?id=${el.attributes?.procedure?.data?.attributes?.countId}`
                                )
                              }
                            >
                              {
                                el.attributes?.procedure?.data?.attributes
                                  ?.title
                              }
                            </span>
                          ): <span
                          className=" ml-4 mr-4"
                          basic
                         
                        >
                          {
                            el.attributes?.procedure?.data?.attributes
                              ?.title
                          }
                        </span> : (
                            t.common.na
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Header as="h3">{t.common.na}</Header>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
