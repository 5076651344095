/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from 'react';
import objectPath from 'object-path';
import { useLocation } from 'react-router-dom';
import { QuickActions } from './components/QuickActions';
import { BreadCrumbs } from './components/BreadCrumbs';
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from '../../_core/MetronicSubheader';
import { useHtmlClassService } from '../../_core/MetronicLayout';
import moment from 'moment';
import DateConvert from '../../../../utilFunctions/DateFormat';
import { useSelector } from 'react-redux';
import { selectTranslations } from '../../../../app/config/i18n/slice';

export function SubHeader() {
  const t = useSelector(selectTranslations);
  const organisationTimezone = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.timezone;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [currentDate, setCurrentDate] = useState(
    new Date(moment().startOf('day')).getTime()
  );
  const [profileBreadCrumbs, setProfileBreadCrumbs] = useState(null);
  const today = new Date();

  const options = { month: 'short', day: 'numeric' };

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        'subheader.mobile-toggle'
      ),
      subheaderCssClasses: uiService.getClasses('subheader', true),
      subheaderContainerCssClasses: uiService.getClasses(
        'subheader_container',
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle('kt_aside_menu', location.pathname);
    const header = getBreadcrumbsAndTitle('kt_header_menu', location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {}, [subheader]);
  useEffect(() => {
    if (location.pathname === '/profile') {
      setProfileBreadCrumbs([
        {
          pathname: '/profile',
          title: t.common.my_profile,
        },
      ]);
    } else if (location.pathname === '/company-profile') {
      setProfileBreadCrumbs([
        {
          pathname: '/company-profile',
          title: ` ${t.common.settings} - ${t.common.company_profile} `,
        },
      ]);
    } else if (location.pathname === '/settings') {
      setProfileBreadCrumbs([
        {
          pathname: '/settings',
          title: ` ${t.common.settings} - ${t.common.language} `,
        },
      ]);
    } else {
      setProfileBreadCrumbs(null);
    }
  }, [location.pathname, selectedLanguage]);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setCurrentDate(new Date(moment().startOf('day')).getTime());
    }, 60000);

    return () => {
      clearInterval(intervalID); // Clear the interval when the component unmounts.
    };
  }, [organisationTimezone]);

  return (
    <></>
    // <div
    //   id="kt_subheader"
    //   className={`subheader custom-subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    // >
    //   <div
    //     className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
    //   >
    //     {/* Info */}
    //     <div className="d-flex align-items-center flex-wrap mr-1">
    //       {layoutProps.subheaderMobileToggle && (
    //         <button
    //           className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
    //           id="kt_subheader_mobile_toggle"
    //         >
    //           <span />
    //         </button>
    //       )}

    //       <BreadCrumbs
    //         items={
    //           profileBreadCrumbs ? profileBreadCrumbs : subheader.breadcrumbs
    //         }
    //       />
    //     </div>

    //     {/* Toolbar */}
    //     <div className="d-flex align-items-center">
    //       <a
    //         href="#"
    //         className="btn btn-light btn-sm font-weight-bold"
    //         id="kt_dashboard_daterangepicker"
    //         data-toggle="tooltip"
    //         // title="Select dashboard daterange"
    //         data-placement="left"
    //       >
    //         <span
    //           className="text-muted font-weight-bold mr-2 ml-2"
    //           id="kt_dashboard_daterangepicker_title"
    //         >
    //           {t.aside.today}
    //         </span>
    //         <span
    //           className="text-primary font-weight-bold font-family-sans"
    //           id="kt_dashboard_daterangepicker_date"
    //         >
    //           {organisationTimezone
    //             ? moment(DateConvert(currentDate, organisationTimezone)).format(
    //                 'MMM DD, h:mm A'
    //               )
    //             : moment(currentDate).format('MMM DD, h:mm A')}
    //         </span>
    //       </a>
    //       {/* <QuickActions /> */}
    //     </div>
    //   </div>
    // </div>
  );
}
