import React from 'react';
import { useSelector } from 'react-redux';

const useDisplayFormattedText = () => {


  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  const DisplayFormattedText = (text = '', number = 15, textOnly = false) => {
    if (text) {
      if (text.length < number) {
        return text;
      } else {
        if (textOnly) {
          return `${text.substring(0, number)}...`;
        }
        return <span title={text}>{selectedLanguage == "en" ? `${text.substring(0, number)} ...` : `... ${text.substring(0, number)}`}</span>;
      }
    } else {
      return '';
    }
  };
  return { DisplayFormattedText };
};

export default useDisplayFormattedText;
