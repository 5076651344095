import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import {
  CloseIcon,
  Fail,
  Success,
  WorkOrderKanbarHeaderBlue,
} from '../../Assets/Icons/svg';
import { selectTranslations } from '../../app/config/i18n/slice';

const NewCancelModal = ({
  isOpen = false,
  po = false,
  setIsOpen = () => {},
  closeCallback = () => {},
  yesCallback = () => {},
  children,
  title = '',
  fromAsset=false
}) => {
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  return (
    <Modal
      size="tiny"
      className="assets-modal"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
        closeCallback();
      }}
     style={po ?  {minHeight:"191px"} :fromAsset ? {} : {minHeight:"191px",maxWidth:"379px"}}
    >
      <div className="header-container red">
        <span className="assets-header">{po ? title :t.common.cancel + ' ' + title}</span>
        <div>
          <span
            className="c-pointer text-right"
            onClick={() => {
              setIsOpen(false);
              closeCallback();
            }}
          >
            <CloseIcon />
          </span>
        </div>
      </div>

      <Modal.Content>
        <p className="assets-label ml-1">{children}</p>
      </Modal.Content>
      <div
        className={`modal-button-container mt-4 ${
          selectedLanguage == 'en' ? 'mr-8' : 'ml-10'
        }`}
      >
         <button
          onClick={() => {
            setIsOpen(false);
            closeCallback(false);
          }}
          style={{ fontWeight: '500' }}
          className="modal-form-action-button black"
        >
          {(po || fromAsset )? t.common.cancel :t.common.back}
        </button>
        <button
          onClick={() => {
            setIsOpen(false);
            yesCallback();
          }}
          style={{ color: '#FFF', fontWeight: '500' }}
          className="modal-form-action-button  light-blue"
        >
          {po ? t.common.close :fromAsset ? t.assets.review_hirachy :t.common.cancel}
        </button>

       
      </div>
      {/* <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            setIsOpen(false);
            yesCallback();
          }}
        >
          {t.common.delete}
        </Button>
        <Button
          color="black"
          onClick={() => {
            setIsOpen(false);
            closeCallback();
          }}
        >
          {t.common.keep}
        </Button>
      </Modal.Actions> */}
    </Modal>
  );
};

export function useNewCancelModal() {
  const [isOpen, setIsOpen] = useState(false);

  const DeletePermissionModalCallback = useCallback(
    ({ children, closeCallback, yesCallback, title,po,fromAsset }) => {
      return (
        <NewCancelModal
          isOpen={isOpen}
          fromAsset={fromAsset}
          title={title}
          po={po}
          setIsOpen={setIsOpen}
          closeCallback={closeCallback}
          yesCallback={yesCallback}
        >
          {children}
        </NewCancelModal>
      );
    }
  );
  return useMemo(
    () => ({
      setNewCancelModal: setIsOpen,
      NewCancelModal: DeletePermissionModalCallback,
    }),
    [setIsOpen, DeletePermissionModalCallback]
  );
}

export default useNewCancelModal;
