import React, { useState, useEffect, createRef } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Card, Header, Table } from 'semantic-ui-react';
import { selectTranslations } from '../../../config/i18n/slice';

import {
  getTeamByID,
  humanize,
  bytesToSize,
  IMAGES_URL,
} from '../../../config/functions';
function Attachments({ data }) {
  const t = useSelector(selectTranslations);

  const attachments_array =
    data.attributes.attachments.data?.length > 0
      ? data.attributes.attachments.data.filter((el) => el)
      : [];
  return attachments_array.length > 0 ? (
    <>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {t.workOrders.details_view.name}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t.workOrders.details_view.fileType}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {t.workOrders.details_view.size}
            </Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {attachments_array?.map((el) => {
            return (
              <Table.Row key={el.id} id={el.id}>
                <Table.Cell>
                  <Icon
                    name={
                      el.attributes.mime.match('image.*')
                        ? 'image'
                        : el.attributes.mime.match('pdf')
                        ? 'file pdf'
                        : 'file'
                    }
                    color="black"
                    verticalAlign="middle"
                  />
                  {el.attributes.name}
                </Table.Cell>
                <Table.Cell>{el.attributes.mime}</Table.Cell>
                <Table.Cell>{bytesToSize(el.attributes.size)}</Table.Cell>
                <Table.Cell>
                  <a
                    className="buttun-type-link"
                    href={IMAGES_URL + el.attributes.url}
                    target="_blank"
                  >
                    {t.workOrders.details_view.view}
                  </a>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <Header as="h3">
        {t.workOrders.details_view.no_attachments_statement} -{' '}
        {data.attributes.title}
      </Header>
    </div>
  );
}

export default Attachments;
