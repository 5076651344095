import React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { LocationBlueIcon, SuccessToastIcon } from '../../Assets/Icons/svg';

function ToastContainerWithDirection(props) {
  const customToastStyle = {
    borderRedius: '8px',
  };
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const commonToastOptions = {
    closeButton: false,
    theme: 'light',
    style: customToastStyle,
    hideProgressBar:true,
    autoClose: 5000,
    position: selectedLanguage === 'ar' ? 'top-left' : 'top-right',
  };

  toast.configure({
    commonToastOptions,
    // Default icon for success toasts
  });

  const originalSuccess = toast.success;
  const originalError = toast.error;

  // Replace default icon for error toasts
  toast.error = (message) => {
    return originalError(message, {
      ...commonToastOptions,
      icon:true,
    });
  };
  toast.success = (message) => {
    return originalSuccess(message, {
      ...commonToastOptions,
      icon: <SuccessToastIcon />,
    });
  };

  return <ToastContainer />;
}

export default ToastContainerWithDirection;
