import React, { useEffect, useState, createRef, useRef } from 'react';
import {
  Header,
  Button,
  Table,
  Image,
  Placeholder,
  Icon,
  Pagination,
  Label,
} from 'semantic-ui-react';

import Moment from 'react-moment';

import md5 from 'md5';

import { NavLink } from 'react-router-dom';

import { useLocation } from 'react-router';
import { toAbsoluteUrl, checkIsActive } from '../../../_metronic/_helpers';

import { getUsersByRole, humanize } from '../../config/functions';

import Preview from './preview/index';
import Add from './add';

import Filters from './filters';

import moment from 'moment';

import { useSelector } from 'react-redux';
import { selectTranslations } from '../../config/i18n/slice';

export const TimeTracking = () => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [refresh, setRefresh] = useState(false);
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [fetching, setFetchLoading] = useState(true);

  const [data, setData] = useState([]);
  const [initData, setInitData] = useState([]);
  const [dataTrigger, updateData] = useState(false);

  const [userProfiles, setUserProfiles] = useState([]);
  const [attendanceRecords, setAttendanceRecords] = useState([]);

  const [targetedID, setTargetedID] = useState();
  const [togglePreview, setTogglePreviw] = useState(false);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url);
  };

  useEffect(() => {
    const fetchData = async () => {
      const fetchData = await getUsersByRole(
        ['worker', 'supervisor', 'project_manger'],
        organisationId
      );

      if (fetchData) {
        setData(fetchData);
        setInitData(fetchData);
      }
    };

    fetchData();
  }, [dataTrigger, toggleAsideModal, toggleEditModal, refresh]);

  const rowStyle = { cursor: 'pointer' };

  const editModalHandler = (ID) => {
    setTargetedID(ID);
    setTogglePreviw(false);
    setToggleEditModal(true);
  };

  const handleSetDataByFilter = (newData) => {
    setData(newData);
  };

  const getUserProfile = (id) => {
    return userProfiles.filter((el) => el.id == id)[0];
  };

  const getUserAttendenceRecords = (id) => {
    return attendanceRecords?.filter((el) => el?.user?.id == id);
  };

  return (
    <div>
      <Header as="h2">Soon</Header>
    </div>
  );
};
